/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 16/04/2021, 20:05
 */

import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Dropdown } from 'components/Dropdown';
import { Form } from 'components/Form';
import { Input, PhoneInput } from 'components/Inputs';
import { Label } from 'components/Labels';
import { Link } from 'components/Links';
import styles from 'forms/auth/Registration/Register.module.css';
import { withLang, withUI } from 'hocs';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Visible } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { showError } from 'validators/showError';

class Register extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
		isEmailExist: PropTypes.func,
	};

	state = {
		agreed: false,
		validation: null,
		localErrors: {},
		captchaToken: null,
		isCaptchaEnabled: process.env.REACT_APP_RECAPTCHA_V3KEY || process.env.REACT_APP_RECAPTCHA_V2KEY
	};

	form = React.createRef();
	first_name = React.createRef();
	last_name = React.createRef();
	lang = React.createRef();
	phone = React.createRef();
	email = React.createRef();
	password = React.createRef();
	agreedCheckbox = React.createRef();
	captchaContainer = React.createRef();

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.props.onSubmit) {
			this.props.onSubmit({
				first_name: this.first_name.current.value,
				last_name: this.last_name.current.value,
				email: this.email.current.value,
				lang: this.lang.current.value,
				phone: this.phone.current.value,
				password: this.password.current.value,
				'g-recaptcha-token': this.state.captchaToken,
				// language: this.props.langContext.lang,
			});
		}
	};

	handleBlur = () => {
		// this.validation();
	};

	validation() {
		// const {email} = this.state;
		// if (this.email.current.value !== '') {
		// 	isEmail(this.email.current.value).catch((lexeme) => {
		// 		this.setState({ validation: { message: lexeme } });
		// 	});
		// }
	}

	verifyCallback = (token) => {
		this.setState({ captchaToken: token })
	}

	captchaCheck = () => {
		if (this.state.isCaptchaEnabled) {
			const grecaptcha = window.grecaptcha;
			if (grecaptcha) {
				const showCaptcha = () => {
					if (this.captchaContainer.current.children.length) {
						return;
					}
					grecaptcha.ready(() => {
						try {
							grecaptcha.render('captcha-container', {
								'sitekey': process.env.REACT_APP_RECAPTCHA_V2KEY,
								'callback': this.verifyCallback,
								'hl': this.props.langContext.lang ?? 'en',
							});
						} catch (error) {
							console.log(error);
						}
					});
				};


				grecaptcha.ready(() => {
					try {
						grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_V3KEY, { action: 'submit' }).then((token) => {
							if (token) {
								this.setState({ captchaToken: token });
								console.log(token);
								return;
							}
							showCaptcha();
						});
					} catch (error) {
						console.log(error);
					}
				});
			}
		}
	};

	handleAgreed = () => {
		this.setState({ agreed: this.agreedCheckbox.current.checked });
		this.captchaCheck();
	};

	handleShowTerms = (e) => {
		e.preventDefault();
		const {
			UIContext: { showModal },
		} = this.props;
		showModal({
			title: <FormattedHTMLMessage id={'rod.modal.terms.header'} />,
			text: <FormattedHTMLMessage id={'rod.modal.terms.text'} />,
			width: '807px',
		})();
	};

	handleLangErrorChange = (e) => {
		const target = 'lang';
		if (this.props.errors[target]) {
			delete this.props.errors[target];
			this.forceUpdate();
		}
	};

	handleInputBlur = (target) => {
		if (this[target].current.value && this.props.errors[target]) {
			delete this.props.errors[target];
			this.forceUpdate();
		}
	};

	render() {
		let { errors } = this.props;
		const {
			UIContext: { isPrivatePolicyShow },
		} = this.props;
		if (errors.password) {
			if (this.password?.current?.value.match(/[а-яА-Я]+/gi)) {
				errors.password = [{ rule: 'cyrillic_invalid' }];
			}
		}

		return (
			<div className={styles.Register_wrapper}>
				<Form
					ref={this.form}
					onSubmit={this.handleSubmit}
					className={styles.form_wrapper}
				>
					{/* <div className={'title'}> */}
					<div className={styles.title}>
						{/* <Label font="--gilroy-Medium-40" black> */}
						<div className={styles.register_header}>
							<FormattedHTMLMessage id={'rod.register.header'} />
						</div>
						{/* </Label> */}
						{/* <Label font="--gilroy-Medium-24" black> */}
						<div className={styles.register_subheader}>
							<FormattedHTMLMessage id={'rod.register.subheader'} />
						</div>
						{/* </Label> */}
					</div>
					<FormattedMessage id={'rod.field.firstname'}>
						{(placeholder) => (
							<Input
								placeholder={placeholder}
								ref={this.first_name}
								required
								showError={showError('first_name')}
								maxLength={255}
								errors={errors?.first_name}
								onBlur={() => this.handleInputBlur('first_name')}
								className={styles.Register_Input}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.lastname'}>
						{(placeholder) => (
							<Input
								placeholder={placeholder}
								ref={this.last_name}
								required
								showError={showError('last_name')}
								maxLength={255}
								errors={errors?.last_name}
								onBlur={() => this.handleInputBlur('last_name')}
								className={styles.Register_Input}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.email'}>
						{(placeholder) => (
							<Input
								placeholder={placeholder}
								ref={this.email}
								required
								showError={showError('email')}
								errors={errors.email}
								onBlur={() => this.handleInputBlur('email')}
								className={styles.Register_Input}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.phone'}>
						{(placeholder) => (
							<PhoneInput
								name={'phone'}
								countryId={18}
								showError={showError('phone')}
								placeholder={placeholder}
								ref={this.phone}
								required
								errors={errors?.phone}
								onBlur={() => this.handleInputBlur('phone')}
								className={styles.Register_Phone_Input}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.password'}>
						{(placeholder) => (
							<Input
								type="password"
								tip={<FormattedHTMLMessage id={'rod.field.password.tip'} />}
								placeholder={placeholder}
								ref={this.password}
								required
								showError={showError('password')}
								errors={errors?.password}
								onBlur={() => this.handleInputBlur('password')}
								className={styles.Register_Input}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.lang'}>
						{(placeholder) => (
							<Dropdown
								placeholder={placeholder}
								//todo get value
								defaultEmpty
								required
								name={'lang'}
								errors={errors?.lang}
								showError={showError('lang')}
								forwardRef={this.lang}
								items={[
									{
										keyword: 'en',
										code: 'us',
										lexeme: 'rod.field.lang.en',
									},
									{
										keyword: 'ru',
										code: 'ru',
										lexeme: 'rod.field.lang.ru',
									},
								]}
								getItem={(item) => (
									<React.Fragment>
										<FormattedMessage id={item.lexeme} />
									</React.Fragment>
								)}
								getListItem={(item) => (
									<React.Fragment>
										<FormattedMessage id={item.lexeme} />
									</React.Fragment>
								)}
								getItemValue={(item) => item.keyword}
								onChange={this.handleLangErrorChange}
								className={styles.Register_Dropdown_Input}
							/>
						)}
					</FormattedMessage>

					<Checkbox
						className={styles.Checkbox}
						ref={this.agreedCheckbox}
						onChange={this.handleAgreed}
						required
					>
						{isPrivatePolicyShow ? (
							// <FormattedMessage
							// 	id={'rod.register.terms'}
							// 	values={{
							// 		a: (msg) => (
							// 			<button
							// 				type="button"
							// 				className={styles.Button}
							// 				onClick={this.handleShowTerms}
							// 			>
							// 				{msg}
							// 			</button>
							// 		),
							// 	}}
							// />
							<FormattedMessage
								id={'rod.register.terms'}
								values={{
									a: (msg) => (
										<a
											href={`/examples/privacy_policy_v3.pdf`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{msg}
										</a>
									),
								}}
							/>
						) : (
							<FormattedMessage id={'rod.register.terms_no_link'} />
						)}
						{/* <FormattedMessage id={'rod.register.terms'} /> */}
					</Checkbox>
					<div id='captcha-container' className={styles['recaptcha-container']} ref={this.captchaContainer} />
					<div className="controls">
						<Button
							className={styles.login_button}
							text={<FormattedMessage id={'rod.action.register'} />}
							type={'submit'}
							disabled={
								!(this.state.agreed && this.state.validation === null) ||
								this.props.isRegisterInProcess
								|| (this.state.isCaptchaEnabled && !this.state.captchaToken)
							}
							variant={'primary'}
						/>
						{this.state.isCaptchaEnabled && (
							<div className={styles['recaptcha-privacy']}>
								<FormattedMessage id={'rod.captcha.description'} />{' '}
								<a href="https://policies.google.com/privacy"><FormattedMessage id={'rod.captcha.privacy'} /> {' '}</a>
								<FormattedMessage id={'rod.captcha.and'} />{' '}
								<a href="https://policies.google.com/terms"><FormattedMessage id={'rod.captcha.terms'} /></a>
							</div>
						)}
					</div>
				</Form>
				<Visible xs>
					{/* <Row className={styles.Form}>
						<Col offset={{ sm: 1 }} sm={5} className="login"> */}

					<div className={styles.login_wrapper}>
						<Label
							font="--gilroy-Medium-28"
							black
							className={styles.login_title_header}
						>
							<FormattedHTMLMessage
								id={'rod.register.text.already_have_account_title'}
							/>
						</Label>
						<Label
							font="--gilroy-Medium-18"
							black
							className={styles.login_title_body}
						>
							<FormattedHTMLMessage
								id={'rod.register.text.already_have_account'}
							/>
						</Label>
						<div className={styles.login_button_wrapper}>
							<Link to={'/login'}>
								<Button className={styles.login_button}>
									<FormattedHTMLMessage id={'rod.landing.form.action.login'} />
								</Button>
							</Link>
						</div>
					</div>

					{/* 						</Col>
					</Row> */}

					{/* 					<div className={'controls'}>
						<Label
							text={
								<FormattedMessage
									id={'rod.register.text.already_have_account'}
								/>
							}
							black
						/>
						<Link to={'/login'}>
							<FormattedMessage id={'rod.action.login'} />
						</Link>
					</div> */}
				</Visible>
			</div>
		);
	}
}

export default compose(withUI, withLang)(Register);
