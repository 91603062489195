// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// UI
import { Button } from 'components/Buttons/Button';
import { FormInput } from 'components/Form/FormInput';
import { SettingsLayout } from '../SettingsLayout';

// Types
import paymentTypes from 'constants/paymentTypes';
import { paymentMethods } from './paymentMethods';

// Styles
import styles from './SettingsBankDataSection.module.css';

const SettingsBankDataSection = (props) => {
	const lang = localStorage.getItem('lang');

	const [errors, setErrors] = useState({});
	const [personal, setPersonal] = useState({
		payment_channel: paymentTypes.BANK,
	});

	const [isEdit, setIsEdit] = useState(false);
	const [isChangedSmth, setIsChangedSmth] = useState(false);

	const isAllRequiredFilled = (data) => {
		if (
			data.bank_account_title &&
			data.bank_account_number &&
			data.bank_title &&
			data.bank_account_swift &&
			data.bank_country_id &&
			data.bank_address
		) {
			return true;
		} else {
			return false;
		}
	};

	const changeField = (field) => (value) => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		if (field === 'payment_channel') {
			setPersonal((prev) => ({ ...prev, [field]: value.id }));
		} else {
			setPersonal((prev) => ({ ...prev, [field]: value }));
		}
	};

	const saveHandler = (e) => {
		e.preventDefault();

		setIsChangedSmth(false);

		if (personal.payment_channel === 'bank') {
			delete personal.paypal;
		}

		if (personal.payment_channel === 'paypal') {
			delete personal.bank_account_number;
			delete personal.bank_account_swift;
			delete personal.bank_account_title;
			delete personal.bank_address;
			delete personal.bank_title;
		}
		props.onChange({
			...personal,
		});
	};

	const handleEditToggle = () => {
		setIsEdit(!isEdit);
	};

	useEffect(() => {
		!isAllRequiredFilled(personal)
			? props.isAllDone('bank')(false)
			: props.isAllDone('bank')(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal]);

	useEffect(() => {
		setErrors(props.errors);
		if (!Object.keys(props.errors).length) {
			props.isAllDone('bank')(true);
		}

		setIsEdit(Object.keys(props.errors).length > 0);
		setIsChangedSmth(Object.keys(props.errors).length > 0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.errors]);

	useEffect(() => {}, [lang]);

	useEffect(() => {
		setErrors({});
		const { data } = props;

		if (props.accountType.business_type === 'individual') {
			delete data.bank_correspondent_account_number;
			delete data.bank_correspondent_account_swift;
		}

		setPersonal((prev) => ({
			...prev,
			...data,
		}));

		if (data?.paypal?.length) {
			setPersonal((prev) => ({
				...prev,
				payment_channel: 'paypal',
			}));

			props.isAllDone('paypal')(isAllRequiredFilled(data));
		} else {
			setPersonal((prev) => ({
				...prev,
				payment_channel: 'bank',
			}));

			props.isAllDone('bank')(isAllRequiredFilled(data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		rootContext: { countries },
		UIContext: { showModal },
	} = props;

	return (
		<>
			{personal && (
				<SettingsLayout
					isEdit={isEdit}
					handleEditToggle={handleEditToggle}
					showModal={showModal}
					title="rod.account.bank.title"
					banner={personal.payment_channel === paymentTypes.PAYPAL}
					hideClose={true}
				>
					<div>
						{personal.payment_channel === paymentTypes.BANK && (
							<>
								<FormattedMessage id={'rod.field.beneficiary_name'}>
									{(placeholder) => (
										<FormInput
											placeholder={placeholder}
											name={'bank_account_title'}
											onChange={changeField}
											errors={errors}
											required
											data={personal}
										/>
									)}
								</FormattedMessage>
								<FormattedMessage id={'rod.field.bank_account_name'}>
									{(placeholder) => (
										<FormInput
											placeholder={placeholder}
											name={'bank_account_number'}
											onChange={changeField}
											errors={errors}
											required
											data={personal}
										/>
									)}
								</FormattedMessage>
								<FormattedMessage id={'rod.field.swift'}>
									{(placeholder) => (
										<FormInput
											placeholder={placeholder}
											name={'bank_account_swift'}
											onChange={changeField}
											errors={errors}
											required
											data={personal}
										/>
									)}
								</FormattedMessage>
								{props.accountType.business_type !== 'individual' && (
									<FormattedMessage id={'rod.field.cor_account'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_correspondent_account_number'}
												onChange={changeField}
												errors={errors}
												data={personal}
												required
											/>
										)}
									</FormattedMessage>
								)}
							</>
						)}
						{personal.payment_channel === paymentTypes.PAYPAL && (
							<>
								{isEdit && (
									<div className={styles.payment}>
										<FormInput
											type="muiInputSelect"
											name={'payment_channel'}
											onChange={changeField}
											errors={errors}
											data={personal}
											label={
												<FormattedMessage
													id={'rod.field.paypal_account.pay_method'}
												/>
											}
											items={paymentMethods}
											required
										/>
									</div>
								)}
								<FormattedMessage id={'rod.field.paypal_account'}>
									{(placeholder) => (
										<FormInput
											placeholder={placeholder}
											name={'paypal'}
											onChange={changeField}
											helper={
												<FormattedMessage
													id={'rod.field.paypal_account.helper'}
												/>
											}
											errors={errors}
											required
											data={personal}
										/>
									)}
								</FormattedMessage>
							</>
						)}
						{isEdit && (
							<div className={styles.save_btn}>
								<Button
									text={<FormattedMessage id={'rod.action.save'} />}
									variant={'primary'}
									onClick={saveHandler}
									disabled={!isEdit || !isChangedSmth}
									style={{
										pointerEvents: isChangedSmth ? 'all' : 'none',
									}}
								/>
							</div>
						)}
					</div>
					<div>
						{personal.payment_channel === paymentTypes.BANK && (
							<>
								<FormattedMessage id={'rod.field.bank_name'}>
									{(placeholder) => (
										<FormInput
											placeholder={placeholder}
											name={'bank_title'}
											onChange={changeField}
											errors={errors}
											required
											data={personal}
										/>
									)}
								</FormattedMessage>
								<FormattedMessage id={'rod.field.bank_department_address'}>
									{(placeholder) => (
										<FormInput
											placeholder={placeholder}
											name={'bank_address'}
											onChange={changeField}
											required
											errors={errors}
											data={personal}
										/>
									)}
								</FormattedMessage>
								<FormattedMessage id={'rod.field.bank_country'}>
									{(placeholder) => (
										<FormInput
											type="country"
											countries={countries}
											placeholder={placeholder}
											name={'bank_country_id'}
											onChange={changeField}
											errors={errors}
											data={personal}
											className={styles.country}
											required
										/>
									)}
								</FormattedMessage>
								{props.accountType.business_type !== 'individual' && (
									<FormattedMessage id={'rod.field.cor_swift'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_correspondent_account_swift'}
												onChange={changeField}
												errors={errors}
												data={personal}
												required
											/>
										)}
									</FormattedMessage>
								)}
							</>
						)}
					</div>
				</SettingsLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(SettingsBankDataSection);
