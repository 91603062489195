/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 21/12/2020, 21:59
 */

import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { InfoButton } from 'components/Buttons';
import { RadioGroup } from 'components/Radio';
import accountTypes from 'constants/accountTypes';
import repertoireOwn from 'constants/repertoireOwn';
import { withAuth, withLang, withRoot } from 'hocs';
import update from 'immutability-helper';
import styles from 'pages/account/Create/steps/01-WhoAmI/WhoAmI.module.css';
import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
// import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { getHelpMessage } from '../../../../../messages/helper';

class WhoAmI extends PureComponent {
	static propTypes = {};

	state = {
		personalData: {
			business_type: accountTypes.PERSONAL,
			scope_group: repertoireOwn.MYSELF,
		},
	};

	componentDidMount() {
		const {
			authContext: { getAccountCompletionStep },
		} = this.props;
		getAccountCompletionStep();
	}

	whoAreYouChangeHandler = (e) => {
		this.setState(
			update(this.state, {
				personalData: {
					scope_group: { $set: e.currentTarget.value },
				},
			})
		);
	};
	signAsChangeHandler = (e) => {
		this.setState(
			update(this.state, {
				personalData: {
					scope_group: {
						$set:
							e.currentTarget.value === accountTypes.COMPANY
								? repertoireOwn.ANOTHER_PERSON
								: e.currentTarget.value === accountTypes.PERSONAL
								? repertoireOwn.MYSELF
								: this.state.personalData.scope_group,
					},
					business_type: { $set: e.currentTarget.value },
				},
			})
		);
	};

	nextHandler = () => {
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(this.state);
		}
	};

	render() {
		// const { authContext: { isAuth, login } } = this.props;

		return (
			<Container fluid>
				<Row>
					<Col sm={10} offset={{ sm: 1 }}>
						<Label
							text={
								<FormattedMessage
									id={'rod.account.create.who_are_you.header'}
								/>
							}
							font="--gilroy-Medium-40"
							black
							className={styles.Header}
						/>
						{this.renderSignAs()}
						{this.renderWhoAreYou()}
						{this.renderControls()}
					</Col>
				</Row>
			</Container>
		);
	}

	renderControls() {
		return (
			<div className={styles.Controls}>
				<Button
					text={<FormattedMessage id={'rod.action.next'} />}
					variant={'primary'}
					onClick={this.nextHandler}
					disabled={this.props.isReqSend}
				/>
			</div>
		);
	}

	renderWhoAreYou() {
		const {
			UIContext: { showModal },
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'who_am_I_repertoire')
		);

		let roles = [
			{
				text: <FormattedMessage id={'rod.account.create.who_are_you.self'} />,
				value: repertoireOwn.MYSELF,
				disabled: false,
			},
			{
				text: <FormattedMessage id={'rod.account.create.who_are_you.shared'} />,
				value: repertoireOwn.ANOTHER_PERSON,
				disabled: false,
			},
			{
				text: <FormattedMessage id={'rod.account.create.who_are_you.all'} />,
				value: repertoireOwn.MYSELF_AND_ANOTHER,
				disabled: false,
			},
		];

		if (this.state.personalData.business_type === accountTypes.COMPANY) {
			roles[0].disabled = true;
			roles[2].disabled = true;
		}
		if (this.state.personalData.business_type === accountTypes.PERSONAL) {
			roles[1].disabled = true;
			roles[2].disabled = true;
		}
		return (
			<div className={styles.Block}>
				<InfoButton
					className={styles.infoButton}
					onClick={showModal({
						text: parse(message),
					})}
				>
					<Label
						text={
							<FormattedMessage id={'rod.account.create.who_are_you.title'} />
						}
						font="--gilroy-Medium-24"
						display="inline-flex"
						black
						className={styles.Title}
					/>
				</InfoButton>
				<RadioGroup
					name={'whoAreYou'}
					value={this.state.personalData.scope_group}
					onChange={this.whoAreYouChangeHandler}
					items={roles}
				/>
			</div>
		);
	}

	renderSignAs() {
		const {
			UIContext: { showModal },
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'who_am_I_sign_as')
		);
		return (
			<div className={styles.Block}>
				<InfoButton
					className={styles.infoButton}
					onClick={showModal({
						text: parse(message),
					})}
				>
					<Label
						text={<FormattedMessage id={'rod.account.create.sign_as.title'} />}
						font="--gilroy-Medium-24"
						display="inline-flex"
						black
						className={styles.Title}
					/>
				</InfoButton>
				<RadioGroup
					name={'signAs'}
					value={this.state.personalData.business_type}
					onChange={this.signAsChangeHandler}
					items={[
						{
							text: (
								<FormattedMessage
									id={'rod.account.create.sign_as.individual'}
								/>
							),
							value: accountTypes.PERSONAL,
						},
						{
							text: (
								<FormattedMessage
									id={'rod.account.create.sign_as.sole_proprietor'}
								/>
							),
							value: accountTypes.PERSONAL_ENTREPRENEUR,
						},
						{
							text: (
								<FormattedMessage
									id={'rod.account.create.sign_as.legal_entity'}
								/>
							),
							value: accountTypes.COMPANY,
						},
					]}
				/>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withLang)(WhoAmI);
