/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 23/09/2020, 15:24
 */
import { Button } from 'components/Buttons/Button';

import { Label } from 'components/Labels';
import { withUI } from 'hocs';
import { withAuth } from 'hocs';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './AccessDenied.module.css';

class AccessDenied extends Component {
	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	handleBack = () => {
		this.props.authContext.logout();
		// this.props.history.push('/');
	};

	render() {
		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.error_page.denied.header'} />
						</Label>
						<Label font="--gilroy-Medium-24" black>
							<FormattedMessage id={'rod.error_page.denied.body'} />
						</Label>
					</div>
					<div className={styles.Controls}>
						<Button
							className={styles.Button}
							text={<FormattedMessage id={'rod.action.to_index_page'} />}
							type={'submit'}
							variant={'primary'}
							onClick={this.handleBack}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default compose(withUI, withAuth, withRouter)(AccessDenied);
