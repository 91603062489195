/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */
// eslint-disable-next-line
const actions = {
	/* Actions */
	'rod.action.login': 'войти',
	'rod.action.register': 'зарегистрироваться',
	'rod.action.change_password': 'Изменить пароль',
	'rod.action.restore_password': 'восстановить пароль',
	'rod.action.send': 'Отправить',
	'rod.action.upload': 'Загрузить',
	'rod.action.upload_more': 'Загрузить еще',
	'rod.action.confirm': 'подтвердить',
	'rod.action.sign_confirm': 'подтвердить подписание',
	'rod.action.send_contract': 'отправить подписанный договор',
	'rod.action.sign_next': 'Подписать следующий',
	'rod.action.no_code': 'Не приходит код',
	'rod.action.send_request': 'Отправить заявку',
	'rod.action.back': 'назад',
	'rod.action.back_to_list': 'вернуться к списку',
	'rod.action.back_to_release': 'вернуться к релизу',
	'rod.action.create': 'создать новый',
	'rod.action.next': 'далее',
	'rod.action.save': 'Сохранить',
	'rod.action.select_conditions': 'выбрать условия присоединения',
	'rod.action.to_index_page': 'вернуться на главную',
	'rod.action.to_login': 'войти в аккаунт',
	'rod.action.sms.resend': 'выслать код повторно',
	'rod.action.complete': 'готово',
	'rod.action.goto_sign_contract': 'перейти к подписанию',
	'rod.action.goto_contract': 'вернуться к тексту договора',
	'rod.action.goto_repertoire': 'перейти в личный кабинет',
	'rod.menu.action.add_account': 'добавить еще аккаунт',
	'rod.menu.action.settings': 'настройки',
	'rod.menu.action.logout': 'выйти',
	'rod.menu.action.admin': 'админка',
	'rod.menu.action.admin_signups': 'запросы регистрации',

	'rod.action.create_single': 'создать',
	'rod.action.download': 'Скачать',
	'rod.action.export_release': 'выпустить релиз',
	'rod.action.close': 'Закрыть',
	'rod.action.add': 'Добавить',
	'rod.account.data.confirm.create': 'создать счет',

	'rod.action.go_to_invoices': 'перейти в “счета”',
	'rod.action.send_payment': 'отправить подписанный счет',

	//================= TOASTS =======================//

	'rod.release.toast.queue_for_shipment': 'Релиз в очереди на отгрузку',
	'rod.release.toast.queue_for_takendown': 'Релиз в очереди на снятие',
};
export default actions;
