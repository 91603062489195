import React, { useEffect, useState } from 'react';

import {
	StatisticByDatesSection,
	StatisticByTerritoriesSection,
	StatisticReports,
} from './StatisticCategory';

import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

import { FormattedHTMLMessage } from 'react-intl';

import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import { styled } from '@mui/material/styles';

import styles from './Statistic.module.css';

// import { accounts } from 'services';
import { useHistory, useRouteMatch } from 'react-router-dom';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: '#f0f0f0',
	color: 'var(--color-dark-grey)',
	font: 'var(--gilroy-Bold-14-17)',
	flex: '0 0 25%',
	padding: '13px 0',
	border: 'none',
	textAlign: 'center',
	margin: 0,
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		'& > *:first-child': {
			textDecoration: 'line-through',
		},
	},
	'& > *:first-child': {
		marginRight: '8px',
	},
}));

function Statistic(props) {
	// const lang = localStorage.getItem('lang');

	const [accountName, setAccountName] = useState('');

	const {
		UIContext: { showTitle, hideTitle, selectStatistic, setSelectedStatistic },
		authContext: { accountId },
		rootContext: { getMvpUserData },
	} = props;

	const match = useRouteMatch();

	const history = useHistory();

	useEffect(() => {
		if (selectStatistic) {
			setSelectedStatistic(selectStatistic.toLowerCase());
			if (props.isAdmin) {
				history.push(
					`/admin/v2/accounts/${
						props.accountId
					}/statistic/${selectStatistic.toLowerCase()}`
				);
			} else {
				history.push(
					`/accounts/${accountId}/statistic/${selectStatistic.toLowerCase()}`
				);
			}
		} else if (match.path.includes('bydate')) {
			setSelectedStatistic('bydate');
		} else if (match.path.includes('byterritory')) {
			setSelectedStatistic('byterritory');
			// } else if (match.path.includes('compare')) {
			// 	setSelectedStatistic('compare');
		} else if (match.path.includes('statistic-reports')) {
			setSelectedStatistic('statistic-reports');
		} else {
			setSelectedStatistic('bydate');
		}

		if (!accountName && props.isAdmin) {
			getMvpUserData(props.accountId).then((res) => {
				setAccountName(res.personal_data.title);
			});
		}
		if (!props.isAdmin) showTitle('rod.navbar.statistic');
		return function cleanup() {
			if (!props.isAdmin) hideTitle();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getLink = (name) => {
		switch (name) {
			case 'bydate':
				setSelectedStatistic('bydate');
				return history.push(
					props.isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/bydate`
						: `/accounts/${accountId}/statistic/bydate`
				);
			case 'byterritory':
				setSelectedStatistic('byterritory');
				return history.push(
					props.isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/byterritory`
						: `/accounts/${accountId}/statistic/byterritory`
				);
			case 'statistic-reports':
				setSelectedStatistic('statistic-reports');
				return history.push(
					props.isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/statistic-reports`
						: `/accounts/${accountId}/statistic/statistic-reports`
				);

			// case 'compare':
			// 	setSelectedStatistic('compare');
			// 	return history.push(`/accounts/${accountId}/statistic/compare`);
			default:
				history.push(
					props.isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/bydate`
						: `/accounts/${accountId}/statistic/bydate`
				);
				break;
		}
	};

	return (
		selectStatistic && (
			<TabsUnstyled defaultValue={selectStatistic} className={styles.statistic}>
				<TabsListUnstyled className={styles.statistic__header}>
					<HeaderButton
						className={styles.statistic__headerButton}
						value={'bydate'}
						onClick={() => getLink('bydate')}
					>
						<FormattedHTMLMessage id={'rod.statistic.by_date'} />
					</HeaderButton>
					<HeaderButton
						className={styles.statistic__headerButton}
						value={'byterritory'}
						onClick={() => getLink('byterritory')}
					>
						<FormattedHTMLMessage id={'rod.statistic.by_territory'} />
					</HeaderButton>
					<HeaderButton
						className={styles.statistic__headerButton}
						value={'statistic-reports'}
						onClick={() => getLink('statistic-reports')}
					>
						<FormattedHTMLMessage id={'rod.navbar.statistic_reports'} />
					</HeaderButton>
				</TabsListUnstyled>
				<div className={styles.statistic__main}>
					<TabPanelUnstyled value={'bydate'}>
						{props.isAdmin ? (
							<StatisticByDatesSection adminAccountId={props.accountId} />
						) : (
							<StatisticByDatesSection />
						)}
					</TabPanelUnstyled>
					<TabPanelUnstyled value={'byterritory'}>
						{props.isAdmin ? (
							<StatisticByTerritoriesSection adminAccountId={props.accountId} />
						) : (
							<StatisticByTerritoriesSection />
						)}
					</TabPanelUnstyled>
					<TabPanelUnstyled value={'statistic-reports'}>
						{props.isAdmin ? (
							<StatisticReports
								adminAccountId={props.accountId}
								accountName={accountName}
							/>
						) : (
							<StatisticReports />
						)}
					</TabPanelUnstyled>
					{/* <TabPanelUnstyled value={'compare'}>
						<StatisticCompareSection/>
					</TabPanelUnstyled> */}
				</div>
			</TabsUnstyled>
		)
	);
}

export default compose(withUI, withAuth, withRoot)(Statistic);
