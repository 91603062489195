// Core
import { useContext, useEffect, useState } from 'react';
import { dateFormatEditor } from 'logic';
import { Col, Row } from 'react-grid-system';
import { FormattedHTMLMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Context
import { RootContext } from 'contexts/RootContext';

// Components
import { Loading, NoData } from 'components';
import { Pagination } from 'material-design/components';
import download from 'images/download.svg';

// Styles
import styles from './UserPayments.module.css';
import tableStyles from '../UserReports/UserReports.module.css';

// Utils
import { checkPaymentStatus } from '../utils/check-payment-status.util';

const UserPayments = (props) => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [invoices, setPayments] = useState([]);
	const [accountId] = useState(localStorage.getItem('accountId'));

	const { getPaymentInvoice, getAccountPayments } = useContext(RootContext);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const { data, total } = await getAccountPayments(accountId, page);
			const notCompletedPayment = data.find((payment) => {
				return checkPaymentStatus(payment.status);
			});
			if (notCompletedPayment) {
				history.push(
					`./reports/sign?id=${notCompletedPayment.id}&status=${notCompletedPayment.status}`
				);
				return;
			}
			setPayments(data);
			setTotal(total);
			setLoading(false);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const handleDownload = async (paymentId) => {
		const invoiceData = await getPaymentInvoice(accountId, paymentId);
		if (invoiceData) {
			window.open(invoiceData, '_blank');
		}
	};

	return (
		<div className={`${tableStyles.mainWrapper} ${styles.payments__wrapper}`}>
			{loading ? (
				<Loading className={tableStyles.loader} />
			) : (
				<>
					{invoices.length ? (
						<>
							<div className={tableStyles.RepertoireSongInfo__mainInfoTable}>
								<Row className={tableStyles.row_header}>
									<Col width={'15%'}>
										<FormattedHTMLMessage
											id={'rod.account.payment.invoice_date'}
										/>
									</Col>
									<Col width={'40%'}>
										<FormattedHTMLMessage
											id={'rod.account.payment.invoice_title'}
										/>
									</Col>
									<Col width={'15%'}>
										<FormattedHTMLMessage
											id={'rod.field.admin.reports.status'}
										/>
									</Col>
									<Col width={'15%'}>
										<FormattedHTMLMessage id={'rod.account.payment.amount'} />
									</Col>

									<Col width={'10%'}></Col>
								</Row>
								{/* new, signing, signed, moderation, rejected, in_processing, paid */}
								<div className={tableStyles.RepertoireMainInfoTable__body}>
									<ul>
										{invoices.map((item, index) => (
											<li className={tableStyles.articlesListItem}>
												<Row className={tableStyles.row_data}>
													<Row className={tableStyles.row_title}>
														<Col width={'15%'} className={tableStyles.shell}>
															{item.created_at &&
																dateFormatEditor(item.created_at, 'dmy', '.')}
														</Col>
														<Col width={'40%'} className={tableStyles.shell}>
															{item.title}
														</Col>
														<Col
															width={'15%'}
															className={`${tableStyles.shell} ${
																item.status === 'rejected'
																	? tableStyles.rejected
																	: ''
															}`}
														>
															<FormattedHTMLMessage
																id={`rod.account.payment.status.${item.status}`}
															/>
														</Col>
														<Col width={'15%'} className={tableStyles.shell}>
															{`${item.sum.replace(
																/(\d)(?=(\d{3})+(?!\d))/g,
																`$1\u00A0`
															)} ${item.currency}` ?? '-'}
														</Col>

														<Col width={'10%'}>
															<div
																className={tableStyles.action}
																onClick={() => handleDownload(item.id)}
															>
																<img
																	src={download}
																	alt=""
																	// style={{ paddingBottom: '11px' }}
																/>
																<div className={tableStyles.actionTitle}>
																	<FormattedHTMLMessage
																		id={'rod.action.download'}
																	/>
																</div>
															</div>
														</Col>
													</Row>
												</Row>
											</li>
										))}
									</ul>
								</div>
							</div>
						</>
					) : (
						<NoData />
					)}
				</>
			)}
			{total > 10 && !loading && (
				<Pagination
					page={page}
					total={total}
					paginate={(pageNumber) => {
						setPage(pageNumber);
					}}
					items_on_page={10}
				/>
			)}
		</div>
	);
};

export default UserPayments;
