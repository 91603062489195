import React, { useState } from 'react';

import s from './InputWithClear.module.css';

import { Loop } from 'material-design/svg';

import filter_svg from 'images/filter.svg';
import filter_dot_svg from 'images/filter_dot.svg';
import { FormattedMessage } from 'react-intl';
import close from 'images/close_black.svg';

export default function InputWithClear({
	handleOnChange,
	setIsFocus,
	filter,
	setActive,
	curRef,
	parent,
	initialValue,
	placeholder,
	lowerCasePlaceholder,
}) {
	filter = filter ? filter : '';
	// поменять потом
	const [inputValue, setInputValue] = useState('');
	setIsFocus = setIsFocus ? setIsFocus : () => {};

	const inputChange = handleOnChange ? handleOnChange : () => {};
	const handleInput = (e) => {
		setInputValue(e.target.value);
		inputChange(e.target.value);
	};
	const handleClearInput = () => {
		setInputValue('');
		inputChange('');
	};
	return (
		<div className={s.repertoire__mainFilter}>
			{initialValue ? (
				<div
					className={`${s.repertoire__mainFilterInput} ${
						inputValue && s.inputFilled
					}`}
				>
					<FormattedMessage
						id={
							placeholder
								? placeholder
								: 'rod.repertoire.search_by_author_and_name'
						}
					>
						{(msg) => (
							<input
								className={`${lowerCasePlaceholder} ${
									s.inputLowercasePlaceholder
								} ${inputValue && s.inputFilled}`}
								value={initialValue}
								ref={curRef}
								type="text"
								placeholder={msg}
								// onChange={(e) => inputChange(e.target.value)}
								onChange={handleInput}
								onClick={() => setIsFocus(true)}
							/>
						)}
					</FormattedMessage>
					<Loop />
				</div>
			) : (
				<div
					className={`${s.repertoire__mainFilterInput} ${
						inputValue && s.inputFilled
					}`}
				>
					<FormattedMessage
						id={
							placeholder
								? placeholder
								: 'rod.repertoire.search_by_author_and_name'
						}
					>
						{(msg) => (
							<input
								className={`${lowerCasePlaceholder} ${
									s.inputLowercasePlaceholder
								} ${inputValue && s.inputFilled}`}
								ref={curRef}
								value={inputValue}
								type="text"
								placeholder={msg}
								// onChange={(e) => inputChange(e.target.value)}
								onChange={handleInput}
								onClick={() => setIsFocus(true)}
							/>
						)}
					</FormattedMessage>
					{inputValue.length > 0 && (
						<img
							src={close}
							alt="remove filter"
							className={s.removeIcon}
							onClick={handleClearInput}
						/>
					)}

					<Loop />
				</div>
			)}

			{parent === 'releaseSection' && (
				<img
					className={s.repertoire__filterImg}
					src={
						!Object.values(filter).some((i) => i.show === true)
							? filter_svg
							: filter_dot_svg
					}
					onClick={setActive}
					alt=""
				/>
			)}
		</div>
	);
}
