/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 22/01/2021, 16:03
 */

import api from '../interceptor';

// all functions are migrated to api.  02/06/2022
// import http from '../http';

export default class Accounts {
	static getSelfRoles() {
		return api.get(`/accounts/scopes/self`);
	}

	static addRoles(id, data) {
		return api.put(`/accounts/${id}/scopes`, data);
	}

	static getSharedRoles() {
		//++
		return api.get(`/accounts/scopes/shared`);
	}

	static getRoles(id) {
		//++
		return api.get(`/accounts/${id}/scopes-new`);
	}
	static getRolesForAddNew(id) {
		//++
		return api.get(`/accounts/${id}/scopes`);
	}

	static addAdditionalRoles(id, data) {
		//++ res is not used in requests
		return api.put(`/accounts/${id}/scopes/additional`, data);
	}

	static createAccount(data) {
		//++
		return api.post(`/accounts`, data);
		// .then((res) => console.log('res - createAccount ', res))
		// .catch((error) => {
		// 	console.log('accounts - createAccount error: ', error);
		// 	console.log(
		// 		'accounts - createAccount error.response: ',
		// 		error.response
		// 	);
		// });
	}

	static getAccountPersonalData(id) {
		//++
		return api.get(`/accounts/${id}`);
	}

	static updateAccountPersonalData(id, data) {
		//++ res is not used in requests
		return api.put(`/accounts/${id}`, data);
		// .then((res) => console.log('res - updateAccountPersonalData ', res))
		// .catch((error) => {
		// 	console.log('accounts - updateAccountPersonalData error: ', error);
		// 	console.log(
		// 		'accounts - updateAccountPersonalData error.response: ',
		// 		error.response
		// 	);
		// });
	}

	static updateAccountCompanyData(id, data) {
		//++ res is not used in requests
		return api.put(`/accounts/${id}`, data);
		// .then((res) => console.log('res - updateAccountCompanyData ', res))
		// .catch((error) => {
		// 	console.log('accounts - updateAccountCompanyData error: ', error);
		// 	console.log(
		// 		'accounts - updateAccountCompanyData error.response: ',
		// 		error.response
		// 	);
		// });
	}

	static updateAccountContactsData(id, data) {
		//++ res is not used in requests
		return api.put(`/accounts/${id}/contact-data`, data);
		// .then((res) => console.log('res - updateAccountContactsData ', res))
		// .catch((error) => {
		// 	console.log('accounts - updateAccountContactsData error: ', error);
		// 	console.log(
		// 		'accounts - updateAccountContactsData error.response: ',
		// 		error.response
		// 	);
		// });
	}

	static getAccountContactsData(id) {
		//++
		return api.get(`/accounts/${id}/contact-data`);
	}

	static updateAccountPaymentData(id, data) {
		//++ res is not used in requests
		return api.put(`/accounts/${id}/payments-info`, data);
	}

	static getAccountPaymentData(id) {
		//++
		return api.get(`/accounts/${id}/payments-info`);
	}

	static getAccountPseudonymsData(id) {
		//++
		return api.get(`/accounts/${id}/pseudonyms/settings`);
	}

	static createPseudonym = (accountId, data) => {
		return api.post(`/accounts/${accountId}/pseudonyms/settings`, data);
	};

	static getOnePseudonymData = (accountId, pseud_id) => {
		return api.get(`/accounts/${accountId}/pseudonyms/${pseud_id}/settings/`);
	};

	static updatePseudonym(accId, pseudonymId, body) {
		return api.put(
			`/accounts/${accId}/pseudonyms/${pseudonymId}/settings`,
			body
		);
	}

	static deletePseudonym(accId, pseudonymId) {
		return api.delete(`/accounts/${accId}/pseudonyms/${pseudonymId}/settings`);
	}

	static getAccountDocuments(id) {
		//++
		return api.get(`/accounts/${id}/files`);
	}

	static uploadAccountDocument(id, data) {
		//++
		return api.post(`/accounts/${id}/files`, data);
	}

	static deleteAccountDocument(id, fileId) {
		//++
		return api.delete(`/accounts/${id}/files/${fileId}`);
	}

	static acceptAccountDocuments(id) {
		//++ res in not used in requests.
		return api.post(`/accounts/${id}/files/accept`);
		// .then((res) => console.log('res - acceptAccountDocuments ', res))
		// .catch((error) => {
		// 	console.log('accounts - acceptAccountDocuments error: ', error);
		// 	console.log(
		// 		'accounts - acceptAccountDocuments error.response: ',
		// 		error.response
		// 	);
		// });
	}

	static sendAccountToModeration(id) {
		//++ res in not used in requests.
		return api.post(`/accounts/${id}/moderation`);
		// .then((res) => console.log('res - sendAccountToModeration ', res))
		// .catch((error) => {
		// 	console.log('accounts - sendAccountToModeration error: ', error);
		// 	console.log(
		// 		'accounts - sendAccountToModeration error.response: ',
		// 		error.response
		// 	);
		// });
	}

	static getAccountPseudonyms(id) {
		//++
		return api.get(`/accounts/${id}/identity`);
	}

	static postAccountSocieties(id, data) {
		//++
		return api.post(`/accounts/${id}/identity`, data);
	}

	static createAccountPseudonyms(id, data) {
		return api.post(`/accounts/${id}/pseudonyms/settings`, data);
	}

	static deleteAccountPseudonym(id, pseudonymId) {
		return api.delete(`/accounts/${id}/pseudonyms/${pseudonymId}/settings`);
	}

	static deleteAccountSociety(id, societyId) {
		//++  res in not used in requests.
		return api.delete(`/accounts/${id}/societies/${societyId}`);
	}

	static uploadRepertoire(id, fd) {
		//++
		return api.post(`/accounts/${id}/repertoire/upload`, fd);
	}

	static getAssetStatistics(id) {
		//+
		return api.get(`/accounts/${id}/asset-statistics`);
	}

	static getAssets(id, type = 'releases') {
		// missing in a code
		return api.get(`/accounts/${id}/assets?type=${type}`);
	}

	static getAssetsText(id, type = 'releases', text = 'qsq') {
		// missing in a code
		return api.get(`/accounts/${id}/assets?type=${type}&search=${text}`);
	}

	static getAssetsPageText(id, text = 'aqw', page = 1, type = 'releases') {
		const encodedText = encodeURIComponent(text);

		return api.get(
			`/accounts/${id}/assets?type=${type}&search=${encodedText}&page=${page}&limit=10`
		);
	}

	static getAssetsPage(id, type = 'releases', page = 1) {
		return api.get(`/accounts/${id}/assets?type=${type}&page=${page}&limit=10`);
	}

	static getAssetsPageTextByStatus(
		id,
		text = 'aqw',
		page = 1,
		type = 'releases',
		status
	) {
		// ++
		return api.get(
			`/accounts/${id}/assets?type=${type}&search=${text}&status=${status}&page=${page}&limit=10`
		);
	}

	static getAssetsPageByStatus(id, type = 'releases', page = 1, status) {
		return api.get(
			`/accounts/${id}/assets?type=${type}&status=${status}&page=${page}&limit=10`
		);
	}

	static getReleasesPageByStatus(id, status = 'ready', page = 1) {
		return api.get(
			`/accounts/${id}/assets/releases?status=${status}&page=${page}&limit=10`
		);
	}

	static getCompositionsPageByStatus(id, status = 'ready', page = 1) {
		return api.get(
			`/accounts/${id}/assets/compositions?status=${status}&page=${page}&limit=10`
		);
	}

	static getRecordingsPageByStatus(id, status = 'ready', page = 1) {
		return api.get(
			`/accounts/${id}/assets/recordings?status=${status}&page=${page}&limit=10`
		);
	}

	// static getAssetsPageTextByStatus(
	// 	id,
	// 	text = 'aqw',
	// 	status = 'ready',
	// 	page = 1,
	// 	type = 'releases'
	// ) {
	// 	// ++
	// 	// /api/accounts/149916/assets/releases/
	// 	return api.get(
	// 		`/accounts/${id}/assets?type=${type}&status=${status}&search=${text}&page=${page}&limit=10`
	// 	);
	// }

	static getReleasesPageTextByStatus(
		id,
		text = 'aqw',
		status = 'ready',
		page = 1
	) {
		// ++
		// /api/accounts/149916/assets/releases?status=ready&search=kar&page=1&limit=10
		return api.get(
			`/accounts/${id}/assets/releases?status=${status}&search=${text}&page=${page}&limit=10`
		);
	}

	static getAssetsDrafts(id, page = 1) {
		return api.get(
			`/accounts/${id}/assets/drafts/all?limit=10&page=${page}&enable_complete=true`
		);
	}

	static getAssetsDraftsText(id, text = 'aqw', page = 1) {
		const encodedText = encodeURIComponent(text);

		return api.get(
			`/accounts/${id}/assets/drafts/all?search=${encodedText}&page=${page}&limit=10&enable_complete=true`
		);
	}

	static deleteDraft(draftType, draftId) {
		return api.delete(`/assets/draft/${draftType}/${draftId}/remove`);
	}

	static getDistributionChannels() {
		// missing in a code
		return api.get(`/dictionaries/distribution-channels`);
	}

	static addRecording(id, file, config) {
		return api.post(`/repertoire/release/${id}/recording/upload`, file, config);
	}

	static getRecordingList(id) {
		//+
		return api.get(`/repertoire/release/${id}/recording/list`);
	}

	static sortRecordings(id, arr) {
		//++  res in not used in requests.
		return api.post(`/repertoire/release/${id}/reorder`, arr);
	}

	static deleteRecording(id, recId) {
		return api.delete(`/repertoire/release/${id}/recording/${recId}/remove`);
	}

	static uploadRecordingInfo(id, recId, data) {
		//+
		return api.put(`/repertoire/release/${id}/recording/${recId}`, data);
	}

	static uploadRecordingCompositionInfo(id, recId, data) {
		//+
		return api.post(
			`/repertoire/release/${id}/recording/${recId}/composition`,
			data
		);
	}

	static getReleaseData(id) {
		//+
		return api.get(`/repertoire/release/${id}/data`);
	}

	static getReleaseCompositionData(id, recId) {
		// missing in a code
		return api.get(`/repertoire/release/${id}/recording/${recId}/data`);
	}

	static getFeatures() {
		//++
		return api.get('/features');
	}

	static createRelease(data) {
		return api.post('/repertoire/release', data);
	}

	static createAdditionalRelease(data) {
		return api.post('/repertoire/release/additional', data);
	}

	static updateAdditionalRelease(data) {
		return api.put(`/repertoire/release/additional/${data.id}`, data);
	}

	static deleteAdditionalRelease(id) {
		return api.delete(`/assets/draft/release/${id}/remove`);
	}

	static updateRelease(releaseId, data) {
		//+
		return api.put(`/repertoire/release/${releaseId}`, data);
	}

	static updateStep(releaseId, data) {
		//+
		return api.post(`/repertoire/release/${releaseId}/step`, data);
	}

	static getGenres() {
		//++
		return api.get('/dictionaries/genres?object_type=music');
	}

	static getGenresWithoutType() {
		return api.get('/dictionaries/genres');
	}

	static getReleaseTypes(lang) {
		//+
		return api.get(
			`/dictionaries/release-types?category=audio&language=${lang}`
		);
	}

	static getReleaseTypesAll(lang) {
		//+
		return api.get(
			`/dictionaries/release-types?category=audio&language=${lang}&all=true`
		);
	}

	static getLanguages() {
		return api.get(`/dictionaries/languages`);
	}

	static setReleaseCover(id, file, config) {
		return api.post(`/repertoire/release/${id}/cover/upload`, file, config);
	}

	static deleteReleaseCover(releaseId) {
		//+
		return api.delete(`/repertoire/release/${releaseId}/cover/remove`);
	}

	static getOutlets(lang = 'en') {
		//+
		return api.get(`/dictionaries/outlets?language=${lang}`);
	}

	static getOutletsWithTypes(lang = 'en', releaseTypeId) {
		//+
		return api.get(
			`/dictionaries/outlets?language=${lang}&release_type_id=${releaseTypeId} `
		);
	}

	static setDistribution(data, releaseId) {
		//+
		return api.post(`/repertoire/release/${releaseId}/distribution`, data);
	}

	static getDistribution(releaseId) {
		//+
		return api.get(`/repertoire/release/${releaseId}/distribution/data`);
	}
	static sendModerateRelease(releaseId) {
		//+
		return api.post(`/repertoire/release/${releaseId}/send-moderate`);
	}

	//===========COMPOSITIONS===============

	static getCompositionData(compositionId) {
		//++
		return api.get(`/repertoire/composition/${compositionId}/data`);
	}
	static createComposition(data) {
		//++
		return api.post('/repertoire/composition', data);
	}
	static updateComposition(compositionId, data) {
		//++
		return api.put(`/repertoire/composition/${compositionId}`, data);
	}
	static uploadCompositionRecording(compositionId, data) {
		//+
		return api.post(`/repertoire/composition/${compositionId}/recording`, data);
	}
	static uploadCompositionPerformed(compositionId, data) {
		//+
		return api.post(`/repertoire/composition/${compositionId}/performed`, data);
	}
	static updateCompositionRecording(compositionId, recordingId, data) {
		//+
		return api.put(
			`/repertoire/composition/${compositionId}/recording/${recordingId}`,
			data
		);
	}
	static deleteCompositionRecording(compositionId, recordingId) {
		//+
		return api.delete(
			`/repertoire/composition/${compositionId}/recording/${recordingId}/remove`
		);
	}

	static updateCompositionStep(compositionId, data) {
		//++
		return api.post(`/repertoire/composition/${compositionId}/step`, data);
	}

	static uploadCompositionContributors(compositionId, data) {
		return api.post(
			`/repertoire/composition/${compositionId}/contributors`,
			data
		);
	}
	static addCompositionLyrics(compositionId, data) {
		return api.put(`/repertoire/composition/${compositionId}/lyrics`, data);
	}

	static getReleaseInfoForBrowsing(id, release_id) {
		return api.get(`accounts/${id}/assets/releases/${release_id}`);
	}

	static getReleaseCoverForBrowsing(id, release_id) {
		return api.get(`accounts/${id}/releases/${release_id}/cover`);
	}

	static getReleaseTracksForBrowsing(id, release_id) {
		return api.get(`accounts/${id}/releases/${release_id}/tracks`);
	}
	//repertoire-releases Moderation
	static getReleaseModeration(accountId, releaseId) {
		return api.get(`/accounts/${accountId}/releases/${releaseId}/moderation`);
	}

	static getReleaseNotices(accountId, releaseId, lang) {
		return api.get(
			`/accounts/${accountId}/releases/${releaseId}/notices?crucial=false&language=${lang}`
		);
	}
	static getReleaseDelivery(accountId, releaseId) {
		return api.get(
			`/accounts/${accountId}/releases/${releaseId}/outlets/deliveries`
		);
	}

	static getRecordingMetadata(accountId, releaseId) {
		return api.get(`/accounts/${accountId}/recordings/${releaseId}`);
	}
	static getCompositionMetadata(accountId, releaseId) {
		return api.get(`/accounts/${accountId}/compositions/${releaseId}`);
	}

	static getRecordingCover(accountId, releaseId) {
		return api.get(`/accounts/${accountId}/recordings/${releaseId}/cover`);
	}

	static getRecordingAudio(accountId, releaseId) {
		return api.get(`/accounts/${accountId}/recordings/${releaseId}/audio`);
	}
	static getRecordingIssues(accountId, releaseId, lang) {
		return api.get(
			`/accounts/${accountId}/recordings/${releaseId}/notices?crucial=false&language=${lang}`
		);
	}
	static getRecordingReleases(accountId, releaseId) {
		return api.get(`/accounts/${accountId}/recordings/${releaseId}/releases`);
	}

	static getCompositionReleases(accountId, compositionId) {
		return api.get(
			`/accounts/${accountId}/compositions/${compositionId}/releases`
		);
	}

	static getRecordingOwnership(
		accountId,
		recordingId,
		countryId,
		exploitationId,
		rightId = '' /* query[right_id] */,
		outletId = '' /* query[outlet_id] */,
		productId = '' /* query[product_id] */,
		dateFrom,
		lang = 'en'
	) {
		//`/accounts/${accountId}/compositions/${releaseId}/ownership?query[country_id=3]&query[exploitation_type_id]=2&query[begins]=03.08.2022`
		return api.get(
			`/accounts/${accountId}/recordings/${recordingId}/ownership?query[country_id]=${countryId}&query[exploitation_type_id]=${exploitationId}&query[right_id]=${rightId}&query[outlet_id]=${outletId}&query[product_id]=${productId}&query[begins]=${dateFrom}&language=${lang}`
		);
	}
	static getCompositionOutlets(
		accountId,
		releaseId,
		lang = 'en',
		countryId,
		exploitationId,
		rightId
	) {
		return api.get(
			`/accounts/${accountId}/compositions/${releaseId}/ownership-outlets?language=${lang}&query[country_id]=${countryId}&query[exploitation_type_id]=${exploitationId}&query[right_type_id]=${rightId}`
		);
	}
	static getRecordingProducts(accountId, releaseId, id, lang = 'en') {
		return api.get(
			`/accounts/${accountId}/recordings/${releaseId}/ownership-outlets/${id}/products?language=${lang}`
		);
	}

	static getCompositionOwnership(
		accountId,
		compositionId,
		countryId,
		exploitationId,
		rightId = '' /* query[right_id] */,
		outletId = '' /* query[outlet_id] */,
		productId = '' /* query[product_id] */,
		dateFrom,
		lang = 'en'
	) {
		//`/accounts/${accountId}/compositions/${releaseId}/ownership?query[country_id=3]&query[exploitation_type_id]=2&query[begins]=03.08.2022`
		return api.get(
			`/accounts/${accountId}/compositions/${compositionId}/ownership?query[country_id]=${countryId}&query[exploitation_type_id]=${exploitationId}&query[right_id]=${rightId}&query[outlet_id]=${outletId}&query[product_id]=${productId}&query[begins]=${dateFrom}&language=${lang}`
		);
	}
	static getRecordingOutlets(
		accountId,
		releaseId,
		lang = 'en',
		countryId,
		exploitationId,
		rightId
	) {
		return api.get(
			`/accounts/${accountId}/recordings/${releaseId}/ownership-outlets?language=${lang}&query[country_id]=${countryId}&query[exploitation_type_id]=${exploitationId}&query[right_type_id]=${rightId}`
		);
	}
	static getCompositionProducts(accountId, releaseId, id, lang = 'en') {
		return api.get(
			`/accounts/${accountId}/compositions/${releaseId}/ownership-outlets/${id}/products?language=${lang}`
		);
	}
	static getCompositionRecordings(accountId, compositionId) {
		return api.get(
			`/accounts/${accountId}/compositions/${compositionId}/recordings`
		);
	}
	static getCompositionIssues(accountId, compositionId, lang) {
		return api.get(
			`/accounts/${accountId}/compositions/${compositionId}/notices?crucial=false&language=${lang}`
		);
	}
	static getFeaturesFlagsForStatistics(accountId) {
		return api.get(`/accounts/${accountId}/features`);
	}

	static getAccountStatisticOutlets = () => {
		return api.get(`/stat/v1/statistics/outlets`);
	};

	static getAccountStatisticTopTracks = (
		isAdmin,
		accountId,
		page = 1,
		dateFrom,
		dateTo,
		outlets,
		limit = 10,
		recordingIds = []
	) => {
		// return api.get(
		// 	`https://rod-statistics.serviceaccount.org/api/v1/accounts/${accountId}/statistics/top?page=${page}&limit=${limit}`
		// );
		return api.get(
			`/stat/v1/${
				isAdmin ? 'admin/' : ''
			}accounts/${accountId}/statistics/top/?outlets=[${outlets}]&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&limit=${limit}&recordingIds=[${recordingIds}]`
		);
	};

	static getAccountStatisticAllTracks = (
		isAdmin,
		accountId,
		search = '',
		page = '',
		limit = '',
		dateFrom = '',
		dateTo = ''
	) => {
		return api.get(
			`/stat/v1/${
				isAdmin ? 'admin/' : ''
			}accounts/${accountId}/statistics/recordings/?page=${page}&limit=${limit}&search=${search}&dateFrom=${dateFrom}&dateTo=${dateTo}`
		);
	};

	static getAccountStatisticStreams = (
		isAdmin,
		accountId,
		dateFrom,
		dateTo,
		outlets = [],
		recordingIds = [],
		all = true
	) => {
		return api.get(
			`/stat/v1/${
				isAdmin ? 'admin/' : ''
			}accounts/${accountId}/statistics/streams/?outlets=[${outlets}]&dateFrom=${dateFrom}&dateTo=${dateTo}&recordingIds=[${recordingIds}]&all=${all}`
		);
	};

	static getAccountStatisticMap = (isAdmin, accountId) => {
		return api.get(
			`/stat/v1/${isAdmin ? 'admin/' : ''}accounts/${accountId}/statistics/map`
		);
	};

	static getAccountStatisticMapByDate = (
		isAdmin,
		accountId,
		dateFrom,
		dateTo,
		outlets = ['apple', 'spotify', 'ok', 'vk', 'zvuk', 'yandex'],
		recordingIds = [],
		all = true
	) => {
		return api.get(
			`/stat/v1/${
				isAdmin ? 'admin/' : ''
			}accounts/${accountId}/statistics/map/?outlets=[${outlets}]&dateFrom=${dateFrom}&dateTo=${dateTo}&recordingIds=[${recordingIds}]&all=${all}`
		);
	};

	static getCompositionsOriginRightholders = (accountId, compositionId) => {
		return api.get(
			`/accounts/${accountId}/compositions/${compositionId}/original-rightholders`
		);
	};

	static getRecordingsOriginRightholders = (accountId, recordingId) => {
		return api.get(
			`/accounts/${accountId}/recordings/${recordingId}/original-rightholders`
		);
	};

	static getRecordingsRevenue = (
		accountId,
		recordingId,
		partyId,
		{
			country_id,
			exploitationTypeID,
			rightTypeID,
			outletID,
			productID,
			dateFrom,
		}
	) => {
		return api.get(
			`/accounts/${accountId}/recordings/${recordingId}/revenue/${partyId}?query[country_id]=${country_id}&query[exploitation_type_id]=${exploitationTypeID}&query[right_id]=${rightTypeID}&query[outlet_id]=${outletID}&query[product_id]=${productID}&query[begins]=${dateFrom}`
		);
	};
	static getCompositionsRevenue = (
		accountId,
		compositionId,
		partyId,
		{
			country_id,
			exploitationTypeID,
			rightTypeID,
			outletID,
			productID,
			dateFrom,
		}
	) => {
		return api.get(
			`/accounts/${accountId}/compositions/${compositionId}/revenue/${partyId}?query[country_id]=${country_id}&query[exploitation_type_id]=${exploitationTypeID}&query[right_id]=${rightTypeID}&query[outlet_id]=${outletID}&query[product_id]=${productID}&query[begins]=${dateFrom}`
		);
	};

	static getFaqList = (lang = 'ru') => {
		return api.get(`/faq/list?lang=${lang}`);
	};
	static getFaqSearchByText = (text, lang = 'ru') => {
		// https://api-c.techrod.pro/api/faq/search?lang=ru&search=%D1%81%D1%82%D0%B0%D1%82
		return api.get(`/faq/search?lang=${lang}&search=${text}`);
	};
	static getFaqSearchBySectionId = (sectionId, lang = 'ru') => {
		//https://api-c.techrod.pro/api/faq/4?lang=ru
		return api.get(`/faq/${sectionId}?lang=${lang}`);
	};
	static getFaqSearchByArticleId = (sectionId, articleId, lang = 'ru') => {
		//https://api-c.techrod.pro/api/faq/4?lang=ru
		return api.get(`/faq/${sectionId}/${articleId}?lang=${lang}`);
	};

	static getRecordingsCompositions = (accountId, recordingId) => {
		return api.get(
			`/accounts/${accountId}/recordings/${recordingId}/compositions`
		);
	};

	//==========statistic reports - uploading============
	static createStatisticAccountReport = (
		accountId,
		dateFrom,
		dateTo,
		outlets = []
	) => {
		return api.post(
			`/stat/v1/statistics/accounts/${accountId}/report?outlets=[${outlets}]&dateFrom=${dateFrom}&dateTo=${dateTo}`
		);
	};

	static getRespStatisticAccountReports = (accountId) => {
		return api.get(`/stat/v1/statistics/accounts/${accountId}/reports`);
	};

	static getStatisticAccountReportFile = (accountId, id) => {
		return api.get(`/stat/v1/statistics/accounts/${accountId}/report/${id}`);
	};

	static createStatisticAdminReport = (
		accountId,
		dateFrom,
		dateTo,
		outlets = []
	) => {
		return api.post(
			`/stat/v1/statistics/admin/report?accountId=${accountId}&outlets=[${outlets}]&dateFrom=${dateFrom}&dateTo=${dateTo}`
		);
	};
	static getRespStatisticAdminReports = (accountId) => {
		return api.get(`/stat/v1/statistics/admin/reports?accountId=${accountId}`);
	};

	static getStatisticAdminReportFile = (accountId, id) => {
		return api.get(
			`/stat/v1/statistics/admin/report/${id}?accountId=${accountId}`
		);
	};

	static getUserNotifications = (accountId) => {
		return api.get(`/accounts/${accountId}/notification`);
	};

	static putReadUserNotification = (accountId, id) => {
		return api.put(`/accounts/${accountId}/notification/${id}`);
	};

	//===================================================

	// ================= REPORTS ========================

	static getAccountReports = (accountId, page = 1) => {
		return api.get(`/accounts/${accountId}/reports?page=${page}&limit=10`);
	};

	static getAccountPaymentsInfo = (accountId) => {
		return api.get(`/accounts/${accountId}/payments/info`);
	};

	static aboutReportsDownloadData = (accountId, reportArchive) => {
		return api.post(`/accounts/${accountId}/download/${reportArchive}`);
	};

	//================== PAYMENTS ======================

	static getAccountPayments = (accountId, page, limit) => {
		return api.get(
			`/accounts/${accountId}/payments?page=${page}&limit=${limit}`
		);
	};

	static getAccountPaymentInvoice = (accountId, paymentId) => {
		return api.get(`/accounts/${accountId}/payments/${paymentId}/download`);
	};

	static createInvoice = (accountId, signType = null) => {
		if (signType) {
			return api.post(`/accounts/${accountId}/payments?sign=${signType}`);
		}
		return api.post(`/accounts/${accountId}/payments`);
	};

	static getPaymentDataById = (accountId, paymentId) => {
		return api.get(`/accounts/${accountId}/payments/${paymentId}`);
	};

	static acceptPaymentById = (accountId, paymentId) => {
		return api.post(`/accounts/${accountId}/payments/${paymentId}/accept`);
	};

	static changePaymentSign = (accountId, paymentId) => {
		return api.post(`/accounts/${accountId}/payments/${paymentId}/change/sign`);
	};

	static signPayment = (accountId, paymentId, code = null, file = null) => {
		if (code) {
			return api.post(
				`/accounts/${accountId}/payments/${paymentId}/sign?code=${code}`
			);
		}

		const formData = new FormData();
		formData.append('file', file);

		return api.post(
			`/accounts/${accountId}/payments/${paymentId}/sign`,
			formData
		);
	};

	static changePaymentStatus = (paymentId, quality, signature, valid) => {
		return api.post(
			`/admin/payment/${paymentId}/status?quality=${quality}&signature=${signature}&valid=${valid}`
		);
	};

	// =============== Relase Shipments/Takedowns =====================

	static getReleaseShipments = (accountId, releaseId) => {
		return api.get(
			`/accounts/${accountId}/releases/${releaseId}/outlets/shippings`
		);
	};
	static getReleaseTakedowns = (accountId, releaseId) => {
		return api.get(
			`/accounts/${accountId}/releases/${releaseId}/outlets/takedowns`
		);
	};
	static postReleaseUpdatedShipments = (accountId, releaseId, outlets) => {
		return api.post(
			`/accounts/${accountId}/releases/${releaseId}/queues/shippings`,
			outlets
		);
	};
	static postReleaseUpdatedTakedowns = (accountId, releaseId, outlets) => {
		return api.post(
			`/accounts/${accountId}/releases/${releaseId}/queues/takedowns`,
			outlets
		);
	};
	static getAvailableServices = (accountId) => {
		return api.get(`/accounts/${accountId}/services`);
	};
	static postAdditionalServices = (accountId, data) => {
		return api.post(`/accounts/${accountId}/services`, data);
	};
	//===================================================
	static getRepertoireDataByCiType = (id, any, ciType, page = 1, text) => {
		// any = recordings, compositions
		// ciType = active, expired
		return text
			? api.get(
					`/accounts/${id}/assets/${any}/${ciType}?page=${page}&limit=10&search=${text}`
			  )
			: api.get(
					`/accounts/${id}/assets/${any}/${ciType}?page=${page}&limit=10`
			  );
	};
}
