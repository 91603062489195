// Core
import { FormattedMessage } from 'react-intl';

// Data
import { additionalReleaseTypes } from './releaseTypes';

// Helpers
import { showError } from 'validators/showError';

// UI
import { FormInput } from 'components/Form/FormInput';

// Styles
import s from './Types.module.css';

const Types = ({
	additionalRelease,
	changeField,
	additionalErrors,
}) => {
	return (
		<div className={s.item}>
			<div className={s.input}>
				<FormInput
					type={'MuiSelectReleaseType'}
					name={'release_type_id'}
					onChange={() => changeField('release_type_id', additionalRelease)}
					errors={
						additionalErrors.id === additionalRelease.id ? additionalErrors : []
					}
					data={additionalRelease}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.relise_type'}
						/>
					}
					items={additionalReleaseTypes}
				/>
				{additionalErrors.id === additionalRelease.id &&
					Object.keys(additionalErrors).includes('release_type_id') && (
						<span className={s.Helper}>
							{showError(additionalErrors.release_type_id[0].rule)(
								additionalErrors.release_type_id[0]
							)}
						</span>
					)}
			</div>
		</div>
	);
};

export default Types;
