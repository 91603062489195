import s from './LoaderWhithoutProgress.module.css';

const LoaderWhithoutProgress = () => {
	return (
		<svg width="30px" height="30px" viewBox="0 0 128 128">
			<path
				d="M64 127.75A63.76 63.76 0 0 1 52.8 1.23v14.23a49.8 49.8 0 1 0 22.4 0V1.23A63.76 63.76 0 0 1 64 127.75z"
				className={s.loader}
			/>
		</svg>
	);
};

export default LoaderWhithoutProgress;
