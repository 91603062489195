import { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Components
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { FormInput } from 'components/Form';
import { Label } from 'components/Labels';
import Loading from 'components/Loading';
import Table from 'components/Table/Table';

// Constants
import accountTypes from 'constants/accountTypes';
import paymentTypes from 'constants/paymentTypes';

// Contexts
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Styles
import styles from './DataConfirm.module.css';

// Helpers
import { Divider } from '@mui/material';
import { personalDataHelper } from './utils/personal-data.util';
import { checkPaymentStatus } from '../utils/check-payment-status.util';

const DataConfirm = (props) => {

	const checkboxRef = useRef(null);
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [buttonLoading, setIsButtonLoading] = useState(false);
	const { showTitle, showBackNavi, hideBackNavi, hideTitle } = useContext(UIContext);
	const { getCountryById, getAccountPaymentData, getAccountPersonalData, getContractSignTypes, createInvoice, getAccountContactsData, getAccountPayments } = useContext(RootContext);
	const [personalData, setPersonalData] = useState(null);
	const [paymentInfo, setPaymentInfo] = useState(null);
	const [contactInfo, setContactInfo] = useState(null);

	const [personalSectionData, setPersonalSectionData] = useState([]);
	const [paymentsSectionData, setPaymentsSectionData] = useState([]);
	const [taxFormSectionData, setTaxFormSectionData] = useState([]);
	const [contractSignTypes, setContractSignTypes] = useState([]);

	const [signType, setSignType] = useState(null);
	const [checked, setChecked] = useState(null);

	const accountId = localStorage.getItem('accountId');


	useEffect(() => {
		const fetchUserData = async () => {
			const { data } = await getAccountPayments(accountId, 1, 1);
			if (data.length) {
				const notCompletedPayment = data.find((payment) => {
					return checkPaymentStatus(payment.status);
				});
				if (notCompletedPayment) {
					history.push(`../reports/sign?id=${notCompletedPayment.id}&status=${notCompletedPayment.status}`);
					return;
				}
			}
			const { data: paymentData } = await getAccountPaymentData(accountId);
			const { data: userData } = await getAccountPersonalData(accountId);
			const { data: contactData } = await getAccountContactsData(accountId);
			const signTypes = await getContractSignTypes();
			setContractSignTypes(signTypes);
			setPersonalData(userData.data);
			setPaymentInfo(paymentData.data);
			setContactInfo(contactData.data);
			setIsLoading(false);
		}

		setIsLoading(true);
		fetchUserData();
		showTitle('rod.navbar.withdraw');
		showBackNavi(`/accounts/${accountId}/reports`);

		return () => {
			hideBackNavi();
			hideTitle();
		};
	}, []);

	useEffect(() => {
		if (personalData && contactInfo) {
			if (personalData?.business_type === accountTypes.COMPANY) {
				setPersonalSectionData([
					...personalDataHelper(personalData.business_type, personalData, getCountryById),
					{
						title: <FormattedHTMLMessage id={'rod.account.data.confirm.company_address'} />,
						value: contactInfo.address,
					},
				]);
			} else {
				setPersonalSectionData(personalDataHelper(personalData.business_type, personalData, getCountryById));
			}

			let id = 'rod.field.personal_entrepreneur.tax_number';
			if (personalData?.business_type === accountTypes.COMPANY) {
				id = 'rod.field.company.tax_number_register';
			}
			setTaxFormSectionData([
				{
					title: <FormattedHTMLMessage id={id} />,
					value: personalData.itin ?? '-',
				},
			])
		}
	}, [personalData, contactInfo]);

	useEffect(() => {
		if (paymentInfo) {
			if (paymentInfo.type === paymentTypes.PAYPAL) {
				setPaymentsSectionData([
					{
						title: <FormattedHTMLMessage id={'rod.field.paypal_account'} />,
						value: paymentInfo.paypal,
					},
				]);
			} else {
				setPaymentsSectionData([
					{
						title: <FormattedHTMLMessage id={'rod.field.beneficiary_name'} />,
						value: paymentInfo.bank_account_title,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_account_name'} />,
						value: paymentInfo.bank_account_number,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.swift_code'} />,
						value: paymentInfo.bank_account_swift,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_name'} />,
						value: paymentInfo.bank_title,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_country'} />,
						value: getCountryById(paymentInfo.bank_country_id, false),
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_address'} />,
						value: paymentInfo.bank_address,
					},
				]);
			}
		}
	}, [paymentInfo]);


	const handleConfirm = async () => {
		setIsButtonLoading(true);
		try {
			const data = await createInvoice(accountId, signType);
			setIsButtonLoading(false);
			history.push(`/accounts/${accountId}/reports/sign?id=${data.id}`);
		} catch (error) {
			console.info('Error', error)
		} finally {
			setIsButtonLoading(false);
		}

	}

	const renderSection = (title, data) => {
		return (
			<Table
				className={styles.Table}
				noBorder
				title={title}
				body={data.map((item) => (
					<tr>
						<td className="title">{item.title}</td>
						<td className="value">{item.value}</td>
					</tr>
				))}
			/>
		);
	};

	const changeSignType = (field) => (e) => {
		setSignType(e);
	};

	if (isLoading) {
		return <Loading non_margin={true} />;
	};

	return (
		<div className={styles.Form}>
			<Container fluid>
				<Row>
					<Col sm={10} offset={{ sm: 1 }}>
						<Label
							text={
								<FormattedHTMLMessage
									id={'rod.account.data.confirm_title'}
								/>
							}
							black
							font="--gilroy-Medium-40"
						/>

						<Label
							text={
								<FormattedHTMLMessage
									id={'rod.account.data.confirm_description'}
								/>
							}
							black
							font="--gilroy-Medium-18"
						/>
						{personalData?.business_type === accountTypes.PERSONAL &&
							renderSection(
								<FormattedHTMLMessage
									id={'rod.account.data.confirm.account'}
								/>,
								personalSectionData
							)}

						{personalData?.business_type ===
							accountTypes.PERSONAL_ENTREPRENEUR &&
							renderSection(
								<FormattedHTMLMessage
									id={'rod.account.data.confirm.personal_entrepreneur'}
								/>,
								personalSectionData
							)}

						{personalData?.business_type === accountTypes.COMPANY &&
							renderSection(
								<FormattedHTMLMessage
									id={'rod.account.data.confirm.company'}
								/>,
								personalSectionData
							)}
						{renderSection(
							<FormattedHTMLMessage
								id={'rod.account.data.confirm.tax_form'}
							/>,
							taxFormSectionData
						)}
						{renderSection(
							<FormattedHTMLMessage
								id={'rod.account.data.confirm.payments'}
							/>,
							paymentsSectionData
						)}

						<div className={styles.Controls}>
							<Label
								text={
									<FormattedHTMLMessage
										id={'rod.account.data.confirm.sign_type'}
									/>
								}
								black
								font="--gilroy-Bold-24"
							/>
							{contractSignTypes.length > 0 && (
								<FormattedMessage
									id={'rod.field.contracts.sign_type.placeholder'}
								>
									{(placeholder) => (
										<FormInput
											type={'dropdown'}
											placeholder={placeholder}
											name={'sign'}
											className="input"
											defaultEmpty
											onChange={changeSignType}
											errors={{}}
											data={{}}
											required
											items={contractSignTypes}
											getItem={(item) => (
												<FormattedHTMLMessage
													id={`rod.field.contracts.sign_types.${item.title}`}
												/>
											)}
											getListItem={(item) => (
												<FormattedHTMLMessage
													id={`rod.field.contracts.sign_types.${item.title}`}
												/>
											)}
											getItemValue={(item) => item.id}
										/>
									)}
								</FormattedMessage>
							)}
							<Checkbox
								className={styles.Checkbox}
								required
								ref={checkboxRef}
								onChange={() => setChecked(checkboxRef.current.checked)}
							>
								<FormattedMessage id={'rod.field.confirm.checkbox'} />
							</Checkbox>
						</div>
					</Col>
					<Divider sx={{ marginBottom: '16px', width: '100%' }} />
					<Col sm={10} offset={{ sm: 1 }}>
						<div className={styles.submit__page}>
							<Button
								text={<FormattedHTMLMessage id={'rod.account.data.confirm.create'} />}
								variant={'primary'}
								disabled={!(signType && checked) || buttonLoading}
								onClick={handleConfirm}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}


export default DataConfirm;
