// Core
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Helpers
import { changeSnippet } from '../../helpers/changeSnippet';

// UI
import { InfoTitle } from 'components';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

// Styles
import styles from './Fragment.module.css';

const Fragment = ({
	additionalRelease,
	additionalErrors,
	setAdditionalErrors,
	additionalReleases,
	setAdditionalReleases,
}) => {
	const checkSnippetErrors = (field) => {
		switch (field) {
			case 'snippet_start':
				return (
					<FormattedMessage
						id={`rod.release_create_snipped.${additionalErrors.snippet_start[0].rule}`}
					/>
				);

			default:
				break;
		}
	};

	return (
		<>
			<InfoTitle
				title="rod.release.create.step.tracks.fragment.title"
				helpTitle="rod.release.create.step.tracks.fragment.help"
			/>
			<div className={`${styles.form__section} ${styles.third__section}`}>
				<div className={styles.input}>
					<InputMask
						mask={'99:99:999'}
						maskChar={'0'}
						name={'snippet_start'}
						onChange={(e) =>
							changeSnippet(
								e,
								additionalRelease,
								additionalErrors,
								setAdditionalErrors,
								additionalReleases,
								setAdditionalReleases
							)
						}
						errors={additionalErrors}
						value={
							additionalRelease.snippet_start
								? additionalRelease.snippet_start
								: ''
						}
					>
						{() => (
							<TextFieldInput
								type="muiInput"
								variant="standard"
								name={'snippet_start'}
								required
								errors={additionalErrors}
								value={
									additionalRelease.snippet_start
										? additionalRelease.snippet_start
										: ''
								}
								label={
									<FormattedMessage
										id={'rod.release.create.step.tracks.fragment.start'}
									/>
								}
							/>
						)}
					</InputMask>
					{additionalErrors.id === additionalRelease.id &&
						Object.keys(additionalErrors).includes('snippet_start') && (
							<span className={styles.Helper}>
								{checkSnippetErrors('snippet_start')}
							</span>
						)}
				</div>
				<div className={styles.input}>
					<InputMask
						mask={'99:99:999'}
						maskChar={'0'}
						name={'snippet_end'}
						disabled={true}
						errors={additionalErrors}
						value={
							additionalRelease.snippet_end ? additionalRelease.snippet_end : ''
						}
					>
						{() => (
							<TextFieldInput
								type="muiInput"
								disabled={true}
								variant="standard"
								name={'snippet_end'}
								errors={additionalErrors}
								value={
									additionalRelease.snippet_end
										? additionalRelease.snippet_end
										: ''
								}
								label={
									<FormattedMessage
										id={'rod.release.create.step.tracks.fragment.end'}
									/>
								}
							/>
						)}
					</InputMask>
				</div>
			</div>
		</>
	);
};

export default Fragment;
