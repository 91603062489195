import React, { useEffect, useState } from 'react';
import { Pagination } from 'material-design/components';
import { Loading, NoData } from 'components';

import styles from './StatisticByTerritoriesSection.module.css';
import close from 'images/close_button.svg';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { FormInput } from 'components/Form/FormInput';
import MultipleSelectCheckmarks from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedTracks';
import MultipleSelectOutletsCheckmarks from 'material-design/components/MuiMultipleCheckedOutlets/MuiMultipleCheckedOutlets';
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';

import { StatisticByTerritoriesChart } from './StatisticByTerritoriesChart';

const StatisticByTerritoriesSection = (props) => {
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32).toISOString(),
		end_date: new Date(new Date().getTime() - 86400000 * 2).toISOString(),
		outlets: [],
		tracks: [],
	});

	const [page, setPage] = useState(1);
	const [pageLoading, setPageLoading] = useState(true);
	const [isPaginationPressed, setIsPaginationPressed] = useState(false);
	const [total, setTotal] = useState(0);
	const [helper, setHelper] = useState(false);

	const [topTracks, setTopTracks] = useState([]);
	const [outletCodes, setOutletCodes] = useState([]);

	const [checkedTopTrack, setCkeckedTopTrack] = useState(null);

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState({});
	// const [outletsStatistic, setOutletsStatistic] = useState([]);
	const [accountOutlets, setAccountOutlets] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	const [isReqLive, setIsReqLive] = useState(false);

	const [isInitialData, setIsInitialData] = useState(true);
	const [accountMap, setAccountMap] = useState([]);
	const [tracksIds, setTracksIds] = useState([]);

	const [isTracksFilterEmpty, setIsTracksFilterEmpty] = useState(false);
	const [isOutletsFilterEmpty, setIsOutletsFilterEmpty] = useState(false);

	let STAT_MAP_CACHE_ID = []; //айди кеша для карты
	let STAT_TOPTRACKS_CACHE_ID = []; //айди кеша топ треков

	const {
		// authContext: { accountId },
		rootContext: {
			getAccountStatisticOutlets,
			getAccountStatisticTopTracks,
			getAccountStatisticMapByDate,
		},
		adminAccountId,
	} = props;
	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: props.authContext.accountId;

	const lang = localStorage.getItem('lang');

	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';
	// const MIN_DATE = !isAdmin
	// 	? new Date(Date.now() - 90 * 86400000).toISOString().slice(0, 10)
	// 	: '2020-01-01';

	useEffect(() => {
		// setLoading(true);
		const isShowedHelper = localStorage.getItem('helper');
		if (isShowedHelper === 'showed') {
			setHelper(false);
		} else {
			setHelper(true);
		}

		getAccountStatisticOutlets().then((res) => {
			res = res.data.data;
			setAccountOutlets(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isTracksFilterEmpty || isOutletsFilterEmpty) {
			return;
		} else if (
			accountOutlets.length &&
			tracksIds.length &&
			!Object.keys(errors).length
		) {
			setPageLoading(true);
			setLoading(true);
			const outlets =
				statisticData['outlets']?.length > 0
					? statisticData['outlets']
					: accountOutlets;
			const startEndDates = getStartEndDates();
			const outletsCodes = outlets.map((outlet) => outlet.code);

			//Хешируем ключ - Аутлеты сортируем по алфавиту. Соеденяем масивы данных в строку через * начиная с аутлетов
			const sortOutlets = outletsCodes.sort();

			const mapHash = `${sortOutlets.join('*')}*${startEndDates.join('*')}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*map*${accountId}`;

			const mapReqFromCash = lscache.get(mapHash);

			if (mapReqFromCash && !isReqLive) {
				setAccountMap(mapReqFromCash.global);
				setLoading(false);
			} else {
				getAccountStatisticMapByDate(
					isAdmin,
					accountId,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					tracksIds.length && !isAllTracks ? tracksIds : [],
					tracksIds.length && !isAllTracks ? false : true
				).then((res) => {
					res = res.data.data;

					if (isReqLive) {
						setAccountMap(res.global);
						setLoading(false);
					} else {
						setCache('statisticMapCache', mapHash, res);

						setAccountMap(res.global);
						setLoading(false);
						/* } */
					}
				});
			}

			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${isAllTracks ? 'all' : tracksIds.join('*')}*topTracks*${accountId}`;

			const topTracksReqFromCash = lscache.get(topTracksHash);

			setPage(1);
			setIsPaginationPressed(false);
			if (topTracksReqFromCash && !isReqLive) {
				setTopTracks(topTracksReqFromCash.recordings);
				setTotal(topTracksReqFromCash.total);
				setPageLoading(false);
			} else {
				getAccountStatisticTopTracks(
					isAdmin,
					accountId,
					page,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					tracksIds.length && !isAllTracks ? tracksIds : ''
				).then((res) => {
					res = res.data.data;

					if (isReqLive) {
						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					} else {
						setCache('statisticTopTracksCache', topTracksHash, res);

						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					}
				});
			}
		}
	}, [accountOutlets, statisticData, tracksIds]);

	useEffect(() => {
		if (
			accountOutlets.length &&
			isPaginationPressed &&
			statisticData !== null
		) {
			const outlets =
				statisticData['outlets'].length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDates();

			const sortOutlets = outletsCodes.sort();

			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*topTracks*${accountId}*${page}`;

			const topTracksReqFromCash = lscache.get(topTracksHash);

			if (topTracksReqFromCash && !isReqLive) {
				setTopTracks(topTracksReqFromCash.recordings);
				setTotal(topTracksReqFromCash.total);
				setPageLoading(false);
			} else {
				getAccountStatisticTopTracks(
					isAdmin,
					accountId,
					page,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					tracksIds.length && !isAllTracks ? tracksIds : ''
				).then((res) => {
					res = res.data.data;

					if (isReqLive) {
						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					} else {
						setCache('statisticTopTracksCache', topTracksHash, res);

						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaginationPressed, page]);

	function getStartEndDates() {
		const isValidEndDate =
			Date.parse(statisticData.end_date) -
				Date.parse(statisticData.start_date) <
			90 * 86400000;
		const correctedEndDate =
			!isAdmin && !isValidEndDate
				? new Date(Date.parse(statisticData.start_date) + 90 * 86400000)
						.toISOString()
						.slice(0, 10)
				: statisticData.end_date;

		return [
			statisticData.start_date.includes('T')
				? statisticData.start_date.slice(
						0,
						statisticData.start_date.indexOf('T')
				  )
				: statisticData.start_date,
			correctedEndDate.includes('T')
				? correctedEndDate.slice(0, correctedEndDate.indexOf('T'))
				: correctedEndDate,
		];
	}

	const getDateInYYYYMMDD = (date) => {
		let dateInMs = new Date(new Date(date).getTime());
		return (
			dateInMs.getFullYear() +
			'-' +
			(dateInMs.getMonth() + 1).toString().padStart(2, '0') +
			'-' +
			dateInMs.getDate().toString().padStart(2, '0')
		);
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (e && e.getFullYear().toString().length === 4) {
					// e = dateFormat(e);
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) >= Date.parse(MIN_DATE) &&
						Date.parse(e) < Date.parse(statisticData['end_date'])
					) {
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
					setStatisticData({ ...statisticData });
				}

				break;
			case 'end_date':
				if (e && e.getFullYear().toString().length === 4) {
					// e = dateFormat(e);
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) > Date.parse(statisticData['start_date']) &&
						Date.parse(e) <= MAX_DATE
					) {
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
					setStatisticData({ ...statisticData });
				}

				break;

			case 'tracks':
				statisticData[field] = e.map((item) => item.title);
				setStatisticData({ ...statisticData });
				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
		if (statisticData['outlets'].length > 0 && isInitialData) {
			setIsInitialData(false);
		} else if (statisticData['outlets'].length === 0 && !isInitialData) {
			setIsInitialData(true);
		}
		// setTimeout(() => {
		// 	prepareFinalData();
		// }, 0);
	};

	const handleCheckedTracks = (tracks, isAll) => {
		if (!loading) {
			// if (tracks.length && !isOutletsFilterEmpty) {
			// 	setLoading(true);
			// }
			setIsTracksFilterEmpty(tracks.length ? false : true);
		}
		if (
			(tracks.length !== tracksIds.length && tracks.length !== 0) ||
			tracks.length === 1
		) {
			setIsAllTracks(isAll);
			setTracksIds(tracks);
		}
	};
	const handleCheckedOutlets = (outlets) => {
		if (!loading) {
			// if (outlets.length && !isTracksFilterEmpty) {
			// 	setLoading(true);
			// }
			setIsOutletsFilterEmpty(outlets.length ? false : true);
		}
		const codes = outlets.map((item) => item.code);

		if (outlets.length !== outletCodes.length /* && tracks.length !== 0 */) {
			setOutletCodes(codes);
			statisticData['outlets'] = outlets;
			setStatisticData({ ...statisticData });
		}
	};

	const handleOnBlurDateInput = (err, value) => {
		// if (err && typeof err === 'object') {
		// 	// delete errors.created_date;
		// 	setErrors({ ...err });
		// } else if (err && typeof err !== 'object') {
		// 	setErrors({
		// 		created_date: [{ rule: err }],
		// 	});
		// } else setErrors({});
	};

	const stylingDigit = (digit) => {
		let result = [];
		if (+digit > 999) {
			for (let i = 0; i < digit.length / 3; i++) {
				result.unshift(digit.slice(digit.length - 3));
				digit = digit.slice(0, digit.length - 3);
			}
			result.unshift(digit);
			return result.join(' ');
		} else return digit;
	};

	const getJSONParse = (string) => {
		try {
			return JSON.parse(string).join(', ');
		} catch (e) {
			return string;
		}
	};

	const handleCheckedTopTrack = (track) => {
		if (track) {
			setCkeckedTopTrack(track);
		} else {
			setCkeckedTopTrack(null);
		}
	};

	const startEndDates = getStartEndDates();

	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={closeHelper} src={close} alt="" />
					<span>
						<FormattedHTMLMessage
							// id={'rod.statistic.help'}
							id={'rod.statistic.notification'}
						></FormattedHTMLMessage>
					</span>
				</div>
			)}
			{/* {outletsStatistic && ( */}
			<div className={styles.statisticByTerritories__mainContent}>
				{/* <div> */}
				<div className={styles.inputsWrapper}>
					<div className={styles.inputOutlets}>
						<MultipleSelectOutletsCheckmarks
							handleCheckedOutlets={handleCheckedOutlets}
							lang={lang}
							className={styles.tracksCheckmarks}
						/>
						{Object.keys(errors).includes('outlets') && (
							<span className={styles.Helper}>
								error
								{/* <FormattedMessage
												id={`rod.error.${errors?.outlets[0].rule}`}
											/> */}
							</span>
						)}
					</div>

					<div className={styles.inputTrack}>
						<MultipleSelectCheckmarks
							isAdmin={isAdmin}
							accountId={accountId}
							handleCheckedTracks={handleCheckedTracks}
							checkedTopTrack={checkedTopTrack}
							handleCheckedTopTrack={handleCheckedTopTrack}
							className={styles.tracksCheckmarks}
							dateFrom={startEndDates[0]}
							dateTo={startEndDates[1]}
							isLoading={setLoading}
						/>
						{Object.keys(errors).includes('tracks') && (
							<span className={styles.Helper}>error</span>
						)}
					</div>
					<div className={styles.inputStartDate}>
						<FormInput
							className={styles.date}
							type={'muiInputDate_v3'}
							defaultValue={true}
							name={'start_date'}
							handleOnBlurDateInput={handleOnBlurDateInput}
							onChange={changeField}
							minDate={Date.parse(MIN_DATE)}
							maxDate={Date.parse(statisticData.end_date)}
							errors={errors}
							data={statisticData}
							label={<FormattedMessage id={'rod.statistic.start-date'} />}
						/>
						{Object.keys(errors).includes('start_date') && (
							<div className={styles.error}>
								<span className={styles.errorText}>
									{errors['start_date'] === 'invalid date' ? (
										<FormattedMessage id={'rod.admin.invalid-date'} />
									) : (
										<FormattedMessage id={'rod.error.statistic.minDate'} />
									)}
									{/* {
											<FormattedMessage
												id={`rod.error.${errors.sale_start_date[0].rule}`}
											/>
										} */}
									{errors['start_date'] === 'minDate' && MIN_DATE}
								</span>
							</div>
						)}
					</div>
					<div className={styles.inputDate}>
						<FormInput
							className={styles.date}
							type={'muiInputDate_v3'}
							defaultValue={true}
							name={'end_date'}
							handleOnBlurDateInput={handleOnBlurDateInput}
							onChange={changeField}
							minDate={Date.parse(statisticData.start_date)}
							maxDate={Date.now()}
							errors={errors}
							data={statisticData}
							label={<FormattedMessage id={'rod.statistic.end-date'} />}
						/>
						{Object.keys(errors).includes('end_date') && (
							<div className={styles.error}>
								<span className={styles.errorText}>
									{errors['end_date'] === 'invalid date' ? (
										<FormattedMessage id={'rod.admin.invalid-date'} />
									) : (
										''
									)}
									{/* {
											<FormattedMessage
												id={`rod.error.${errors.sale_start_date[0].rule}`}
											/>
										} */}
								</span>
							</div>
						)}
					</div>
					<StatisticDownloadBtn
						accountId={accountId}
						statisticData={statisticData}
						accountOutlets={accountOutlets}
						getStartEndDates={getStartEndDates}
						isAdmin={isAdmin}
					/>
				</div>

				{loading ? (
					<Loading className={styles.loader} />
				) : isOutletsFilterEmpty ||
				  isTracksFilterEmpty ||
				  accountMap.length === 0 ? (
					<NoData />
				) : (
					accountMap && (
						<>
							<StatisticByTerritoriesChart
								data={accountMap}
								selectedOutlets={
									isInitialData ? accountOutlets : statisticData.outlets
								}
							/>
							<>
								<div className={styles.topTracksTitle}>
									<span>
										<FormattedHTMLMessage
											id={'rod.statistic.top_tracks'}
										></FormattedHTMLMessage>
									</span>
								</div>
								{pageLoading ? (
									<Loading className={styles.loader} />
								) : (
									topTracks && (
										<div style={{ marginBottom: '50px' }}>
											<table className={styles.topTracksTable}>
												<thead className={styles.topTracksTable__header}>
													<tr>
														<td> </td>
														<td>
															<FormattedHTMLMessage
																id={'rod.statistic.top.track'}
															/>
														</td>
														<td>
															<FormattedHTMLMessage
																id={'rod.statistic.top.performer'}
															/>
														</td>
														<td>
															<FormattedHTMLMessage
																id={'rod.statistic.top.album'}
															/>
														</td>
														{/* <td> ISRC </td> */}
														<td style={{ textAlign: 'right' }}>
															<FormattedHTMLMessage
																id={'rod.statistic.top.streams'}
															/>
														</td>
													</tr>
												</thead>
												<tbody className={styles.topTracksTable__body}>
													{topTracks.map((item, index) => (
														<tr
															className={styles.tableRow}
															onClick={() => handleCheckedTopTrack(item)}
														>
															<td className={styles.topTracksTable__item}>
																{index + 1 + (page - 1) * 10}
															</td>
															<td className={styles.topTracksTable__item}>
																{item.raw_title}

																<span
																	className={styles.topTracksTable__fullName}
																>
																	{item.raw_title}
																</span>
															</td>
															<td className={styles.topTracksTable__item}>
																{item.performers
																	? getJSONParse(item.performers)
																	: '-'}
															</td>
															<td className={styles.topTracksTable__item}>
																{item.release_name}
															</td>
															{/* <td className={styles.topTracksTable__item}>
												{item.isrc}
											</td> */}
															<td
																className={styles.topTracksTable__item}
																style={{ textAlign: 'right' }}
															>
																{stylingDigit(item.streams)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
											{total > 10 && (
												<Pagination
													page={page}
													total={total}
													paginate={(pageNumber) => {
														setPage(pageNumber);
														setIsPaginationPressed(true);
														setPageLoading(true);
													}}
													items_on_page={10}
												/>
											)}
										</div>
									)
								)}
							</>
						</>
					)
				)}
			</div>
			{/* )} */}
		</>
	);
};

export default compose(
	withUI,
	withAuth,
	withRoot
)(StatisticByTerritoriesSection);
