// Core
import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Context
import { UIContext } from 'contexts/UIContext';

// Services
import lscache from 'lscache';
import { dateFormat } from 'logic';
import { dictionaries } from 'services';
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { FormInput } from 'components/Form';
import ShowEditRecording from './ShowEditRecording/ShowEditRecording';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

// Icons
import remove from 'images/close_grey.svg';

// Styles
import s from './NewRecordingForm.module.css';

const NewRecordingForm = ({
	number,
	onClickDeleteItem,
	saveRecord,
	data,
	recording,
	errors,
	isEdit,
}) => {
	const [showEditRecording, setShowEditRecording] = useState(isEdit);
	const [countries, setCountries] = useState([]);
	const [newRecording, setNewRecording] = useState({});
	const [dateError, setDateError] = useState({});
	const [isReqLive] = useState(false);

	const { disableRecordingBtns, setDisableRecordingBtns } = useContext(
		UIContext
	);

	useEffect(() => {
		if (!recording.title) {
			recording[`title`] = data.title;
		}
		setNewRecording(recording);
		getCountries();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function modifyTitle(title) {
		const exeptions = ['the', 'of', 'and', 'и'];
		const lowerTitle = title.toLowerCase();
		if (!lowerTitle.includes(' '))
			return lowerTitle.charAt(0).toUpperCase() + lowerTitle.slice(1);

		const titleAsArray = lowerTitle.split(' ');
		const modifiedTitle = titleAsArray
			.map((word) => {
				return exeptions.includes(word)
					? word
					: word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(' ');
		return modifiedTitle;
	}

	function getCountries() {
		const countriesCache = lscache.get('countriesCashe');
		if (countriesCache && !isReqLive) {
			const countries = countriesCache.map((obj) => {
				let result = obj;
				result.title_en = modifyTitle(obj.title_en);
				result.title_ru = modifyTitle(obj.title_ru);
				return result;
			});
			setCountries(countries);
			return countries;
		} else {
			return dictionaries
				.getCountries()
				.then((res) => {
					res = res.data.data;
					setCache('countries', 'countriesCashe', res);

					const countries = res.map((obj) => {
						let result = obj;
						result.title_en = modifyTitle(obj.title_en);
						result.title_ru = modifyTitle(obj.title_ru);
						return result;
					});

					setCountries(countries);

					return Promise.resolve(res);
				})
				.catch((error) => {
					console.error('Error', error);
					return Promise.reject();
				});
		}
	}

	const changeField = (field) => (e) => {
		if (field === `collect_rewards`) {
			newRecording[field] = e.target.checked;
			setNewRecording(newRecording);
			return;
		}

		switch (field) {
			case `created_date`:
				if (!newRecording.created_date) {
					newRecording[field] = new Date().toISOString();
					setNewRecording({ ...newRecording });
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = dateFormat(e);
				}
				newRecording[field] = e;

				break;

			case `genres`:
				const newArr = e.map((item) => (item.code ? item.code : item));
				newRecording[field] = newArr;
				break;
			case `created_country_id`:
				newRecording[field] = e;
				break;
			case `parental_warning_type`:
				newRecording[field] = e.code;
				break;

			default:
				newRecording[field] = e.target.value;
				break;
		}

		setNewRecording({ ...newRecording });
	};

	const hideEditRecordings = () => {
		setShowEditRecording(true);
	};

	const showRecordings = () => {
		setShowEditRecording(false);
		setDisableRecordingBtns(true);
	};

	const changeMask = (e) => {
		newRecording.isrc = e.target.value;
		setNewRecording({ ...newRecording });
	};

	const handleOnBlurDateInput = (err, value) => {
		setDateError({});

		if (err) {
			setDateError({ created_date: [{ rule: 'wrong_format' }] });
		}

		if (Date.parse(value) > Date.now()) {
			setDateError({ created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const recordingSave = () => {
		if (Object.keys(dateError).length) return;

		if (!saveRecord(newRecording)) {
			hideEditRecordings();
		}
	};

	return (
		<div>
			{showEditRecording ? (
				<ShowEditRecording
					newRecording={newRecording}
					showRecordings={showRecordings}
					onClickDeleteItem={onClickDeleteItem}
					disableRecordingBtns={disableRecordingBtns}
				/>
			) : (
				<div className={s.main}>
					<div className={s.form_container}>
						<h2 className={s.title}>
							<FormattedMessage
								id={'rod.performance_work.title'}
								values={{ number: number }}
							/>
						</h2>

						{((number === 1 && newRecording.main_performer) || number > 1) && (
							<button onClick={onClickDeleteItem} className={s.delete_svg}>
								<img src={remove} alt="" />
							</button>
						)}

						<ul className={s.list}>
							<li className={s.list_item}>
								<FormInput
									type="muiInput"
									name={`title`}
									onChange={changeField}
									errors={
										errors &&
										errors.id === newRecording.id &&
										Object.keys(errors).includes(`title`)
											? errors
											: {}
									}
									data={newRecording}
									label={
										<FormattedMessage
											id={'rod.performance_work.name_recordings'}
										/>
									}
									required
								/>
								{errors &&
									errors.id === newRecording.id &&
									Object.keys(errors).includes(`title`) && (
										<span className={s.Helper}>
											{<FormattedMessage id={`rod.error.required`} />}
										</span>
									)}
							</li>
							<li className={s.list_item}>
								<FormInput
									type="muiInput"
									name={`subtitle`}
									onChange={changeField}
									errors={errors}
									data={newRecording}
									label={<FormattedMessage id={'rod.basic_info.subtitle'} />}
								/>
							</li>
							<li className={s.list_item}>
								<FormInput
									type="muiInput"
									name={`main_performer`}
									onChange={changeField}
									errors={
										errors &&
										errors.id === newRecording.id &&
										Object.keys(errors).includes(`main_performer`)
											? errors
											: {}
									}
									data={newRecording}
									label={
										<FormattedMessage
											id={'rod.performance_work.main_performer'}
										/>
									}
									required
								/>
								{errors &&
									errors.id === newRecording.id &&
									Object.keys(errors).includes(`main_performer`) && (
										<span className={s.Helper}>
											{<FormattedMessage id={`rod.error.required`} />}
										</span>
									)}
								<p className={s.description}>
									<FormattedMessage
										id={'rod.performance_work.main_performer.description'}
									/>
								</p>
							</li>
							<li className={s.list_item}>
								<FormInput
									type="muiInput"
									name={`party_id`}
									onChange={changeField}
									errors={errors}
									data={newRecording}
									label={<FormattedMessage id={'rod.performance_work.label'} />}
									minWidth={352}
								/>
								<p className={s.description}>
									<FormattedMessage
										id={'rod.performance_work.main_performer.description'}
									/>
								</p>
							</li>
							<li className={s.list_item}>
								<FormInput
									type={'muiAutocompleteMultipleGenres'}
									name={`genres`}
									onChange={changeField}
									errors={errors}
									data={newRecording}
									label={<FormattedMessage id={'rod.basic_info.genres'} />}
								/>
								<p className={s.description}>
									<FormattedMessage id={'rod.basic_info.genres_description'} />
								</p>
							</li>
							<li className={s.list_item}>
								<InputMask
									mask={'aa-***-99-99999'}
									name={`ISRC`}
									onChange={changeMask}
									errors={errors}
									value={newRecording.isrc ? newRecording.isrc : ''}
								>
									{() => (
										<TextFieldInput
											variant="standard"
											type="muiInput"
											name={`ISRC`}
											errors={
												errors &&
												errors.id === newRecording.id &&
												Object.keys(errors).includes(`isrc`)
													? errors
													: {}
											}
											value={newRecording.isrc ? newRecording.isrc : ''}
											label="ISRC"
										/>
									)}
								</InputMask>
								{errors &&
									errors.id === newRecording.id &&
									Object.keys(errors).includes(`isrc`) && (
										<span className={s.Helper}>
											{<FormattedMessage id={`rod.error.invalid`} />}
										</span>
									)}
								<p className={s.description}>
									<FormattedMessage
										id={'rod.performance_work.iswc.description'}
									/>
								</p>
							</li>
							<li className={`${s.list_item} ${s.countryItem}`}>
								<FormattedMessage id={'rod.basic_info.country_name'}>
									{(placeholder) => (
										<FormInput
											type="country"
											name={`created_country_id`}
											onChange={changeField}
											errors={errors}
											data={newRecording}
											items={countries}
											countries={countries}
											placeholder={placeholder}
										/>
									)}
								</FormattedMessage>
							</li>
							<div className={s.input}>
								<li className={s.list_item}>
									<FormInput
										type={'muiInputDate_v2'}
										name={`created_date`}
										onChange={changeField}
										handleOnBlurDateInput={handleOnBlurDateInput}
										maxDate={true}
										errors={
											dateError &&
											Object.keys(dateError).includes('created_date')
												? dateError
												: {}
										}
										data={newRecording}
										label={<FormattedMessage id={'rod.basic_info.date'} />}
									/>
								</li>
								{dateError &&
									Object.keys(dateError).includes('created_date') && (
										<span className={s.Helper}>
											{
												<FormattedMessage
													id={`rod.error.${dateError.created_date[0].rule}`}
												/>
											}
										</span>
									)}
							</div>
							<li className={s.list_item}>
								<FormInput
									type="muiInput"
									name={`featured_artist`}
									onChange={changeField}
									errors={errors}
									data={newRecording}
									label={
										<FormattedMessage
											id={'rod.performance_work.featured_artist'}
										/>
									}
								/>
							</li>
						</ul>
					</div>
					<div>
						<button className={s.btn_save} onClick={() => recordingSave()}>
							<p>
								<FormattedMessage id={'rod.performance_work.save'} />
							</p>
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default NewRecordingForm;
