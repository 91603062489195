import { useContext, useEffect, useState, useCallback } from 'react';
import { RootContext } from 'contexts/RootContext';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { Loading } from 'components';
import { Pagination, Input } from 'material-design/components';
// import { Pagination } from 'material-design/components';
import Admin from 'services/api/admin';
import plusImg from 'images/plus.svg';
import { withLang, withUI } from 'hocs';
import { compose } from 'recompose';
import { AuthContext } from 'contexts/AuthContext';

import styles from './NotificationsPage.module.css';
import debounce from 'lodash.debounce';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const NotificationsPage = (props) => {
	const { getAdminNotifications } = useContext(RootContext);
	const authContext = useContext(AuthContext);

	const [notifications, setNotifications] = useState([]);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [isShowAllRecipients, setIsShowAllRecipients] = useState('');
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noData, setNoData] = useState(false);

	const {
		UIContext: { showModal },
		langContext: { lang },
	} = props;

	useEffect(() => {
		setLoading(true);
		Admin.getAccountsAdminV2().then((res) => {
			res = res.data.data;
			setUsers(res);
		});
	}, []);

	useEffect(() => {
		if (users.length) {
			if (searchValue && searchValue.length >= 3) {
				getNotifications(page, searchValue, lang);
			} else if (users.length && searchValue === '') {
				getNotifications(page, '', lang);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users, page, searchValue, lang]);

	// useEffect(() => {
	// 	if (searchValue && searchValue.length >= 3) {
	// 		getNotifications(page, searchValue);
	// 	} else if (users.length && searchValue === '') {
	// 		getNotifications();
	// 	}
	// }, [searchValue]);

	const getNotifications = (page, search) => {
		if (!loading) setLoading(true);
		getAdminNotifications(page, search, lang)
			.then((res) => {
				if (res.data.length) {
					setTotal(res.total);
					res = res.data.sort(
						(a, b) => new Date(b.created_at) - new Date(a.created_at)
					);

					const data = res.map((item) => {
						const time = item.created_at.match(/\d{1,2}:\d{2}/)[0];
						const date = item.created_at
							.slice(0, item.created_at.indexOf('T'))
							.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
						item.created_at = time + ', ' + date;

						const accountNames = [];
						// users.map((user) => {
						// 	item.accounts_id.find((acc) => {
						// 		if (user.id === acc.account_id) accountNames.push(user.title);
						// 	});
						// });

						users.forEach((user) => {
							const equalId = item.accounts_id.find(
								(acc) => user.id === acc.account_id
							);
							if (equalId) {
								accountNames.push(user.title);
							}
						});

						item.recipients = accountNames;

						return item;
					});
					setNoData(false);
					setNotifications(data);
				} else {
					setNoData(true);
				}
			})
			.catch((err) => console.info('Error:', err))
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnChange = (value) => {
		setSearchValue(value);
		if (!value) setPage(1);
	};

	const debouncedChangeHandler = useCallback(debounce(handleOnChange, 500), []);

	const handleOnMouseOver = (index) => {
		setIsShowAllRecipients(index);
	};
	const handleOnMouseLeave = () => {
		setIsShowAllRecipients('');
	};

	const htmlParsing = (text) => {
		return parse(
			DOMPurify.sanitize(text, {
				ADD_ATTR: ['target'],
			})
		);
	};

	const isNotificationSend = (isSend) => {
		if (isSend) {
			getNotifications();
		}
	};

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.search}>
				<Input handleOnChange={debouncedChangeHandler} />
			</div>
			<div className={styles.sectionTitleWrapper}>
				<div className={styles.sectionTitle}>
					<FormattedHTMLMessage id={'rod.notification.list'} />
				</div>
				<div
					className={styles.notifyBtn}
					onClick={showModal(
						{
							users: users,
							authContext: authContext,
							isNotificationSend: isNotificationSend,
						},
						'notification'
					)}
				>
					<img src={plusImg} alt="add" className={styles.iconPlus} />
					<div>
						<FormattedHTMLMessage id={'rod.notification.add'} />
					</div>
				</div>
			</div>
			{loading ? (
				<Loading className={styles.loader} />
			) : noData ? (
				<div className={styles.noData}>
					<FormattedMessage id={'rod.admin.no-data'} />
				</div>
			) : (
				<>
					<div className={styles.RepertoireSongInfo__mainInfoTable}>
						<Row className={styles.row_header}>
							<Col width={'4%'}></Col>
							<Col width={'28%'}>
								<FormattedHTMLMessage id={'rod.admin.notifications.title'} />
							</Col>
							<Col width={'28%'}>
								<FormattedHTMLMessage id={'rod.admin.notifications.text'} />
							</Col>
							<Col width={'15%'}>
								<FormattedHTMLMessage id={'rod.admin.notifications.date'} />
							</Col>
							<Col width={'25%'}>
								<FormattedHTMLMessage
									id={'rod.admin.notifications.recipients'}
								/>
							</Col>
						</Row>
						<div className={styles.RepertoireMainInfoTable__body}>
							<ul>
								{notifications.map((item, index) => (
									<li
										className={styles.notificationListItem}
										onClick={showModal(
											{
												notify: item,
												authContext: authContext,
											},
											'notification_admin_read'
										)}
									>
										<Row className={styles.row_data}>
											<Row className={styles.row_title}>
												<Col width={'4%'} className={styles.row_title_number}>
													{page * 15 - 15 + index + 1}
												</Col>
												<Col width={'28%'}>{item.heading}</Col>
												<Col width={'28%'}>
													<div className={styles.itemText}>
														{Array.isArray(htmlParsing(item.text))
															? htmlParsing(item.text).find(
																	(element) =>
																		element !== '\n' &&
																		typeof element !== 'string' &&
																		typeof element.props.children === 'string'
															  )
															: htmlParsing(item.text)}
														{/* {item.text} */}
													</div>
												</Col>
												<Col width={'15%'}>{item.created_at}</Col>
												<Col width={'25%'}>
													<div className={styles.modalRecipientsWrapper}>
														<div
															onMouseOver={() => handleOnMouseOver(index)}
															onMouseLeave={() => handleOnMouseLeave(index)}
														>
															{item.id_mass_mailing ? (
																<FormattedHTMLMessage
																	id={'rod.contracts.all_recipients'}
																/>
															) : item.recipients.length > 1 ? (
																<div
																	className={styles.modalRecipientsItemWrapper}
																>
																	<div className={styles.modalRecipientsItem}>
																		{item.recipients[0]}
																	</div>
																	<div>{`+ ${item.recipients.length - 1}`}</div>
																</div>
															) : (
																item.recipients[0]
															)}
														</div>
														{isShowAllRecipients === index &&
															!item.id_mass_mailing && (
																<div className={styles.modalRecipientsAll}>
																	{item.recipients.join(', ')}
																</div>
															)}
													</div>
												</Col>
											</Row>
										</Row>
									</li>
								))}
							</ul>
						</div>
					</div>
					{total > 10 && !loading && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => {
								setPage(pageNumber);
							}}
							items_on_page={15}
						/>
					)}
				</>
			)}
		</div>
	);
};
export default compose(withUI, withLang)(NotificationsPage);
