// Core
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';

// Styles
import s from './DistributionDate.module.css';

const DistributionDate = ({
	additionalRelease,
	changeField,
	additionalErrors,
	setAdditionalErrors,
}) => {
	const isErrors =
		additionalErrors &&
		Object.keys(additionalErrors).includes('sale_start_date');

	const handleOnBlurDateInput = () => {
		setAdditionalErrors({});
	};

	return (
		<div className={s.item}>
			<FormInput
				type={'muiInputDate_v2'}
				name={'sale_start_date'}
				onChange={() => changeField('sale_start_date', additionalRelease)}
				handleOnBlurDateInput={handleOnBlurDateInput}
				minDate={true}
				maxDate={false}
				defaultValue={true}
				errors={isErrors ? additionalErrors : []}
				data={additionalRelease}
				label={
					<FormattedMessage
						id={
							'rod.release.create.step.tracks.additional_release.distribution_date'
						}
					/>
				}
				required
			/>
			{isErrors && (
				<span className={s.Helper}>
					<FormattedMessage
						id={`rod.error.${additionalErrors.sale_start_date[0].rule}`}
					/>
				</span>
			)}
		</div>
	);
};

export default DistributionDate;
