// Core
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import lscache from 'lscache';

// Helpers
import { setCache } from 'services/cacheHelper/setCache';
import debounce from 'lodash.debounce';
import { compose } from 'recompose';
import { accounts, dictionaries } from 'services';

// UI
import Banner from '../RepertoireReleaseSection/Banner/Banner';
import { Pagination, Input } from 'material-design/components';
import { Loading } from 'components';
import { withUI, withAuth, withRoot } from 'hocs';

// Icons
import ContentEmpty from './ContentEmpty/ContentEmpty';
import RemoveButton from './RemoveButton/RemoveButton';
import { Cd } from 'material-design/svg';
import { CdWhite } from 'material-design/svg';
import { Nota } from 'material-design/svg';
import { NotaWhite } from 'material-design/svg';
import pencil from 'images/pencil.svg';
import remove from 'images/remove_2.svg';

// Types
import modalTypes from 'constants/modalTypes';
import { Col, Row, Container } from 'react-grid-system';

// Styles
import styles from './RepertoireDraftSection.module.css';

const RepertoireDraftSection = (props) => {
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [banner, setBanner] = useState(true);

	const [releases, setReleases] = useState([]);
	const [compositionTypes, setCompositionTypes] = useState([]);
	const [drafts, setDrafts] = useState([]);
	const lang = localStorage.getItem('lang');
	const [loading, setLoading] = useState(true);
	const [value, setValue] = useState('');
	const [isReqLive] = useState(false);

	const {
		authContext: { accountId },
	} = props;

	useEffect(() => {
		const {
			UIContext: { changeBackTargetUrl },
		} = props;
		changeBackTargetUrl('/repertoire/drafts');

		if (releases.length === 0) {
			new Promise((resolve, reject) => {
				const releaseTypesCache = lscache.get('releaseTypesCache');
				if (
					releaseTypesCache &&
					!isReqLive &&
					releaseTypesCache.lang === lang
				) {
					setReleases(releaseTypesCache.data);
					resolve(releaseTypesCache.data);
				} else {
					accounts
						.getReleaseTypes(lang)
						.then((releases) => {
							setCache('releaseTypes', 'releaseTypesCache', releases.data.data);
							setReleases(releases.data.data);
							resolve(releases.data.data);
						})
						.catch((error) => {
							console.info('Error', error);
						});
				}
			});
		} else {
			new Promise((resovle, reject) => {
				resovle(releases);
			});
		}
		if (compositionTypes.length === 0) {
			new Promise((resolve, reject) => {
				dictionaries
					.getCompositionTypes()
					.then((res) => {
						setCompositionTypes(res.data.data);
						resolve(res.data.data);
					})
					.catch((error) => {
						console.info('Error', error);
					});
			});
		} else {
			new Promise((resolve, reject) => {
				resolve(compositionTypes);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			setReleases(releaseTypesCache.data);
		} else {
			accounts
				.getReleaseTypes(lang)
				.then((releases) => {
					setCache('releaseTypes', 'releaseTypesCache', releases.data.data);
					setReleases(releases.data.data);
				})
				.catch((error) => {
					console.info('Error', error);
				});
		}
	}, [lang]);

	const onChange = (filter) => {
		setValue(filter);
		if (releases && compositionTypes)
			if (filter.length >= 3) {
				setLoading(true);
				accounts
					.getAssetsDraftsText(accountId, filter)
					.then((res) => {
						res = res.data;
						res.data.forEach((item) => {});
						setTotal(res.total);
						const draftsWithType = addTypesToDrafts(res.data);
						setDrafts(draftsWithType);
						setPage(1);
						setLoading(false);
					})
					.catch((error) => {
						console.info('Error', error);
					});
			} else if (!filter) {
				setLoading(true);
				accounts
					.getAssetsDrafts(accountId)
					.then((res) => {
						res = res.data;
						res.data.forEach((item) => {});
						setTotal(res.total);
						const draftsWithType = addTypesToDrafts(res.data);
						setDrafts(draftsWithType);
						setPage(1);
						setLoading(false);
					})
					.catch((error) => {
						console.info('Error', error);
					});
			}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleFilter = useCallback(debounce(onChange, 500), []);

	useEffect(() => {
		if (releases?.length !== 0 && compositionTypes !== 0)
			if (value.length >= 3) {
				accounts
					.getAssetsDraftsText(accountId, value, page)
					.then((res) => {
						const draftsWithType = addTypesToDrafts(res.data.data);
						setDrafts(draftsWithType);
						setTotal(res.data.total);
						loading && setLoading(false);
					})
					.catch((err) => {
						console.info('Drafts -changePage - error: ', err);
					});
			} else {
				accounts
					.getAssetsDrafts(accountId, page)
					.then((res) => {
						res = res.data;
						setTotal(res.total);
						const draftsWithType = addTypesToDrafts(res.data);
						setDrafts(draftsWithType);
						loading && setLoading(false);
					})
					.catch((error) => {
						console.info('Error', error);
					});
			}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releases, compositionTypes, page]);

	const getReleasesAfterRemove = (releases) => {
		setReleases(releases);
	};

	const addTypesToDrafts = (initialDrafts) => {
		if (initialDrafts.length !== 0) {
			initialDrafts.forEach((item) => {
				switch (item.draft_type) {
					case 'release':
						releases.forEach((releaseItem) => {
							if (item.type_id.toString() === releaseItem.id.toString())
								item.type = releaseItem.title;
						});
						break;
					case 'composition':
						compositionTypes.forEach((compositionItem) => {
							if (item.type_id.toString() === compositionItem.id.toString())
								item.type =
									lang === 'en'
										? compositionItem.title_en
										: compositionItem.title_ru;
						});
						break;

					default:
						break;
				}

				if (item.created_date) {
					item.created_date = item.created_date.replace(
						/(\d{4})-(\d{2})-(\d{2}).+/gm,
						'$3.$2.$1'
					);
				}
				return item;
			});
			setLoading(false);
		}
		return initialDrafts;
	};

	const getRedirectLink = (item) => {
		switch (item.draft_type) {
			case 'release':
				return `/release/${item.id}/${item.step}`;
			case 'composition':
				if (item.step === 'info' || item.step === 'confirm') {
					return `/compositions/create`;
				} else {
					return `/compositions/${item.id}/${item.step}`;
				}

			default:
				break;
		}
	};

	const handleonClick = (item) => {
		switch (item.draft_type) {
			case 'release':
				const releaseId = localStorage.getItem('releaseId');
				if (item.id !== releaseId) {
					localStorage.setItem('releaseId', item.id);
				}

				return `/release/${item.id}/${item.step}`;
			case 'composition':
				const compositionId = localStorage.getItem('compositionId');
				if (item.id !== compositionId) {
					localStorage.setItem('compositionId', item.id);
				}

				if (item.step === 'info') {
					return `/compositions/create`;
				} else {
					return `/compositions/${item.id}/${item.step}`;
				}

			default:
				break;
		}
	};

	const getDraftStatus = (item) => {
		switch (item.step) {
			case 'release':
				return <FormattedMessage id={'rod.release.create.step.release'} />;
			case 'file':
				return <FormattedMessage id={'rod.release.create.step.files'} />;
			case 'tracks':
				return <FormattedMessage id={'rod.release.create.step.tracks'} />;
			case 'authors':
				return <FormattedMessage id={'rod.release.create.step.authors'} />;
			case 'lyrics':
				return <FormattedMessage id={'rod.release.create.step.lyrics'} />;
			case 'cover':
				return <FormattedMessage id={'rod.release.create.step.cover'} />;
			case 'distribution':
				return <FormattedMessage id={'rod.release.create.step.distribution'} />;
			case 'confirm':
				return <FormattedMessage id={'rod.release.create.step.confirm'} />;
			case 'info':
				return <FormattedMessage id={'rod.composition.create.step.info'} />;
			case 'authors_shares':
				return (
					<FormattedMessage id={'rod.composition.create.step.authors_shares'} />
				);
			case 'composition_recordings':
				return (
					<FormattedMessage
						id={'rod.composition.create.step.composition_recordings'}
					/>
				);

			default:
				break;
		}
	};

	const {
		UIContext: { upload },
	} = props;

	const isUnknownStep = (step) => {
		const allKnownStatuses = [
			'moderation',
			'completed',
			'parsed',
			'check',
			'update',
			'release',
			'file',
			'tracks',
			'authors',
			'lyrics',
			'cover',
			'distribution',
			'confirm',
			'info',
			'authors_shares',
			'composition_recordings',
		];
		return !allKnownStatuses.includes(step);
	};

	const isKnownStatus = (step) => {
		const knownStatuses = [
			'moderation',
			'completed',
			'parsed',
			'check',
			'update',
		];

		return knownStatuses.includes(step);
	};

	return (
		<>
			{banner && <Banner setBanner={setBanner} title="rod.drafts.title" />}
			<Input handleOnChange={handleFilter} />
			{loading ? (
				<Loading className={styles.loader} />
			) : drafts?.length ? (
				<div className={styles.repertoire__mainContent}>
					<div>
						<div
							className={`${styles.repertoire__songTitle} ${styles.song__table} ${styles.adaptive__songTitle}`}
						>
							<FormattedHTMLMessage
								id={'rod.for_all.title'}
							></FormattedHTMLMessage>
							<FormattedHTMLMessage
								id={'rod.for_all.performer'}
							></FormattedHTMLMessage>
							<FormattedHTMLMessage
								id={'rod.for_all.type'}
							></FormattedHTMLMessage>
							<FormattedHTMLMessage
								id={'rod.for_all.status'}
							></FormattedHTMLMessage>
						</div>

						<ul className={styles.repertoire__songItemsWrapper}>
							{drafts &&
								drafts.map((item, index) => {
									return isKnownStatus(item.step) ||
										isUnknownStep(item.step) ? (
										<li className={styles.itemWrapper}>
											<div className={styles.itemInactive}>
												<div className={styles.adaptive__item}>
													<Container fluid>
														<Row style={{ marginTop: '10px' }}>
															<div style={{ height: '29px' }}></div>
														</Row>
														<Row>
															<Col xs={4}>
																<div>
																	{item.draft_type === 'release' ? (
																		<CdWhite
																			className={styles.adaptive__songItemLogo}
																		/>
																	) : (
																		<NotaWhite
																			className={styles.adaptive__songItemLogo}
																		/>
																	)}
																</div>
															</Col>
															<Col xs={8}>
																<div
																	className={
																		styles.adaptive__itemText_firstLine
																	}
																>
																	<span>{item.title}</span>
																	{item.title && <span>{item.title}</span>}
																</div>
															</Col>
														</Row>

														<Row>
															<Col xs={4}>
																<div className={styles.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.performer'}
																	/>
																</div>
																<div className={styles.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.type'}
																	/>
																</div>
																<div className={styles.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.status'}
																	/>
																</div>
															</Col>
															<Col xs={8}>
																<div className={styles.adaptive__itemText}>
																	<span>
																		{item.performers ? item.performers : ''}
																	</span>
																	{item.performers ? (
																		<span>{item.performers}</span>
																	) : (
																		<span> </span>
																	)}
																</div>
																<div className={styles.adaptive__itemText}>
																	<span>{item.type}</span>
																	{item.type && <span>{item.type}</span>}
																</div>
																<div className={styles.adaptive__itemText}>
																	<span>
																		<FormattedMessage
																			id={`rod.draft.release.${
																				isUnknownStep(item.step)
																					? 'unknownstatus'
																					: item.step
																			}`}
																		/>
																	</span>
																	{item.step && (
																		<span>
																			<FormattedMessage
																				id={`rod.draft.release.${
																					isUnknownStep(item.step)
																						? 'unknownstatus'
																						: item.step
																				}`}
																			/>
																		</span>
																	)}
																</div>
															</Col>
														</Row>
													</Container>
												</div>

												<div
													className={`${styles.draft_moderation} ${styles.song__table} ${styles.adaptive__songTable}`}
												>
													<div>
														<span>{item.title}</span>
														{item.title && <span>{item.title}</span>}
													</div>
													<div>
														<span>{item.performers && item.performers}</span>
														{item.performers && <span>{item.performers}</span>}
													</div>
													<div>
														<span>{item.type}</span>
														{item.type && <span>{item.type}</span>}
													</div>
													<div>
														<span>
															<FormattedMessage
																id={`rod.draft.release.${
																	isUnknownStep(item.step)
																		? 'unknownstatus'
																		: item.step
																}`}
															/>
														</span>
														{item.step && (
															<span>
																<FormattedMessage
																	id={`rod.draft.release.${
																		isUnknownStep(item.step)
																			? 'unknownstatus'
																			: item.step
																	}`}
																/>
															</span>
														)}
													</div>

													{item.draft_type === 'release' ? (
														<CdWhite
															className={styles.repertoire__songItemLogo}
														/>
													) : (
														<NotaWhite
															className={styles.repertoire__songItemLogo}
														/>
													)}
												</div>
											</div>
											<div className={styles.adaptive__menuMoreWrapper}></div>
										</li>
									) : (
										<li className={styles.itemWrapper}>
											<Link
												key={item.id}
												to={() => getRedirectLink(item)}
												onClick={() => handleonClick(item)}
												className={styles.itemLink}
											>
												<div className={styles.adaptive__item}>
													<Container fluid>
														<Row style={{ marginTop: '10px' }}>
															<div style={{ marginLeft: 'auto' }}>
																<img
																	className={styles.edit}
																	src={pencil}
																	alt=""
																/>
															</div>
														</Row>
														<Row className={styles.adaptive__firstRow}>
															<Col xs={4}>
																<div>
																	{item.draft_type === 'release' ? (
																		<Cd
																			className={styles.adaptive__songItemLogo}
																		/>
																	) : (
																		<Nota
																			className={styles.adaptive__songItemLogo}
																		/>
																	)}
																</div>
															</Col>
															<Col xs={10}>
																<div
																	className={
																		styles.adaptive__itemText_firstLine
																	}
																>
																	<span>{item.title}</span>
																	{item.title && <span>{item.title}</span>}
																</div>
															</Col>
														</Row>
														<Row>
															<Col xs={4}>
																<div className={styles.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.performer'}
																	/>
																</div>
																<div className={styles.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.type'}
																	/>
																</div>
																<div className={styles.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.status'}
																	/>
																</div>
															</Col>
															<Col xs={6}>
																<div className={styles.adaptive__itemText}>
																	<span>
																		{item.performers ? item.performers : ''}
																	</span>
																	{item.performers ? (
																		<span>{item.performers}</span>
																	) : (
																		<span> </span>
																	)}
																</div>
																<div className={styles.adaptive__itemText}>
																	<span>{item.type}</span>
																	{item.type && <span>{item.type}</span>}
																</div>
																<div className={styles.adaptive__itemText}>
																	<span>{getDraftStatus(item)}</span>
																	{getDraftStatus(item) && (
																		<span>{getDraftStatus(item)}</span>
																	)}
																</div>
															</Col>
														</Row>
													</Container>
												</div>

												<div
													className={
														item.status_title === 'disputed' ||
														item.status_title === 'not_ready'
															? `${styles.repertoire__songItemError} ${styles.song__table} ${styles.adaptive__songTable}`
															: `${styles.song__table} ${styles.adaptive__songTable}`
													}
												>
													<div>
														<span>{item.title}</span>
														{item.title && <span>{item.title}</span>}
													</div>
													<div>
														<span>{item.performers && item.performers}</span>
														{item.performers && <span>{item.performers}</span>}
													</div>
													<div>
														<span>{item.type}</span>
														{item.type && <span>{item.type}</span>}
													</div>
													<div>
														<span>{getDraftStatus(item)}</span>
														{getDraftStatus(item) && (
															<span>{getDraftStatus(item)}</span>
														)}
													</div>
													<div>
														<img className={styles.edit} src={pencil} alt="" />
													</div>
													{item.draft_type === 'release' ? (
														<Cd className={styles.repertoire__songItemLogo} />
													) : (
														<Nota className={styles.repertoire__songItemLogo} />
													)}
												</div>
											</Link>

											<RemoveButton
												item={item}
												releases={releases}
												getReleasesAfterRemove={getReleasesAfterRemove}
											/>
										</li>
									);
								})}
						</ul>
					</div>
					{total > 10 && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => setPage(pageNumber)}
							items_on_page={10}
						/>
					)}
				</div>
			) : (
				<ContentEmpty upload={upload} />
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(RepertoireDraftSection);
