// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const ContentEmpty = (pathname, clickToCreateRelease) => {
	return (
		<span>
			<FormattedMessage
				id={'rod.release.empty'}
				values={{
					a: (msg) => (
						<Link
							to={{
								pathname: '/release/create',
								state: {
									url: pathname,
								},
							}}
							onClick={clickToCreateRelease}
						>
							{msg}
						</Link>
					),
				}}
			/>
		</span>
	);
};

export default ContentEmpty;
