import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot } from 'hocs';
import { compose } from 'recompose';
import styles from './StatisticDownloadBtn.module.css';
import downloadFromAmazon from 'logic/downloadFromAmazon';
const StatisticDownloadBtn = (props) => {
	const [isReportReady, setIsReportReady] = useState(true);
	const {
		accountId,
		statisticData,
		accountOutlets,
		// getStartEndDates,
		isAdmin,
	} = props;

	const {
		rootContext: {
			createStatisticAccountReport,
			getRespStatisticAccountReports,
			getStatisticAccountReportFile,
			createStatisticAdminReport,
			getRespStatisticAdminReports,
			getStatisticAdminReportFile,
		},
		// adminAccountId,
	} = props;

	const downloadHandling = () => {
		setIsReportReady(false);
		const outlets =
			statisticData['outlets']?.length > 0
				? statisticData['outlets']
				: accountOutlets;

		const outletsCodes = outlets.map((outlet) => outlet.code);
		// const startEndDates = getStartEndDates();
		const startEndDates = [
			statisticData.start_date.includes('T')
				? statisticData.start_date.slice(
						0,
						statisticData.start_date.indexOf('T')
				  )
				: statisticData.start_date,
			statisticData.end_date.includes('T')
				? statisticData.end_date.slice(0, statisticData.end_date.indexOf('T'))
				: statisticData.end_date,
		];

		if (!isAdmin) {
			let filePath = '';
			createStatisticAccountReport(
				accountId,
				startEndDates[0],
				startEndDates[1],
				outletsCodes
			).then((res) => {
				getRespStatisticAccountReports(accountId).then((reports) => {
					const intervalId = setInterval(() => {
						getStatisticAccountReportFile(accountId, res.data).then((file) => {
							if (file.data.status === 'done') {
								console.info('file is ready');
								setIsReportReady(true);
								filePath = file.data.output_file;

								downloadFromAmazon(filePath);

								clearInterval(intervalId);
							} else {
								console.log('in progress...');
							}
						});
					}, 2000);
				});
			});
		} else {
			let filePath = '';
			createStatisticAdminReport(
				accountId,
				startEndDates[0],
				startEndDates[1],
				outletsCodes
			).then((res) => {
				getRespStatisticAdminReports(accountId).then((reports) => {
					const intervalId = setInterval(() => {
						getStatisticAdminReportFile(accountId, res.data).then((file) => {
							if (file.data.status === 'done') {
								console.log('file is ready');
								setIsReportReady(true);
								filePath = file.data.output_file;
								downloadFromAmazon(filePath);
								// const link = document.createElement('a');
								// link.href = filePath;
								// link.download = 'statistic';
								// link.click();
								// window.open(filePath, '_parent');
								clearInterval(intervalId);
							} else {
								console.log('in progress...');
							}
						});
					}, 2000);
				});
			});
		}
	};

	return (
		<div
			className={isReportReady ? styles.enabled : styles.disabled}
			onClick={downloadHandling}
		>
			{/* <img src="images/download_square.svg" width="40" alt=""></img> */}
			{!isReportReady && (
				<div className={styles.downloadStatus}>
					<FormattedMessage id={'rod.statistic.preparing'} />
				</div>
			)}
		</div>
	);
};

export default compose(withRoot)(StatisticDownloadBtn);
