// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Styles
import styles from '../Menu.module.css';

const ItemWrapper = ({ hide, to, text }) => {
	return (
		<li className={styles.item_wrapper}>
			<div className={styles.Item}>
				<Link to={to} onClick={hide}>
					<FormattedMessage id={text} />
				</Link>
			</div>
		</li>
	);
};

export default ItemWrapper;
