// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import clock from 'images/clock.svg';

// Styles
import s from '../TreatiesPage.module.css';

const ContractsOnReview = ({ contractsOnReview }) => {
	return (
		<ul className={s.treaties__list}>
			{contractsOnReview.map((item, index) => (
				<li className={s.item}>
					<div className={s.itemWrapper}>
						<div className={s.mainData}>
							<div className={s.headerWrapper}>
								<div className={s.titleWrapper}>
									<div className={s.title}>{item.title}</div>
									<div className={s.typeId}>
										<FormattedMessage
											id={
												item.contract_type === 'license'
													? 'rod.account.create.license-agreement'
													: item.contract_type === 'publishing'
													? 'rod.contracts.publishing'
													: 'rod.contracts.sub'
											}
										/>
										<span>: #{item.id}</span>
									</div>
								</div>
								<div className={s.downloadWrapper}>
									<div>
										<button className={s.load__btn}>
											<img className={s.svg__btn} src={clock} alt="" />
											<FormattedMessage
												id={'rod.treaties_page.pending_review'}
											/>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
			))}
		</ul>
	);
};

export default ContractsOnReview;
