// Core
import React from 'react';

// UI
import { FilteredDropdown } from 'components/FilteredDropdown';

// Styles
import styles from './CountrySelect.module.css';

const CountrySelect = (props) => {
	const { countries, value } = props;
	const lang = localStorage.getItem('lang');

	return (
		<div className={styles.CountrySelect}>
			<FilteredDropdown
				{...props}
				defaultEmpty
				isCountryData
				value={value}
				items={countries}
				getItem={(item) => {
					if (item) {
						return <span>{lang === 'ru' ? item.title_ru : item.title_en}</span>;
					}
				}}
				valueToString={(item) => {
					return item ? (lang === 'ru' ? item.title_ru : item.title_en) : null;
				}}
				getListItem={(item) => {
					if (item) {
						return <span>{lang === 'ru' ? item.title_ru : item.title_en}</span>;
					}
				}}
				getItemValue={(item) => {
					if (item) {
						return item.id;
					}
				}}
			/>
		</div>
	);
};

CountrySelect.propTypes = {};
CountrySelect.defaultProps = {};

export default CountrySelect;
