/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 11/12/2020, 13:48
 */

import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Input, PhoneInput } from 'components/Inputs';
import { Label } from 'components/Labels';
import styles from 'forms/auth/Registration/SpecialRegistrationForm/RegisterSpecial.module.css';
import { withUI } from 'hocs';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { showError } from 'validators/showError';

class RegisterSpecial extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
		errors: PropTypes.object,
	};

	static defaultProps = {
		errors: {},
	};

	count = React.createRef();
	form = React.createRef();
	name = React.createRef();
	email = React.createRef();
	phone = React.createRef();
	company = React.createRef();
	agreedCheckbox = React.createRef();

	state = {
		agreed: false,
		repertoireCount: '',
		email: '',
		errFromRepertoire: false,
		errFromEmail: false,
	};

	handleAgreed = () => {
		this.setState({ agreed: this.agreedCheckbox.current.checked });
	};

	handleShowTerms = (e) => {
		e.preventDefault();
		const {
			UIContext: { showModal },
		} = this.props;
		showModal({
			title: <FormattedHTMLMessage id={'rod.modal.terms.header'} />,
			text: <FormattedHTMLMessage id={'rod.modal.terms.text'} />,
			width: 8,
		})();
	};

	handleOnRepertoireCount = (e) => {
		this.setState({ repertoireCount: e });
		this.setState({ errFromRepertoire: false });
	};

	handleOnEmail = (e) => {
		this.setState({ email: e });
		this.setState({ errFromEmail: false });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		const { errors } = this.props;
		const email = 'email';
		const maxLength = 'max_length';
		const worksNumber = 'works_number';
		const minRepertoire = 'min_repertoire_value';

		errors[worksNumber] && delete errors[worksNumber];
		errors[email] && delete errors[email];

		if (parseInt(this.state.repertoireCount) <= 0) {
			const err = showError(worksNumber)({ rule: minRepertoire });

			this.setState({ errFromRepertoire: true });
			errors[worksNumber] = [{ rule: minRepertoire }];

			return err;
		}

		if (this.state.email.length > 100) {
			const err = showError(email)({ rule: maxLength });

			this.setState({ errFromEmail: true });
			errors[email] = [{ rule: maxLength, value: '100' }];

			return err;
		}

		if (this.props.onSubmit) {
			this.props.onSubmit({
				works_number: this.count.current.value,
				company: this.company.current.value,
				name: this.name.current.value,
				email: this.email.current.value,
				phone: this.phone.current.value,
			});
		}
	};

	render() {
		const { errors } = this.props;
		return (
			<div ref={this.form} className={styles.Form}>
				<div className={styles.Title}>
					<Label className={styles.header}>
						<FormattedMessage id={'rod.registration.special.header'} />
					</Label>
					<Label className={styles.subheader}>
						<FormattedMessage id={'rod.registration.special.subheader'} />
					</Label>
				</div>

				<div className={styles.Fields}>
					<FormattedMessage id={'rod.field.repertoire_count'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								value={this.state.repertoireCount}
								placeholder={placeholder}
								forwardRef={this.count}
								errors={errors.works_number}
								showError={showError('works_number')}
								type="number"
								numberNonNegative={true}
								onChange={this.handleOnRepertoireCount}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.name'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								placeholder={placeholder}
								forwardRef={this.name}
								showError={showError('name')}
								errors={errors.name}
								inputMaxLength={255}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.company'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								placeholder={placeholder}
								forwardRef={this.company}
								showError={showError('company')}
								errors={errors.company}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.email'}>
						{(placeholder) => (
							<Input
								value={this.state.email}
								className={styles.Input}
								placeholder={placeholder}
								forwardRef={this.email}
								showError={showError('email')}
								errors={errors.email}
								onChange={this.handleOnEmail}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.phone'}>
						{(placeholder) => (
							<PhoneInput
								placeholder={placeholder}
								ref={this.phone}
								showError={showError('phone')}
								required
								errors={errors.phone}
							/>
						)}
					</FormattedMessage>

					<Checkbox
						className={styles.Checkbox}
						ref={this.agreedCheckbox}
						onChange={this.handleAgreed}
						required
					>
						<FormattedMessage
							id={'rod.register.terms'}
							values={{
								a: (msg) => (
									<button
										className={styles.Terms}
										onClick={this.handleShowTerms}
									>
										{msg}
									</button>
								),
							}}
						/>
					</Checkbox>
				</div>

				<div className={styles.Controls}>
					<Button
						className={styles.Button}
						text={<FormattedMessage id={'rod.action.send_request'} />}
						type={'submit'}
						onClick={this.handleSubmit}
						variant={'primary'}
						disabled={!this.state.agreed || this.props.isRegisterInProcess}
					/>
				</div>
			</div>
		);
	}
}

export default compose(withUI)(RegisterSpecial);

//qwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwertyuiopqwert
