// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

// UI
import { Loading } from 'components';
import { EmailCheckForm } from 'forms';
import { withAuth, withRoot, withUI } from 'hocs';

// Styles
import styles from './EmailCheck.module.css';

class EmailCheck extends PureComponent {
	static propTypes = {};

	componentDidMount() {
		const { code } = this.props.match.params;

		const {
			authContext: { verifyEmail },
		} = this.props;
		if (code) {
			verifyEmail({ code });
		}
	}

	componentWillUnmount() {}

	render() {
		const {
			authContext: { verifyEmail, errors, clearAllErrors, isAuth },
		} = this.props;

		const { code } = this.props.match.params;
		if (!isAuth) {
			return <Loading />;
		}

		if (isAuth && code) {
			return this.props.history.push('/accounts');
		}
		return (
			<div className={styles.Page}>
				<EmailCheckForm
					onSubmit={verifyEmail}
					clearAllErrors={clearAllErrors}
					errors={errors}
				/>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI, withRouter)(EmailCheck);
