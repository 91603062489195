// Core
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { accounts } from 'services';

// UI
import { BottomNavi, InfoHelper } from 'components';
import { DropZone } from 'components/Upload/DropZone';

// Styles
import s from './Cover.module.css';

function Cover(props) {
	const [cover, setCover] = useState('');
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);
	const [uploadProgressPercents, setUploadProgressPercents] = useState({});

	const releaseId = localStorage.getItem('releaseId');
	const history = useHistory();

	const handleOnLoad = () => {
		setLoading(false);
	};

	const nextHandler = () => {
		if (typeof props.onChange === 'function') {
			props.onChange();
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			history.push(`/release/${releaseId}/lyrics`);
			props.handlePrevStep();
		}
	};

	const deleteCover = () => {
		accounts
			.deleteReleaseCover(releaseId)
			.then(() => {
				setCover('');
			})
			.catch((error) => {
				console.info('Error', error);
			});
	};

	const handleFileUpload = (files) => {
		setErrors({});

		if (
			files[0].type !== 'image/jpg"' &&
			files[0].type !== 'image/jpeg' &&
			files[0].type !== 'image/png'
		) {
			setErrors({ file: [{ rule: 'image_wrong_format' }] });
			return;
		}

		const fd = new FormData();
		fd.append('file', files[0]);
		const config = {
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);

				if (+progressEvent.loaded !== +progressEvent.total) {
					setUploadProgressPercents({ value: percentCompleted });
				}
			},
		};

		setLoading(true);
		accounts
			.setReleaseCover(releaseId, fd, config)
			.then((res) => {
				setCover(res.data.data.url);
				setUploadProgressPercents({});

				setTimeout(() => {
					setLoading(false);
				}, 1500);
			})
			.catch((err) => {
				if (err?.response.data.errors) {
					setErrors({ file: err.response.data.errors.file });
				}
				setLoading(false);
			});

		return Promise.resolve();
	};

	useEffect(() => {
		accounts
			.getReleaseData(releaseId)
			.then((res) => {
				if (res.data.data.cover?.url) {
					setLoading(true);
					setCover(res.data.data.cover.url);
				} else setLoading(false);
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={s.page}>
				<div className={s.main}>
					<span className={s.title}>
						<FormattedMessage id={'rod.release.create.step.cover'} />
					</span>
					<div className={s.body}>
						<div className={s.DropZone__wrapper}>
							{cover || loading ? (
								<div className={s.Preview}>
									{loading && <div className={s.preLoader} />}
									<img
										style={{ display: loading ? 'none' : 'block' }}
										className={loading ? s.loader : null}
										src={cover}
										onLoad={handleOnLoad}
										alt=""
									/>
								</div>
							) : (
								!loading && (
									<>
										<DropZone
											multiple={false}
											accept=".jpg, .jpeg, .png"
											imgType={2}
											classStyles={s.DropZone}
											dropzoneId={'rod.release.create.step.cover.upload'}
											onUpload={handleFileUpload}
											kb
											maxSize={1024 * 1024 * 100}
										/>
									</>
								)
							)}
						</div>
						{cover.length > 0 && !loading && (
							<div className={s.delete} onClick={() => deleteCover()}>
								<FormattedMessage id={'rod.release.create.step.cover.delete'} />
							</div>
						)}
					</div>

					<div className={s.button__wrapper}>
						<div className={s.input}>
							{Object.keys(errors).includes('file') && (
								<div className={s.errorHelper}>
									{errors &&
										errors.file.map((err) => (
											<>
												<FormattedMessage
													id={`rod.error.${err.rule}`}
													values={err.value && { value: +err.value / 1000 }}
												/>
												<br />
											</>
										))}
								</div>
							)}
						</div>
					</div>
				</div>
				<InfoHelper text="rod.release.create.step.cover.description" />
			</div>
			<BottomNavi
				showPrevBtn
				disabled={!cover}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
}

export default Cover;
