// Core
import React, { useEffect, useRef } from 'react'; // Icons
import arrow from 'images/arrow.svg';

// Styles
import s from '../File.module.css';

const Board = ({ index, handleToogle, handleGoUP, handleGoDown }) => {
	const rootEl = useRef(null);

	useEffect(() => {
		const onClick = (e) => {
			rootEl.current?.contains(e.target) || handleToogle(index);
		};
		document.addEventListener('click', onClick);
    
		return () => document.removeEventListener('click', onClick);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hide = () => {
		handleToogle(index);
	};

	const goUp = () => {
		handleGoUP(index);
		hide();
	};

	const goDown = () => {
		handleGoDown(index);
		hide();
	};

	return (
		<div className={s.board} ref={rootEl}>
			<div className={s.board__item} onClick={() => goUp()}>
				<img className={s.arrow__up} src={arrow} alt="" />
				<span>на позицию выше</span>
			</div>
			<div className={s.board__item} onClick={() => goDown()}>
				<img className={s.arrow__down} src={arrow} alt="" />
				<span>на позицию ниже</span>
			</div>
		</div>
	);
};

export default Board;
