/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from './DistributionListSection.module.css';
import noReports from 'images/noReports.svg';
import { useContext, useEffect, useState, useCallback } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Loading, NoData } from 'components';
import plusImg from 'images/plus.svg';
import { Pagination, InputWithClear } from 'material-design/components';
import filter_svg from 'images/filter.svg';
import arrow_down_grey from 'images/arrow_down_grey.svg';
import arrow_up_grey from 'images/arrow_up.svg';
import more from 'images/more.svg';
import moreWhite from 'images/moreWhite.svg';
import { Col, Row } from 'react-grid-system';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';
import { dateFormatEditor } from 'logic';
import { Link, useHistory } from 'react-router-dom';
import modalTypes from 'constants/modalTypes';
import debounce from 'lodash.debounce';
import cloneDeep from 'lodash/cloneDeep';

const DistributionList = () => {
	const [noData, setNoData] = useState(false);
	const [loading, setLoading] = useState(false);
	const [filteredDataLoading, setFilteredDataLoading] = useState(false);
	const [reports, setReports] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noFilteredData, setNoFilteredData] = useState(false);
	const [titleSortEnable, setTitleSortEnable] = useState(false);
	const [dateSortEnable, setDateSortEnable] = useState(false);
	const [filterValue, setFilterValue] = useState('');
	const [tmpSortingOrder, setTmpSortingOrder] = useState([]);
	const lang = localStorage.getItem('lang');
	const [isShowMenuMore, setIsShowMenuMore] = useState(false);
	const [isShowMenuMoreId, setIsShowMenuMoreId] = useState('');

	const {
		getAdminReportsDistributions,
		createAdminReportsDistributions,
		removeAdminReportsDistributions,
		putAdminDistributionReportArchive,
	} = useContext(RootContext);
	const { showModal } = useContext(UIContext);

	const history = useHistory();

	useEffect(() => {
		setLoading(true);
		getDistributions();
	}, [page]);

	const getDistributions = () => {
		getAdminReportsDistributions(page, filterValue)
			.then((res) => {
				setTotal(res.total);
				if (res.data.length) {
					if (noData) setNoData(false);
					res = res.data.sort(
						(a, b) =>
							new Date(b.date_publication) - new Date(a.date_publication)
					);

					const correctedDates = correctDatesView(res);
					setReports(correctedDates);

					setTmpSortingOrder([...res]);
				} else {
					setNoData(true);
				}
			})
			.catch((err) => {
				console.info('Error', err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleFilter = (filter) => {
		setNoFilteredData(false);
		setFilterValue(filter);
		setPage(1);
		if (filter.length >= 3) {
			setFilteredDataLoading(true);
			getAdminReportsDistributions(1, filter)
				.then((res) => {
					setTotal(res.total);
					if (res.data.length) {
						if (noFilteredData) setNoFilteredData(false);
						res = res.data.sort(
							(a, b) =>
								new Date(b.date_publication) - new Date(a.date_publication)
						);

						const correctedDates = correctDatesView(res);
						setReports(correctedDates);

						setTmpSortingOrder([...res]);
					} else {
						setNoFilteredData(true);
					}
				})
				.catch((err) => {
					console.info('Error', err);
				})
				.finally(() => {
					setFilteredDataLoading(false);
				});
		} else if (!filter) {
			setFilteredDataLoading(true);
			getAdminReportsDistributions(1, '')
				.then((res) => {
					setTotal(res.total);
					if (res.data.length) {
						if (noFilteredData) setNoFilteredData(false);
						res = res.data.sort(
							(a, b) =>
								new Date(b.date_publication) - new Date(a.date_publication)
						);

						const correctedDates = correctDatesView(res);
						setReports(correctedDates);

						setTmpSortingOrder([...res]);
						// setTmpSortingOrder(correctedDates);
					} else {
						setNoFilteredData(true);
					}
				})
				.catch((err) => {
					console.info('Error', err);
				})
				.finally(() => {
					setFilteredDataLoading(false);
				});
		}
	};

	// const handleFilter = useCallback(debounce(onChange, 500), []);

	const correctDatesView = (res) => {
		const result = res.map((item, index) => {
			const date_publication_corrected =
				item.date_publication.replace(' ', 'T') + 'Z';
			const date_publication = new Date(date_publication_corrected);

			const time_publication =
				date_publication.getHours().toString().padStart(2, '0') +
				':' +
				date_publication.getMinutes().toString().padStart(2, '0');
			return {
				...item,

				date_publication: dateFormatEditor(date_publication, 'dmy', '.'),
				time_publication: time_publication,
			};
		});
		return result;
	};

	const createDistribution = (data, date_publication) => {
		createAdminReportsDistributions(data, date_publication)
			.then((res) => {
				refreshList();
			})
			.catch((err) => {
				console.info('Error:', err);
			});
	};

	const editDistribution = (distributionId, data) => {
		putAdminDistributionReportArchive(distributionId, data)
			.then((res) => {
				refreshList();
			})
			.catch((err) => {
				console.info('Error:', err);
			});
	};

	const refreshList = () => {
		setLoading(true);
		getDistributions();
	};

	const handleRemoveDistribution = (id) => {
		removeAdminReportsDistributions(id)
			.then((res) => {
				refreshList();
			})
			.catch((err) => {
				console.info('Error:', err);
			});
	};

	const handleTitleSort = () => {
		if (titleSortEnable) {
			setTitleSortEnable(false);
			const correctedDates = correctDatesView(tmpSortingOrder);
			setReports(correctedDates);
		} else if (!titleSortEnable && dateSortEnable) {
			const data =
				lang === 'en'
					? reports.sort((a, b) =>
							a.heading_en?.toLowerCase() > b.heading_en?.toLowerCase() ? 1 : -1
					  )
					: reports.sort((a, b) =>
							a.heading?.toLowerCase() > b.heading?.toLowerCase() ? 1 : -1
					  );

			setReports([...data]);
			setTitleSortEnable(true);
			setDateSortEnable(false);
		} else {
			const data =
				lang === 'en'
					? reports.sort((a, b) =>
							a.heading_en?.toLowerCase() > b.heading_en?.toLowerCase() ? 1 : -1
					  )
					: reports.sort((a, b) =>
							a.heading?.toLowerCase() > b.heading?.toLowerCase() ? 1 : -1
					  );

			setReports([...data]);
			setTitleSortEnable(true);
		}
	};
	const handleDateSort = () => {
		if (dateSortEnable) {
			setDateSortEnable(false);
			const correctedDates = correctDatesView(tmpSortingOrder);
			setReports(correctedDates);
		} else if (titleSortEnable && !dateSortEnable) {
			const data = [...tmpSortingOrder].sort(
				(a, b) => new Date(a.date_publication) - new Date(b.date_publication)
			);
			const correctedDates = correctDatesView(data);
			setReports(correctedDates);
			setTitleSortEnable(false);
			setDateSortEnable(true);
		} else {
			const data = [...tmpSortingOrder].sort(
				(a, b) => new Date(a.date_publication) - new Date(b.date_publication)
			);

			const correctedDates = correctDatesView(data);
			setReports(correctedDates);
			setDateSortEnable(true);
		}
	};
	const handleClickMore = (index) => {
		setIsShowMenuMoreId(index);
		setIsShowMenuMore(true);
	};

	const handleOnBlurMenuMore = () => {
		setIsShowMenuMoreId('');
		setIsShowMenuMore(false);
	};

	return (
		<div
			className={styles.mainWrapper}
			style={
				noFilteredData || noData || loading
					? { overflow: 'hidden' }
					: { overflow: 'unset' }
			}
		>
			{noData ? (
				<div className={styles.noData}>
					<div
						className={styles.uploadReportBtn}
						onClick={showModal(
							{
								// prepareFileToUpload: prepareFileToUpload,
								// postReportArchiveRequest: postReportArchiveRequest,
								refreshList: refreshList,
								createDistribution: createDistribution,
							},
							'create_distribution'
						)}
					>
						<img src={noReports} alt="noReports" className={styles.noReports} />
						<div>
							<FormattedHTMLMessage id={'rod.admin.reports.noData'} />
						</div>
					</div>
				</div>
			) : (
				<>
					{loading ? (
						<Loading /* non_margin={true}  */ />
					) : (
						<>
							<div className={styles.sectionTitleWrapper}>
								<div className={styles.filterWrapper}>
									<FormattedHTMLMessage id={'rod.distribution_name.find'}>
										{(placeholder) => (
											<InputWithClear
												handleOnChange={handleFilter}
												placeholder={placeholder}
												// initialValue={filterValue}
											/>
										)}
									</FormattedHTMLMessage>
									{/* <img
										src={filter_svg}
										alt="filter"
										className={styles.filterIcon}
										// onClick={modalOpen}
									/> */}
								</div>
								<div className={styles.topBtnsWrapper}>
									<div
										className={styles.createArticleBtn}
										onClick={showModal(
											{
												// prepareFileToUpload: prepareFileToUpload,
												// postReportArchiveRequest: postReportArchiveRequest,
												refreshList: refreshList,
												createDistribution: createDistribution,
											},
											'create_distribution'
										)}
									>
										<img src={plusImg} alt="add" className={styles.iconPlus} />

										<FormattedHTMLMessage
											id={'rod.admin.reports.add_distribution'}
										/>
									</div>
								</div>
							</div>
							{filteredDataLoading ? (
								<Loading /* non_margin */ />
							) : noFilteredData ? (
								<NoData />
							) : (
								<div className={styles.mainInfoTable}>
									<Row className={styles.row_header}>
										<Col width={'70%'}>
											<FormattedHTMLMessage id={'rod.for_all.title'} />
											{!titleSortEnable ? (
												<img
													src={arrow_down_grey}
													alt=""
													className={styles.arrow_down}
													onClick={handleTitleSort}
												/>
											) : (
												<img
													src={arrow_up_grey}
													alt=""
													className={styles.arrow_down}
													onClick={handleTitleSort}
												/>
											)}
										</Col>
										<Col width={'20%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.release_date'}
											/>
											{!dateSortEnable ? (
												<img
													src={arrow_down_grey}
													alt=""
													className={styles.arrow_down}
													onClick={handleDateSort}
												/>
											) : (
												<img
													src={arrow_up_grey}
													alt=""
													className={styles.arrow_down}
													onClick={handleDateSort}
												/>
											)}
										</Col>
										<Col width={'10%'}>
											{/* <FormattedHTMLMessage id={'rod.reports_page.action'} /> */}
										</Col>
									</Row>
									<div className={styles.MainInfoTable__body}>
										<ul>
											{reports.map((item, index) => (
												<li>
													<div className={styles.reportsListItem}>
														<Row nowrap className={styles.row_data}>
															<Row nowrap className={styles.row_title}>
																<Col width={'70%'} className={styles.shell}>
																	<a
																		onClick={(e) =>
																			history.push(
																				`/admin/v2/reports/distributions/${item.id}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		{lang === 'en'
																			? item.heading_en
																			: item.heading}
																	</a>
																</Col>
																<Col width={'20%'} className={styles.shell}>
																	<a
																		onClick={(e) =>
																			history.push(
																				`/admin/v2/reports/distributions/${item.id}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		{item.date_publication}
																	</a>
																</Col>
																<Col width={'10%'} className={styles.shell}>
																	{isShowMenuMore &&
																	isShowMenuMoreId === index ? (
																		<div
																			className={styles.menuMoreWrapperActive}
																			tabindex="0"
																			onBlur={handleOnBlurMenuMore}
																		>
																			<img
																				src={moreWhite}
																				alt="visible"
																				className={styles.menuMore}
																			/>
																			<ul className={styles.menuMoreList}>
																				<li
																					className={styles.menuMoreListItem}
																					onClick={showModal(
																						{
																							// prepareFileToUpload: prepareFileToUpload,
																							// postReportArchiveRequest: postReportArchiveRequest,
																							itemData: item,
																							refreshList,
																							editDistribution,
																						},
																						'create_distribution'
																					)}
																				>
																					{
																						<span>
																							<FormattedMessage
																								id={`rod.admin.reports.edit`}
																							/>
																						</span>
																					}
																				</li>

																				<li
																					className={styles.menuMoreListItem}
																					// onClick={() => {
																					// 	handleRemoveDistribution(item.id);
																					// }}
																					onClick={showModal(
																						{
																							title: (
																								<FormattedMessage
																									id={
																										'rod.distribution.delete-confirm'
																									}
																								/>
																							),
																							text: (
																								<FormattedMessage
																									id={
																										'rod.distribution.delete-help'
																									}
																								/>
																							),
																							onAction: () => {
																								handleRemoveDistribution(
																									item.id
																								);
																							},
																							confirmBtnTxt: (
																								<FormattedMessage
																									id={'rod.modal.yes_delete'}
																								/>
																							),
																							declineBtnTxt: (
																								<FormattedMessage
																									id={'rod.modal.cancel'}
																								/>
																							),
																						},
																						modalTypes.FULL_MODAL
																					)}
																				>
																					<FormattedHTMLMessage
																						id={'rod.drafts.delete'}
																					/>
																				</li>
																			</ul>
																		</div>
																	) : (
																		<div
																			className={styles.menuMoreWrapper}
																			tabindex="0"
																			onClick={() => handleClickMore(index)}
																			onBlur={handleOnBlurMenuMore}
																		>
																			<img
																				src={more}
																				alt="hidden"
																				className={styles.menuMore}
																			/>
																		</div>
																	)}
																</Col>
																{/* <Col width={'10%'} className={styles.shell}>
																		<div
																			className={styles.actionTitle}
																			style={{ paddingTop: '1px' }}
																			onClick={() => {
																				handleRemoveDistribution(item.id);
																			}}
																		>
																			<FormattedHTMLMessage
																				id={'rod.drafts.delete'}
																			>
																				{(msg) => (
																					<span >{msg}</span>
																				)}
																			</FormattedHTMLMessage>
																		</div>
																	</Col> */}
															</Row>
														</Row>
													</div>
													{/* </a> */}
												</li>
											))}
										</ul>
									</div>
								</div>
							)}
							{total > 15 &&
								!loading &&
								!filteredDataLoading &&
								!noFilteredData && (
									<div style={{ marginTop: '30px', marginBottom: '30px' }}>
										<Pagination
											page={page}
											total={total}
											paginate={(pageNumber) => {
												setPage(pageNumber);
											}}
											items_on_page={15}
										/>
									</div>
								)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default DistributionList;
