// Langs
import pagesEn from 'messages/EN/pages';
import pagesRu from 'messages/RU/pages';

export const filterReleaseSettingsData = [
	{
		id: 1,
		title_ru: 'статус',
		title_en: 'status',
		list: [
			{
				id: 1,
				name_ru: pagesRu['rod.draft.release.moderation'],
				name_en: pagesEn['rod.draft.release.moderation'],
				code: 'draft_processing',
				checked: false,
			},
			{
				id: 2,
				name_ru: pagesRu['rod.treaties_page.pending_review'],
				name_en: pagesEn['rod.treaties_page.pending_review'],
				code: 'draft_verify',
				checked: false,
			},
			{
				id: 3,
				name_ru: pagesRu['rod.repertoire.pending'],
				name_en: pagesEn['rod.repertoire.pending'],
				code: 'pending',
				checked: false,
			},
			{
				id: 4,
				name_ru: pagesRu['rod.repertoire.rejected'],
				name_en: pagesEn['rod.repertoire.rejected'],
				code: 'rejected',
				checked: false,
			},
			{
				id: 5,
				name_ru: pagesRu['rod.repertoire.not_ready'],
				name_en: pagesEn['rod.repertoire.not_ready'],
				code: 'not_ready',
				checked: false,
			},
			{
				id: 6,
				name_ru: pagesRu['rod.repertoire.approved'],
				name_en: pagesEn['rod.repertoire.approved'],
				code: 'approved',
				checked: false,
			},
			{
				id: 7,
				name_ru: pagesRu['rod.release.info.ready_for_delivery'],
				name_en: pagesEn['rod.release.info.ready_for_delivery'],
				code: 'ready',
				checked: false,
			},
			{
				id: 8,
				name_ru: pagesRu['rod.repertoire.shipping_queue'],
				name_en: pagesEn['rod.repertoire.shipping_queue'],
				code: 'shipping_queue',
				checked: false,
			},
			{
				id: 9,
				name_ru: pagesRu['rod.repertoire.delivered'],
				name_en: pagesEn['rod.repertoire.delivered'],
				code: 'shipped',
				checked: false,
			},
			{
				id: 10,
				name_ru: pagesRu['rod.repertoire.takedown_queue'],
				name_en: pagesEn['rod.repertoire.takedown_queue'],
				code: 'takedown_queue',
				checked: false,
			},

			{
				id: 11,
				name_ru: pagesRu['rod.repertoire.takendown'],
				name_en: pagesEn['rod.repertoire.takendown'],
				code: 'takendown',
				checked: false,
			},
			{
				id: 12,
				name_ru: pagesRu['rod.repertoire.draft_verify'],
				name_en: pagesEn['rod.repertoire.draft_verify'],
				code: 'verify',
				checked: false,
			},
		],
	},
];
