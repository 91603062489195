// Core
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Services
import { accounts } from 'services';
import { compose } from 'recompose';
import { withUI, withAuth, withRoot } from 'hocs';

// UI
import { Loading } from 'components';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import {
	RepertoireReleaseInfo,
	RepertoireReleaseNotices,
	RepertoireReleaseModeration,
	RepertoireReleaseShipments,
} from './RepertoireReleaseTab';

// Icons
import chevronDownImg from 'images/chevron_down_black.svg';
import chevronUpImg from 'images/chevron_up_white.svg';
import icon_cd from 'images/icon_cd.svg';

// Styles
import { HeaderButton } from './HeaderButton.styled';
import styles from './RepertoireRelease.module.css';

function RepertoirePhonogram(props) {
	const [loading, setLoading] = useState(true);
	const [deliveryData, setDeliveryData] = useState([]);
	const [infoFromRelease, setInfoFromRelease] = useState({});
	const [isReleaseReady, setIsReleaseReady] = useState(false);
	const [defaultTab, setDefaultTab] = useState('');
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [shipmentsArr, setShipmentsArr] = useState([]);
	const [takenDownsArr, setTakenDownsArr] = useState([]);
	const [isShowDistributionBtns, setIsShowDistributionBtns] = useState(false);
	const [isShipmentsBtnEnable, setIsShipmentsBtnEnable] = useState(true);
	const [isShipmentsInProcess, setIsShipmentsInProcess] = useState(false);
	const [isTakenDownsBtnEnable, setIsTakenDownsBtnEnable] = useState(true);
	const [isTakenDownsInProcess, setIsTakenDownsInProcess] = useState(false);
	const [disabledBtnsReasonText, setDisabledBtnsReasonText] = useState('');
	const [
		isReleaseCanShipsOrTakeDowns,
		setIsReleaseCanShipsOrTakeDowns,
	] = useState(true);
	const [allOutlets, setAllOutlets] = useState([]);
	const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
	const [isReleaseInQueue, setIsReleaseInQueue] = useState(false);
	const [moderationStatus, setModerationStatus] = useState('');
	const [hasPriveleges, setHasPriveleges] = useState(false);

	const {
		UIContext: {
			showBackNavi,
			changeBackTargetUrl,
			setPrevRepertoireParams,
			hideBackNavi,
			isShipmentsTakedownsEnable,
		},
		authContext: { accountId },
		rootContext: { getReleaseShipments, getReleaseTakedowns },
	} = props;

	const { id: releaseId } = useParams();
	const history = useHistory();
	const match = useRouteMatch();
	const lang = localStorage.getItem('lang');

	useEffect(() => {
		accounts
			.getReleaseInfoForBrowsing(accountId, releaseId)
			.then((res) => {
				res = res.data.data;

				if (res.hasOwnProperty('has_privileges')) {
					setHasPriveleges(res.has_privileges);
				}

				const isSomeOutletsWaitingQueue = Object.values(
					res.queue_statuses
				).find((item) => item.status === 'queued');

				if (isSomeOutletsWaitingQueue) {
					setIsShipmentsBtnEnable(false);
					setIsTakenDownsBtnEnable(false);
				}

				setReleaseTypeId(res.release_type_id);
				setIsReleaseReady(res?.statuses?.includes('not_ready') ? false : true);

				switch (true) {
					case res?.moderation_status === 'not_ready':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('not_ready');
						setIsShowDistributionBtns(false);
						setIsReleaseCanShipsOrTakeDowns(false);
						break;
					case res?.moderation_status === 'ready':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('ready');
						setIsShowDistributionBtns(false);
						setIsReleaseCanShipsOrTakeDowns(false);
						break;
					case res?.moderation_status === 'rejected':
						setIsShipmentsBtnEnable(false);
						break;
					case res?.moderation_status === 'pending':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('pending');
						setIsShowDistributionBtns(false);
						setIsReleaseCanShipsOrTakeDowns(false);
						break;
					case res?.moderation_status === 'disputed':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('disputed');
						setIsShowDistributionBtns(false);
						setIsReleaseCanShipsOrTakeDowns(false);
						break;

					default:
						break;
				}
				accounts
					.getReleaseModeration(accountId, releaseId, lang)
					.then((res) => {
						res = res.data.data;

						setModerationStatus(res.status);
					})
					.catch((error) => {
						console.error('Error', error);
						setLoading(false);
					});
			})
			.catch((error) => {
				console.error('Error', error);
				setLoading(false);
			});

		accounts
			.getReleaseDelivery(accountId, releaseId)
			.then((res) => {
				setDeliveryData(res.data.data);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		changeBackTargetUrl('/repertoire/releases');
		showBackNavi('/repertoire/releases', null, 'rod.action.back_to_list');

		return function cleanup() {
			hideBackNavi();
			setPrevRepertoireParams({ page: 1 });
			setIsShowDistributionBtns(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (moderationStatus) {
			if (match.path.includes('info')) {
				setDefaultTab(0);
			} else if (match.path.includes('notices')) {
				setDefaultTab(1);
			} else if (match.path.includes('moderation')) {
				setDefaultTab(1);
			} else if (match.path.includes('deliveries')) {
				setDefaultTab(
					!isReleaseReady ||
						['pending', 'rejected'].includes(moderationStatus) ||
						isReleaseInQueue
						? 2
						: 1
				);
			} else {
				setDefaultTab(0);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moderationStatus]);

	useEffect(() => {
		if (releaseTypeId) {
			accounts
				.getOutletsWithTypes(lang, releaseTypeId)
				.then((outlets) => {
					outlets = outlets.data.data;

					setAllOutlets(outlets);
				})
				.catch((error) => {
					console.info('Error', error);
				});
		}
	}, [releaseTypeId]);

	useEffect(() => {
		if (allOutlets.length) {
			const shipmentsMatchingWithId = [];
			const shipmentsMatchingWithIdAndStatus = [];
			const takeDownsMatchingWithId = [];
			const takeDownsMatchingWithIdAndStatus = [];

			getReleaseShipments(accountId, releaseId)
				.then((res) => {
					if (!res || !res.length) {
						setIsShipmentsBtnEnable(false);
						setIsReleaseInQueue(false);
						if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
					} else {
						const isInProcess = res.find(
							(item) =>
								item.status === 'shipping_queue' ||
								item.status === 'takedown_queue'
						);
						setIsReleaseInQueue(isInProcess ? true : false);
						const shipmentIds = res.map((item) => item.id);

						allOutlets.map((item) => {
							if (shipmentIds.includes(item.id)) {
								shipmentsMatchingWithId.push(item.id);
								const outlet = res.find((outlet) => outlet.id === item.id);
								if (outlet.status === 'shipping')
									shipmentsMatchingWithIdAndStatus.push(outlet.id);
							}
						});

						if (
							shipmentsMatchingWithId.length ===
							shipmentsMatchingWithIdAndStatus.length
						) {
							setIsShipmentsBtnEnable(false);
							if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
						} else if (isInProcess && Object.keys(isInProcess).length) {
							setIsShipmentsBtnEnable(false);

							isInProcess.status === 'shipping_queue'
								? setIsShipmentsInProcess(true)
								: setIsShipmentsInProcess(false);

							if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
							setDefaultTab(1);
						} else {
							setShipmentsArr(res);
							setIsShipmentsInProcess(false);
							if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
						}
					}
					setLoading(false);
				})
				.catch((err) => {
					console.info('Error:', err);
					setLoading(false);
				});

			getReleaseTakedowns(accountId, releaseId)
				.then((res) => {
					if (!res || !res.length) {
						setIsTakenDownsBtnEnable(false);
						setIsReleaseInQueue(false);
						if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
					} else {
						const isInProcess = res.find(
							(item) =>
								item.status === 'takedown_queue' ||
								item.status === 'shipping_queue'
						);
						setIsReleaseInQueue(isInProcess ? true : false);
						const takeDownsIds = res.map((item) => item.id);

						allOutlets.map((item) => {
							if (takeDownsIds.includes(item.id)) {
								takeDownsMatchingWithId.push(item.id);
								const outlet = res.find((outlet) => outlet.id === item.id);
								if (
									outlet.status !== 'shipping' &&
									outlet.status !== 'takedown_queue'
								)
									takeDownsMatchingWithIdAndStatus.push(outlet.id);
							}
						});

						if (
							takeDownsMatchingWithId.length ===
							takeDownsMatchingWithIdAndStatus.length
						) {
							setIsTakenDownsBtnEnable(false);
							if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
						} else if (isInProcess && Object.keys(isInProcess).length) {
							setIsTakenDownsBtnEnable(false);

							isInProcess.status === 'takedown_queue'
								? setIsTakenDownsInProcess(true)
								: setIsTakenDownsInProcess(false);

							if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
						} else {
							setTakenDownsArr(res);
							setIsTakenDownsInProcess(false);
							if (isReleaseCanShipsOrTakeDowns) setIsShowDistributionBtns(true);
						}
					}
					setLoading(false);
				})
				.catch((err) => {
					console.error('Error:', err);
					setLoading(false);
				});
		}
	}, [allOutlets]);

	const getInfo = (data) => {
		setInfoFromRelease(data);
	};

	const getLink = (name) => {
		switch (name) {
			case 'info':
				return history.push(`/repertoire/releases/${releaseId}/info`);
			case 'notices':
				return history.push(`/repertoire/releases/${releaseId}/notices`);
			case 'moderation':
				return history.push(`/repertoire/releases/${releaseId}/moderation`);
			case 'deliveries':
				return history.push(`/repertoire/releases/${releaseId}/deliveries`);

			default:
				history.push(`/repertoire/releases/${releaseId}/info`);
				break;
		}
	};

	const handleActionBtn = () => {
		setIsActionMenuVisible((prev) => !prev);
	};

	const handleBlur = (e) => {
		if (!e.relatedTarget?.attributes?.value) {
			handleActionBtn();
		}
	};

	return (
		<>
			{loading ? (
				<Loading className={styles.loader} />
			) : (
				<>
					<div className={styles.header}>
						<div>
							<img
								className={styles.repertoireSong__logo}
								src={icon_cd}
								width="32px"
								height="32px"
								alt=""
							/>
							<span className={styles.title}>{infoFromRelease.title}</span>
						</div>
						<div className={styles.rightSideBtnsWrapper}>
							<span className={styles.ean}>
								<FormattedMessage id={'EAN/UPC'} />:
							</span>

							<span className={styles.ean_number}>
								{infoFromRelease.ean ? infoFromRelease.ean : '-'}
							</span>

							{isShipmentsTakedownsEnable &&
								isShowDistributionBtns &&
								hasPriveleges && (
									<div className={styles.shipmentActionsWrapper}>
										<div
											className={styles.actionBtn}
											onClick={handleActionBtn}
											tabindex="0"
											onBlur={handleBlur}
										>
											<span>
												<FormattedMessage id={'rod.field.admin.actions'} />
											</span>
											<div className={styles.Chevron}>
												<img src={chevronDownImg} alt="" />
											</div>
										</div>

										{isActionMenuVisible && (
											<div
												className={styles.shipmentBtnsWrapper}
												tabindex="0"
												onBlur={() => {
													handleActionBtn();
												}}
												value={'actionChild'}
											>
												<div className={styles.shipmentBtnsHeader}>
													<span>
														<FormattedMessage id={'rod.field.admin.actions'} />
													</span>
													<div className={styles.Chevron} onClick={handleBlur}>
														<img src={chevronUpImg} alt="" />
													</div>
												</div>
												{isShipmentsBtnEnable ? (
													<Link
														to={{
															pathname: `/repertoire/releases/${releaseId}/shipment`,
															state: {
																typeId: releaseTypeId,
																shipmentsArr: shipmentsArr,
															},
														}}
														className={styles.shipmentBtn}
														value={'actionChild'}
													>
														<FormattedMessage
															id={'rod.release.release_shipment'}
														/>
													</Link>
												) : (
													<div className={styles.shipmentBtnDisabled}>
														{disabledBtnsReasonText ? (
															<FormattedMessage
																id={`rod.repertoire.status.${disabledBtnsReasonText}`}
															/>
														) : isShipmentsInProcess ? (
															<FormattedMessage
																id={'rod.release.release_shipment_inProcess'}
															/>
														) : (
															<FormattedMessage
																id={'rod.release.release_shipment'}
															/>
														)}
													</div>
												)}
												{isTakenDownsBtnEnable ? (
													<Link
														to={{
															pathname: `/repertoire/releases/${releaseId}/takendown`,
															state: {
																typeId: releaseTypeId,
																takenDownsArr: takenDownsArr,
															},
														}}
														className={styles.fallbackBtn}
														value={'actionChild'}
													>
														<FormattedMessage
															id={'rod.release.release_fallback_enable'}
														/>
													</Link>
												) : (
													<div className={styles.fallbackBtnDisabled}>
														{disabledBtnsReasonText ? (
															<FormattedMessage
																id={`rod.repertoire.status.${disabledBtnsReasonText}`}
															/>
														) : isTakenDownsInProcess ? (
															<FormattedMessage
																id={'rod.release.release_fallback_inProcess'}
															/>
														) : (
															<FormattedMessage
																id={'rod.release.release_fallback'}
															/>
														)}
													</div>
												)}
											</div>
										)}
									</div>
								)}
						</div>
					</div>

					{defaultTab !== '' && (
						<TabsUnstyled
							defaultValue={defaultTab}
							className={styles.repertoireSong}
						>
							<TabsListUnstyled className={styles.repertoireSong__header}>
								<HeaderButton
									onClick={() => getLink('info')}
									className={styles.headerButton}
								>
									<FormattedMessage id={'rod.release.tab.main'} />
								</HeaderButton>

								{!isReleaseReady ? (
									<HeaderButton
										onClick={() => getLink('notices')}
										className={styles.headerButton}
									>
										<FormattedMessage id={'rod.release.tab.not_ready'} />
									</HeaderButton>
								) : (
									(['pending', 'rejected'].includes(moderationStatus) ||
										isReleaseInQueue) &&
									hasPriveleges && (
										<HeaderButton
											onClick={() => getLink('moderation')}
											className={styles.headerButton}
										>
											<FormattedMessage id={'rod.release.tab.moderation'} />
										</HeaderButton>
									)
								)}

								{hasPriveleges && deliveryData && deliveryData.length > 0 && (
									<HeaderButton
										onClick={() => getLink('deliveries')}
										className={styles.headerButton}
									>
										<FormattedMessage id={'rod.release.info.deliveries'} />
									</HeaderButton>
								)}
							</TabsListUnstyled>

							<div className={styles.repertoireSong__main}>
								<TabPanelUnstyled value={0}>
									<RepertoireReleaseInfo getInfo={getInfo} />
								</TabPanelUnstyled>
								{!isReleaseReady ? (
									<TabPanelUnstyled value={1}>
										<RepertoireReleaseNotices
											props={props}
											releaseId={releaseId}
											getInfo={getInfo}
										/>
									</TabPanelUnstyled>
								) : (
									(['pending', 'rejected'].includes(moderationStatus) ||
										isReleaseInQueue) && (
										<TabPanelUnstyled value={1}>
											<RepertoireReleaseModeration
												props={props}
												releaseId={releaseId}
												getInfo={getInfo}
												isReleaseInQueue={isReleaseInQueue}
											/>
										</TabPanelUnstyled>
									)
								)}
								<TabPanelUnstyled
									value={
										!isReleaseReady ||
										['pending', 'rejected'].includes(moderationStatus) ||
										isReleaseInQueue
											? 2
											: 1
									}
								>
									<RepertoireReleaseShipments
										props={props}
										releaseId={releaseId}
										getInfo={getInfo}
									/>
								</TabPanelUnstyled>
							</div>
						</TabsUnstyled>
					)}
				</>
			)}
		</>
	);
}
export default compose(withUI, withAuth, withRoot)(RepertoirePhonogram);
