// Core
import lscache from 'lscache';

export const getReleaseTypes = (
	isReqLive,
	lang,
	setReleaseTypes,
	accounts,
	setCache
) => {
	const releaseTypesCache = lscache.get('releaseTypesCache');
	if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
		setReleaseTypes(releaseTypesCache.data);
		return releaseTypesCache.data;
	} else {
		return accounts
			.getReleaseTypes(lang)
			.then((res) => {
				res = res.data.data;
				setCache('releaseTypes', 'releaseTypesCache', res);
				setReleaseTypes(res);
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				return Promise.reject();
			});
	}
};
