// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const ContentEmpty = (clickToCreateComposition) => {
	return (
		<span>
			<FormattedMessage
				id={'rod.composition.empty'}
				values={{
					a: (msg) => (
						<Link to="/compositions/create" onClick={clickToCreateComposition}>
							{msg}
						</Link>
					),
				}}
			/>
		</span>
	);
};

export default ContentEmpty;
