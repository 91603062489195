export const onEditReleaseHandler = (e, releaseId, accounts, history) => {
	e.preventDefault();

	accounts.updateStep(releaseId, { step: 'confirm' }).catch((error) => {
		console.error('Error', error);
	});

	localStorage.setItem('releaseId', releaseId);
	history.push(`/release/${releaseId}/confirm`);
};
