// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { TabPanelUnstyled, TabsListUnstyled, TabsUnstyled } from '@mui/material';
import { HeaderButton } from 'material-design/Repertoire/RepertoireCategory/RepertoireReleaseSection/RepertoireRelease/HeaderButton.styled';
import UserPayments from './UserPayments/UserPayments';
import UserReports from './UserReports/UserReports';

// Styles
import styles from './UserReportsWithPayment.module.css';

// Context
import { UIContext } from 'contexts/UIContext';

const UserReportsPage = (props) => {
	const { showTitle, showBackNavi, hideBackNavi, hideTitle, } = useContext(UIContext);

	const [currentTab, setCurrentTab] = useState(0);

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		showTitle('rod.navbar.reports');
		showBackNavi();

		const searchParams = new URLSearchParams(location.search);
		if (searchParams.get('tab')) {
			setCurrentTab(1);
			searchParams.delete('tab')
			history.replace({
				search: searchParams.toString(),
			})
		}
		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section>
			<TabsUnstyled value={currentTab} >
				<TabsListUnstyled className={styles.tabs__header}>
					<HeaderButton onClick={() => setCurrentTab(0)}>
						<FormattedMessage id={'rod.reports_page.nav.reports'}></FormattedMessage>
					</HeaderButton>
					<HeaderButton onClick={() => setCurrentTab(1)}>
						<FormattedMessage
							id={'rod.reports_page.nav.invoices'}
						></FormattedMessage>
					</HeaderButton>
				</TabsListUnstyled>
				<div>
					<TabPanelUnstyled value={0}>
						<UserReports />
					</TabPanelUnstyled>
					<TabPanelUnstyled value={1}>
						<UserPayments />
					</TabPanelUnstyled>
				</div>
			</TabsUnstyled>
		</section>
	)
};

export default UserReportsPage;
