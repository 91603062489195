// Core
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

// Components
import { Wizard } from 'components/Wizard';
import { Loading } from 'components';

// MaterialComponents
import releaseCompletionSteps from 'material-design/constants/releaseCompletionSteps';

// Contexts
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// Hooks
import { withRoot, withUI } from 'hocs';

// Steps
import { initialSteps } from './initialSteps';
import { authorsSteps } from './steps/Authors/authorsSteps';
import { confirmSteps } from './steps/Confirm/confirmSteps';
import { coverSteps } from './steps/Cover/coverSteps';
import { distributionSteps } from './steps/Distribution/distributionSteps';
import { lyricsSteps } from './steps/Lyrics/lyricsSteps';
import { fileSteps } from './steps/File/fileSteps';
import { tracksSteps } from './steps/Tracks/tracksSteps';

// Helpers
import { accounts } from 'services';
import { getPrevStep } from './steps/helpers/getPrevStep';
import { renderStep } from './helpers/renderStep';

// Styles
import styles from './ReleaseCreate.module.css';

const ReleaseCreate = (props) => {
	const { isTransferRelease, setIsTransferRelease } = useContext(UIContext);

	const [loading, setLoading] = useState(false);
	const [transferRelease, setTransferRelease] = useState(isTransferRelease);
	const [confirm, setConfirm] = useState(false);
	const [errors, setErrors] = useState({});
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [completionStep, setCompletionStep] = useState('');
	const [lastStep, setLastStep] = useState(null);
	const [defaultSteps, setDefaultSteps] = useState(initialSteps);

	const releaseId = localStorage.getItem('releaseId');
	const authContext = useContext(AuthContext);
	const history = useHistory();
	const location = useLocation();
	const params = useParams();

	const {
		UIContext: { showBackNavi, hideBackNavi, backTargetUrl, showTitle },
	} = props;

	useEffect(() => {
		const transferRelease = localStorage.getItem('isTransferRelease');
		if (transferRelease) {
			setTransferRelease(true);
			setIsTransferRelease(true);
		}
	}, [setIsTransferRelease]);

	const setRelease = (data) => {
		setErrors({});
		const id = authContext.accountId;
		const clone = cloneDeep(data);

		if (transferRelease) {
			clone.isTransferRelease = transferRelease;
			localStorage.removeItem('isTransferRelease');
		}

		const _promise = new Promise((resolve, reject) => {
			if (!clone.generate_ean) {
				delete data.generate_ean;
			}

			if (!clone.generate_catalog_number) {
				delete clone.generate_catalog_number;
			}

			if (clone.grid) {
				const preparedGrid = clone.grid.split('-').join('').toUpperCase();
				clone.grid = preparedGrid;
			}

			if (!releaseId) {
				clone.account_id = id;
				accounts
					.createRelease(clone)
					.then((res) => {
						localStorage.setItem('releaseId', res.data.data.id);
						setReleaseTypeId(res.data.data.release_type_id);
						resolve(res.data.data.id);
					})
					.catch((data) => {
						setErrors(data.response.data.errors);
						reject(false);
					});
			} else {
				if (clone.created_date.length === 19) {
					clone.created_date = data.created_date.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];
				}
				accounts
					.updateRelease(releaseId, clone)
					.then((res) => {
						setReleaseTypeId(res.data.data.release_type_id);
						resolve(res.data.data.id);
					})
					.catch((data) => {
						setErrors(data.response.data.errors);
						reject(false);
					});
			}
		});

		_promise
			.then((id) => {
				if (!releaseId) {
					accounts.updateStep(id, { step: 'file' }).catch((error) => {
						console.error('Error', error);
					});
					setLastStep('file');
				}
				history.push(`/release/${id}/file`);
				setCompletionStep(releaseCompletionSteps.FILE);
				update();
			})
			.catch((error) => console.error('Error in ReleaseCreate: ', error));
	};

	const setFiles = () => {
		if (lastStep === 'file') {
			accounts.updateStep(releaseId, { step: 'tracks' }).catch((error) => {
				console.error('Error', error);
			});
			setLastStep('tracks');
		}
		history.push(`/release/${releaseId}/tracks`);
		setCompletionStep(releaseCompletionSteps.TRACKS);
		update();
	};

	const setTracks = () => {
		if (lastStep === 'tracks') {
			accounts.updateStep(releaseId, { step: 'authors' }).catch((error) => {
				console.error('Error', error);
			});
			setLastStep('authors');
		}
		history.push(`/release/${releaseId}/authors`);
		setCompletionStep(releaseCompletionSteps.AUTHORS);
		update();
	};

	const setAuthors = () => {
		if (lastStep === 'authors') {
			accounts.updateStep(releaseId, { step: 'lyrics' }).catch((error) => {
				console.error('Error', error);
			});
			setLastStep('lyrics');
		}
		history.push(`/release/${releaseId}/lyrics`);
		setCompletionStep(releaseCompletionSteps.LYRICS);
		update();
	};

	const setLyrics = () => {
		if (lastStep === 'lyrics') {
			accounts.updateStep(releaseId, { step: 'cover' }).catch((error) => {
				console.error('Error', error);
			});
			setLastStep('cover');
		}
		history.push(`/release/${releaseId}/cover`);
		setCompletionStep(releaseCompletionSteps.COVER);
		update();
	};

	const setCover = () => {
		if (lastStep === 'cover') {
			accounts
				.updateStep(releaseId, { step: 'distribution' })
				.catch((error) => {
					console.error('Error', error);
				});
			setLastStep('distribution');
		}
		history.push(`/release/${releaseId}/distribution`);
		setCompletionStep(releaseCompletionSteps.DISTRIBUTION);
		update();
	};

	const setDISTRIBUTION = (data) => {
		const _promise = new Promise((resolve, reject) => {
			accounts
				.setDistribution(data, releaseId)
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					console.error('Error', error);
					reject(false);
				});
		});
		_promise
			.then(() => {
				if (lastStep === 'distribution') {
					accounts.updateStep(releaseId, { step: 'confirm' }).catch((error) => {
						console.error('Error', error);
					});
					setLastStep('confirm');
				}
				history.push(`/release/${releaseId}/confirm`);
				setCompletionStep(releaseCompletionSteps.CONFIRM);
				update();
			})
			.catch((err) =>
				console.error('releaseCreate error in accounts.updateStep: ', err)
			);
	};

	const emptyTrackList = () => {
		setCompletionStep(releaseCompletionSteps.FILE);
		accounts.updateStep(releaseId, { step: 'file' }).catch((error) => {
			console.error('Error', error);
		});
		setLastStep('file');
		setConfirm(false);
		setDefaultSteps([
			{
				id: 0,
				status: 'complete',
				title: <FormattedMessage id={'rod.release.create.step.release'} />,
			},
			{
				id: 1,
				status: 'active',
				title: <FormattedMessage id={'rod.release.create.step.files'} />,
			},
			{
				id: 2,
				status: 'default',
				title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
			},
			{
				id: 3,
				status: 'default',
				title: <FormattedMessage id={'rod.release.create.step.authors'} />,
			},
			{
				id: 4,
				status: 'default',
				title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
			},
			{
				id: 5,
				status: 'default',
				title: <FormattedMessage id={'rod.release.create.step.cover'} />,
			},
			{
				id: 6,
				status: 'default',
				title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
			},
			{
				id: 7,
				status: 'default',
				title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
			},
		]);
	};

	const handlePrevStep = () => {
		setCompletionStep(
			getPrevStep(completionStep, defaultSteps, setDefaultSteps, setConfirm)
		);
	};

	const checkDateErrors = (error) => {
		if (error && errors.created_date) {
			delete errors.created_date;
			setErrors({ ...errors });
		}
	};

	const handleOpenPrevTabs = (id) => {
		switch (id) {
			case 0:
				setCompletionStep(releaseCompletionSteps.RELEASE);
				setDefaultSteps([
					{
						id: 0,
						status: 'active',
						title: <FormattedMessage id={'rod.release.create.step.release'} />,
					},
					{
						id: 1,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.files'} />,
					},
					{
						id: 2,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
					},
					{
						id: 3,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.authors'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.cover'} />,
					},
					{
						id: 6,
						status: 'default',
						title: (
							<FormattedMessage id={'rod.release.create.step.distribution'} />
						),
					},
					{
						id: 7,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
					},
				]);
				break;
			case 1:
				setCompletionStep(releaseCompletionSteps.FILE);
				setDefaultSteps([
					{
						id: 0,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.release'} />,
					},
					{
						id: 1,
						status: 'active',
						title: <FormattedMessage id={'rod.release.create.step.files'} />,
					},
					{
						id: 2,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
					},
					{
						id: 3,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.authors'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.cover'} />,
					},
					{
						id: 6,
						status: 'default',
						title: (
							<FormattedMessage id={'rod.release.create.step.distribution'} />
						),
					},
					{
						id: 7,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
					},
				]);
				break;
			case 2:
				setCompletionStep(releaseCompletionSteps.TRACKS);
				setDefaultSteps([
					{
						id: 0,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.release'} />,
					},
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.files'} />,
					},
					{
						id: 2,
						status: 'active',
						title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
					},
					{
						id: 3,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.authors'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.cover'} />,
					},
					{
						id: 6,
						status: 'default',
						title: (
							<FormattedMessage id={'rod.release.create.step.distribution'} />
						),
					},
					{
						id: 7,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
					},
				]);
				break;
			case 3:
				setCompletionStep(releaseCompletionSteps.AUTHORS);
				setDefaultSteps([
					{
						id: 0,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.release'} />,
					},
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.files'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
					},
					{
						id: 3,
						status: 'active',
						title: <FormattedMessage id={'rod.release.create.step.authors'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.cover'} />,
					},
					{
						id: 6,
						status: 'default',
						title: (
							<FormattedMessage id={'rod.release.create.step.distribution'} />
						),
					},
					{
						id: 7,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
					},
				]);
				break;
			case 4:
				setCompletionStep(releaseCompletionSteps.LYRICS);
				setDefaultSteps([
					{
						id: 0,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.release'} />,
					},
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.files'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.authors'} />,
					},
					{
						id: 4,
						status: 'active',
						title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.cover'} />,
					},
					{
						id: 6,
						status: 'default',
						title: (
							<FormattedMessage id={'rod.release.create.step.distribution'} />
						),
					},
					{
						id: 7,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
					},
				]);
				break;
			case 5:
				setCompletionStep(releaseCompletionSteps.COVER);
				setDefaultSteps([
					{
						id: 0,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.release'} />,
					},
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.files'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.authors'} />,
					},
					{
						id: 4,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
					},
					{
						id: 5,
						status: 'active',
						title: <FormattedMessage id={'rod.release.create.step.cover'} />,
					},
					{
						id: 6,
						status: 'default',
						title: (
							<FormattedMessage id={'rod.release.create.step.distribution'} />
						),
					},
					{
						id: 7,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
					},
				]);
				break;
			case 6:
				setCompletionStep(releaseCompletionSteps.DISTRIBUTION);
				setDefaultSteps([
					{
						id: 0,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.release'} />,
					},
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.files'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.authors'} />,
					},
					{
						id: 4,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
					},
					{
						id: 5,
						status: 'complete',
						title: <FormattedMessage id={'rod.release.create.step.cover'} />,
					},
					{
						id: 6,
						status: 'active',
						title: (
							<FormattedMessage id={'rod.release.create.step.distribution'} />
						),
					},
					{
						id: 7,
						status: 'default',
						title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
					},
				]);
				break;

			default:
				break;
		}
	};

	const update = () => {
		switch (completionStep) {
			case releaseCompletionSteps.RELEASE:
				defaultSteps[0].status = 'complete';
				defaultSteps[1].status = 'active';
				setDefaultSteps([...defaultSteps]);
				return;
			case releaseCompletionSteps.FILE:
				defaultSteps[1].status = 'complete';
				defaultSteps[2].status = 'active';
				setDefaultSteps(defaultSteps);
				return;
			case releaseCompletionSteps.TRACKS:
				defaultSteps[2].status = 'complete';
				defaultSteps[3].status = 'active';
				setDefaultSteps(defaultSteps);
				return;
			case releaseCompletionSteps.AUTHORS:
				defaultSteps[3].status = 'complete';
				defaultSteps[4].status = 'active';
				setDefaultSteps(defaultSteps);
				return;
			case releaseCompletionSteps.LYRICS:
				defaultSteps[4].status = 'complete';
				defaultSteps[5].status = 'active';
				setDefaultSteps(defaultSteps);
				return;
			case releaseCompletionSteps.COVER:
				defaultSteps[5].status = 'complete';
				defaultSteps[6].status = 'active';
				setDefaultSteps(defaultSteps);
				return;
			case releaseCompletionSteps.DISTRIBUTION:
				defaultSteps[6].status = 'complete';
				defaultSteps[7].status = 'active';
				setDefaultSteps(defaultSteps);
				return;
			case releaseCompletionSteps.CONFIRM:
				setConfirm(true);
				return;
			default:
				return;
		}
	};

	useEffect(() => {
		let url_step = location.pathname.replace(/.+\/(.+)/gm, '$1');

		const url = history.location?.state?.url || backTargetUrl;
		const title = transferRelease
			? 'rod.transfer_release'
			: 'rod.btn.add_release';
		showTitle(title);

		if (!releaseId) {
			history.push('/release/create');
			setCompletionStep(releaseCompletionSteps.RELEASE);
			setDefaultSteps([
				{
					id: 0,
					status: 'active',
					title: <FormattedMessage id={'rod.release.create.step.release'} />,
				},
				{
					id: 1,
					status: 'default',
					title: <FormattedMessage id={'rod.release.create.step.files'} />,
				},
				{
					id: 2,
					status: 'default',
					title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
				},
				{
					id: 3,
					status: 'default',
					title: <FormattedMessage id={'rod.release.create.step.authors'} />,
				},
				{
					id: 4,
					status: 'default',
					title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
				},
				{
					id: 5,
					status: 'default',
					title: <FormattedMessage id={'rod.release.create.step.cover'} />,
				},
				{
					id: 6,
					status: 'default',
					title: (
						<FormattedMessage id={'rod.release.create.step.distribution'} />
					),
				},
				{
					id: 7,
					status: 'default',
					title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
				},
			]);
			showBackNavi(url);
		}

		if (releaseId) {
			setLoading(true);

			accounts
				.getReleaseData(releaseId)
				.then((res) => {
					const response = res.data.data;
					if (response.isTransferRelease) {
						setIsTransferRelease(true);
						setTransferRelease(true);
					}
					setLastStep(response.step);
					setReleaseTypeId(response.release_type_id);
					const _promise = new Promise((resolve, reject) => {
						if (url_step === 'create') resolve(response.step);
						if (releaseId && params.id === releaseId) {
							accounts
								.getReleaseData(releaseId)
								.then((res) => {
									const arrSteps = [
										'release',
										'file',
										'tracks',
										'authors',
										'lyrics',
										'cover',
										'distribution',
										'confirm',
									];
									arrSteps.forEach((item, index) => {
										if (
											url_step === item &&
											index <= arrSteps.indexOf(res.data.data.step)
										) {
											resolve(item);
										}
									});

									resolve(res.data.data.step);
								})
								.catch((error) => {
									console.error('Error', error);
								});
						} else {
							resolve(res.data.data.step);
						}
					});

					_promise.then((current_step) => {
						setCompletionStep(current_step);
						switch (current_step) {
							case 'release':
								history.push(`/release/${releaseId}/release`);
								break;
							case 'file':
								setDefaultSteps(fileSteps);
								history.push(`/release/${releaseId}/file`);
								break;
							case 'tracks':
								setDefaultSteps(tracksSteps);
								history.push(`/release/${releaseId}/tracks`);
								break;
							case 'authors':
								setDefaultSteps(authorsSteps);
								history.push(`/release/${releaseId}/authors`);
								break;
							case 'lyrics':
								setDefaultSteps(lyricsSteps);
								history.push(`/release/${releaseId}/lyrics`);
								break;
							case 'cover':
								setDefaultSteps(coverSteps);
								history.push(`/release/${releaseId}/cover`);
								break;
							case 'distribution':
								setDefaultSteps(distributionSteps);
								history.push(`/release/${releaseId}/distribution`);
								break;
							case 'confirm':
								setConfirm(true);
								setDefaultSteps(confirmSteps);
								history.push(`/release/${releaseId}/confirm`);
								break;
							case 'moderation':
								setConfirm(true);
								history.push(`/release/${releaseId}/moderation`);
								break;
							default:
								history.push(`/release/create`);
								completionStep('release');
								break;
						}
					});
					setLoading(false);
					setTimeout(() => {
						showBackNavi(url);
					}, 500);
				})
				.catch((error) => {
					console.error('Error', error);
					setLoading(false);
					showBackNavi(url);
				});
		}

		return function cleanup() {
			hideBackNavi();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transferRelease]);

	return (
		<div className={styles.page}>
			{!completionStep || loading ? (
				<Loading className={styles.loader} />
			) : (
				<>
					<Wizard
						steps={defaultSteps}
						className={styles.wizard}
						handleOpenPrevTabs={handleOpenPrevTabs}
					/>
					<Fragment>
						{renderStep(
							completionStep,
							setRelease,
							errors,
							checkDateErrors,
							setFiles,
							handlePrevStep,
							releaseTypeId,
							setTracks,
							setAuthors,
							setLyrics,
							setCover,
							setDISTRIBUTION,
							emptyTrackList
						)}
					</Fragment>
				</>
			)}
		</div>
	);
};

export default compose(withUI, withRoot)(ReleaseCreate);
