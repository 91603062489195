// Core
import moment from 'moment';

// Helpers
import { snippetValidation } from './snippetValidation';

export const changeSnippet = (
	e,
	selectedRelease,
	errors,
	setErrors,
	additionalReleases,
	setAdditionalReleases
) => {
	const { release_type_id } = selectedRelease;
	const updatedErrors = { ...errors };

	delete updatedErrors.snippet_start;
	snippetValidation(e.target.value.split(':'), updatedErrors);
	let endTime;

	switch (release_type_id) {
		case 43:
			endTime = moment(e.target.value, 'mm:ss:SSS')
				.add(29.99, 'seconds')
				.format('mm:ss:SSS');
			break;

		case 70:
			endTime = moment(e.target.value, 'mm:ss:SSS')
				.add(60, 'seconds')
				.format('mm:ss:SSS');
			break;

		default:
			break;
	}

	const updatedSelectedRelease = { ...selectedRelease };
	updatedSelectedRelease.snippet_start = e.target.value;
	updatedSelectedRelease.snippet_end = endTime.toString();

	const releaseIndex = additionalReleases.findIndex(
		(release) => release.id === selectedRelease.id
	);

	const updatedReleases = [...additionalReleases];
	updatedReleases[releaseIndex] = updatedSelectedRelease;

	setErrors(updatedErrors);
	setAdditionalReleases(updatedReleases);
};
