import React from 'react';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import s from './End.module.css';

export default function End() {
	const id = localStorage.getItem('accountId');
	return (
		<div className={s.end}>
			<span className={s.title}>
				<FormattedMessage id={'rod.release.create.step.end.title'} />
			</span>
			<span className={s.text}>
				<FormattedMessage id={'rod.release.create.step.end.text'} />
			</span>
			<Link className={s.button} to={`/accounts/${id}/dashboard`}>
				<span>
					<FormattedMessage id={'rod.release.create.step.end.submit'} />
				</span>
			</Link>
		</div>
	);
}
