/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/02/2021, 11:32
 */

import { Loading, Wizard } from 'components';
import { Button } from 'components/Buttons/Button';
import { ContractListItem } from 'components/ContractListItem';
import { Label } from 'components/Labels/Label';
import contractStates from 'constants/contractStates';
import { withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import styles from 'pages/contracts/AddContract/steps/04-Sign/Sign.module.css';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

class Next extends PureComponent {
	constructor(props) {
		super(props);
		this.steps = [
			{
				id: 1,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_3_1.title'} />,
			},
			{
				id: 2,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_3_2.title'} />,
			},
		];
		this.state = {
			loading: true,
			data: {},
		};
	}

	componentDidMount() {
		const {
			rootContext: { getContracts, getAdditionalContracts },
		} = this.props;
		if (this.props.isAdditional) {
			getAdditionalContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
			});
		} else {
			getContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
			});
		}
	}

	submit = () => {
		if (typeof this.props.onSubmit === 'function') {
			this.props.onSubmit(this.state.data);
		}
	};

	localizeTitles = (title) => {
		const lang = localStorage.getItem('lang');

		if (lang === 'ru') return title;
		if (lang === 'en') {
			switch (title) {
				case 'Лицензионный договор':
					return 'Licensing agreement';
				case 'Single-song издательский договор':
					return 'Single-song publishing agreement';
				case 'Субиздательский договор':
					return 'Sub-publishing agreement';
				default:
					return title;
			}
		}
	};

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		const { signed, sms } = this.props;
		const { data } = this.state;
		const titles = signed.map((contract) => contract.title);
		const localizeTitles = titles.map((title) => this.localizeTitles(title));

		return (
			<Container fluid className={styles.Page}>
				<Wizard steps={this.steps} className={styles.Wizard} />
				<div className={styles.Form}>
					<Row>
						<Col sm={10} offset={{ sm: 1 }} className={styles.Header}>
							<Label
								text={<FormattedHTMLMessage id={'rod.contracts.next.header'} />}
								font="--gilroy-Medium-40"
								black
							/>
							<Label
								className="sub"
								text={
									<FormattedHTMLMessage
										id={'rod.contracts.next.subheader'}
										values={{ values: localizeTitles.join(', ') }}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>

							<ul className={styles.List}>
								{data.map((item, index) => (
									<li>
										<ContractListItem
											{...item}
											index={index}
											showDownloadSigned={
												sms && item.status === contractStates.SIGNED
											}
											showDate={item.status === contractStates.SIGNED}
											date={item.signed_at || Date.now()}
											showHash={item.status === contractStates.SIGNED}
											hash={item.hash}
										/>
									</li>
								))}
							</ul>
							<div className={styles.Controls}>
								<Button
									text={<FormattedMessage id={'rod.action.sign_next'} />}
									variant={'primary'}
									onClick={this.submit}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		);
	}
}

Next.propTypes = {
	onSubmit: PropTypes.func,
};

Next.defaultProps = {};

export default compose(withUI, withRoot)(Next);
