// Icons
import iconPdf from 'images/icon_pdf.svg';
import iconXls from 'images/icon_xls.svg';

export const filesEn = [
	{
		icon: iconXls,
		title: 'Download  instruction how to fill-in repertoire form',
		filename: 'Corrected_Customer Instructions for Template v2.6 EN.xlsx',
	},
	{
		icon: iconXls,
		title: 'Download sample of filled in form',
		filename: 'metadata_import_SAMPLE_v2.6_EN.xlsx',
	},
	{
		icon: iconXls,
		title: 'Download repertoire form',
		filename: 'metadata_import_template_v2.6_EN.xlsx',
	},
	{
		icon: iconPdf,
		title: 'Download an instruction for calculation of authors shares',
		filename: 'How to calculate and fill in shares.pdf',
	},
];

export const filesRu = [
	{
		icon: iconXls,
		title: 'Скачать инструкции по заполнению формы',
		filename: 'Corrected_Customer Instructions for Template v2.6 RU.xlsx',
	},
	{
		icon: iconXls,
		title: 'Скачать форму для заполнения данных репертуара',
		filename: 'metadata_import_template_v2.6_RU.xlsx',
	},
	{
		icon: iconXls,
		title: 'Скачать образец заполненной формы',
		filename: 'metadata_import_SAMPLE_v2.6_RU.xlsx',
	},
	{
		icon: iconPdf,
		title: 'Скачать инструкцию по расчету долей авторов',
		filename: 'Как расчитать и заполнить доли.pdf',
	},
];
