import { useContext, useEffect, useState, useCallback } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { Loading, NoData } from 'components';
import { Pagination, Input } from 'material-design/components';
import plusImg from 'images/plus.svg';
import edit from 'images/edit.svg';
import download from 'images/download.svg';
import error from 'images/error.svg';
import pendingIcon from 'images/pending-icon.svg';
import noReports from 'images/noReports.svg';
import { withRoot, withUI } from 'hocs';
import { compose } from 'recompose';
import { LangContext } from 'contexts/LangContext';
import debounce from 'lodash.debounce';
import { ReportsAdminModal } from 'material-design/modals/ReportsAdminModal';

import styles from './ReportsPage.module.css';
import more from 'images/more.svg';
import moreWhite from 'images/moreWhite.svg';
import admin from 'services/api/admin';
import { dateFormatEditor } from 'logic';
import Loader from 'components/Loader';
import filter_svg from 'images/filter.svg';
import pagesEn from 'messages/EN/pages';
import pagesRu from 'messages/RU/pages';
import { FullScreenModalConfirm } from 'containers/Modal';
import { Link } from 'react-router-dom';
// import download from 'images/download_square.svg';
// import { Lang } from 'components';
// import modalTypes from 'constants/modalTypes';

const ReportsPage = (props) => {
	const { lang } = useContext(LangContext);
	const [reports, setReports] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noData, setNoData] = useState(false);
	const [isShowMenuMore, setIsShowMenuMore] = useState(false);
	const [isShowMenuMoreId, setIsShowMenuMoreId] = useState('');

	const [accountsList, setAccountsList] = useState([]);

	const [nextReportDate, setNextReportDate] = useState({});
	// const [isUploadingProcess, setIsUploadingProcess] = useState(false);
	const [uploadArchiveData, setUploadArchiveData] = useState([]);
	const [uploadProgressPercents, setUploadProgressPercents] = useState({});
	const [filterValue, setFilterValue] = useState('');
	const [filteredDataLoading, setFilteredDataLoading] = useState(false);

	const [noFilteredData, setNoFilteredData] = useState(false);
	const [modal, setModal] = useState(false);
	const [isFilterEnable, setIsFilterEnable] = useState(false);

	const [showErrorModal, setShowErrorModal] = useState(false);

	const [filterSettings, setFilterSettings] = useState([
		{
			id: 1,
			title_ru: 'статус',
			title_en: 'status',
			list: [
				{
					id: 1,
					name_ru: pagesRu['rod.admin.reports.reports_with_errors'],
					name_en: pagesEn['rod.admin.reports.reports_with_errors'],
					code: 'withError',
					checked: false,
				},
			],
		},
	]);

	const {
		UIContext: { showModal, isManualReports },
		rootContext: {
			getAdminReports,
			getAllAdminReports,
			getAdminReportsByFilter,
			getAdminReportsByFilterWromModal,
			getAdminReportsNextDate,
			postAdminReportsNextDate,
			editAdminReportsNextDate,
			postReportArchiveRequest,
			editReportArchiveFile,
			editReportArchiveRequest,
			removeReport,
			reloadReport,
			// postReportArchiveFile,
			postReportArchiveFileS3,
			postReportXlsFile,
			putFileToS3,
			currencies,
			editManualReportFile,
			editManualReportData,
			startReportParsing,
		},
	} = props;
	useEffect(() => {
		admin.getAccountsAdminV2().then((res) => setAccountsList(res.data.data));
		getAdminReportsNextDate().then((res) => {
			if (res) {
				setNextReportDate({
					...res,
					next_date: dateFormatEditor(res.next_date, 'dmy'),
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (accountsList.length) {
			getReports();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountsList, page]);

	const getReports = () => {
		if (filterValue) return;
		setLoading(true);

		getAdminReports(page)
			.then((res) => {
				setTotal(res.total);
				if (res.data.length) {
					if (noData) setNoData(false);
					res = res.data.sort(
						(a, b) => new Date(b.created_at) - new Date(a.created_at)
					);

					const correctedDates = correctDatesView(res);
					setReports(correctedDates);
				} else {
					setNoData(true);
				}
			})
			.catch((err) => {
				console.info('Error', err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const modalOpen = () => {
		setModal(!modal);
	};

	const handleCheckedFilter = (index, code) => {
		filterSettings[index].list.map((item) => {
			if (item.code === code) {
				item.checked = !item.checked;
				item.checked ? setIsFilterEnable(true) : setIsFilterEnable(false);
			}
		});

		setFilterSettings([...filterSettings]);
	};

	const handleClearFilter = () => {
		filterSettings.forEach((setting) => {
			setting.list.forEach((item) => {
				if (item.checked) item.checked = false;
			});
		});
		setIsFilterEnable(false);
		setFilterSettings([...filterSettings]);
	};

	const getReportsWithFilterWromModal = (filter) => {
		getAdminReportsByFilterWromModal(page, filter)
			.then((res) => {
				setTotal(res.total);
				if (res.data.length) {
					if (noData) setNoData(false);
					res = res.data.sort(
						(a, b) => new Date(b.created_at) - new Date(a.created_at)
					);

					const correctedDates = correctDatesView(res);
					setReports(correctedDates);
				} else {
					setNoData(true);
				}
			})
			.catch((err) => {
				console.info('Error', err);
			})
			.finally(() => {
				setLoading(false);
				modalOpen();
			});
	};

	const correctDatesView = (res) => {
		const result = res.map((item, index) => {
			const created_at = new Date(item.created_at);

			const date_publication_corrected =
				item.date_publication.replace(' ', 'T') + 'Z';
			const date_publication = new Date(date_publication_corrected);

			const time_publication =
				date_publication.getHours().toString().padStart(2, '0') +
				':' +
				date_publication.getMinutes().toString().padStart(2, '0');
			return {
				...item,
				created_at: dateFormatEditor(created_at, 'dmy', '.'),
				date_publication: dateFormatEditor(date_publication, 'dmy', '.'),
				time_publication: time_publication,
			};
		});
		return result;
	};

	const handleClickMore = (index) => {
		setIsShowMenuMoreId(index);
		setIsShowMenuMore(true);
	};

	const handleOnBlurMenuMore = () => {
		setIsShowMenuMoreId('');
		setIsShowMenuMore(false);
	};

	const handleCreateNextReportDate = (date) => {
		postAdminReportsNextDate(date).then((res) => {
			setNextReportDate({
				...res,
				next_date: dateFormatEditor(res.next_date, 'dmy', '.'),
			});
		});
	};

	const handleEditNextReportDate = (date) => {
		editAdminReportsNextDate(nextReportDate.id, date).then((res) => {
			setNextReportDate({
				...res,
				next_date: dateFormatEditor(res.next_date, 'dmy', '.'),
			});
		});
	};

	const prepareFileToUpload = async (
		archiveAttachment,
		id,
		reportPrepared,
		xlsAttachment
	) => {
		reportPrepared.created_at = dateFormatEditor(
			reportPrepared.created_at,
			'dmy',
			'.'
		);

		reportPrepared.date_publication = dateFormatEditor(
			reportPrepared.date_publication.date.slice(0, -7),
			'dmy',
			'.'
		);

		setUploadArchiveData([reportPrepared]);

		const config = {
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);

				if (+progressEvent.loaded !== +progressEvent.total) {
					setUploadProgressPercents({ value: percentCompleted });
				}
			},
		};

		// postReportArchiveFile(attachment.file, id, config)
		// 	.then((res) => {
		// 		setUploadArchiveData([]);
		// 		setUploadProgressPercents({});
		// 		// getReports();
		// 	})
		// 	.catch((err) => {
		// 		console.info('Error', err);
		// 	});

		const file_name_with_ext = archiveAttachment.fileData.name;
		const file_size = archiveAttachment.fileData.size;
		//==========================================================
		// postReportArchiveFileS3(file_name_with_ext, file_size, id)
		// 	.then((res) => {
		// 		putFileToS3(res.putUrl, archiveAttachment.file, config).then((res) => {
		// 			console.log('putFileToS3 success.');
		// 			postReportXlsFile(xlsAttacment).then((res) => {
		// 				console.log('finalRes after Xls is uploaded', res);
		// 				setUploadArchiveData([]);
		// 				setUploadProgressPercents({});
		// 				refreshList();
		// 			});
		// 		});
		// 	})
		// 	.catch((err) => console.log('ERROR! ', err));

		let response = await postReportArchiveFileS3(
			file_name_with_ext,
			file_size,
			id
		);

		await putFileToS3(response.putUrl, archiveAttachment.file, config);
		if (!isManualReports) {
			await startReportParsing();
		}
		if (isManualReports) {
			await postReportXlsFile(xlsAttachment.file);
		}
		setUploadArchiveData([]);
		setUploadProgressPercents({});
		refreshList();
		if (isManualReports) {
			let getAllReportsResponse = await getAllAdminReports();
			if (
				getAllReportsResponse.error &&
				getAllReportsResponse.error.length > 0
			) {
				setShowErrorModal(true);
			}
		}
	};

	const handleDelete = (item) => {
		removeReport(item.report_files)
			.then((res) => {
				handleOnBlurMenuMore();
				getReports();
			})
			.catch((err) => {
				console.info('Error', err);
			});
	};

	const handlingReloadReport = (identifier) => {
		reloadReport(identifier);
	};

	const refreshList = () => {
		getReports();
	};

	const onChange = (filter) => {
		setNoFilteredData(false);
		setFilterValue(filter);
		setPage(1);
		if (filter.length >= 3) {
			setFilteredDataLoading(true);
			getAdminReportsByFilter(1, filter)
				.then((res) => {
					setTotal(res.total);
					if (res.data.length) {
						res = res.data.sort(
							(a, b) => new Date(b.created_at) - new Date(a.created_at)
						);

						const correctedDates = correctDatesView(res);

						setReports(correctedDates);
					} else {
						setNoFilteredData(true);
					}
				})
				.catch((err) => {
					console.info('Error', err);
				})
				.finally(() => {
					setFilteredDataLoading(false);
				});
		} else if (!filter) {
			setFilteredDataLoading(true);
			getAdminReports(1)
				.then((res) => {
					setTotal(res.total);
					setNoFilteredData(false);
					res = res.data.sort(
						(a, b) => new Date(b.created_at) - new Date(a.created_at)
					);

					const correctedDates = correctDatesView(res);

					setReports(correctedDates);
				})
				.catch((err) => {
					console.info('Error', err);
				})
				.finally(() => {
					setFilteredDataLoading(false);
				});
		}
	};

	const handleFilter = useCallback(debounce(onChange, 500), []);

	const handleManualEdit = async ({ reportId, reportData, attachment }) => {
		if (attachment && attachment.file && attachment.fileData) {
			await editManualReportFile(reportId, attachment.file);
		}
		if (reportData) {
			await editManualReportData(reportId, reportData);
		}
		refreshList();
	};

	const closeModal = () => {
		setShowErrorModal(false);
	};

	return (
		<div className={styles.mainWrapper}>
			{noData ? (
				<div className={styles.noData}>
					<div
						className={styles.uploadReportBtn}
						onClick={
							!isManualReports
								? showModal(
										{
											prepareFileToUpload: prepareFileToUpload,
											postReportArchiveRequest: postReportArchiveRequest,
											refreshList: refreshList,
										},
										'reports'
								  )
								: showModal(
										{
											prepareFileToUpload: prepareFileToUpload,
											postReportArchiveRequest: postReportArchiveRequest,
											refreshList: refreshList,
										},
										'reports_manual'
								  )
						}
					>
						<img src={noReports} alt="noReports" className={styles.noReports} />
						<div>
							<FormattedHTMLMessage id={'rod.admin.reports.noData'} />
						</div>
					</div>
				</div>
			) : (
				<>
					{loading || !accountsList ? (
						<Loading className={styles.loader} non_margin={true} />
					) : (
						<>
							<div className={styles.sectionTitleWrapper}>
								{nextReportDate.next_date ? (
									<ul>
										<li
											style={{
												color: 'var(--color-grey100)',
												font: 'var(--gilroy-Medium-14)',
											}}
										>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.next_report_date'}
											/>
										</li>
										<li className={styles.iconEditWrapper}>
											<span
												style={{
													// color: 'var(--color-grey100)',
													font: 'var(--gilroy-Medium-24)',
												}}
											>
												{nextReportDate.next_date}
											</span>

											<img
												src={edit}
												alt="edit"
												className={styles.iconEdit}
												style={{
													cursor: 'pointer',
												}}
												onClick={showModal(
													{
														handleNextReportDate: handleEditNextReportDate,
														date: nextReportDate.next_date,
													},
													'nextReportDate'
												)}
											/>
										</li>
									</ul>
								) : (
									<div
										className={styles.sectionTitle}
										onClick={showModal(
											{
												handleNextReportDate: handleCreateNextReportDate,
												// users: users,
												// authContext: authContext,
												// isNotificationSend: isNotificationSend,
											},
											'nextReportDate'
										)}
									>
										<FormattedHTMLMessage
											id={'rod.field.admin.reports.next_report_date'}
										/>
									</div>
								)}
								<Link to={'/admin/v2/distribution_page'}>
									<button type="button">DISTRIBUTION</button>
								</Link>
								<div className={styles.topBtnsWrapper}>
									<div
										className={styles.createArticleBtn}
										onClick={
											!isManualReports
												? showModal(
														{
															prepareFileToUpload: prepareFileToUpload,
															postReportArchiveRequest: postReportArchiveRequest,
															refreshList: refreshList,
															// users: users,
															// authContext: authContext,
															// isNotificationSend: isNotificationSend,
														},
														'reports'
												  )
												: showModal(
														{
															prepareFileToUpload: prepareFileToUpload,
															postReportArchiveRequest: postReportArchiveRequest,
															refreshList: refreshList,
															// users: users,
															// authContext: authContext,
															// isNotificationSend: isNotificationSend,
														},
														'reports_manual'
												  )
										}
									>
										<img src={plusImg} alt="add" className={styles.iconPlus} />

										<FormattedHTMLMessage id={'rod.admin.reports.add'} />
									</div>
								</div>
							</div>
							<div className={styles.filterWrapper}>
								<FormattedHTMLMessage id={'rod.admin.find'}>
									{(placeholder) => (
										<Input
											handleOnChange={handleFilter}
											placeholder={placeholder}
											// initialValue={filterValue}
										/>
									)}
								</FormattedHTMLMessage>
								<img
									src={filter_svg}
									alt="filter"
									className={styles.filterIcon}
									onClick={modalOpen}
								/>
							</div>
							{filteredDataLoading ? (
								<Loading /* non_margin */ />
							) : noFilteredData ? (
								<NoData />
							) : (
								<div className={styles.RepertoireSongInfo__mainInfoTable}>
									<Row className={styles.row_header}>
										<Col width={'12%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.publishing_date'}
											/>
										</Col>
										<Col width={'12%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.made'}
											/>
										</Col>
										<Col width={'11%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.acc_id'}
											/>
										</Col>
										<Col width={'25%'}>
											<FormattedHTMLMessage id={'rod.field.admin.title'} />
										</Col>
										<Col width={'10%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.sum'}
											/>
										</Col>
										<Col width={'10%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.currency'}
											/>
										</Col>
										<Col width={'170px'}>
											<FormattedHTMLMessage id={'rod.field.admin.actions'} />
										</Col>
										<Col width={'42px'}></Col>
									</Row>
									<div className={styles.RepertoireMainInfoTable__body}>
										<ul>
											{uploadArchiveData.length
												? uploadArchiveData.map((item, index) => (
														<li className={styles.articlesListItem}>
															<Row nowrap className={styles.row_data}>
																<Row nowrap className={styles.row_title}>
																	<Col width={'12%'} className={styles.shell}>
																		{item.date_publication}
																	</Col>
																	<Col width={'12%'} className={styles.shell}>
																		{item.created_at}
																	</Col>
																	<Col
																		width={'11%'}
																		className={styles.shell}
																	></Col>
																	<Col width={'25%'} className={styles.shell}>
																		{lang === 'ru'
																			? item.heading
																			: item.heading_en}
																	</Col>
																	<Col
																		width={'10%'}
																		className={styles.shell}
																	></Col>
																	<Col
																		width={'10%'}
																		className={styles.shell}
																	></Col>
																	<Col width={'170px'}>
																		<div className={styles.loaderWrapper}>
																			<div className={styles.loader}>
																				<Loader
																					uploadProgressPercents={
																						uploadProgressPercents
																					}
																					width={'64px'}
																					height={'64px'}
																					padding={'0px'}
																				/>
																			</div>
																			{uploadProgressPercents.value && (
																				<span className={styles.percentCount}>
																					{uploadProgressPercents.value + '%'}
																				</span>
																			)}
																		</div>
																	</Col>
																	<Col width={'42px'}></Col>
																</Row>
															</Row>
														</li>
												  ))
												: ''}
											{reports.map((item, index) => (
												<li className={styles.articlesListItem}>
													<Row nowrap className={styles.row_data}>
														<Row nowrap className={styles.row_title}>
															<Col width={'12%'} className={styles.shell}>
																{item.date_publication}
															</Col>
															<Col width={'12%'} className={styles.shell}>
																{item.created_at}
															</Col>
															<Col width={'11%'} className={styles.shell}>
																<div className={styles.row_title_accId}>
																	<span>{item.account_id}</span>
																	<span>
																		{item.account_title
																			? item.account_title
																			: '\u2212'}
																	</span>
																</div>
															</Col>
															<Col width={'25%'} className={styles.shell}>
																<div className={styles.heading}>
																	{lang === 'ru'
																		? item.heading
																		: item.heading_en}
																</div>
															</Col>
															<Col width={'10%'} className={styles.shell}>
																{item.royalties &&
																	item.royalties.replace(
																		/(\d)(?=(\d{3})+(?!\d))/g,
																		`$1\u00A0`
																	)}
															</Col>
															<Col width={'10%'} className={styles.shell}>
																{item.currency}
															</Col>
															<Col width={'170px'} className={styles.shell}>
																{item.status === 'done' && (
																	<div
																		className={styles.action}
																		onClick={() => {
																			item.file_id &&
																				window.open(item.file_id, '_parent');
																		}}
																	>
																		<img
																			src={download}
																			alt=""
																			width="25"

																			// style={{ marginBottom: '4px' }}
																		/>
																		<div
																			className={styles.actionTitle}
																			style={{ paddingTop: '1px' }}
																		>
																			<FormattedHTMLMessage
																				id={'rod.action.download'}
																			/>
																		</div>
																	</div>
																)}
																{item.status === 'error' && (
																	<div
																		className={styles.action}
																		onClick={() => {
																			item.file_id &&
																				window.open(item.file_id, '_parent');
																		}}
																	>
																		<img src={error} alt="" />
																		<div className={styles.actionTitleError}>
																			<FormattedHTMLMessage
																				id={'rod.error_page.not_found.header'}
																			/>
																		</div>
																	</div>
																)}
																{item.status === 'pending' && (
																	<div className={styles.action}>
																		<img src={pendingIcon} alt="" width="25" />
																		<div className={styles.actionTitlePending}>
																			{/* <FormattedHTMLMessage
																			id={'rod.error_page.not_found.header'}
																		/> */}
																			<FormattedHTMLMessage
																				id={'rod.admin.reports.pending_review'}
																			/>
																		</div>
																	</div>
																)}
															</Col>

															<Col width={'42px'}>
																{isShowMenuMore &&
																isShowMenuMoreId === index ? (
																	<div
																		className={styles.menuMoreWrapperActive}
																		tabindex="0"
																		onBlur={handleOnBlurMenuMore}
																	>
																		<img
																			src={moreWhite}
																			alt=""
																			className={styles.menuMore}
																		/>
																		<ul className={styles.menuMoreList}>
																			<li
																				className={styles.menuMoreListItem}
																				onClick={() => {
																					item.status === 'error' &&
																						item.report_files &&
																						handlingReloadReport(item.file_db);
																				}}
																			>
																				<span
																					style={
																						['pending', 'done'].includes(
																							item.status
																						) || !item.report_files
																							? { color: 'var(--color-grey)' }
																							: { cursor: 'pointer' }
																					}
																				>
																					<FormattedMessage
																						id={`rod.admin.reports.download_again`}
																					/>
																				</span>
																			</li>

																			<li
																				className={styles.menuMoreListItem}
																				onClick={
																					isManualReports
																						? item.status !== 'pending' &&
																						  item.report_files &&
																						  showModal(
																								{
																									reportId: item.report_files,
																									data: item,
																									// editReportArchiveFile: editReportArchiveFile,
																									// editReportArchiveRequest: editReportArchiveRequest,
																									handleManualEdit: handleManualEdit,
																									refreshList: refreshList,
																									currencies: currencies,
																									accountId: item.account_id,
																								},
																								'reports_manual_edit'
																						  )
																						: item.status !== 'pending' &&
																						  item.report_files &&
																						  showModal(
																								{
																									reportId: item.report_files,
																									data: item,
																									editReportArchiveFile: editReportArchiveFile,
																									editReportArchiveRequest: editReportArchiveRequest,
																									refreshList: refreshList,
																								},
																								'reports'
																						  )
																				}
																			>
																				{
																					<span
																						style={
																							item.status === 'pending' ||
																							!item.report_files
																								? { color: 'var(--color-grey)' }
																								: { cursor: 'pointer' }
																						}
																					>
																						<FormattedMessage
																							id={`rod.admin.reports.edit`}
																						/>
																					</span>
																				}
																			</li>

																			<li
																				className={styles.menuMoreListItem}
																				onClick={() => {
																					item.status !== 'pending' &&
																						item.report_files &&
																						handleDelete(item);
																				}}
																			>
																				<span
																					style={
																						item.status === 'pending' ||
																						!item.report_files
																							? { color: 'var(--color-grey)' }
																							: { cursor: 'pointer' }
																					}
																				>
																					<FormattedMessage
																						id={`rod.drafts.delete`}
																					/>
																				</span>
																			</li>
																		</ul>
																	</div>
																) : (
																	<div
																		className={styles.menuMoreWrapper}
																		tabindex="0"
																		onClick={() => handleClickMore(index)}
																		onBlur={handleOnBlurMenuMore}
																	>
																		<img
																			src={more}
																			alt=""
																			className={styles.menuMore}
																		/>
																	</div>
																)}
															</Col>
														</Row>
													</Row>
												</li>
											))}
										</ul>
									</div>
								</div>
							)}
						</>
					)}
					{total > 15 && !loading && !filteredDataLoading && !noFilteredData && (
						<div style={{ marginTop: '30px' }}>
							<Pagination
								page={page}
								total={total}
								paginate={(pageNumber) => {
									setPage(pageNumber);
								}}
								items_on_page={15}
							/>
						</div>
					)}
				</>
			)}
			<ReportsAdminModal
				active={modal}
				setActive={modalOpen}
				filterData={filterSettings}
				handleCheckedFilter={handleCheckedFilter}
				submitFilter={getReportsWithFilterWromModal}
				isFilterEnable={isFilterEnable}
				handleClearFilter={handleClearFilter}
				// setFilterData={handleChangeFilter}
				// currentFilterSettings={currentFilterSettings}
				// setCurrentFilter={handleCurrentFilter}
				// selectFilters={selectFilters}
				// setSelectFilters={setSelectFilters}
			/>
			{showErrorModal && (
				<FullScreenModalConfirm
					title={<FormattedMessage id={'rod.modal.admin_reports_title'} />}
					text={<FormattedMessage id={'rod.modal.admin_reports_text'} />}
					confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
					onClose={closeModal}
				/>
			)}
		</div>
	);
};
export default compose(withUI, withRoot)(ReportsPage);
