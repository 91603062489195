import { useState, useEffect } from 'react';
import styles from './TimePicker.module.css';
import clock from 'images/clock.svg';

const TimePicker = ({
	setTimeInHHMM,
	initialTimeInHHMM,
	stylesData,
	handleIsSelectedTimeValid,
}) => {
	const [hoursValue, setHoursValue] = useState('');
	const [minutesValue, setMinutesValue] = useState('');
	const [timeList, setTimeList] = useState([]);
	const [isShow, setIsShow] = useState(false);
	const [isTimeSelected, setIsTimeSelected] = useState(false);

	const lang = localStorage.getItem('lang');

	useEffect(() => {
		if (initialTimeInHHMM) {
			const time = initialTimeInHHMM.split(':');
			setHoursValue(time[0]);
			setMinutesValue(time[1]);
			setIsTimeSelected(true);
		}
	}, [initialTimeInHHMM]);

	useEffect(() => {
		if (isShow) {
			makeTimeTable();
		}
	}, [isShow]);

	useEffect(() => {
		if (
			hoursValue &&
			minutesValue &&
			hoursValue.length === 2 &&
			minutesValue.length === 2
		) {
			setTimeInHHMM(`${hoursValue}:${minutesValue}`);
			setIsTimeSelected(true);
			handleIsSelectedTimeValid(true);
		} else if (hoursValue === '' && minutesValue === '') {
			setTimeInHHMM('00:00');
			setIsTimeSelected(true);
			handleIsSelectedTimeValid(true);
		} else {
			setIsTimeSelected(false);
			handleIsSelectedTimeValid(false);
		}
	}, [hoursValue, minutesValue]);

	const handleInput = (e) => {
		const value = e.target.value;
		if (value.length > 2 || value < 0) return;
		switch (e.target.name) {
			case 'inputHours':
				if (value <= 24 /* twentyfourClock */) setHoursValue(value);
				break;
			case 'inputMinutes':
				if (value <= 60) setMinutesValue(value);

				break;
			default:
				break;
		}
	};
	// const handleTwentyfourClock = () => {
	// 	setTwentyfourClock((prev) => (prev === 12 ? 24 : 12));
	// 	setHoursValue((prev) => (prev > 12 ? hoursValue - 12 : prev));
	// };

	const makeTimeTable = () => {
		let timeList = [];
		for (let i = 0; i < 24; i++) {
			let hours = ('' + i).padStart(2, '0');
			// let timeRow = [];
			for (let j = 0; j < 4; j++) {
				let mins = ('' + j * 15).padStart(2, '0');

				timeList.push(hours + ':' + mins);
			}
			// timeList.push(timeRow);
		}
		setTimeList(timeList);
	};

	const handleSelectTime = (e) => {
		const hours = e.target.innerText.slice(0, 2);
		const mins = e.target.innerText.slice(3);
		setHoursValue(hours);
		setMinutesValue(mins);
		handleShow();
	};

	const handleShow = () => {
		setIsShow((prev) => !prev);
	};

	const handleOnBlur = () => {
		if (isShow) {
			setIsShow(false);
		}
	};

	return (
		<div
			className={styles.mainWrapper} /* tabIndex={0} onBlur={handleOnBlur} */
		>
			<div
				className={
					isShow || isTimeSelected
						? `${styles.inputWrapper} ${styles.inputBorderFocus} `
						: `${styles.inputWrapper}`
				}
				// onClick={handleShow}
				onClick={(e) => {
					// e.preventDefault();
					// e.stopPropagation();
					// e.nativeEvent.stopImmediatePropagation();
					handleShow();
				}}
			>
				<div /* className={styles.inputsWrapper} */>
					<input
						type="number"
						name="inputHours"
						value={hoursValue}
						className={
							isShow
								? `${styles.inputFocus} ${styles.inputHours}`
								: `${styles.inputHours}`
						}
						onChange={handleInput}
						placeholder={lang === 'ru' ? 'чч' : 'hh'}
					/>
					&nbsp;:&nbsp;
					<input
						type="number"
						name="inputMinutes"
						value={minutesValue}
						className={
							isShow
								? `${styles.inputFocus} ${styles.inputMinutes}`
								: `${styles.inputMinutes}`
						}
						onChange={handleInput}
						placeholder={lang === 'ru' ? 'мм' : 'mm'}
					/>
					{/* <div onClick={handleShow}>000</div> */}
				</div>
				{/* <div
					onClick={(e) => {
						handleShow();
					}}
					className={styles.clickedDiv}
					style={{
						position: 'absolute',
						top: '2px',
						left: '65px',
						width: '280px',
						height: '40px',
						flex: '1',
						cursor: 'pointer',
						paddingTop: '11px',
					}}
				></div> */}
				<div className={styles.twentyfourClock}>
					<img src={clock} alt="" />
				</div>
			</div>

			{isShow && (
				<div
					className={styles.dropdownWrapper}
					// style={{ display: isShow ? 'flex' : 'none' }}
					stylesData
					style={
						stylesData && stylesData.dropdown ? { ...stylesData.dropdown } : {}
					}
				>
					<div className={styles.dropTable}>
						{timeList.length > 0 &&
							timeList.map((item) => {
								return (
									<div
										onClick={(e) => handleSelectTime(e)}
										// tabIndex={1}
										className={
											`${hoursValue}:${minutesValue}` === item
												? styles.selectedTime
												: styles.singleTime
										}
									>
										<span>{item}</span>
									</div>
								);
							})}
					</div>
					{/* <div className={styles.dropRightSide}>
						<span
							className={
								twentyfourClock === 24 ? styles.active : styles.inactive
							}
						>
							{`24${lang === 'ru' ? 'ч' : 'h'}`}
						</span>
					</div> */}
				</div>
			)}
		</div>
	);
};

export default TimePicker;
