import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
// import { Pagination, Input } from 'material-design/components';
import { FaqAutosuggestInput } from 'components/Inputs';
import { FormattedMessage } from 'react-intl';

import styles from './FaqSubSection.module.css';
import { Col, Row } from 'react-grid-system';

const FaqSubSection = (props) => {
	const lang = localStorage.getItem('lang');
	let { url } = useRouteMatch();
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [faqData, setFaqData] = useState([]);
	const history = useHistory();
	const {
		UIContext: {
			showTitle,
			hideTitle,
			showBackNavi,
			hideBackNavi,
			setParentFaq,
			setFaqSearchResult,
		},
		rootContext: { getFaqSearchBySectionId },
	} = props;
	useEffect(() => {
		history.push(`/faq/section/${props.sectionId}`);

		showTitle('rod.nav.link.faq');
		showBackNavi('/faq');

		setParentFaq(`/faq/section/${props.sectionId}`);

		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getFaqSearchBySectionId(props.sectionId, lang).then((res) => {
			if (res.data.length > 0) {
				setFaqData(res.data[0]);
			} else {
				history.push(`/faq`);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	window.addEventListener('resize', () => {
		setScreenWidth(window.innerWidth);
	});

	const updateSearchResult = (result, searchText) => {
		setFaqSearchResult({
			text: searchText,
			data: Array.isArray(result) ? result : [result],
		});
		history.push(`/faq/search`);
		//----------- REDIRECT TO SEARCH PAGE ---------//
	};

	return (
		<>
			<div className={styles.mainWrapper}>
				<Row className={styles.search__wrapper}>
					<Col className={styles.columnLeft}>
						<div className={styles.wayTitle}>
							<Link to={`/faq`}>
								<FormattedMessage id={'rod.nav.link.faq'} />
							</Link>
							{`/${faqData && faqData.title ? faqData.title : ''}`}
						</div>
					</Col>
					<Col className={styles.columnRight}>
						<FormattedMessage id={'rod.statistic.search'}>
							{(placeholder) => (
								<FaqAutosuggestInput
									className={styles.Input}
									required
									value={{ title: '' }}
									lang={lang}
									onChange={updateSearchResult}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
				{Object.keys(faqData).length > 0 && (
					<>
						<Row className={styles.title}>
							<div className={styles.subTitle__text}>{faqData.title}</div>
						</Row>
						<Row className={styles.titleComment}>
							<div className={styles.subTitle__text}>
								<FormattedMessage id={'rod.faq.title_comment_begin'} />
								{` "${faqData.title}" `}
								{/* <FormattedMessage id={'rod.faq.title_comment_end'} /> */}
							</div>
						</Row>
						<div className={styles.section__wrapper}>
							{faqData.articles.map((item, index, array) => (
								<>
									{screenWidth >= 768 ? (
										(index + 1) % 2 !== 0 && (
											<Row className={styles.textRow}>
												{index % 2 === 0 && (
													<Col className={styles.columnLeft}>
														<Link to={`${url}/article/${item.slug}`}>
															<span className={styles.articlesList}>
																{item.heading}
															</span>
														</Link>
													</Col>
												)}
												{index + 1 < array.length && (index + 1) % 2 !== 0 && (
													<Col className={styles.columnRight}>
														<Link
															to={`${url}/article/${array[index + 1].slug}`}
														>
															<span className={styles.articlesList}>
																{array[index + 1].heading}
															</span>
														</Link>
													</Col>
												)}
											</Row>
										)
									) : (
										<Row className={styles.textRow}>
											<Col className={styles.columnLeft}>
												<Link to={`${url}/article/${item.slug}`}>
													{item.heading}
												</Link>
											</Col>
										</Row>
									)}
								</>
							))}
						</div>
					</>
				)}
			</div>
		</>
	);
};
export default compose(withUI, withAuth, withRoot)(FaqSubSection);
