/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { WizardStep } from './index';
import styles from './Wizard.module.css';

class Wizard extends Component {
	state = { steps: this.props.steps };
	static propTypes = {
		steps: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
				status: PropTypes.string,
				title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
			})
		).isRequired,
	};

	componentDidUpdate(prevProps) {
		if (prevProps.steps !== this.props.steps) {
			this.setState(this.props.steps);
		}
	}

	itemClickHandler = (step) => {
		if (step.status !== 'disabled') {
		}
	};

	render() {
		const { className } = this.props;
		const classes = classNames(className, styles['Wizard']);

		return (
			<div className={classes}>
				{this.props.steps.map((step) => (
					<WizardStep
						{...step}
						key={step.id}
						step={step}
						handleOpenPrevTabs={this.props.handleOpenPrevTabs}
						onClick={() => this.itemClickHandler(step)}
					/>
				))}
			</div>
		);
	}
}

export default Wizard;
