/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */

import { Loading } from 'components';
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';
import { Wizard } from 'components/Wizard';
import { InfoButton } from 'components/Buttons';
import paymentTypes from 'constants/paymentTypes';
import { withAuth, withLang, withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
import { getHelpMessage } from '../../../../../messages/helper';
import { compose } from 'recompose';
import styles from './Payments.module.css';

class Payments extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);
		this.state = {
			isEdit: false,
			isShow: true,
			loading: true,
			payments: {
				payment_channel: paymentTypes.BANK,
			},
		};
		this.form = React.createRef();
		this.steps = [
			{
				id: 1,
				status: 'complete',
				title: (
					<FormattedMessage
						id={`rod.account.create.step_2_1_${props.personalData.business_type}.title`}
					/>
				),
			},
			{
				id: 2,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_2_2.title'} />,
			},
			{
				id: 3,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_2_3.title'} />,
			},
			{
				id: 4,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_2_4.title'} />,
			},
			{
				id: 5,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_2_5.title'} />,
			},
		];
	}

	toggle = () => {
		if (this.state.isEdit) {
			this.setState({ isShow: !this.state.isShow });
		}
	};

	nextHandler = (e) => {
		const { isShow } = this.state;
		e.preventDefault();

		if (typeof this.props.onChange === 'function') {
			if (isShow) {
				let correctedState = { ...this.state.payments };
				if (this.state.payments.payment_channel === paymentTypes.BANK) {
					delete correctedState.paypal;
				}
				if (this.state.payments.payment_channel === paymentTypes.PAYPAL) {
					delete correctedState.bank_account_number;
					delete correctedState.bank_account_swift;
					delete correctedState.bank_account_title;
					delete correctedState.bank_address;
					delete correctedState.bank_correspondent_account_number;
					delete correctedState.bank_correspondent_account_swift;
					delete correctedState.bank_title;
				}
				this.props.onChange(correctedState);
			} else {
				this.props.onChange({});
			}
		}
	};

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.id !== this.props.id) {
			this.update();
		}
	}

	update() {
		const {
			rootContext: { getAccountPaymentData },
		} = this.props;

		getAccountPaymentData(this.props.id)
			.then((res) => {
				res = res.data.data;
				const { ...rest } = res;

				this.setState(
					update(this.state, {
						payments:
							Array.isArray(res) && !res.length
								? { $set: { payment_channel: paymentTypes.BANK } }
								: { $set: { ...rest } },
						loading: { $set: false },
						isEdit:
							Array.isArray(res) && !res.length
								? { $set: true }
								: { $set: false },
					})
				);
			})
			.catch((error) => {
				console.error('Payments - getAccountPaymentData error: ', error);
				console.error(
					'Payments - getAccountPaymentData error.response: ',
					error.response
				);
			});
	}

	changeField = (field) => (value) => {
		if (
			field === 'bank_correspondent_account_number' &&
			!Number.isInteger(+value)
		) {
			return;
		} else if (this.state.isEdit) {
			this.setState(
				update(this.state, { payments: { [field]: { $set: value } } })
			);
		}
	};

	handleEditToggle = () => {
		this.setState({
			isEdit: !this.state.isEdit,
		});
	};

	renderPaymentFields() {
		const {
			UIContext: { showModal },
			rootContext: { countries },
			errors,
		} = this.props;

		const lang = localStorage.getItem('lang');
		const message = DOMPurify.sanitize(...getHelpMessage(lang, 'payments'));
		const { isShow, payments } = this.state;

		return (
			<div>
				<Row>
					<Col sm={11} offset={{ sm: 1 }}>
						<div className={styles.HeaderLine}>
							<div
								className={styles.labelWrapper}
								style={{ 'margin-bottom': '34px' }}
							>
								<InfoButton
									className={styles.infoButton}
									onClick={showModal({
										text: parse(message),
									})}
								>
									<Label
										text={
											<FormattedMessage
												id={'rod.account.create.payments.header'}
											/>
										}
										className={styles.Header}
										font="--gilroy-Medium-40"
										display="inline"
										black
									/>
								</InfoButton>
								{!this.state.isEdit && (
									<button onClick={this.handleEditToggle}>
										<svg
											width="25"
											height="25"
											viewBox="0 0 25 25"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M24.1089 22.793H0.891603C0.403488 22.793 0.0214844 23.1962 0.0214844 23.6631C0.0214844 24.13 0.42471 24.5332 0.891603 24.5332H24.1301C24.6183 24.5332 25.0003 24.13 25.0003 23.6631C25.0003 23.1962 24.597 22.793 24.1089 22.793Z"
												fill="black"
											/>
											<path
												d="M0.0212224 15.0891L0 19.2275C0 19.4609 0.0848897 19.6944 0.254669 19.8642C0.424448 20.034 0.636672 20.1188 0.870119 20.1188L4.98727 20.0976C5.22071 20.0976 5.43294 20.0127 5.60272 19.843L19.8217 5.62394C20.1613 5.28438 20.1613 4.7326 19.8217 4.37182L15.747 0.254669C15.4075 -0.0848896 14.8557 -0.0848896 14.4949 0.254669L11.6511 3.11969L0.275891 14.4737C0.127335 14.6435 0.0212224 14.8557 0.0212224 15.0891ZM15.1316 2.12224L17.9966 4.98727L16.3837 6.60017L13.5187 3.73514L15.1316 2.12224ZM1.78268 15.4711L12.2666 4.98727L15.1316 7.85229L4.64771 18.3149L1.76146 18.3362L1.78268 15.4711Z"
												fill="black"
											/>
										</svg>
										<span className={styles.editText}>
											<FormattedMessage id={'rod.admin.reports.edit'} />
										</span>
									</button>
								)}
							</div>
							<div
								style={{
									pointerEvents: this.state.isEdit ? 'all' : 'none',
									opacity: this.state.isEdit ? '1' : '0.6',
								}}
							>
								<Checkbox
									text={<FormattedMessage id={'rod.field.skip_step'} />}
									className={styles.Checkbox}
									checked={!isShow}
									onChange={this.toggle}
								/>
							</div>
						</div>
					</Col>
				</Row>
				{isShow && (
					<form
						onSubmit={this.nextHandler}
						ref={this.form}
						style={{
							pointerEvents: this.state.isEdit ? 'all' : 'none',
							opacity: this.state.isEdit ? '1' : '0.6',
						}}
					>
						<Row>
							{payments.type && (
								<Col sm={5} offset={{ sm: 0 }} className={styles.Header}>
									<Label
										className="subheader"
										text={<FormattedMessage id={'rod.form.required_title'} />}
										font="--gilroy-Medium-18"
										black
									/>
								</Col>
							)}
						</Row>
						{payments.payment_channel === paymentTypes.BANK && (
							<Row>
								<Col sm={5} offset={{ sm: 1 }}>
									<FormattedMessage id={'rod.field.beneficiary_name'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_account_title'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.bank_account_name'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_account_number'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.bank_name'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_title'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.swift_code'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_account_swift'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
								</Col>
								<Col sm={5}>
									<FormattedMessage id={'rod.field.bank_country'}>
										{(placeholder) => (
											<FormInput
												type="country"
												countries={countries}
												placeholder={placeholder}
												name={'bank_country_id'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
												className={styles.CountryInput}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.bank_address'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_address'}
												onChange={this.changeField}
												errors={errors}
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.cor_account'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_correspondent_account_number'}
												onChange={this.changeField}
												errors={errors}
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.cor_swift'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_correspondent_account_swift'}
												onChange={this.changeField}
												errors={errors}
												data={payments}
											/>
										)}
									</FormattedMessage>
								</Col>
							</Row>
						)}

						{payments.payment_channel === paymentTypes.PAYPAL && (
							<Row>
								<Col sm={5} offset={{ sm: 1 }}>
									<FormattedMessage id={'rod.field.paypal_account'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'paypal'}
												onChange={this.changeField}
												helper={
													<FormattedMessage
														id={'rod.field.paypal_account.helper'}
													/>
												}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
								</Col>
							</Row>
						)}
					</form>
				)}
			</div>
		);
	}

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<div className={styles.Page}>
				<Wizard
					steps={this.steps}
					className={styles.Wizard}
					handleOpenPrevTabs={this.props.handleOpenPrevTabs}
				/>
				<div className={styles.Container}>
					{this.renderPaymentFields()}
					{this.renderControls()}
				</div>
			</div>
		);
	}

	renderControls() {
		return (
			<Row>
				<Col md={5} offset={{ sm: 1 }}>
					<div className={styles.Controls}>
						<Button
							text={<FormattedMessage id={'rod.action.complete'} />}
							variant={'primary'}
							onClick={this.nextHandler}
						/>
					</div>
				</Col>
			</Row>
		);
	}
}

export default compose(withRoot, withAuth, withLang, withUI)(Payments);
