import { withAuth, withRoot, withUI } from 'hocs';
import moment from 'moment';

import React, { useEffect, useState } from 'react';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './StatusHistory.module.css';
import { Loading } from 'components';
import { Pagination } from 'material-design/components';
import { FormattedMessage } from 'react-intl';

function StatusHistory(props) {
	const [loading, setLoading] = useState(true);

	const [total, setTotal] = useState(0);
	const [historyData, setHistoryData] = useState([]);

	const [page, setPage] = useState(1);

	moment.locale('ru');

	useEffect(() => {}, []);

	const {
		rootContext: { getAdminSteps, getSignupsSteps },
		id,
		isSignup,
	} = props;
	useEffect(() => {
		switch (isSignup) {
			case true:
				getSignupsSteps(id, page)
					.then((res) => {
						setHistoryData(res.data);
						setTotal(res.total);
						setLoading(false);
					})
					.catch((err) => console.log.log(err));

				break;
			case false:
				getAdminSteps(id, page)
					.then((res) => {
						setHistoryData(res.data);
						setTotal(res.total);
						setLoading(false);
					})
					.catch((err) => console.log.log(err));

				break;
			default:
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const fm = (id) => {
		return <FormattedMessage id={id} />;
	};

	const statusLocalization = (status) => {
		switch (status) {
			case accountCompletionSteps.PHONE_CONFIRM:
				return fm('rod.field.admin.phone');
			case accountCompletionSteps.EMAIL_CONFIRM:
				return fm('rod.field.admin.email');
			case accountCompletionSteps.ACCOUNT_CREATED:
				return fm('rod.field.admin.acc-created') /* 'account' */;
			case accountCompletionSteps.BEGIN:
				return fm(
					'rod.release.create.step.tracks.fragment.start'
				) /* 'begin' */;
			case accountCompletionSteps.ROLE:
				return fm('rod.field.admin.role-selection') /* 'role' */;
			case accountCompletionSteps.PERSONAL_DATA:
				return fm('rod.account.personal.title'); /* 'personal_data' */
			case accountCompletionSteps.CONTACT_DATA:
				return fm(
					'rod.admin.account.contact_information'
				); /* 'contact_data'; */
			case accountCompletionSteps.DOCUMENTS:
				return fm('rod.field.admin.documents'); /* 'documents'; */
			case accountCompletionSteps.IDENTITY:
				return fm('rod.field.admin.identifiers'); /* 'identity'; */
			case accountCompletionSteps.MONEY:
				return fm('rod.field.admin.payment-details'); /* 'money'; */
			case accountCompletionSteps.PENDING:
				return fm('rod.admin.pending_documents'); /* 'pending documents'; */
			case accountCompletionSteps.PENDING_CONTRACT:
				return fm('rod.admin.pending_contracts'); /* 'pending_contracts'; */
			case accountCompletionSteps.APPROVED:
				return fm('rod.admin.approved_documents'); /* 'approved'; */
			case accountCompletionSteps.DECLINED:
				return fm('rod.repertoire.rejected'); /* 'declined'; */
			case accountCompletionSteps.DECLINED_DOCUMENTS:
				return fm('rod.admin.declined_documents'); /*  ;'declined_docs'; */
			case accountCompletionSteps.DECLINED_CONTRACTS:
				return fm('rod.admin.declined_contracts'); /* 'declined_contracts'; */
			case accountCompletionSteps.APPROVED_CONTRACTS:
				return fm('rod.admin.approved_contracts'); /* 'approved_contracts'; */
			case accountCompletionSteps.SIGN_PHYSICAL:
				return fm('rod.field.admin.signing'); /* 'sign_physical'; */
			case accountCompletionSteps.CONFIRM:
				return fm('rod.admin.account.status.confirm'); /* 'confirm'; */
			case accountCompletionSteps.SIGN_SMS:
				return fm('rod.field.admin.signing'); /* 'sign_sms'; */
			case accountCompletionSteps.SMS_CONFIRM:
				return fm('rod.admin.account.status.sms_confirm'); /* 'sms_confirm'; */
			case accountCompletionSteps.COMPLETE:
				return fm('rod.contracts.complete.header'); /* 'completed'; */
			case accountCompletionSteps.PERSONAL_AREA:
				return fm(
					'rod.admin.account.status.personal_area'
				); /* 'personal_area'; */
			case accountCompletionSteps.REPERTOIRE_APPLY:
				return fm(
					'rod.admin.account.status.repertoire_apply'
				); /* 'repertoire_apply'; */
			case accountCompletionSteps.PERSONAL_AREA_LEGASY:
				return fm(
					'rod.admin.account.status.personal_area_legacy'
				); /* 'personal_area_legacy'; */
			case accountCompletionSteps.LIST:
				return status; /* 'list'; */
			default:
				return status;
		}
	};

	const typeLocalization = (type) => {
		switch (type) {
			case 'manual':
				return fm('rod.admin.status_history.manual_type');
			case 'automatic':
				return fm('rod.admin.status_history.auto_type');
			default:
				return type;
		}
	};

	const getAuthor = (item) => {
		if (item.type === 'automatic') return '';

		if (item.performed_by_title) {
			return (
				<div>
					<span>{item.performed_by_title}</span>
					<span>{item.performed_by_title}</span>
				</div>
			);
		} else {
			return '';
		}
	};

	return (
		<>
			<div className={styles.page}>
				<table className={styles.Table}>
					<tr>
						<th className="firstCol">
							<FormattedMessage id={'rod.field.admin.status_history.change'} />
						</th>
						<th>
							<FormattedMessage
								id={'rod.field.admin.status_history.date_time'}
							/>
						</th>
						<th>
							<FormattedMessage
								id={'rod.field.admin.status_history.change_type'}
							/>
						</th>
						<th>
							<FormattedMessage
								id={'rod.field.admin.status_history.changed_by'}
							/>
						</th>
					</tr>
					{loading ? (
						<tr className={styles.loaderWrapper}>
							<td colspan="4">
								<Loading className={styles.loader} />
							</td>
						</tr>
					) : (
						<>
							{historyData?.length === 0 && (
								<>
									<tr>
										<td colspan="4" />
									</tr>
									<tr className={styles.noData}>
										<td colspan="4">
											<FormattedMessage id={'rod.admin.no-data'} />
										</td>
									</tr>
									<tr>
										<td colspan="4" />
									</tr>
								</>
							)}
							{historyData?.length > 0 &&
								historyData
									// .sort((a, b) => +b.updated_at - +a.updated_at)
									.map((item) => (
										<tr>
											<td className="firstCol">
												{(!isSignup ||
													item.action === 'signup.status_change') && (
													<>
														{item.step_from
															? statusLocalization(item.step_from)
															: '(-)'}{' '}
														&#10230; {statusLocalization(item.step_to)}
													</>
												)}
												{isSignup &&
													[
														'signup.phone.manual_verification',
														'signup.email.manual_verification',
													].includes(item.action) && (
														<>
															<FormattedMessage
																id={`rod.admin.${item.action}`}
															/>
														</>
													)}
											</td>
											<td>
												{moment(item.updated_at)
													/* .subtract(10, 'days') */
													.calendar()}{' '}
												{moment(item.updated_at).format('LT')}
											</td>
											<td>{typeLocalization(item.type)}</td>
											<td className={styles.author}>{getAuthor(item)}</td>
										</tr>
									))}
						</>
					)}
				</table>
				{!loading && total > 20 && (
					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => setPage(pageNumber)}
						items_on_page={20}
					/>
				)}
			</div>
		</>
	);
}

export default compose(withRoot, withAuth, withUI, withRouter)(StatusHistory);
