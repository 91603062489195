import React, { useEffect, useState, useContext } from 'react';
import { InfoButton } from 'components/Buttons';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import close_darkgrey from 'images/close_darkgrey.svg';
import arrow_grey from 'images/arrow_grey.svg';
import s from './Lyrics.module.css';
import { UIContext } from 'contexts/UIContext';
import { AuthContext } from 'contexts/AuthContext';
import { ReactComponent as Selected } from 'images/selected.svg';
import { accounts } from 'services';
import { LyricsForm } from './LyricsForm';
import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router-dom';
import { BottomNavi } from 'components';

const Lyrics = (props) => {
	const [toogleMenu, setToogleMenu] = useState('');
	const [personal, setPersonal] = useState([]);
	const [isTextAreaOn, setIsTextAreaOn] = useState(false);

	const [selectPerson, setSelectPerson] = useState({});
	const [languages, setLanguages] = useState([]);
	const [isReqLive, setIsReqLive] = useState(false);
	const [errors, setErrors] = useState({});
	const [audio, setAudio] = useState(new Audio());

	const { showModal, play, handlePlay, audioId, handlePlayStop } = useContext(
		UIContext
	);

	const history = useHistory();

	const { accountId } = useContext(AuthContext);

	const releaseId = localStorage.getItem('releaseId');
	const MAX_LYRICS_LENGTH = 10000;

	useEffect(() => {
		accounts
			.getReleaseData(releaseId)
			.then((res) => {
				const tracks = correctDateInTracks(res.data.data.tracks);

				setPersonal(tracks);

				let first = true;
				let countValid = 0;
				for (let i = 0; i < tracks.length; i++) {
					tracks[i].language = tracks[i].language
						? tracks[i].language
						: res.data.data.language || null;
					tracks[i].parental_warning_type = tracks[i].parental_warning_type
						? tracks[i].parental_warning_type
						: res.data.data.parental_warning_type;
					tracks[i].lyrics = tracks[i].lyrics
						? tracks[i].lyrics
						: res.data.data.lyrics;

					if (!isValid(tracks[i])) {
						tracks[i].done = true;
						countValid++;
					} else if (first) {
						setToogleMenu(i);
						first = false;
					}
					if (countValid === tracks.length) {
						// setToogleMenu('');
						setToogleMenu(0);
					}
				}
			})
			.catch((error) => {
				console.info('Error', error);
			});
		const languagesCache = lscache.get('languagesCache');
		if (languagesCache && !isReqLive) {
			setLanguages(languagesCache);
		} else {
			accounts
				.getLanguages()
				.then((res) => {
					res = res.data.data;
					setCache('languages', 'languagesCache', res);
					setLanguages(res);
				})
				.catch((error) => {
					console.info('Error', error);
				});
		}
	}, []);

	useEffect(() => {
		if (toogleMenu === '') {
			return;
		}

		if (personal[toogleMenu]?.lyrics_id?.url) {
			getLyricTextFromFile(personal[toogleMenu].lyrics_id.url);
		} else {
			setSelectPerson(personal[toogleMenu]);
			setIsTextAreaOn(false);
		}

		setErrors({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toogleMenu]);

	const getLyricTextFromFile = (url) => {
		fetch(url)
			.then((res) => res.text())
			.then((text) => {
				personal[toogleMenu].lyrics = text;
				setIsTextAreaOn(true);
				setSelectPerson(personal[toogleMenu]);
			})

			.catch((err) => {
				setSelectPerson(personal[toogleMenu]);
				console.info('Error:', err);
			});
	};

	const correctDateInTracks = (tracks) => {
		try {
			const validDates = tracks.map((track) => ({
				...track,
				created_date: track.created_date
					? track.created_date.slice(0, 10)
					: track.created_date,
			}));
			return validDates;
		} catch (err) {
			console.log('incorrect/missing date in tracks from back');
			return tracks;
		}
	};

	const changeField = (field) => (e) => {
		setErrors({});
		switch (field) {
			case 'language':
				selectPerson[field] = e.id;
				break;
			case 'parental_warning_type':
				selectPerson[field] = e.code;
				break;

			default:
				selectPerson[field] = e.target.value;
				break;
		}
		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const handleChecked = (e) => {
		if (
			!e.target.checked &&
			personal[toogleMenu].lyrics &&
			personal[toogleMenu]?.lyrics_id?.url
		) {
			personal[toogleMenu].lyrics = null;
			setPersonal([...personal]);
		}
		setIsTextAreaOn(e.target.checked);
	};

	const isValid = (toCheck, showErr = false) => {
		let errorCount = 0;
		const newError = {};

		if (!toCheck.lyrics && isTextAreaOn) {
			newError.lyrics = 'required';
			errorCount++;
		}

		if (toCheck.lyrics && isTextAreaOn && +toCheck.lyrics.length === 0) {
			newError.lyrics = 'required';
			errorCount++;
		}

		if (
			toCheck.lyrics &&
			isTextAreaOn &&
			+toCheck.lyrics.length > MAX_LYRICS_LENGTH
		) {
			newError.lyrics = 'max_length';
			errorCount++;
		}

		if (typeof toCheck.language !== 'number') {
			newError.language = 'обязательное поле';
			errorCount++;
		}

		if (!toCheck.parental_warning_type) {
			newError.parental_warning_type = 'обязательное поле';
			errorCount++;
		}

		if (!errorCount) {
			setErrors({});
			return false;
		} else {
			if (showErr) {
				setErrors(newError);
			}
			return true;
		}
	};

	const recordingSave = () => {
		if (isValid(selectPerson, true)) return;

		const clone = cloneDeep(selectPerson);
		clone.account_id = accountId;
		clone.add_lyrics = isTextAreaOn;

		accounts
			.uploadRecordingInfo(releaseId, selectPerson.id, clone)
			.then((res) => {
				res = res.data.data;
				if (res.isrc) {
					const data = res.isrc.replace(
						/(\d{2})\.(\d{3})\.(\d{2})\.(\d{5})/,
						'$1-$2-$3-$4'
					);

					selectPerson.isrc = data;
				}
				selectPerson.done = true;
				personal[toogleMenu] = selectPerson;
				setPersonal(personal);
				if (personal[toogleMenu + 1]) {
					if (!isValid(personal[toogleMenu + 1])) {
						setToogleMenu('');
					} else {
						setToogleMenu(toogleMenu + 1);
					}
				} else {
					setToogleMenu('');
				}
			})
			.then(() => {
				const ellementToScroll = document.getElementById('general');
				if (ellementToScroll) {
					ellementToScroll.scrollIntoView({
						behavior: 'smooth',
						block: 'end',
						inline: 'nearest',
					});
				}
			})
			.catch((err) => {
				console.info('Error', err.response?.data?.errors);
				console.info('Error', err.response?.data);
				setErrors(err.response?.data?.errors);
			});
	};

	const nextHandler = () => {
		if (typeof props.onChange === 'function') {
			setPersonal(personal);
			audio.currentTime = 0;
			audio.pause();
			props.onChange(personal);
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			history.push(`/release/${releaseId}/authors`);
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const handleForm = (index) =>
		toogleMenu === index ? setToogleMenu('') : setToogleMenu(index);

	const disabledNextBtn = () => {
		if (!personal.every((item) => item.done) || personal.length === 0)
			return true;

		return false;
	};

	return (
		<>
			<div className={s.page}>
				<div
					className={
						toogleMenu !== '' ? s.main__wrapper : s.main__wrapper_whithoutForm
					}
				>
					<div
						className={
							toogleMenu !== '' ? s.main : `${s.main} ${s.main__notForm}`
						}
					>
						<InfoButton
							className={`${s.info} ${s.title__info}`}
							onClick={showModal({
								text: (
									<FormattedHTMLMessage
										id={'rod.release.create.step.tracks.title.help'}
									/>
								),
							})}
						>
							<span className={s.title}>
								<FormattedHTMLMessage id={'rod.release.create.step.lyrics'} />
							</span>
						</InfoButton>
						<ul className={s.table}>
							{personal.length !== 0 &&
								personal.map((item, index) => (
									<li
										key={index}
										className={
											index === toogleMenu
												? `${s.table__item} ${s.table__itemSel}`
												: s.table__item
										}
									>
										{item.done ? (
											<Selected className={s.tableItem__sel} />
										) : (
											<div
												className={`${s.tableItem__inselected} ${s.tableItem__sel}`}
											></div>
										)}
										<span>{index + 1}</span>
										<span>{item.title ?? item.file.client_name}</span>
										{(audioId === item.id && play) ||
										(audioId === item.file.id && play) ? (
											<img
												src={stop_button}
												className={s.play}
												onClick={handlePlayStop}
												alt=""
											/>
										) : (
											<img
												src={play_button}
												className={s.play}
												onClick={() =>
													handlePlay(
														index,
														item.file,
														item.title ?? item.file.client_name
													)
												}
												alt=""
											/>
										)}

										<img
											src={toogleMenu === index ? close_darkgrey : arrow_grey}
											className={s.toogleMenu}
											onClick={() => handleForm(index)}
											alt=""
										/>
										<span></span>
									</li>
								))}
						</ul>
					</div>
					{toogleMenu !== '' && (
						<LyricsForm
							errors={errors}
							changeField={changeField}
							handleForm={handleForm}
							toogleMenu={toogleMenu}
							selectPerson={selectPerson}
							languages={languages}
							handleChecked={handleChecked}
							isTextAreaOn={isTextAreaOn}
							recordingSave={recordingSave}
						/>
					)}
				</div>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn()}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
};

export default Lyrics;
