export const onEditCompositionHandler = (
	e,
	compositionId,
	accounts,
	history
) => {
	e.preventDefault();

	accounts
		.updateCompositionStep(compositionId, { step: 'confirm' })
		.catch((error) => {
			console.info('Error', error);
		});

	localStorage.setItem('compositionId', compositionId);
	history.push(`/compositions/${compositionId}/review`);
};
