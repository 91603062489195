/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 18:59
 */

import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';
// import Loading from 'components/Loading';
import { DropZone } from 'components/Upload/DropZone';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';
import iconPdf from 'images/icon_pdf.svg';
import iconXls from 'images/icon_xls.svg';
import iconFile from 'images/file_large.svg';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
// import PropTypes from 'prop-types';
import styles from './LegacyDashboard.module.css';
// import { AuthContext } from '../../../contexts/AuthContext';

const LegacyDashboard = (props) => {
	const {
		getAccountPersonalData,
		getAccountContactsData,
		uploadRepertoire,
	} = useContext(RootContext);
	const { lang } = useContext(LangContext);
	// const { user } = useContext(AuthContext);
	const { showUploadHandler, hideUploadHandler } = useContext(UIContext);
	const [account, setAccount] = useState(null);
	const [contactsData, setContactsData] = useState(null);
	const [repertoireFile, setRepertoireFile] = useState(null);
	const [uploaded, setUploaded] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	// const [isUploadError, setIsUploadError] = useState(false);
	// const [uploadErrorType, setUploadErrorType] = useState(false);
	let { params } = useRouteMatch();
	let { push } = useHistory();
	// const [isBannerHidden, closeBanner] = useState(localStorage.getItem('bannerHidden'));

	// const onCloseBanner = () => {
	// 	closeBanner(true);
	// 	localStorage.setItem('bannerHidden', true);
	// };

	useEffect(() => {
		getAccountPersonalData(params.id)
			.then((account) => {
				account = account.data.data;
				if (
					account.completion_step !== accountCompletionSteps.PERSONAL_AREA &&
					account.completion_step !== accountCompletionSteps.REPERTOIRE_APPLY &&
					account.completion_step !==
						accountCompletionSteps.PERSONAL_AREA_LEGASY
				) {
					push(`/accounts/${params.id}`);
					hideUploadHandler();
				} else {
					// hideBackNaviHandler();
					showUploadHandler();
				}
				setAccount(account);
			})
			.catch((err) => {
				console.error(
					'LegacyDashboard - error.response - getAccountPersonalData: ',
					err.response
				);
				console.error('LegacyDashboard - error - getAccountPersonalData: ', err);
				push('/accounts');
			});

		getAccountContactsData(params.id)
			.then((res) => {
				res = res.data.data;
				setContactsData(res);
			})
			.catch((err) => push('/accounts'));

		return () => {
			hideUploadHandler();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const upload = (e) => {
		const fd = new FormData();
		// const { files } = e.currentTarget;
		// for (let i = 0; i < files.length; i++) {
		// 	fd.append(`file[${i}]`, files[i]);
		// }
		fd.append(`file[0]`, repertoireFile);
		setIsUploading(true);
		uploadRepertoire(params.id, fd)
			.then((res) => {
				res = res.data.data;
				setUploaded(true);
				setIsUploading(false);
				setRepertoireFile(null);
			})
			.catch((err) => {
				setUploaded(false);
				setIsUploading(false);
				setRepertoireFile(null);
			});
	};

	const filesEn = [
		{
			icon: iconXls,
			title: 'Download  instruction how to fill-in repertoire form',
			filename:
				'Customer Instructions for H11 Template ENGLISH corrected v.2.2.xlsx',
		},
		{
			icon: iconXls,
			title: 'Download sample of filled in form',
			filename: 'metadata_import_template_sample_en_v2.1.xlsx',
		},
		{
			icon: iconXls,
			title: 'Download repertoire form',
			filename: 'metadata_import_template_en_v2.5.xlsx',
		},
		{
			icon: iconPdf,
			title: 'Download an instruction for calculation of authors shares',
			filename: 'How to calculate and fill in shares.pdf',
		},
	];
	const filesRu = [
		{
			icon: iconXls,
			title: 'Скачать инструкции по заполнению формы',
			filename:
				'Customer Instructions for H11 Template RU corrected v.2.2.xlsx',
		},
		{
			icon: iconXls,
			title: 'Скачать форму для заполнения данных репертуара',
			filename: 'metadata_import_template_ru_v2.5.xlsx',
		},
		{
			icon: iconXls,
			title: 'Скачать образец заполненной формы',
			filename: 'metadata_import_template_sampleru_v2.2.xlsx',
		},
		{
			icon: iconPdf,
			title: 'Скачать инструкцию по расчету долей авторов',
			filename: 'Как расчитать и заполнить доли.pdf',
		},
	];

	const formsRu = [
		{
			icon: iconFile,
			title: 'Зарегистрировать релиз онлайн',
			//Release registration v2 with required files
			url: 'https://form.jotform.com/213324096236351',
			/*
			//Release registration without files
			url: 'https://form.jotform.com/211093575127353',
			 */
		},
		{
			icon: iconFile,
			title: 'Зарегистрировать произведение онлайн',
			url: 'https://form.jotform.com/210983625957367',
		},
	];

	const formsEn = [
		{
			icon: iconFile,
			title: 'Register release online',
			//Release registration v2 with required files
			url: 'https://form.jotform.com/213324096236351',
			/*
			//Release registration without files
			url: 'https://form.jotform.com/211093575127353',
			 */
		},
		{
			icon: iconFile,
			title: 'Register composition online',
			url: 'https://form.jotform.com/210983625957367',
		},
	];

	const handleFileUpload = (files) => {
		setRepertoireFile(files[0]);
		return Promise.resolve();
	};
	const handleFileRemove = () => {
		setRepertoireFile(null);
	};

	const files = lang === 'ru' ? filesRu : filesEn;
	const forms = lang === 'ru' ? formsRu : formsEn;
	const repFile = repertoireFile
		? {
				client_name: repertoireFile.name,
				size: repertoireFile.size,
		  }
		: null;

	const loadMore = () => {
		setUploaded(false);
	};

	return (
		<Row nogutter nowrap className={styles.Row}>
			<Col sm={6} offset={{ sm: 1 }} className={styles.Left}>
				<div className={styles.Form}>
					<div className={styles.Header}>
						<Label
							text={
								<FormattedMessage
									id={
										uploaded
											? 'rod.repertoire.legacy.uploaded.title'
											: 'rod.repertoire.legacy.welcome.title'
									}
								/>
							}
							font="--gilroy-Medium-40"
							black
						/>
					</div>
					<div className={styles.FormLine}>
						<div className={styles.Subheader}>
							<Label
								text={
									<FormattedHTMLMessage
										id={
											uploaded
												? 'rod.repertoire.legacy.uploaded.content'
												: 'rod.repertoire.legacy.welcome.content.part'
										}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>
						</div>
						{false && !uploaded && (
							<DropZone
								onUpload={handleFileUpload}
								value={repFile}
								accept=".xlsx, .xls"
								onRemove={handleFileRemove}
								kb
							/>
						)}
					</div>
					{false && uploaded && (
						<Button
							text={<FormattedMessage id={'rod.action.upload_more'} />}
							variant={'primary'}
							onClick={loadMore}
						/>
					)}
					{false && !uploaded && (
						<div className={styles.Controls}>
							<Button
								disabled={!repertoireFile || isUploading}
								text={<FormattedMessage id={'rod.action.upload'} />}
								variant={'primary'}
								onClick={upload}
							/>
						</div>
					)}
				</div>
			</Col>
			<Col sm={5} className={styles.Right}>
				<div>
					<Label
						text={<FormattedMessage id={'rod.repertoire.welcome.form'} />}
						font="--gilroy-Medium-32"
						black
						className={styles.Header}
					/>
					<div className={styles.Example}>
						{forms.map((form) => (
							<div key={form.title} className={styles.File}>
								<a
									href={`${form.url}?language=${lang}${
										account ? `&accountName=${account.title}` : ''
									}${
										contactsData ? `&accountEmail=${contactsData.email}` : ''
									}`}
									target="_blank"
									rel="noreferrer"
								>
									<img src={form.icon} alt="" /> <span>{form.title}</span>
								</a>
							</div>
						))}
						<br />
						<br />
					</div>
					{false && (
						<Label
							text={<FormattedMessage id={'rod.repertoire.welcome.download'} />}
							font="--gilroy-Medium-32"
							black
							className={styles.Header}
						/>
					)}
					<div className={styles.Example}>
						{false &&
							files.map((file) => (
								<div key={file.title} className={styles.File}>
									<a
										href={`/examples/${file.filename}`}
										target="_blank"
										rel="noreferrer"
									>
										<img src={file.icon} alt="" /> <span>{file.title}</span>
									</a>
								</div>
							))}
					</div>
				</div>
			</Col>
		</Row>
	);
};

LegacyDashboard.propTypes = {};

export default LegacyDashboard;
