import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { InfoButton } from 'components/Buttons';
import parse from 'html-react-parser';
import { Label } from 'components/Labels/Label';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { UIContext } from 'contexts/UIContext';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import { RootContext } from 'contexts/RootContext';
import { Col, Row } from 'react-grid-system';
import { Loading } from 'components';
import { Button } from 'components/Buttons/Button';

import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';
import { FormInput } from 'components/Form/FormInput';
import add_box_line from 'images/add_box_line.svg';
import cross from 'images/cross.svg';
import s from './CreatePseudonym.module.css';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { accounts } from 'services';

const CreatePseudonym = () => {
	// const [businessType, setBusinessType] = useState('');

	const [pseudonymData, setPseudonymData] = useState({
		outlets: [{ id: '', outlet_id: '', outlet: '', id_outlet_user: '' }],
		role_alias: [],
	});
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [roles, setRoles] = useState([
		{
			id: 7,
			code: 'Libr33333ettist',
			scope: 'com44position',
			title: 'Libre33ttist',
		},
		{ id: 8, code: 'Librettist', scope: 'composition', title: 'Librettist' },
	]);
	const [isEdit, setIsEdit] = useState(false);
	const [outletsArr, setOutletsArr] = useState([]);
	const [whereHelpBtnUP, setWhereHelpBtnUP] = useState('');
	const [isIPIExists, setIsIPIExists] = useState(true);

	const {
		getAccountPersonalData,
		getContributorRolesByAccId,
		getContributorRoles,
		createPseudonym,
		getOnePseudonymData,
		updatePseudonym,
		getContractData,
		// ...props
	} = useContext(RootContext);

	const {
		/* showTitle, hideTitle,  */ showBackNavi,
		hideBackNavi,
	} = useContext(UIContext);
	const { accountId } = useContext(AuthContext);
	const lang = localStorage.getItem('lang');
	const { showModal } = useContext(UIContext);
	const history = useHistory();
	const match = useRouteMatch();

	const message = DOMPurify.sanitize(
		...getHelpMessage(lang, 'pseudonyms_create')
	);

	useEffect(() => {
		showBackNavi('/profile/settings/pseudonyms');

		if (match.params.pseud_id) {
			setIsEdit(true);
			getOnePseudonymData(accountId, match.params.pseud_id).then((res) => {
				res = res.data.data;
				setPseudonymData(res);
				setIsIPIExists(res.ipi_name_number ? true : false);
				setLoading(false);
			});
		} else {
			getAccountPersonalData(accountId).then((res) => {
				res = res.data.data;
				setPseudonymData((prev) => ({
					...prev,
					first_name: prev.first_name ? prev.first_name : res.first_name,
					last_name: prev.last_name ? prev.last_name : res.last_name,
				}));
			});
			setLoading(false);
		}

		return function cleanup() {
			hideBackNavi();
			// hideTitle();
		};
	}, []);

	useEffect(() => {
		accounts
			.getOutlets(lang)
			.then((res) => {
				res = res.data.data;
				setOutletsArr(res);
				getContributorRolesByAccId(accountId, lang).then((res) => {
					res = res.data;
					setRoles(res);
				});
				// if (match.params.pseud_id) {
				// 	getContributorRolesByAccId(accountId, lang).then((res) => {
				// 		res = res.data;
				// 		setRoles(res);
				// 	});
				// } else {
				// 	getContributorRoles(lang).then((res) => {
				// 		res = res.data;
				// 		setRoles(res);
				// 	});
				// }
			})
			.catch((err) => console.info('err', err));
	}, [lang]);

	const changeFieldOutlet = (index) => (field) => (e) => {
		setErrors({});
		switch (field) {
			case 'outlets':
				pseudonymData[field][index].id = e.id;
				pseudonymData[field][index].outlet_id = e.id;
				pseudonymData[field][index].outlet = e.title;
				const newArr = outletsArr.filter((item) => item.id !== e.id);
				setOutletsArr(newArr);

				break;
			case 'id_outlet_user':
				const regex = /[a-zA-Z0-9]/i;
				if (
					regex.test(e.target.value.slice(e.target.value.length - 1)) &&
					e.target.value.length <= 40
				)
					pseudonymData['outlets'][index].id_outlet_user = e.target.value;
				break;

			default:
				break;
		}
		setPseudonymData({ ...pseudonymData });
	};

	const changeField = (field) => (e) => {
		setErrors({});
		switch (field) {
			case 'ipi_name_number':
				if (
					!e.target.value.includes(' ') &&
					!isNaN(+e.target.value) &&
					e.target.value.length <= 11
				) {
					pseudonymData[field] = e.target.value;
				}
				break;
			case 'ipn':
				if (
					!e.target.value.includes(' ') &&
					!isNaN(+e.target.value) &&
					e.target.value.length <= 8
				) {
					pseudonymData[field] = e.target.value;
				}
				break;
			case 'role_alias':
				pseudonymData[field] = e;
				break;

			default:
				pseudonymData[field] = e.target.value;
				break;
		}
		setPseudonymData({ ...pseudonymData });
	};

	const handleAddOutlet = () => {
		pseudonymData.outlets.push({
			id: '',
			outlet_id: '',
			outlet: '',
			id_outlet_user: '',
		});
		setPseudonymData({ ...pseudonymData });
	};

	const saveHandler = () => {
		let isError = false;

		if (
			pseudonymData.ipi_name_number &&
			pseudonymData.ipi_name_number.length !== 11
		) {
			setErrors((prev) => ({
				...prev,
				ipi_name_number: [{ rule: 'wrong_format' }],
			}));

			isError = true;
		}
		if (pseudonymData.ipn && pseudonymData.ipn.length !== 8) {
			setErrors((prev) => ({ ...prev, ipn: [{ rule: 'wrong_format' }] }));
			isError = true;
		}
		if (pseudonymData.isni && pseudonymData.isni.includes('_')) {
			setErrors((prev) => ({ ...prev, isni: [{ rule: 'wrong_format' }] }));
			isError = true;
		}
		if (!pseudonymData.ipi_name_number) {
			setErrors((prev) => ({
				...prev,
				ipi_name_number: [{ rule: 'required' }],
			}));
			isError = true;
		}
		if (
			pseudonymData.outlets &&
			pseudonymData.outlets.length > 0 &&
			pseudonymData.outlets.find(
				(item) => !item['id_outlet_user'] && item['outlet']
			)
		) {
			setErrors((prev) => ({
				...prev,
				id_outlet_user: [{ rule: 'required' }],
			}));
			isError = true;
		}

		if (
			pseudonymData.outlets &&
			pseudonymData.outlets.length === 1 &&
			pseudonymData.outlets[0].outlet === ''
		) {
			pseudonymData.outlets = [];
		}

		if (isError) return;

		if (isEdit) {
			updatePseudonym(accountId, match.params.pseud_id, pseudonymData).then(
				(res) => {
					history.push('/profile/settings/pseudonyms');
				}
			);
		} else {
			createPseudonym(accountId, pseudonymData).then((res) => {
				history.push('/profile/settings/pseudonyms');
			});
		}
	};

	const handleRemoveOutlet = (index) => {
		setErrors({});

		if (pseudonymData.outlets.length === 1) {
			pseudonymData.outlets = [];
		} else {
			pseudonymData.outlets.splice(index, 1);
		}

		setPseudonymData({ ...pseudonymData });
	};

	const changeMask = (e) => {
		setErrors({});
		pseudonymData.isni = e.target.value;
		setPseudonymData({ ...pseudonymData });
	};

	const handleFocus = (field) => (e) => {
		setWhereHelpBtnUP(field);
	};
	const handleBlur = (field) => (e) => {
		setWhereHelpBtnUP('');
	};

	const getClassnameForInfoBtn = (value) => {
		if (whereHelpBtnUP === value || pseudonymData[value]) {
			if (process.env.REACT_APP_CLIENT === 'mts') {
				return s.infoActiveMts;
			} else {
				return s.infoActive;
			}
		} else {
			if (process.env.REACT_APP_CLIENT === 'mts') {
				return s.infoMts;
			} else {
				return s.info;
			}
		}
	};

	return loading ? (
		<Loading non_margin />
	) : (
		<div className={s.container}>
			<InfoButton
				onClick={showModal({
					text: parse(message),
				})}
			>
				<Label
					className={s.title}
					text={
						!isEdit ? (
							<FormattedMessage id={'rod.account.settings.pseudonyms-add'} />
						) : (
							<FormattedMessage id={'rod.account.settings.pseudonyms-edit'} />
						)
					}
					font="--gilroy-Medium-32"
					display="inline"
					black
				/>
			</InfoButton>
			<span className={s.subTitle}>
				<FormattedMessage id={'rod.form.required_title'} />
			</span>
			<div className={s.form}>
				<div className={s.names}>
					<div className={isEdit ? `${s.formItem} ${s.disabled}` : s.formItem}>
						<FormInput
							type="muiInput"
							name={'title'}
							onChange={changeField}
							onFocus={handleFocus}
							onBlur={handleBlur}
							errors={errors}
							data={pseudonymData}
							required
							label={<FormattedMessage id={'rod.field.pseudonym'} />}
						/>
						<InfoButton
							className={getClassnameForInfoBtn('title')}
							onClick={showModal({
								text: parse(message),
							})}
						/>
					</div>
					<div className={s.formItem}>
						<FormInput
							type="muiInput"
							name={'first_name'}
							onChange={changeField}
							errors={errors}
							data={pseudonymData}
							label={<FormattedMessage id={'rod.field.firstname'} />}
						/>
					</div>
					<div className={s.formItem}>
						<FormInput
							type="muiInput"
							name={'last_name'}
							onChange={changeField}
							errors={errors}
							data={pseudonymData}
							label={<FormattedMessage id={'rod.field.lastname'} />}
						/>
					</div>
				</div>
				<div className={s.numbers}>
					<div
						className={
							isEdit && isIPIExists ? `${s.formItem} ${s.disabled}` : s.formItem
						}
					>
						<FormInput
							type={'muiInputNumber'}
							name={'ipi_name_number'}
							onChange={changeField}
							onFocus={handleFocus}
							onBlur={handleBlur}
							errors={errors}
							required
							data={pseudonymData}
							label={<FormattedMessage id={'rod.field.number_ipi'} />}
						/>
						<InfoButton
							className={getClassnameForInfoBtn('ipi_name_number')}
							style={{
								marginLeft:
									whereHelpBtnUP === 'ipi_name_number' ||
									pseudonymData['ipi_name_number']
										? '10px'
										: '8px',
							}}
							onClick={showModal({
								text: parse(message),
							})}
						/>
						{Object.keys(errors).includes('ipi_name_number') && (
							<span className={s.Helper}>
								{
									<FormattedMessage
										id={`rod.error.${errors.ipi_name_number[0].rule}`}
									/>
								}
							</span>
						)}
					</div>
					<div className={s.formItem}>
						<FormInput
							type="muiInput"
							name={'ipn'}
							onChange={changeField}
							onFocus={handleFocus}
							onBlur={handleBlur}
							errors={errors}
							data={pseudonymData}
							label={<FormattedMessage id={'rod.field.ipn'} />}
						/>
						<InfoButton
							className={getClassnameForInfoBtn('ipn')}
							style={{
								marginLeft:
									whereHelpBtnUP === 'ipn' || pseudonymData['ipn']
										? '10px'
										: '8px',
							}}
							onClick={showModal({
								text: parse(message),
							})}
						/>
						{Object.keys(errors).includes('ipn') && (
							<span className={s.Helper}>
								{<FormattedMessage id={`rod.error.${errors.ipn[0].rule}`} />}
							</span>
						)}
					</div>
					<div className={s.formItem}>
						<InputMask
							mask={'9999 9999 9999 9999'}
							name={`isni`}
							onChange={changeMask}
							onFocus={handleFocus}
							onBlur={handleBlur}
							value={pseudonymData.isni ? pseudonymData.isni : ''}
						>
							{() => (
								<TextFieldInput
									variant="standard"
									type="muiInput"
									name={'isni'}
									error={Object.keys(errors).length > 0 && errors.isni}
									value={pseudonymData.isni ? pseudonymData.isni : ''}
									label={<FormattedMessage id={'rod.field.isni'} />}
								/>
							)}
						</InputMask>
						<InfoButton
							className={getClassnameForInfoBtn('isni')}
							style={{
								marginLeft:
									whereHelpBtnUP === 'isni' || pseudonymData['isni']
										? '13px'
										: '11px',
							}}
							onClick={showModal({
								text: parse(message),
							})}
						/>
						{Object.keys(errors).includes('isni') && (
							<span className={s.Helper}>
								{<FormattedMessage id={`rod.error.${errors.isni[0].rule}`} />}
							</span>
						)}
					</div>
				</div>

				<div className={s.roles}>
					<FormInput
						type={'muiAutocompleteMultiplePseudonimsRoles'}
						name={'role_alias'}
						onChange={changeField}
						errors={errors}
						data={pseudonymData}
						roles={roles}
						label={<FormattedMessage id={'rod.admin.account_roles'} />}
					/>
				</div>

				<div className={s.outlets}>
					<span className={s.outletsTitle}>
						<FormattedMessage id={'rod.release.create.step.submit.outlets'} />
					</span>

					{pseudonymData.outlets.map((item, index) => (
						<div className={s.outletsForm}>
							<div className={s.outletItem}>
								<FormInput
									type={'muiAutocompleteMultiplePseudonimsOutlets'}
									name={'outlets'}
									onChange={changeFieldOutlet(index)}
									errors={errors}
									data={pseudonymData}
									pseudonumOutletIndex={index}
									outletsArr={outletsArr}
									label={<FormattedMessage id={'rod.pseudonyms.outlet'} />}
									style={{
										pointerEvents: pseudonymData.outlets[index].outlet
											? 'all'
											: 'none',
									}}
								/>
							</div>
							<div className={s.outletId}>
								<FormInput
									type="muiInput"
									name={'id_outlet_user'}
									onChange={changeFieldOutlet(index)}
									onFocus={handleFocus}
									onBlur={handleBlur}
									required={pseudonymData.outlets[index].outlet}
									errors={errors}
									data={pseudonymData.outlets[index]}
									pseudonumOutletIndex={index}
									label={'ID'}
									style={{
										pointerEvents: pseudonymData.outlets[index].outlet
											? 'all'
											: 'none',
									}}
								/>
								<InfoButton
									className={getClassnameForInfoBtn('id_outlet_user')}
									style={{
										marginLeft:
											whereHelpBtnUP === 'id_outlet_user' ||
											(pseudonymData.outlets[index].outlet &&
												pseudonymData.outlets[index].id_outlet_user)
												? '-45px'
												: pseudonymData.outlets[index].outlet
												? '-61px'
												: '-70px',
									}}
									onClick={showModal({
										text: parse(message),
									})}
								/>
								{Object.keys(errors).includes('id_outlet_user') &&
									pseudonymData.outlets[index].outlet && (
										<span className={s.Helper}>
											{!item.id_outlet_user && (
												<FormattedMessage
													id={`rod.error.${errors.id_outlet_user[0].rule}`}
												/>
											)}
										</span>
									)}
							</div>

							<img
								src={cross}
								alt="remove"
								className={s.deleteBtns}
								onClick={() => handleRemoveOutlet(index)}
							/>
						</div>
					))}

					<div className={s.addOutlet} onClick={handleAddOutlet}>
						<img
							src={add_box_line}
							alt="add outlet"
							className={s.outletsBtns}
						/>
						<div className={s.addOutletTitle}>
							<FormattedMessage id={'rod.field.add_outlet'} />
						</div>
					</div>
				</div>
				<Button
					text={<FormattedMessage id={'rod.action.save'} />}
					variant={'primary'}
					onClick={saveHandler}
					disabled={
						pseudonymData.title && pseudonymData.ipi_name_number ? false : true
					}
				/>
			</div>
		</div>
	);
};

export default CreatePseudonym;
