// Helpers
import { isDateInPast } from './isDateInPast';

export const checkFieldIsValid = (data, setAdditionalErrors, transferRelease) => {
	const validationErrors = {};
	validationErrors.id = data.id;

	if (!data.release_type_id) {
		validationErrors.release_type_id = [{ rule: 'required' }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.snippet_start) {
		validationErrors.snippet_start = [{ rule: 'required' }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.generate_ean && !data.ean) {
		validationErrors.ean = [{ rule: 'required_if', value: false }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.generate_ean && data.ean.length < 8) {
		validationErrors.ean = [{ rule: 'min_length', value: 8 }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.generate_ean && data.ean.length > 18) {
		validationErrors.ean = [{ rule: 'max_length', value: 18 }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!transferRelease && !data.generate_catalog_number && !data.catalog_number) {
		validationErrors.catalog_number = [{ rule: 'invalid' }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (data.sale_start_date) {
		const isPastDate = isDateInPast(data.sale_start_date);	
		if (isPastDate) {
			validationErrors.sale_start_date = [{ rule: 'wrong_format' }];
			setAdditionalErrors(validationErrors);
			return false;
		}
		return true;
	}

	return true;
};
