/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 11/03/2021, 11:56
 */

import { Loading } from 'components';
import { Button, InfoButton } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { AutosuggestInput, DateInput, Input } from 'components/Inputs';
import { Label } from 'components/Labels/Label';
import { Wizard } from 'components/Wizard';
import accountTypes from 'constants/accountTypes';
import { withAuth, withLang, withRoot, withUI } from 'hocs';
import removeImg from 'images/close_button.svg';
import plusImg from 'images/plus.svg';
import update from 'immutability-helper';
import styles from 'pages/account/Create/steps/06-Identifiers/Identifiers.module.css';
import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
import { getHelpMessage } from '../../../../../messages/helper';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';

class Identifiers extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);

		// const {
		// 	authContext: { user },
		// } = props;
		let title = '';
		switch (props.personalData.business_type) {
			case accountTypes.PERSONAL:
				title = `${props.personalData.last_name} ${
					props.personalData.first_name
				} ${
					props.personalData.patronymic ? props.personalData.patronymic : ''
				}`;
				break;
			case accountTypes.PERSONAL_ENTREPRENEUR:
			case accountTypes.COMPANY:
				title = props.personalData.title;
				break;
			default:
				title = '';
		}
		this.state = {
			initialSocietiesIDs: [],
			societies: [{}],
			errFromInput: false,
			// name: {
			// 	id: 0,
			// 	type: 'name',
			// 	title,
			// 	ipi: '',
			// 	isni: '',
			// },
			pseudonyms: [
				{
					id: null,
					category: 'pseudonym',
				},
			],
			newPseudonyms: [],
			isShowSocieties: true,
			isShowIds: true,
			loading: true,
			errors: {},
		};
		this.steps = [
			{
				id: 1,
				status: 'complete',
				title: (
					<FormattedMessage
						id={`rod.account.create.step_2_1_${props.personalData.business_type}.title`}
					/>
				),
			},
			{
				id: 2,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_2_2.title'} />,
			},
			{
				id: 3,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_2_3.title'} />,
			},
			{
				id: 4,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_2_4.title'} />,
			},
			{
				id: 5,
				status: 'default',
				title: <FormattedMessage id={'rod.account.create.step_2_5.title'} />,
			},
		];
	}

	//todo ВАЛИДАЦИЯ

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.isPseudonymsUpdateNeeded !==
				this.props.isPseudonymsUpdateNeeded &&
			this.props.isPseudonymsUpdateNeeded === true
		) {
			this.pseudonymsUpdate();
		}
		if (
			this.state.societies?.length < prevState.societies?.length ||
			(this.props.errors &&
				Object.keys(this.props.errors).length > 0 &&
				Object.keys(prevProps.errors).length !==
					Object.keys(this.props.errors).length)
		) {
			this.getInputErrors();
		}
		if (prevProps.id !== this.props.id) {
			this.update();
		}
	}

	makeDataCorrect = (societies, suggestData) => {
		let result;
		result = societies.map((element) => {
			element['mebership_since'] = element.mebership_since.substring(0, 10);
			const currentSociety = suggestData.find((suggest) => {
				return suggest.id === element.community_account_id;
			});
			element['title_ru'] = currentSociety ? currentSociety.title_ru : '';
			return element;
		});
		return result;
	};

	update() {
		const {
			rootContext: {
				getAccountPseudonyms,
				suggestSociety,
				getAccountPseudonymsData,
			},
		} = this.props;
		suggestSociety()
			.then((suggestSocietyData) => {
				getAccountPseudonyms(this.props.id)
					.then((res) => {
						res = res.data.data;
						const societiesWithCorrectedData = this.makeDataCorrect(
							res.societies,
							suggestSocietyData
						);
						this.setState(
							update(this.state, {
								initialSocietiesIDs: {
									$set: res.societies.map((element) => element.id),
								},
								societies:
									res.societies.length > 0
										? {
												$set: societiesWithCorrectedData,
										  }
										: { $set: this.state.societies },
								// name: {
								// 	$set: {
								// 		...res.name,
								// 		id: res.name.id || null,
								// 		title: this.state.name.title,
								// 	},
								// },

								// pseudonyms:
								// 	res.pseudonyms.length > 0
								// 		? { $set: res.pseudonyms }
								// 		: { $set: this.state.pseudonyms },
								// loading: { $set: false },
							})
						);
					})
					.then(() => {
						getAccountPseudonymsData(this.props.id).then((pseudonymsData) => {
							pseudonymsData = pseudonymsData.data.data;
							this.setState(
								update(this.state, {
									pseudonyms:
										pseudonymsData.length > 0
											? { $set: pseudonymsData }
											: { $set: this.state.pseudonyms },
									loading: { $set: false },
								})
							);
						});
					});
			})
			.catch((error) => {
				console.error('Identifier - getAccountPseudonyms error: ', error);
				console.error(
					'Identifier - getAccountPseudonyms error.response: ',
					error.response
				);
			});
	}

	pseudonymsUpdate() {
		const {
			rootContext: { getAccountPseudonymsData },
		} = this.props;
		let newPseudonyms = this.state.pseudonyms.filter((item) => !item.id);
		let updatedPseudonyms = [...this.state.pseudonyms];

		this.setState({ newPseudonyms });
		getAccountPseudonymsData(this.props.id).then((newPseudonymsData) => {
			newPseudonymsData = newPseudonymsData.data.data;
			updatedPseudonyms = updatedPseudonyms.map((pseudonym) => {
				if (
					pseudonym.id &&
					newPseudonymsData.find((item) => item.id === pseudonym.id)
				) {
					return pseudonym;
				}
				const equalPseudonymWithId = newPseudonymsData.find(
					(dataItem) =>
						(pseudonym.title === dataItem.title &&
							pseudonym.isni &&
							dataItem.isni &&
							pseudonym.isni === dataItem.isni &&
							pseudonym.ipi_name_number &&
							dataItem.ipi_name_number &&
							pseudonym.ipi_name_number === dataItem.ipi_name_number) ||
						(pseudonym.title === dataItem.title &&
							!pseudonym.isni &&
							!dataItem.isni &&
							!pseudonym.ipi_name_number &&
							!dataItem.ipi_name_number) ||
						(pseudonym.title === dataItem.title &&
							!pseudonym.isni &&
							!dataItem.isni &&
							pseudonym.ipi_name_number &&
							dataItem.ipi_name_number &&
							pseudonym.ipi_name_number === dataItem.ipi_name_number) ||
						(pseudonym.title === dataItem.title &&
							pseudonym.isni &&
							dataItem.isni &&
							!pseudonym.ipi_name_number &&
							!dataItem.ipi_name_number &&
							pseudonym.isni === dataItem.isni)
				);
				if (!pseudonym.id && equalPseudonymWithId) {
					pseudonym.id = equalPseudonymWithId.id;
					return pseudonym;
				}
				return pseudonym;
			});
			this.setState(
				update(this.state, {
					pseudonyms:
						updatedPseudonyms.length > 0
							? { $set: updatedPseudonyms }
							: { $set: newPseudonyms },
					loading: { $set: false },
				})
			);
		});
	}

	toggleSocieties = () => {
		this.setState({ isShowSocieties: !this.state.isShowSocieties });
	};
	toggleIds = () => {
		this.setState({ isShowIds: !this.state.isShowIds });
	};

	// updateName = (field) => (value) => {
	// 	this.setState(
	// 		update(this.state, {
	// 			name: {
	// 				[field]: {
	// 					$set: value,
	// 				},
	// 			},
	// 		})
	// 	);
	// };

	addSocietyRow = () => {
		this.setState(
			update(this.state, {
				societies: {
					$push: [
						{
							id: null,
						},
					],
				},
			})
		);
	};

	updateSocietyRow = (index, field) => (value) => {
		this.setState(
			update(this.state, {
				societies: {
					[index]: {
						[field]: {
							$set: value,
						},
					},
				},
			})
		);
	};

	updateSocietyName = (index) => (value) => {
		const mebership_since = this.state.societies[index].mebership_since
			? this.state.societies[index].mebership_since
			: null;
		if (mebership_since) {
			value['mebership_since'] = mebership_since;
		}
		this.setState(
			update(this.state, {
				societies: {
					[index]: { $set: value },
				},
			})
		);
	};

	// updateSocietyName = (index) => (value) => {
	// 	this.setState(
	// 		update(this.state, {
	// 			societies: {
	// 				[index]: { $merge: value },
	// 			},
	// 		})
	// 	);
	// };

	removeSocietyRow = (index) => () => {
		// const {
		// 	rootContext: { deleteAccountSociety },
		// } = this.props;
		// if (
		// 	this.state.initialSocietiesIDs.includes(this.state.societies[index].id)
		// ) {
		// 	deleteAccountSociety(this.props.id, this.state.societies[index].id)
		// 		.then((res) => {
		// 			// console.log('deleteAccountSociety - res: ', res.data.data)
		// 		})
		// 		.catch((error) => {
		// 			console.log('Identifier - deleteAccountSociety error: ', error);
		// 			console.log(
		// 				'Identifier - deleteAccountSociety error.response: ',
		// 				error.response
		// 			);
		// 		});
		// }
		this.setState(
			update(this.state, {
				societies: {
					$splice: [[index, 1]],
				},
			})
		);
	};

	addIdentifierRow = () => {
		this.setState(
			update(this.state, {
				pseudonyms: {
					$push: [{ id: null, category: 'pseudonym' }],
				},
			})
		);
	};

	updateIdentifierRow = (index, field) => (value) => {
		this.setState(
			update(this.state, {
				pseudonyms: {
					[index]: {
						[field]: {
							$set: value,
						},
					},
				},
			})
		);
	};
	removeIdentifierRow = (index) => () => {
		const {
			rootContext: { deleteAccountPseudonym },
		} = this.props;
		if (this.state.pseudonyms[index].id) {
			deleteAccountPseudonym(this.props.id, this.state.pseudonyms[index].id)
				.then((res) => {})
				.catch((error) => {
					console.error('Identifier - deleteAccountPseudonym error: ', error);
					console.error(
						'Identifier - deleteAccountPseudonym error.response: ',
						error.response
					);
				});
		}
		this.setState(
			update(this.state, {
				pseudonyms: {
					$splice: [[index, 1]],
				},
			})
		);
	};

	getInputErrors = () => {
		let societiesInputErrors = {};
		let pseudonymsInputErrors = {};

		let societiesLength = this.state.societies.length;
		if (this.state.isShowSocieties) {
			for (let i = 0; i < societiesLength; i++) {
				if (
					!this.state.societies[i].id ||
					!this.state.societies[i].mebership_since
				) {
					if (!this.state.societies[i].id) {
						societiesInputErrors[`societies.${i}.id`] = [{ rule: 'required' }];
					}
					if (!this.state.societies[i].mebership_since) {
						societiesInputErrors[`societies.${i}.mebership_since`] = [
							{ rule: 'required' },
						];
					}
				} else {
					societiesInputErrors = {};
					break;
				}
			}
		}

		switch (this.state.isShowIds) {
			case true:
				// if (this.state.name.ipi || this.state.name.isni) {
				// 	pseudonymsInputErrors = {};
				// } else {
				// 	if (!this.state.name.ipi)
				// 		pseudonymsInputErrors[`name.ipi`] = [{ rule: 'required' }];
				// 	if (!this.state.name.isni)
				// 		pseudonymsInputErrors[`name.isni`] = [{ rule: 'required' }];

				let pseudonymsLength = this.state.pseudonyms.length;
				for (let i = 0; i < pseudonymsLength; i++) {
					if (this.state.pseudonyms[i].title) {
						pseudonymsInputErrors = {};
						break;
					} else {
						pseudonymsInputErrors[`pseudonyms.${i}.title`] = [
							{ rule: 'required' },
						];
					}
					if (
						this.state.pseudonyms[i].ipi_name_number &&
						this.state.pseudonyms[i].ipi_name_number.length !== 11
					) {
						pseudonymsInputErrors[`pseudonyms.${i}.ipi_name_number`] = [
							{ rule: 'required' },
						];
					}
					if (
						this.state.pseudonyms[i].isni &&
						this.state.pseudonyms[i].isni.length !== 19
					) {
						pseudonymsInputErrors[`pseudonyms.${i}.isni`] = [
							{ rule: 'required' },
						];
					}
				}
				// }
				break;
			case false:
				pseudonymsInputErrors = {};
				break;
			default:
				break;
		}

		const inputErrors = { ...societiesInputErrors, ...pseudonymsInputErrors };
		this.setState({ errors: inputErrors });
		return inputErrors;
	};

	getValidRecordsFromSocieties = () => {
		let result = this.state.isShowSocieties
			? this.state.societies.map((society) => {
					if (society.id && society.mebership_since) {
						const { title_ru, title_en, ...rest } = society;
						return rest;
					} else return {};
			  })
			: [];
		if (result.length) {
			result = result.filter((society) => Object.keys(society).length !== 0);
		}
		return result;
	};

	getValidRecordsFromPseudonyms = () => {
		let result = this.state.isShowIds
			? this.state.pseudonyms.map((pseudonym) => {
					// if (
					// 	pseudonym.title &&
					// 	(pseudonym.ipi_name_number || pseudonym.isni)
					// )
					if (pseudonym.title) {
						// const { id, ...rest } = pseudonym;
						// return rest;
						return pseudonym;
					} else return {};
			  })
			: [];
		if (result.length) {
			result = result.filter(
				(pseudonym) => Object.keys(pseudonym).length !== 0
			);
		}
		// if(this.state.name.ipi || this.state.name.isni) {result.unshift(this.state.name);}
		return result;
	};

	// getPseudonymIfNotShow = () => {
	// 	this.state.pseudonyms[0].title =
	// 		this.props.personalData.business_type === 'legal_entity'
	// 			? `${this.props.personalData.title}`
	// 			: `${this.props.personalData.first_name}` +
	// 			  ' ' +
	// 			  `${this.props.personalData.last_name}`;
	// 	return this.state.pseudonyms;
	// };

	nextHandler = () => {
		let inputErrors = {};
		inputErrors =
			this.state.isShowSocieties && this.state.isShowIds
				? this.getInputErrors()
				: {};

		if (Object.keys(inputErrors).length) return false;

		if (typeof this.props.onChange === 'function') {
			this.props.onChange({
				societies: this.getValidRecordsFromSocieties(),
				// name: this.state.isShowIds ? this.state.name : false,
				pseudonyms: this.state.isShowIds
					? this.getValidRecordsFromPseudonyms()
					: [], // : this.getPseudonymIfNotShow(),
			});
		}

		//====================================

		// if (typeof this.props.onChange === 'function') {

		// 	this.props.onChange({
		// 		societies: this.state.isShowSocieties
		// 			? this.state.societies.map((s) => {
		// 					const { title_ru, title_en, ...rest } = s;
		// 					return rest;
		// 			  })
		// 			: [],
		// 		name: this.state.isShowIds ? this.state.name : false,
		// 		pseudonyms: this.state.isShowIds ? this.state.pseudonyms : [],
		// 	});
		// }
	};

	renderSocieties() {
		const {
			UIContext: { showModal },
			// errors,
		} = this.props;

		const { isShowSocieties, societies } = this.state;
		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'identifiers_societies')
		);

		return (
			<Row>
				<Col sm={10} offset={{ sm: 1 }}>
					<div className={styles.HeaderLine}>
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.societies.header'}
									/>
								}
								className={styles.Header}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
						<Checkbox
							text={<FormattedMessage id={'rod.field.skip_step'} />}
							className={styles.Checkbox}
							checked={!isShowSocieties}
							onChange={this.toggleSocieties}
						/>
					</div>
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.societies.sub_header'}
							/>
						}
						font="--gilroy-Medium-24"
						black
					/>
					{isShowSocieties && societies && (
						<div>
							{societies.map((society, index) => {
								return (
									<div key={index} className={styles.FormLine}>
										<FormattedMessage id={'rod.field.society'}>
											{(placeholder) => (
												<AutosuggestInput
													className={styles.Input}
													placeholder={placeholder}
													required
													value={{
														...society,
														title: society.title_ru,
													}}
													errors={this.state.errors[`societies.${index}.id`]}
													// showError={'society'}
													onChange={this.updateSocietyName(index)}
												/>
											)}
										</FormattedMessage>

										<FormattedMessage id={'rod.field.from_date'}>
											{(placeholder) => (
												<DateInput
													placeholder={placeholder}
													value={society.mebership_since}
													// errors={errors[`societies.${index}.mebership_since`]}
													errors={
														this.state.errors[
															`societies.${index}.mebership_since`
														]
													}
													onChange={this.updateSocietyRow(
														index,
														'mebership_since'
													)}
												/>
											)}
										</FormattedMessage>
										{index === 0 && (
											<Button icon onClick={this.addSocietyRow}>
												<img src={plusImg} alt="add" />
											</Button>
										)}
										{index > 0 && (
											<Button icon onClick={this.removeSocietyRow(index)}>
												<img src={removeImg} alt="remove" />
											</Button>
										)}
									</div>
								);
							})}
						</div>
					)}
				</Col>
			</Row>
		);
	}

	renderIds() {
		const {
			UIContext: { showModal },
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'identifiers_names')
		);

		const { isShowIds, pseudonyms } = this.state;

		return (
			<Row className={styles.Section}>
				<Col md={10} offset={{ sm: 1 }}>
					<div className={styles.HeaderLine}>
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.identifiers.header'}
									/>
								}
								className={styles.Header}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
						<Checkbox
							text={<FormattedMessage id={'rod.field.skip_step'} />}
							className={styles.Checkbox}
							checked={!isShowIds}
							onChange={this.toggleIds}
						/>
					</div>

					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.identifiers.sub_header'}
							/>
						}
						font="--gilroy-Medium-24"
						black
					/>
					{isShowIds && (
						<div>
							{/* <div>
								<div className={styles.SubHeader}>
									<Label
										text={
											<FormattedMessage
												id={'rod.account.create.identifiers.name.header'}
											/>
										}
										font="--gilroy-Medium-24"
										black
									/>
								</div>

								<div className={styles.FormLine}>
									<FormattedMessage id={'rod.field.name'}>
										{(placeholder) => (
											<Input
												placeholder={placeholder}
												disabled
												value={this.state.name.title}
												errors={this.state.errors[`name.id`]}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.ipi'}>
										{(placeholder) => (
											<Input
												placeholder={placeholder}
												value={name.ipi}
												required
												errors={this.state.errors[`name.ipi`]}
												onChange={this.updateName('ipi')}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.isni'}>
										{(placeholder) => (
											<Input
												placeholder={placeholder}
												value={name.isni}
												required
												errors={this.state.errors[`name.isni`]}
												onChange={this.updateName('isni')}
											/>
										)}
									</FormattedMessage>
								</div>
							</div> */}

							<div className={styles.SubHeader}>
								<Label
									text={
										<FormattedMessage
											id={'rod.account.create.identifiers.pseudonyms.header'}
										/>
									}
									font="--gilroy-Medium-24"
									black
								/>
							</div>
							{pseudonyms.map((pseudonym, index) => (
								<div key={index} className={styles.FormLine}>
									<FormattedMessage id={'rod.field.pseudonym'}>
										{(placeholder) => (
											<Input
												placeholder={placeholder}
												value={pseudonym.title}
												required
												errors={this.props.errors[`pseudonyms${index}`]?.title}
												onChange={this.updateIdentifierRow(index, 'title')}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.ipi'}>
										{(placeholder) => (
											<Input
												placeholder={placeholder}
												value={pseudonym.ipi_name_number}
												errors={
													this.props.errors[`pseudonyms${index}`]
														?.ipi_name_number
												}
												onChange={this.updateIdentifierRow(
													index,
													'ipi_name_number'
												)}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.isni'}>
										{(placeholder) => (
											<Input
												placeholder={placeholder}
												value={pseudonym.isni}
												errors={this.props.errors[`pseudonyms${index}`]?.isni}
												onChange={this.updateIdentifierRow(index, 'isni')}
											/>
										)}
									</FormattedMessage>
									{index === 0 && (
										<Button icon onClick={this.addIdentifierRow}>
											<img src={plusImg} alt="add" />
										</Button>
									)}
									{index > 0 && (
										<Button icon onClick={this.removeIdentifierRow(index)}>
											<img src={removeImg} alt="remove" />
										</Button>
									)}
								</div>
							))}
						</div>
					)}
				</Col>
			</Row>
		);
	}

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<div className={styles.Page}>
				<Wizard
					steps={this.steps}
					className={styles.Wizard}
					handleOpenPrevTabs={this.props.handleOpenPrevTabs}
				/>
				<div className={styles.Container}>
					{this.renderSocieties()}
					{this.renderIds()}
					{this.renderControls()}
				</div>
			</div>
		);
	}

	renderControls() {
		return (
			<Row>
				<Col md={5} offset={{ sm: 1 }}>
					<div className={styles.Controls}>
						<Button
							text={<FormattedMessage id={'rod.action.next'} />}
							variant={'primary'}
							onClick={this.nextHandler}
						/>
					</div>
				</Col>
			</Row>
		);
	}
}

export default compose(withRoot, withAuth, withLang, withUI)(Identifiers);
