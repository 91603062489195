/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */
// eslint-disable-next-line
import {
	platform,
	compound,
	partner_compound,
	platform_compound,
} from 'messages/RU/pages_branding';

const pages = {
	/* Nav */
	'rod.nav.link.features': 'возможности',
	'rod.nav.link.terms': 'условия использования',
	'rod.nav.link.about': 'о сервисе',
	'rod.nav.link.faq': 'вопросы',
	'rod.nav.link.articles': 'статьи',
	'rod.nav.link.h11': `${platform_compound.title_powered}`,
	'rod.nav.link.support': 'тех. поддержка',

	'rod.header.link.register': 'регистрация',
	'rod.header.link.login': 'вход',
	'rod.header.menu': 'меню',
	'rod.header.admin': 'администрирование',
	'rod.header.account_search': 'поиск по аккаунтам',
	'rod.header.account_not_found': 'аккаунт не найден',

	/* Wizard steps*/
	'rod.account.create.step_1.title': 'Создание учетной записи',
	'rod.account.create.step_2.title': 'Создание аккаунта',
	'rod.account.create.step_3.title': 'Присоединение к услуге',

	'rod.account.create.step_2_1_individual.title': 'личные данные',
	'rod.account.create.step_2_1_sole_proprietor.title': 'данные ИП',
	'rod.account.create.step_2_1_legal_entity.title': 'данные компании',
	'rod.account.create.step_2_2.title': 'контактные данные',
	'rod.account.create.step_2_3.title': 'документы',
	'rod.account.create.step_2_4.title': 'идентификаторы',
	'rod.account.create.step_2_5.title': 'банковские данные',

	'rod.account.create.step_3_1.title': 'параметры договоров',
	'rod.account.create.step_3_2.title': 'подписание документов',
	'rod.account.create.step_3_3.title': 'выбор роли',
	'rod.account.create.license-agreement': 'Лицензионный договор',
	'rod.account.create.single-song-agreement':
		'Single-song издательский договор',
	'rod.account.create.publishing-agreement': 'Издательский договор',
	'rod.account.create.sub-publishing-agreement': 'Субиздательский договор',
	'rod.account.create.license-agreement.whole-world': 'Весь Мир',

	/* Landing page */
	'rod.landing.header.text': `присоединяйтесь${partner_compound.title_to} и получите:`,
	'rod.landing.header.text.mts': `присоединяйтесь к мтс лейблу и получите:`,
	'rod.landing.header.subtext_1':
		'Максимальное вознаграждение за использование вашего репертуара;',
	'rod.landing.header.subtext_2': 'Гибкие условия лицензирования;',
	'rod.landing.header.subtext_3':
		'Детальные отчеты об использовании репертуара.',
	'rod.landing.form.have_an_account': `Уже есть аккаунт${compound.title}?`,
	'rod.landing.form.have_not': 'нет аккаунта?',
	'rod.landing.form.action.login': 'Войти',
	'rod.landing.form.action.register': 'Зарегистрироваться',
	'rod.landing.footer.text':
		'У вас большой каталог или фан-база? <a>Отправьте заявку</a> — и мы подготовим для вас персональное предложение.',
	'rod.landing.footer.link.send_request': 'Отправьте заявку',
	'rod.landing.footer.link.leave_request': 'оставьте заявку',
	'rod.landing.footer.text_title': 'у вас большой каталог или фан-база?',
	'rod.landing.footer.text_body': 'оставьте заявку на персональное предложение',

	/* Login */
	'rod.login.header': 'вход',
	'rod.login.register.header': 'регистрация',
	'rod.login.text.have_not_account_yet': 'Создайте аккаунт',
	'rod.login.text.forgot_password': 'Забыли пароль?',

	/* Registration */
	'rod.register.text.already_have_account_title': 'вход',
	'rod.register.text.already_have_account': `если есть аккаунт${platform_compound.title_in}`,
	'rod.register.header': 'регистрация',
	'rod.register.subheader': 'создайте учетную запись пользователя',
	'rod.register.terms': 'я согласен на <a>обработку персональных данных</a>',
	'rod.register.terms_no_link': 'я согласен на обработку персональных данных',
	'rod.register.terms.anchor': 'обработку персональных данных',
	'rod.registration.success.header': 'спасибо!',
	'rod.registration.success.subheader':
		'В ближайшее время мы свяжемся с вами и поможем в регистрации.',
	'rod.registration.special.header': 'персональное подключение к сервису',
	'rod.registration.special.subheader':
		'У вас 1 000 и более произведений? Больше 100 000 подписчиков в соцсетях? Отправьте заявку и мы подготовим специальное предложение для вас: настроим аккаунт и добавим весь предоставленный вами репертуар.',
	'rod.registration.special.success.header': 'спасибо за ваше обращение!',
	'rod.registration.special.success.body':
		'Мы свяжемся с вами в ближайшее время, для обсуждения деталей персонального предложения',

	/* Auth pages (SMS/Email-verification, Restore password, etc)*/
	'rod.change_password.header': 'изменение пароля',
	'rod.restore_password.header': 'восстановление пароля',
	'rod.restore_password.subtitle':
		'Если регистрация была произведена до 05.05.2022, для восстановления пароля обратитесь в поддержку, используя виджет справа внизу страницы',
	'rod.restore_password.email.header': 'проверьте свою почту!',
	'rod.restore_password.email.subheader':
		'В ближайшее время мы вам отправим Email со ссылкой для подтверждения пароля.',
	'rod.no_code.header': 'не получили код?',
	'rod.no_code.header.email': 'не получили email?',
	'rod.no_code.subheader':
		'Если вы не получили SMS или email код, ' +
		'или возникли сложности при регистрации аккаунта, пожалуйста, ' +
		'воспользуйтесь онлайн чатом в виджете внизу страницы.<br/><br/>' +
		'Сообщите почту и номер телефона, которые вы указывали при регистрации, и опишите проблему.<br/><br/>' +
		'Мы будем рады помочь!',
	'rod.no_code.text.i_want_you_contact_me': 'Я хочу, чтобы со мной связались',
	'rod.special.header':
		'специальные условия и помощь в настройке и заполнении данных для крупных аккаунтов',
	'rod.special.text.i_am_ready_to_provide':
		'Я готов предоставить больше ### ### произведений для лицензирования или объемом роялти выше $### ###',
	'rod.verify_sms.header': 'введите код из смс',
	'rod.verify_sms.subheader': 'мы отправили код на номер',
	'rod.verify_sms.no_code': 'Не приходит код',
	'rod.verify_sms.send_again': 'отправить код повторно',
	'rod.verify_email.header': 'спасибо за подтверждение вашего номера телефона!',
	'rod.verify_email.action': 'Введите код вручную',
	'rod.verify_email.subheader':
		'Вам отправлен e-mail с кодом подтверждения и ссылкой. Введите полученный код или перейдите по ссылке из письма, чтобы создать учетную запись и продолжить процесс регистрации.',
	'rod.verify_email.no_code': 'Не приходит email',
	'rod.verify_email.send_again': 'отправить код повторно',
	'rod.text.special.success':
		'Спасибо, ваше обращение получено! В ближайшее время мы свяжемся с вами для разработки персонального предложения',
	'rod.text.help.success':
		'Спасибо за обращение!\n Мы с Вами свяжемся и поможем в регистрации',
	'rod.text.register.success':
		'Успех! Мы отправили тебе e-mail. Перейди по ссылке в нем для доступа в личный кабинет.',
	'rod.text.change_password.success': 'пароль успешно изменен',
	'rod.text.forgot.success': 'пароль изменен',
	// 'rod.text.forgot.fail': 'время действия ссылки истекло',
	'rod.text.forgot.fail':
		'Код неверный или уже был использован. Пожалуйста, попробуйте снова или обратитесь в поддержку.',
	'rod.captcha.description':
		'Этот сервис защищен reCAPTCHA, к нему применяются',
	'rod.captcha.privacy': 'Политика конфиденциальности',
	'rod.captcha.and': 'и',
	'rod.captcha.terms': 'Условия обслуживания Google',

	/* Accounts */
	'rod.account.header': 'выберите аккаунт для дальнейшей работы',
	'rod.account.header.have_not_account_yet':
		'создайте аккаунт для начала работы',
	'rod.account.sub_header':
		'Вы можете переключаться между аккаунтами в профиле',
	'rod.account.sub_header.have_not_account_yet':
		'Вы можете создать больше, чем один аккаунт',
	'rod.account.create.who_are_you.header':
		'пожалуйста, расскажите подробнее о вас.',
	'rod.account.create.who_are_you.title': 'чей репертуар вы представляете?',
	'rod.account.create.who_are_you.self': 'Я представляю свой репертуар',
	'rod.account.create.who_are_you.shared': 'Я представляю репертуар других лиц',
	'rod.account.create.who_are_you.all':
		'Я представляю и свой репертуар, и репертуар других лиц',

	'rod.ref.roles.words_author.title':
		'Я — автор слов (лирики) и хочу получать вознаграждения за произведения, где они используются',
	'rod.ref.roles.words_author.summary': 'Автор',
	'rod.ref.roles.music_author.title':
		'Я — композитор и хочу получать вознаграждения за произведения, где используются моя музыка',
	'rod.ref.roles.music_author.summary': 'Композитор',
	'rod.ref.roles.vocal_performer.title':
		'Я — исполнитель: при моем участии была записана фонограмма(ы), и я хочу получать вознаграждения за ее(их) использование',
	'rod.ref.roles.vocal_performer.summary': 'Исполнитель',
	'rod.ref.roles.producer.title':
		'Я — продюсер фонограммы: я организовал запись фонограмм(ы) (как частное лицо) и хочу получать вознаграждения за использование этой фонограмм(ы)',
	'rod.ref.roles.producer.summary': 'Продюсер',
	'rod.ref.roles.publisher.title':
		'Я – музыкальный издатель: я представляю интересы других лиц (авторов слов / композиторов / других издателей)',
	'rod.ref.roles.publisher.summary': 'Издатель',
	'rod.ref.roles.sub_publisher.summary': 'субиздатель',
	'rod.ref.roles.record_company.title':
		'Я – музыкальный лейбл: я представляю интересы других лиц (исполнителей /музыкантов / продюсеров / других лейблов)',
	'rod.ref.roles.record_company.summary': 'Музыкальный лейбл',
	'rod.ref.roles.instrumental_performer.title':
		' instrumental performer description',
	'rod.ref.roles.instrumental_performer.summary': 'instrumental performer',

	'rod.account.create.sign_as.title': 'я подписываю договор как:',
	'rod.account.create.sign_as.individual': 'Физическое лицо',
	'rod.account.create.sign_as.sole_proprietor':
		'Индивидуальный предприниматель',
	'rod.account.create.sign_as.legal_entity': 'Компания',

	'rod.account.create.confirm_role.form.header':
		'выберите пункты, которые относятся к вам',
	'rod.account.create.confirm_role.summary.header':
		'исходя из выбранных опций, вы:',

	'rod.account.personal.title': 'персональная информация',
	'rod.account.personal_entrepreneur.title': 'информация об ИП',
	'rod.account.company.title': 'информация о компании',

	'rod.account.create.contacts.title': 'контактные данные',

	'rod.account.create.documents.passport.title': 'паспорт',
	'rod.account.create.documents.passport.photo_page': 'страница с фотографией',
	'rod.account.create.documents.passport.address_page':
		'страница с регистрацией',

	'rod.account.create.documents.driver_license.title':
		'Водительское удостоверение:',
	'rod.account.create.documents.driver_license.front_side': 'Лицевая сторона',
	'rod.account.create.documents.driver_license.back_side': 'Тыльная сторона',

	'rod.account.create.documents.pe.title': 'документ о регистрации ИП',
	'rod.account.create.documents.pe.registration': 'свидетельство',

	'rod.account.create.documents.company.title': 'документы о компании',
	'rod.account.create.documents.company.registration':
		'свидетельство о регистрации',
	'rod.account.create.documents.company.tax':
		'сертификат о налоговом резидентстве',
	'rod.account.create.documents.list_title': 'загрузите',
	// 'rod.account.create.documents.list_title': 'выберите и загрузите',

	'rod.account.create.moderation.pending.header': 'спасибо!',
	'rod.account.create.moderation.pending':
		'Ваши данные отправлены в обработку. Это займет некоторое время. <br/> <br/>По завершении обработки, вам на почту будет отправлено письмо, для дальнейшего присоединения к услуге.',
	'rod.account.create.moderation.pending_contracts':
		'Ваши документы отправлены в обработку. Это займет некоторое время. <br/> <br/>По завершении обработки, вам на почту будет отправлено письмо, для дальнейшего присоединения к услуге.',

	'rod.account.create.payments.header':
		'реквизиты для получения денежных средств',
	'rod.account.create.societies.header': 'общества',
	'rod.account.create.societies.sub_header':
		'пожалуйста, выберите название общества, членом которого вы являетесь.',
	'rod.account.create.identifiers.header': 'имена и идентификаторы',
	'rod.account.create.identifiers.sub_header':
		'если вы не знаете что такое идентификатор и как его использовать, нажмите «?». если у вас нет идентификатора — оставьте поля пустыми и нажмите «Готово».',
	'rod.account.create.identifiers.name.header': 'Имя',
	'rod.account.create.identifiers.pseudonyms.header': 'Псевдонимы',
	'rod.account.add_role': 'Добавление прав',
	'rod.account.add_service_btn': 'добавить услугу',
	'rod.account.choose_service_btn': 'выбрать услугу',
	'rod.account.choose_few_services_btn': 'выбрать услуги',
	'rod.account.save_to_draft_btn': 'сохранить черновик',
	'rod.account.add_service_banner_title': 'подключить услугу',
	'rod.account.add_role_banner_release_not_available_title':
		'публикация релиза недоступна',
	'rod.account.add_role_banner_release_not_available_text':
		'чтобы загрузить релиз на витрины, добавьте услугу дистрибуции',
	'rod.account.add_role_banner_composition_not_available_title':
		'публикация произведения недоступна',
	'rod.account.add_role_banner_composition_not_available_text':
		'чтобы загрузить релиз на витрины, подпишите лицензионный договор',
	'rod.account.add_role_banner_release_not_available_moderation':
		'у вас есть договор на модерации. Пожалуйста, дождитесь окончания модерации и повторите попытку. Сейчас вы можете сохранить релиз в черновиках и вернуться к нему позже.',
	'rod.account.add_role_banner_composition_not_available_moderation':
		'у вас есть договор на модерации. Пожалуйста, дождитесь окончания модерации и повторите попытку. Сейчас вы можете сохранить произведение в черновиках и вернуться к нему позже.',
	'rod.account.add_role_banner_composition_has_not_contracts':
		'чтобы загрузить произведение, добавьте услуги сбора авторских прав и лирики.',

	'rod.account.add_role_not_available.btn': 'подписать договор',
	'rod.account.add_service_banner_text':
		'вы можете добавить новые услуги для вашего аккаунта и воспользоваться полным списком возможностей сервиса',

	/* Contracts */
	'rod.contracts.publishing': 'Издательский контракт',
	'rod.contracts.sub': 'Cубиздательский контракт',
	'rod.contracts.all_recipients': 'все пользователи',
	'rod.contracts.sms_sign.title': 'введите код из смс',
	'rod.contracts.sms_sign.subtitle': 'мы отправили код на номер<br/> {value}',
	'rod.contracts.sms_sign.description':
		'Вводя код, я соглашаюсь с подписанием договора (hash document: {value}). Код из смс приравнивается к подписи.',
	'rod.contracts.use_types.digital.title': 'Сбор за онлайн использование',
	'rod.contracts.use_types.public_performance.title':
		'Сбор за публичное исполнение',
	'rod.contracts.use_types.synchronization.title': 'Сбор за синхронизацию',
	'rod.contracts.use_types.lyrics.title': 'Сбор за использование лирики',
	'rod.contracts.media_types.recording.title': 'Фонограммы',
	'rod.contracts.media_types.composition.title': 'Произведения',
	'rod.contracts.add.header':
		'выберите нужные вам услуги<br/> и условия для договора',
	'rod.contracts.add.header_add_service':
		'выберите дополнительные настройки<br/> к предоставлению услуги',
	'rod.contracts.add.subheader':
		'При подключении репертуара к услуге вы сможете также исключать территории  и онлайн сервисы индивидуально.',
	'rod.contracts.add.sign_as.header': 'Я подписываю договор как',
	'rod.contracts.add.conditions.header': 'что включает договор',
	'rod.contracts.add.conditions.subheader':
		'Для заключения договора, выберите хотя бы одну из предложенных ниже услуг',
	'rod.contracts.processing': 'Обработка...',

	'rod.contracts.sign_sms.header': 'подпишите договоры онлайн',
	'rod.contracts.sign_sms.subheader':
		'данные договоры являются рамочным — вы сможете выбрать, какие произведения к ним присоединить после загрузки репертуара',
	'rod.contracts.sign.help':
		'При ручном подписании договора, пожалуйста, используйте стандартный инструмент для подписания документов в Acrobat Reader или другом редакторе PDF, а <b>не функцию добавления комментариев.</b> <br/> <br/> Также <b>подпишите каждую страницу</b> договора снизу - <br/> (Подпись: )',

	'rod.contracts.sign.header': 'подтвердите, скачайте и подпишите договоры',
	'rod.contracts.sign.subheader':
		'данные договоры являются рамочным — вы сможете выбрать, какие произведения к ним присоединить после загрузки репертуара',
	'rod.contracts.complete.header': 'готово!',
	'rod.contracts.complete.subheader': 'Документы отправлены на проверку',
	'rod.contracts.complete.subheader_sms':
		'Вы успешно подписали договоры.<br/>Добро пожаловать',
	'rod.contracts.complete_signed.subheader':
		'Ниже Вы можете скачать подписанные договора и перейти в личный кабинет, нажав кнопку.',
	'rod.contracts.no_code.header': 'спасибо!',
	'rod.contracts.no_code.body':
		'В ближайшее время мы свяжемся с вами и поможем с получением смс кода.',
	'rod.contracts.no_contract.header': 'У вас нет договора с нашей компанией.',
	'rod.contracts.no_contract.body':
		'Пожалуйста, свяжитесь с администратором для подписания договора и получения доступов.',
	'rod.contracts.contract_overdue.header': 'Ваш договор просрочен.',
	'rod.contracts.contract_overdue.body':
		'Пожалуйста, свяжитесь с администратором для подписания договора и получения доступов.',
	'rod.contracts.next.header': 'спасибо!',
	'rod.contracts.next.subheader':
		'Вы успешно подписали {values}. Для завершения подпишите оставшиеся.',
	'rod.field.contracts.duration.header': 'Выберите срок действия услуги',
	'rod.field.contracts.duration.placeholder': 'Срок договора',

	'rod.field.contracts.currency.header':
		'Выберите валюту для выплаты вознаграждения',
	'rod.field.contracts.currency.placeholder': 'Валюта договора',
	'rod.field.contracts.contract_status.title': 'статус',
	'rod.field.contracts.contract_status.value.new': 'новый',
	'rod.field.contracts.contract_status.value.signing': 'подписание',
	'rod.field.contracts.contract_status.value.signed': 'подписан',
	'rod.field.contracts.contract_status.value.moderation': 'на модерации',
	'rod.field.contracts.contract_status.value.in_processing': 'в обработке',
	'rod.field.contracts.contract_status.date': 'дата подписания',
	'rod.field.contracts.sign_type.header': 'Выберите тип подписания',
	'rod.field.contracts.sign_type.placeholder': 'Тип подписания',
	'rod.field.contracts.contract_download': 'Скачать документ',
	'rod.field.contracts.contract_download_signed':
		'Скачать подписанный документ',
	'rod.account.create.complete.text':
		'спасибо! <br/><br/> ваши данные отправлены в обработку. Это займет некоторое время. сейчас вы можете закрыть страницу. При входе в аккаунт, вы вернетесь на эту страницу. <br/><br/>по завершении обработки ваших данных, на почту, указанную при регистрации, будет отправлено письмо, чтобы вы могли присоединиться к услуге.',

	'rod.contracts.upload.title': 'загрузка контракта',
	'rod.contracts.upload.text':
		'<u>выберите</u> или перетяните файлы<br /> подписанного контракта',
	'rod.contracts.territory_select.header':
		'укажите страны/территории,<br /> которые вы хотите исключить из услуги',

	'rod.repertoire.welcome.title': 'добро пожаловать',
	'rod.repertoire.welcome.title.widget_error': 'Уважаемые пользователи!',
	'rod.repertoire.legacy.welcome.title': 'добро пожаловать',
	'rod.repertoire.welcome.content': `
		Для выполнения услуги нам необходимо получить информацию о вашем репертуаре.
		<br/><br/>
		Для передачи информации о 10 и более релизах, фонограммах или произведениях, рекомендуем вам скачать, заполнить и загрузить «<b>форму передачи репертуара</b>», предварительно скачав ее, а также образец заполнения и инструкцию, по ссылкам справа. Вы также можете загружать CWR файлы (поддерживаемые версии: V21, V22).
		<br/><br/>
		Внимательно прочитайте  инструкции, заполните данные о репертуаре и загрузите форму здесь.
		<br/><br/>
		Используйте виджет справа внизу для поиска ответов на ваши вопросы в разделе FAQ, базе знаний, или для коммуникаций с нами.
	`,
	'rod.repertoire.welcome.content.part': `
	Для выполнения услуги нам необходимо получить информацию о вашем репертуаре.
	<br/><br/>
	Внимательно прочитайте  инструкции, заполните данные о репертуаре и загрузите форму здесь. Вы также можете загружать CWR файлы (поддерживаемые версии: 2.1, 2.2).
	<br/><br/>
	Используйте виджет справа внизу для поиска ответов на ваши вопросы в разделе FAQ, базе знаний, или для коммуникаций с нами.
`,
	'rod.repertoire.legacy.welcome.content.part': `
	Мы обновили личный кабинет и продолжаем добавлять в него новые функции. Для доступа к новой версии, пожалуйста авторизуйтесь, используя Ваш адрес электронной почты и ранее высланный пароль от системы${platform.title}, или воспользуйтесь формой восстановления пароля для его обновления.
	<br/><br/>
	Параллельно, Вы можете продолжать использовать формы отправки репертуара, размещенные справа.
	<br/><br/>
	Используйте виджет справа внизу для поиска ответов на ваши вопросы в разделе FAQ, базе знаний, или для коммуникаций с нами.
`,
	'rod.repertoire.welcome.download': 'Файлы для скачивания',
	'rod.repertoire.welcome.form': 'Формы для заполнения',

	'rod.repertoire.uploaded.title': 'Спасибо.',
	'rod.repertoire.uploaded.content': `Ваш файл с репертуаром передан в обработку. Мы оповестим вас, когда обработка завершится.
	<br/><br/>
	Если у Вас есть вопросы, используйте виджет справа внизу для поиска ответов в разделе FAQ, базе знаний, или для коммуникаций с нами.`,

	'rod.field.contracts.pdf.subheader':
		'прочитайте договор полностью и подтвердите свое согласие с условиями договора, кликнув на чекбокс внизу страницы.',
	'rod.field.additional_contracts': 'Доп. договоры',
	'rod.field.payments_withdrawal': 'Счета на вывод средств',

	// for all
	'rod.for_all.title': 'название',
	'rod.for_all.author': 'автор',
	'rod.for_all.share': 'доля',
	'rod.for_all.performer': 'исполнитель',
	'rod.for_all.performers': 'исполнители',
	'rod.for_all.date': 'дата',
	'rod.for_all.type': 'тип',
	'rod.for_all.status': 'статус',
	'rod.for_all.subtitle': 'версия',
	'rod.for_all.close': 'закрыть',

	'rod.repertoire.input_performers': 'исполнители',
	'rod.repertoire.input_releases': 'релизы',

	// NavBar
	'rod.navbar.main': 'главная',
	'rod.navbar.accounts': 'аккаунты',
	'rod.navbar.finances': 'финансы',
	'rod.navbar.dispute': 'диспуты',
	'rod.navbar.treaties': 'договоры',

	'rod.navbar.repertoire': 'репертуар',
	'rod.navbar.reports': 'отчеты',
	'rod.navbar.withdraw': 'вывод средств',
	'rod.navbar.statistic': 'статистика',
	'rod.navbar.statistic_reports': 'отчеты статистики',
	'rod.navbar.admin.statistics_service': 'сервис статистики',
	'rod.navbar.handling': 'управление',
	'rod.navbar.handling.drafts': 'черновики',
	'rod.navbar.handling.release': 'выпуск релиза',

	// Notification View
	'rod.notification.title': 'уведомления',
	'rod.notification.empty': 'Новых оповещений пока нет',
	'rod.notification.mark_all': 'Отметить все как прочитанные',
	'rod.notification.mark_one': 'Отметить как прочитанное',
	'rod.notification.list': 'список уведомлений',
	'rod.notification.add': 'создать уведомление',

	/* Repertoire */
	'rod.repertoire.drafts': 'черновики',
	'rod.repertoire.composition': 'произведения',
	'rod.repertoire.recordery': 'фонограммы',
	'rod.repertoire.releases': 'релизы',

	'rod.repertoire.status.disputed': 'в диспуте',
	'rod.repertoire.status.not_ready': 'не готово',
	'rod.repertoire.status.delivered': 'отгружено',
	'rod.repertoire.status.ready': 'готово',
	'rod.repertoire.status.rejected': 'отклонено',
	'rod.repertoire.status.pending': 'на модерации',
	'rod.repertoire.status.approved': 'готов к отгрузке',

	'rod.repertoire.all': 'всего',
	'rod.repertoire.declared': 'заявлены',
	'rod.repertoire.not_declared': 'не заявлены',
	'rod.repertoire.ready': 'готово',
	'rod.repertoire.disputed': 'в диспуте',
	'rod.repertoire.not_ready': 'не готово',
	'rod.repertoire.rejected': 'отклонено',
	'rod.repertoire.rejected.tab': 'отклонено',
	'rod.repertoire.pending': 'на модерации',
	'rod.repertoire.approved': 'одобрено',
	'rod.repertoire.approved.tab': 'одобрено',
	'rod.repertoire.in_dispute': 'в диспуте',
	'rod.repertoire.delivered': 'отгружено',
	'rod.repertoire.delivered.tab': 'отгружено',
	'rod.repertoire.shipped': 'отгружено',
	'rod.repertoire.takendown.tab': 'снято',
	'rod.repertoire.takendown': 'снято',
	'rod.repertoire.takedown_queue': 'на снятии',
	'rod.repertoire.shipping_queue': 'на отгрузке',
	'rod.repertoire.outlets.status.shipping_queue': 'на отгрузке',
	'rod.repertoire.outlets.status.takedown_queue': 'на снятии',
	'rod.repertoire.outlets.status.shipping': 'отгружено',
	'rod.repertoire.outlets.status.takedown': 'снято',
	'rod.repertoire.draft_processing_or_verify': 'на рассмотрении',
	'rod.repertoire.draft_processing': 'в обработке',
	'rod.repertoire.draft_verify': 'на проверке',
	'rod.repertoire.verify': 'на проверке',
	'rod.repertoire.expiring': 'заканчивающиеся',

	'rod.repertoire.search_by_author_and_name':
		'Поиск по исполнителю или названию',
	'rod.repertoire.compositions.search_by_author':
		'Поиск по автору или названию',

	'rod.releases.title':
		'<p>важная информация о редактировании релизов.</p> <br/> <p>	Вы можете отредактировать свой релиз до 23:00 UTC, пока он находится в статусе "в обработке". После этого он перейдет на модерацию и станет не редактируемым до публикации.</p>',

	'rod.repertoire.clear_all': 'сбросить все',
	'rod.drafts.title':
		'<p>для продолжения работы над черновиком нажмите на элемент списка.</p> <br/> <p>После прохождения модерации релиз появится в разделе репертуар. Обычно модерация занимает до 48 часов, пожалуйста, ожидайте.</p>',

	/* Statistic */
	'rod.statistic.reports_title': 'название',
	'rod.statistic.reports_period': 'период',
	'rod.statistic.reports_date': 'дата предоставления',
	'rod.statistic.reports_action': 'действие',
	'rod.statistic.by_date': 'по датам',
	'rod.statistic.by_territory': 'по территориям',
	'rod.statistic.compare': 'сравнение треков',
	'rod.statistic.outlets': 'витрины',
	'rod.statistic.tracks': 'треки',
	'rod.statistic.start-date': 'начальная дата',
	'rod.statistic.end-date': 'конечная дата',
	'rod.statistic.top_tracks': 'топ треки',
	'rod.statistic.chart-title': 'прослушиваний',
	'rod.statistic.chart-amount-units': 'единиц',
	'rod.statistic.chart-amount-thousands': 'тысяч',
	'rod.statistic.chart-amount-millions': 'миллионов',
	'rod.statistic.chart-accumulative': 'накопительная',
	'rod.statistic.chart-comparative': 'сравнительная',
	'rod.statistic.chart-total': 'Всего',
	'rod.statistic.value': 'Значение',
	'rod.statistic.no-data': 'нет данных',
	'rod.statistic.tracks-selected': 'Выбрано',
	'rod.statistic.tracks-selected-tracks': 'треков',

	'rod.statistic.top.track': 'трек',
	'rod.statistic.top.performer': 'исполнитель',
	'rod.statistic.top.album': 'альбом',
	'rod.statistic.top.streams': 'прослушиваний',
	'rod.statistic.search': 'Поиск',
	'rod.statistic.download': 'скачать',

	'rod.statistic.search-title': 'строка поиска',
	'rod.statistic.search-all': 'Всё',
	'rod.statistic.search-not-found': 'Не найдено',
	'rod.statistic.checked-tracks': 'Выбранные треки',
	'rod.statistic.help-title': 'Помощь',
	'rod.statistic.help':
		'<p>Обратите внимание, что дневная статистика прослушиваний является ориентировочными данными и может не совпадать в точности с отчетными данными витрин за соответствующий период.</p><br><p>В данный момент дневная статистика доступна для Apple Music и Spotify. Мы планируем добавлять новые витрины в будущем. Следите за обновлениями!</p>',
	'rod.statistic.notification':
		'<p>Сервис статистики работает в тестовом режиме и отражает дневную статистику, передаваемую витринами. Эти данные могут не совпадать с итоговой отчетностью за период. </p><br><p>Если релиз не виден в статистике или цифры радикально отличаются от ваших данных, пожалуйста, передайте эту информацию в поддержку.</p>',
	'rod.statistic.preparing': 'Подготовка...',
	'rod.statistic.top-ten': 'Топ прослушиваний',

	'rod.drafts.delete': 'удалить',
	'rod.drafts.delete-confirm': 'подтвердите удаление',
	'rod.drafts.delete-help':
		'после удаления, данные и файлы черновика нельзя будет восстановить',
	'rod.drafts.delete-cancel': 'отменить',

	// произведение
	'rod.composition.tab.main': 'основное',
	'rod.composition.tab.contacts': 'контракты',
	'rod.composition.tab.recordery': 'фонограммы',
	'rod.composition.tab.releases': 'релизы',
	'rod.composition.tab.not_ready': 'не готово',

	'rod.composition.empty': 'Просим <a>загрузить</a> произведения',
	'rod.composition.info.title': 'информация',
	'rod.composition.info.creation_date': 'дата создания',
	'rod.composition.info.sales_start_date': 'дата начала продаж',
	'rod.composition.info.catalog_number': 'каталожный номер',
	'rod.composition.info.lyrics_language': 'языки лирики',
	'rod.composition.info.genres': 'жанры',
	'rod.composition.info.composition_type': 'тип произведения',
	'rod.composition.info.creation_territory': 'создано на территории',
	'rod.composition.info.rightholder': 'правообладатель',
	'rod.composition.info.role': 'роль',
	'rod.composition.info.territory': 'территория',
	'rod.composition.info.exploitation_type': 'тип экслуатации',
	'rod.composition.info.use_type': 'тип использования',
	'rod.composition.info.product': 'продукт',
	'rod.composition.info.commercial_model': 'коммерческая модель',
	'rod.composition.info.Show_all': 'Показать все',
	'rod.composition.info.original_rightholders': 'оригинальные правообладатели',
	'rod.composition.info.licensees': 'лицензиаты',
	'rod.composition.info.distribution_channel': 'канал дистрибуции',
	'rod.composition.info.marketing_channel': 'канал продвижения',
	'rod.composition.info.interface_type': 'тип интерфейса',
	'rod.composition.info.societies': 'общества',
	'rod.composition.info.start_date': 'дата начала',
	'rod.composition.info.report_date': 'дата отчета',
	'rod.composition.info.Collapse': 'Свернуть',

	'rod.composition.contract.empty': 'Просим <a>выбрать</a> контракт',
	'rod.composition.contract.modal.title': 'контракты',
	'rod.composition.contract.modal.create_new': 'создать новый контракт',
	'rod.composition.contract.modal.selected': 'выбрано',
	'rod.composition.contract.modal.Continue': 'Продолжить',
	'rod.composition.contract.modal.Clear': 'сбросить',
	'rod.composition.contract.condition': 'условие',
	'rod.composition.contract.period': 'срок',
	'rod.composition.contract.collection': 'сбор',

	'rod.composition.recordery.empty':
		'Просим <a>загрузить</a> или <cta>добавить<ctaa> фонограмму',
	'rod.composition.recordery.select_from_list': 'выберите из списка',
	'rod.composition.recordery.Add': 'Добавить',
	'rod.composition.recordery.category': 'категория',
	'rod.composition.recordery.date': 'дата',
	'rod.composition.recordery.Audio': 'Аудио',
	'rod.composition.recordery.Cover': 'Обложка',
	'rod.composition.recordery.Genre': 'Жанр',
	'rod.composition.dispute.error_body':
		'данное произведение находится в диспуте, где две или более сторон заявили права, которые в сумме превышают 100%. Отображение долей будет доступно только после разрешения диспута между сторонами.',

	'rod.composition': 'произведение',
	'rod.composition.publication_is_not_available.title':
		'недоступна публикация произведения',
	'rod.composition.publication_is_not_available.text':
		'чтобы загрузить произведение, сначала подпишите авторский и субиздательский договоры.',
	'rod.composition.sign_agreement': 'подписать договор',
	'rod.composition.filter.active': 'активные',
	'rod.composition.filter.expired': 'закончившиеся',

	// ФОНОГРАММЫ //
	'rod.recordery.tab.main': 'основное',
	'rod.recordery.tab.contacts': 'контракты',
	'rod.recordery.tab.audio': 'аудио',
	'rod.recordery.tab.composition': 'произведения',
	'rod.recordery.tab.releases': 'релизы',
	'rod.recordery.tab.notices': 'Notices',

	'rod.recordery.empty': 'Просим <a>загрузить</a> фонограммы',
	'rod.recordery.info.recording_type': 'тип фонограммы',
	'rod.recordery.info.recorded_in_country': 'записана на территории',
	'rod.recordery.info.parents_notice': 'вниманию родителей',
	'rod.recordery.info.exceptions': 'исключения',
	'rod.recordery.info.format': 'формат',
	'rod.recordery.info.length': 'время',
	'rod.recordery.info.size': 'размер',
	'rod.recordery.info.dropzone': 'Загрузите или перетяните файл фонограммы',
	'rod.recordery.info.delete': ' удалить',
	'rod.recordery.info.release': 'релиз',

	// РЕЛИЗЫ //
	'rod.release.tab.main': 'основное',
	'rod.release.tab.contacts': 'контракты',
	'rod.release.tab.moderation': 'модерация',
	'rod.release.info.deliveries': 'отгрузки',
	'rod.release.info.sale_start_dates': 'даты начала продаж',
	'rod.release.tab.not_ready': 'не готово',

	'rod.release.moderation.approved': 'релиз успешно прошел модерацию',
	'rod.release.moderation.pending':
		'в данный момент релиз находится на модерации',
	'rod.release.moderation.declined': 'релиз отклонён',
	'rod.release.moderation.declined.help': 'Скоро здесь появится подсказка',

	'rod.release.moderation.issues': 'ошибки',
	'rod.release.moderation.cover': 'обложка',
	'rod.release.moderation.release_metadata': 'метаданные релиза',
	'rod.release.moderation.track_metadata': 'метаданные трека',
	'rod.release.moderation.audio': 'аудио',
	'rod.release.moderation.track': 'трек',

	'rod.release.empty': 'Просим <a>загрузить</a> релизы',
	'rod.release.info.sort': 'сортировать',
	'rod.release.info.ready_for_delivery': 'Готов к отгрузке',
	'rod.release.info.delivery_in_progress': 'отгружается',
	'rod.release.info.delivered': 'отгружено',
	'rod.release.info.disputed': 'диспут',
	'rod.release.info.Apply': 'применить',

	'rod.release.info.label': 'лейбл',
	'rod.release.info.created_in_country': 'создан на территории',
	'rod.release.info.tracks': 'треки',
	'rod.release.info.Upload_cover_image':
		'<span>Загрузите</span> <span>изображение обложки<span>',
	'rod.release.info.no-cover': 'Обложка не загружена',
	'rod.release.info.downloading_with_bundle_only':
		'скачивание в составе релиза',
	'rod.release.info.outlet': 'площадка',
	'rod.release.info.upload_file': 'загрузить файл',
	'rod.release.info.country_region': 'страна/регион',
	'rod.release.info.sale_start_date': 'дата начала продаж',
	'rod.release.release_shipment': 'отгрузить релиз',
	'rod.release.release_shipment_btn': 'отгрузить релиз',
	'rod.release.release_shipment_inProcess': 'отгружается',
	'rod.release.release_fallback': 'снять релиз',
	'rod.release.release_fallback_enable': 'снять релиз',
	'rod.release.release_fallback_inProcess': 'снимается',

	// release create
	'rod.release.create.step.release': 'релиз',
	'rod.release.create.step.files': 'файлы',
	'rod.release.create.step.tracks': 'треки',
	'rod.release.create.step.authors': 'авторы',
	'rod.release.create.step.lyrics': 'лирика',
	'rod.release.create.step.cover': 'обложка',
	'rod.release.create.step.distribution': 'дистрибуция',
	'rod.release.create.step.confirm': 'обзор',
	'rod.composition.create.step.info': 'инфо',
	'rod.composition.create.step.authors_shares': 'авторы',
	'rod.composition.create.step.composition_recordings': 'исполнения',
	'rod.release.create.step.handler_back': 'предыдущий шаг',
	'rod.release.create.step.handler_next': 'далее',

	// drafts statuses

	'rod.draft.release.moderation': 'в обработке',
	'rod.draft.release.completed': 'в обработке',
	'rod.draft.release.parsed': 'в обработке',
	'rod.draft.release.check': 'Проверка данных',
	'rod.draft.release.update': 'Проверка данных',
	'rod.draft.release.unknownstatus': 'Проверка данных',

	// release help texts
	'rod.release.create.step.release.description':
		'Не рекомендуем использовать в названии релиза эмодзи и другие специализированные символы.<br/><br/>Если у релиза несколько основных исполнителей, укажите их через запятую.<br/><br/>Приглашенных артистов укажите через "feat". <br>Если в релизе больше 4-х исполнителей, то необходимо указать "Various Artists". Это касается всех типов релиза.<br/><br/>В том случае, если дата создания неизвестна, укажите 01.01 года создания. Если релиз ранее был опубликован, обязательно укажите его EAN/UPC.',
	'rod.release.create.step.files.description':
		'Принимаемые форматы аудио файлов : FLAC 44100 Гц 24 бит или WAV 44100 Гц 16 бит',
	'rod.release.create.step.cover.description':
		'Принимаемый формат файлов обложки : JPEG, PNG. Согласно требованиям витрин, размер изображения должен быть не менее, чем 1500х1500 пикселей. Обратите внимание, что рекомендуемый размер для некоторых витрин - от 3000х3000 пикселей.',
	'rod.release.create.step.distributions.description':
		'Появление релиза на витринах может занять некоторое время. Планируйте дату релиза заранее, чтобы  публикация прошла в желаемую дату.',
	'rod.release.create.step.distributions.description.transfer':
		'Для переноса уже опубликованного релиза выберите опцию “перенос релиза”',

	// release create release
	'rod.release.create.step.release.label.name': 'Название',
	'rod.release.create.step.release.label.subtitle': 'Сабтайтл',
	'rod.release.create.step.release.label.relise_type': 'Тип релиза',
	'rod.release.create.step.release.label.genre': 'Жанры',
	'rod.release.create.step.release.label.main_executor': 'Исполнители релиза',
	'rod.release.create.step.release.label.label': 'Лейбл',
	'rod.release.create.step.release.label.create_date': 'Дата создания',
	'rod.release.label.takendown_date': 'дата снятия',
	'rod.release.create.step.release.label.create_country': 'Страна создания',
	'rod.release.create.step.release.label.identifiers': 'идентификаторы',
	'rod.release.create.step.release.label.catalog_number': 'Каталожный номер',
	'rod.release.create.step.release.label.auto': 'Присвоить автоматически ',
	'rod.release.label.takendown_reason': 'причина снятия',
	'rod.release.label.takendown_rightholder_wish': 'желание правообладателя',
	'rod.release.label.takendown_rights_violation': 'нарушение прав',
	'rod.release.label.takendown_agreement_termination': 'расторжение договора',
	'rod.release.label.takendown_indicate_reason': 'указать причину снятия',

	// release create files
	'rod.release.create.step.recording.title': 'добавьте фонограммы в релиз',
	'rod.release.create.step.recording.list_button':
		'или <a>выберите из списка</a>',
	'rod.release.create.step.recording.helper_title':
		'форматы файлов и требования к качеству',
	'rod.release.create.step.recording.helper_main':
		'Принимаемые форматы аудио файлов : FLAC 44100 Гц 24 бит или WAV 44100 Гц 16 бит',

	// release create tracks / authors
	'rod.release.create.step.tracks.title': 'информация о фонограммах',
	'rod.release.create.step.tracks.title.help': `
	<p>Каждая новая строчка текста должна начинаться с заглавной буквы. Разделяйте куплеты и припевы отступом, не указывайте части песен (куплет, припев, нумерация и аналогичные)</p>
	<p>Если в ней повторяется строчка, это должно быть отражено в тексте</p>
	<h4><b>Корректный вариант:</b></h4>
	Волны внутри меня сильные<br>
	Солнце живет во мне<br>
	Солнце живет во мне<br>
	<br>
	<h4><b>Некорректный вариант:</b></h4>
	Волны внутри меня сильные<br>
	Солнце живет во мне<br>
	(2 раза)<br>
	`,
	'rod.release.create.step.tracks.fragment.title': 'использование фрагмента',
	'rod.release.create.step.tracks.fragment.help':
		'Вы можете выбрать отрывок из своего трека для использования в качестве предварительного просмотра или мелодии звонка на некоторых площадках: ТikTok: 60 сек, Рингтон: 30 сек, Рингбэктон: 45 секунд. Выберите секунду, с которой должен начинаться фрагмент, и он будет сохранен автоматически.',
	'rod.release.on_verify.fragment.help':
		'"На рассмотрении" означает, что ваш релиз создан, но еще не прошел предварительную проверку перед модерацией.',
	'rod.compositions.on_verify.fragment.help':
		'"На рассмотрении" означает, что ваше произведение создано, но еще не прошло предварительную проверку перед модерацией.',
	'rod.release.create.step.tracks.fragment.start': 'Начало',
	'rod.release.create.step.tracks.fragment.end': 'Конец',
	'rod.release.create.step.tracks.contributors': 'артисты фонограммы',
	'rod.release.create.step.author.title': 'авторы и доли',
	'rod.release.create.step.author.title.help':
		'Скоро здесь появится подсказка ',
	'rod.release.create.step.author.share.help':
		'Скоро здесь появится подсказка ',

	'rod.release.create.step.recording_info.form.save':
		'Предзаполнить данные из предыдущей фонограммы',
	'rod.release.create.step.author_info.form.save':
		'Предзаполнить данные из предыдущего трека',
	'rod.release.create.step.recording_info.label.name': 'Название',
	'rod.release.create.step.recording_info.label.subtitle': 'Сабтайтл',
	'rod.release.create.step.recording_info.label.lang': 'Язык',
	'rod.release.create.step.recording_info.label.create_date': 'Дата создания',
	'rod.release.create.step.recording_info.label.genres': 'Жанры',
	'rod.release.create.step.recording_info.label.create_country':
		'Страна создания',
	'rod.release.create.step.recording_info.label.non_narrative':
		'Ненормативная лексика',
	'rod.release.create.step.recording_info.label.main_executor':
		'Основные исполнители',
	'rod.release.create.step.recording_info.label.label': 'Лейбл',
	'rod.release.create.step.recording_info.label.p-line/c-line': 'P-Line/С-Line',
	'rod.release.create.step.recording_info.label.author': 'Автор #{id}',
	'rod.release.create.step.recording_info.label.author_role':
		'Роль автора #{id}',
	'rod.release.create.step.recording_info.label.author_share':
		'Доля автора #{id}',
	'rod.release.create.step.recording_info.label.add_publisher':
		'Добавить издателя',
	'rod.release.create.step.recording_info.label.author_delete':
		'Удалить автора',
	'rod.release.create.step.recording_info.label.author_add': 'Добавить автора',
	'rod.release.create.step.recording_info.label.author_add_anonymous':
		'Добавить анонимную долю',
	'rod.release.create.step.recording_info.form_save': 'Сохранить',
	'rod.release.create.step.lyrics.add_lyrics': 'добавить текст песни',
	'rod.release.create.step.lyrics.add_lyrics.title': 'текст песни',

	'rod.muiAuthor.author_of_lyrics': 'Автор слов',
	'rod.muiAuthor.composer_writer': 'Автор музыки',
	'rod.muiAuthor.composer_author': 'Автор слов и музыки',
	'rod.muiAuthor.arranger': 'Арранжировщик',
	'rod.muiAuthor.adaptor': 'Адаптор',
	'rod.muiAuthor.translator': 'Переводчик',
	'rod.muiAuthor.participant': 'Бенефициар',

	'rod.release.create.sum': 'Сумма долей должна быть 100%',

	// release create cover

	'rod.release.create.step.cover.helper': 'подсказка',
	'rod.release.create.step.cover.upload':
		'<a>Загрузите</a> изображение обложки',
	'rod.release.create.step.cover.delete': 'удалить',

	// release create additional
	'rod.release.create.step.tracks.additional_release.title':
		'дополнительный релиз (TikTok, RT)',
	'rod.release.create.step.tracks.additional_release.add_button':
		'cоздать дополнительный релиз',
	'rod.release.create.step.tracks.additional_release.delete_button': 'удалить',
	'rod.release.create.step.tracks.additional_release.generate_automatically':
		'Сгенерировать автоматически',
	'rod.release.create.step.tracks.additional_release.distribution_date':
		'дата дистрибуции',
	'rod.modal.type.additional_release.title': 'удалите дополнительные релизы',
	'rod.modal.type.additional_release.text':
		'для данного типа релиза недоступно создание дополнительных релизов. Удалите дополнительные релизы, или вернитесь на шаг "релиз" и выберите другой тип («Сингл», «Альбом», «EP», «Компиляция»)“',

	// release create distribution

	'rod.release.create.step.distribution.input_date': 'Дата начала продаж',
	'rod.release.transfer.step.distribution.input_date':
		'Дата создания оригинального релиза',
	'rod.release.create.step.distribution.input_title': 'где опубликовать релиз?',
	'rod.release.create.step.distribution.input_radio3':
		'на всех доступных витринах',
	'rod.release.create.step.distribution.input_radio4':
		'только на выбранных витринах',
	'rod.release.create.step.distribution.outlets': 'Витрины',
	'rod.release.create.step.distribution.outlets_selected': 'выбрано витрин',
	'rod.release.create.step.distribution.helper': 'подсказка',
	'rod.release.create.step.distribution.upload':
		'<a>Загрузите</a> изображение обложки',
	'rod.release.create.step.distribution.delete': 'Удалить',
	'rod.release.create.step.distribution.asap-date':
		'как можно скорее (обычно 3-7 дней)',
	'rod.release.create.step.distribution.desired-date':
		'выбор желаемой даты (7+ дней, начиная с текущего)',
	'rod.release.create.step.distribution.transfer': 'перенос релиза',
	'rod.release.create.step.distribution.adaptr-info':
		'Adaptr – новый сервис для размещения цифровой музыки B2B, в котором Стартапы (разработка игр и приложений, и др.) могут выбирать вашу лицензированную музыку для интерактивного использования в своем продукте.',

	'rod.release.create.step.distribution.netease_cloud_music-info':
		'NetEase Cloud Music, запущенная в 2013 году компанией NetEase, Inc., является ведущим поставщиком услуг интерактивной потоковой передачи музыки в Китае с более чем 200 миллионами активных пользователей в месяц.',

	'rod.release.create.help.c-line/p-line':
		'<p>P-line указывает владельца прав на звукозапись и будет отображаться на всех доступных витринах.</p><br> <p>C-line указывает владельца авторских прав на музыкальное произведение, но не на его звукозапись.</p><br> <p>При заполнении C-line указывается обладатель авторских прав.</p><br> <p>Если передаются и авторские, и смежные права на фонограмму, в C-line можно указать лейбл из P-line.</p><br> <p>Если передаются только смежные права, в C-line указывается компания-издатель, которой принадлежат авторские права на данное Произведение.</p>',

	// release create submit

	'rod.release.create.step.submit.upload': 'загрузить',
	'rod.release.create.step.submit.body_title': 'информация',
	'rod.release.create.step.submit.submit': 'Отправить на модерацию',
	'rod.release.moderation.error':
		'Релиз содержит недопустимые данные. Пожалуйста, проверьте отсутствие ошибок на предыдущих шагах',
	'rod.release.create.step.submit.assign_automatically':
		'Присвоить автоматически',
	'rod.release.create.step.submit.release': 'релиз:',
	'rod.release.create.step.submit.final_preview': 'предпросмотр',
	'rod.release.create.step.submit.subtitle': 'сабтайтл',
	'rod.release.create.step.submit.relise_type': 'тип релиза',
	'rod.release.create.step.submit.genres': 'жанры',
	'rod.release.create.step.submit.main_artist': 'основной исполнитель',
	'rod.release.create.step.submit.label': 'лейбл',
	'rod.release.create.step.submit.create_date': 'дата создания',
	'rod.release.create.step.submit.country_name': 'страна создания*',
	'rod.release.create.step.submit.catalog_number': 'каталожный номер',
	'rod.release.create.step.submit.recordings': 'фонограммы',
	'rod.release.create.step.submit.distribution': 'дистрибуция релиза',
	'rod.release.create.step.submit.additional.distribution_tiktok':
		'дистрибуция дополнительных релизов: TikTok',
	'rod.release.create.step.submit.additional.distribution_rt':
		'дистрибуция дополнительных релизов: RT',
	'rod.release.create.step.submit.additional.title': 'название фонограммы',
	'rod.release.create.step.submit.distribution_date': 'дата дистрибуции',
	'rod.release.create.step.submit.additional.distribution.tiktok.date':
		'дата начала продаж TikTok релиза',
	'rod.release.create.step.submit.additional.distribution.rt.date':
		'дата начала продаж RT релиза',
	'rod.release.create.step.submit.outlets': 'витрины',
	'rod.release.create.step.submit.outlets_available': 'выбрано витрин',

	// release create end
	'rod.release.create.step.end.title': 'ваш релиз отправлен на модерацию',
	'rod.composition.create.step.end.title': 'композиция отправлена на модерацию',
	'rod.release.create.step.end.text':
		'После прохождения модерации он станет доступен в списке релизов в разделе Репертуар',
	'rod.composition.create.step.end.text':
		'ваша композиция отправлена, после обработки она появится в разделе {link} в течение двух рабочих дней',
	'rod.composition.final.step.link.text': 'Произведения',
	'rod.release.create.step.end.submit': 'перейти на главный экран',
	'rod.release.shipment.end.title': 'релиз отправлен на отгрузку',
	'rod.release.takedown.end.title': 'релиз отправлен на снятие',
	'rod.release.shipment.end.text':
		'после обработки релиз будет отгружен на выбранные витрины. Вы можете наблюдать актуальный статус на {one} релиза, в табе “отгрузки”.Если у вас остались вопросы, обратитесь в раздел {two}.',
	'rod.release.takedown.end.text':
		'после обработки релиз будет снят с выбранных витрин. Вы можете наблюдать актуальный статус на {one} релиза, в табе “отгрузки”.Если у вас остались вопросы, обратитесь в раздел {two}',
	'rod.release.shipment.end.btn': 'перейти в вкладку "отгрузки"',
	// UploadPhonogramModal

	'rod.upload.phonogram.modal.select_from_list': 'выберите из списка',
	'rod.upload.phonogram.modal.search': 'Поиск по исполнителю или названию',
	'rod.upload.phonogram.modal.selected': 'выбрано',
	'rod.upload.phonogram.modal.clear': 'Сбросить',
	'rod.upload.phonogram.modal.continue': 'Продолжить',

	// Repertoire Upload //

	'rod.repertoire_upload.title': 'загруженные файлы',

	//  Main Page //////

	'rod.main_page.phonograms': 'фонограммы',
	'rod.main_page.compositions': 'произведения',
	'rod.main_page.releases': 'релизы',
	'rod.main_page.mistakes': 'не готово',
	'rod.main_page.disputes': 'диспуты',
	'rod.main_page.balance': 'баланс',
	'rod.btn.add_release': 'добавить релиз',
	'rod.btn.transfer_release': 'перенос релиза',
	'rod.btn.add_catalog': 'добавить каталог',
	'rod.btn.add_composition': 'добавить произведение',
	'rod.btn.add_recordings': 'добавить фонограмму',
	'rod.main_page.you_need_to_sign_a_contract':
		'Необходимо подписать <span>контракт</span>',
	'rod.main_page.bulk_loading_of_repertoire': 'Массовая загрузка репертуара',
	'rod.main_page.greetings.body':
		'Для начала работы с сервисом вам необходимо добавить информацию о репертуаре. Нажмите на кнопки ниже, чтобы добавить релиз (для дистрибуции) или произведение (для заявки прав) по одному. Для передачи каталога в формате Excel или CWR, нажмите на иконку со стрелочкой вверху страницы и выберите Добавить каталог во всплывающем меню.',
	'rod.main_page.greetings.body_widget_error':
		'В связи с техническими работами на сервере чат-бота, просьба временно использовать для коммуникации адрес <a href="mailto:info@broma16.ru">info@broma16.ru.</a>',
	'rod.main_page.greetings.body.dgtal':
		'Для начала работы с сервисом вам необходимо добавить информацию о репертуаре. Нажмите на кнопки ниже, чтобы добавить произведение (для заявки прав) по одному. Для передачи каталога в формате Excel или CWR, нажмите на иконку со стрелочкой вверху страницы и выберите Добавить каталог во всплывающем меню.',
	'rod.new_release': 'новый релиз',
	'rod.transfer_release': 'перенос релиза',

	// HelpView//

	'rod.help_view.title': 'скачайте, заполните и загрузите репертуарный файл',
	'rod.help_view.sample.repertoire_file.text':
		'<span>Скачайте образец </span> репертуарного файла',
	'rod.help_view.fill_out_the_file_according_to_the_instructions.text':
		'Заполните файл согласно <span> инструкции по заполнению</span>',
	'rod.help_view.Upload_your_completed_repertoire_file.text':
		'Загрузите заполненный вами репертуарный файл',
	'rod.help_view.download': 'Загрузить',

	/// loadingRepertoireFile ///

	'rod.loading_repertoire_file.title':
		' Выберите услугу для которой вы передаете данные',
	'rod.loading_repertoire_file.placeholder': ' Я передаю данные для ...',
	'rod.loading_repertoire_file.select': 'Я передаю данные для дистрибьюции...',
	'rod.loading_repertoire_file.Submit': 'Отправить',
	'rod.loading_repertoire_file.cancel': 'отмена',

	/// successView ///

	'rod.successView.title': 'спасибо!',
	'rod.successView.text':
		'файл {filename} успешно прошел проверку и находится на обработке.',
	'rod.successView.text2': 'мы оповестим вас по e-mail об окончании обработки.',
	'rod.successView.go_to_uploaded_files': 'Перейти к загруженным файлам',

	/// RepertoireFileList ///

	'rod.repertoire_file_list.name_date': 'название и дата',
	'rod.repertoire_file_list.stage': 'этап',
	'rod.repertoire_file_list.errors': 'ошибки',

	/// RepertoireFileInfo ///

	'rod.repertoire_file_info.btn.compositions': 'Произведения',
	'rod.repertoire_file_info.btn.recordings': 'Фонограммы',
	'rod.repertoire_file_info.btn.releases': 'Релизы',
	'rod.repertoire_file_info.btn.issues': 'Ошибки',

	/// RepertoireFileMistakes ///

	'rod.repertoire_file_mistakes.download': 'Скачать файл ошибок',
	'rod.repertoire_file_mistakes.upload': 'Загрузить исправленный',
	'rod.repertoire_file_mistakes.issue': 'ошибка',
	'rod.repertoire_file_mistakes.column': 'колонка',
	'rod.repertoire_file_mistakes.value': 'значение',
	'rod.repertoire_file_mistakes.position': 'место',
	'rod.repertoire_file_song_list.title': 'название',
	'rod.repertoire_file_song_list.author': 'автор',

	/// TreatiesPage ///
	'rod.treaties_page.pending_review': 'На проверке',
	'rod.treaties_page.sign_additional_contract': 'заключить новый договор',
	'rod.treaties_page.notify':
		'Новый договор можно добавлять после завершения проверки предыдущего',
	'rod.treaties_page.dates': 'срок действия:',
	'rod.treaties_page.empty': 'нет условий',
	'rod.treaties_page.empty.regions': 'нет регионов',
	'rod.treaties_page.collection': 'период сбора:',
	'rod.treaties_page.condition': 'Условие:',
	'rod.treaties_page.select_services_and_terms':
		'выберите нужные вам услуги и условия для договора',
	'rod.treaties_page.while_attaching_repertoire':
		'При подключении репертуара к услуге вы сможете также исключать территории и онлайн сервисы индивидуально.',
	'rod.treaties_page.next': 'далее',
	'rod.treaties_page.term_of_the_contract': 'Срок договора*',
	'rod.treaties_page.contract_currency': 'Валюта договора*',
	'rod.treaties_page.signature_type': 'Тип подписания*',
	'rod.treaties_page.edit': 'редактировать',
	'rod.treaties_page.hide': 'Скрыть',
	'rod.treaties_page.number': 'Еще +{N}',
	'rod.treaties_page.composition': 'Произведения',
	'rod.treaties_page.recordings': 'Фонограммы',
	'rod.treaties_page.for_online_use': 'Сбор за онлайн использование',
	'rod.treaties_page.for_public_perfomance': 'Сбор за публичное исполнение',
	'rod.treaties_page.for_synchronization': 'Сбор за синхронизацию',
	'rod.treaties_page.for_the_use_of_lyrics': 'Сбор за использование лирики',

	/// ReportsPage ///

	'rod.reports_page.filter.placeholder': 'Отчет по авторским',
	'rod.reports_page.title': 'название',
	'rod.reports_page.period': 'период',
	'rod.reports_page.date_provided': 'дата предоставления',
	'rod.reports_page.action': 'действие',
	'rod.reports_page.nav.reports': 'отчеты',
	'rod.reports_page.nav.invoices': 'счета',
	'rod.user_reports.royalty_to_pay.com.fragment.help':
		'<p>Это Роялти за последний отчетный период. Предыдущие суммы вы можете найти в Отчетах за предыдущие периоды. На данный момент Роялти за предыдущие периоды не суммируются. Подробная информация об Отчетах и Выплатах находится <a href="https://rod.broma16.com/faq/section/reports-and-payments" target="_blank" rel="noopener noreferrer">здесь<a/></p>',
	'rod.user_reports.royalty_to_pay.ru.fragment.help':
		'<p>Это Роялти за последний отчетный период. Предыдущие суммы вы можете найти в Отчетах за предыдущие периоды. На данный момент Роялти за предыдущие периоды не суммируются. Подробная информация об Отчетах и Выплатах находится <a href="https://rod.broma16.ru/faq/section/reports-and-payments" target="_blank" rel="noopener noreferrer">здесь<a/></p>',
	'rod.user_reports_helper_banner_text_ru':
		'<p>В связи с возросшим объемом данных, публикация отчетов задерживается до 25.08.2023. Мы приносим свои извинения за доставленные неудобства.</p></br> <p>Мы понимаем, как важно для вас получать отчетность и выплаты вовремя, поэтому обработка платежей будет ускорена.</p></br> <p><b>Важно:</b></p> <ul><li>&#8226; Отчеты будут опубликованы до 25.08.2023</li><li>&#8226; Выплаты роялти за Q2 2023 будут осуществлены вовремя</li><li>&#8226; Срок обработки инвойсов сократится до 5 дней</li></ul></br> <p>Спасибо за ваше понимание.</p>',
	'rod.user_reports_helper_banner_text_com':
		'<p>Due to the increased volume of data, the publication of reports will be delayed until August 25, 2023. We apologize for any inconveniences.</p></br> <p>We understand the importance of timely report and payment, so we will expedite payment processing.</p></br> <p><b>Important:</b></p> <ul><li>&#8226; Reports will be published by 25.08.2023</li><li>&#8226; Royalty payments for Q2 2023 will be made on time</li></ul></br> <p>Thank you for your understanding.</p>',
	'rod.reports_page.banner':
		'<h4>важная информация о выводе средств</h4> <br/> <p>вывод средств недоступен, т.к. у вас не заполнены персональные и банковские данные для формирования счета. Пожалуйста, перейдите по <a href={value}>ссылке</a> в настройки и актуализируйте информацию, после чего кнопка выплат станет доступной.</p>',
	'rod.reports_page.current_royalty': 'роялти к выплате',
	'rod.reports_page.next_royalty_period': 'дата следующего отчета:',
	'rod.reports_page.withdraw': 'вывести средства',
	'rod.reports_page.withdraw_continue': 'продолжить вывод средств',
	'rod.reports_page.insufficient_funds':
		'<b>Недостаточно средств.</b> </br> </br> <p>Вывод средств возможен после достижения минимальной суммы вывода по договору - {value}.</p>',
	'rod.reports_page.payment.toast.title': 'ваш счет принят в обработку',
	'rod.reports_page.payment.toast.text':
		'О выполнении платежа мы сообщим Вам дополнительно',

	// AddCompositionPage //
	'rod.add_composition_page.step1': 'основная информация',
	'rod.add_composition_page.step2': 'авторы и доли',
	'rod.add_composition_page.step3': 'лирика',
	'rod.add_composition_page.step4': 'исполнения произведения',
	'rod.add_composition_page.step5': 'обзор',

	// BasicInfo //
	'rod.basic_info.type': 'Тип произведения',
	'rod.basic_info.date': 'Дата создания',
	'rod.basic_info.name': 'Название произведения',
	'rod.basic_info.alter_name': 'Альтернативное название',
	'rod.basic_info.description_text':
		'Регистрационный номер фонограммы в формате CC-XXX-YY-NNNNN. Если его не знаете, оставьте пустым.',
	'rod.basic_info.country_name': 'Страна создания',
	'rod.basic_info.subtitle': 'Сабтайтл',
	'rod.basic_info.genres': 'Жанры',
	'rod.basic_info.genres_description':
		'Жанр фонограммы. Можно выбрать несколько.',
	'rod.composition.create.step.basic.helper_title': 'подсказка',
	'rod.composition.create.step.basic.helper_body':
		'Если у произведения есть присвоенный авторским обществом ISWC номер - обязательно укажите его.<br/><br/>Если нет информации о типе произведения - оставьте значение по умолчанию.<br/><br/>Если известен только год создания, но не дата - укажите 01.01 соответствующего года.',
	// AuthorsShares ///

	'rod.authors_shares.title': 'авторы и доли',
	'rod.authors_shares.author': 'Автор #1',
	'rod.authors_shares.author_id': 'Автор #{number}',
	'rod.authors_shares.authors_role': 'Роль автора #1',
	'rod.authors_shares.authors_role_id': 'Роль автора #{number}',
	'rod.authors_shares.authors_share': 'Доля автора #1',
	'rod.authors_shares.authors_share_id': 'Доля автора #{number}',
	'rod.authors_shares.publisher_authors': 'Издатель автора',
	'rod.authors_shares.publisher_share': 'Доля издателя',
	'rod.authors_shares.anonim_role': 'Роль анонима',
	'rod.authors_shares.anonim_share': 'Анонимная доля',
	'rod.authors_shares.notification_unallocated_share':
		'У вас остались нераспределенные доли в',
	'rod.authors_shares.descroption':
		'Укажите данные авторов произведения. Если количество авторов другое, вы можете вернуться на предыдущий шаг и скорректировать их число. Обратите внимание, что сумма долей авторов в произведении всегда должна быть равна 100%',
	'rod.authors_shares.my_shares': 'Доля контролируется мной',
	'rod.authors_shares.add_author': 'Добавить автора',
	'rod.authors_shares.add_anonim_shares': 'Добавить анонимную долю',
	'rod.authors_shares.add_publisher': 'Добавить издателя',
	'rod.authors_shares.author_publisher_id': 'Издатель автора #{id}',
	'rod.authors_shares.publisher_share_id': 'доля издателя #{id}',
	'rod.authors_shares.unallocated_shares':
		'У вас остались нераспределенные доли в {allValue}%',
	'rod.composition.create.step.authors.helper_title': 'подсказка',
	'rod.composition.create.step.authors.helper_body':
		'Укажите данные авторов и паблишеров (при наличии) произведения.<br/><br/>Пожалуйста, указывайте имя автора в виде "Фамилия Имя" и не используйте псевдонимы вместо имен.<br/><br/> Cумма долей авторов и паблишеров в произведении всегда должна быть равна 100%.<br/><br/>Если кто-то из авторов неизвестен, укажите Неизвестный автор в качестве имени.',

	// PerformanceWork //

	'rod.performance_work.title': 'фонограмма #{number}',
	'rod.performance_work.whether_work_was_perfomed':
		'исполнялось ли произведение?',
	'rod.performance_work.yes': 'Да',
	'rod.performance_work.no': 'Нет',
	'rod.performance_work.previously_added_soundtrack':
		'Выберите ранее добавленную фонограмму',
	'rod.performance_work.create_new_recordings': 'создать новую фонограмму',
	'rod.performance_work.create_new_recordings.tooltip':
		'сохраните текущую фонограмму для дальнейших действий',
	'rod.performance_work.name_recordings': 'Название фонограммы',
	'rod.performance_work.main_performer': 'Основные исполнители',
	'rod.performance_work.main_performer.description': 'Например Иванов И. И.',
	'rod.performance_work.label': 'Лейбл',
	'rod.performance_work.iswc.description':
		'Регистрационный номер фонограммы в формате CC-XXX-YY-NNNNN. Если его не знаете, оставьте пустым.',
	'rod.performance_work.featured_artist': 'Включенные исполнители',
	'rod.performance_work.profanity': 'Ненормативная лексика',
	'rod.performance_work.collect_rewards_for_this_form':
		'Собирать вознаграждение за эту фонограмму',
	'rod.performance_work.add_recording2': 'Добавить еще одну фонограмму',
	'rod.performance_work.missing': 'Отсутствует',
	'rod.performance_work.present': 'Присутствует',
	'rod.performance_work.save': 'Сохранить',
	'rod.composition.create.step.performance.helper_title': 'подсказка',
	'rod.composition.create.step.performance.helper_body':
		'Если есть известные исполнения произведения (в том числе выполненные третьими лицами), пожалуйста заполните информацию о них максимально подробно. Это обеспечит своевременность и полноту сбора вознаграждения в пользу авторов произведения.',

	// disputes page

	'rod.disputes.title': 'диспуты',

	// disputes page

	'rod.disputes.disputing': 'Disputing',
	'rod.disputes.disputing.message':
		'There are numbers of disputes appeared in between your account and other parties, who also claim their rights for same master recordings or music works. Please take you time to go through simple resolution wizzard  to resolve all disputes.',
	'rod.disputes.disputing.dispute_party': 'dispute party',
	'rod.disputes.disputing.over_share': 'over share',
	'rod.disputes.disputing.resources': 'resources',
	'rod.disputes.disputing.resolved': 'resolved',
	'rod.disputes.disputing.created_at': 'created at',
	'rod.disputes.disputing.updated': 'updated',
	'rod.disputes.disputing.status': 'status',
	'rod.disputes.disputing.type': 'type',

	'rod.disputes.resolved': 'Resolved',

	// account settings //
	'rod.navbar.settings': 'настройки',
	'rod.account.settings.private_data': 'Личные данные',
	'rod.account.settings.contact_data': 'Контактные данные',
	'rod.account.settings.bank_data': 'Банковские данные',
	'rod.account.settings.pseudonyms': 'Псевдонимы',
	'rod.account.settings.pseudonyms-add': 'добавление псевдонима',
	'rod.account.settings.pseudonyms-edit': 'редактирование псевдонима',
	'rod.account.settings.header': 'Пожалуйста заполните все разделы',
	'rod.account.contact.title': 'контактная информация',
	'rod.account.bank.title': 'реквизиты для получения денежных средств',
	'rod.account.bank.banner.title': 'измените метод оплаты',
	'rod.account.bank.banner.text':
		'В данный момент сервис не поддерживает вывод средств через PayPal. Пожалуйста, выберите другой способ вывода средств.',

	//---------------administration-----------------
	'rod.admin.search.placeholder': 'поиск по имени, телефону, email',
	'rod.admin.no-data': 'нет данных',
	'rod.admin.account_data.title': 'данные аккаунта',
	'rod.admin.signup_data.title': 'данные регистрации',
	'rod.admin.user.title': 'Данные пользователя',
	'rod.admin.account.status_title': 'Статус',
	'rod.admin.delete_rega.title': 'удалить регистрацию',
	'rod.admin.delete_account.title': 'удалить аккаунт',
	'rod.admin.delete_rega.text': 'вы уверены, что хотите удалить регистрацию?',
	'rod.admin.delete_account.text': 'вы уверены, что хотите удалить аккаунт?',
	'rod.admin.account.personal_data.title': 'Название ИП',
	'rod.admin.account.personal_data.behalf': 'Подписант в лице',
	'rod.admin.account.personal_data.behalf_short': 'Краткое имя подписанта',
	'rod.admin.account.personal_data.company_reg_number': 'Регистрационный номер',
	'rod.admin.account.personal_data.company_reg_date': 'Дата регистрации',
	'rod.admin.account.personal_data.country_id': 'Страна регистрации',
	'rod.admin.account.personal_data.last_name': 'Фамилия',
	'rod.admin.account.personal_data.first_name': 'Имя',
	'rod.admin.account.personal_data.patronymic': 'Отчество',
	'rod.admin.account.personal_data.sex': 'Пол',
	'rod.admin.account.personal_data.sex.male': 'Мужской',
	'rod.admin.account.personal_data.sex.female': 'Женский',
	'rod.admin.account.personal_data.birth_date': 'Дата рождения',
	'rod.admin.account.personal_data.birth_place': 'Адрес регистрации',
	'rod.admin.account.personal_data.itin': 'ИНН',
	'rod.admin.account.user_data.email': 'Почта',
	'rod.admin.account.user_data.phone': 'Телефон',
	'rod.admin.account': 'Аккаунт',
	'rod.admin.account_roles': 'Роли',
	'rod.admin.account.category': 'Категория',
	'rod.admin.account.repertoire': 'Репертуар',
	'rod.admin.account.personal_information': 'Персональная информация',
	'rod.admin.account.sole_proprietor_data': 'Информация об ИП',
	'rod.admin.account.company_data': 'Информация о компании',
	'rod.admin.account.company_name': 'Название организации',
	'rod.admin.account.attorney': 'Номер доверенности',
	'rod.admin.account.signature_position': 'Должность подписанта',
	'rod.admin.account.acting_on': 'Действующий на основании',
	'rod.admin.account.company_vat_number': 'Идентификационный номер НДС',
	'rod.admin.account.contact_information': 'Контактная информация',
	'rod.admin.account.country': 'Страна',
	'rod.admin.account.zip_code': 'Индекс',
	'rod.admin.account.address': 'Адрес',
	'rod.admin.account.city': 'Город',
	'rod.admin.account.site': 'Сайт',
	'rod.admin.account.locale': 'Локаль',
	'rod.admin.account.current_locale': 'текущая локаль: {current_locale}',
	'rod.admin.account.redirect_locale': 'Перенаправлять локаль',
	'rod.admin.account.files.documents': 'Документы',
	'rod.admin.account.files.document_type': 'Тип документа',
	'rod.admin.account.files.file': 'Файл',
	'rod.admin.account.files.quality': 'Качество',
	'rod.admin.account.files.data_match': 'Данные соответствуют',
	'rod.admin.account.files.set_status': 'Будет установлен статус',
	'rod.admin.account.files.approved': 'подтверждено',
	'rod.admin.account.files.sent': 'Отправлено',
	'rod.admin.account.files.to_send': 'Отправить',
	'rod.admin.account.societies': 'Общества',
	'rod.admin.account.societies.author_society': 'Авторское общество',
	'rod.admin.account.societies.date': 'Дата',
	'rod.admin.account.pseudonyms.names_identifiers': 'Имена и идентификаторы',
	'rod.admin.account.pseudonyms.identifier': 'Идентификатор',
	'rod.admin.account.payment.data': 'Платежные данные',
	'rod.admin.account.payment.method': 'Метод оплаты',
	'rod.admin.account.payment.bank_data': 'Банковские реквизиты',
	'rod.admin.account.payment.beneficiary_name': 'Имя получателя',
	'rod.admin.account.payment.bank_account_name': 'Номер счета получателя',
	'rod.admin.account.payment.bank_name': 'Название банка',
	'rod.admin.account.payment.bank_address': 'Адрес банка',
	'rod.admin.account.payment.bank_country': 'Страна банка',
	'rod.admin.account.payment.correspondent_account': 'Корреспондентский счет',
	'rod.admin.account.payment.correspondent_account_swift':
		'SWIFT/БИК банка корреспондента',
	'rod.admin.account.payment.swift_bic': 'SWIFT/БИК',
	'rod.admin.account.payment.paypal_account': 'Paypal аккаунт',
	'rod.admin.account.contracts': 'Контракты',
	'rod.admin.account.contracts.period-info':
		'Договоры формируются с возможностью автопродления и досрочного прекращения',
	'rod.admin.account.contracts.signing_variants': 'Способ подписания контракта',
	'rod.admin.account.contracts.by_hand': 'Ручное',
	'rod.admin.account.contracts.switch_to_by_hand_signing':
		'Переключить на ручное подписание',
	'rod.admin.account.contracts.signs': 'Подписи',
	'rod.admin.account.contracts.all_pages': 'Все страницы',
	'rod.admin.invalid-date': 'не корректная дата',
	'rod.admin.sign-contract.sent': 'Договор отправлен',
	'rod.admin.sign-contract.rejected': 'Договор отклонен',
	'rod.admin.account.status.confirm': 'Подтверждение данных',
	'rod.admin.account.status.sms_confirm': 'Подтверждение номера телефона',
	'rod.admin.account.status.personal_area': 'Личный кабинет',
	'rod.admin.account.status.personal_area_legacy': 'Личный кабинет (старый)',
	'rod.admin.account.status.repertoire_apply': 'Передача репертуара',

	/*--- status-history --- */
	'rod.admin.status_history.title': 'история статусов',
	'rod.admin.change_history.title': 'история изменений',
	'rod.admin.status_history.manual_type': 'ручной',
	'rod.admin.status_history.auto_type': 'автоматический',
	'rod.admin.pending_documents': 'Проверка документов',
	'rod.admin.pending_contracts': 'Проверка контрактов',
	'rod.admin.declined_documents': 'Документы отклонены',
	'rod.admin.declined_contracts': 'Контракты отклонены',
	'rod.admin.approved_documents': 'Документы подтверждены',
	'rod.admin.approved_contracts': 'Контракты подтверждены',
	'rod.admin.notifications.title': 'Заголовок',
	'rod.admin.notifications.text': 'Текст',
	'rod.admin.notifications.date': 'Дата',
	'rod.admin.notifications.recipients': 'Получатели',

	'rod.admin.statistics_service.issue': 'посмотреть&nbsp;подробности',
	'rod.admin.signup.sms_verify': 'Верифицировать телефон вручную',
	'rod.admin.signup.email_verify': 'Верифицировать email вручную',
	'rod.admin.signup.email_code': 'Код подтверждения email -',
	'rod.admin.signup.sms_code': 'Код подтверждения номера телефона -',
	'rod.admin.signup.sms_valid_until': 'Действует до',
	'rod.admin.signup.err422':
		'Операция не удалась. Пользователь уже изменил статус',
	'rod.admin.signup.phone.manual_verification':
		'Код подтверждения SMS запрошен администратором',
	'rod.admin.signup.email.manual_verification':
		'Код подтверждения Email запрошен администратором',
	'rod.admin.time.just_now': 'Только что',

	'rod.faq.title_comment_begin': 'Эта секция содержит всю информацию по теме',
	'rod.faq.title_comment_end': `в ${platform.title_space}`,
	'rod.faq.related_articles': 'статьи по теме',
	'rod.faq.knowledgebase': 'база знаний',
	'rod.faq.search_result': 'результат поиска',
	'rod.repertoire.help.own_col_rev':
		'<p>Own/Владение - отражает долю объекта прав, которым владеет правообладатель.</p><br><p>Col/Сбор -  показывает процент и сторону, которая осуществляет сбор вознаграждения за использование объекта прав.</p><br><p>Rev/Доход - отражает распределение вознаграждения между сторонами в соответствии с долей владения объектом и контрактными условиями.</p>',
	'rod.faq.results_for': 'Результат для ',
	'rod.faq.help': 'помощь',

	//Admin articles//
	'rod.admin.articles': 'статьи',
	'rod.admin.articles_add': 'создать статью',
	'rod.admin.articles.rubric': 'рубрика',
	'rod.admin.articles.rubrics': 'рубрики',
	'rod.admin.articles.date': 'дата',
	'rod.admin.articles.last_corrected_date': 'дата редактирования',
	'rod.admin.articles.rubric_settings': 'настройки рубрик',
	'rod.admin.articles.rubric_settings_title': 'название',
	'rod.admin.articles.rubric_add': 'добавить рубрику',
	'rod.faq.create_article': 'создание статьи',
	'rod.faq.article_title': 'заголовок статьи',
	'rod.faq.article_main_text': 'основной текст статьи',
	'rod.faq.pic.upload': '<a>Загрузите изображение</a>',
	'rod.faq.confirm_one_article':
		'Статья заполнена только на одном языке. Пожалуйста подготовьте статью на обоих языках.',
	'rod.faq.edit_article': 'редактирование статьи',
	'rod.release_type.other': 'другой',
	'rod.admin.articles.rubric_edit': 'Редактирование рубрики',
	'rod.admin.articles.rubric_create': 'Создание рубрики',
	'rod.admin.articles.search': 'поиск по названию и тексту статьи',

	// Admin reports //
	'rod.admin.reports.add': 'Добавить отчеты',
	'rod.admin.reports.add_distribution': 'добавить дистрибуцию',
	'rod.admin.reports.release_date': 'Дата следующего релиза',
	'rod.admin.reports.noData': 'Пожалуйста, <u>загрузите</u> отчеты',
	'rod.admin.reports.loading_report': 'Загрузка отчета',
	'rod.admin.reports.editing_report': 'Редактирование отчета',
	'rod.admin.distribution.create': 'создание дистрибуции',
	'rod.admin.distribution.edit': 'редактирование дистрибуции',
	'rod.admin.distribution.name': 'название дистрибуции',

	'rod.admin.reports.report_name': 'Название отчета',
	'rod.admin.reports.load_archive': 'Загрузить архив',
	'rod.admin.reports.load_file': 'Загрузить файл',
	'rod.admin.reports.report_dropbox':
		'<a>Загрузите</a> или перетяните zip архив с отчетами',
	'rod.admin.reports.report_dropbox_xls':
		'<a>Загрузите</a> или перетяните файлы отчетов',
	'rod.admin.reports.edit_report_dropbox':
		'<a>Загрузите</a> или перетяните xls файл отчета',
	'rod.admin.reports.confirm_one_article':
		'Отчет заполнен только на одном языке. Пожалуйста подготовьте отчет на обоих языках.',
	'rod.admin.reports.download_again': 'повторить загрузку',
	'rod.admin.reports.edit': 'редактировать',
	'rod.admin.reports.attach': 'Пожалуйста загрузите файл',
	'rod.admin.reports.pending_review': 'Проверка',
	'rod.admin.reports.edit_file':
		'Если необходимо изменить файл, воспользуйтесь дропбоксом ниже',
	'rod.admin.reports.edit_file_new':
		'Если необходимо заменить файл - воспользуйтесь формой ниже',
	'rod.admin.reports.filters': 'фильтры',
	'rod.admin.reports.reports_with_errors': 'отчеты с ошибками',
	'rod.admin.reports.reports_with_errors_modal_title':
		'редактирование файла отчета',
	'rod.admin.reports.reports.summ': 'Сумма',
	'rod.admin.reports.reports.currency': 'Валюта',

	'rod.no_items_found': 'Данные не найдены',
	'rod.no_reports_title': 'нет отчетов',
	'rod.no_reports_body': 'загрузите файлы, чтобы просматривать отчеты',
	'rod.filter.no_reports_title': 'нет результатов',
	'rod.filter.no_reports_body': 'попробуйте изменить параметры поиска',

	// contracts
	'rod.contracts.whole_world': 'Весь Мир',
	'rod.contracts.regions': 'регионы:',

	'rod.contracts.number': 'Номер договора',
	'rod.contracts.licensor': 'лицензиар',
	'rod.contracts.licensee': 'лицензиат',
	'rod.contracts.term': 'срок действия условия',
	'rod.contracts.start_collection': 'начало ретроактивного сбора',
	'rod.contracts.end_self_off_period': 'окончание self-off периода',
	'rod.contracts.automatic_prolongation': 'автоматическая пролонгация',
	// admin.reports.currency
	'rod.contracts.rights_transfer': 'передача прав',
	'rod.contracts.reports_transfer': 'подача отчетов',
	// field.territories.included
	'rod.contracts.territories_excluded': 'за исключением территорий',
	'rod.contracts.regions_excluded': 'за исключением:',
	// rod.statistic.outlets
	'rod.contracts.outlets_excluded': 'за исключением витрин',
	//rod.main_page.releases

	'rod.contracts.rights_types_group': 'группа типов прав',
	'rod.contracts.right_type': 'тип права',

	'rod.contracts.licensor_share': 'доля лицензиара',
	'rod.contracts.licensee_share': 'доля лицензиата',
	'rod.contracts.price': 'цена',
	'rod.contracts.publisher_share': 'доля издателя',
	'rod.contracts.sub_publisher_share': 'доля субиздателя',

	'rod.contracts.type': 'тип договора',
	'rod.contracts.author_share_collection': 'сбор доли автора',
	'rod.contracts.author_share_payment': 'выплата доли автора от источника',
	'rod.contracts.author_share': 'доля автора',
	'rod.contracts.specific': 'специфическая',
	'rod.contracts.exclusive': 'эксклюзивная',
	'rod.contracts.add_role.title': 'Добавление роли',

	'rod.contracts.collect_licensor_share': 'Сбор доли лицензиара',
	'rod.contracts.collect_assignor_share': 'Сбор доли автора',
	'rod.contracts.collect_author_share_at_source':
		'Выплата доли автора от источника',

	'rod.contracts.licensor_share_via_publisher': 'Licensor share via publisher',
	'rod.contracts.neighboring_rights_share': 'Доля смежных прав',
	'rod.contracts.authors_rights_share': 'Доля авторских прав',
	'rod.contracts.ccid_share': 'Доля MECH/PERF',

	'rod.admin.find': 'Поиск по id аккаунта',
	'rod.reports.find': 'Поиск по отчетам',
	'rod.distribution_name.find': 'Поиск по названию дистрибуции',
	'rod.account_name.find': 'Поиск по названию аккаунта',

	'rod.pseudonyms.main': 'основной псевдоним',
	'rod.pseudonyms.todo_main': 'сделать основным',
	'rod.pseudonyms.add': 'Добавить псевдоним',
	'rod.pseudonyms.delete': 'удалить&nbsp;псевдоним',
	'rod.pseudonyms.outlet': 'Витрина',
	'rod.pseudonyms.delete-confirm': 'Удалить псевдоним?',
	'rod.pseudonyms.delete-help':
		'Это действие необратимо. Если вы удалите псевдоним, вам прийдется создать его заново.',
	'rod.contracts.expand': 'развернуть',

	'rod.add_service.title': 'добавить услугу',

	'rod.modal.understood': 'Понятно',
	'rod.modal.statistic_title': 'График отображает динамику за срок 90 дней',
	'rod.modal.statistic_text':
		'Вам по прежнему доступна вся статистика и выгрузка отчёта, но график отображает ограниченную динамику за последние 90 дней выбранного периода. ',
	'rod.modal.admin_reports_title': 'Присутствуют записи с ошибками',
	'rod.modal.admin_reports_text':
		'Активируйте фильтр для отображения отчетов с ошибками и исправьте их, выбрав в меню пункт “Редактировать”',

	'rod.modal.admin_reports_parsing.title': 'Возникла ошибка',
	'rod.modal.admin_reports_parsing.text': 'Пожалуйста, загрузите архив снова',
	'rod.modal.leave_only_one_track.title': 'уберите лишние треки',
	'rod.modal.leave_only_one_track.text':
		'Для данного типа релиза доступен только один трек. Пожалуйста, оставьте нужный и удалите лишние из списка',
	'rod.modal.leave_only_one_track.btn': 'далее',

	'rod.modal.cancel': 'Отмена',
	'rod.modal.yes_delete': 'Да, удалить',

	'rod.distribution.delete-confirm': 'Удалить дистрибуцию?',
	'rod.distribution.delete-help':
		'Это действие необратимо. Если вы удалите дистрибуцию, все ее отчеты также будут удалены.',

	'rod.distribution.date_time': 'Дата и время публикации',
	'rod.contracts.no_contracts_info':
		'Ваш аккаунт является лицензиатом, с которым заключают контракты, поэтому у вас нет договоров',
	'rod.rubric.delete-confirm': 'Удалить рубрику?',
	'rod.rubric.delete-help':
		'В данной рубрике присутствуют связанные статьи, чтобы ее удалить, сначала перенесите статьи в другую рубрику',
	'rod.release.confirmation-title': 'Подтвердите действие',
	'rod.release.shipment.confirmation-text':
		'Вы уверены, что хотите отгрузить релиз на выбранные витрины?',
	'rod.release.takendown.confirmation-text':
		'Вы уверены, что хотите снять релиз с выбранных витрин?',

	'rod.admin.handling.drafts_title': 'список просроченных черновиков',
	'rod.admin.handling.drafts_delete_title': 'удалить черновик',
	'rod.admin.handling.drafts_delete_text':
		'вы уверены, что хотите удалить черновик?',
	'rod.handling.release_text':
		'нажимая кнопку, вы отправляете все созданные  за день релизы на модерацию. Пожалуйста, пользуйтесь кнопкой только по срочной необходимости',
	'rod.handling.release_help': 'что это значит?',
	'rod.handling.release_info_text':
		'Релизы выгружаются в автоматическом режиме раз в сутки, но если вам нужно срочно выгрузить релиз, вы можете воспользоваться функционалом выпуска релиза. Все созданные релизы к этому часу будут отгружены на модерацию. Пожалуйста, не используйте функционал часто, т.к. это влияет на команду, занимающейся модерацией релизов',

	/*links words*/
	'rod.link_word.on_page': 'странице',
	'rod.link_word.faq': 'FAQ',

	'rod.admin.account.contract_help': 'не все договора загружены пользователем',
};

export default pages;
