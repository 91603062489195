/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */
import classNames from 'classnames';
import { withAuth, withUI } from 'hocs';
import React from 'react';
import { compose } from 'recompose';
import styles from './Auth.module.css';

class Auth extends React.Component {
	static propTypes = {};

	static defaultProps = {};

	static displayName = 'Auth';

	render() {
		const {
			className,
			authContext: { user },
			UIContext: { isShowUserMenu },
			withFocusRef,
			...props
		} = this.props;
		const classes = classNames(className, styles['Auth'], {
			'menu-open': isShowUserMenu,
		});

		let name;
		try {
			// const person = user.roles[0].accounts[0].persons[0];
			// name = user.first_name[0] + user.last_name[0];
			name = user['title_en'].split(' ').map((name) => name[0]);
		} catch (e) {
			name = 'NA';
		}
		return (
			<div {...props} className={classes}>
				<div className={classNames(styles.User)}>{name}</div>
				{isShowUserMenu ? (
					<i className="fa fa-caret-up" />
				) : (
					<i className="fa fa-caret-down" />
				)}
			</div>
		);
	}
}

export default compose(withAuth, withUI)(Auth);
