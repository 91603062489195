import React, { useEffect, useState, useContext } from 'react';
import {
	TextFieldInput,
	AutocompleteInputPseudonyms,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
// import { accounts } from 'services';
import styles from './MuiAutocompleteMultiple.module.css';
// import lscache from 'lscache';
// import { setCache } from 'services/cacheHelper/setCache';
import { RootContext } from 'contexts/RootContext';
import isItemSelected from 'logic/multiselectHighlight.js';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			// width: '228px !important',
			'& .MuiAutocomplete-listbox': {
				background: '#f0f0f0',
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 8px',
					'&:hover': {
						backgroundColor: '#F0F0F0 !important',
					},
				},
			},
			// '&': { left: '230px !important' },
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return (
		<Popper
			{...props}
			className={`${classes.root} ${styles.noNavBar}`}
			style={{ width: '328px' }}
			placement="bottom-end"
		/>
	);
};

const MuiAutocomplete = ({ onChange, label, value, required, roles }) => {
	const [genresArr, setGenresArr] = useState([]);
	// const [isReqLive, setIsReqLive] = useState(false);
	const lang = localStorage.getItem('lang');
	const { getContributorRoles } = useContext(RootContext);
	useEffect(() => {
		setGenresArr(roles);
	}, [roles]);
	useEffect(() => {
		// getContributorRoles(/* lang */ 'ru')
		// 	.then((res) => {
		// 		res = res.data;
		// 		// console.log('res', res);
		// 		setGenresArr(res);
		// 	})
		// 	.catch((err) => console.info('err', err));
		// let genresCache = lscache.get('genresCache');
		// if (genresCache && !isReqLive) {
		// 	if (lang === 'ru') {
		// 		genresCache = genresCache.sort((a, b) =>
		// 			a.title_ru > b.title_ru ? 1 : -1
		// 		);
		// 	} else {
		// 		genresCache = genresCache.sort((a, b) =>
		// 			a.title_en > b.title_en ? 1 : -1
		// 		);
		// 	}
		// 	setGenresArr(genresCache);
		// } else {
		// accounts
		// 	.getGenres()
		// 	.then((res) => {
		// 		res = res.data.data;
		// 		console.log('res99', res);
		// 		setCache('genres', 'genresCache', res);
		// 		if (lang === 'ru') {
		// 			res = res.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
		// 		} else {
		// 			res = res.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
		// 		}
		// 		setGenresArr(res);
		// 	})
		// 	.catch((error) => {
		// 		console.info('Error', error);
		// 	});
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	if (genresArr.length) {
		for (let i = 0; i < value.length; i++) {
			for (let j = 0; j < genresArr.length; j++) {
				if (value[i].code === genresArr[j].code) {
					value[i] = {
						id: genresArr[j].id,
						code: genresArr[j].code,
						title: genresArr[j].title,
						scope: genresArr[j].scope,
					};
				}
			}
		}
	}

	const filterOptions = (_, state) => {
		const newOptions = [];
		if (state.inputValue) {
			genresArr.forEach((item) => {
				if (
					item.title.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					item.title.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				) {
					newOptions.push(item);
				}
			});
			return newOptions;
		} else return genresArr;
	};

	return (
		<div className={styles.autoGenres_wrapper}>
			<AutocompleteInputPseudonyms
				multiple
				id="size-small-standard-multi"
				size="small"
				// limitTags={5}
				onChange={(_, obj) => onChange(obj)}
				options={genresArr}
				value={value}
				renderInput={(params) => (
					<TextFieldInput
						{...params}
						label={label}
						variant="standard"
						// required={required}
					/>
				)}
				getOptionLabel={(option) => {
					// return lang === 'ru' ? option.title : option.code;
					return option.title;
				}}
				renderOption={(option, item) => {
					return (
						<li
							{...option}
							style={{
								backgroundColor: isItemSelected(item, value)
									? 'var(--color-green)'
									: 'var(--color-white)',
								color: isItemSelected(item, value)
									? 'var(--color-white)'
									: 'unset',
							}}
						>
							{item.title}
							{/* {`${lang === 'ru' ? item.title : item.title}`} */}
						</li>
					);
				}}
				isOptionEqualToValue={(option, value) => option.code === value.code}
				PopperComponent={CustomPopper}
				filterOptions={filterOptions}
			/>
			{/* {value.length > 2 && (
				<span className={styles.autoTag}>+{value.length - 2}</span>
			)} */}
		</div>
	);
};

export default MuiAutocomplete;
