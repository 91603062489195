// Core
import { FormattedMessage } from 'react-intl';

// Helpers
import { getTooltip } from '../../helpers/getTooltip';

// Icons
import remove from 'images/close_grey.svg';
import edit from 'images/edit.svg';

// Styles
import s from '../NewRecordingForm.module.css';

const ShowEditRecording = ({
	newRecording,
	showRecordings,
	onClickDeleteItem,
	disableRecordingBtns,
}) => {
	return (
		<div className={s.form_wrapper}>
			<div className={s.title_wrapepr}>
				<h2 className={s.name_recording}>
					<span className={s.title_name}>{newRecording['title']}</span>
				</h2>
				<div className={s.title_hover_hide}>{newRecording['title']}</div>
			</div>
			<div className={s.btn_container}>
				<button
					type="button"
					className={disableRecordingBtns ? s.btn__edit_disabled : s.btn__edit}
					disabled={disableRecordingBtns}
					onClick={showRecordings}
					title={getTooltip(disableRecordingBtns)}
				>
					<img
						className={
							disableRecordingBtns ? s.edit__svg_disabled : s.edit__svg
						}
						src={edit}
						alt="edit"
					/>
					<p>
						<FormattedMessage id={'rod.treaties_page.edit'} />
					</p>
				</button>
				<button
					className={
						disableRecordingBtns ? s.remove_svg_disabled : s.remove_svg
					}
					onClick={onClickDeleteItem}
					disabled={disableRecordingBtns}
					title={getTooltip(disableRecordingBtns)}
				>
					<img src={remove} alt="" />
				</button>
			</div>
		</div>
	);
};

export default ShowEditRecording;
