import React, { useState, useEffect, useContext } from 'react';
import { withRoot, withAuth, withUI } from 'hocs';
import { compose } from 'recompose';
import dynamic from 'images/dynamic.svg';
import { AudioPlayer } from 'material-design/components/audioPlayer';
import { useRouteMatch } from 'react-router-dom';
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import { UIContext } from 'contexts/UIContext';
import { Link } from 'react-router-dom';
import { Loading, NoData } from 'components';
import eye from 'images/eye_black.svg';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import s from './RepertoireCompositionRecording.module.css';

function RepertoireCompositionRecording(props) {
	const match = useRouteMatch();
	const compositionId = match.params.id;
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const lang = localStorage.getItem('lang');

	const {
		rootContext: { getCompositionRecordings, getRecordingAudio, getGenres },
		authContext: { accountId },
		UIContext: { isPlayerShow },
	} = props;

	const uiContext = useContext(UIContext);
	const { play, handlePlay, audioId } = uiContext;

	useEffect(() => {
		const genresList = getGenres();
		getCompositionRecordings(accountId, compositionId)
			.then((res) => {
				return res.data;
			})
			.then((res) => {
				// getGenres().then((genresList) => {
				const result = (Array.isArray(res) ? res : [res]).map((element) => ({
					...element,
					genres: element.genres.map((genId) =>
						genresList.find((el) => el.id === genId || genId === el.code)
					),
				}));

				if (Array.isArray(res)) {
					setItems(result);
				} else {
					setItems([result]);
				}
				setLoading(false);
				// });
			})
			.catch((error) => {
				console.info('Error', error);
			});
	}, []);

	useEffect(() => {
		items.forEach((item, index) => {
			getRecordingAudio(accountId, item.id).then((res) => {
				item.audioData = res.data;
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	return loading ? (
		<Loading />
	) : (
		<>
			{items.length > 0 ? (
				<div className={s.songPhonogram__body}>
					<div className={`${s.songPhonogramBody__tableHead} ${s.song__table}`}>
						<FormattedHTMLMessage id={'rod.for_all.title'} />
						<FormattedHTMLMessage id={'rod.for_all.performers'} />
						<FormattedHTMLMessage id={'rod.composition.info.genres'} />
						<span className={s.songTableHead__ISRC}>
							<FormattedMessage id={'ISRC'} />
						</span>
					</div>
					<ul className={s.songPhonogramBody__tableBody}>
						{items.map((i, index) => (
							<li key={index}>
								<Link key={i.id} to={`/repertoire/recordings/${i.id}`}>
									<div className={`${s.song__tableItem} ${s.song__table}`}>
										<div className={s.song__tableName}>
											<span>{i.title}</span>
										</div>
										<div className={s.song__tableExecutor}>
											<span>
												{Array.isArray(i.performers)
													? i.performers.join(', ')
													: i.performers}
											</span>
										</div>
										<div className={s.song__tableGenre}>
											<span>
												{i.genres.map((s) => s[`title_${lang}`]).join(', ')}
											</span>
										</div>
										<div className={s.song__tableISRC}>
											<span>{i.isrc ? i.isrc : ' '}</span>
										</div>
										<img
											src={dynamic}
											width={22}
											height={22}
											className={s.dynamic}
											alt=""
										/>
										<img
											src={eye}
											className={s.eye}
											// onClick={() => handleEye(i.id)}
											alt=""
										/>
										{/* {i.preview && (
											<img
												src={
													audioId === i.id && play ? stop_button : play_button
												}
												className={s.play}
												onClick={() => handlePlay(index, i)}
												alt=""
											/>
										)} */}
									</div>
								</Link>
							</li>
						))}
					</ul>
				</div>
			) : (
				<div>
					<NoData />
				</div>
			)}
		</>
	);
}
export default compose(
	withAuth,
	withRoot,
	withUI
)(RepertoireCompositionRecording);
