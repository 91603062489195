import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import styles from './StatisticReports.module.css';
import { useContext, useEffect, useState } from 'react';
import { RootContext } from 'contexts/RootContext';
import { AuthContext } from 'contexts/AuthContext';
import { Loading, NoData } from 'components';
import { Pagination } from 'material-design/components';

const StatisticReports = ({ adminAccountId, accountName }) => {
	const [reports, setReports] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const rootContext = useContext(RootContext);
	const authContext = useContext(AuthContext);

	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: localStorage.getItem('accountId');

	useEffect(() => {
		// setLoading(true);

		if (isAdmin) {
			rootContext
				.getAdminStatisticReports(accountId, page)
				.then((res) => {
					const data = res.data.map((item) => {
						item.parameters = JSON.parse(item.parameters);
						item.parameters.datefrom = item.parameters.datefrom.replace(
							/(\d{4})-(\d{2})-(\d{2})/gm,
							'$3.$2.$1'
						);
						item.parameters.dateto = item.parameters.dateto.replace(
							/(\d{4})-(\d{2})-(\d{2})/gm,
							'$3.$2.$1'
						);

						item.time = item.updated_at.match(/\d{1,2}:\d{2}/)[0];

						item.updated_at = item.updated_at
							.slice(0, item.updated_at.indexOf('T'))
							.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');

						return item;
					});

					setReports(data);
					setTotal(res.total);
				})
				.catch((err) => {
					console.info('Error', err);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			rootContext
				.getAccountStatisticReports(accountId, page)
				.then((res) => {
					const data = res.data.map((item) => {
						item.parameters = JSON.parse(item.parameters);
						item.parameters.datefrom = item.parameters.datefrom.replace(
							/(\d{4})-(\d{2})-(\d{2})/gm,
							'$3.$2.$1'
						);
						item.parameters.dateto = item.parameters.dateto.replace(
							/(\d{4})-(\d{2})-(\d{2})/gm,
							'$3.$2.$1'
						);

						item.time = item.updated_at.match(/\d{1,2}:\d{2}/)[0];

						item.updated_at = item.updated_at
							.slice(0, item.updated_at.indexOf('T'))
							.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');

						item.name = authContext.user.accounts.filter(
							(acc) =>
								acc.id.toString() === item.parameters.account_id.toString()
						);

						return item;
					});

					setReports(data);
					setTotal(res.total);
				})
				.catch((err) => {
					console.info('Error', err);
				})
				.finally(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const handleDownload = (reportId, accountId) => {
		if (isAdmin) {
			rootContext
				.getStatisticAdminReportFile(accountId, reportId)
				.then((file) => {
					window.open(file.data.output_file, '_parent');
				});
		} else {
			rootContext
				.getStatisticAccountReportFile(accountId, reportId)
				.then((file) => {
					window.open(file.data.output_file, '_parent');
				});
		}
	};

	return (
		<>
			{loading ? (
				<Loading className={styles.loader} />
			) : reports.length ? (
				<div className={styles.RepertoireSongInfo__mainInfoTable}>
					<Row className={styles.row_header}>
						<Col width={'35%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_title'} />
						</Col>
						<Col width={'35%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_period'} />
						</Col>
						<Col width={'20%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_date'} />
						</Col>
						<Col width={'10%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_action'} />
						</Col>
					</Row>
					<div className={styles.RepertoireMainInfoTable__body}>
						<ul>
							{reports.map((item) => (
								<li>
									<Row className={styles.row_data}>
										<Row className={styles.row_title}>
											<Col width={'35%'}>
												{isAdmin
													? item.updated_at +
													  ' ' +
													  item.time +
													  ' : ' +
													  accountName
													: item.updated_at +
													  ' ' +
													  item.time +
													  ' : ' +
													  item.name[0].title}
											</Col>
											<Col width={'35%'}>
												{item.parameters.datefrom +
													' - ' +
													item.parameters.dateto}
											</Col>
											<Col width={'20%'}>{item.updated_at}</Col>
											<Col width={'10%'}>
												<div
													className={styles.download}
													onClick={() =>
														handleDownload(item.id, item.parameters.account_id)
													}
												>
													<FormattedMessage id={'rod.statistic.download'} />
												</div>
											</Col>
										</Row>
									</Row>
								</li>
							))}
						</ul>
					</div>
				</div>
			) : (
				<NoData />
			)}
			{total > 15 && !loading && (
				<Pagination
					page={page}
					total={total}
					paginate={(pageNumber) => {
						setPage(pageNumber);
					}}
					items_on_page={15}
				/>
			)}
		</>
	);
};

export default StatisticReports;
