// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Context
import { UIContext } from 'contexts/UIContext';

// Services
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { accounts } from 'services';

// UI
import {
	RepertoireDraftSection,
	RepertoireCompositionSection,
	RepertoireRecordingSection,
	RepertoireReleaseSection,
} from './RepertoireCategory';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';

// Styles
import { styled } from '@mui/material/styles';
import styles from './Repertoire.module.css';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: '#f0f0f0',
	color: 'var(--color-dark-grey)',
	font: 'var(--gilroy-Bold-14-17)',
	flex: '0 0 25%',
	padding: '13px 0',
	border: 'none',
	textAlign: 'center',
	margin: 0,
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		'& > *:first-child': {
			textDecoration: 'line-through',
		},
	},
	'& > *:first-child': {
		marginRight: '8px',
	},
}));

function Reperoire(props) {
	const {
		currentDraftCount,
		setCurrentDraftCount,
		setCurrentCompositionStatus,
	} = useContext(UIContext);

	const [draftTotal, setDraftTotal] = useState('');
	const [compositionTotal, setCompostitionTotal] = useState('');
	const [recordingTotal, setRecordingTotal] = useState('');
	const [releaseTotal, setReleaseTotal] = useState('');
	const [assetStatistics, setAssetStatistics] = useState({});

	const {
		UIContext: {
			showTitle,
			hideTitle,
			selectRepertoire,
			setSelectedRepertoire,
		},
		authContext: { accountId },
	} = props;

	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		if (match.path.includes('drafts')) {
			setSelectedRepertoire('drafts');
		} else if (match.path.includes('releases')) {
			setSelectedRepertoire('releases');
		} else if (match.path.includes('recordings')) {
			setSelectedRepertoire('recordings');
		} else if (match.path.includes('compositions')) {
			setSelectedRepertoire('compositions');
		} else {
			setSelectedRepertoire('drafts');
		}

		accounts
			.getAssetStatistics(accountId)
			.then((res) => {
				setAssetStatistics(res.data.data);
				setCompostitionTotal(res.data.data['compositions'].total);
				setRecordingTotal(res.data.data['recordings'].total);
				setReleaseTotal(res.data.data['releases'].total);
			})
			.catch((error) => {
				console.error('Error', error);
			});
		accounts
			.getAssetsDrafts(accountId)
			.then((res) => {
				setDraftTotal(res.data.total);
			})
			.catch((error) => {
				console.error('Error', error);
			});
		showTitle('rod.navbar.repertoire');
		return function cleanup() {
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			setCurrentDraftCount(null);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getLink = (name) => {
		switch (name) {
			case 'drafts':
				setSelectedRepertoire('drafts');
				return history.push(`/repertoire/drafts`);
			case 'releases':
				setSelectedRepertoire('releases');
				return history.push(`/repertoire/releases`);
			case 'recordings':
				setSelectedRepertoire('recordings');
				return history.push(`/repertoire/recordings`);
			case 'compositions':
				setSelectedRepertoire('compositions');
				return history.push(`/repertoire/compositions`);

			default:
				history.push(`/repertoire/drafts`);
				break;
		}
	};

	const onClickTabHandler = (link) => {
		setCurrentCompositionStatus('');
		getLink(link);
	};

	return (
		selectRepertoire && (
			<>
				<div className={styles.headerText}>
					<FormattedHTMLMessage id={'rod.navbar.repertoire'} />
				</div>
				<TabsUnstyled
					defaultValue={selectRepertoire}
					className={styles.repertoire}
				>
					<TabsListUnstyled className={styles.repertoire__header}>
						<HeaderButton
							value={'drafts'}
							onClick={() => onClickTabHandler('drafts')}
							className={styles.repertoire__headerButton}
						>
							<FormattedHTMLMessage id={'rod.repertoire.drafts'} />
							{compositionTotal.length !== 0 && (
								<span className={styles.repertoire__headerButtonRepertoires}>
									{!currentDraftCount ? draftTotal : currentDraftCount}
								</span>
							)}
						</HeaderButton>
						<HeaderButton
							value={'releases'}
							onClick={() => onClickTabHandler('releases')}
							className={styles.repertoire__headerButton}
						>
							<FormattedHTMLMessage id={'rod.repertoire.releases'} />
							{releaseTotal.length !== 0 && (
								<span className={styles.repertoire__headerButtonRepertoires}>
									{releaseTotal
										.toString()
										.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1\u00A0`)}
								</span>
							)}
						</HeaderButton>
						<HeaderButton
							value={'recordings'}
							onClick={() => onClickTabHandler('recordings')}
							className={styles.repertoire__headerButton}
						>
							<FormattedHTMLMessage id={'rod.repertoire.recordery'} />
							{recordingTotal.length !== 0 && (
								<span className={styles.repertoire__headerButtonRepertoires}>
									{recordingTotal
										.toString()
										.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1\u00A0`)}
								</span>
							)}
						</HeaderButton>
						<HeaderButton
							value={'compositions'}
							onClick={() => onClickTabHandler('compositions')}
							className={styles.repertoire__headerButton}
						>
							<FormattedHTMLMessage id={'rod.repertoire.composition'} />
							{compositionTotal.length !== 0 && (
								<span className={styles.repertoire__headerButtonRepertoires}>
									{compositionTotal
										.toString()
										.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1\u00A0`)}
								</span>
							)}
						</HeaderButton>
					</TabsListUnstyled>
					<div className={styles.repertoire__main}>
						<TabPanelUnstyled value={'drafts'}>
							<RepertoireDraftSection />
						</TabPanelUnstyled>
						<TabPanelUnstyled value={'releases'}>
							<RepertoireReleaseSection
								statistics={assetStatistics['releases']}
							/>
						</TabPanelUnstyled>
						<TabPanelUnstyled value={'recordings'}>
							<RepertoireRecordingSection
								statistics={assetStatistics['recordings']}
							/>
						</TabPanelUnstyled>
						<TabPanelUnstyled value={'compositions'}>
							<RepertoireCompositionSection
								statistics={assetStatistics['compositions']}
							/>
						</TabPanelUnstyled>
					</div>
				</TabsUnstyled>
			</>
		)
	);
}

export default compose(withUI, withAuth, withRoot)(Reperoire);
