import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import s from './Statistics.module.css';
import { NavLink } from 'react-router-dom';
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';
import { withUI } from 'hocs';
import { compose } from 'recompose';

const Statistics = (props) => {
	const statistics = props.statistics;
	const [recordingsSum, setRecoringsSum] = useState('');
	const [compositionsSum, setCompositionsSum] = useState('');
	const [releaseSum, setReleaseSum] = useState('');
	const [notReady, setNotReady] = useState('');
	const [disputes, setDisputes] = useState('');
	const [balance, setBalance] = useState({});

	function isEmpty(obj) {
		for (var key in obj) {
			return false;
		}
		return true;
	}

	useEffect(() => {
		if (!isEmpty(statistics)) {
			setCompositionsSum(statistics['compositions'].total);
			setRecoringsSum(statistics['recordings'].total);
			setReleaseSum(statistics['releases'].total);
			setNotReady(statistics['combined'].not_ready);
			setDisputes(statistics['combined'].disputed);
			setBalance(statistics['balance']);
		}
	}, [statistics]);

	const {
		UIContext: { selectRepertoirePage },
	} = props;

	const repertoireRedirect = (value) => {
		switch (value) {
			case 'recordings':
				selectRepertoirePage(value);
				break;
			case 'compositions':
				selectRepertoirePage(value);
				break;
			case 'releases':
				selectRepertoirePage(value);
				break;
			default:
				break;
		}
	};

	return (
		<div className={s.container__items}>
			{props.isLoading ? (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.releases'} />
					</span>
					<div>
						<div className={s.preLoader}>
							<LoaderWhithoutProgress />
						</div>
					</div>
				</div>
			) : (
				<NavLink
					to="/repertoire/releases"
					className={s.container__item}
					onClick={() => repertoireRedirect('releases')}
				>
					<span>
						<FormattedMessage id={'rod.main_page.releases'} />
					</span>
					{releaseSum && releaseSum.length !== 0 ? (
						<span className={Number(releaseSum) !== 0 ? s.stats : null}>
							{releaseSum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</NavLink>
			)}
			{props.isLoading ? (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.phonograms'} />
					</span>
					<div>
						<div className={s.preLoader}>
							<LoaderWhithoutProgress />
						</div>
					</div>
				</div>
			) : (
				<NavLink
					to="/repertoire/recordings"
					className={s.container__item}
					onClick={() => repertoireRedirect('recordings')}
				>
					<span>
						<FormattedMessage id={'rod.main_page.phonograms'} />
					</span>
					{recordingsSum && recordingsSum.length !== 0 ? (
						<span className={Number(compositionsSum) !== 0 ? s.stats : null}>
							{recordingsSum
								.toString()
								.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</NavLink>
			)}
			{props.isLoading ? (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.compositions'} />
					</span>
					<div>
						<div className={s.preLoader}>
							<LoaderWhithoutProgress />
						</div>
					</div>
				</div>
			) : (
				<NavLink
					to="/repertoire/compositions"
					className={s.container__item}
					onClick={() => repertoireRedirect('compositions')}
				>
					<span>
						<FormattedMessage id={'rod.main_page.compositions'} />
					</span>
					{compositionsSum && compositionsSum.length !== 0 ? (
						<span className={Number(compositionsSum) !== 0 ? s.stats : null}>
							{compositionsSum
								.toString()
								.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</NavLink>
			)}
			{props.isLoading ? (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.mistakes'} />
					</span>
					<div>
						<div className={s.preLoader}>
							<LoaderWhithoutProgress />
						</div>
					</div>
				</div>
			) : (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.mistakes'} />
					</span>
					{notReady && notReady.length !== 0 ? (
						<span className={Number(notReady) !== 0 ? s.stats : null}>
							{notReady.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</div>
			)}
			{props.isLoading ? (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.disputes'} />
					</span>
					<div>
						<div className={s.preLoader}>
							<LoaderWhithoutProgress />
						</div>
					</div>
				</div>
			) : (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.disputes'} />
					</span>
					{disputes && disputes.length !== 0 ? (
						<span className={Number(disputes) !== 0 ? s.disputes : null}>
							{disputes.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</div>
			)}
			{props.isLoading ? (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.balance'} />
					</span>
					<div>
						<div className={s.preLoader}>
							<LoaderWhithoutProgress />
						</div>
					</div>
				</div>
			) : (
				<div className={s.container__item}>
					<span>
						<FormattedMessage id={'rod.main_page.balance'} />
					</span>

					<span
						className={
							balance && Object.keys(balance).length !== 0 ? s.stats : null
						}
					>
						{balance &&
						Object.keys(balance).length !== 0 &&
						balance.royalties &&
						balance.currency
							? balance.royalties.replace(
									/(\d)(?=(\d{3})+(?!\d))/g,
									`$1\u00A0`
							  ) +
							  ' ' +
							  balance.currency
							: '-'}
					</span>
				</div>
			)}
		</div>
	);
};

export default compose(withUI)(Statistics);
