/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/03/2021, 19:16
 */

import errorRules from 'constants/errorRules';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

export const showError = (field) => (error) => {
	if (typeof error === 'string') {
		switch (error) {
			case errorRules.EXISTS_IN_SIGNUP:
				return (
					<FormattedHTMLMessage id={`rod.error.exists_in_signup.${field}`} />
				);

			case errorRules.EXISTS_IN_USER:
				return (
					<FormattedHTMLMessage id={`rod.error.exists_in_user.${field}`} />
				);

			default:
				return <FormattedHTMLMessage id={`rod.error.${error}`} />;
		}
	} else {
		switch (error.rule) {
			case errorRules.USER_EXIST:
				return <FormattedHTMLMessage id={`rod.error.${field}_exist`} />;
			case errorRules.MIN_LENGTH:
			case errorRules.MIN_AGE:
			case errorRules.MAX_LENGTH:
				if (error.value) {
					return (
						<FormattedHTMLMessage
							id={`rod.error.${error.rule}`}
							values={{ num: error.value ? +error.value : null }}
						/>
					);
				} else {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_no_value`} />
					);
				}

			case errorRules.INVALID:
				if (field === 'password') {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_${field}`} />
					);
				}

				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);

			case errorRules.CYRILLIC_INVALID:
				if (field === 'password') {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_${field}`} />
					);
				}

				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);

			case errorRules.REQUIRED:
				if (field === 'society') {
					return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
				}
				if (field === 'rod.contracts.add.conditions.header') {
					return (
						<FormattedHTMLMessage id={`rod.error.contracts.add.conditions`} />
					);
				}
				// if (field.indexOf('country_id')) {
				// 	return <FormattedHTMLMessage id={`rod.error.country`} />;
				// }

				if (field === 'rod.contracts.add.conditions.header.lyric') {
					return (
						<FormattedHTMLMessage
							id={`rod.error.contracts.add.conditions_with_lyric`}
						/>
					);
				}

				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);

			case errorRules.SOMETHING_WENT_WRONG:
			case errorRules.WRONG_EMAIL_OR_PASSWORD:
				return (
					<FormattedHTMLMessage
						id={`rod.error.incorrect_username_or_password`}
					/>
				);

			default:
				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
		}
	}
};
