import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
// import { Pagination, Input } from 'material-design/components';
import { FaqAutosuggestInput } from 'components/Inputs';

import { FormattedMessage } from 'react-intl';

import styles from './FaqSearch.module.css';
import { Col, Row } from 'react-grid-system';

const FaqSearch = (props) => {
	const lang = localStorage.getItem('lang');
	const {
		UIContext: {
			showTitle,
			showBackNavi,
			hideBackNavi,
			hideTitle,
			faqSearchResult,
			parentFaq,
			setFaqSearchResult,
		},
	} = props;
	// const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [searchResult, setSearchResult] = useState(faqSearchResult);
	const history = useHistory();

	useEffect(() => {
		// history.push(`/faq/search`);
		showTitle('rod.nav.link.faq');
		showBackNavi(`${parentFaq}`);
		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// window.addEventListener('resize', () => {
	// 	setScreenWidth(window.innerWidth);
	// });

	const updateSearchResult = (result, searchText) => {
		setFaqSearchResult({
			text: searchText,
			data: Array.isArray(result) ? result : [result],
		});
		setSearchResult({
			text: searchText,
			data: Array.isArray(result) ? result : [result],
		});
		history.push(`/faq/search`);
		//----------- REDIRECT TO SEARCH PAGE ---------//
	};

	return (
		<>
			<div className={styles.mainWrapper}>
				<Row className={styles.search__wrapper}>
					<Col md={8} className={styles.columnLeft}>
						<div className={styles.wayTitle}>
							<FormattedMessage id={'rod.faq.knowledgebase'} />
							{`/`}
							<FormattedMessage id={'rod.faq.search_result'} />
						</div>
					</Col>
					<Col md={4} className={styles.columnRight}>
						<FormattedMessage id={'rod.statistic.search'}>
							{(placeholder) => (
								<FaqAutosuggestInput
									className={styles.Input}
									required
									value={{ title: '' }}
									lang={lang}
									onChange={updateSearchResult}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
				<Row className={styles.articleText}>
					<Col md={8} className={styles.columnLeft}>
						<p className={styles.title}>
							<FormattedMessage id={'rod.faq.results_for'} />
							{`"${searchResult.text}": ${searchResult.data.length}`}
						</p>
						<p className={styles.knowledgebase}>
							<FormattedMessage id={'rod.faq.knowledgebase'} />
						</p>
						{searchResult.data.map((item) => (
							<div className={styles.searchWrapper}>
								<div className={styles.searchTitle}>
									<Link
										to={`section/${item.section.slug}/article/${item.slug}`}
									>
										<div dangerouslySetInnerHTML={{ __html: item.heading }} />
									</Link>
								</div>

								<div className={styles.searchPath}>
									<FormattedMessage id={'rod.faq.knowledgebase'} />
									{`/`}
									<FormattedMessage id={'rod.faq.help'} />
									{`/`}
									<FormattedMessage id={'rod.nav.link.faq'} />
								</div>

								<div className={styles.searchData}>
									<div
										dangerouslySetInnerHTML={{ __html: item.description }}
										className={styles.articlesList}
									/>
								</div>
							</div>
						))}
					</Col>
					<Col md={4} className={styles.columnRight}></Col>
				</Row>
			</div>
		</>
	);
};
export default compose(withUI, withAuth, withRoot)(FaqSearch);
