/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/03/2021, 19:13
 */

import { withUI, withRoot } from 'hocs';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import {
	AccountData,
	SignupData,
	StatusHistory,
	AdminAccountDrafts,
} from './pages';

import { FormattedHTMLMessage } from 'react-intl';

import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import { Statistic } from 'material-design/Statistic';

import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import styles from './Account.module.css';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: '#f0f0f0',
	color: 'var(--color-dark-grey)',
	font: 'var(--gilroy-Bold-14-17)',
	flex: '0 0 25%',
	padding: '13px 0',
	border: 'none',
	textAlign: 'center',
	margin: 0,
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		'& > *:first-child': {
			textDecoration: 'line-through',
		},
	},
	'& > *:first-child': {
		marginRight: '8px',
	},
}));

function Account(props) {
	const {
		UIContext: { showBackNavi, hideBackNavi, isDisplayingStatistics },
		activeTab = 'account-data',
		id,
		isSignup = false,
	} = props;

	const history = useHistory();

	// const [defaultTab, setDefaultTab] = useState([
	// 	{
	// 		id: 1,
	// 		title: <FormattedMessage id={'rod.admin.account_data.title'} />,
	// 	},
	// 	{
	// 		id: 2,
	// 		title: <FormattedMessage id={'rod.admin.account_history.title'} />,
	// 	},
	// ]);

	const [selectedTab, setSelectedTab] = useState(activeTab);

	useEffect(() => {
		showBackNavi(props.backUrl);
		if (activeTab === 'status-history') {
			setSelectedTab('status-history');
		}

		return hideBackNavi;
		// eslint-disable-next-line
	}, []);

	const getLink = (name) => {
		switch (name) {
			case 'account-data':
				history.push(
					isSignup
						? `/admin/v2/accounts/${id}/signup/account-data`
						: `/admin/v2/accounts/${id}/account-data`
				);
				setSelectedTab('account-data');

				break;
			case 'status-history':
				history.push(
					isSignup
						? `/admin/v2/accounts/${id}/signup/status-history`
						: `/admin/v2/accounts/${id}/status-history`
				);
				setSelectedTab('status-history');

				break;

			case 'account-drafts':
				history.push(`/admin/v2/accounts/${id}/account-drafts`);
				setSelectedTab('account-drafts');

				break;
			case 'statistic':
				history.push(`/admin/v2/accounts/${id}/statistic/bydate`);
				setSelectedTab('statistic');

				break;
			default:
				break;
		}
	};

	return (
		<>
			<TabsUnstyled defaultValue={selectedTab} className={styles.tabs}>
				<TabsListUnstyled className={styles.tabs__header}>
					<HeaderButton
						className={styles.headerButton}
						value={'account-data'}
						onClick={() => getLink('account-data')}
					>
						<FormattedHTMLMessage
							id={
								isSignup
									? 'rod.admin.signup_data.title'
									: 'rod.admin.account_data.title'
							}
						/>
					</HeaderButton>
					<HeaderButton
						className={styles.headerButton}
						value={'status-history'}
						onClick={() => getLink('status-history')}
					>
						<FormattedHTMLMessage
							id={
								isSignup
									? 'rod.admin.change_history.title'
									: 'rod.admin.status_history.title'
							}
						/>
					</HeaderButton>
					{!isSignup && (
						<HeaderButton
							className={styles.headerButton}
							value={'account-drafts'}
							onClick={() => getLink('account-drafts')}
						>
							<FormattedHTMLMessage id={'rod.repertoire.drafts'} />
						</HeaderButton>
					)}

					{!isSignup && isDisplayingStatistics && (
						<HeaderButton
							className={styles.headerButton}
							value={'statistic'}
							onClick={() => getLink('statistic')}
						>
							<FormattedHTMLMessage
								id={'rod.field.admin.statistics_service.statistic_upper'}
							/>
						</HeaderButton>
					)}
				</TabsListUnstyled>
				<div
					className={
						selectedTab === 'statistic'
							? styles.tabs__statistic
							: styles.tabs__main
					}
				>
					<TabPanelUnstyled value={'account-data'}>
						{isSignup ? (
							<SignupData id={props.id} />
						) : (
							<AccountData id={props.id} />
						)}
					</TabPanelUnstyled>
					<TabPanelUnstyled value={'status-history'}>
						<StatusHistory id={props.id} isSignup={isSignup} />
					</TabPanelUnstyled>
					<TabPanelUnstyled value={'account-drafts'}>
						<AdminAccountDrafts accountId={props.id} />
					</TabPanelUnstyled>
					{isDisplayingStatistics && (
						<TabPanelUnstyled value={'statistic'}>
							<Statistic accountId={props.id} isAdmin={true} />
						</TabPanelUnstyled>
					)}
				</div>
			</TabsUnstyled>

			{/* <AccountData id={props.id} /> */}
		</>
	);
}

export default compose(withUI, withRoot)(Account);
