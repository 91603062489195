// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// UI
import { FormInput } from 'components/Form/FormInput';
import { Loading, NoData } from 'components';
import { Pagination } from 'material-design/components';
import MultipleSelectCheckmarks from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedTracks';
import MultipleSelectOutletsCheckmarks from 'material-design/components/MuiMultipleCheckedOutlets/MuiMultipleCheckedOutlets';
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';
import { FullScreenModalConfirm } from 'containers/Modal';
import { StatisticByDatesChart } from './StatisticByDatesChart';
import close from 'images/close_button.svg';

// Styles
import styles from './StatisticByDatesSection.module.css';

const StatisticByDatesSection = (props) => {
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32).toISOString(),
		end_date: new Date(new Date().getTime() - 86400000 * 2).toISOString(),
		outlets: [],
		tracks: [],
	});

	const [page, setPage] = useState(1);
	const [pageLoading, setPageLoading] = useState(true);
	const [isPaginationPressed, setIsPaginationPressed] = useState(false);
	const [helper, setHelper] = useState(false);
	const [total, setTotal] = useState(0);
	const [topTracks, setTopTracks] = useState([]);
	const [tracksIds, setTracksIds] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	const [checkedTopTrack, setCkeckedTopTrack] = useState(null);
	const [outletCodes, setOutletCodes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [outletsStatistic, setOutletsStatistic] = useState({});
	const [accountOutlets, setAccountOutlets] = useState([]);
	const [amountYAxisTitle, setAmountYAxisTitle] = useState('');
	const {
		rootContext: {
			getAccountStatisticOutlets,
			getAccountStatisticTopTracks,
			getAccountStatisticStreams,
		},

		UIContext: { showModal },
		adminAccountId,
	} = props;
	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: props.authContext.accountId;

	const [accountAuditions, setAccountAuditions] = useState([]);
	const [xAxisSize, setXAxisSize] = useState(0);
	const [isReqLive] = useState(false);

	const [isTracksFilterEmpty, setIsTracksFilterEmpty] = useState(false);
	const [isOutletsFilterEmpty, setIsOutletsFilterEmpty] = useState(false);

	const [showRangeModal, setShowRangeModal] = useState(false);

	// let STAT_CHART_CACHE_ID = []; //айди кеша для чата
	// let STAT_TOPTRACKS_CACHE_ID = []; //айди кеша топ треков

	const lang = localStorage.getItem('lang');
	let maxY = 0;

	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';

	useEffect(() => {
		const isShowedHelper = localStorage.getItem('helper');
		if (isShowedHelper === 'showed') {
			setHelper(false);
		} else {
			setHelper(true);
		}
		getAccountStatisticOutlets().then((res) => {
			res = res.data.data;
			setAccountOutlets(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getStartEndDates() {
		const isValidStartDate =
			Date.parse(statisticData.end_date) -
				Date.parse(statisticData.start_date) <
			90 * 86400000;
		const correctedStartDate =
			!isAdmin && !isValidStartDate
				? new Date(Date.parse(statisticData.end_date) - 90 * 86400000)
						.toISOString()
						.slice(0, 10)
				: statisticData.start_date;

		return [
			correctedStartDate.includes('T')
				? correctedStartDate.slice(0, correctedStartDate.indexOf('T'))
				: correctedStartDate,
			statisticData.end_date.includes('T')
				? statisticData.end_date.slice(0, statisticData.end_date.indexOf('T'))
				: statisticData.end_date,
		];
	}

	useEffect(() => {
		if (isTracksFilterEmpty || isOutletsFilterEmpty) {
			return;
		} else if (
			accountOutlets.length &&
			tracksIds.length &&
			!Object.keys(errors).length
		) {
			setPageLoading(true);
			setLoading(true);
			const outlets =
				statisticData['outlets']?.length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDates();

			// Хешируем ключ - Аутлеты сортируем по алфавиту. Соеденяем масивы данных в строку через * начиная с аутлетов
			const sortOutlets = outletsCodes.sort();
			const chartHash = `${sortOutlets.join('*')}*${startEndDates.join('*')}
      		*${isAllTracks ? 'all' : tracksIds.join('*')}*chart*${accountId}`;
			const chartReqFromCash = lscache.get(chartHash);

			if (chartReqFromCash && !isReqLive) {
				setAccountAuditions(chartReqFromCash.global);
			} else {
				getAccountStatisticStreams(
					isAdmin,
					accountId,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					tracksIds.length && !isAllTracks ? tracksIds : [],
					tracksIds.length && !isAllTracks ? false : true
				).then((res) => {
					res = res.data.data;
					if (isReqLive) {
						setAccountAuditions(res.global);
					} else {
						setCache('statisticChartCache', chartHash, res);
						setAccountAuditions(res.global);
					}
				});
			}

			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*topTracks*${accountId}*${page}`;

			const topTracksReqFromCash = lscache.get(topTracksHash);

			setPage(1);
			setIsPaginationPressed(false);

			if (topTracksReqFromCash && !isReqLive) {
				setTopTracks(topTracksReqFromCash.recordings);
				setTotal(topTracksReqFromCash.total);
				setPageLoading(false);
			} else {
				getAccountStatisticTopTracks(
					isAdmin,
					accountId,
					1,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					tracksIds.length && !isAllTracks ? tracksIds : ''
				).then((res) => {
					res = res.data.data;
					if (!res.recordings.length) {
						setAccountAuditions([]);
						setLoading(false);
					} else if (isReqLive) {
						setTopTracks(res.recordings);
						setTotal(res.total);
						setLoading(false);
						setPageLoading(false);
					} else {
						setCache('statisticTopTracksCache', topTracksHash, res);
						setTopTracks(res.recordings);
						setTotal(res.total);
						setLoading(false);
						setPageLoading(false);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountOutlets, statisticData, tracksIds]);

	useEffect(() => {
		prepareFinalData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountAuditions]);

	useEffect(() => {
		if (Object.values(outletsStatistic).length) setLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outletsStatistic]);

	// const aaa = usePrevious(page);
	useEffect(() => {
		if (
			accountOutlets.length &&
			isPaginationPressed &&
			statisticData !== null
		) {
			const outlets =
				statisticData['outlets'].length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDates();

			const sortOutlets = outletsCodes.sort();

			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*topTracks*${accountId}*${page}`;

			const topTracksReqFromCash = lscache.get(topTracksHash);

			if (topTracksReqFromCash && !isReqLive) {
				setTopTracks(topTracksReqFromCash.recordings);
				setTotal(topTracksReqFromCash.total);
				setPageLoading(false);
			} else {
				getAccountStatisticTopTracks(
					isAdmin,
					accountId,
					page,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					tracksIds.length && !isAllTracks ? tracksIds : ''
				).then((res) => {
					res = res.data.data;

					if (isReqLive) {
						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					} else {
						setCache('statisticTopTracksCache', topTracksHash, res);

						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaginationPressed, page]);

	const getDateInYYYYMMDD = (date) => {
		let dateInMs = new Date(new Date(date).getTime());
		return (
			dateInMs.getFullYear() +
			'-' +
			(dateInMs.getMonth() + 1).toString().padStart(2, '0') +
			'-' +
			dateInMs.getDate().toString().padStart(2, '0')
		);
	};

	const handleCheckedTracks = (tracks, isAll) => {
		if (!loading) {
			setIsTracksFilterEmpty(tracks.length ? false : true);
		}

		if (tracks.length !== tracksIds.length || tracksIds.length === 1) {
			setIsAllTracks(isAll);
			setTracksIds(tracks);
		}
	};
	const handleCheckedOutlets = (outlets) => {
		setIsOutletsFilterEmpty(outlets.length ? false : true);

		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData['outlets'] = outlets;
			setStatisticData({ ...statisticData });
		}
	};

	const getDataForEachOutlet = (selectedOutlet, startDate, endDate) => {
		if (startDate.includes('T')) {
			startDate = startDate.slice(0, startDate.indexOf('T'));
		}
		if (endDate.includes('T')) {
			endDate = endDate.slice(0, endDate.indexOf('T'));
		}

		let result = [];
		const rangeInDays = Math.ceil(
			(new Date(endDate).getTime() - new Date(startDate).getTime()) / 86400000
		);

		const xSize =
			rangeInDays <= 10
				? rangeInDays
				: Math.ceil(rangeInDays / Math.ceil(rangeInDays / 10));
		setXAxisSize(xSize);

		const accountAuditionsByOutlet = accountAuditions.filter(
			(element) => element.outlet === selectedOutlet.code
		);
		maxY = 0;
		for (let i = 0; i <= rangeInDays; i++) {
			let startDateinMs = new Date(
				new Date(startDate).getTime() + 86400000 * i
			);
			let y;
			let x =
				startDateinMs.getFullYear() +
				'-' +
				(startDateinMs.getMonth() + 1).toString().padStart(2, '0') +
				'-' +
				startDateinMs.getDate().toString().padStart(2, '0');

			let succesfullyCompared = accountAuditionsByOutlet.find(
				(data) => data.date === x
			);

			y = succesfullyCompared ? +succesfullyCompared.stat_count : 0;

			if (y > maxY) {
				maxY = y;
			}
			result.push({
				x: new Date(x),
				y: y,
			});
		}
		return result;
	};

	const convertDataToCumulative = (newStatForSelectedOutlets) => {
		let cumulativeVariant = {};

		const sortingValues = Object.values(newStatForSelectedOutlets).sort(
			(a, b) => a.maxY - b.maxY
		);

		for (let i = 1; i <= sortingValues.length; i++) {
			cumulativeVariant[`id${i}`] = sortingValues[i - 1];
		}
		return cumulativeVariant;
	};

	const cumulativeDataToUnitsThousMill = (cumulativeData) => {
		let divIndex;

		const maximalMaxY = Math.max(
			...Object.values(cumulativeData).map(({ maxY }) => maxY)
		);

		if (maximalMaxY <= 10000) {
			divIndex = 1;
			setAmountYAxisTitle('rod.statistic.chart-amount-units');
		} else if (maximalMaxY > 10000 && maximalMaxY <= 10000000) {
			divIndex = 1000;
			setAmountYAxisTitle('rod.statistic.chart-amount-thousands');
		} else {
			divIndex = 1000000;
			setAmountYAxisTitle('rod.statistic.chart-amount-millions');
		}

		if (divIndex !== 1) {
			for (const key in cumulativeData) {
				const element = cumulativeData[key];
				const a = element.data.map((obj) => ({
					x: obj.x,
					y: obj.y > 0 ? (obj.y = obj.y / divIndex) : obj.y,
				}));

				element.data = a;
			}
		}
	};

	const prepareFinalData = () => {
		let newStatForSelectedOutlets = {};
		let index = 1;

		const startEndDates = getStartEndDates();

		const outletsForRendering =
			statisticData['outlets'].length > 0
				? statisticData['outlets']
				: accountOutlets;
		outletsForRendering.forEach((selectedOutlet) => {
			newStatForSelectedOutlets['id' + index++] = {
				title: selectedOutlet[`title_${lang}`],
				code: selectedOutlet.code,
				data: getDataForEachOutlet(
					selectedOutlet,
					startEndDates[0],
					startEndDates[1]
				),
				maxY: maxY,
			};
		});

		const cumulativeVariant = convertDataToCumulative(
			newStatForSelectedOutlets
		);

		cumulativeDataToUnitsThousMill(cumulativeVariant);
		setOutletsStatistic({ ...cumulativeVariant });
	};

	const checkRangeInThreeMonths = (
		startDate = statisticData.start_date,
		endDate = statisticData.end_date
	) => {
		const isRangeMoreThreeMonths =
			Date.parse(endDate) - Date.parse(startDate) > 90 * 86400000;
		setShowRangeModal(isRangeMoreThreeMonths ? true : false);
		if (isRangeMoreThreeMonths)
			showModal({
				text: (
					<>
						<FormattedHTMLMessage id={'rod.repertoire.help.own_col_rev'} />
					</>
				),
			});
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (e && e.getFullYear().toString().length === 4) {
					// e = dateFormat(e);
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) >= Date.parse(MIN_DATE) &&
						Date.parse(e) < Date.parse(statisticData['end_date'])
					) {
						checkRangeInThreeMonths(e);

						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
					setStatisticData({ ...statisticData });
				}

				break;
			case 'end_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) > Date.parse(statisticData['start_date']) &&
						Date.parse(e) <= MAX_DATE
					) {
						checkRangeInThreeMonths(statisticData.start_date, e);

						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
					setStatisticData({ ...statisticData });
				}

				break;

			case 'tracks':
				statisticData[field] = e.map((item) => item.title);
				setStatisticData({ ...statisticData });
				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const handleOnBlurDateInput = (err, value) => {
		// if (err && typeof err === 'object') {
		// 	// delete errors.created_date;
		// 	setErrors({ ...err });
		// } else if (err && typeof err !== 'object') {
		// 	setErrors({
		// 		created_date: [{ rule: err }],
		// 	});
		// } else setErrors({});
	};

	const stylingDigit = (digit) => {
		let result = [];
		if (+digit > 999) {
			for (let i = 0; i < digit.length / 3; i++) {
				result.unshift(digit.slice(digit.length - 3));
				digit = digit.slice(0, digit.length - 3);
			}
			result.unshift(digit);
			return result.join(' ');
		} else return digit;
	};

	const getJSONParse = (string) => {
		try {
			return JSON.parse(string).join(', ');
		} catch (e) {
			return string;
		}
	};

	const handleCheckedTopTrack = (track) => {
		if (track) {
			setCkeckedTopTrack(track);
		} else {
			setCkeckedTopTrack(null);
		}
	};

	const startEndDates = getStartEndDates();

	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	const closeModal = () => {
		setShowRangeModal(false);
	};

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={closeHelper} src={close} alt="" />
					<span>
						<FormattedHTMLMessage id={'rod.statistic.notification'} />
					</span>
				</div>
			)}
			{outletsStatistic && (
				<div className={styles.statisticByDate__mainContent}>
					<div className={styles.inputsWrapper}>
						<div className={styles.inputOutlets}>
							<MultipleSelectOutletsCheckmarks
								handleCheckedOutlets={handleCheckedOutlets}
								lang={lang}
								className={styles.tracksCheckmarks}
							/>
							{Object.keys(errors).includes('outlets') && (
								<span className={styles.Helper}>error</span>
							)}
						</div>

						<div className={styles.inputTrack}>
							<MultipleSelectCheckmarks
								isAdmin={isAdmin}
								accountId={accountId}
								handleCheckedTracks={handleCheckedTracks}
								checkedTopTrack={checkedTopTrack}
								handleCheckedTopTrack={handleCheckedTopTrack}
								className={styles.tracksCheckmarks}
								dateFrom={startEndDates[0]}
								dateTo={startEndDates[1]}
								isLoading={setLoading}
							/>
							{Object.keys(errors).includes('tracks') && (
								<span className={styles.Helper}>error</span>
							)}
						</div>
						{showRangeModal && (
							<FullScreenModalConfirm
								title={<FormattedMessage id={'rod.modal.statistic_title'} />}
								text={<FormattedMessage id={'rod.modal.statistic_text'} />}
								confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
								onClose={closeModal}
							/>
						)}

						<div className={styles.inputStartDate}>
							<FormInput
								className={styles.date}
								type={'muiInputDate_v3'}
								defaultValue={true}
								name={'start_date'}
								handleOnBlurDateInput={handleOnBlurDateInput}
								onChange={changeField}
								minDate={Date.parse(MIN_DATE)}
								maxDate={Date.parse(statisticData.end_date)}
								errors={errors}
								data={statisticData}
								label={<FormattedMessage id={'rod.statistic.start-date'} />}
							/>
							{Object.keys(errors).includes('start_date') && (
								<div className={styles.error}>
									<span className={styles.errorText}>
										{errors['start_date'] === 'invalid date' ? (
											<FormattedMessage id={'rod.admin.invalid-date'} />
										) : (
											<FormattedMessage id={'rod.error.statistic.minDate'} />
										)}
										{errors['start_date'] === 'minDate' && MIN_DATE}
									</span>
								</div>
							)}
						</div>
						<div className={styles.inputDate}>
							<FormInput
								className={styles.date}
								type={'muiInputDate_v3'}
								defaultValue={true}
								name={'end_date'}
								handleOnBlurDateInput={handleOnBlurDateInput}
								onChange={changeField}
								minDate={Date.parse(statisticData.start_date)}
								maxDate={Date.now()}
								errors={errors}
								data={statisticData}
								label={<FormattedMessage id={'rod.statistic.end-date'} />}
							/>
							{Object.keys(errors).includes('end_date') && (
								<div className={styles.error}>
									<span className={styles.errorText}>
										{errors['end_date'] === 'invalid date' ? (
											<FormattedMessage id={'rod.admin.invalid-date'} />
										) : (
											''
										)}
									</span>
								</div>
							)}
						</div>

						<StatisticDownloadBtn
							accountId={accountId}
							statisticData={statisticData}
							accountOutlets={accountOutlets}
							getStartEndDates={getStartEndDates}
							isAdmin={isAdmin}
						/>
					</div>
					{loading ? (
						<Loading className={styles.loader} />
					) : isOutletsFilterEmpty ||
					  isTracksFilterEmpty ||
					  accountAuditions.length === 0 ? (
						<NoData />
					) : (
						outletsStatistic &&
						amountYAxisTitle && (
							<>
								<StatisticByDatesChart
									data={outletsStatistic}
									selectedOutlets={
										statisticData['outlets'].length
											? statisticData['outlets']
											: accountOutlets
									}
									xAxisSize={xAxisSize}
									amountYAxisTitle={amountYAxisTitle}
								/>
								<>
									<div className={styles.topTracksTitle}>
										<span>
											<FormattedHTMLMessage
												id={'rod.statistic.top_tracks'}
											></FormattedHTMLMessage>
										</span>
									</div>
									{pageLoading ? (
										<Loading className={styles.loader} />
									) : (
										topTracks && (
											<div style={{ marginBottom: '50px' }}>
												<table className={styles.topTracksTable}>
													<thead className={styles.topTracksTable__header}>
														<tr>
															<td> </td>
															<td>
																<FormattedHTMLMessage
																	id={'rod.statistic.top.track'}
																/>
															</td>
															<td>
																<FormattedHTMLMessage
																	id={'rod.statistic.top.performer'}
																/>
															</td>
															<td>
																<FormattedHTMLMessage
																	id={'rod.statistic.top.album'}
																/>
															</td>
															<td style={{ textAlign: 'right' }}>
																<FormattedHTMLMessage
																	id={'rod.statistic.top.streams'}
																/>
															</td>
														</tr>
													</thead>
													<tbody className={styles.topTracksTable__body}>
														{topTracks.map((item, index) => (
															<tr
																key={index}
																className={styles.tableRow}
																onClick={() => handleCheckedTopTrack(item)}
															>
																<td className={styles.topTracksTable__item}>
																	{index + 1 + (page - 1) * 10}
																</td>
																<td className={styles.topTracksTable__item}>
																	{item.raw_title}

																	<span
																		className={styles.topTracksTable__fullName}
																	>
																		{item.raw_title}
																	</span>
																</td>
																<td className={styles.topTracksTable__item}>
																	{item.performers
																		? getJSONParse(item.performers)
																		: '-'}
																</td>
																<td className={styles.topTracksTable__item}>
																	{item.release_name}
																</td>
																<td
																	className={styles.topTracksTable__item}
																	style={{ textAlign: 'right' }}
																>
																	{stylingDigit(item.streams)}
																</td>
															</tr>
														))}
													</tbody>
												</table>

												{total > 10 && (
													<Pagination
														page={page}
														total={total}
														paginate={(pageNumber) => {
															setPage(pageNumber);
															setIsPaginationPressed(true);
															setPageLoading(true);
														}}
														items_on_page={10}
													/>
												)}
											</div>
										)
									)}
								</>
							</>
						)
					)}
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(StatisticByDatesSection);
