/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 12:37
 */
// eslint-disable-next-line
const actions = {
	/* Actions */
	'rod.action.login': 'Login',
	'rod.action.register': 'Register',
	'rod.action.change_password': 'Change password',
	'rod.action.restore_password': 'Restore password',
	'rod.action.send': 'Send',
	'rod.action.upload': 'Upload',
	'rod.action.upload_more': 'Upload more',
	'rod.action.confirm': 'Confirm',
	'rod.action.sign_confirm': 'Confirm',
	'rod.action.send_contract': 'Send',
	'rod.action.sign_next': 'Sign next',
	'rod.action.no_code': 'I didn’t receive the code',
	'rod.action.send_request': 'Send request',
	'rod.action.back': 'back',
	'rod.action.back_to_list': 'back to list',
	'rod.action.back_to_release': 'back to release',
	'rod.action.create': 'Create',
	'rod.action.next': 'Next',
	'rod.action.save': 'Save',
	'rod.action.to_index_page': 'Back to main page',
	'rod.action.to_login': 'Login',
	'rod.action.sms.resend': 'resend',
	'rod.action.complete': 'Done',
	'rod.action.select_conditions': 'Choose service',
	'rod.action.goto_sign_contract': 'go to signing',
	'rod.action.goto_contract': 'Return to agreement',
	'rod.action.goto_repertoire': 'Proceed to your account',

	'rod.menu.action.add_account': 'create account',
	'rod.menu.action.settings': 'settings',
	'rod.menu.action.logout': 'logout',
	'rod.menu.action.admin': 'administration',
	'rod.menu.action.admin_signups': 'signups',
	'rod.action.create_single': 'create',
	'rod.action.download': 'Download',
	'rod.action.export_release': 'export release',
	'rod.action.close': 'Close',
	'rod.action.add': 'Add',
	'rod.account.data.confirm.create': 'create invoice',
	'rod.action.go_to_invoices': 'go to "invoices"',
	'rod.action.send_payment': 'send signed invoice',

	//================= TOASTS =======================//

	'rod.release.toast.queue_for_shipment': 'Release in the queue for shipment',
	'rod.release.toast.queue_for_takendown': 'Release in the queue for takendown',
};

export default actions;
