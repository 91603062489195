/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState, useCallback } from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
import { InputLab } from 'material-design/MuiStyled/MuiStyled';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { FormattedMessage } from 'react-intl';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import CheckedSceleton from './MuiMultipleCheckedAnimation';
import s from './MuiMultipleCheckedTracks.module.css';
import { Label } from 'components/Labels';
import debounce from 'lodash.debounce';
import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';

const MultipleSelectCheckmarks = (props) => {
	const [tracks, setTracks] = useState([]);
	const [isAllCheked, setIsAllCheked] = useState(false);
	const [selectedData, setSelectedData] = useState([]);
	const [checkedTracks, setCheckedTracks] = useState([]);
	const [checkedIds, setCheckedIds] = useState([]);
	const [isBtnOnlyShowId, setIsBtnOnlyShowId] = useState('');
	const [searchValue, setSearchValue] = useState(null);
	const [isShowSceleton, setIsShowSceleton] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(100);
	const [isReqLive, setIsReqLive] = useState(false);

	let STAT_TRACK_FOR_SELECT_CACHE_ID = []; //айди кеша для чата

	// const [page, setPage] = React.useState(1);
	// const [limit, setLimit] = React.useState(100);
	// const page = 1;
	// const limit = 100;

	const {
		// authContext: { accountId },
		rootContext: { getAccountStatisticAllTracks },
		dateFrom,
		dateTo,
		accountId,
		isAdmin,
	} = props;

	const ref = React.useRef(false);

	// React.useEffect(() => {
	// 	setIsShowSceleton(true);
	// 	getAccountStatisticAllTracks(
	// 		isAdmin,
	// 		accountId,
	// 		searchValue ? searchValue : '',
	// 		page,
	// 		limit,
	// 		dateFrom,
	// 		dateTo
	// 	).then((res) => {
	// 		res = res.data.data;
	// 		// console.log('res.recordings.length', res.recordings.length);

	// 		res.recordings.map((item) => (item.checked = true));
	// 		setTracks([{ title: 'All', checked: true }, ...res.recordings]);
	// 		setIsAllCheked(true);
	// 		setIsShowSceleton(false);
	// 		props.isLoading(false);
	// 	});
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useEffect(() => {
		props.isLoading(true);
		setIsShowSceleton(true);
		getAccountStatisticAllTracks(
			isAdmin,
			accountId,
			searchValue ? searchValue : '',
			page,
			limit,
			dateFrom,
			dateTo
		).then((res) => {
			res = res.data.data;
			// console.log('res.recordings.length', res.recordings.length);

			res.recordings.map((item) => (item.checked = true));
			setTracks([{ title: 'All', checked: true }, ...res.recordings]);
			setIsAllCheked(true);
			setIsShowSceleton(false);
			props.isLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateFrom, dateTo]);

	React.useEffect(() => {
		const data = tracks.filter((item) => item.checked);

		setSelectedData(data);
		// console.log('data', data);
		const trackIds = data
			.filter((item) => item.id || item.id === 0)
			.map((item) => item.id);

		// console.log('trackIds', trackIds);
		const ids = checkedIds.length ? checkedIds : trackIds;
		props.handleCheckedTracks(ids, isAllCheked);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tracks, checkedIds]);

	// React.useEffect(() => {
	// 	if (selectedData.length) {
	// 		const data = selectedData
	// 			.filter((item) => item.heaven11_track_id)
	// 			.map((item) => item.heaven11_track_id);
	// props.handleCheckedTracks(data);
	// 	}
	// }, [selectedData]);
	React.useEffect(() => {
		if (selectedData.length > 0) {
			ref.current = true;
		}
	}, [selectedData]);

	React.useEffect(() => {
		if (searchValue && searchValue.length >= 3) {
			setIsShowSceleton(true);
			getAccountStatisticAllTracks(
				isAdmin,
				accountId,
				searchValue,
				page,
				limit,
				dateFrom,
				dateTo
			)
				.then((res) => {
					res = res.data.data;
					const data = res.recordings.map((item) => {
						if (checkedIds.includes(item.id)) {
							item.checked = true;
						} else {
							item.checked = false;
						}
						return item;
					});
					// console.log('searched tracks:', res.recordings);
					setIsAllCheked(false);
					setTracks(data);
					setIsShowSceleton(false);
				})
				.catch((err) => console.info('error', err))
				.finally(() => setIsShowSceleton(false));
		} else if (searchValue === '') {
			//Кешируем запрос без поисковых слов

			const allTracksForCheckedHash = `${page}*${limit}
      		*${dateFrom}*${dateTo}*allTracksForChecked*${accountId}`;

			const allTracksForCheckedReqFromCash = lscache.get(
				allTracksForCheckedHash
			);

			if (allTracksForCheckedReqFromCash && !isReqLive) {
				const data = allTracksForCheckedReqFromCash.recordings.map((item) => {
					checkedIds.includes(item.id)
						? (item.checked = true)
						: (item.checked = false);
					return item;
				});

				setTracks([{ title: 'All', checked: false }, ...data]);
				setIsAllCheked(false);
			} else {
				setIsShowSceleton(true);
				getAccountStatisticAllTracks(
					isAdmin,
					accountId,
					searchValue,
					page,
					limit,
					dateFrom,
					dateTo
				)
					.then((res) => {
						res = res.data.data;

						/////
						if (isReqLive) {
							const data = res.recordings.map((item) => {
								checkedIds.includes(item.id)
									? (item.checked = true)
									: (item.checked = false);
								return item;
							});
							setTracks([{ title: 'All', checked: false }, ...data]);
							setIsAllCheked(false);
							setIsShowSceleton(false);
						} else {
							// STAT_CHART_CACHE_ID = [];
							setCache(
								'statisticTrackForSelectCache',
								allTracksForCheckedHash,
								res
							);
							// if (localStorage.getItem('STAT_TRACK_FOR_SELECT_CACHE_ID')) {
							// 	STAT_TRACK_FOR_SELECT_CACHE_ID = JSON.parse(
							// 		localStorage.getItem('STAT_TRACK_FOR_SELECT_CACHE_ID')
							// 	);
							// }

							// STAT_TRACK_FOR_SELECT_CACHE_ID.push({
							// 	hash: [allTracksForCheckedHash],
							// 	time: Date.now(),
							// });

							// localStorage.setItem(
							// 	'STAT_TRACK_FOR_SELECT_CACHE_ID',
							// 	JSON.stringify(STAT_TRACK_FOR_SELECT_CACHE_ID)
							// );
							// if (STAT_TRACK_FOR_SELECT_CACHE_ID.length <= 10) {
							// 	lscache.set(allTracksForCheckedHash, res, 30);
							// } else {
							// 	const statisticReqTimes = STAT_TRACK_FOR_SELECT_CACHE_ID.map(
							// 		(item) => item.time
							// 	);

							// 	const oldestStatisticReqTime = Math.min(...statisticReqTimes);

							// 	const oldestStatisticReqData = STAT_TRACK_FOR_SELECT_CACHE_ID.filter(
							// 		(item) => item.time === oldestStatisticReqTime
							// 	);

							// 	const newRequestsData = STAT_TRACK_FOR_SELECT_CACHE_ID.filter(
							// 		(item) => item.time !== oldestStatisticReqTime
							// 	);

							// 	localStorage.setItem(
							// 		'STAT_TRACK_FOR_SELECT_CACHE_ID',
							// 		JSON.stringify(newRequestsData)
							// 	);

							// 	lscache.remove(oldestStatisticReqData[0].hash);
							// 	lscache.set(allTracksForCheckedHash, res, 30);
							// }
						}
						// }

						// setAccountAuditions(res.global);
						/////

						const data = res.recordings.map((item) => {
							checkedIds.includes(item.id)
								? (item.checked = true)
								: (item.checked = false);
							return item;
						});
						setTracks([{ title: 'All', checked: false }, ...data]);
						setIsAllCheked(false);
						setIsShowSceleton(false);
					})
					.catch((err) => console.info('error', err))
					.finally(() => setIsShowSceleton(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	const handleChange = (e) => {
		const {
			target: { value },
		} = e;
		setSearchValue(value);
	};

	const debouncedChangeHandler = useCallback(debounce(handleChange, 500), []);

	const isCheckedIdsUsed = (track) => {
		if (track.title !== 'All') {
			if (checkedIds.includes(track.id)) {
				const trackData = checkedTracks.filter((item) => item.id !== track.id);
				const IdsData = checkedIds.filter((item) => item !== track.id);
				setCheckedTracks(trackData);
				setCheckedIds(IdsData);
			} else if (isAllCheked) {
				let ids = [];
				const trackData = tracks.filter(
					(item) => item.id !== track.id && item.title !== 'All'
				);
				tracks.forEach((item) => {
					if (item.id && item.id !== track.id) ids.push(item.id);
				});

				setCheckedTracks(trackData);
				setCheckedIds(ids);
			} else {
				setCheckedIds((prev) => [...prev, track.id]);
				setCheckedTracks((prev) => [...prev, track]);
			}
		} else if (track.title === 'All') {
			setCheckedIds([]);
			setCheckedTracks([]);
		}
	};

	const handleChaсked = (track) => {
		isCheckedIdsUsed(track);

		if (track.title === 'All') {
			if (track.checked) {
				track.checked = false;
				setIsAllCheked(false);
			} else {
				track.checked = true;
				setIsAllCheked(true);
			}

			// track.checked ? (track.checked = false) : (track.checked = true);
			const data = tracks.map((item) => {
				item.checked = track.checked;
				return item;
			});

			// setSelectedData(data);

			setTracks(data);
		} else {
			const data = tracks.map((item) => {
				if (item.title === 'All') {
					item.checked = false;
					setIsAllCheked(false);
				}
				if (item.id === track.id) {
					item.checked ? (item.checked = false) : (item.checked = true);
				}
				return item;
			});
			// setSelectedData(selectedData);
			setTracks(data);
		}

		// return checkedTracks.indexOf(name) > -1;
	};

	const debouncedCheckedHandler = useCallback(debounce(handleChaсked, 500), [
		tracks,
	]);

	const handleEarlyChaсked = (track) => {
		const chacked = checkedTracks.map((item) => {
			if (item.id === track.id) {
				item.checked ? (item.checked = false) : (item.checked = true);
			}
			return item;
		});

		const data = chacked.filter((item) => item.checked);
		const IdsData = checkedIds.filter((item) => item !== track.id);
		setCheckedIds(IdsData);
		setCheckedTracks(data);
	};

	const handleMouseOver = (index) => {
		if (index) {
			setIsBtnOnlyShowId(index);
		}
	};

	const handleMouseLeave = () => {
		setIsBtnOnlyShowId('');
	};

	const handleClickOnly = (track) => {
		const data = tracks.map((item) => {
			item.title === track.title
				? (item.checked = true)
				: (item.checked = false);

			return item;
		});
		// setSelectedData(track);
		setIsAllCheked(false);
		setTracks(data);
		setCheckedTracks([track]);
		setCheckedIds([track.id]);
		if (props.checkedTopTrack) {
			props.handleCheckedTopTrack();
		}
	};

	React.useEffect(() => {
		if (props.checkedTopTrack) {
			props.checkedTopTrack.title = props.checkedTopTrack.raw_title;
			props.checkedTopTrack.checked = true;
			handleClickOnly(props.checkedTopTrack);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.checkedTopTrack]);

	return (
		<>
			<div>
				<FormControl
					variant="standard"
					sx={{ m: 1 }}
					className={s.formControl}
					error={!selectedData.length && ref.current && !checkedIds.length}
				>
					{/* <InputLab id="demo-multiple-checkbox-label">
						<FormattedMessage id={'rod.statistic.tracks'} />
					</InputLab> */}
					<Input id="demo-multiple-checkbox-label">
						<FormattedMessage id={'rod.statistic.tracks'} />
					</Input>
					<SelectCustom
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						// value={checkedTracks}
						value={
							tracks.length === 10 || checkedTracks.length
								? checkedTracks
								: selectedData
						}
						// onChange={handleChange}
						// input={<OutlinedInput label="Tag" />}
						renderValue={(selected) => {
							// return (
							if (isShowSceleton) return '';

							if (selected[0]?.title === 'All' && tracks[0]?.title === 'All') {
								return (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{selected.length - 1 + '/' + (tracks.length - 1)}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								);
							}

							if (checkedIds.length && !tracks.length) {
								return (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{checkedIds.length + '/' + tracks.length}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								);
							}

							if (checkedIds.length) {
								return tracks[0]?.title === 'All' ? (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{checkedIds.length + '/' + (tracks.length - 1)}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								) : (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{checkedIds.length + '/' + tracks.length}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								);
							}

							// 	if (isShowSceleton && !searchValue) {
							// 		return tracks.length <= 10 ? (
							// 			<>
							// 				<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 				:&nbsp;
							// 				{selected.length + '/' + tracks.length}
							// 				&nbsp;
							// 				<FormattedMessage
							// 					id={'rod.statistic.tracks-selected-tracks'}
							// 				/>
							// 			</>
							// 		) : (
							// 			<>
							// 				{!tracks.length ? (
							// 					<>
							// 						<FormattedMessage
							// 							id={'rod.statistic.tracks-selected'}
							// 						/>
							// 						:&nbsp;
							// 						{checkedIds.length + '/' + checkedIds.length}
							// 						&nbsp;
							// 						<FormattedMessage
							// 							id={'rod.statistic.tracks-selected-tracks'}
							// 						/>
							// 					</>
							// 				) : (
							// 					<>
							// 						<FormattedMessage
							// 							id={'rod.statistic.tracks-selected'}
							// 						/>
							// 						:&nbsp;
							// 						{selected.length - 1 + '/' + tracks.length - 1}
							// 						&nbsp;
							// 						<FormattedMessage
							// 							id={'rod.statistic.tracks-selected-tracks'}
							// 						/>
							// 					</>
							// 				)}
							// 			</>
							// 		);
							// 	}

							// if (checkedIds.length) {
							// 	// handleCheckedValuesLength(selected);

							// 	return tracks.length <= 10 ? (
							// 		<>
							// 			<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 			:&nbsp;
							// 			{checkedIds.length + '/' + tracks.length}
							// 			&nbsp;
							// 			<FormattedMessage
							// 				id={'rod.statistic.tracks-selected-tracks'}
							// 			/>
							// 		</>
							// 	) : tracks.length ? (
							// 		<>
							// 			<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 			:&nbsp;
							// 			{checkedIds.length + '/' + (tracks.length - 1)}
							// 			&nbsp;
							// 			<FormattedMessage
							// 				id={'rod.statistic.tracks-selected-tracks'}
							// 			/>
							// 		</>
							// 	) : (
							// 		<>
							// 			<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 			:&nbsp;
							// 			{checkedIds.length + '/' + checkedIds.length}
							// 			&nbsp;
							// 			<FormattedMessage
							// 				id={'rod.statistic.tracks-selected-tracks'}
							// 			/>
							// 		</>
							// 	);
							// } else {
							// 	return (
							// 		<>
							// 			<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 			:&nbsp;
							// 			{selected.length - 1 + '/' + (tracks.length - 1)}
							// 			&nbsp;
							// 			<FormattedMessage
							// 				id={'rod.statistic.tracks-selected-tracks'}
							// 			/>
							// 		</>
							// 	);
							// }

							// );
							// if (!searchValue) {
							// 	return selected.length <= tracks.length - 1 ? (
							// 		<>
							// 			<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 			:&nbsp;
							// 			{/* {selected.length}/{tracks.length - 1} */}
							// 			{checkedIds.length
							// 				? checkedIds.length + '/' + (tracks.length - 1)
							// 				: ''}
							// 			&nbsp;
							// 			<FormattedMessage
							// 				id={'rod.statistic.tracks-selected-tracks'}
							// 			/>
							// 		</>
							// 	) : (
							// 		<>
							// 			<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 			:&nbsp;
							// 			{/* {selected.length - 1}/{tracks.length - 1}&nbsp; */}
							// 			{/* {selected.length - 1}/{tracks.length - 1}&nbsp; */}
							// 			ok2
							// 			<FormattedMessage
							// 				id={'rod.statistic.tracks-selected-tracks'}
							// 			/>
							// 		</>
							// 	);
							// } else
							// 	return (
							// 		<>
							// 			<FormattedMessage id={'rod.statistic.tracks-selected'} />
							// 			:&nbsp;
							// 			{/* {selected.length}/{tracks.length}&nbsp; */}
							// 			ok3
							// 			<FormattedMessage
							// 				id={'rod.statistic.tracks-selected-tracks'}
							// 			/>
							// 		</>
							// 	);
						}}
						// renderValue={(selected) => handleRenderValue(selected)}

						// MenuProps={MenuProps}
						className={s.selectCustom}
					>
						<FormattedMessage id={'rod.statistic.search-title'}>
							{(label) => (
								<TextFieldInput
									className={s.search}
									autoFocus={false}
									initialValue={searchValue ? searchValue : ''}
									onChange={debouncedChangeHandler}
									label={label}
									variant="standard"
								/>
							)}
						</FormattedMessage>
						<div className={s.wrapper}>
							{/* {console.log('======tracks====')} */}

							{tracks.length > 0 && !isShowSceleton
								? tracks.map((track, index) => (
										<MenuItem
											className={s.menuItem}
											// key={track.id}
											key={index}
											value={track.title}
											//TODO- Restore IT !
											onMouseOver={() => handleMouseOver(index)}
											onMouseLeave={handleMouseLeave}
										>
											<CheckboxBlack
												checked={track.checked}
												onChange={() => debouncedCheckedHandler(track)}
											/>
											{/* <ListItemText
											primary={
												track.title === 'All' ? (
													<FormattedMessage id={'rod.statistic.search-all'} />
												) : (
													track.title
												)
											}
									/> */}
											<Label className={s.menuItem__Label}>
												{track.title === 'All' ? (
													<FormattedMessage id={'rod.statistic.search-all'} />
												) : (
													`${track.title} - ${JSON.parse(track.performers)}`
												)}
											</Label>

											{isBtnOnlyShowId && isBtnOnlyShowId === index && (
												<button
													className={s.btnOnly}
													onClick={() => handleClickOnly(track)}
												>
													Only
												</button>
											)}
										</MenuItem>
								  ))
								: isShowSceleton && <CheckedSceleton />}
							{!tracks.length && !isShowSceleton && (
								<p className={s.notFound}>
									<FormattedMessage id={'rod.statistic.search-not-found'} />
								</p>
							)}
							<div>
								{checkedTracks.length > 0 && !isShowSceleton && (
									<>
										<p className={s.checkedTracks}>
											<FormattedMessage id={'rod.statistic.checked-tracks'} />
										</p>
										{checkedTracks.map((track, index) => (
											<MenuItem
												className={s.menuItem}
												// key={track.id}
												key={index}
												value={track.title}
												//TODO- Restore IT !
												onMouseOver={() => handleMouseOver(index)}
												onMouseLeave={handleMouseLeave}
											>
												<CheckboxBlack
													checked={track.checked}
													onChange={() => handleEarlyChaсked(track)}
												/>

												<Label>
													{track.title === 'All' ? (
														<FormattedMessage id={'rod.statistic.search-all'} />
													) : (
														track.title
													)}
												</Label>

												{isBtnOnlyShowId && isBtnOnlyShowId === index && (
													<button
														className={s.btnOnly}
														onClick={() => handleClickOnly(track)}
													>
														Only
													</button>
												)}
											</MenuItem>
										))}
									</>
								)}
							</div>
						</div>
					</SelectCustom>
				</FormControl>
			</div>
		</>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectCheckmarks);
