// Core
import { useContext, useEffect, useState, useCallback } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import DOMPurify from 'dompurify';
import { withUI } from 'hocs';
import { compose } from 'recompose';

// UI
import { Loading } from 'components';
import { Pagination, Input } from 'material-design/components';
import { RubricLang } from '../RubricLang';
import plusImg from 'images/plus.svg';

// Context
import { RootContext } from 'contexts/RootContext';

// Styles
import styles from './ArticlesPage.module.css';

const ArticlesPage = (props) => {
	const { getAdminArticles } = useContext(RootContext);
	const rubricLang = localStorage.getItem('rubric_lang');

	const [lang, setLang] = useState(rubricLang || 'ru');
	const [articles, setArticles] = useState([]);

	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');

	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noData, setNoData] = useState(false);

	useEffect(() => {
		setLoading(true);
		getAdminArticles(page, searchValue, lang)
			.then((res) => {
				if (res.data.length) {
					setTotal(res.total);

					const data = res.data.map((item) => {
						const timeCreated = item.created_at.match(/\d{1,2}:\d{2}/)[0];
						const timeUpdated = item.updated_at.match(/\d{1,2}:\d{2}/)[0];
						const dateCreated = item.created_at
							.slice(0, item.created_at.indexOf('T'))
							.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
						item.created_at = timeCreated + ', ' + dateCreated;
						const dateUpdated = item.updated_at
							.slice(0, item.updated_at.indexOf('T'))
							.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
						item.updated_at = timeUpdated + ', ' + dateUpdated;

						return item;
					});
					setNoData(false);
					setArticles(data);
				} else {
					setNoData(true);
				}
			})
			.catch((err) => console.error('Error:', err))
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, lang, searchValue]);

	const handleOnChange = (value) => {
		setSearchValue(value);
		if (!value) setPage(1);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedChangeHandler = useCallback(debounce(handleOnChange, 500), []);

	const htmlParsing = (text) => {
		const securedText = DOMPurify.sanitize(text, {
			ADD_ATTR: ['target'],
		});
		const floatingElement = new DOMParser().parseFromString(
			securedText,
			'text/html'
		);

		return floatingElement.firstChild.lastChild.textContent;
	};

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.search}>
				<FormattedHTMLMessage id={'rod.admin.articles.search'}>
					{(placeholder) => (
						<Input
							handleOnChange={debouncedChangeHandler}
							lowerCasePlaceholder={true}
							placeholder={placeholder}
						/>
					)}
				</FormattedHTMLMessage>
			</div>
			<div className={styles.sectionTitleWrapper}>
				<div className={styles.sectionTitle}>
					<FormattedHTMLMessage id={'rod.admin.articles'} />
				</div>
				<div className={styles.topBtnsWrapper}>
					<Link
						className={styles.rubricSettingsLink}
						to="/admin/v2/articles/rubric-settings"
					>
						<FormattedHTMLMessage id={'rod.admin.articles.rubric_settings'} />
					</Link>

					<Link
						className={styles.createArticleBtn}
						to="/admin/v2/articles/create"
					>
						<img src={plusImg} alt="add" className={styles.iconPlus} />

						<FormattedHTMLMessage id={'rod.admin.articles_add'} />
					</Link>
				</div>
			</div>
			{!noData && (
				<div>
					<RubricLang className={styles.lang} lang={lang} setLang={setLang} />
				</div>
			)}

			{loading ? (
				<div>
					<Loading className={styles.loader} />
				</div>
			) : noData ? (
				<div className={styles.noData}>
					<FormattedMessage id={'rod.admin.no-data'} />
				</div>
			) : (
				<>
					<div className={styles.RepertoireSongInfo__mainInfoTable}>
						<Row className={styles.row_header}>
							<Col width={'2%'}></Col>
							<Col width={'25%'}>
								<FormattedHTMLMessage id={'rod.admin.notifications.title'} />
							</Col>
							<Col width={'25%'}>
								<FormattedHTMLMessage id={'rod.admin.notifications.text'} />
							</Col>
							<Col width={'14%'}>
								<FormattedHTMLMessage id={'rod.admin.articles.rubric'} />
							</Col>
							<Col width={'17%'}>
								<FormattedHTMLMessage id={'rod.admin.articles.date'} />
							</Col>
							<Col width={'17%'}>
								<FormattedHTMLMessage
									id={'rod.admin.articles.last_corrected_date'}
								/>
							</Col>
						</Row>
						<div className={styles.RepertoireMainInfoTable__body}>
							<ul>
								{articles.map((item, index) => (
									<li className={styles.articlesListItem}>
										<Row className={styles.row_data}>
											<Link
												className={styles.modifyArticle}
												to={`/admin/v2/articles/edit/${item.id}`}
											>
												<Row className={styles.row_title}>
													<Col width={'2%'} className={styles.row_title_number}>
														{page * 10 - 10 + index + 1}
													</Col>
													<Col width={'25%'}>{item.heading}</Col>
													<Col width={'25%'}>
														<div className={styles.itemTextWrapper}>
															<div className={styles.innerBlockText}>
																{htmlParsing(item.description)}
															</div>
														</div>
													</Col>
													<Col width={'14%'}>
														{item.section ? item.section.title : '-'}
													</Col>
													<Col width={'17%'}>{item.created_at}</Col>
													<Col width={'16%'}>{item.updated_at}</Col>
												</Row>
											</Link>
										</Row>
									</li>
								))}
							</ul>
						</div>
					</div>
					{total > 10 && !loading && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => {
								setPage(pageNumber);
							}}
							items_on_page={10}
						/>
					)}
				</>
			)}
		</div>
	);
};
export default compose(withUI)(ArticlesPage);
