// Core
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Icons
import { Cd } from 'material-design/svg';

// Styles
import styles from '../RepertoireDraftSection.module.css';
import { useLocation } from 'react-router-dom';

const ContentEmpty = ({ upload }) => {
	const { pathname } = useLocation();

	return (
		<div className={styles.repertoire__mainContentEmpty}>
			<Cd width={64} />
			{upload.drafts && (
				<span>
					{process.env.REACT_APP_CLIENT === 'dgtal' ? (
						<FormattedMessage
							id={'rod.composition.empty'}
							values={{
								a: (msg) => (
									<Link
										to={{
											pathname: '/compositions/create',
											state: { url: pathname },
										}}
									>
										{msg}
									</Link>
								),
							}}
						/>
					) : (
						<FormattedMessage
							id={'rod.release.empty'}
							values={{
								a: (msg) => (
									<Link
										to={{
											pathname: '/release/create',
											state: { url: pathname },
										}}
									>
										{msg}
									</Link>
								),
							}}
						/>
					)}
				</span>
			)}
		</div>
	);
};

export default ContentEmpty;
