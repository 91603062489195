// Core
import React from 'react';
import parse from 'html-react-parser';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// UI
import { Bullet } from 'components/Bullet';
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';
import { InfoButton } from 'components/Buttons';

// Types
import documentTypes from 'constants/documentTypes';

// Styles
import styles from '../Documents.module.css';

const Sole = ({
	documentsPassportMessage,
	documentsPeMessage,
	showModal,
	files,
	moderationStatus,
	handleFileUpload,
	handleFileRemove,
	children,
}) => {
	return (
		<Row nogutter nowrap className={styles.Row}>
			<Col sm={6} offset={{ sm: 1 }} className={styles.Left}>
				<div className={styles.Form}>
					<div className={styles.Header}>
						<InfoButton
							onClick={showModal({
								text: parse(documentsPassportMessage),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.passport.title'}
									/>
								}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
					</div>
					<div className={styles.FormLine}>
						<div className={styles.Subheader}>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.passport.photo_page'}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>
						</div>
						<DropZone
							onUpload={handleFileUpload(documentTypes.PASSPORT_PHOTO_PAGE)}
							value={files[documentTypes.PASSPORT_PHOTO_PAGE]}
							onRemove={handleFileRemove(
								files[documentTypes.PASSPORT_PHOTO_PAGE]
							)}
							moderation={moderationStatus[documentTypes.PASSPORT_PHOTO_PAGE]}
							kb
						/>
					</div>
					<div className={styles.FormLine}>
						<div className={styles.Subheader}>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.passport.address_page'}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>
						</div>
						<DropZone
							onUpload={handleFileUpload(
								documentTypes.PASSPORT_REGISTRATION_PAGE
							)}
							value={files[documentTypes.PASSPORT_REGISTRATION_PAGE]}
							onRemove={handleFileRemove(
								files[documentTypes.PASSPORT_REGISTRATION_PAGE]
							)}
							moderation={
								moderationStatus[documentTypes.PASSPORT_REGISTRATION_PAGE]
							}
							kb
						/>
					</div>
					<div className={styles.Header}>
						<InfoButton
							onClick={showModal({
								text: parse(documentsPeMessage),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.pe.title'}
									/>
								}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
					</div>
					<div className={styles.FormLine}>
						<div className={styles.Subheader}>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.pe.registration'}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>
						</div>
						<DropZone
							onUpload={handleFileUpload(
								documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE
							)}
							value={
								files[documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE]
							}
							onRemove={handleFileRemove(
								files[documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE]
							)}
							moderation={
								moderationStatus[
									documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE
								]
							}
							kb
						/>
					</div>
				</div>
			</Col>
			<Col sm={5} className={styles.Right}>
				<div>
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.documents.list_title'}
							/>
						}
						font="--gilroy-Medium-32"
						black
						className={styles.Header}
					/>
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.documents.passport.title'}
							/>
						}
						font="--gilroy-Medium-24"
						black
						className={styles.Header}
					/>
					<ul>
						<li>
							<Bullet
								outline={!files[documentTypes.PASSPORT_PHOTO_PAGE]}
								text={
									<FormattedMessage
										id={'rod.account.create.documents.passport.photo_page'}
									/>
								}
							/>
						</li>
						<li>
							<Bullet
								outline={!files[documentTypes.PASSPORT_REGISTRATION_PAGE]}
								text={
									<FormattedMessage
										id={'rod.account.create.documents.passport.address_page'}
									/>
								}
							/>
						</li>
					</ul>
					<Label
						text={
							<FormattedMessage id={'rod.account.create.documents.pe.title'} />
						}
						font="--gilroy-Medium-24"
						black
						className={styles.Header}
					/>

					<ul>
						<li>
							<Bullet
								outline={
									!files[
										documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE
									]
								}
								text={
									<FormattedMessage
										id={'rod.account.create.documents.pe.registration'}
									/>
								}
							/>
						</li>
					</ul>
					{children}
				</div>
			</Col>
		</Row>
	);
};

export default Sole;
