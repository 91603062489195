import * as React from 'react';
import { InputLab } from 'material-design/MuiStyled/MuiStyled';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import s from './MuiMultipleCheckedOutlets.module.css';
import { Label } from 'components/Labels';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';

const MultipleSelectOutletsCheckmarks = (props) => {
	const [outlets, setOutlets] = React.useState([]);
	const [selectedData, setSelectedData] = React.useState([]);
	// const [isBtnOnlyShowId, setIsBtnOnlyShowId] = React.useState('');

	const {
		rootContext: { getAccountStatisticOutlets },
	} = props;

	const ref = React.useRef(false);

	React.useEffect(() => {
		getAccountStatisticOutlets().then((res) => {
			res = res.data.data;
			res.map((item) => (item.checked = true));
			setOutlets([{ raw_title: 'All', checked: true }, ...res]);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (outlets.length) {
			const data = outlets.filter((item) => item.checked && item.code);
			setSelectedData(data);
			props.handleCheckedOutlets(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outlets]);

	React.useEffect(() => {
		if (selectedData.length > 0) {
			ref.current = true;
		}
	}, [selectedData]);

	const handleCheсked = (outlet) => {
		if (outlet.raw_title === 'All') {
			outlet.checked ? (outlet.checked = false) : (outlet.checked = true);
			const data = outlets.map((item) => {
				item.checked = outlet.checked;
				return item;
			});

			setOutlets(data);
		} else {
			const data = outlets.map((item) => {
				if (item.raw_title === 'All') item.checked = false;
				if (item.code === outlet.code) {
					item.checked ? (item.checked = false) : (item.checked = true);
				}

				return item;
			});

			setOutlets(data);
		}
	};

	const debouncedChangeHandler = useCallback(debounce(handleCheсked, 500), [
		outlets,
	]);

	// const handleClickOnly = (outlet) => {
	// 	const data = outlets.map((item) => {
	// 		item.raw_title === outlet.raw_title
	// 			? (item.checked = true)
	// 			: (item.checked = false);

	// 		return item;
	// 	});
	// 	setOutlets(data);
	// };

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1 }}
				className={s.formControl}
				error={!selectedData.length && ref.current}
			>
				{/* <InputLab id="demo-multiple-checkbox-label">
					<FormattedMessage id={'rod.statistic.outlets'} />
				</InputLab> */}
				<Input id="demo-multiple-checkbox-label">
					<FormattedMessage id={'rod.statistic.outlets'} />
				</Input>
				<SelectCustom
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					// value={checkedTracks}
					value={selectedData}
					// onChange={handleChange}
					// input={<OutlinedInput label="Tag" />}
					renderValue={(selected) => {
						return selected.length <= outlets.length - 1 ? (
							<>
								<FormattedMessage id={'rod.statistic.tracks-selected'} />
								:&nbsp;
								{selected.length}/{outlets.length - 1}&nbsp;
								<FormattedMessage id={'rod.statistic.outlets'} />
							</>
						) : (
							<>
								<FormattedMessage id={'rod.statistic.tracks-selected'} />
								:&nbsp;
								{selected.length - 1}/{outlets.length - 1}&nbsp;
								<FormattedMessage id={'rod.statistic.outlets'} />
							</>
						);
					}}
					className={s.selectCustom}
				>
					<div className={s.wrapper}>
						{outlets.map((outlet, index) => (
							<MenuItem
								className={s.menuItem}
								/* key={outlet.code} */
								key={index}
								value={outlet.raw_title}
							>
								<CheckboxBlack
									checked={outlet.checked}
									onChange={() => debouncedChangeHandler(outlet)}
								/>
								<Label>
									{outlet.raw_title === 'All' ? (
										<FormattedMessage id={'rod.statistic.search-all'} />
									) : (
										outlet[`title_${props.lang}`]
									)}
								</Label>

								{/* {isBtnOnlyShowId && isBtnOnlyShowId === index && (
									<button
										className={s.btnOnly}
										onClick={() => handleClickOnly(outlet)}
									>
										Only
									</button>
								)} */}
							</MenuItem>
						))}
					</div>
				</SelectCustom>
			</FormControl>
		</div>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectOutletsCheckmarks);
