/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 06/05/2020, 21:24
 */

import { Loading } from 'components';
import { withAuth, withUI } from 'hocs';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
// import PropTypes from 'prop-types';
// import styles from './Logout.module.css';
class Logout extends PureComponent {
	componentDidMount() {
		const {
			authContext: { logout },
		} = this.props;
		const token = localStorage.getItem('token');
		if (token) {
			logout();
		}
	}

	render() {
		// const {} = this.props;
		//const {} = this.state;
		return <Loading non_margin={true} />;
	}
}

Logout.propTypes = {};

Logout.defaultProps = {};

export default compose(withAuth, withUI)(Logout);
