// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Document, Page } from 'react-pdf';

// UI
import { Col, Container, Row } from 'react-grid-system';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { Label } from 'components/Labels/Label';
import fileImg from 'images/file.svg';

// Styles
import styles from './DocumentToSign.module.css';

class DocumentToSign extends PureComponent {
	constructor(props) {
		super(props);
		this.form = React.createRef();
		this.state = {
			formWidth: 600,
			numPages: 0,
			agreed1: false,
			agreed2: false,
			contacts: {},
			errors: {},
			isSubmitPressed: false,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.update);
		this.update();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.update);
	}

	update = () => {
		this.setState({
			formWidth: this.form.current.getBoundingClientRect().width - 100,
		});
	};

	loadSuccess = ({ numPages }) => {
		this.setState({
			numPages,
		});
	};

	agreed = (field) => () => {
		this.setState({
			[field]: !this.state[field],
		});
	};

	getContractType = (documentTitle) => {
		const title = documentTitle?.replace('Payment', '');
		return (<FormattedMessage
			id='rod.account.invoice-sign.invoice_number'
			values={{ value: title }}
		/>);
	};

	render() {
		const { numPages, formWidth, checkbox1, checkbox2, } = this.state;
		const { generated_url, handleDocConfirm, documentTitle } = this.props;
		const typeTitle = this.getContractType(documentTitle);

		if (checkbox1 && checkbox2) {
			handleDocConfirm(true);
		}

		if ((checkbox1 || checkbox2) && !(checkbox1 && checkbox2)) {
			handleDocConfirm(false);
		}

		return (
			<>
				<div className={styles.Header}>
					<img src={fileImg} alt="" />
					<Label
						font="--gilroy-Medium-40"
						black
						text={typeTitle}
					/>
				</div>
				<Container fluid className={styles.Page}>
					<div className={styles.Form} ref={this.form}>
						<Document
							file={generated_url.url}
							className={styles.Document}
							onLoadSuccess={this.loadSuccess}
						>
							{Array.from(new Array(numPages), (el, index) => (
								<Page
									pageNumber={index + 1}
									key={`page_${index + 1}`}
									className={styles.DocPage}
									width={formWidth}
									renderTextLayer={false}
								>
								</Page>
							))}
						</Document>
						<Row>
							<Col sm={10} offset={{ sm: 1 }}>
								<div className={styles.Controls}>
									<Checkbox
										className={styles.Checkbox}
										onChange={this.agreed('checkbox1')}
										checked={checkbox1}
										required
									>
										<FormattedMessage id={'rod.field.payments.agreed1'} />
									</Checkbox>
									<Checkbox
										className={styles.Checkbox}
										checked={checkbox2}
										onChange={this.agreed('checkbox2')}
										required
									>
										<FormattedMessage id={'rod.field.payments.agreed2'} />
									</Checkbox>
									{this.props.children}
									<div className={styles.btnWrapper}>
										{Object.keys(this.state.errors).includes('code') && (
											<span className={styles.Helper}>
												{<FormattedMessage id={`rod.error.code.forbidden`} />}
											</span>
										)}
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</>
		);
	}
}

DocumentToSign.propTypes = {};
DocumentToSign.defaultProps = {};
export default DocumentToSign;
