// Core
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withAuth, withRoot } from 'hocs';
import lscache from 'lscache';

// Services & Helpers
import { accounts } from 'services';
import { compose } from 'recompose';
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { BottomNavi, InfoHelper } from 'components';
import Board from './Board/Board';
import Loader from 'components/Loader';
import { DropZone } from 'components/Upload/DropZone';
import { UploadPhonogramModal } from 'material-design/modals';
import { FullScreenModalConfirm } from 'containers/Modal';

// Icons
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import remove from 'images/remove.svg';
import positionUp from 'images/positionUp.svg';
import positionDown from 'images/positionDown.svg';

// Context
import { UIContext } from 'contexts/UIContext';

// Styles
import s from './File.module.css';

function Recording(props) {
	const [toogleMenu, setToogleMenu] = useState('');
	const [recordings, setRecordings] = useState([]);
	const [errors, setErrors] = useState({});
	const [errorsValue, setErrorsValue] = useState('');
	const [serverError, setServerError] = useState(false);
	const [formatError, setFormstError] = useState({});
	const [
		canUploadMultipleComposition,
		setCanUploadMultipleComposition,
	] = useState(true);
	const [releaseType, setReleaseType] = useState('');
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [dropFilesAccepted, setDropFilesAccepted] = useState([]);
	const [audio] = useState(new Audio(''));
	const [arraySort, setArraySort] = useState([]);
	const [currentCard, setCurrentCard] = useState(null);
	const [uploadProgressPercents, setUploadProgressPercents] = useState([]);
	const [isAllFilesDownloaded, setIsAllFilesDownloaded] = useState(false);
	const [isReqLive] = useState(false);
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [
		recordingsReadyForCheckWithType,
		setRecordingsReadyForCheckWithType,
	] = useState(false);

	let PROGRESS_DATA = [];
	let FILE_COUNT = [];

	const { play, handlePlay, audioId, handlePlayStop } = useContext(UIContext);

	const maxSize = useMemo(() => 1024 * 1024 * 1024 * 3, []);

	const releaseId = localStorage.getItem('releaseId');
	const history = useHistory();

	function handleToogle(index) {
		toogleMenu === index ? setToogleMenu('') : setToogleMenu(index);
	}

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			history.push(`/release/${releaseId}/release`);
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const nextHandler = () => {
		const isCountOk = checkRecorgingsCountAcordingReleaseType();

		if (typeof props.onChange === 'function' && isCountOk) {
			audio.currentTime = 0;
			audio.pause();
			props.onChange();
		}
	};

	const modalOpen = (active) => {};

	const getReleaseTypes = () => {
		const lang = localStorage.getItem('lang');
		const releaseTypesCache = lscache.get('releaseTypesCache');

		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			releaseTypesCache.data.map((item) => {
				if (item.id === props.releaseTypeId) {
					setReleaseType(item.title);
					setReleaseTypeId(item.id);
					item.is_compound === 0
						? setCanUploadMultipleComposition(false)
						: setCanUploadMultipleComposition(true);
				}
				return true;
			});
			return releaseTypesCache;
		} else {
			return accounts
				.getReleaseTypes(lang)
				.then((res) => {
					res = res.data.data;
					setCache('releaseTypes', 'releaseTypesCache', res);
					res.map((item) => {
						if (item.id === props.releaseTypeId) {
							setReleaseType(item.title);
							setReleaseTypeId(item.id);
							item.is_compound === 0
								? setCanUploadMultipleComposition(false)
								: setCanUploadMultipleComposition(true);
						}
						return true;
					});
					return Promise.resolve(res);
				})
				.catch((err) => {
					console.error(
						'GetReleaseTypes error in ReleaseCreation - File: ',
						err
					);
					return Promise.reject();
				});
		}
	};

	const getDropFilesCount = (files) => {
		setDropFilesAccepted(files);
	};

	const handleFileUpload = (files) => {
		setErrors({});

		if (!canUploadMultipleComposition && dropFilesAccepted.length > 1) {
			return setErrors({
				upload: [{ rule: 'upload_only_one_track' }],
			});
		}

		if (!canUploadMultipleComposition && recordings.length) {
			return setErrors({
				upload: [{ rule: 'upload_only_one_track' }],
			});
		}

		if (canUploadMultipleComposition && recordings.length === '500') {
			return setErrors({
				upload: [{ rule: 'max_upload_count' }],
			});
		}
		if (serverError) {
			const uploadWithOutError = recordings.filter(
				(item) => item.done !== false
			);

			setRecordings([...uploadWithOutError]);
			setServerError(false);
		}
		const sumAudios = recordings.reduce(
			(acc, item) => (item.file ? acc + item.file.size : acc + item?.size),
			0
		);

		const emptySize = maxSize - sumAudios;

		const allowedMimeTypes = [
			'audio/wav',
			'audio/flac',
			'audio/x-flac',
			'audio/x-wav',
		];

		files.forEach((file, index) => {
			if (allowedMimeTypes.includes(file.type)) {
				if (
					!recordings.find(
						(item) =>
							(item.file ? item.file.client_name : item?.name) === file.name
					) &&
					emptySize - file.size >= 0
				) {
					file.done = false;
					// file.sort = index + 1;
					setRecordings((prev) => [...prev, file]);

					const positionNum = index === 0 ? 1 : index + 1;

					const sortNum = recordings.length
						? recordings.length + positionNum
						: index + 1;

					const fd = new FormData();
					fd.append('file', file);
					fd.append('sort', sortNum);

					console.info('file_name', file.name);
					console.log('file_size', file.size);
					PROGRESS_DATA.push({
						key: file.name,
						value: 0,
					});

					const config = {
						onUploadProgress: (progressEvent) => {
							const percentCompleted = Math.round(
								(progressEvent.loaded * 100) / progressEvent.total
							);

							if (+progressEvent.loaded !== +progressEvent.total) {
								if (PROGRESS_DATA.length) {
									const checkFile = PROGRESS_DATA.filter(
										(item) => item.key === file.name
									);

									if (checkFile.length) {
										const data = PROGRESS_DATA.map((item) => {
											if (item.key === file.name) {
												item.value = percentCompleted;
											}

											return {
												key: item.key,
												value: item.value,
											};
										});

										PROGRESS_DATA = [...data];
									} else {
										PROGRESS_DATA.push({
											key: file.name,
											value: percentCompleted,
										});
									}
								}
							}
						},
					};

					const intervalId = setInterval(() => {
						if (FILE_COUNT.length === PROGRESS_DATA.length) {
							clearInterval(intervalId);
							PROGRESS_DATA = [];
							FILE_COUNT = [];
							setUploadProgressPercents([]);
						}

						setUploadProgressPercents(PROGRESS_DATA);
					}, 1000);

					accounts
						.addRecording(releaseId, fd, config)
						.then((res) => {
							file.id = res.data.data.id;
							file.url = res.data.data.file.url;
							file.done = true;
							FILE_COUNT.push(file.id);
							setRecordings((prev) => [...prev]);
							setArraySort((prev) => [...prev, file.id]);
						})
						.catch((err) => {
							if (err === 'Network Error') {
								const fileWithError = dropFilesAccepted.filter((item) => {
									if (!item.done) {
										const index = dropFilesAccepted.indexOf(item);

										const filesDone = dropFilesAccepted.splice(index, 1);
										setDropFilesAccepted(filesDone);
										!recordings.length
											? setRecordings(dropFilesAccepted)
											: setRecordings(recordings);
										return item.name;
									} else return false;
								});
								const value = fileWithError.map((item) => item.name).join();
								setErrorsValue(value);
								return setErrors({
									upload: [{ rule: 'universal_error' }],
								});
							}

							if (err?.response?.status === 413) {
								const fileWithError = dropFilesAccepted.filter((item) => {
									if (!item.done) {
										const index = dropFilesAccepted.indexOf(item);

										const filesDone = dropFilesAccepted.splice(index, 1);
										setDropFilesAccepted(filesDone);
										!recordings.length
											? setRecordings(dropFilesAccepted)
											: setRecordings(recordings);
										return item.name;
									} else return false;
								});

								const value = fileWithError.map((item) => item.name).join();
								setErrorsValue(value);
								return setErrors({
									upload: [{ rule: 'max_size_error' }],
								});
							}

							if (err?.response?.status === 422) {
								const fileWithError = dropFilesAccepted.filter((item) => {
									if (!item.done) {
										const index = dropFilesAccepted.indexOf(item);

										const filesDone = dropFilesAccepted.splice(index, 1);
										setDropFilesAccepted(filesDone);
										!recordings.length
											? setRecordings(dropFilesAccepted)
											: setRecordings(recordings);
										return item.name;
									} else return false;
								});

								const value = fileWithError.map((item) => item.name).join();
								setErrorsValue(value);
								return setErrors({
									upload: [{ rule: 'max_size_error' }],
								});
							}

							if (err?.response?.status >= 500 && err?.response?.status < 600) {
								return setServerError(true);
							}

							if (err?.message?.includes('500')) {
								return setServerError(true);
							} else {
								dropFilesAccepted.filter((item) => {
									if (!item.done) {
										const index = dropFilesAccepted.indexOf(item);

										const filesDone = dropFilesAccepted.splice(index, 1);
										console.log('recordings', recordings);
										console.log('dropFilesAccepted', dropFilesAccepted);
										setDropFilesAccepted(filesDone);
										setRecordings([...recordings, ...dropFilesAccepted]);
										return item.name;
									} else return false;
								});
							}
						});
				}
			} else {
				setFormstError({
					error: true,
					rule: 'track_format',
					name: file.name,
				});
			}
		});
	};

	const handleDelete = (index, item) => {
		accounts
			.deleteRecording(releaseId, item.id)
			.then(() => {
				recordings.splice(index, 1);
				arraySort.splice(index, 1);
				if (
					audio &&
					(audio?.src === item?.url || audio?.src === item?.file?.url)
				) {
					handlePlayStop();
				}
				setRecordings([...recordings]);
				setArraySort([...arraySort]);
			})
			.catch((error) => {
				console.log(
					'DeleteRecording error in Release Creation - File: ',
					error
				);
			});
	};

	const handleGoUP = (e, index) => {
		if (index - 1 < 0) return;

		e.target.closest('li').style.background = 'var(--color-light-green)';
		[recordings[index], recordings[index - 1]] = [
			recordings[index - 1],
			recordings[index],
		];
		[arraySort[index], arraySort[index - 1]] = [
			arraySort[index - 1],
			arraySort[index],
		];
		accounts
			.sortRecordings(releaseId, {
				recordings: arraySort,
			})
			.then(() => {
				setRecordings([...recordings]);
				setArraySort([...arraySort]);
			})
			.catch((error) => {
				console.log(
					'DeleteRecordings error in Release Creation - File: ',
					error
				);
			})
			.finally(() => {
				e.target.closest('li').style.background = 'var(--color-white)';
			});
	};

	const handleGoDown = (e, index) => {
		if (index + 1 >= recordings.length) return;
		e.target.closest('li').style.background = 'var(--color-light-green)';
		[recordings[index], recordings[index + 1]] = [
			recordings[index + 1],
			recordings[index],
		];
		[arraySort[index], arraySort[index + 1]] = [
			arraySort[index + 1],
			arraySort[index],
		];
		accounts
			.sortRecordings(releaseId, {
				recordings: arraySort,
			})
			.then(() => {
				setRecordings([...recordings]);
				setArraySort([...arraySort]);
			})
			.catch((error) => {
				console.log('SortRecordings error in Release Creation - File: ', error);
			})
			.finally(() => {
				e.target.closest('li').style.background = 'var(--color-white)';
			});
	};

	const dragStartHandler = (e, item, index) => {
		if (!isAllFilesDownloaded) return;
		item.index = index;
		setCurrentCard(item);
	};
	const dragEndHandler = (e) => {
		if (!isAllFilesDownloaded) return;
		e.preventDefault();
		e.currentTarget.style.background = 'var(--color-white)';
	};
	const dragOverHandler = (e) => {
		if (!isAllFilesDownloaded) return;
		e.preventDefault();
		e.currentTarget.style.background = 'var(--color-light-green)';
	};
	const dropHandler = (e, item, index) => {
		if (!isAllFilesDownloaded) return;
		e.preventDefault();
		if (index === currentCard.index) return;
		[arraySort[index], arraySort[currentCard.index]] = [
			arraySort[currentCard.index],
			arraySort[index],
		];
		[recordings[index], recordings[currentCard.index]] = [
			recordings[currentCard.index],
			recordings[index],
		];
		accounts
			.sortRecordings(releaseId, {
				recordings: arraySort,
			})
			.then(() => {
				setRecordings([...recordings]);
				setArraySort([...arraySort]);
			})
			.catch((error) => {
				console.log('SortRecordings error in Release Creation - File: ', error);
			});

		e.currentTarget.style.background = 'var(--color-white)';
	};

	const checkUploadCount = (rule) => {
		switch (rule) {
			case 'upload_only_one_track':
				return (
					<FormattedMessage
						id={'rod.release_create.error.upload_only_one_track'}
						values={{ type: releaseType }}
					/>
				);

			case 'max_upload_count':
				return (
					<FormattedMessage id={'rod.release_create.error.max_upload_count'} />
				);

			case 'universal_error':
				return (
					<FormattedMessage
						id={'rod.release_create.error.upload_universal_error'}
						values={{ name: errorsValue }}
					/>
				);
			case 'max_size_error':
				return (
					<FormattedMessage
						id={'rod.release_create.error.upload_max_size_error'}
						values={{ name: errorsValue }}
					/>
				);

			default:
				break;
		}
	};

	const closeModal = () => {
		setShowRangeModal(false);
	};

	useEffect(() => {
		accounts
			.getRecordingList(releaseId)
			.then((res) => {
				res = res.data.data;
				setRecordings(res);
				setRecordingsReadyForCheckWithType(true);
				res.forEach((item) => {
					item.done = true;
					arraySort.push(item.id);
				});
				//	if (res.length > 0) setNext(true);
				setArraySort([...arraySort]);
				getReleaseTypes();
			})
			.catch((error) => {
				console.log(
					'GetRecordingList error in Release Creation - File: ',
					error
				);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (releaseTypeId && recordings.length) {
			checkRecorgingsCountAcordingReleaseType();
		}
	}, [releaseTypeId, recordingsReadyForCheckWithType]);

	const checkRecorgingsCountAcordingReleaseType = () => {
		if (
			(releaseTypeId.toString() === '42' ||
				releaseTypeId.toString() === '43' ||
				releaseTypeId.toString() === '70') &&
			recordings.length > 1
		) {
			setShowRangeModal(true);
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (recordings.length) {
			const data = recordings.find((item) => !item.done);

			if (data && isAllFilesDownloaded) {
				setIsAllFilesDownloaded(false);
			} else if (!data && !isAllFilesDownloaded) {
				setIsAllFilesDownloaded(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recordings]);

	const handlePrepearedAudio = (index, item) => {
		if (!item.file) {
			item.file = item;
			item.file.client_name = item.path;
		}

		handlePlay(index, item.file, item.file.client_name);
	};

	const disabledNextBtn = () => {
		if (!recordings.every((item) => item.done) || recordings.length === 0) {
			return true;
		}

		if (
			(releaseTypeId.toString() === '42' ||
				releaseTypeId.toString() === '43' ||
				releaseTypeId.toString() === '70') &&
			recordings.length > 1
		) {
			return true;
		}

		return false;
	};

	return (
		<>
			<div className={s.page}>
				{showRangeModal && (
					<FullScreenModalConfirm
						title={
							<FormattedMessage id={'rod.modal.leave_only_one_track.title'} />
						}
						text={
							<FormattedMessage id={'rod.modal.leave_only_one_track.text'} />
						}
						confirmBtnTxt={
							<FormattedMessage id={'rod.modal.leave_only_one_track.btn'} />
						}
						onClose={closeModal}
					/>
				)}
				<div className={s.main}>
					<span className={s.title}>
						<FormattedMessage id={'rod.release.create.step.recording.title'} />
					</span>
					<div className={s.input}>
						<DropZone
							accept=".wav, .flac"
							classStyles={!serverError ? s.DropZone : s.DropZoneError}
							dropzoneId={
								!serverError
									? 'rod.field.upload_v2'
									: 'rod.field.upload_server_error'
							}
							onUpload={handleFileUpload}
							kb
							maxSize={1024 * 1024 * 191}
							getDropFilesCount={getDropFilesCount}
							error={Object.keys(formatError).length ? formatError : false}
							setFormstError={setFormstError}
						/>
						{dropFilesAccepted.length > 1 &&
							Object.keys(errors).includes('upload') && (
								<span className={s.ErrorHelper}>
									{checkUploadCount(errors.upload[0].rule)}
								</span>
							)}
					</div>

					<div className={s.input}>
						{!serverError && recordings.length !== 0 && (
							<ul className={s.table}>
								{recordings.map((item, index) => (
									<li
										onDragStart={(e) => dragStartHandler(e, item, index)}
										onDragLeave={(e) => dragEndHandler(e)}
										onDragEnd={(e) => dragEndHandler(e)}
										onDragOver={(e) => dragOverHandler(e)}
										onDrop={(e) => dropHandler(e, item, index)}
										draggable={true}
										key={item?.file?.id || item.name}
										className={s.table__item}
									>
										<span>{index + 1}</span>
										<span>{item?.file?.client_name || item.name}</span>
										<span>3:00</span>
										{!item.done ? (
											<div className={!item.done ? s.loader : ''}>
												<Loader
													uploadProgressPercents={uploadProgressPercents.find(
														(progressObj) => progressObj.key === item.name
													)}
												/>
											</div>
										) : (
											<div className={s.menuWrapper}>
												{(audioId === item?.id && play) ||
												(audioId === item.file?.id && play) ? (
													<img
														src={stop_button}
														className={s.play}
														onClick={handlePlayStop}
														alt=""
													/>
												) : (
													<img
														src={play_button}
														className={s.play}
														onClick={() => handlePrepearedAudio(index, item)}
														alt=""
													/>
												)}
												<img
													src={remove}
													className={s.play}
													onClick={() => handleDelete(index, item)}
													alt=""
												/>
												<div
													className={s.menu}
													onClick={() => handleToogle(index)}
												>
													<div
														className={
															toogleMenu === index
																? `${s.dots} ${s.dots__active}`
																: s.dots
														}
													>
														{isAllFilesDownloaded && (
															<img
																src={positionUp}
																className={s.positionUp}
																alt=""
																onClick={(e) => handleGoUP(e, index)}
															/>
														)}
														{isAllFilesDownloaded && (
															<img
																src={positionDown}
																className={s.positionDown}
																alt=""
																onClick={(e) => handleGoDown(e, index)}
															/>
														)}
													</div>
													{toogleMenu === index && (
														<Board
															index={index}
															item={item}
															handleToogle={handleToogle}
															handleGoUP={handleGoUP}
															handleGoDown={handleGoDown}
														/>
													)}
												</div>
											</div>
										)}
									</li>
								))}
							</ul>
						)}
						{Object.keys(errors).includes('upload') && (
							<span className={s.ErrorHelper}>
								{checkUploadCount(errors.upload[0].rule)}
							</span>
						)}
					</div>
				</div>
				<InfoHelper
					title="rod.release.create.step.recording.helper_title"
					text="rod.release.create.step.recording.helper_main"
				/>
				<UploadPhonogramModal active={false} setActive={modalOpen} />
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn()}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
}

export default compose(withRoot, withAuth)(Recording);
