// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Helpers
import { getTooltip } from '../helpers/getTooltip';

// Icons
import plus_in_square from 'images/plus_in_square.svg';

// Styles
import styles from './AddRecordingBtn.module.css';

const AddRecordingBtn = ({ onAddRecording, disabled }) => {
	return (
		<button
			className={disabled ? styles.addBtnDisabled : styles.addBtn}
			disabled={disabled}
			onClick={onAddRecording}
			title={getTooltip(disabled)}
		>
			<img
				className={disabled ? styles.imageDisabled : ''}
				src={plus_in_square}
				alt="plus"
			/>
			<FormattedHTMLMessage id={'rod.performance_work.create_new_recordings'} />
		</button>
	);
};

export default AddRecordingBtn;
