// Core
import axios from 'axios';
import { useContext } from 'react';

// Context
import { UIContext } from 'contexts/UIContext';

const url = process.env.REACT_APP_API_URL;
const api = axios.create({
	withCredentials: true,
	baseURL: `${url}`,
});

export const AxiosInterceptor = ({ children }) => {
	const context = useContext(UIContext);
	api.interceptors.request.use((config) => {
		if (config.url.includes('amazonaws')) {
			config.headers = {
				'Content-Type': 'application/zip',
			};
		} else if (config.url === '/auth/token/create') {
			config.headers = {
				'Content-Language': localStorage.getItem('lang') || 'en',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Access-Token': localStorage.getItem('token') || null,
				'X-Client-Version': window.COMMIT_SHA || null,
				'Content-Type': 'application/json',
				'Refresh-Token': localStorage.getItem('refresh') || null,
			};
		} else {
			config.headers = {
				'Content-Language': localStorage.getItem('lang') || 'en',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Access-Token': localStorage.getItem('token') || null,
				'X-Client-Version': window.COMMIT_SHA || null,
				'Content-Type': 'application/json',
			};
		}
		return config;
	});

	api.interceptors.response.use(
		(config) => {
			return config;
		},
		async (error) => {
			const originalRequest = error.config;
			const refreshToken = localStorage.getItem('refresh');

			if (error?.response?.status === 401 && !refreshToken) {
				return (window.location = '/session-expired');
			}

			if (error === 'Network Error') {
				return Promise.reject(error);
			}

			if (error?.message === 'Network Error') {
				return Promise.reject(error.message);
			}

			if (error?.response?.status === 413) {
				return Promise.reject(error);
			}

			if (
				error?.response?.status === 401 &&
				error?.config &&
				!error?.config?.secondTime &&
				refreshToken
			) {
				originalRequest.secondTime = true;
				try {
					const refreshToken = localStorage.getItem('refresh');
					const headers = {
						'X-Access-Token': localStorage.getItem('token'),
					};
					const response = await axios.post(
						`${url}/auth/refresh`,
						{
							withCredentials: true,
							refresh_token: refreshToken,
						},
						{
							headers: headers,
						}
					);
					localStorage.setItem('token', response.data.access_token);
					localStorage.setItem('refresh', response.data.refresh_token);

					return api.request(originalRequest);
				} catch (err) {
					if (err?.response?.status === 401) {
						window.location = '/session-expired';
						localStorage.clear();
						throw err;
					} else {
						context.showErrorInterceptor(err);
					}
				}
			} else {
				context.showErrorInterceptor(error);
			}
		}
	);

	return children;
};

export default api;
