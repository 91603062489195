// Core
import React, { PureComponent } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';

// UI
import { Input } from 'components/Inputs/Input';
import { withUI } from 'hocs';

// Constants
import errorRules from 'constants/errorRules';

// Styles
import styles from './CodeInput.module.css';

const TIME = 5 * 60 * 1000;

class CodeInput extends PureComponent {
	constructor(props) {
		super(props);
		const {
			UIContext: { putResendSMSTime, resendSMSTime },
		} = this.props;

		this.state = {
			time: moment(new Date(TIME)),
			isRemainSMSTime: false,
			showResendButton: false,
			resendSMSTime: resendSMSTime,
			putResendSMSTime: putResendSMSTime,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.remainSMSTime && !this.state.isRemainSMSTime) {
			this.setState({
				time: moment(new Date(this.props.remainSMSTime)),
				isRemainSMSTime: true,
			});
		}
	}

	componentDidMount() {
		if (this.state.resendSMSTime === TIME) {
			this.state.putResendSMSTime(new Date().getTime());
			this.setState({ time: moment(new Date(this.state.resendSMSTime)) });
		} else if (TIME - (new Date().getTime() - this.state.resendSMSTime) <= 0) {
			this.setState({
				time: moment(new Date(0)),
			});
		} else {
			this.setState({
				time: moment(
					new Date(TIME - (new Date().getTime() - this.state.resendSMSTime))
				),
			});
		}

		setTimeout(() => {
			this.reset();
		}, 0);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	reset() {
		this.interval = setInterval(() => {
			if (this.state.time.valueOf() > 0) {
				const time = moment(this.state.time.subtract(1, 'seconds'));
				this.setState({ time });
			} else {
				clearInterval(this.interval);
				this.setState({ showResendButton: true });
				this.props.clearAllErrors();
			}
		}, 1000);
	}

	handleResend = () => {
		const { onResend } = this.props;
		this.setState(
			{
				time: moment(new Date(this.context['resendSMSTime'])),
				showResendButton: false,
			},
			() => {
				if (typeof onResend === 'function') {
					onResend();
				}
				this.state.putResendSMSTime(new Date().getTime());
				this.setState({ time: moment(new Date(TIME)) });
				this.reset();
			}
		);
	};

	showError = (error) => {
		switch (error.rule) {
			case errorRules.INVALID:
				if (error.remaining === 0) {
					if (this.props.isChangeSignTypeAvailable) {
						return <FormattedHTMLMessage id={'rod.error.code.limit'} />;
					}

					return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
				} else {
					return (
						<FormattedHTMLMessage
							id={'rod.error.code.invalid'}
							values={{ count: error.remaining }}
						/>
					);
				}
			case errorRules.RETRY:
				return <FormattedHTMLMessage id={'rod.error.code.retry'} />;

			case errorRules.FORBIDDEN:
				return <FormattedHTMLMessage id={'rod.error.code.forbidden'} />;

			case errorRules.EXHAUSTED:
				if (this.props.isChangeSignTypeAvailable) {
					return <FormattedHTMLMessage id={'rod.error.code.limit'} />;
				}

				return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;

			default:
				return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
		}
	};

	onChange(text) {
		if (this.props.clearAllErrors) {
			this.props.clearAllErrors();
		}
	}

	changeRemainTime = (t) => {
		this.setState({ time: moment(new Date(t)) });
	};

	render() {
		let { onResend, errors, remainSMSTime, ...props } = this.props;

		if (errors.find((element) => element.rule === 'retry')) {
			errors = errors.filter((element) => element.rule !== 'retry');
		}

		const { time, showResendButton } = this.state;

		return (
			<FormattedHTMLMessage id={'rod.field.sms.helper'}>
				{(helper) => {
					return (
						<div className={styles.CodeInput}>
							<Input
								className={styles.Input}
								placeholder={'xxx xxx'}
								{...props}
								clearInputIfHasError={true}
								errors={errors}
								onChange={(s) => {
									this.onChange(s);
								}}
								name={'code'}
								showError={this.showError}
								helper={
									!showResendButton
										? `${helper} ${moment(time).format('mm:ss')}`
										: undefined
								}
							/>
							{showResendButton && (
								<button onClick={this.handleResend}>
									<FormattedHTMLMessage id={'rod.action.sms.resend'} />
								</button>
							)}
						</div>
					);
				}}
			</FormattedHTMLMessage>
		);
	}
}

CodeInput.propTypes = {
	onResend: PropTypes.func,
	errors: PropTypes.array,
	isChangeSignTypeAvailable: PropTypes.bool,
};

CodeInput.defaultProps = {
	errors: [],
};

export default compose(withUI)(CodeInput);
