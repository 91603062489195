import React, { useState, useEffect } from 'react';
import {
	TextFieldInputNotifyUsers,
	// AutocompleteInput,
	AutocompleteInputNotificationsAdmin,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import Admin from 'services/api/admin';
import { FormattedMessage } from 'react-intl';

// import { Portal } from '@mui/material';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				background: '#f0f0f0',
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 16px',
					'&:hover': {
						backgroundColor: '#F0F0F0;',
					},
				},
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiAutocompleteMultipleUsers = ({ onChange, value /* items */ }) => {
	const [searchValue, setSearchValue] = useState('');
	const [items, setItems] = useState([]);

	useEffect(() => {
		if (searchValue.length > 2) {
			Admin.getAccountsAdminV2(searchValue).then((res) => {
				res = res.data.data;
				setItems(res);
			});
		} else if (!value.length) {
			setItems([]);
		}
	}, [searchValue, value]);
	// items = [];

	for (let i = 0; i < value.length; i++) {
		for (let j = 0; j < items.length; j++) {
			if (value[i] === items[j].id) {
				value[i] = {
					id: items[j].id,
					title: items[j].title,
				};
			}
		}
	}

	return (
		<AutocompleteInputNotificationsAdmin
			disablePortal
			multiple
			size="small"
			// limitTags={2}
			limitTags={-1}
			onChange={(_, obj) => {
				return onChange(obj);
			}}
			options={items}
			value={value}
			renderInput={(params) => {
				setSearchValue(params.inputProps.value);
				return (
					<FormattedMessage id={'rod.admin.notifications.recipients'}>
						{(placeholder) => (
							<TextFieldInputNotifyUsers
								multiline
								maxRows={4}
								{...params}
								id={value}
								variant="standard"
								placeholder={placeholder}
							/>
						)}
					</FormattedMessage>
				);
			}}
			getOptionLabel={(option) => {
				if (option.title) {
					return option.title.length < 25
						? option.title
						: `${option.title.slice(0, 25)}...`;
				} else return '';
			}}
			renderOption={(option, item) => (
				<li key={item.id} {...option}>
					{/* {`${item.id} - `} {item.title} */}
					{item.title}
				</li>
			)}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			PopperComponent={CustomPopper}
		/>
	);
};

export default MuiAutocompleteMultipleUsers;
