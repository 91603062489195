// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { withAuth, withUI } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import { auth } from 'services';

// UI
import ItemWrapper from './ItemWrapper/ItemWrapper';
import LogoutBtn from './LogoutBtn/LogoutBtn';
import { InputMenu } from 'material-design/components';
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';

// Styles
import styles from './Menu.module.css';

class Menu extends PureComponent {
	container = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			isAccountsShow: false,
			accountId: '',
			page: 1,
			accounts: [],
			allAccountsUploaded: false,
			searchValue: '',
			isLoading: false,
			waitUpload: false,
		};

		this.ref = React.createRef();
	}

	componentDidMount() {
		const {
			authContext: { user, accountId },
		} = this.props;

		this.setState({ accounts: user.accounts });
		this.setState({ accountId });

		try {
			if (
				user.completion_step === 'account' ||
				user.completion_step === 'account_legacy'
			) {
				this.setState({ isAccountsShow: true });
			}
		} catch (err) {
			console.error('Menu - getUser/user.completion_step error: ', err);
		}

		setTimeout(() => {
			document.addEventListener('click', this.handleDocumentClick);
		}, 0);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleDocumentClick);
	}

	handleScroll = () => {
		if (this.state.allAccountsUploaded) return;

		if (
			this.ref.current.scrollHeight - this.ref.current.scrollTop <=
				this.ref.current.clientHeight + 1 &&
			!this.state.waitUpload
		) {
			this.setState(
				{ page: this.state.page + 1, isLoading: true, waitUpload: true },
				() => {
					auth
						.getUser(this.state.page, this.state.searchValue)
						.then((res) => {
							res = res.data.data;

							if (!res?.accounts.length)
								this.setState({ allAccountsUploaded: true });

							this.setState({
								accounts: [...this.state.accounts, ...res.accounts],
							});
						})
						.catch((err) => {
							console.error('Error:', err);
						})
						.finally(() => {
							this.setState({
								isLoading: false,
								waitUpload: false,
							});
						});
				}
			);
		}
	};

	handleDocumentClick = (e) => {
		const {
			UIContext: { hideUserMenu },
		} = this.props;

		if (
			!this.container?.current?.contains(e.target) &&
			e.target?.nodeName !== 'IMG'
		) {
			hideUserMenu();
		}
	};

	hide = () => {
		const {
			UIContext: { hideUserMenu, isShowUserMenu },
			authContext: { switchAccount },
		} = this.props;

		switchAccount(+this.state.accountId);

		if (isShowUserMenu) {
			hideUserMenu();
		}
	};

	removeSearch = () => {
		this.setState({
			page: 1,
			allAccountsUploaded: false,
		});
	};

	handleOnChange = debounce((search) => this.onChange(search), 500);

	onChange = (search) => {
		if (search.length >= 3) {
			this.setState({
				isLoading: true,
				accounts: [],
				searchValue: search,
				allAccountsUploaded: false,
			});

			auth
				.getUser(1, search)
				.then((res) => {
					res = res.data.data;
					this.setState({
						accounts: res.accounts,
						page: 1,
					});
				})
				.catch((err) => {
					console.error('Error:', err);
				})
				.finally(() => {
					this.setState({
						isLoading: false,
					});
				});
		} else if (!search.length) {
			this.setState({
				isLoading: true,
				searchValue: '',
				allAccountsUploaded: false,
			});

			auth
				.getUser(1)
				.then((res) => {
					res = res.data.data;

					this.setState({
						accounts: res.accounts,
						page: 1,
					});
				})
				.catch((err) => {
					console.error('Error:', err);
				})
				.finally(() => {
					this.setState({
						isLoading: false,
					});
				});
		}
	};

	render() {
		const {
			authContext: { user, isLegacy, isJoin },
		} = this.props;

		const selectedAcc = this.state.accounts.filter(
			(acc) => acc.id === +this.state.accountId
		)[0];

		const getLink = (selectedAcc) => {
			const isNoContract = localStorage.getItem('no_contract');

			if (isNoContract) {
				localStorage.removeItem('no_contract');
				return `/accounts/${selectedAcc?.id}`;
			} else {
				return `/accounts/${selectedAcc?.id}`;
			}
		};

		return (
			<div className={styles.Menu} ref={this.container}>
				{this.state.isAccountsShow && (
					<React.Fragment>
						<div className={styles.InputWrapper}>
							<InputMenu
								placeholder={'rod.header.account_search'}
								handleOnChange={this.handleOnChange}
								removeSearch={this.removeSearch}
							/>
						</div>
						<ul
							className={styles.List}
							onScroll={this.handleScroll}
							ref={this.ref}
							style={this.state.page === 1 ? { height: '280px' } : {}}
						>
							{this.state.isLoading && (
								<div className={styles.preLoader}>
									<LoaderWhithoutProgress />
								</div>
							)}

							{selectedAcc && (
								<li className={styles.Item_Active}>
									<div className={styles.Item}>
										<NavLink
											to={() => getLink(selectedAcc)}
											onClick={this.hide}
										>
											{selectedAcc?.title
												? selectedAcc.title
												: `Account #${selectedAcc?.id}`}
										</NavLink>
									</div>
								</li>
							)}

							{this.state.accounts
								.filter((acc) => {
									return acc.id !== +this.state.accountId;
								})
								.map((acc) => (
									<li className={styles.item_wrapper}>
										<div className={styles.Item}>
											<NavLink to={`/accounts/${acc.id}`} onClick={this.hide}>
												{acc.title ? acc.title : `Account #${acc.id}`}
											</NavLink>
										</div>
									</li>
								))}

							{this.state.searchValue.length &&
								!this.state.accounts.length &&
								!this.state.isLoading && (
									<div className={styles.notFound}>
										<FormattedMessage id={'rod.header.account_not_found'} />
									</div>
								)}
						</ul>
						<div className={styles.Separator} />
						<ul>
							{!isLegacy &&
								this.props.UIContext.isAllowAccountCreate &&
								this.props.UIContext.isAdditionalAccountCreationEnabled && (
									<ItemWrapper
										hide={this.hide}
										to="/accounts/new"
										text="rod.menu.action.add_account"
									/>
								)}

							{user.role === 'admin' && (
								<ItemWrapper
									hide={this.hide}
									to="/admin/v2/accounts"
									text="rod.menu.action.admin"
								/>
							)}

							{isJoin && (
								<ItemWrapper
									hide={this.hide}
									to="/profile/settings/private_data"
									text="rod.menu.action.settings"
								/>
							)}
						</ul>
						<div className={styles.Separator} />
					</React.Fragment>
				)}
				<LogoutBtn hide={this.hide} />
			</div>
		);
	}
}

Menu.propTypes = {};
Menu.defaultProps = {};

export default compose(withUI, withAuth, withRouter)(Menu);
