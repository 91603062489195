// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

// Helpers
import { fm } from 'helpers';

export const statusLocalization = (status) => {
	switch (status) {
		case accountCompletionSteps.PHONE_CONFIRM:
			return fm('rod.field.admin.phone');
		case accountCompletionSteps.EMAIL_CONFIRM:
			return fm('rod.field.admin.email');
		case accountCompletionSteps.ACCOUNT_CREATED:
			return fm('rod.field.admin.acc-created') /* 'account' */;
		case accountCompletionSteps.BEGIN:
			return fm('rod.release.create.step.tracks.fragment.start');
		case accountCompletionSteps.ROLE:
			return fm('rod.field.admin.role-selection') /* 'role' */;
		case accountCompletionSteps.PERSONAL_DATA:
			return fm('rod.account.personal.title'); /* 'personal_data' */
		case accountCompletionSteps.CONTACT_DATA:
			return fm('rod.admin.account.contact_information'); /* 'contact_data'; */
		case accountCompletionSteps.DOCUMENTS:
			return fm('rod.field.admin.documents'); /* 'documents'; */
		case accountCompletionSteps.IDENTITY:
			return fm('rod.field.admin.identifiers'); /* 'identity'; */
		case accountCompletionSteps.MONEY:
			return fm('rod.field.admin.payment-details'); /* 'money'; */
		case accountCompletionSteps.PENDING:
			return fm('rod.treaties_page.pending_review'); /* 'pending'; */
		case accountCompletionSteps.PENDING_CONTRACT:
			return fm('rod.treaties_page.pending_review'); /* 'pending_contracts'; */
		case accountCompletionSteps.APPROVED:
			return fm('rod.admin.account.files.approved'); /* 'approved'; */
		case accountCompletionSteps.DECLINED:
			return fm('rod.repertoire.rejected'); /* 'declined'; */
		case accountCompletionSteps.DECLINED_DOCUMENTS:
			return fm('rod.repertoire.rejected'); /*  ;'declined_docs'; */
		case accountCompletionSteps.DECLINED_CONTRACTS:
			return fm('rod.repertoire.rejected'); /* 'declined_contracts'; */
		case accountCompletionSteps.APPROVED_CONTRACTS:
			return fm('rod.admin.account.files.approved'); /* 'approved_contracts'; */
		case accountCompletionSteps.SIGN_PHYSICAL:
			return fm('rod.field.admin.signing'); /* 'sign_physical'; */
		case accountCompletionSteps.CONFIRM:
			return fm('rod.admin.account.status.confirm'); /* 'confirm'; */
		case accountCompletionSteps.SIGN_SMS:
			return fm('rod.field.admin.signing'); /* 'sign_sms'; */
		case accountCompletionSteps.SMS_CONFIRM:
			return fm('rod.admin.account.status.sms_confirm'); /* 'sms_confirm'; */
		case accountCompletionSteps.COMPLETE:
			return fm('rod.contracts.complete.header'); /* 'completed'; */
		case accountCompletionSteps.PERSONAL_AREA:
			return fm(
				'rod.admin.account.status.personal_area'
			); /* 'personal_area'; */
		case accountCompletionSteps.REPERTOIRE_APPLY:
			return fm(
				'rod.admin.account.status.repertoire_apply'
			); /* 'repertoire_apply'; */
		case accountCompletionSteps.PERSONAL_AREA_LEGASY:
			return fm(
				'rod.admin.account.status.personal_area_legacy'
			); /* 'personal_area_legacy'; */
		case accountCompletionSteps.LIST:
			return status; /* 'list'; */
		default:
			return status;
	}
};
