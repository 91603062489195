import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { FormattedMessage } from 'react-intl';
import styles from './ContractOverduePage.module.css';
import { useContext } from 'react';

import { AuthContext } from 'contexts/AuthContext';

const ContractOverduePage = () => {
	const { logout } = useContext(AuthContext);

	const handleGoToMain = () => {
		logout();
	};

	return (
		<div className={styles.Page}>
			<div className={styles.Form}>
				<div className={styles.Title}>
					<Label font="--gilroy-Medium-40" black>
						<FormattedMessage id={'rod.contracts.contract_overdue.header'} />
					</Label>
					<Label font="--gilroy-Medium-24" black>
						<FormattedMessage id={'rod.contracts.contract_overdue.body'} />
					</Label>
				</div>
				<div className={styles.Controls}>
					<Button
						className={styles.Button}
						text={<FormattedMessage id={'rod.action.to_index_page'} />}
						type={'submit'}
						variant={'primary'}
						onClick={handleGoToMain}
					/>
				</div>
			</div>
		</div>
	);
};

export default ContractOverduePage;
