// Core
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Context
import { AuthContext } from 'contexts/AuthContext';

// UI
import AdditionalRelease from '../AdditionalRelease/AdditionalRelease';
import { InfoTitle } from 'components';
import { FormInput } from 'components/Form/FormInput';
import { SwitchSave } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

// Helpers
import { checkFieldIsValid } from '../helpers/checkFieldIsValid';
import { formatSalesDate } from 'helpers/formatSalesDate';
import { checkSnippetErrors } from '../helpers/checkSnippetErrors';
import { showError } from 'validators/showError';
import { accounts } from 'services';

// Icons
import close_button from 'images/close_button.svg';
import { Check, NoCheck } from 'material-design/svg';

// Styles
import s from '../Tracks.module.css';

const TracksForm = ({
	releaseTypeId,
	transferRelease,
	lastSavePerson,
	changeMask,
	isSnippedRender,
	changeSnippet,
	errors,
	setErrors,
	changeField,
	handleForm,
	toogleMenu,
	selectPerson,
	setSelectPerson,
	recordingSave,
	toogleLastPerson,
	takeByLastPerson,
	handleOnBlurDateInput,
	countries,
	handleISRC,
	isLabelOrProducerRequred,
	additionalReleases,
	setAdditionalReleases,
	setShowRangeModal,
	isMainValid,
}) => {
	const { isAdditionalReleaseEnable } = useContext(AuthContext);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [additionalErrors, setAdditionalErrors] = useState({});

	const checkOnValid = () => {
		return additionalReleases.every((additionalRelease) =>
			checkFieldIsValid(additionalRelease, setAdditionalErrors, transferRelease)
		);
	};

	const onSaveHandler = async () => {
		if (!isAdditionalReleaseEnable && !additionalReleases.length)
			recordingSave(toogleMenu);

		if (isAdditionalReleaseEnable) {
			if (
				releaseTypeId !== 51 &&
				releaseTypeId !== 2 &&
				releaseTypeId !== 64 &&
				releaseTypeId !== 69 &&
				selectPerson.additional
			) {
				setShowRangeModal(true);
			} else {
				if (isMainValid(selectPerson, true)) return;

				setButtonDisabled(true);
				try {
					const savePromises = additionalReleases.map(
						async (additionalRelease) => {
							if (
								checkFieldIsValid(
									additionalRelease,
									setAdditionalErrors,
									transferRelease
								)
							) {
								additionalRelease.sale_start_date = formatSalesDate(
									additionalRelease.sale_start_date
								);

								if (typeof additionalRelease.id === 'string') {
									await accounts.createAdditionalRelease(additionalRelease);
								} else {
									await accounts.updateAdditionalRelease(additionalRelease);
								}
							}
						}
					);

					await Promise.all(savePromises);
					setButtonDisabled(false);

					if (checkOnValid()) {
						recordingSave(toogleMenu);
					}
				} catch (error) {
					setErrors(error.response.data.errors);
					setButtonDisabled(false);
				}
			}
		}
	};

	return (
		<div className={s.form}>
			<img
				src={close_button}
				className={s.form__close}
				onClick={() => handleForm(toogleMenu)}
				alt=""
			/>

			{lastSavePerson && Object.keys(lastSavePerson).length !== 0 && (
				<div className={s.lastSave}>
					<div className={s.lastSave__switch}>
						<SwitchSave
							checked={takeByLastPerson}
							onChange={(_, c) => toogleLastPerson(c)}
						/>
					</div>
					<span>
						<FormattedMessage
							id={'rod.release.create.step.recording_info.form.save'}
						/>
					</span>
				</div>
			)}

			<span className={s.form__title} id="general">
				<FormattedMessage id={'rod.release.tab.main'} />
			</span>
			<div className={`${s.form__section} ${s.first__section}`}>
				<div className={s.input_leftCol}>
					<FormInput
						type="muiInput"
						name={'title'}
						maxRows={4}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.name'}
							/>
						}
						required
					/>
					{errors && Object.keys(errors).includes('title') && (
						<span className={s.Helper}>
							{typeof errors.title === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_rightCol}>
					<FormInput
						type="muiInput"
						name={'subtitle'}
						maxRows={4}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.subtitle'}
							/>
						}
					/>
					{errors && Object.keys(errors).includes('subtitle') && (
						<span className={s.Helper}>
							{typeof errors.subtitle === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>

				<div className={s.input_rightCol}>
					<FormInput
						type={'muiInputDate_v2'}
						name={'created_date'}
						onChange={changeField}
						handleOnBlurDateInput={handleOnBlurDateInput}
						maxDate={true}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.create_date'}
							/>
						}
						required
					/>
					{errors && Object.keys(errors).includes('created_date') && (
						<span className={s.Helper}>
							{
								<FormattedMessage
									id={`rod.error.${errors.created_date[0].rule}`}
								/>
							}
						</span>
					)}
				</div>

				<div className={s.input_leftCol}>
					<FormInput
						type={'muiAutocompleteMultipleGenres'}
						name={'genres'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						required
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.genre'}
							/>
						}
					/>
					{errors && Object.keys(errors).includes('genres') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>

				<div className={`${s.input_rightCol} ${s.input_country}`}>
					<FormattedMessage
						id={'rod.release.create.step.recording_info.label.create_country'}
					>
						{(placeholder) => (
							<FormInput
								type="country"
								name="created_country_id"
								onChange={changeField}
								errors={errors}
								data={selectPerson}
								placeholder={placeholder}
								items={countries}
								countries={countries}
								required
							/>
						)}
					</FormattedMessage>
					{errors && Object.keys(errors).includes('created_country_id') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>

				<div></div>

				<div className={s.input_leftCol}>
					<InputMask
						mask={'aa-***-99-99999'}
						name={'isrc'}
						onChange={changeMask}
						errors={errors}
						value={selectPerson.isrc ? selectPerson.isrc : ''}
						disabled={
							selectPerson.generate_isrc && !transferRelease ? true : false
						}
					>
						{() => (
							<TextFieldInput
								variant="standard"
								type="muiInput"
								name={'isrc'}
								error={Object.keys(errors).length > 0 && errors['isrc']}
								value={selectPerson.isrc ? selectPerson.isrc : ''}
								label="ISRC"
								required
								disabled={
									selectPerson.generate_isrc && !transferRelease ? true : false
								}
							/>
						)}
					</InputMask>

					{errors && Object.keys(errors).includes('isrc') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>
				{!transferRelease && (
					<div className={s.auto__wrapper}>
						<div
							className={s.auto}
							onClick={() => handleISRC(selectPerson?.generate_isrc)}
						>
							{selectPerson?.generate_isrc ? (
								<Check className={s.auto__check} />
							) : (
								<NoCheck className={s.auto__noCheck} />
							)}
							<span>
								<FormattedMessage
									id={'rod.release.create.step.release.label.auto'}
								/>
							</span>
						</div>
					</div>
				)}
			</div>

			<span className={s.form__title}>
				<FormattedMessage id={'rod.release.create.step.tracks.contributors'} />
			</span>

			<div className={`${s.form__section} ${s.second__section}`}>
				<div className={s.input_leftCol}>
					<FormInput
						type="muiInput"
						name={'main_performer'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.main_executor'}
							/>
						}
						required
					/>
					{errors && Object.keys(errors).includes('main_performer') && (
						<span className={s.Helper}>
							{typeof errors.main_performer === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>

				<div className={s.input_rightCol}>
					<FormInput
						type="muiInput"
						name={'party_id'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.label'}
							/>
						}
						required={isLabelOrProducerRequred}
					/>
					{errors && Object.keys(errors).includes('party_id') && (
						<span className={s.Helper}>
							{typeof errors.party_id === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_leftCol}>
					<FormInput
						type="muiInput"
						name={'featured_artist'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage id={`rod.performance_work.featured_artist`} />
						}
					/>
					{errors && Object.keys(errors).includes('featured_artist') && (
						<span className={s.Helper}>
							{typeof errors.featured_artist === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_rightCol}>
					<FormInput
						type="muiInput"
						name={'producer'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={<FormattedMessage id={`rod.ref.roles.producer.summary`} />}
						required={isLabelOrProducerRequred}
					/>
					{errors && Object.keys(errors).includes('producer') && (
						<span className={s.Helper}>
							{typeof errors.producer === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
			</div>

			{isSnippedRender && (
				<>
					<InfoTitle
						title="rod.release.create.step.tracks.fragment.title"
						helpTitle="rod.release.create.step.tracks.fragment.help"
					/>
					<div className={`${s.form__section} ${s.third__section}`}>
						<div className={s.input}>
							<InputMask
								mask={'99:99:999'}
								maskChar={'0'}
								name={'snippet_start'}
								onChange={changeSnippet('snippet_start')}
								// onBlur={() =>
								// 	snippetOnBlur(selectPerson.snippet_start, 'snippet_start')
								// }
								errors={errors}
								value={
									selectPerson.snippet_start ? selectPerson.snippet_start : ''
								}
							>
								{() => (
									<TextFieldInput
										type="muiInput"
										variant="standard"
										name={'snippet_start'}
										required
										errors={errors}
										value={
											selectPerson.snippet_start
												? selectPerson.snippet_start
												: ''
										}
										label={
											<FormattedMessage
												id={'rod.release.create.step.tracks.fragment.start'}
											/>
										}
									/>
								)}
							</InputMask>
							{errors && Object.keys(errors).includes('snippet_start') && (
								<span className={s.Helper}>
									{checkSnippetErrors('snippet_start', errors)}
									{/* {<FormattedMessage id={`rod.snippet_start.error.required`} />} */}
								</span>
							)}
						</div>
						<div className={s.input}>
							<InputMask
								mask={'99:99:999'}
								maskChar={'0'}
								name={'snippet_end'}
								// onChange={changeSnippet('snippet_end')}
								disabled={true}
								// onBlur={() =>
								// 	snippetOnBlur(selectPerson.snippet_end, 'snippet_end')
								// }
								errors={errors}
								value={selectPerson.snippet_end ? selectPerson.snippet_end : ''}
							>
								{() => (
									<TextFieldInput
										type="muiInput"
										disabled={true}
										variant="standard"
										name={'snippet_end'}
										errors={errors}
										value={
											selectPerson.snippet_end ? selectPerson.snippet_end : ''
										}
										label={
											<FormattedMessage
												id={'rod.release.create.step.tracks.fragment.end'}
											/>
										}
									/>
								)}
							</InputMask>
							{/* {errors && Object.keys(errors).includes('snippet_end') && (
								<span className={s.Helper}>
									{checkSnippetErrors('snippet_end', errors)}
								</span>
							)} */}
						</div>
					</div>
				</>
			)}

			{isAdditionalReleaseEnable &&
				(releaseTypeId === 51 ||
					releaseTypeId === 2 ||
					releaseTypeId === 64 ||
					releaseTypeId === 69 ||
					selectPerson.additional) && (
					<AdditionalRelease
						releaseTypeId={releaseTypeId}
						additionalReleases={additionalReleases}
						setAdditionalReleases={setAdditionalReleases}
						selectPerson={selectPerson}
						setSelectPerson={setSelectPerson}
						errors={errors}
						setErrors={setErrors}
						additionalErrors={additionalErrors}
						setAdditionalErrors={setAdditionalErrors}
						transferRelease={transferRelease}
					/>
				)}

			<button
				className={
					errors.snippet_start || errors.snippet_end
						? `${s.form__submit} ${s.form_submit_withError}`
						: `${s.form__submit}`
				}
				style={buttonDisabled ? { opacity: 0.2 } : { opacity: 1 }}
				disabled={buttonDisabled}
				onClick={onSaveHandler}
			>
				<FormattedMessage
					id={'rod.release.create.step.recording_info.form_save'}
				/>
			</button>
		</div>
	);
};

export default TracksForm;
