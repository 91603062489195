/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 18:59
 */

import { Loading } from 'components';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { withAuth, withRoot, withUI } from 'hocs';
import { Create } from 'pages/account/Create';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

// import PropTypes from 'prop-types';
// import styles from './Account.module.css';
class Account extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			account: null,
			loading: true,
		};
	}

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.update();
		}
	}

	componentWillUnmount() {
		const {
			UIContext: { hideUploadHandler },
		} = this.props;
		hideUploadHandler();
	}

	update() {
		const {
			rootContext: { getAccountPersonalData },
			UIContext: { showUploadHandler },
			authContext: { switchAccount, contractsExpiredRedirect, setIsNoContract },
		} = this.props;

		const isNoContract = localStorage.getItem('no_contract');

		switchAccount(this.props.match.params.id);

		getAccountPersonalData(this.props.match.params.id)
			.then((res) => {
				if (res.status === 207 || res.status === 205) {
					return contractsExpiredRedirect(res.status);
				} else if (isNoContract) {
					setIsNoContract();
				}

				res = res.data.data;

				if (
					res.completion_step === accountCompletionSteps.PERSONAL_AREA ||
					res.completion_step === accountCompletionSteps.REPERTOIRE_APPLY
				) {
					showUploadHandler();
				}
				this.setState({
					account: {
						...res,
						moderation_status: res.moderation_status || {},
					},
					loading: false,
				});
			})
			.catch((error) => {
				console.log('Account - catched error: getAccountPersonalData', error);
				console.log(
					'Account - catched error.response: getAccountPersonalData',
					error.response
				);
			});
	}

	render() {
		const {
			authContext: { getAccountCompletionStep },
		} = this.props;
		// const {
		// 	rootContext: { getAccountPersonalData },
		// } = this.props;
		const { account, loading } = this.state;
		if (loading) {
			return <Loading non_margin={true} />;
		}

		if (!account) {
			return <Redirect to={'/accounts'} />;
		}

		try {
			getAccountCompletionStep(account.completion_step);

			switch (account.completion_step) {
				case accountCompletionSteps.APPROVED:
				case accountCompletionSteps.SIGN_PHYSICAL:
				case accountCompletionSteps.SIGN_SMS:
				case accountCompletionSteps.APPROVED_CONTRACTS:
					return <Redirect to={`/accounts/${account.id}/contracts/add`} />;
				case accountCompletionSteps.PENDING_CONTRACT:
					// 	return <Redirect to={`/accounts/${account.id}/contracts/pending`} />;

					// case accountCompletionSteps.PERSONAL_AREA:
					// case accountCompletionSteps.REPERTOIRE_APPLY:
					// 	return <Redirect to={`/accounts/${account.id}/dashboard`} />;

					return <Redirect to={`/accounts/${account.id}/contracts/add`} />;
				/*case accountCompletionSteps.PENDING_CONTRACT:
				return <Redirect to={`/accounts/${account.id}/contracts/pending`}/>;*/
				case accountCompletionSteps.PERSONAL_AREA:
				case accountCompletionSteps.REPERTOIRE_APPLY:
					return <Redirect to={`/accounts/${account.id}/dashboard`} />;

				case accountCompletionSteps.PERSONAL_AREA_LEGASY:
					return <Redirect to={`/accounts/${account.id}/dashboard`} />;
				default:
					return <Create account={account} />;
			}
		} catch (err) {
			console.log('Account - catched error: getAccountCompletionStep', err);
		}
	}
}

Account.propTypes = {};

Account.defaultProps = {};

export default compose(withRoot, withAuth, withUI)(Account);
