import accountTypes from 'constants/accountTypes';
import { FormattedHTMLMessage } from 'react-intl';

export const personalDataHelper = (accountType, userData, getCountryById) => {
	switch (accountType) {
		case accountTypes.PERSONAL: {
			return [
				{
					title: <FormattedHTMLMessage id={'rod.field.firstname'} />,
					value: userData.first_name,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.lastname'} />,
					value: userData.last_name,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.patronymic'} />,
					value: userData.patronymic ?? '-',
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.registration_address'} />,
					value: userData.birth_place ?? '-',
				},
			];
		}
		case accountTypes.PERSONAL_ENTREPRENEUR: {
			return [
				{
					title: (
						<FormattedHTMLMessage id={'rod.field.personal_entrepreneur.name'} />
					),
					value: userData.title,
				},
				{
					title: (
						<FormattedHTMLMessage
							id={'rod.field.personal_entrepreneur.signer'}
						/>
					),
					value: userData.behalf,
				},
			];
		}
		case accountTypes.COMPANY: {
			return [
				{
					title: <FormattedHTMLMessage id={'rod.field.company.name'} />,
					value: userData.title,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.company.signer'} />,
					value: userData.behalf,
				},
			];
		}
		default: {
			return [];
		}
	}
};
