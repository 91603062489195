/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */

import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './Wizard.module.css';

class WizardStep extends Component {
	static propTypes = {
		status: PropTypes.oneOf(['disabled', 'active', 'complete', 'default']),
		title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	};

	handleOnClick = (status, step) => {
		if (status === 'complete' && this.props.handleOpenPrevTabs) {
			this.props.handleOpenPrevTabs(step.id);
		}
	};

	render() {
		const { title, status, step, handleOpenPrevTabs, ...props } = this.props;
		return (
			<div
				{...props}
				className={cn(
					this.props.handleOpenPrevTabs
						? styles.WizardStepClickable
						: styles.WizardStep,
					status
				)}
				onClick={() => this.handleOnClick(status, step)}
			>
				{title}
			</div>
		);
	}
}

export default WizardStep;
