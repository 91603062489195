// Core
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Services
import { accounts } from 'services';

// Icons
import remove from 'images/remove_2.svg';

// Types
import modalTypes from 'constants/modalTypes';

// Styles
import styles from './RemoveButton.module.css';

const RemoveButton = ({ item, releases, getReleasesAfterRemove }) => {
	const accountId = localStorage.getItem('accountId');
	const { showModal, removeOneDraft, setCurrentDraftCount } = useContext(
		UIContext
	);
	const { id, draft_type } = item;

	const handleRemoveDraft = () => {
		accounts
			.deleteDraft(draft_type, id)
			.then((res) => {
				const releasesAfterRemove = releases.filter((item) => item.id !== id);
				getReleasesAfterRemove(releasesAfterRemove);
				removeOneDraft();
			})
			.catch((err) => {
				console.error('error', err.response);
			});
	};

	useEffect(() => {
		accounts
			.getAssetsDrafts(accountId)
			.then((res) => {
				setCurrentDraftCount(res.data.total);
			})
			.catch((error) => {
				console.error('Error', error);
			});
	}, []);

	return (
		<button
			className={styles.removeBtn}
			onClick={showModal(
				{
					title: <FormattedMessage id="rod.drafts.delete-confirm" />,
					text: <FormattedMessage id="rod.drafts.delete-help" />,
					onAction: () => {
						handleRemoveDraft();
					},
					confirmBtnTxt: <FormattedMessage id={'rod.modal.yes_delete'} />,
					declineBtnTxt: <FormattedMessage id={'rod.modal.cancel'} />,
				},

				modalTypes.FULL_MODAL
			)}
		>
			<img src={remove} alt="delete" />
		</button>
	);
};

export default RemoveButton;
