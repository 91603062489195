/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */
// eslint-disable-next-line

import {
	partner,
	platform,
	compound,
	partner_compound,
	platform_compound,
} from 'messages/EN/pages_branding';

const pages = {
	/* Nav */
	// 'rod.nav.link.what_is_rod': 'что такое ROD',
	'rod.nav.link.features': 'opportunities',
	'rod.nav.link.terms': 'terms of usage',
	'rod.nav.link.about': 'about the service',
	'rod.nav.link.faq': 'faq',
	'rod.nav.link.articles': 'articles',
	'rod.nav.link.h11': `${platform_compound.title_powered}`,
	'rod.nav.link.support': 'support',

	'rod.header.link.register': 'registration',
	'rod.header.link.login': 'login',
	'rod.header.menu': 'menu',
	'rod.header.admin': 'admin area',
	'rod.header.account_search': 'account search',
	'rod.header.account_not_found': 'account not found',

	/* Wizard steps*/
	'rod.account.create.step_1.title': 'user registration',
	'rod.account.create.step_2.title': 'account registration',
	'rod.account.create.step_3.title': 'join services',

	'rod.account.create.step_2_1_individual.title': 'Private details',
	'rod.account.create.step_2_1_sole_proprietor.title':
		'Sole proprietor details',
	'rod.account.create.step_2_1_legal_entity.title': 'Company details',
	'rod.account.create.step_2_2.title': 'Contact details',
	'rod.account.create.step_2_3.title': 'Documents',
	'rod.account.create.step_2_4.title': 'Identifiers',
	'rod.account.create.step_2_5.title': 'Banking details',

	'rod.account.create.step_3_1.title': 'agreement details',
	'rod.account.create.step_3_2.title': 'agreement execution',
	'rod.account.create.step_3_3.title': 'role selection',
	'rod.account.create.license-agreement': 'Licensing agreement',
	'rod.account.create.single-song-agreement':
		'Single-song publishing agreement',
	'rod.account.create.publishing-agreement': 'Publishing agreement',
	'rod.account.create.sub-publishing-agreement': 'Sub-publishing agreement',
	'rod.account.create.license-agreement.whole-world': 'Whole World',

	/* Landing page */
	'rod.landing.header.text': `Join${partner.title} and receive:`,
	'rod.landing.header.text.mts': `Join${partner.title} and receive:`,
	'rod.landing.header.subtext_1':
		'Maximum royalties for the usage of your repertoire',
	'rod.landing.header.subtext_2': 'Flexible licensing terms',
	'rod.landing.header.subtext_3': 'Detailed reports on repertoire usage',
	'rod.landing.form.have_an_account': `Already have an account${compound.title_with}?`,
	'rod.landing.form.have_not': 'Don’t have an account? ',
	'rod.landing.form.action.login': 'Log in',
	'rod.landing.form.action.register': 'Register',
	'rod.landing.footer.text':
		'do you have a large catalogue or fan base? <a>Send us a request</a> and we will prepare a tailored proposal.',
	'rod.landing.footer.link.send_request': 'Send us a request',
	'rod.landing.footer.link.leave_request': 'leave a request',
	'rod.landing.footer.text_title': 'do you have a large catalogue or fan base?',
	'rod.landing.footer.text_body': 'leave a request for a personal offer',

	/* Login */
	'rod.login.header': 'login',
	'rod.login.register.header': 'register',
	'rod.login.text.have_not_account_yet': 'don’t have an account yet?',
	'rod.login.text.forgot_password': 'Forgot password?',

	/* Registration */
	'rod.register.text.already_have_account_title': 'login',
	'rod.register.text.already_have_account': `already have an account${compound.title_with}`,
	'rod.register.header': 'registration',
	'rod.register.subheader': `create your${partner.title} account`,
	'rod.register.terms':
		'I agree with the <a>Terms of personal data processing</a>',
	// 'I agree with the Terms of personal data processing',
	'rod.register.terms_no_link':
		'I agree with the Terms of personal data processing',
	'rod.register.terms.anchor': 'Terms of personal data processing',
	'rod.registration.success.header': 'thank you!',
	'rod.registration.success.subheader':
		'we will contact you shortly and help you with the registration.',
	'rod.registration.special.header': 'Personal service activation',
	'rod.registration.special.subheader':
		'Do you have 1000 or more compositions? Over 100 000 fans in social networks? Please send us a request and we will make you a tailored proposal, set up your account and add your entire repertoire.',
	'rod.registration.special.success.header': 'thank you for your request!',
	'rod.registration.special.success.body':
		'we will contact you shortly to discuss the details of the tailored proposal. ',

	/* Auth pages (SMS/Email-verification, Restore password, etc)*/
	'rod.change_password.header': 'Change password',
	'rod.restore_password.header': 'password recovering',
	'rod.restore_password.subtitle':
		'If you registered before 05.05.2022 please contact support to reset your password',
	'rod.restore_password.email.header': 'check your email!',
	'rod.restore_password.email.subheader':
		'An email with your password will be send to you shortly. ',
	'rod.no_code.header': 'did not receive the SMS code?',
	'rod.no_code.header.email': 'did not receive the Email code?',
	'rod.no_code.subheader':
		' If you did not receive the SMS or email code, ' +
		'or have difficulties creating an account, please, use online chat in widget ' +
		'at the bottom of the page.<br/><br/>' +
		'Tell us your email and phone number, you filled in at registration, and describe the issue.<br/><br/>' +
		'We will be glad to help!',
	'rod.no_code.text.i_want_you_contact_me': 'I would like to be contacted',
	'rod.special.header':
		'Special conditions and help for large companies and rights holders',
	'rod.special.text.i_am_ready_to_provide': `I am ready to provide${partner.title} more ### ### songs for licensing or with royalties above $### ###`,
	'rod.verify_sms.header': 'please enter the SMS code',
	'rod.verify_sms.subheader': 'We sent your code to the number',
	'rod.verify_sms.no_code': 'Code not received',
	'rod.verify_sms.send_again': 'send again',
	'rod.verify_email.header': 'thank you for confirming your phone!',
	'rod.verify_email.action': 'Put the code manually',
	'rod.verify_email.subheader':
		'An email with confirmation code and a link have been sent to you. Please enter a code or follow the link in the email to confirm user details and proceed to account registration.',
	'rod.verify_email.no_code': 'Email not received',
	'rod.verify_email.send_again': 'Re-send code',
	'rod.text.special.success':
		'Thank you for your application, We will be back to you shortly to negotiate an individual conditions.',
	'rod.text.help.success':
		'Thank you for you information. We will be back to you to help with registration.',
	'rod.text.register.success':
		'Perfect ! An email with link have been sent to you to login into your account.',
	'rod.text.change_password.success': 'Password successfully changed',
	'rod.text.forgot.success': 'password changed',
	// 'rod.text.forgot.fail': 'this link has expired',
	'rod.text.forgot.fail':
		'Code is invalid or was already used. Please try again or contact support if issue persists.',
	'rod.captcha.description':
		'This site is protected by reCAPTCHA and the Google',
	'rod.captcha.privacy': 'Privacy Policy',
	'rod.captcha.and': 'and',
	'rod.captcha.terms': 'Terms of Service apply',

	/* Accounts */
	'rod.account.header': `you already have an account${platform_compound.title_space_with}. Please, choose which of the accounts below would you like to use to join services${partner_compound.title_solid_of}, or create a new one to join`,
	'rod.account.sub_header': 'You can switch between accounts in your profile',
	'rod.account.header.have_not_account_yet':
		'please create your account to join the service',
	'rod.account.sub_header.have_not_account_yet':
		'you can add more than one account',
	'rod.account.create.who_are_you.header': 'please tell us more about yourself',
	'rod.account.create.who_are_you.title': 'whose repertoire do you represent?',
	'rod.account.create.who_are_you.self': 'I represent my own repertoire',
	'rod.account.create.who_are_you.shared':
		'I represent repertoire of other parties',
	'rod.account.create.who_are_you.all':
		'I represent both my own and other parties’ repertoire',

	'rod.ref.roles.words_author.title':
		'I am an author of lyrics and would like to receive royalties for compositions where they are used',
	'rod.ref.roles.words_author.summary': 'Author',
	'rod.ref.roles.music_author.title':
		'I am music composer and would like to receive royalties for compositions where it is used',
	'rod.ref.roles.music_author.summary': 'Composer',
	'rod.ref.roles.vocal_performer.title':
		'I am a performer: I took part in the recording of a song, and would like to receive royalties for its usage',
	'rod.ref.roles.vocal_performer.summary': 'Performer',
	'rod.ref.roles.producer.title':
		'I am a producer: I organized the recording of a song (as a private entity) and would like to receive royalties for the usage of this recording',
	'rod.ref.roles.producer.summary': 'Producer',
	'rod.ref.roles.publisher.title':
		'I am music publisher: I represent interests of other parties (music composers, lyrics authors or other publishers)',
	'rod.ref.roles.publisher.summary': 'Publisher',
	'rod.ref.roles.sub_publisher.summary': 'subpublisher',
	'rod.ref.roles.record_company.title':
		'I am record label: I represent interests of other parties (performers, musicians, producers and other labels)',
	'rod.ref.roles.record_company.summary': 'Record label',
	'rod.ref.roles.instrumental_performer.title':
		' instrumental performer description',
	'rod.ref.roles.instrumental_performer.summary': 'instrumental performer',

	'rod.account.create.sign_as.title': 'I sign agreements as',
	'rod.account.create.sign_as.individual': 'Individual',
	'rod.account.create.sign_as.sole_proprietor': 'Sole entrepreneur',
	'rod.account.create.sign_as.legal_entity': 'Company',

	'rod.account.create.confirm_role.form.header':
		'please choose what applies to you:',
	'rod.account.create.confirm_role.summary.header':
		'based on your choices you are:',

	'rod.account.personal.title': 'personal information',
	'rod.account.personal_entrepreneur.title': 'Sole entrepreneur details',
	'rod.account.company.title': 'Company details',

	'rod.account.create.contacts.title': 'Contact details',

	'rod.account.create.documents.passport.title': 'Passport',
	'rod.account.create.documents.passport.photo_page': 'Page with photo',
	'rod.account.create.documents.passport.address_page':
		'Page with residence registration',

	'rod.account.create.documents.driver_license.title': 'Driving license',
	'rod.account.create.documents.driver_license.front_side': 'Front side',
	'rod.account.create.documents.driver_license.back_side': 'Rear side',

	'rod.account.create.documents.pe.title':
		'Sole Trader registration certificate',
	'rod.account.create.documents.pe.registration': 'Registration certificate',

	'rod.account.create.documents.company.title':
		'Company Incorporation documents',
	'rod.account.create.documents.company.registration':
		'Certificate of incorporation',
	'rod.account.create.documents.company.tax': 'Tax residence certificate',
	'rod.account.create.documents.list_title': 'upload',
	// 'rod.account.create.documents.list_title': 'select and upload',

	'rod.account.create.moderation.pending.header': 'Thank you!',
	'rod.account.create.moderation.pending':
		'Your details sent for verification. It’ll take some time. Once it is done, we will send you an e-mail notification, so you could proceed to service selection.',
	'rod.account.create.moderation.pending_contracts':
		'Your countersigned agreements sent for verification. It’ll take some time. Once it is done, we will send you an e-mail notification, so you could proceed to service selection.',

	'rod.account.create.payments.header': 'royalty payment details',

	'rod.account.create.societies.header': 'societies',
	'rod.account.create.societies.sub_header':
		'please choose the name of the society which you are a part of.',
	'rod.account.create.identifiers.header': 'identifiers',
	'rod.account.create.identifiers.sub_header':
		'if you don’t know what an identifier is and how to use it, select “?”. If you do not have some of identifiers, please leave that fields blank and go  “Next”.',
	'rod.account.create.identifiers.name.header': 'Name',
	'rod.account.create.identifiers.pseudonyms.header': 'Pseudonyms',
	'rod.account.add_role': 'Add rights',
	'rod.account.add_service_btn': 'add service',
	'rod.account.choose_service_btn': 'choose service',
	'rod.account.choose_few_services_btn': 'choose services',
	'rod.account.save_to_draft_btn': 'save to draft',
	'rod.account.add_service_banner_title': 'add the service',
	'rod.account.add_role_banner_release_not_available_title':
		'publication of the release is not available',
	'rod.account.add_role_banner_release_not_available_text':
		'to upload a release to outlets, add a distribution service',
	'rod.account.add_role_banner_composition_not_available_title':
		'publication of the work is not available',
	'rod.account.add_role_banner_composition_not_available_text':
		'чтобы загрузить релиз на витрины, подпишите лицензионный договор',
	'rod.account.add_role_banner_release_not_available_moderation':
		'you have a moderation contract. Please wait until moderation is over and try again. Now you can save the release in drafts and return to it later.',
	'rod.account.add_role_banner_composition_not_available_moderation':
		'you have a moderation contract. Please wait until moderation is over and try again. Now you can save the composition in drafts and return to it later.',
	'rod.account.add_role_banner_composition_has_not_contracts':
		"to upload a work, add author's rights and lyrics services.",
	'rod.account.add_service_banner_text':
		'you can add new services to your account and use the full list of service features',

	// NavBar
	'rod.navbar.main': 'general',
	'rod.navbar.accounts': 'accounts',

	'rod.navbar.finances': 'finances',
	'rod.navbar.dispute': 'disputes',
	'rod.navbar.treaties': 'contracts',

	'rod.navbar.repertoire': 'repertoire',
	'rod.navbar.reports': 'reports',
	'rod.navbar.withdraw': 'fund withdrawal',
	'rod.navbar.statistic': 'statistic',
	'rod.navbar.statistic_reports': 'Statistic reports',
	'rod.navbar.admin.statistics_service': 'statistics service',
	'rod.navbar.handling': 'handling',
	'rod.navbar.handling.drafts': 'drafts',
	'rod.navbar.handling.release': 'release export',

	// Notification View
	'rod.notification.title': 'notifications',
	'rod.notification.empty': 'No new notifications',
	'rod.notification.mark_all': 'Mark all as read',
	'rod.notification.mark_one': 'Mark as read',
	'rod.notification.list': 'notification list',
	'rod.notification.add': 'add notification',

	'rod.composition.contract.empty': 'Please <a>select</a> contracts',
	'rod.composition.contract.create_new': 'Add new contract',
	'rod.composition.contract.selected': 'selected',
	'rod.composition.contract.Continue': 'Continue',
	'rod.composition.contract.Clear': 'Clear',

	/* Contracts */
	'rod.contracts.publishing': 'Publishing contract',
	'rod.contracts.sub': 'Sub-publishing contract',
	'rod.contracts.all_recipients': 'all users',
	'rod.contracts.add.header': 'choose parameters of the agreements',
	'rod.contracts.add.header_add_service':
		'choose additional settings for the submitting of the service',
	'rod.contracts.add.subheader':
		'This is a frame agreement – when you upload your repertoire you will be able to exclude specific countries and platforms.',
	'rod.contracts.sms_sign.title': 'SMS code',
	'rod.contracts.sms_sign.subtitle':
		'We sent your code to the number<br/> {value}',
	'rod.contracts.sms_sign.description':
		'Entering code, I agree to sign this agreement. (hash document: {value}). Text message (SMS) equal to your signature.',

	'rod.contracts.use_types.digital.title': 'Digital exploitation',
	'rod.contracts.use_types.public_performance.title': 'Public performance',
	'rod.contracts.use_types.synchronization.title': 'Synchronization',
	'rod.contracts.use_types.lyrics.title': 'Lyrics',
	'rod.contracts.media_types.recording.title': 'Recordings',
	'rod.contracts.media_types.composition.title': 'Compositions',
	'rod.contracts.add.sign_as.header': 'I sign the agreement as',
	'rod.contracts.add.conditions.header': 'the agreement includes ',
	'rod.contracts.add.conditions.subheader':
		'To have an agreement, chose at least one of the services proposed below',
	'rod.contracts.processing': 'Processing...',

	'rod.contracts.sign_sms.header': 'sign documents online',
	'rod.contracts.sign_sms.subheader':
		'this is a general agreement and you can chose , what songs to attach for service , after you register your repertoire',
	'rod.contracts.sign.help':
		'Please sign the contracts with your signature using the standard tool for signing documents in Acrobat Reader or another PDF editor, <b>not the function for adding comments.</b> <br/> <br/> Also, <b>sign each page</b> of each contract at the bottom - <br/>(Sign here: )',

	'rod.contracts.sign.header': 'read, download and sign the agreements',
	'rod.contracts.sign.subheader':
		'this is a general agreement and you can chose , what songs to attach for service , after you register your repertoire',
	'rod.contracts.complete.header': 'Done!',
	'rod.contracts.complete.subheader_sms': `You have successfully undersigned all agreements.<br/>Welcome${partner_compound.title_space_to}`,
	'rod.contracts.complete_signed.subheader':
		'Below you can download countersigned agreements and proceed to your account. ',
	'rod.contracts.complete.subheader': 'Your documents sent for verification.',
	'rod.contracts.no_code.header': 'Thank you!',
	'rod.contracts.no_code.body':
		'We’ll be back to you shortly to help to receive a SMS code.',
	'rod.contracts.no_contract.header':
		'You don`t have a contract with our company.',
	'rod.contracts.no_contract.body':
		'Please contact with our Support Team to sign the contract and get access.',
	'rod.contracts.contract_overdue.header': 'Your contract is overdue.',
	'rod.contracts.contract_overdue.body':
		'Please contact with our Support Team to sign the contract and get access.',
	'rod.contracts.next.header': 'thank you!',
	'rod.contracts.next.subheader':
		'You have successfully undersigned {values}. To finish, please sign remaining agreements.',
	'rod.field.contracts.duration.header': 'Agreement period',
	'rod.field.contracts.duration.placeholder': 'Agreement period',
	'rod.field.contracts.currency.header': 'Agreement currency',
	'rod.field.contracts.currency.placeholder': 'Agreement currency',
	'rod.field.contracts.sign_type.header': 'Choose signature type',
	'rod.field.contracts.sign_type.placeholder': 'Signature type',

	'rod.field.contracts.contract_status.title': 'status',
	'rod.field.contracts.contract_status.value.new': 'new',
	'rod.field.contracts.contract_status.value.signing': 'signing',
	'rod.field.contracts.contract_status.value.signed': 'signed',
	'rod.field.contracts.contract_status.value.moderation': 'moderation',
	'rod.field.contracts.contract_status.value.in_processing': 'in processing',
	'rod.field.contracts.contract_status.date': 'date',

	'rod.field.contracts.contract_download': 'Download document',
	'rod.field.contracts.contract_download_signed': 'Download signed document',
	'rod.account.create.complete.text':
		'Thank you !<br /><br />Your details sent for verification. It’ll take some time. Once it is done, we will send you an e-mail notification, so you could proceed to service selection. ',

	'rod.contracts.upload.title': 'document upload',
	'rod.contracts.upload.text': '<u>Select</u> or drag and drop documents ',
	'rod.contracts.territory_select.header':
		'specify, which countries/territories, would you like to exclude from service',

	'rod.repertoire.welcome.title': `welcome${partner_compound.title_space_to}`,
	'rod.repertoire.welcome.title.widget_error': `Dear customers!`,
	'rod.repertoire.legacy.welcome.title': `welcome${partner_compound.title_space_to}`,
	'rod.repertoire.welcome.content': `
		To provide you with our services, we need you to send us your repertoire information.
		<br/><br/>
		Should you send us 10 and more releases, sound recordings or compositions, please download a repertoire form and instructions on how to fill-in repertoire form, available on the right. You may also upload CWR files (versions supported: V21, V22)
		<br/><br/>
		Please read the instructions , fill in your repertoire details and upload form here.
		<br/><br/>
		Should you need any help, please browse around help and FAQ topics on our knowledge base or communicate to us, using widget below on the right.
	`,
	'rod.repertoire.welcome.content.part': `
	To provide you with our services, we need you to send us your repertoire information.
	<br/><br/>
	Please read the instructions , fill in your repertoire details and upload form here. You may also upload CWR files (versions supported: 2.1, 2.2).
	<br/><br/>
	Should you need any help, please browse around help and FAQ topics on our knowledge base or communicate to us, using widget below on the right.
`,
	'rod.repertoire.welcome.content.part.dgtal': `
	To provide you with our services, we need you to send us your repertoire information.
	<br/><br/>
	Please read the instructions , fill in your repertoire details and upload form here. You may also upload CWR files (versions supported: 2.1, 2.2).
	<br/><br/>
	Should you need any help, please browse around help and FAQ topics on our knowledge base or communicate to us, using mail.
`,
	'rod.repertoire.legacy.welcome.content.part': `
	We are now upgrading to a new version of personal area. To access a new dashboard, please log in with your email and${platform.title} password that was previously sent to you, or use Forgot password function on login form to reset it.
	<br/><br/>
	Meanwhile, you can continue using forms using the links on the right.
	<br/><br/>
	Should you need any help, please browse around help and FAQ topics on our knowledge base or communicate to us, using widget below on the right.
`,
	'rod.repertoire.welcome.download': 'Download files',
	'rod.repertoire.welcome.form': 'Fill online forms',

	'rod.repertoire.uploaded.title': 'Thank you.',
	'rod.repertoire.legacy.uploaded.title': 'Thank you.',
	'rod.repertoire.uploaded.content': `Your repertoire file has passed into processing. Once processing is done we’ll notify you on next steps.
	<br/><br/>
	Should you need any help, please browse around help and FAQ topics on our knowledge base or communicate to us, using widget below on the right.
	`,
	'rod.repertoire.legacy.uploaded.content': `Your repertoire file has passed into processing. Once processing is done we’ll notify you on next steps.
	<br/><br/>
	Should you need any help, please browse around help and FAQ topics on our knowledge base or communicate to us, using widget below on the right.
	`,

	'rod.field.contracts.pdf.subheader':
		'read the contract carefully and confirm the conditions by clicking checkbox at the page bottom',
	'rod.field.additional_contracts': 'Additional contracts',
	'rod.field.payments_withdrawal': 'Payments withdrawal',

	// for all
	'rod.for_all.title': 'title',
	'rod.for_all.author': 'author',
	'rod.for_all.share': 'share',
	'rod.for_all.performer': 'performer',
	'rod.for_all.performers': 'performers',
	'rod.for_all.date': 'date',
	'rod.for_all.type': 'type',
	'rod.for_all.status': 'status',
	'rod.for_all.subtitle': 'subtitle',
	'rod.for_all.close': 'close',
	'rod.repertoire.input_performers': 'performers',
	'rod.repertoire.input_releases': 'releases',

	/* Repertoire */
	'rod.repertoire.drafts': 'drafts',
	'rod.repertoire.composition': 'compositions',
	'rod.repertoire.recordery': 'recordings',
	'rod.repertoire.releases': 'releases',

	'rod.repertoire.status.disputed': 'disputed',
	'rod.repertoire.status.not_ready': 'not ready',
	'rod.repertoire.status.delivered': 'shipped',
	'rod.repertoire.status.ready': 'ready',
	'rod.repertoire.status.rejected': 'rejected',
	'rod.repertoire.status.pending': 'pending',
	'rod.repertoire.status.approved': 'ready for delivery',

	'rod.repertoire.all': 'Total',
	'rod.repertoire.declared': 'Claimed',
	'rod.repertoire.not_declared': 'Not claimed',
	'rod.repertoire.ready': 'Ready',
	'rod.repertoire.disputed': 'Disputed',
	'rod.repertoire.not_ready': 'Not ready',
	'rod.repertoire.rejected': 'Rejected',
	'rod.repertoire.pending': 'Moderation',
	'rod.repertoire.approved': 'Approved',
	'rod.repertoire.rejected.tab': 'Rejected',
	'rod.repertoire.approved.tab': 'Approved',
	'rod.repertoire.delivered.tab': 'Delivered',
	'rod.repertoire.shipped': 'Delivered',
	'rod.repertoire.takendown.tab': 'Takendown',
	'rod.repertoire.takendown': 'Takendown',
	'rod.repertoire.takedown_queue': 'Takedowning',
	'rod.repertoire.shipping_queue': 'Delivering',
	'rod.repertoire.outlets.status.shipping_queue': 'delivering',
	'rod.repertoire.outlets.status.takedown_queue': 'takedowning',
	'rod.repertoire.outlets.status.shipping': 'shipped',
	'rod.repertoire.outlets.status.takedown': 'Takendown',
	'rod.repertoire.draft_processing_or_verify': 'Pending review',
	'rod.repertoire.draft_processing': 'Processing',
	'rod.repertoire.draft_verify': 'Pending review',
	'rod.repertoire.verify': 'Pending review',
	'rod.repertoire.expiring': 'Expiring',

	'rod.repertoire.in_dispute': 'Disputed',
	'rod.repertoire.delivered': 'Delivered',

	'rod.repertoire.search_by_author_and_name': 'Search by artist or title',
	'rod.repertoire.compositions.search_by_author': 'Search by author or title',

	'rod.releases.title':
		'<p>important Information about Editing Releases.</p> <br/> <p>You can edit your release until 23:00 UTC while it is in the "processing" status. After that, it will under go moderation and become non-editable until publication.</p>',

	'rod.repertoire.clear_all': 'Clear all',
	'rod.drafts.title':
		'<p>To continue working on release draft, click on the item in the list.</p> <br/> <p>After passing moderation, the release will appear in the repertoire section. Moderation usually takes up to 48 hours, please expect.</p>',
	'rod.drafts.delete': 'delete',
	'rod.drafts.delete-confirm': 'confirm deletion',
	'rod.drafts.delete-help':
		'you won’t be able to restore draft files/metadata after deletion',
	'rod.drafts.delete-cancel': 'cancel',

	/* Statistic */
	'rod.statistic.help':
		'<p>Please note that daily play statistic is indicative data and may not exactly match storefront reporting data for the respective period.</p><br><p>Daily stats are currently available for Apple Music and Spotify. We plan to add more outlets in the future. Stay tuned!</p>',
	'rod.statistic.notification':
		'<p>Statistics service is currently in beta version and shows daily statistics from outlets. This data may be not the same as final data in your reports. </p><br><p>If release is missing or daily data has large discrepancies with yours, please notify our support.</p>',
	'rod.statistic.reports_title': 'title',
	'rod.statistic.reports_period': 'period',
	'rod.statistic.reports_date': 'date of recieving',
	'rod.statistic.reports_action': 'action',
	'rod.statistic.by_date': 'by date',
	'rod.statistic.by_territory': 'by territory',
	'rod.statistic.compare': 'compare tracks',
	'rod.statistic.outlets': 'outlets',
	'rod.statistic.tracks': 'tracks',
	'rod.statistic.start-date': 'start date',
	'rod.statistic.end-date': 'end date',
	'rod.statistic.chart-title': 'streams',
	'rod.statistic.chart-amount-units': 'units',
	'rod.statistic.chart-amount-thousands': 'thousands',
	'rod.statistic.chart-amount-millions': 'millions',
	'rod.statistic.chart-accumulative': 'cumulative',
	'rod.statistic.chart-comparative': 'comparison',
	'rod.statistic.chart-total': 'Total',
	'rod.statistic.value': 'Value',
	'rod.statistic.no-data': 'no data',
	'rod.statistic.tracks-selected': 'Selected',
	'rod.statistic.tracks-selected-tracks': 'tracks',

	'rod.statistic.top_tracks': 'top tracks',
	'rod.statistic.top.track': 'track',
	'rod.statistic.top.performer': 'performer',
	'rod.statistic.top.album': 'album',
	'rod.statistic.top.streams': 'streams',
	'rod.statistic.download': 'download',

	'rod.statistic.search-title': 'search here',
	'rod.statistic.search': 'Search',
	'rod.statistic.search-all': 'All',
	'rod.statistic.search-not-found': 'Not Found',
	'rod.statistic.checked-tracks': 'Checked tracks',
	'rod.statistic.help-title': 'Help',
	'rod.statistic.preparing': 'Preparing...',
	'rod.statistic.top-ten': 'Auditions top',

	// произведение
	'rod.composition.tab.main': 'General',
	'rod.composition.tab.contacts': 'Contracts',
	'rod.composition.tab.recordery': 'Recordings',
	'rod.composition.tab.releases': 'Releases',
	'rod.composition.tab.not_ready': 'Incomplete',

	'rod.composition.empty': 'Please <a>add</a> compositions',
	'rod.composition.info.title': 'information',
	'rod.composition.info.creation_date': 'creation date',
	'rod.composition.info.sales_start_date': 'sell start date',
	'rod.composition.info.catalog_number': 'catalog number',
	'rod.composition.info.lyrics_language': 'lyrics language',
	'rod.composition.info.genres': 'genres',
	'rod.composition.info.composition_type': 'composition type',
	'rod.composition.info.creation_territory': 'creation territory',
	'rod.composition.info.rightholder': 'rightholder',
	'rod.composition.info.role': 'role',
	'rod.composition.info.territory': 'territory',
	'rod.composition.info.exploitation_type': 'exploitation type',
	'rod.composition.info.use_type': 'use type',
	'rod.composition.info.product': 'product',
	'rod.composition.info.commercial_model': 'commercial model',
	'rod.composition.info.Show_all': 'Show all',
	'rod.composition.info.original_rightholders': 'original rightholders',
	'rod.composition.info.licensees': 'licensees',
	'rod.composition.info.distribution_channel': 'distribution channel',
	'rod.composition.info.marketing_channel': 'marketing channel',
	'rod.composition.info.interface_type': 'interface type',
	'rod.composition.info.societies': 'societies',
	'rod.composition.info.start_date': 'start date',
	'rod.composition.info.report_date': 'report date',
	'rod.composition.info.Collapse': 'Collapse',

	'rod.composition.contract.modal.title': 'contracts',
	'rod.composition.contract.modal.create_new': 'Add new contract',
	'rod.composition.contract.modal.selected': 'selected',
	'rod.composition.contract.modal.Continue': 'Continue',
	'rod.composition.contract.modal.Clear': 'Clear',
	'rod.composition.contract.condition': 'condition',
	'rod.composition.contract.period': 'period',
	'rod.composition.contract.collection': 'collection',

	'rod.composition.recordery.empty':
		'Please <a>select</a> or <cta>add</cta> new recording',
	'rod.composition.recordery.select_from_list': 'select from list',
	'rod.composition.recordery.Add': 'Add',
	'rod.composition.recordery.category': 'category',
	'rod.composition.recordery.date': 'date',
	'rod.composition.recordery.Audio': 'Audio',
	'rod.composition.recordery.Cover': 'Cover',
	'rod.composition.recordery.Genre': 'Genre',

	'rod.composition.dispute.error_body':
		'this work is in a dispute where two or more parties have claimed rights in total exceeding 100%. The display of shares will be available only after the resolution of the dispute between the parties.',

	'rod.composition': 'composition',
	'rod.composition.publication_is_not_available.title':
		'publication of composition is not available',
	'rod.composition.publication_is_not_available.text':
		'to upload a composition, sign author`s and sub-publishing agreements first.',
	'rod.composition.sign_agreement': 'sign agreement',

	'rod.composition.filter.active': 'active',
	'rod.composition.filter.expired': 'expired',

	// ФОНОГРАММЫ //
	'rod.recordery.tab.main': 'General',
	'rod.recordery.tab.contacts': 'Contracts',
	'rod.recordery.tab.audio': 'Audio',
	'rod.recordery.tab.composition': 'Compositions',
	'rod.recordery.tab.releases': 'Releases',
	'rod.recordery.tab.notices': 'Notices',

	'rod.recordery.empty': 'Pleases <a>add</a> recordings',
	'rod.recordery.info.recording_type': 'recording type',
	'rod.recordery.info.recorded_in_country': 'recorded in country',
	'rod.recordery.info.parents_notice': "parents' notice",
	'rod.recordery.info.exceptions': 'exceptions',
	'rod.recordery.info.format': 'format',
	'rod.recordery.info.length': 'length',
	'rod.recordery.info.size': 'size',
	'rod.recordery.info.dropzone': 'Select or drag & drop recording file',
	'rod.recordery.info.delete': 'Delete',
	'rod.recordery.info.release': 'release',

	// РЕЛИЗЫ //

	'rod.release.tab.main': 'General',
	'rod.release.tab.contacts': 'Contracts',
	'rod.release.tab.moderation': 'Moderation',
	'rod.release.info.deliveries': 'Deliveries',
	'rod.release.info.sale_start_dates': 'Sale start dates',
	'rod.release.tab.not_ready': 'Not ready',

	'rod.release.moderation.approved': 'release has been successfully approved',
	'rod.release.moderation.pending': 'release is currently under moderation',
	'rod.release.moderation.declined': 'release declined',
	'rod.release.moderation.declined.help': 'A hint will appear here soon',

	'rod.release.moderation.issues': 'issues',
	'rod.release.moderation.cover': 'cover',
	'rod.release.moderation.release_metadata': 'release<br>metadata',
	'rod.release.moderation.track_metadata': 'track<br>metadata',
	'rod.release.moderation.audio': 'audio',
	'rod.release.moderation.track': 'track',

	'rod.release.empty': 'Please <a>add</a> releases',
	'rod.release.info.sort': 'sort',
	'rod.release.info.ready_for_delivery': 'Ready for delivery',
	'rod.release.info.delivery_in_progress': 'delivery in progress',
	'rod.release.info.delivered': 'delivered',
	'rod.release.info.disputed': 'disputed',
	'rod.release.info.Apply': 'Apply',
	'rod.release.info.label': 'label',
	'rod.release.info.created_in_country': 'created in country',
	'rod.release.info.recordings': 'recordings',
	'rod.release.info.tracks': 'tracks',
	'rod.release.info.Upload_cover_image':
		'<span>Upload</span> <span>cover image<span>',
	'rod.release.info.no-cover': 'No cover',
	'rod.release.info.downloading_with_bundle_only':
		'downloading with bundle only',
	'rod.release.info.outlet': 'outlet',
	'rod.release.info.upload_file': 'Upload file',
	'rod.release.info.country_region': 'country/region',
	'rod.release.info.sale_start_date': 'sale start date',
	'rod.release.release_shipment': 'Ship the release',
	'rod.release.release_shipment_btn': 'Ship release',
	'rod.release.release_shipment_inProcess': 'Delivering',
	'rod.release.release_fallback': 'Release taken down',
	'rod.release.release_fallback_enable': 'Take down the release ',
	'rod.release.release_fallback_inProcess': 'Taking down',

	// release create

	'rod.release.create.step.release': 'Release',
	'rod.release.create.step.files': 'Files',
	'rod.release.create.step.tracks': 'Tracks',
	'rod.release.create.step.authors': 'Authors',
	'rod.release.create.step.lyrics': 'Lyrics',
	'rod.release.create.step.cover': 'Cover',
	'rod.release.create.step.distribution': 'Distribution',
	'rod.release.create.step.confirm': 'Сonfirm',
	'rod.composition.create.step.info': 'Info',
	'rod.composition.create.step.authors_shares': 'Authors Shares',
	'rod.composition.create.step.composition_recordings': 'Performances',
	'rod.release.create.step.handler_back': 'Previous step',
	'rod.release.create.step.handler_next': 'Next',

	//drafts statuses
	'rod.draft.release.moderation': 'Processing',
	'rod.draft.release.completed': 'Processing',
	'rod.draft.release.parsed': 'Processing',
	'rod.draft.release.check': 'Verification',
	'rod.draft.release.update': 'Verification',
	'rod.draft.release.unknownstatus': 'Verification',

	// release help texts

	'rod.release.create.step.release.description':
		'We do not recommend using emoji or other special characters in release title.<br/><br/>If there are multiple main artists, please enter them comma-separated.<br/><br/>Guest artists were accepted through "feat".<br>If there are more than 4 artists in the release, then you must specify "Different artists". This applies to all release types.<br/><br/>If there is no exact creation date, use 01.01 of creation year as creation date. If release was previously distributed, please fill release UPC (EAN).',
	'rod.release.create.step.files.description':
		'Accepted audio formats : FLAC 44100 Hz 24 bit or WAV 44100 Hz 16 bit',
	'rod.release.create.step.cover.description':
		'Accepted cover file format: JPEG, PNG. According to the requirements of outlets, the size of the image must be at least 1500x1500 pixels. Please note that the recommended size for some outlets is from 3000x3000 pixels.',
	'rod.release.create.step.distributions.description':
		'Delivering release to outlets takes some time. Plan ahead to make sure that you achieve release availabilty at desired date.',
	'rod.release.create.step.distributions.description.transfer':
		'If you need to transfer release that was already publisher, use “transfer release” option',

	// release create release

	'rod.release.create.step.release.label.name': 'Title',
	'rod.release.create.step.release.label.subtitle': 'Subtitle',
	'rod.release.create.step.release.label.relise_type': 'Release type',
	'rod.release.create.step.release.label.genre': 'Genres',
	'rod.release.create.step.release.label.main_executor': 'Release artists',
	'rod.release.create.step.release.label.label': 'Label',
	'rod.release.create.step.release.label.create_date': 'Creation date',
	'rod.release.label.takendown_date': 'takendown date',
	'rod.release.create.step.release.label.create_country': 'Creation country',
	'rod.release.create.step.release.label.identifiers': 'identifiers',
	'rod.release.create.step.release.label.catalog_number': 'Catalog number',
	'rod.release.create.step.release.label.auto': 'Assign automatically ',
	'rod.release.label.takendown_reason': 'takendown reason',
	'rod.release.label.takendown_rightholder_wish': 'rightsholder request',
	'rod.release.label.takendown_rights_violation': 'copyright infringement',
	'rod.release.label.takendown_agreement_termination': 'relinquishment',
	'rod.release.label.takendown_indicate_reason': 'specify the reason',

	// release create files

	'rod.release.create.step.recording.title': 'add tracks to release',
	'rod.release.create.step.recording.list_button':
		'или <a>выберите из списка</a>',
	'rod.release.create.step.recording.helper_title':
		'file formats and requirements',
	'rod.release.create.step.recording.helper_main':
		'Accepted audio formats : FLAC 44100 Hz 24 bit or WAV 44100 Hz 16 bit',

	// release create tracks / authors

	'rod.release.create.step.tracks.title': 'track information',
	'rod.release.create.step.tracks.title.help': `
	<p>The text must match the audio version of the song.</p>
	<p>Each new line of text should start with a capital letter. Separate verses and choruses by indentation, do not specify parts of songs (verse, chorus, numbering, and similar)</p>
	<p>If a line is repeated in it, it should be reflected in the text</p>
	<h4><b>Correct option:</b></h4>
	The waves inside me are strong<br>
	The sun lives in me<br>
	The sun lives in me<br>
	<br>
	<h4><b>Incorrect option:</b></h4>
	The waves inside me are strong<br>
	The sun lives in me<br>
	(2 times)<br>
	`,
	'rod.release.create.step.tracks.fragment.title': 'Use fragment',
	'rod.release.create.step.tracks.fragment.help':
		'You can select a snippet of your track to use as a preview or ringtone on certain platforms: TikTok: 60 seconds, Ringtone: 30 seconds, Ringback tone: 45 seconds. Choose the second at which the fragment should start, and it will be saved automatically.',
	'rod.release.on_verify.fragment.help':
		'"Under Review" indicates that your release has been created but has not yet completed preliminary review before moderation.',
	'rod.compositions.on_verify.fragment.help':
		'"Under Review" indicates that your composition has been created but has not yet completed preliminary review before moderation.',
	'rod.release.create.step.tracks.fragment.start': 'Start',
	'rod.release.create.step.tracks.fragment.end': 'End',
	'rod.release.create.step.tracks.contributors': 'Recording contributors',
	'rod.release.create.step.author.title': 'authors and shares',
	'rod.release.create.step.author.title.help': 'A hint will appear here soon ',
	'rod.release.create.step.author.share.help': 'A hint will appear here soon ',
	'rod.release.create.step.recording_info.form.save': 'Use previous track data',
	'rod.release.create.step.author_info.form.save': 'Use previous track data',
	'rod.release.create.step.recording_info.label.name': 'Title',
	'rod.release.create.step.recording_info.label.subtitle': 'Subtitle',
	'rod.release.create.step.recording_info.label.lang': 'Language',
	'rod.release.create.step.recording_info.label.create_date': 'Creation date',
	'rod.release.create.step.recording_info.label.genres': 'Genres',
	'rod.release.create.step.recording_info.label.create_country':
		'Creation country',
	'rod.release.create.step.recording_info.label.non_narrative':
		'Explicit lyrics',
	'rod.release.create.step.recording_info.label.main_executor': 'Main artist',
	'rod.release.create.step.recording_info.label.label': 'Label',
	'rod.release.create.step.recording_info.label.p-line/c-line': 'P-Line/С-Line',
	'rod.release.create.step.recording_info.label.author': 'Author #{id}',
	'rod.release.create.step.recording_info.label.author_role':
		'Author #{id} role',
	'rod.release.create.step.recording_info.label.author_share':
		'Author #{id} share',
	'rod.release.create.step.recording_info.label.add_publisher': 'Add publisher',
	'rod.release.create.step.recording_info.label.author_delete':
		'Удалить автора',
	'rod.release.create.step.recording_info.label.author_add': 'Add author',
	'rod.release.create.step.recording_info.label.author_add_anonymous':
		'Add anonymous share',
	'rod.release.create.step.recording_info.form_save': 'Save',
	'rod.release.create.step.lyrics.add_lyrics': 'Add lyrics',
	'rod.release.create.step.lyrics.add_lyrics.title': 'lyrics',

	'rod.muiAuthor.author_of_lyrics': 'Writer, Author of Lyrics',
	'rod.muiAuthor.composer_writer': 'Composer Writer',
	'rod.muiAuthor.composer_author': 'Composer/Author',
	'rod.muiAuthor.arranger': 'Arranger',
	'rod.muiAuthor.adaptor': 'Adaptor',
	'rod.muiAuthor.translator': 'Translator',
	'rod.muiAuthor.participant': 'Income Participant',

	'rod.release.create.sum': 'Total shares should be 100%',

	// release create cover

	'rod.release.create.step.cover.helper': 'Help',
	'rod.release.create.step.cover.upload': '<a>Upload</a> cover image',
	'rod.release.create.step.cover.delete': 'Delete',

	// release create additional
	'rod.release.create.step.tracks.additional_release.title':
		'additional release (TikTok, RT)',
	'rod.release.create.step.tracks.additional_release.add_button':
		'create an additional release',
	'rod.release.create.step.tracks.additional_release.delete_button': 'delete',
	'rod.release.create.step.tracks.additional_release.generate_automatically':
		'Generate automatically',
	'rod.release.create.step.tracks.additional_release.distribution_date':
		'Distribution date',
	'rod.modal.type.additional_release.title': 'remove additional releases',
	'rod.modal.type.additional_release.text':
		'no additional release are available for this release type. Please remove additional releases or go back to step «release» and choose another release type («Single», «LP», «EP», «Compilation»)',

	// release create distribution

	'rod.release.create.step.distribution.input_date': 'Sale start date',
	'rod.release.transfer.step.distribution.input_date': 'Release create date',
	'rod.release.create.step.distribution.input_title':
		'Where to publish release?',
	'rod.release.create.step.distribution.input_radio3':
		'To all available outlets',
	'rod.release.create.step.distribution.input_radio4':
		'Only to specific outlets',
	'rod.release.create.step.distribution.outlets': 'Outlets',
	'rod.release.create.step.distribution.outlets_selected': 'outlets selected',
	'rod.release.create.step.distribution.helper': 'help',
	'rod.release.create.step.distribution.upload': '<a>Upload</a> cover image',
	'rod.release.create.step.distribution.delete': 'Delete',
	'rod.release.create.step.distribution.asap-date': 'ASAP (usually 3-7 days)',
	'rod.release.create.step.distribution.desired-date':
		'Select desired date (7+ days from now)',
	'rod.release.create.step.distribution.transfer': 'Release transfer',
	'rod.release.create.step.distribution.adaptr-info':
		'Adaptr is a new B2B digital music placement service that enables startups – such as those involved in game and app development – to select licensed music for interactive use in their products.',

	'rod.release.create.step.distribution.netease_cloud_music-info':
		'Launched in 2013 by NetEase, Inc., NetEase Cloud Music is a leading interactive music streaming service provider in China with more than 200 million monthly active users.',

	'rod.release.create.help.c-line/p-line':
		'<p>P-line indicates the owner of the sound recording rights and will be displayed on all available services.</p><br> <p>C-line indicates the owner of the copyright in the Composition, but not in its sound recording. When filling in C-line, the copyright holder is indicated.</p><br> <p>If both copyright and related rights to the phonogram are transferred, the label from P-line can be indicated in C-line. Alternatively, if only related rights are transferred, the publishing company owning the copyright in the Composition should be indicated in C-line.</p>',
	// release create submit

	'rod.release.create.step.submit.upload': 'upload',
	'rod.release.create.step.submit.body_title': 'information',
	'rod.release.create.step.submit.submit': 'Send to approval',
	'rod.release.moderation.error':
		'Release metadata has issues. Please go back and check there are no errors on previous steps',
	'rod.release.create.step.submit.assign_automatically': 'Assign automatically',
	'rod.release.create.step.submit.release': 'release:',
	'rod.release.create.step.submit.final_preview': 'final preview',
	'rod.release.create.step.submit.subtitle': 'subtitle',
	'rod.release.create.step.submit.relise_type': 'тип релиза',
	'rod.release.create.step.submit.genres': 'genres',
	'rod.release.create.step.submit.main_artist': 'main artist',
	'rod.release.create.step.submit.label': 'label',
	'rod.release.create.step.submit.create_date': 'creation date',
	'rod.release.create.step.submit.country_name': 'creation country*',
	'rod.release.create.step.submit.catalog_number': 'catalog number',
	'rod.release.create.step.submit.recordings': 'recordings',
	'rod.release.create.step.submit.distribution': 'distribution release',
	'rod.release.create.step.submit.additional.distribution_tiktok':
		'distribution additional releases: TikTok',
	'rod.release.create.step.submit.additional.distribution_rt':
		'distribution additional releases: RT',
	'rod.release.create.step.submit.additional.title': 'name of the phonogram',
	'rod.release.create.step.submit.distribution_date': 'distribution date',
	'rod.release.create.step.submit.additional.distribution.tiktok.date':
		'TikTok release distribution date',
	'rod.release.create.step.submit.additional.distribution.rt.date':
		'RT release distribution date',
	'rod.release.create.step.submit.outlets': 'outlets',
	'rod.release.create.step.submit.outlets_available': 'outlets available',

	// release create end

	'rod.release.create.step.end.title': 'your release is awaiting approval',
	'rod.composition.create.step.end.title': 'thank you for submission your work',
	'rod.release.create.step.end.text':
		'After approval you will see Release in repertoire section',
	'rod.composition.create.step.end.text':
		'Your composition has been submitted, and after processing, it will appear in the {link} section within two business days.',
	'rod.composition.final.step.link.text': 'Works',
	'rod.release.create.step.end.submit': 'back to main page',
	'rod.release.shipment.end.title': 'the release sent to ship',
	'rod.release.takedown.end.title': 'the release sent for take down',
	'rod.release.shipment.end.text':
		'the release will be shipped to the selected outlets after processing. You can see the current status on the release {one}, in the “shipments” tab. If you have any questions, please go to the {two} section',
	'rod.release.takedown.end.text':
		'the release will be removed from the selected outlets after processing. You can see the current status on the release {one}, in the “shipments” tab. If you have any questions, please go to the {two} section',
	'rod.release.shipment.end.btn': 'go to "shipment" section ',
	// UploadPhonogramModal

	'rod.upload.phonogram.modal.select_from_list': 'select from list',
	'rod.upload.phonogram.modal.search': 'Search by artist or title',
	'rod.upload.phonogram.modal.selected': 'selected',
	'rod.upload.phonogram.modal.clear': 'Clear',
	'rod.upload.phonogram.modal.continue': 'Continue',

	// Repertoire Upload //

	'rod.repertoire_upload.title': 'uploaded files',

	//  Main Page
	'rod.main_page.phonograms': 'recordings',
	'rod.main_page.compositions': 'compositions',
	'rod.main_page.releases': 'releases',
	'rod.main_page.mistakes': 'not ready',
	'rod.main_page.disputes': 'disputes',
	'rod.main_page.balance': 'balance',
	'rod.btn.add_release': 'add release',
	'rod.btn.transfer_release': 'Transfer release',
	'rod.btn.add_catalog': 'Add catalog',
	'rod.btn.add_composition': 'add composition',
	'rod.btn.add_recordings': 'add recordings',
	'rod.main_page.you_need_to_sign_a_contract':
		'You need to sign a <span>contract</span>',
	'rod.main_page.bulk_loading_of_repertoire': 'Bulk load of repertoire',
	'rod.main_page.greetings.body':
		'To start working with the service, you need to add information about the repertoire. Click on the buttons below to add release (for distribution) or composition (for claiming your rights). If you have catalog in Excel or CWR format, click the button with arrow in header and select Add catalog in popup menu.',
	'rod.main_page.greetings.body_widget_error':
		'Due to maintenance on the chatbot server, please temporarily use the address <a href="mailto:info@broma16.com">info@broma16.com</a> for communication.',

	'rod.main_page.greetings.body.dgtal':
		'To start working with the service, you need to add information about the repertoire. Click on the buttons below to add composition (for claiming your rights). If you have catalog in Excel or CWR format, click the button with arrow in header and select Add catalog in popup menu.',
	'rod.new_release': 'new release',
	'rod.transfer_release': 'transfer release',

	// HelpView//

	'rod.help_view.title': 'download, fill and upload the repertoire file',
	'rod.help_view.sample.repertoire_file.text':
		'<span>Download sample</span> repertoire file',
	'rod.help_view.fill_out_the_file_according_to_the_instructions.text':
		'Fill out the file according to the <span> instructions for filling</span>',
	'rod.help_view.Upload_your_completed_repertoire_file.text':
		'Upload your completed repertoire file',
	'rod.help_view.download': 'Download',

	/// loadingRepertoireFile ///

	'rod.loading_repertoire_file.title':
		' Select service for which you provide metadata',
	'rod.loading_repertoire_file.placeholder': ' I provide metadata for...',
	'rod.loading_repertoire_file.select':
		'I provide metadata for distribution of...',
	'rod.loading_repertoire_file.Submit': 'Submit',
	'rod.loading_repertoire_file.cancel': 'cancel',

	/// successView ///

	'rod.successView.title': 'thank you!',
	'rod.successView.text':
		'file {filename} was successfully validated and is being processed.',
	'rod.successView.text2':
		'We will send you email notification when processing is complete.',
	'rod.successView.go_to_uploaded_files': 'Go to uploaded files',

	/// RepertoireFileList ///

	'rod.repertoire_file_list.name_date': 'name and date',
	'rod.repertoire_file_list.stage': 'stage',
	'rod.repertoire_file_list.errors': 'errors',

	/// RepertoireFileInfo ///

	'rod.repertoire_file_info.btn.compositions': 'Compositions',
	'rod.repertoire_file_info.btn.recordings': 'Recordings',
	'rod.repertoire_file_info.btn.releases': 'Releases',
	'rod.repertoire_file_info.btn.issues': 'Issues',

	/// RepertoireFileMistakes ///

	'rod.repertoire_file_mistakes.download': 'Download list of issues',
	'rod.repertoire_file_mistakes.upload': 'Upload corrected file',
	'rod.repertoire_file_mistakes.issue': 'issue',
	'rod.repertoire_file_mistakes.column': 'column',
	'rod.repertoire_file_mistakes.value': 'value',
	'rod.repertoire_file_mistakes.position': 'position',
	'rod.repertoire_file_song_list.title': 'title',
	'rod.repertoire_file_song_list.author': 'author',

	/// TreatiesPage ///

	'rod.treaties_page.pending_review': 'Pending review',
	'rod.treaties_page.sign_additional_contract': 'Sign additional contract',
	'rod.treaties_page.notify':
		'New contract may be added after previous is approved',
	'rod.treaties_page.dates': 'dates:',
	'rod.treaties_page.empty': 'no conditions',
	'rod.treaties_page.empty.regions': 'no regions',
	'rod.treaties_page.collection': 'collection:',
	'rod.treaties_page.condition': 'Condition:',
	'rod.treaties_page.select_services_and_terms': 'select services and terms',
	'rod.treaties_page.while_attaching_repertoire':
		'While attaching repertoire to contract you would also be able to individually exlude outlets and territories',
	'rod.treaties_page.next': 'Next',
	'rod.treaties_page.term_of_the_contract': 'Term of the contract*',
	'rod.treaties_page.contract_currency': 'Contract currency*',
	'rod.treaties_page.signature_type': 'Signature type*',
	'rod.treaties_page.edit': 'Edit',
	'rod.treaties_page.hide': 'Hide',
	'rod.treaties_page.number': 'More +{N}',
	'rod.treaties_page.composition': 'Composition',
	'rod.treaties_page.recordings': 'Recordings',
	'rod.treaties_page.for_online_use': 'For online use',
	'rod.treaties_page.for_public_perfomance': 'For public perfomance',
	'rod.treaties_page.for_synchronization': 'For synchronization',
	'rod.treaties_page.for_the_use_of_lyrics': 'For the use of lyrics',

	/// ReportsPage ///

	'rod.reports_page.filter.placeholder': 'Report on copyright',
	'rod.reports_page.title': 'title',
	'rod.reports_page.period': 'period',
	'rod.reports_page.date_provided': 'date provided',
	'rod.reports_page.action': 'action',
	'rod.reports_page.nav.reports': 'reports',
	'rod.reports_page.nav.invoices': 'invoices',
	'rod.user_reports.royalty_to_pay.com.fragment.help':
		'<p>This is royalty only for the last reporting period. Previous amounts can be found in reports for previous periods. At the moment, royalties for previous periods are not cumulative. Detailed information about Reports and Payments can be found <a href="https://rod.broma16.com/faq/section/reports-and-payments" target="_blank" rel="noopener noreferrer">here<a/></p>',
	'rod.user_reports.royalty_to_pay.ru.fragment.help':
		'<p>This is royalty only for the last reporting period. Previous amounts can be found in reports for previous periods. At the moment, royalties for previous periods are not cumulative. Detailed information about Reports and Payments can be found <a href="https://rod.broma16.ru/faq/section/reports-and-payments" target="_blank" rel="noopener noreferrer">here<a/></p>',
	'rod.user_reports_helper_banner_text_ru':
		'<p>Due to the increased volume of data, the publication of reports will be delayed until August 25, 2023. We apologize for any inconveniences.</p></br> <p>We understand the importance of timely report and payment, so we will expedite payment processing.</p></br> <p><b>Important:</b></p> <ul><li>&#8226; Reports will be published by 25.08.2023</li><li>&#8226; Royalty payments for Q2 2023 will be made on time</li><li>&#8226; Invoice processing time will be reduced to 5 days</li></ul></br> <p>Thank you for your understanding.</p>',
	'rod.user_reports_helper_banner_text_com':
		'<p>Due to the increased volume of data, the publication of reports will be delayed until August 25, 2023. We apologize for any inconveniences.</p></br> <p>We understand the importance of timely report and payment, so we will expedite payment processing.</p></br> <p><b>Important:</b></p> <ul><li>&#8226; Reports will be published by 25.08.2023</li><li>&#8226; Royalty payments for Q2 2023 will be made on time</li></ul></br> <p>Thank you for your understanding.</p>',
	'rod.reports_page.banner':
		'<h4>important information on withdrawals</h4> <br/> <p>withdrawal of funds is unavailable, because your personal and bank data for account formation are not filled in. please follow the <a href={value}>link</a> to the settings and update the information, after which the payment button will become available.</p>',
	'rod.reports_page.current_royalty': 'royalties due',
	'rod.reports_page.next_royalty_period': 'next report date:',
	'rod.reports_page.withdraw': 'withdraw funds',
	'rod.reports_page.withdraw_continue': 'continue withdrawing funds',
	'rod.reports_page.insufficient_funds':
		'<b>Insufficient funds.</b> </br> </br> <p>Your account has insufficient funds for withdrawal. The minimum amount to withdraw is {value}.</p>',
	'rod.reports_page.payment.toast.title': 'your invoice has been accepted for processing',
	'rod.reports_page.payment.toast.text':
		'After the payment is completed, we will notify you additionally',

	// AddCompositionPage //
	'rod.add_composition_page.step1': 'Basic information',
	'rod.add_composition_page.step2': 'Authors and shares',
	'rod.add_composition_page.step3': 'Lyrics',
	'rod.add_composition_page.step4': 'Performances of the work',
	'rod.add_composition_page.step5': 'Review',

	// BasicInfo ///

	'rod.basic_info.type': 'Music work type',
	'rod.basic_info.date': 'Creation date',
	'rod.basic_info.name': 'Composition name',
	'rod.basic_info.alter_name': 'Alternative title',
	'rod.basic_info.description_text':
		'Recording ISRC number in CC-XXX-YY-NNNNN format. Leave blank for unknown.',
	'rod.basic_info.country_name': 'Creation country',
	'rod.basic_info.subtitle': 'Subtitle',
	'rod.basic_info.genres': 'Genres',
	'rod.basic_info.genres_description':
		'Recording genres. Multiselection is possible.',
	'rod.composition.create.step.basic.helper_title': 'Help',
	'rod.composition.create.step.basic.helper_body':
		'If you have composition ISWC number assigned by CMO, please nake sure to fill it.<br/><br/>If composition type is unknown, just keep the default field value.<br/><br/>If you know creation year, but not creation date, please fill 01.01 of the creation year as creation date.',

	// AuthorsShares ///

	'rod.authors_shares.title': 'authors and shares',
	'rod.authors_shares.author': 'Author #1',
	'rod.authors_shares.author_id': 'Author #{number}',
	'rod.authors_shares.authors_role': 'Author #1 role ',
	'rod.authors_shares.authors_role_id': 'Author #{number} role ',
	'rod.authors_shares.authors_share': 'Author #1 share ',
	'rod.authors_shares.authors_share_id': 'Author #{number} share ',
	'rod.authors_shares.publisher_authors': 'Publisher',
	'rod.authors_shares.publisher_share': 'Publisher share',
	'rod.authors_shares.anonim_role': 'Anonymous role',
	'rod.authors_shares.anonim_share': 'Anonymous share',
	'rod.authors_shares.notification_unallocated_share':
		'Do you have unallocated shares in',
	'rod.authors_shares.descroption': '',
	'rod.authors_shares.my_shares': 'I control this share',
	'rod.authors_shares.add_author': 'Add author',
	'rod.authors_shares.add_anonim_shares': 'Add anonymous share',
	'rod.authors_shares.add_publisher': 'Add publisher',
	'rod.authors_shares.author_publisher_id': 'Author #{id} publisher',
	'rod.authors_shares.publisher_share_id': 'Publisher #{id} share ',
	'rod.authors_shares.unallocated_shares':
		'Do you have unallocated shares in {allValue}%',
	'rod.composition.create.step.authors.helper_title': 'Help',
	'rod.composition.create.step.authors.helper_body':
		'Specify the data of the authors of the work and their publshers (if applicable).<br/><br/>Please do not use pseudonyms and input real author names in "Last name First name" format.<br/><br/>Sum of the authors\' and publishers\' shares in the work must always be equal to 100%.<br/><br/>If you do not know other author(s), please fill Anonymous writer as name.',

	// PerformanceWork //

	'rod.performance_work.title': 'recording #{number}',
	'rod.performance_work.whether_work_was_perfomed':
		'Was the composition performed?',
	'rod.performance_work.yes': 'Yes',
	'rod.performance_work.no': 'No',
	'rod.performance_work.previously_added_soundtrack':
		'Selected previously added recording',
	'rod.performance_work.create_new_recordings': 'Create new recording',
	'rod.performance_work.create_new_recordings.tooltip':
		'Save the current soundtrack for further actions',
	'rod.performance_work.name_recordings': 'Recording title',
	'rod.performance_work.main_performer': 'Main artist',
	'rod.performance_work.main_performer.description': 'For example, John Doe',
	'rod.performance_work.label': 'Label',
	'rod.performance_work.iswc.description':
		'Recording ISRC number in CC-XXX-YY-NNNNN format. Leave blank for unknown.',
	'rod.performance_work.featured_artist': 'Featured artist',
	'rod.performance_work.profanity': 'Explicit lyrics',
	'rod.performance_work.collect_rewards_for_this_form':
		'Collect royalties for this recording',
	'rod.performance_work.add_recording2': 'Add one more recording',
	'rod.performance_work.missing': 'Missing',
	'rod.performance_work.present': 'Present',
	'rod.performance_work.save': 'Save',
	'rod.composition.create.step.performance.helper_title': 'Help',
	'rod.composition.create.step.performance.helper_body':
		'If you know information about composition recordings/performances, please fill in the information about them. This allows to collect author royalties for composition faster and more.',
	//file.js

	// account settings //

	'rod.navbar.settings': 'settings',
	'rod.account.settings.private_data': 'Private data',
	'rod.account.settings.contact_data': 'Contact data',
	'rod.account.settings.bank_data': 'Bank data',
	'rod.account.settings.pseudonyms': 'Pseudonyms',
	'rod.account.settings.pseudonyms-add': 'add pseudonym',
	'rod.account.settings.pseudonyms-edit': 'edit pseudonym',
	'rod.account.settings.header': 'Please fill out all sections',
	'rod.account.contact.title': 'contact information',
	'rod.account.bank.title': 'details for receiving funds',
	'rod.account.bank.banner.title': 'please change the payment method.',
	'rod.account.bank.banner.text':
		'At the moment, the service does not support withdrawals via PayPal. Please choose another withdrawal method.',

	//---------------administration-----------------
	'rod.admin.search.placeholder': 'search by name, phone, email',
	'rod.admin.no-data': 'no data',
	'rod.admin.account_data.title': 'Account data',
	'rod.admin.signup_data.title': 'Signup data',
	'rod.admin.user.title': 'User data',
	'rod.admin.account.status_title': 'Status',
	'rod.admin.account.personal_data.title': 'Sole Proprietor Title',
	'rod.admin.account.personal_data.behalf': 'Signing on behalf',
	'rod.admin.account.personal_data.behalf_short': 'Short name of Signer',
	'rod.admin.account.personal_data.company_reg_number': 'Registration number',
	'rod.admin.account.personal_data.company_reg_date': 'Registration Date',
	'rod.admin.account.personal_data.country_id': 'Country of registration',
	'rod.admin.account.personal_data.last_name': 'Surname',
	'rod.admin.account.personal_data.first_name': 'Name',
	'rod.admin.account.personal_data.patronymic': 'Patronymic',
	'rod.admin.account.personal_data.sex': 'Sex',
	'rod.admin.account.personal_data.sex.male': 'Male',
	'rod.admin.account.personal_data.sex.female': 'Female',
	'rod.admin.account.personal_data.birth_date': 'Birth date',
	'rod.admin.account.personal_data.birth_place': 'Registration address',
	'rod.admin.account.personal_data.itin': 'Tax number (TIN)',
	'rod.admin.account.user_data.email': 'Email',
	'rod.admin.account.user_data.phone': 'Phone',
	'rod.admin.account': 'Account',
	'rod.admin.account_roles': 'Roles',
	'rod.admin.account.category': 'Category',
	'rod.admin.account.repertoire': 'Repertoire',
	'rod.admin.account.personal_information': 'Personal information',
	'rod.admin.account.sole_proprietor_data': 'Sole Proprietor Information',
	'rod.admin.account.company_data': 'Company details',
	'rod.admin.account.company_name': 'Company name',
	'rod.admin.account.attorney': 'Number and Date of Power of Attorney',
	'rod.admin.account.signature_position': 'Signer position',
	'rod.admin.account.acting_on': 'Acting as',
	'rod.admin.account.company_vat_number': 'VAT number',
	'rod.admin.account.contact_information': 'Contact data',
	'rod.admin.account.country': 'Country',
	'rod.admin.account.zip_code': 'Zip code',
	'rod.admin.account.address': 'Address',
	'rod.admin.account.city': 'City',
	'rod.admin.account.site': 'Site',
	'rod.admin.account.locale': 'Locale',
	'rod.admin.account.current_locale': 'current locale: {current_locale}',
	'rod.admin.account.redirect_locale': 'Redirect locale',
	'rod.admin.account.files.documents': 'Documents',
	'rod.admin.account.files.document_type': 'Document type',
	'rod.admin.account.files.file': 'File',
	'rod.admin.account.files.quality': 'Quality',
	'rod.admin.account.files.data_match': 'Data is correct',
	'rod.admin.account.files.set_status': 'Status will be set',
	'rod.admin.account.files.approved': 'Approved',
	'rod.admin.account.files.sent': 'Sent',
	'rod.admin.account.files.to_send': 'Send',
	'rod.admin.account.societies': 'Societies',
	'rod.admin.account.societies.author_society': 'Author society',
	'rod.admin.account.societies.date': 'Date',
	'rod.admin.account.pseudonyms.names_identifiers': 'Names and Identifiers',
	'rod.admin.account.pseudonyms.identifier': 'Identifier',
	'rod.admin.account.payment.data': 'Royalty payment details',
	'rod.admin.account.payment.method': 'Payment method',
	'rod.admin.account.payment.bank_data': 'Banking details',
	'rod.admin.account.payment.beneficiary_name': 'Account name',
	'rod.admin.account.payment.bank_account_name': 'Account number (IBAN)',
	'rod.admin.account.payment.bank_name': 'Bank name',
	'rod.admin.account.payment.bank_address': 'Bank address',
	'rod.admin.account.payment.bank_country': 'Bank country',
	'rod.admin.account.payment.correspondent_account':
		'Correspondent bank account',
	'rod.admin.account.payment.correspondent_account_swift':
		'SWIFT/BIC correspondent bank',
	'rod.admin.account.payment.swift_bic': 'SWIFT/BIC',
	'rod.admin.account.payment.paypal_account': 'Paypal account',
	'rod.admin.account.contracts': 'Contracts',
	'rod.admin.account.contracts.period-info':
		'Contracts are formed with the option of automatic renewal and early termination. They also provide the possibility of ending the agreement before the renewal date',
	'rod.admin.account.contracts.signing_variants': 'Contract signing type',
	'rod.admin.account.contracts.by_hand': 'Manual',
	'rod.admin.account.contracts.switch_to_by_hand_signing':
		'Switch to by-hand signing',
	'rod.admin.account.contracts.signs': 'Signatures',
	'rod.admin.account.contracts.all_pages': 'All pages',
	'rod.admin.invalid-date': 'invalid date',
	'rod.admin.sign-contract.sent': 'Contract complete',
	'rod.admin.sign-contract.rejected': 'Contract rejected',
	'rod.admin.account.status.confirm': 'confirm account data',
	'rod.admin.account.status.sms_confirm': 'confirm phone number',
	'rod.admin.account.status.personal_area': 'Personal area',
	'rod.admin.account.status.personal_area_legacy': 'Personal area (legacy)',
	'rod.admin.account.status.repertoire_apply': 'Repertoire apply',

	/*--- status-history --- */
	'rod.admin.status_history.title': 'Status history',
	'rod.admin.change_history.title': 'Change history',
	'rod.admin.status_history.manual_type': 'manual',
	'rod.admin.status_history.auto_type': 'automatic',
	'rod.admin.pending_documents': 'Documents pending',
	'rod.admin.pending_contracts': 'Contracts pending',
	'rod.admin.declined_documents': 'Documents declined',
	'rod.admin.declined_contracts': 'Contracts declined',
	'rod.admin.approved_documents': 'Documents approved',
	'rod.admin.approved_contracts': 'Contracts approved',
	'rod.admin.notifications.title': 'Title',
	'rod.admin.notifications.text': 'Text',
	'rod.admin.notifications.date': 'Date',
	'rod.admin.notifications.recipients': 'Recipients',

	'rod.admin.statistics_service.issue': 'view&nbsp;details',
	'rod.admin.signup.sms_verify': 'Verify phone manually',
	'rod.admin.signup.email_verify': 'Verify email manually',
	'rod.admin.signup.email_code': 'Email verification code is',
	'rod.admin.signup.sms_code': 'Phone verification code is',
	'rod.admin.signup.sms_valid_until': 'Valid until',
	'rod.admin.signup.err422':
		'Operation failed. User already moved on to another status.',
	'rod.admin.signup.phone.manual_verification':
		'SMS verification code requested by admin',
	'rod.admin.signup.email.manual_verification':
		'Email verification code requested by admin',
	'rod.admin.time.just_now': 'Just now',

	'rod.faq.title_comment_begin':
		'This section contains all the information about',
	'rod.faq.title_comment_end': `in ${platform.title_space}`,
	'rod.faq.related_articles': 'related articles',
	'rod.faq.knowledgebase': 'knowledge base',
	'rod.faq.search_result': 'search result',
	'rod.repertoire.help.own_col_rev':
		'<p>Own/Ownership - reflects asset share that is owned by rightholder.</p><br><p>Col/Collection - shows percent and party that collects royalty for the use of asset.</p><br><p>Rev/Revenue - reflects royalty distribution between parties according to asset ownership and terms of agreements.</p>',
	'rod.faq.results_for': 'Result for ',
	'rod.faq.help': 'help',

	//Admin articles//
	'rod.admin.articles': 'articles',
	'rod.admin.articles_add': 'add article',
	'rod.admin.articles.rubric': 'rubric',
	'rod.admin.articles.rubrics': 'rubrics',
	'rod.admin.articles.date': 'date',
	'rod.admin.articles.last_corrected_date': 'last corected date',
	'rod.admin.articles.rubric_settings': 'rubric settings',
	'rod.admin.articles.rubric_settings_title': 'title',
	'rod.admin.articles.rubric_add': 'add rubric',
	'rod.faq.create_article': 'create article',
	'rod.faq.article_title': 'article`s title',
	'rod.faq.article_main_text': 'article`s main text',
	'rod.faq.pic.upload': '<a>Upload picture</a>',
	'rod.faq.confirm_one_article':
		'The article is only in one language. Please make the article in both languages.',
	'rod.faq.edit_article': 'article editing',
	'rod.release_type.other': 'other',
	'rod.admin.articles.rubric_edit': 'Rubric editing',
	'rod.admin.articles.rubric_create': 'Rubric creation',
	'rod.admin.articles.search': 'search by title and text of the article',

	// Admin reports //
	'rod.admin.reports.add': 'Add reports',
	'rod.admin.reports.add_distribution': 'add distribution',
	'rod.admin.reports.release_date': 'Next release date',
	'rod.admin.reports.noData': 'Please, <u>upload</u> reports',
	'rod.admin.reports.loading_report': 'Loading report',
	'rod.admin.reports.editing_report': 'Editing report',
	'rod.admin.distribution.create': 'creating distribution',
	'rod.admin.distribution.edit': 'editing distribution',
	'rod.admin.distribution.name': 'distribution name',

	'rod.admin.reports.report_name': 'Report name',
	'rod.admin.reports.load_archive': 'Load archive',
	'rod.admin.reports.load_file': 'Load file',
	'rod.admin.reports.report_dropbox':
		'<a>Loading</a> or drag&drop zip archive with reports',
	'rod.admin.reports.report_dropbox_xls':
		'<a>Loading</a> or drag&drop reports files',
	'rod.admin.reports.edit_report_dropbox':
		'<a>Loading</a> or drag&drop xls report file',
	'rod.admin.reports.confirm_one_article':
		'The report is only in one language. Please make the report in both languages.',
	'rod.admin.reports.download_again': 'download again',
	'rod.admin.reports.edit': 'edit',
	'rod.admin.reports.upload': 'Please upload file',
	'rod.admin.reports.pending_review': 'Pending',
	'rod.admin.reports.edit_file':
		'If you want to change file, please use dropbox below',
	'rod.admin.reports.edit_file_new':
		'If you want to change file - please use form below',
	'rod.admin.reports.filters': 'filters',
	'rod.admin.reports.reports_with_errors_modal_title': 'edit reports file',
	'rod.admin.reports.reports.summ': 'Amount',
	'rod.admin.reports.reports.currency': 'Currency',
	'rod.admin.reports.reports_with_errors': 'reports with errors',

	'rod.no_items_found': 'No items found',
	'rod.no_reports_title': 'No reports found',
	'rod.no_reports_body': 'Upload files to view reports',
	'rod.filter.no_reports_title': 'No result',
	'rod.filter.no_reports_body': 'try to change search parameters',

	'rod.contracts.whole_world': 'Whole World',
	'rod.contracts.regions': 'regions:',

	'rod.contracts.number': 'contract number',
	'rod.contracts.licensor': 'licensor',
	'rod.contracts.licensee': 'licensee',
	'rod.contracts.term': 'term of the condition',
	'rod.contracts.start_collection': 'start of retroactive collection',
	'rod.contracts.end_self_off_period': 'end of self-off period',
	'rod.contracts.automatic_prolongation': 'automatic prolongation',
	// admin.reports.currency
	'rod.contracts.rights_transfer': 'transfer of rights',
	'rod.contracts.reports_transfer': 'transmission of reports',
	// rod.field.territories.included
	'rod.contracts.territories_excluded': 'except for territories',
	'rod.contracts.regions_excluded': 'except for:',
	// rod.statistic.outlets
	'rod.contracts.outlets_excluded': 'except for outlets',
	//rod.main_page.releases

	'rod.contracts.rights_types_group': 'group of rights types',
	'rod.contracts.right_type': 'right type',
	'rod.contracts.licensor_share': 'licensor share',
	'rod.contracts.licensee_share': 'licensee share',
	'rod.contracts.price': 'price',
	'rod.contracts.publisher_share': 'publisher share',
	'rod.contracts.sub_publisher_share': 'sub-publisher share',

	'rod.contracts.type': 'Contract type',
	'rod.contracts.author_share_collection': 'collection of the author share',
	'rod.contracts.author_share_payment':
		'payment of the author share from the source',
	'rod.contracts.author_share': 'author share',
	'rod.contracts.specific': 'specific',
	'rod.contracts.exclusive': 'exclusive',
	'rod.contracts.add_role.title': 'Adding role',

	'rod.contracts.collect_licensor_share': 'Collect licensor share',
	'rod.contracts.collect_assignor_share': 'Collect author`s share',
	'rod.contracts.collect_author_share_at_source':
		'Collect author revenue at source',
	'rod.contracts.licensor_share_via_publisher': 'Licensor share via publisher',

	'rod.contracts.neighboring_rights_share': 'Neighboring rights share',
	'rod.contracts.authors_rights_share': 'Author`s rights share',
	'rod.contracts.ccid_share': 'MECH/PERF share',

	'rod.admin.find': 'Search by account id',
	'rod.reports.find': 'Search by report',
	'rod.distribution_name.find': 'Search by distribution name',
	'rod.account_name.find': 'Search by account name',

	'rod.pseudonyms.main': 'main pseudonym',
	'rod.pseudonyms.todo_main': 'make it the main',
	'rod.pseudonyms.add': 'Add pseudonym',
	'rod.pseudonyms.delete': 'delete&nbsp;pseudonym',
	'rod.pseudonyms.outlet': 'Outlet',
	'rod.pseudonyms.delete-confirm': 'Delete pseudonym?',
	'rod.pseudonyms.delete-help':
		'This action is irreversible. If you delete this pseudonym, you will have to create it again.',
	'rod.contracts.expand': 'expand',

	'rod.modal.understood': 'Understand',
	'rod.modal.statistic_title':
		'The graph displays the dynamics for a period of 90 days',
	'rod.modal.statistic_text':
		'All statistics and report download are still available to you, but the chart displays limited dynamics for the last 90 days of the selected period. ',
	'rod.modal.admin_reports_title': 'There are entries with errors',
	'rod.modal.admin_reports_text':
		'Activate the filter to display reports with errors and correct them by selecting the menu item “Edit”',
	'rod.modal.admin_reports_parsing.title': 'An error occurred',
	'rod.modal.admin_reports_parsing.text': 'Please upload the archive again',
	'rod.add_service.title': 'add service',

	'rod.modal.cancel': 'Cancel',
	'rod.modal.yes_delete': 'Yes, delete',
	'rod.modal.leave_only_one_track.title': 'leave only one track',
	'rod.modal.leave_only_one_track.text':
		'For this type of release, only one track is available. Please leave the necessary track and remove the rest from the list',
	'rod.modal.leave_only_one_track.btn': 'next',
	'rod.distribution.delete-confirm': 'Delete distribution?',
	'rod.distribution.delete-help':
		'This action is irreversible. If you delete this distribution, all its reports will be deleted too.',
	'rod.distribution.date_time': 'Date and time of publishing',
	'rod.contracts.no_contracts_info':
		'Your account is a licensee with whom contracts are concluded, so you do not have contracts',
	'rod.rubric.delete-confirm': 'Delete rubric?',
	'rod.rubric.delete-help':
		'The category contains related articles. If you want to delete this category, first move the articles from it to another category',
	'rod.release.confirmation-title': 'Confirm action',
	'rod.release.shipment.confirmation-text':
		'Are you sure you want to publish a release from the selected outlets?',
	'rod.release.takendown.confirmation-text':
		'Are you sure you want to remove the release from the selected outlets?',
	'rod.admin.handling.drafts_title': 'expired drafts list',
	'rod.admin.handling.drafts_delete_title': 'delete a draft',
	'rod.admin.handling.drafts_delete_text':
		'are you sure you want to delete the draft?',
	'rod.handling.release_text':
		'by pressing the button, you send all the releases created during the day for moderation. Please use the button only when absolutely necessary.',
	'rod.handling.release_help': 'what does it mean?',
	'rod.handling.release_info_text':
		'Releases are uploaded automatically once a day, but if you need to upload a release urgently, you can use the release export functionality. All created releases by this hour will be shipped for moderation. Please don`t use the functionality often. This affects the release moderation team',

	/*links words*/
	'rod.link_word.on_page': 'page',
	'rod.link_word.faq': 'FAQ',

	'rod.admin.account.contract_help': 'not all contracts are loaded by the user',
};

export default pages;
