import React, { useState, useEffect } from 'react';

import s from './UploadPhonogramModal.module.css';

import CloseIcon from '@mui/icons-material/Close';

import checked from 'images/checked.svg';
import no_checked from 'images/no_checked.svg';
// import create_new from 'images/create_new.svg';
import loop from 'images/loop.svg';
import { FormattedMessage } from 'react-intl';

export default function UploadPhonogramModal({
	active,
	setActive,
	arrFromModal,
}) {
	const [settings, setSettings] = useState([]);
	const [footer, setFooter] = useState([]);

	useEffect(() => {
		const newSettings = [
			{ id: 1, name: 'Название фонограммы', author: 'Исполнитель' },
			{ id: 2, name: 'The View from the Afternoon', author: 'Arctic Monkeys' },
			{
				id: 3,
				name: 'Weapon of Choice (feat. Bootsy Collins)',
				author: 'Fatboy Slim',
			},
			{ id: 4, name: 'Wolf', author: 'Tyler The Creator' },
			{ id: 5, name: 'Название фонограммы', author: 'Исполнитель' },
			{ id: 6, name: 'The View from the Afternoon', author: 'Arctic Monkeys' },
			{
				id: 7,
				name: 'Who Ride Wit Us',
				author: 'Daz Dillinger Fredwreck Nassars',
			},
			{
				id: 8,
				name: 'Weapon of Choice (feat. Bootsy Collins)',
				author: 'Fatboy Slim',
			},
			{ id: 9, name: 'Wolf', author: 'Tyler The Creator' },
			{ id: 10, name: 'Название фонограммы', author: 'Исполнитель' },
			{ id: 11, name: 'The View from the Afternoon', author: 'Arctic Monkeys' },
			{
				id: 12,
				name: 'Who Ride Wit Us',
				author: 'Daz Dillinger Fredwreck Nassars',
			},
			{
				id: 13,
				name: 'Weapon of Choice (feat. Bootsy Collins)',
				author: 'Fatboy Slim',
			},
			{ id: 14, name: 'Wolf', author: 'Tyler The Creator' },
			{
				id: 15,
				name: 'Who Ride Wit Us',
				author: 'Daz Dillinger Fredwreck Nassars',
			},
			{
				id: 16,
				name: 'Weapon of Choice (feat. Bootsy Collins)',
				author: 'Fatboy Slim',
			},
			{ id: 17, name: 'Wolf', author: 'Tyler The Creator' },
		];
		setSettings(newSettings);
	}, []);

	function handlerSettings(item) {
		setSettings(settings.filter((i) => i.id !== item.id));
		setFooter([...footer, item]);
	}
	function handlerDeleteSetting(item) {
		setFooter(footer.filter((i) => i.id !== item.id));
		setSettings([...settings, item]);
	}
	function handlerDeleteAllSettings() {
		setSettings([...settings, ...footer]);
		setFooter([]);
	}

	function submit() {
		arrFromModal(footer);
		setSettings([...settings, ...footer]);
		setFooter([]);
		setActive(false);
	}

	return (
		<div
			className={active ? `${s.modal} ${s.active}` : s.modal}
			onClick={() => setActive(false)}
		>
			<div className={s.container} onClick={(e) => e.stopPropagation()}>
				<div className={s.modalHeader}>
					<span className={s.modalHeader__title}>
						<FormattedMessage
							id={'rod.upload.phonogram.modal.select_from_list'}
						/>
					</span>
					<CloseIcon onClick={() => setActive(false)} />
				</div>

				<div className={s.modalBody}>
					<div className={s.input}>
						<img src={loop} alt="" />

						<FormattedMessage id={'rod.upload.phonogram.modal.search'}>
							{(placeholder) => <input type="text" placeholder={placeholder} />}
						</FormattedMessage>
					</div>

					<ul className={s.modalBody__list}>
						{settings &&
							settings.map((item) => (
								<li
									key={item.id}
									className={s.list__item}
									onClick={() => handlerSettings(item)}
								>
									<img
										className={s.modal__icon}
										width={16}
										height={16}
										src={no_checked}
										alt=""
									/>
									<span className={s.list__text}>{item.name}</span>
									<span className={s.list__author}>{item.author}</span>
								</li>
							))}
					</ul>
				</div>

				<div
					className={
						footer.length
							? `${s.modalFooterActive} ${s.modalFooter}`
							: `${s.modalFooter}`
					}
				>
					<div className={s.footerTitle}>
						<span className={s.footerTitle__textContainer}>
							<span className={s.modalFooter__title}>
								<FormattedMessage id={'rod.upload.phonogram.modal.selected'} />
							</span>
							<span className={s.footerTitle__count}>{footer.length}</span>
						</span>
						<a
							href
							className={s.footerTitle__reset}
							onClick={() => handlerDeleteAllSettings()}
						>
							<FormattedMessage id={'rod.upload.phonogram.modal.clear'} />
						</a>
					</div>
					<ul className={s.footer__list}>
						{footer.map((item) => (
							<li
								key={item.id}
								className={s.list__item}
								onClick={() => handlerDeleteSetting(item)}
							>
								<img
									className={s.modal__icon}
									width={16}
									height={16}
									src={checked}
									alt=""
								/>
								<span className={s.list__text}>{item.name}</span>
								<span className={s.list__author}>{item.author}</span>
							</li>
						))}
					</ul>
					<button className={s.footerNext__button} onClick={() => submit()}>
						<FormattedMessage id={'rod.upload.phonogram.modal.continue'} />
					</button>
				</div>
			</div>
		</div>
	);
}
