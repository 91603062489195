import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withRoot, withUI } from 'hocs';
import { compose } from 'recompose';
import { Loading } from 'components';
import { Button } from 'components/Buttons';
import { showError } from 'validators/showError';
import { FormInput } from 'components/Form/FormInput';
import { DropZone } from 'components/Upload/DropZone';
import debounce from 'lodash.debounce';
import RichTextEditor from 'react-rte';
import { useHistory } from 'react-router-dom';

import styles from './CreateArticle.module.css';
import { FaqAdminLang } from 'material-design/components/FaqAdminLang';
// import Loader from 'components/Loader';
// import { id } from 'date-fns/locale';
// import { indexOf } from 'lodash';

const CreateArticle = (props) => {
	const toolbarConfig = {
		display: [
			'INLINE_STYLE_BUTTONS',
			'BLOCK_TYPE_BUTTONS',
			'LINK_BUTTONS',
			'HISTORY_BUTTONS',
		],
		INLINE_STYLE_BUTTONS: [
			{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
			{ label: 'Italic', style: 'ITALIC' },
			{ label: 'Underline', style: 'UNDERLINE' },
		],
		BLOCK_TYPE_BUTTONS: [
			{ label: 'UL', style: 'unordered-list-item' },
			{ label: 'OL', style: 'ordered-list-item' },
		],
	};
	const history = useHistory();
	const [pageLoading, setPageLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [articleLang, setArticleLang] = useState('en');
	const [enData, setEnData] = useState({
		article_id: '',
		title: '',
		text: RichTextEditor.createEmptyValue(),
		pic: null,
		pic_identifier: null,
		pic_preview: null,
		section_selected: {},
		sections: [],
	});

	const [ruData, setRuData] = useState({
		article_id: '',
		title: '',
		text: RichTextEditor.createEmptyValue(),
		pic: null,
		pic_identifier: null,
		pic_preview: null,
		section_selected: {},
		sections: [],
	});
	const [enConfirmed, setEnConfirmed] = useState(false);
	const [ruConfirmed, setRuConfirmed] = useState(false);
	const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
	// const [confirmed, setConfirmed] = useState(false);
	const [isShowNotice, setIsShowNotice] = useState(false);
	const [isConfirmedBtnActive, setIsConfirmedBtnActive] = useState(true);
	const [isShowConfirmQuestion, setIsShowConfirmQuestion] = useState(false);
	const [isPicsUploaded, setIsPicsUploaded] = useState(false);

	const {
		UIContext: { showBackNavi, hideBackNavi },
		rootContext: {
			getAdminArticlesSectionsAll,
			createAdminArticle,
			getAdminArticle,
			getAdminArticlesBySlugLang,
			uploadAdminArticlePicture,
			updateAdminArticle,
			deleteAdminArticle,
			deleteAdminArticlePicFile,
		},
		articleId,
	} = props;

	const isEdit = articleId ? true : false;

	useEffect(() => {
		showBackNavi('/admin/v2/articles');
		getAdminArticlesSectionsAll().then((res) => {
			const ruSections = res.data.filter(
				(section) => section.language === 'ru'
			);
			const enSections = res.data.filter(
				(section) => section.language === 'en'
			);
			setRuData((prev) => ({ ...prev, sections: ruSections }));
			setEnData((prev) => ({ ...prev, sections: enSections }));
			setPageLoading(false);
		});

		if (isEdit) {
			getAdminArticle(articleId).then((res) => {
				if (res.language === 'ru') {
					setArticleLang('ru');
					setRuData((prev) => ({
						...prev,
						article_id: res.id,
						title: res.heading,
						text: RichTextEditor.createValueFromString(res.description, 'html'),
						section_selected: res.section ? res.section : {},
						pic: res.file_id,
						pic_preview: res.file_id,
						pic_identifier:
							res.file_id &&
							res.file_id.slice(
								res.file_id.indexOf('/faq/') + 5,
								res.file_id.indexOf('.')
							),
					}));

					getAdminArticlesBySlugLang('en', res.slug).then((art) => {
						if (Array.isArray(art) && art.length) {
							setEnData((prev) => ({
								...prev,
								article_id: art[0].id,
								title: art[0].heading,
								text: RichTextEditor.createValueFromString(
									art[0].description,
									'html'
								),
								// section_selected: art[0].section,
								section_selected: art[0].section ? art[0].section : {},
								pic: art[0].file_id,
								pic_preview: art[0].file_id,
								pic_identifier:
									art[0].file_id &&
									art[0].file_id.slice(
										art[0].file_id.indexOf('/faq/') + 5,
										art[0].file_id.indexOf('.')
									),
							}));
						}
					});
				} else {
					setEnData((prev) => ({
						...prev,
						article_id: res.id,
						title: res.heading,
						text: RichTextEditor.createValueFromString(res.description, 'html'),
						section_selected: res.section ? res.section : {},
						pic: res.file_id,
						pic_preview: res.file_id,
						pic_identifier:
							res.file_id &&
							res.file_id.slice(
								res.file_id.indexOf('/faq/') + 5,
								res.file_id.indexOf('.')
							),
					}));
					getAdminArticlesBySlugLang('ru', res.slug).then((art) => {
						if (Array.isArray(art) && art.length) {
							setRuData((prev) => ({
								...prev,
								article_id: art[0].id,
								title: art[0].heading,
								text: RichTextEditor.createValueFromString(
									art[0].description,
									'html'
								),
								// section_selected: art[0].section,
								section_selected: art[0].section ? art[0].section : {},
								pic: art[0].file_id,
								pic_preview: art[0].file_id,
								pic_identifier:
									art[0].file_id &&
									art[0].file_id.slice(
										art[0].file_id.indexOf('/faq/') + 5,
										art[0].file_id.indexOf('.')
									),
							}));
						}
					});
				}
			});
		}

		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			enData.text.toString('html') !== '<p><br></p>' &&
			enData.title &&
			Object.keys(enData.section_selected).length
		) {
			setEnConfirmed(true);
		} else setEnConfirmed(false);
	}, [enData]);

	useEffect(() => {
		if (
			ruData.text.toString('html') !== '<p><br></p>' &&
			ruData.title &&
			Object.keys(ruData.section_selected).length
		) {
			setRuConfirmed(true);
		} else setRuConfirmed(false);
	}, [ruData]);

	const titleChange = (field) => (e) => {
		setErrors({});
		if (articleLang === 'en') {
			setEnData((prev) => ({ ...prev, [field]: e }));
		} else {
			setRuData((prev) => ({ ...prev, [field]: e }));
		}
	};

	const editorTextChange = (value) => {
		if (articleLang === 'en') {
			setEnData((prev) => ({ ...prev, text: value }));
		} else {
			setRuData((prev) => ({ ...prev, text: value }));
		}
	};

	const preConfirmData = () => {
		if (!enConfirmed || !ruConfirmed) {
			setArticleLang(enConfirmed ? 'ru' : 'en');
			setIsShowNotice(true);
		} else {
			setIsShowNotice(false);
			setIsShowConfirmQuestion(true);
		}
	};

	const prepareToSendData = () => {
		setPageLoading(true);
		sendPics();
	};

	const sendPics = () => {
		if (
			(enData.pic && typeof enData.pic !== 'string') ||
			(ruData.pic && typeof ruData.pic !== 'string') ||
			(isEdit && (!enData.pic || !ruData.pic))
		) {
			Promise.all([
				enData.pic &&
					typeof enData.pic !== 'string' &&
					uploadAdminArticlePicture(enData.pic),
				ruData.pic &&
					typeof ruData.pic !== 'string' &&
					uploadAdminArticlePicture(ruData.pic),
				!enData.pic && deleteAdminArticlePicFile(enData.pic_identifier),
				!ruData.pic && deleteAdminArticlePicFile(ruData.pic_identifier),
			]).then((res) => {
				enData.pic &&
					typeof enData.pic !== 'string' &&
					setEnData((prev) => ({ ...prev, pic_identifier: res[0].id }));
				ruData.pic &&
					typeof ruData.pic !== 'string' &&
					setRuData((prev) => ({ ...prev, pic_identifier: res[1].id }));
				setIsPicsUploaded(true);
			});
		} else if (
			(!enData.pic || typeof enData.pic === 'string') &&
			(!ruData.pic || typeof ruData.pic === 'string')
		) {
			setIsPicsUploaded(true);
		}
	};

	useEffect(() => {
		if (isPicsUploaded) {
			const articleObj = [
				{
					language: 'ru',
					heading: ruData.title,
					description: ruData.text.toString('html'),
					file: ruData.pic_identifier || null,
					section_id: ruData.section_selected.id,
				},
				{
					language: 'en',
					heading: enData.title,
					description: enData.text.toString('html'),
					file: enData.pic_identifier || null,
					section_id: enData.section_selected.id,
				},
			];

			if (!isEdit) {
				createAdminArticle(articleObj).then((res) => {
					history.push('/admin/v2/articles');
				});
			} else {
				Promise.all([
					updateAdminArticle(ruData.article_id, [articleObj[0]]),
					updateAdminArticle(enData.article_id, [articleObj[1]]),
				]).then((res) => {
					history.push('/admin/v2/articles');
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPicsUploaded]);

	const debouncedSendData = debounce(() => prepareToSendData(), 500);

	const langChange = () => {
		setArticleLang((prev) => (prev === 'en' ? 'ru' : 'en'));
	};

	const changeSelectSectionField = (field) => (value) => {
		if (articleLang === 'en') {
			setEnData((prev) => ({ ...prev, section_selected: value }));
			const equalSection = ruData.sections.find(
				(section) => section.slug === value.slug
			);
			setRuData((prev) => ({ ...prev, section_selected: equalSection }));
		} else {
			setRuData((prev) => ({ ...prev, section_selected: value }));
			const equalSection = enData.sections.find(
				(section) => section.slug === value.slug
			);
			setEnData((prev) => ({ ...prev, section_selected: equalSection }));
		}
	};

	const handleFileUpload = (files) => {
		setErrors({});
		if (
			files[0].type !== 'image/jpg"' &&
			files[0].type !== 'image/jpeg' &&
			files[0].type !== 'image/png'
			// files[0].type !== 'image/tiff'
		) {
			setErrors({ file: [{ rule: 'image_wrong_format' }] });
			return;
		}
		const correctedFile = Object.assign(files[0], {
			preview: URL.createObjectURL(files[0]),
		});
		const fd = new FormData();
		fd.append('file', correctedFile);

		if (articleLang === 'en') {
			setEnData((prev) => ({
				...prev,
				pic: fd,
				pic_preview: URL.createObjectURL(files[0]),
			}));
		} else {
			setRuData((prev) => ({
				...prev,
				pic: fd,
				pic_preview: URL.createObjectURL(files[0]),
			}));
		}

		return Promise.resolve();
	};

	const deleteArticle = () => {
		Promise.all([
			ruData.article_id && deleteAdminArticle(ruData.article_id),
			enData.article_id && deleteAdminArticle(enData.article_id),
		])
			.then((res) => {
				history.push('/admin/v2/articles');
			})
			.catch((err) => {
				console.error('delete-error');
				setPageLoading(false);
			});
	};

	const debouncedDeleteArticle = debounce(() => deleteArticle(), 500);

	return (
		<div className={styles.wrapper}>
			{pageLoading ? (
				<Loading className={styles.loader} />
			) : (
				<ul>
					<li className={styles.component}>
						<div className={styles.createArticle__header}>
							<span>
								<FormattedMessage
									id={
										isEdit ? 'rod.faq.edit_article' : 'rod.faq.create_article'
									}
								/>
							</span>
							{isEdit && (
								<span
									style={{ cursor: 'pointer', textDecoration: 'underline' }}
									onClick={() => {
										setIsShowConfirmQuestion(true);
										setIsDeleteConfirmed(true);
										// setIsShowConfirmQuestion(true);
									}}
								>
									<FormattedMessage id={'rod.drafts.delete'} />
								</span>
							)}
						</div>
					</li>
					<li className={styles.component}>
						<div className={styles.createArticle__lang}>
							<ul className={styles.Tray}>
								<li onClick={langChange} style={{ maxWidth: '50px' }}>
									<FaqAdminLang lang={articleLang} className={styles.Lang} />
								</li>
							</ul>
						</div>
					</li>
					<li className={styles.component}>
						<div className={styles.createArticle__name}>
							{/* <FormattedMessage id={'rod.faq.article_title'}>
								{(placeholder) => (
									<input
										type="text"
										name="articleTitle"
										className={styles.articleNameInput}
										placeholder={placeholder}
										value={articleLang === 'en' ? enData.title : ruData.title}
										onChange={titleChange}
									/>
								)}
							</FormattedMessage> */}
							<FormattedMessage id={'rod.faq.article_title'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'title'}
										onChange={titleChange}
										errors={errors}
										required
										data={articleLang === 'en' ? enData : ruData}
										className={styles.articleNameInput}
									/>
								)}
							</FormattedMessage>
							{Object.keys(errors).includes('heading') && (
								<span className={styles.Helper}>
									{showError('title')(errors.heading[0])}
								</span>
							)}
						</div>
					</li>
					<li className={styles.componentRichText}>
						<div className={styles.createArticle__body}>
							<RichTextEditor
								toolbarClassName={styles.toolbarEditor}
								editorClassName={styles.textEditor}
								placeholder={
									<FormattedMessage
										id={'rod.faq.article_main_text'}
									></FormattedMessage>
								}
								value={articleLang === 'en' ? enData.text : ruData.text}
								onChange={editorTextChange}
								toolbarConfig={toolbarConfig}
							/>
						</div>
					</li>
					<li className={styles.component}>
						{
							<FormattedMessage id={'rod.admin.articles.rubric'}>
								{(placeholder) => (
									<FormInput
										type={'MuiSelectRubric'}
										name={'rubrics'}
										onChange={changeSelectSectionField}
										errors={errors}
										value={
											articleLang === 'en'
												? enData.section_selected
												: ruData.section_selected
										}
										items={
											articleLang === 'en' ? enData.sections : ruData.sections
										}
										label={placeholder}
										placeholder={placeholder}
									/>
								)}
							</FormattedMessage>
						}
					</li>
					<li className={styles.component}>
						<div className={styles.DropZone__wrapper}>
							{(articleLang === 'en' && enData.pic_preview) ||
							(articleLang === 'ru' && ruData.pic_preview) ? (
								<div className={styles.Preview}>
									<img
										className={styles.loader}
										src={
											articleLang === 'en'
												? enData.pic_preview
												: ruData.pic_preview
										}
										alt=""
										// width="200"
									/>
									<div
										className={styles.delete}
										onClick={() =>
											articleLang === 'en'
												? setEnData((prev) => ({
														...prev,
														pic: null,
														pic_preview: '',
												  }))
												: setRuData((prev) => ({
														...prev,
														pic: null,
														pic_preview: '',
												  }))
										}
									>
										<FormattedMessage
											id={'rod.release.create.step.cover.delete'}
										/>
									</div>
								</div>
							) : (
								<>
									<DropZone
										multiple={false}
										accept=".jpg, .jpeg, .png"
										imgType={2}
										classStyles={styles.DropZone}
										dropzoneId={'rod.faq.pic.upload'}
										onUpload={handleFileUpload}
										kb
										maxSize={1024 * 1024 * 100}
									/>
								</>
							)}
						</div>
					</li>
					<li className={styles.component}>
						{!isShowConfirmQuestion ? (
							<>
								{isShowNotice && (
									<div className={styles.notify__confirm_question}>
										<FormattedHTMLMessage id={'rod.faq.confirm_one_article'} />
									</div>
								)}
								<div className={styles.notify__confirm}>
									<Button
										text={
											<FormattedHTMLMessage
												id={
													isEdit
														? 'rod.performance_work.save'
														: 'rod.action.create_single'
												}
											/>
										}
										className={
											// !confirmed ? styles.inactiveBtn : styles.activeBtn
											(articleLang === 'en' && enConfirmed) ||
											(articleLang === 'ru' && ruConfirmed)
												? styles.activeBtn
												: styles.inactiveBtn
										}
										variant={'primary'}
										// disabled={!confirmed}
										disabled={
											!(
												(articleLang === 'en' && enConfirmed) ||
												(articleLang === 'ru' && ruConfirmed)
											)
										}
										onClick={preConfirmData}
									/>
								</div>
							</>
						) : (
							<div className={styles.notify__confirm_question_wrapper}>
								<div className={styles.notify__confirm_question}>
									<FormattedHTMLMessage
										id={
											(enConfirmed && ruConfirmed) || isDeleteConfirmed
												? 'rod.modal.notify.admin.confirm-question'
												: 'rod.faq.confirm_one_article'
										}
									/>
								</div>
								{/* {enConfirmed && ruConfirmed && ( */}
								<div className={styles.notify__confirm}>
									<Button
										text={
											<FormattedHTMLMessage
												id={'rod.modal.notify.admin.confirm-yes'}
											/>
										}
										className={styles.activeBtnConfirmQuestion}
										variant={isConfirmedBtnActive ? 'primary' : 'disabled'}
										onClick={() => {
											if (isDeleteConfirmed) {
												setIsConfirmedBtnActive(false);
												setPageLoading(true);
												debouncedDeleteArticle();
											} else if (isConfirmedBtnActive) {
												setIsConfirmedBtnActive(false);
												debouncedSendData();
											}
										}}
									/>
									<Button
										text={
											<FormattedHTMLMessage
												id={'rod.modal.notify.admin.confirm-no'}
											/>
										}
										className={styles.activeBtnConfirmQuestion}
										variant={isConfirmedBtnActive ? 'primary' : 'disabled'}
										onClick={() => {
											if (isDeleteConfirmed) {
												setIsDeleteConfirmed(false);
												setIsShowConfirmQuestion(false);
											} else if (isConfirmedBtnActive) {
												setIsShowConfirmQuestion(false);
											}
										}}
									/>
								</div>
								{/* 	)} */}
							</div>
						)}
					</li>
				</ul>
			)}
		</div>
	);
};
export default compose(withUI, withRoot)(CreateArticle);
