// Core
import React, { useEffect, useState, useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import lscache from 'lscache';

// Context
import { UIContext } from 'contexts/UIContext';

// Data
import { narrative } from './helpers/narrative';

// Services
import { accounts } from 'services';
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { BottomNavi } from 'components';
import { InfoButton } from 'components/Buttons';
import { FormInput } from 'components/Form/FormInput';
import LyricWrapper from './LyricWrapper/LyricWrapper';
import { Checkbox } from 'components/Checkboxes/Checkbox';

// Styles
import s from './CompositionLyrics.module.css';

const CompositionLyrics = (props) => {
	const [compositionData, setCompositionData] = useState({});
	const [isTextAreaOn, setIsTextAreaOn] = useState(false);
	const [languages, setLanguages] = useState([]);
	const [isReqLive] = useState(false);
	const [errors, setErrors] = useState({});

	const lang = localStorage.getItem('lang');
	const compositionId = localStorage.getItem('compositionId');
	const history = useHistory();
	const { showModal, showBackNavi, hideBackNavi } = useContext(UIContext);

	const MAX_LYRICS_LENGTH = 10000;

	useEffect(() => {
		if (compositionId) {
			accounts
				.getCompositionData(compositionId)
				.then((res) => {
					res.data.data['created_date'] = res.data.data['created_date'].match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];
					res.data.data['composition_type_id'] = +res.data.data[
						'composition_type_id'
					];
					if (res.data.data?.lyrics_id?.url) {
						getLyricTextFromFile(res.data.data);
					} else {
						setCompositionData(res.data.data);
						setIsTextAreaOn(false);
					}
				})
				.catch((err) => {
					console.info('Error', err);
				});
		}
		const languagesCache = lscache.get('languagesCache');
		if (languagesCache && !isReqLive) {
			setLanguages(languagesCache);
		} else {
			accounts
				.getLanguages()
				.then((res) => {
					res = res.data.data;
					setCache('languages', 'languagesCache', res);
					setLanguages(res);
				})
				.catch((error) => {
					console.info('Error', error);
				});
		}

		showBackNavi();
		return function cleanup() {
			hideBackNavi();
		};
	}, []);

	const getLyricTextFromFile = (data) => {
		fetch(data.lyrics_id.url)
			.then((res) => res.text())
			.then((text) => {
				data.lyrics = text;
				setIsTextAreaOn(true);
				setCompositionData(data);
			})

			.catch((err) => {
				setCompositionData(data);
				console.info('Error:', err);
			});
	};

	const changeField = (field) => (e) => {
		setErrors({});

		switch (field) {
			case 'language':
				compositionData[field] = e.id;
				break;
			case 'parental_warning_type':
				compositionData[field] = e.code;
				break;
			default:
				compositionData[field] = e.target.value;
				break;
		}

		setCompositionData({ ...compositionData });
	};

	const handleChecked = (e) => {
		if (!e.target.checked && compositionData.lyrics) {
			compositionData.lyrics = null;
			setCompositionData({ ...compositionData });
		}
		setIsTextAreaOn(e.target.checked);
		setErrors({});
	};

	const isValid = () => {
		let errorCount = 0;
		const newError = {};

		if (!compositionData.language) {
			newError.language = 'required';
			errorCount++;
		}

		if (!compositionData.parental_warning_type) {
			newError.parental_warning_type = 'required';
			errorCount++;
		}

		if (!compositionData.lyrics && isTextAreaOn) {
			newError.lyrics = 'required';
			errorCount++;
		}

		if (
			compositionData.lyrics &&
			isTextAreaOn &&
			+compositionData.lyrics.length > MAX_LYRICS_LENGTH
		) {
			newError.lyrics = 'max_length';
			errorCount++;
		}

		if (!errorCount) {
			setErrors({});
			return true;
		} else {
			setErrors(newError);

			return false;
		}
	};

	const nextHandler = () => {
		const valid = isValid();
		if (typeof props.onChange === 'function' && valid) {
			props.onChange({ ...compositionData, add_lyrics: isTextAreaOn });
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			history.push(`/compositions/${compositionId}/authors_shares`);

			props.handlePrevStep();
		}
	};

	const disabledNextBtn =
		!compositionData.language ||
		!compositionData.parental_warning_type ||
		(isTextAreaOn &&
			(!compositionData.lyrics || compositionData.lyrics.trim() === ''));

	return (
		<>
			<div className={s.page}>
				<div className={s.form}>
					<InfoButton
						className={`${s.info} ${s.title__info}`}
						onClick={showModal({
							text: (
								<FormattedHTMLMessage
									id={'rod.release.create.step.tracks.title.help'}
								/>
							),
						})}
					>
						<span className={`${s.title}`}>
							<FormattedHTMLMessage id={'rod.release.create.step.lyrics'} />
						</span>
					</InfoButton>
					<div className={`${s.form__section} ${s.first__section}`}>
						<div className={s.input_leftCol}>
							<FormInput
								type="muiInputSelect"
								name={'language'}
								onChange={changeField}
								errors={errors}
								data={compositionData}
								label={
									<FormattedMessage
										id={'rod.release.create.step.recording_info.label.lang'}
									/>
								}
								items={languages}
								languages={true}
								required
							/>
							{errors && Object.keys(errors).includes('language') && (
								<span className={s.Helper}>
									{
										<FormattedMessage
											id={`rod.release_create.error.required`}
										/>
									}
								</span>
							)}
						</div>
						<div className={s.input_rightCol}>
							<FormInput
								className={s.narrative}
								type={'MuiSelectNarrative'}
								name={'parental_warning_type'}
								onChange={changeField}
								errors={errors}
								data={compositionData}
								label={
									<FormattedMessage
										id={
											'rod.release.create.step.recording_info.label.non_narrative'
										}
									/>
								}
								items={narrative}
								required
							/>
							{errors &&
								Object.keys(errors).includes('parental_warning_type') && (
									<span className={s.Helper}>
										{
											<FormattedMessage
												id={`rod.release_create.error.required`}
											/>
										}
									</span>
								)}
						</div>
					</div>
					<div className={s.checkBoxWrapper}>
						<Checkbox
							text={
								<FormattedMessage
									id={'rod.release.create.step.lyrics.add_lyrics'}
								/>
							}
							checked={isTextAreaOn}
							onChange={handleChecked}
							className={s.checkbox}
						/>
					</div>
					{isTextAreaOn && (
						<LyricWrapper
							changeField={changeField}
							errors={errors}
							compositionData={compositionData}
							isTextAreaOn={isTextAreaOn}
						/>
					)}
				</div>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
};

export default CompositionLyrics;
