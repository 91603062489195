// Core
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import CloseIcon from '@mui/icons-material/Close';
import { LangContext } from 'contexts/LangContext';
import checked from 'images/circle_checked.svg';
import no_checked from 'images/circle_unchecked.svg';

// Styles
import s from './RepertoireFilterModal.module.css';

const RepertoireFilterModal = ({
	active,
	setActive,
	filterData,
	setFilterData,
	setCurrentFilter,
	selectFilters,
	setSelectFilters,
}) => {
	useEffect(() => {
		if (active) {
			document.body.style.position = 'fixed';
			document.body.style.top = `-${window.scrollY}px`;
		}
		if (!active) {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}, [active]);

	const settings = filterData || [];

	const { lang } = useContext(LangContext);

	const handleChecked = (item, setting) => {
		const newSettings = [...settings];
		const targetIndex = setting.id - 1;
		const itemIndex = item.id - 1;
		const list = newSettings[itemIndex].list;

		list.forEach((item, index) => {
			item.checked = index === targetIndex ? !setting.checked : false;
		});

		setFilterData(newSettings);
		handleSetCurrentFilter(item, setting);
	};

	const handleSetCurrentFilter = (item, setting) => {
		const newCurrentFilter = {};
		newCurrentFilter['' + item.id + setting.id] = {
			parentId: item.id,
			currentId: setting.id,
			id: '' + item.id + setting.id,
			title_en: item.title_en,
			title_ru: item.title_ru,
			name_en: setting.name_en,
			name_ru: setting.name_ru,
			show: setting.checked ? true : false,
			code: setting.code,
		};

		setSelectFilters(newCurrentFilter);
	};

	const handleApplyAllFilters = () => {
		const applySettings = { ...selectFilters };

		if (!filterData[0].list.find((item) => item.checked === true)) {
			setCurrentFilter({});
		} else {
			setCurrentFilter(applySettings);
		}
		setActive(false);
	};

	return (
		<>
			<div className={active ? `${s.Overlay} ${s.OverlayActive}` : s.Overlay} />

			<div
				className={active ? `${s.modal} ${s.active}` : s.modal}
				onClick={() => setActive(false)}
			>
				<div className={s.container} onClick={(e) => e.stopPropagation()}>
					<div className={s.modalHeader}>
						<div className={s.modalHeader__titleWrapper}>
							<span className={s.modalHeader__title}>
								<FormattedMessage
									id={'rod.release.info.sort'}
								></FormattedMessage>
							</span>
							<div className={s.modalHeader__icon}>
								<CloseIcon
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					<div className={s.modalBody}>
						{settings.map((item) => (
							<div key={item.id} className={s.setting}>
								<div className={s.setting__title}>
									{lang === 'en' ? item.title_en : item.title_ru}
								</div>
								<ul className={s.setting__list}>
									{item.list.map((setting) => {
										if (setting.code === 'verify') return <></>;
										return (
											<li
												className={s.setting__item}
												key={setting.id}
												onClick={() => handleChecked(item, setting)}
											>
												<img
													className={s.setting__checkbox}
													src={setting.checked ? checked : no_checked}
													width={'26px'}
													alt=""
												/>
												<span className={s.setting__label}>
													{lang === 'en' ? setting.name_en : setting.name_ru}
												</span>
											</li>
										);
									})}
								</ul>
							</div>
						))}
						<button
							className={s.button__select}
							onClick={() => handleApplyAllFilters()}
						>
							<FormattedMessage id={'rod.release.info.sort'}></FormattedMessage>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default RepertoireFilterModal;
