// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Routes
import ProtectedRoute from 'routes/ProtectedRoute';

// UI
import { Loading } from 'components';
import { Layout } from 'containers';
import { TerritorySelect } from 'forms/contracts/TerritorySelect';
import { AddCompositionPage } from 'material-design/AddCompositionPage';
import { CompositionEnd } from 'material-design/AddCompositionPage/steps';
import MainPage from 'material-design/MainPage/MainPage';
import { ReleaseCreate } from 'material-design/ReleaseCreate';
import { End } from 'material-design/ReleaseCreate/steps';
import { Repertoire } from 'material-design/Repertoire';
import { RepertoireComposition } from 'material-design/Repertoire/RepertoireCategory/RepertoireCompositionSection/RepertoireComposition';
import { RepertoireRecording } from 'material-design/Repertoire/RepertoireCategory/RepertoireRecordingSection/RepertoireRecording';
import { RepertoireRelease } from 'material-design/Repertoire/RepertoireCategory/RepertoireReleaseSection/RepertoireRelease';
import { RepertoireReleaseShipment } from 'material-design/Repertoire/RepertoireCategory/RepertoireReleaseSection/RepertoireRelease/RepertoireReleaseShipment';
import { RepertoireReleaseTakenDown } from 'material-design/Repertoire/RepertoireCategory/RepertoireReleaseSection/RepertoireRelease/RepertoireReleaseTakenDown';
import { AdminV2 } from 'pages/adminV2';
import SpecialSuccess from 'pages/auth/Registration/SpecialSuccess/SpecialSuccess';
import Pending from 'pages/contracts/AddContract/steps/07-Pending/Pending';
import { AccessDenied } from 'pages/errors/AccessDenied';
import { NotFound } from 'pages/errors/NotFound';
import { SessionExpired } from 'pages/errors/SessionExpired';
import { Unavailable } from 'pages/errors/Unavailable';
import { QAPage } from 'pages/qa';
import { Dashboard } from 'pages/repertoire/Dashboard';
import { LegacyDashboard } from 'pages/repertoire/LegacyDashboard';
import { About } from 'pages/static/About';
import { Features } from 'pages/static/Features';
import { Terms } from 'pages/static/Terms';

// Pages
import { Faq } from 'material-design/Faq';
import { ReportsUserPage } from 'material-design/ReportsUser';
import { Statistic } from 'material-design/Statistic';
import { TreatiesPage } from 'material-design/TreatiesPage';
import { UserReportsPage } from 'material-design/UserReportsWithPayment';
import DataConfirm from 'material-design/UserReportsWithPayment/DataConfirm/DataConfirm';
import { UserSign } from 'material-design/UserReportsWithPayment/Sign';
import { AudioPlayer } from 'material-design/components/audioPlayer';
import {
	Account,
	AccountSettings,
	Accounts,
	AddContract,
	ContractInfo,
	Create,
	EmailCheck,
	EmailNoCode,
	ForgotPassword,
	ForgotPasswordError,
	ForgotPasswordSend,
	ForgotPasswordSuccess,
	LandingNew,
	Logout,
	RegisterSpecial,
	Registration,
	Success as RegistrationSuccessful,
	SMSCheck,
	SMSNoCode,
	Success,
} from 'pages';
import { ContractOverduePage } from 'pages/ContractOverduePage';
import { NoContractPage } from 'pages/NoContractPage';
import { CreatePseudonym } from 'pages/accountSettings/SettingsCategory/SettingsPseudonymsSection/CreatePseudonym';

const Routes = ({ accounts }) => {
	const authContext = useContext(AuthContext);
	const {
		isPlayerShow,
		getFeaturesFlags,
		isAllowAccountCreate,
		isAdditionalAccountCreationEnabled,
		isAccountFeatureRecordingView,
		isAccountFeatureCompositionView,
		isReportsEnable,
		isPaymentsEnabled,
		isFaqEnabled,
		isPseudonymsEnable,
		isDisplayingStatistics,
	} = useContext(UIContext);
	const { lang, getLanguages } = useContext(LangContext);
	const { update } = useContext(RootContext);
	const { getAllowLanguageDetectionParam, getFlagForWidget } = authContext;
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (window.hj) {
			window.hj('tagRecording', []);
		}
	}, []);

	useEffect(() => {
		window.addEventListener('load', isLoadHTMLdone);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isReady) {
			window.removeEventListener('load', isLoadHTMLdone);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReady]);

	const isLoadHTMLdone = () => {
		if (!isReady) {
			setIsReady(true);
		}
	};

	const isFeatureFlag = (res, flag) => {
		if (
			flag === 'feature.show_contracts' ||
			flag === 'feature.disable_release_create' ||
			flag === 'feature.allow_manual_reports_without_automation' ||
			flag === 'feature.disable_management' ||
			flag === 'feature.displaying_contracts' ||
			flag === 'feature.contract_service_role' ||
			flag === 'feature.private_policy' ||
			flag === 'feature.not_transfer_release' ||
			flag === 'feature.materials_available_editing' ||
			flag === 'feature.interaction_shipped_release'
		) {
			return res[flag] ? JSON.parse(res[flag]) : false;
		}

		return res[flag] ? JSON.parse(res[flag]) : true;
	};

	useEffect(() => {
		accounts
			.getFeatures()
			.then((res) => {
				res = res.data.data;
				getAllowLanguageDetectionParam(
					isFeatureFlag(res, 'feature.allow_language_detection')
				);


				getFeaturesFlags(
					isFeatureFlag(res, 'feature.account_feature.recording_view'),
					isFeatureFlag(res, 'feature.account_feature.composition_view'),
					isFeatureFlag(res, 'feature.allow_account_create'),
					isFeatureFlag(res, 'feature.additional_account_creation.enabled'),
					isFeatureFlag(res, 'feature.allow_signup'),
					isFeatureFlag(res, 'feature.show_contracts'),
					isFeatureFlag(res, 'feature.add_composition_enabled'),
					isFeatureFlag(res, 'feature.allow_manual_report'),
					isFeatureFlag(res, 'feature.faq.enabled'),
					isFeatureFlag(res, 'feature.allow_pseudonyms_setting'),
					isFeatureFlag(res, 'feature.displaying_statistics'),
					isFeatureFlag(res, 'feature.disable_release_create'),
					isFeatureFlag(res, 'feature.allow_manual_reports_without_automation'),
					isFeatureFlag(res, 'feature.disable_management'),
					isFeatureFlag(res, 'feature.signing_additional_contract'),
					isFeatureFlag(res, 'feature.displaying_contracts'),
					isFeatureFlag(res, 'feature.contract_service_role'),
					isFeatureFlag(res, 'feature.banner_services_repertoire'),
					isFeatureFlag(res, 'feature.private_policy'),
					isFeatureFlag(res, 'feature.not_transfer_release'),
					isFeatureFlag(res, 'feature.materials_available_editing'),
					isFeatureFlag(res, 'feature.interaction_shipped_release'),
					isFeatureFlag(res, 'feature.allow_payments')
				);

				if (
					res['feature.languages'] &&
					Array.isArray(res['feature.languages'])
				) {
					getLanguages(res['feature.languages']);
				}

				if (res['feature.displaying.technical_support_widget']) {
					getFlagForWidget(res['feature.displaying.technical_support_widget']);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		update();
		// eslint-disable-next-line
	}, [lang]);

	useEffect(() => {
		const fcWidget = document.body.querySelector('#fc_frame');
		if (isPlayerShow) {
			fcWidget?.classList.add('widget-with-audio');
			return;
		}
		fcWidget?.classList.remove('widget-with-audio');
	}, [isPlayerShow]);

	const isRenderCreateAccount = () => {
		const accountsQty = authContext.user.accounts.length;
		switch (isAllowAccountCreate) {
			case true:
				if (!accountsQty) {
					return true;
				} else if (isAdditionalAccountCreationEnabled) {
					return true;
				} else return false;

			case false:
				return false;
			default:
				break;
		}
	};

	if (!authContext.loaded || !authContext.isJoinCheckedComplete) {
		return <Loading />;
	}

	const isNoContract = localStorage.getItem('no_contract');
	if (authContext.isAuth) {
		if (isNoContract) {
			return (
				<Layout>
					<Switch>
						<Route path="/no_contract" exact component={NoContractPage} />
						<Route
							path="/contract_overdue"
							exact
							component={ContractOverduePage}
						/>
						<ProtectedRoute path="/accounts/:id" exact component={Account} />
						<Route path="/logout" exact component={Logout} />
						<Redirect to={'/no_contract'} />
					</Switch>
				</Layout>
			);
		} else {
			return (
				<Layout withPlayer={isPlayerShow}>
					<>
						<Switch>
							<ProtectedRoute path="/logout" exact component={Logout} />
							<Route path="/no_contract" exact component={NoContractPage} />
							{isFaqEnabled && <Route path="/faq" component={Faq} />}
							{isFaqEnabled && (
								<Route path="/faq/search" exact component={Faq} />
							)}
							<Route
								path="/admin/v2/statistics_service/:outlet/:date/issues"
								exact
								component={AdminV2}
							/>
							<Route
								path="/profile/settings/private_data"
								exact
								component={AccountSettings}
							/>
							<Route
								path="/profile/settings/contact_data"
								exact
								component={AccountSettings}
							/>
							<Route
								path="/profile/settings/bank_data"
								exact
								component={AccountSettings}
							/>
							{isPseudonymsEnable && (
								<Route
									path="/profile/settings/pseudonyms"
									exact
									component={AccountSettings}
								/>
							)}
							{isPseudonymsEnable && (
								<Route
									path="/profile/settings/pseudonyms/create"
									exact
									component={CreatePseudonym}
								/>
							)}
							{isPseudonymsEnable && (
								<Route
									path="/profile/:acc_id/settings/pseudonyms/:pseud_id/edit"
									exact
									component={CreatePseudonym}
								/>
							)}
							<Route
								path="/registration/email/check"
								exact
								component={EmailCheck}
							/>
							<Route
								path="/registration/email/nocode"
								exact
								component={EmailNoCode}
							/>
							<Route
								path="/registration/success"
								exact
								component={RegistrationSuccessful}
							/>
							<Route
								path="/registration/special"
								exact
								component={RegisterSpecial}
							/>
							<Route
								path="/registration/sms/check"
								exact
								component={SMSCheck}
							/>
							<Route
								path="/registration/sms/nocode"
								exact
								component={SMSNoCode}
							/>
							<Route path="/email/verify/:code" exact component={EmailCheck} />
							{!isRenderCreateAccount() && (
								<Redirect from={'/accounts/new'} to={'/denied'} />
							)}
							<ProtectedRoute path="/accounts/new" exact component={Create} />
							<ProtectedRoute path="/accounts/:id" exact component={Account} />
							<ProtectedRoute path="/accounts" exact component={Accounts} />
							<ProtectedRoute
								path="/accounts/:id/contracts/add"
								exact
								component={AddContract}
							/>
							<ProtectedRoute
								path="/accounts/:id/contracts/add/:useType/:mediaType/excluded"
								exact
								component={TerritorySelect}
							/>
							<ProtectedRoute
								path="/accounts/:id/contracts/sign"
								exact
								component={AddContract}
							/>
							<Route
								path="/accounts/:id/contracts/pending"
								exact
								component={Pending}
							/>
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from="/accounts/:id/dashboard"
									to={`/accounts/${authContext.user.account_id}`}
								/>
							) : (
								<ProtectedRoute
									path="/accounts/:id/dashboard"
									exact
									component={
										authContext.isLegacy === false ? MainPage : LegacyDashboard
									}
								/>
							)}
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from={'/repertoire/upload'}
									to={
										isAllowAccountCreate === true &&
										isAdditionalAccountCreationEnabled === true
											? `/accounts/${authContext.user.account_id}`
											: `/repertoire/${authContext.user.account_id}/upload`
									}
								/>
							) : (
								<Route
									path="/repertoire/:id/upload"
									exact
									component={Dashboard}
								/>
							)}
							<Route path="/mainpage" exact component={MainPage} />
							<Route path="/treaties" exact component={TreatiesPage} />
							<Route path="/treaties/form/:id" exact component={AddContract} />
							<Route
								path="/treaties/addrole/:id"
								exact
								component={AddContract}
							/>
							<Route
								path="/treaties/addservices/:id"
								exact
								component={AddContract}
							/>
							<Route
								path="/treaties/form/:id/:useType/:mediaType/excluded"
								exact
								component={TerritorySelect}
							/>
							<Route
								path="/treaties/info/:id/condition/:conditionid"
								exact
								component={ContractInfo}
							/>
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from="/repertoire/drafts"
									to={`/accounts/${authContext.user.account_id}`}
								/>
							) : (
								<Route path="/repertoire/drafts" exact component={Repertoire} />
							)}
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from="/repertoire/releases"
									to={`/accounts/${authContext.user.account_id}`}
								/>
							) : (
								<Route
									path="/repertoire/releases"
									exact
									component={Repertoire}
								/>
							)}
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from="/repertoire/recordings"
									to={`/accounts/${authContext.user.account_id}`}
								/>
							) : (
								<Route
									path="/repertoire/recordings"
									exact
									component={Repertoire}
								/>
							)}
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from="/repertoire/compositions"
									to={`/accounts/${authContext.user.account_id}`}
								/>
							) : (
								<Route
									path="/repertoire/compositions"
									exact
									component={Repertoire}
								/>
							)}
							<Route
								path="/repertoire/releases/:id/"
								exact
								component={RepertoireRelease}
							/>
							<Route
								path="/repertoire/releases/:id/info"
								exact
								component={RepertoireRelease}
							/>
							<Route
								path="/repertoire/releases/:id/notices"
								exact
								component={RepertoireRelease}
							/>
							<Route
								path="/repertoire/releases/:id/moderation"
								exact
								component={RepertoireRelease}
							/>
							<Route
								path="/repertoire/releases/:id/deliveries"
								exact
								component={RepertoireRelease}
							/>
							<Route
								path="/repertoire/releases/:id/shipment"
								exact
								component={RepertoireReleaseShipment}
							/>
							<Route
								path="/repertoire/releases/:id/takendown"
								exact
								component={RepertoireReleaseTakenDown}
							/>
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from="/compositions/create"
									to={`/accounts/${authContext.user.account_id}`}
								/>
							) : (
								<Route
									path="/compositions/create"
									exact
									component={AddCompositionPage}
								/>
							)}
							<Route
								path="/compositions/:id/create"
								exact
								component={AddCompositionPage}
							/>
							<Route
								path="/compositions/:id/authors_shares"
								exact
								component={AddCompositionPage}
							/>
							<Route
								path="/compositions/:id/lyrics"
								exact
								component={AddCompositionPage}
							/>
							<Route
								path="/compositions/:id/composition_recordings"
								exact
								component={AddCompositionPage}
							/>
							<Route
								path="/compositions/:id/review"
								exact
								component={AddCompositionPage}
							/>
							<Route
								path="/compositions/moderation"
								exact
								component={CompositionEnd}
							/>
							{isAccountFeatureCompositionView && (
								<Route
									path="/repertoire/compositions/:id"
									exact
									component={RepertoireComposition}
								/>
							)}
							{isAccountFeatureCompositionView && (
								<Route
									path="/repertoire/compositions/:id/details"
									exact
									component={RepertoireComposition}
								/>
							)}
							{isAccountFeatureCompositionView &&
								isAccountFeatureRecordingView && (
									<Route
										path="/repertoire/compositions/:id/recordings"
										exact
										component={RepertoireComposition}
									/>
								)}
							{isAccountFeatureCompositionView && (
								<Route
									path="/repertoire/compositions/:id/releases"
									exact
									component={RepertoireComposition}
								/>
							)}
							{isAccountFeatureCompositionView && (
								<Route
									path="/repertoire/compositions/:id/notices"
									exact
									component={RepertoireComposition}
								/>
							)}

							{isAccountFeatureRecordingView && (
								<Route
									path="/repertoire/recordings/:id"
									exact
									component={RepertoireRecording}
								/>
							)}
							{!authContext.isJoin && authContext.user.account_id ? (
								<Redirect
									from="/release/create/"
									to={`/accounts/${authContext.user.account_id}`}
								/>
							) : !authContext.isJoin && !authContext.user.account_id ? (
								<Redirect from="/release/create/" to={'/accounts'} />
							) : (
								<Route
									path="/release/create/"
									exact
									component={ReleaseCreate}
								/>
							)}
							<Route
								path="/release/:id/release"
								exact
								component={ReleaseCreate}
							/>
							<Route path="/release/:id/file" exact component={ReleaseCreate} />
							<Route
								path="/release/:id/tracks"
								exact
								component={ReleaseCreate}
							/>
							<Route
								path="/release/:id/authors"
								exact
								component={ReleaseCreate}
							/>
							<Route
								path="/release/:id/lyrics"
								exact
								component={ReleaseCreate}
							/>
							<Route
								path="/release/:id/cover"
								exact
								component={ReleaseCreate}
							/>
							<Route
								path="/release/:id/distribution"
								exact
								component={ReleaseCreate}
							/>
							<Route
								path="/release/:id/confirm"
								exact
								component={ReleaseCreate}
							/>
							<Route path="/release/moderation" exact component={End} />
							{authContext.user.role === 'admin' && (
								<Route path="/admin/v2/accounts" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && (
								<Route
									path="/admin/v2/statistics_service"
									component={AdminV2}
								/>
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/admin/v2/notifications" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/admin/v2/articles" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && isReportsEnable && (
								<Route path="/admin/v2/reports" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && isReportsEnable && (
								<Route
									path="/admin/v2/reports/distributions/:distributionId"
									component={AdminV2}
								/>
							)}

							{authContext.user.role === 'admin' && (
								<Route
									path="/admin/v2/articles/rubric-settings"
									component={AdminV2}
								/>
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/admin/v2/articles/edit" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/admin/v2/articles/create" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/admin/v2/handling_drafts" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/admin/v2/handling_release" component={AdminV2} />
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/iddqd" exact component={QAPage} />
							)}
							{authContext.user.role === 'admin' && (
								<Route path="/accounts/:id/iddqd" exact component={QAPage} />
							)}
							<Route path="/denied" component={AccessDenied} />
							<Route path="/session-expired" component={SessionExpired} />
							<Route path="/notfound" component={NotFound} />
							<Route path="/unavailable" component={Unavailable} />
							<Route path="/contracts" component={TerritorySelect} />
							<Route path="/about" component={About} />
							<Route path="/features" component={Features} />
							<Route path="/terms" component={Terms} />

							{authContext.isJoin && isDisplayingStatistics && (
								<Route
									path="/accounts/:id/statistic/bydate"
									exact
									component={Statistic}
								/>
							)}
							{authContext.isJoin && isDisplayingStatistics && (
								<Route
									path="/accounts/:id/statistic/byterritory"
									exact
									component={Statistic}
								/>
							)}
							{authContext.isJoin && isDisplayingStatistics && (
								<Route
									path="/accounts/:id/statistic/statistic-reports"
									exact
									component={Statistic}
								/>
							)}
							{authContext.isJoin && isReportsEnable && (
								<Route
									path="/accounts/:id/reports"
									exact
									component={isPaymentsEnabled ? UserReportsPage : ReportsUserPage}
								/>

							)}
							{authContext.isJoin && isPaymentsEnabled && (
								<Route
									path="/accounts/:id/reports/confirmation"
									exact
									component={DataConfirm}
								/>
							)}
							{authContext.isJoin && isPaymentsEnabled && (
								<Route
									path="/accounts/:id/reports/sign"
									exact
									component={UserSign}
								/>
							)}
							{authContext.user.account_id && (
								<Redirect
									from={'/'}
									to={
										isAllowAccountCreate === true &&
										isAdditionalAccountCreationEnabled === true
											? `/accounts/${authContext.user.account_id}`
											: `/accounts/${authContext.user.account_id}/dashboard`
									}
								/>
							)}
							<Redirect from={'/'} to={'/accounts'} />
						</Switch>
					</>
					{isPlayerShow && <AudioPlayer isPlayerShow={isPlayerShow} />}
				</Layout>
			);
		}
	}

	return (
		<>
			{isReady ? (
				<Layout>
					<Switch>
						<Route path="/login" component={LandingNew} />
						<Route
							path="/registration/special/success"
							exact
							component={SpecialSuccess}
						/>
						<Route
							path="/registration/special"
							exact
							component={RegisterSpecial}
						/>
						<Route
							path="/registration/help"
							exact
							render={() => (
								<Success>
									<FormattedMessage id={'rod.text.help.success'} />
								</Success>
							)}
						/>

						<Route path="/registration" exact component={Registration} />
						<Route path="/logout" exact component={Logout} />
						<Route path="/password/restore" exact component={ForgotPassword} />
						<Route
							path="/password/restore/email"
							exact
							component={ForgotPasswordSend}
						/>
						<Route
							path="/password/restore/wrong"
							exact
							component={ForgotPasswordError}
						/>
						<Route
							path="/password-reset/restore/:token"
							exact
							component={ForgotPasswordSuccess}
						/>

						<Route path="/session-expired" component={SessionExpired} />
						<Route path="/email/verify/:code" exact component={EmailCheck} />
						<Route
							path="/registration/sms/nocode"
							exact
							component={SMSNoCode}
						/>
						<Route path="/" exact component={LandingNew} />
						<Redirect to={'/login'} />
					</Switch>
				</Layout>
			) : (
				<Loading />
			)}
		</>
	);
};

export default Routes;
