// Core
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Context
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

// Data
import { acceptedFormats } from './helpers/acceptedFormats';
import { filesRu, filesEn } from './helpers/filesRu';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';

// Icons
import { ReactComponent as TriangleDown } from 'images/triangle_go_down.svg';
import { ReactComponent as TriangleUp } from 'images/triangle_go_up.svg';

// Styles
import styles from './Dashboard.module.css';

const Dashboard = (props) => {
	const {
		getAccountPersonalData,
		getAccountContactsData,
		uploadRepertoire,
		isDashboardScrolled,
	} = useContext(RootContext);
	const { lang } = useContext(LangContext);
	const { showUploadHandler, hideUploadHandler } = useContext(UIContext);

	const [repertoireFile, setRepertoireFile] = useState(null);
	const [uploaded, setUploaded] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [isBulletShow, setIsBulletShow] = useState(false);
	const [typeOfBullet, setTypeOfBullet] = useState('');
	let { params } = useRouteMatch();
	let { push } = useHistory();
	const formElement = useRef(null);

	const handleScroll = () => {
		const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

		clientHeight + Math.ceil(scrollTop) >= scrollHeight
			? setTypeOfBullet('up')
			: setTypeOfBullet('down');
	};

	useEffect(() => {
		const { clientWidth } = document.documentElement;
		const pageHeight = document.documentElement.clientHeight;
		const elementHeight = formElement.current?.clientHeight;
		if (formElement.current && elementHeight > pageHeight) {
			setIsBulletShow(true);
		}

		if (clientWidth > 1023) {
			isDashboardScrolled ? setTypeOfBullet('up') : setTypeOfBullet('down');
		}
	}, []);

	useEffect(() => {
		getAccountPersonalData(params.id)
			.then((account) => {
				account = account.data.data;
				if (
					account.completion_step !== accountCompletionSteps.PERSONAL_AREA &&
					account.completion_step !== accountCompletionSteps.REPERTOIRE_APPLY
				) {
					push(`/accounts/${params.id}`);
					hideUploadHandler();
				} else {
					// hideBackNaviHandler();
					showUploadHandler();
				}
				// setAccount(account);
			})
			.catch((err) => {
				console.error(
					'Dashboard - error.response - getAccountPersonalData: ',
					err.response
				);
				console.error('Dashboard - error - getAccountPersonalData: ', err);
				push('/accounts');
			});

		getAccountContactsData(params.id)
			.then((res) => {
				res = res.data.data;
				// setContactsData(res);
			})
			.catch((err) => push('/accounts'));
		window.addEventListener('scroll', handleScroll);

		return () => {
			hideUploadHandler();
			window.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const upload = (e) => {
		const fd = new FormData();

		fd.append(`file[0]`, repertoireFile);
		setIsUploading(true);
		uploadRepertoire(params.id, fd)
			.then((res) => {
				res = res.data.data;
				setUploaded(true);
				setIsUploading(false);
				setRepertoireFile(null);
			})
			.catch((err) => {
				setUploaded(false);
				setIsUploading(false);
				setRepertoireFile(null);
			});
	};

	const handleFileUpload = (files) => {
		if (files.length === 0) {
			const error = new Error('No file was uploaded.');
			console.error('Documents - uploadAccountDocument error: ', error);
			return Promise.reject(error);
		}

		const fileExtension = `.${files[0].name.split('.').pop()}`;

		if (!acceptedFormats.includes(fileExtension)) {
			const error = new Error(`Unsupported file format: ${fileExtension}.`);
			console.error('Documents - uploadAccountDocument error: ', error);
			return Promise.reject(error);
		}

		setRepertoireFile(files[0]);
		return Promise.resolve();
	};

	const handleFileRemove = () => {
		setRepertoireFile(null);
	};

	const files = lang === 'ru' ? filesRu : filesEn;
	const repFile = repertoireFile
		? {
				client_name: repertoireFile.name,
				size: repertoireFile.size,
		  }
		: null;

	const loadMore = () => {
		setUploaded(false);
	};

	return (
		<Row nogutter nowrap className={styles.Row}>
			{isBulletShow && (
				<div className={styles.triangle}>
					{typeOfBullet === 'down' ? (
						<TriangleDown className={styles.svg_branding} />
					) : (
						<TriangleUp className={styles.svg_branding} />
					)}
				</div>
			)}
			<Col sm={6} offset={{ sm: 1 }} className={styles.Left}>
				<div className={styles.Form} ref={formElement}>
					{uploaded && (
						<div className={styles.Header}>
							<Label
								text={
									<FormattedMessage
										id={
											uploaded
												? 'rod.repertoire.uploaded.title'
												: 'rod.repertoire.welcome.title'
										}
									/>
								}
								font="--gilroy-Medium-40"
								black
							/>
						</div>
					)}
					<div className={styles.FormLine}>
						<div className={styles.Subheader}>
							<Label
								text={
									<FormattedHTMLMessage
										id={
											uploaded
												? 'rod.repertoire.uploaded.content'
												: process.env.REACT_APP_CLIENT === 'dgtal'
												? 'rod.repertoire.welcome.content.part.dgtal'
												: 'rod.repertoire.welcome.content.part'
										}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>
						</div>
						{!uploaded && (
							<DropZone
								onUpload={handleFileUpload}
								onRemove={handleFileRemove}
								value={repFile}
								accept=".zip, .xlsx, .xls, .xlsb, .csv, .v21, .v22"
								dropzoneId={'rod.field.upload.zip'}
								kb
							/>
						)}
					</div>
					{uploaded && (
						<Button
							text={<FormattedMessage id={'rod.action.upload_more'} />}
							variant={'primary'}
							onClick={loadMore}
						/>
					)}
					{!uploaded && (
						<div className={styles.Controls}>
							<Button
								disabled={!repertoireFile || isUploading}
								text={<FormattedMessage id={'rod.action.upload'} />}
								variant={'primary'}
								onClick={upload}
							/>
						</div>
					)}
				</div>
			</Col>
			<Col sm={5} className={styles.Right}>
				<div>
					<Label
						text={<FormattedMessage id={'rod.repertoire.welcome.download'} />}
						font="--gilroy-Medium-32"
						black
						className={styles.Header}
					/>
					<div className={styles.Example}>
						{files.map((file) => (
							<div key={file.title} className={styles.File}>
								<a
									href={`/examples/${file.filename}`}
									target="_blank"
									rel="noreferrer"
								>
									<img src={file.icon} alt="" /> <span>{file.title}</span>
								</a>
							</div>
						))}
					</div>
				</div>
			</Col>
		</Row>
	);
};

Dashboard.propTypes = {};

export default Dashboard;
