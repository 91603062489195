// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import classNames from 'classnames';
import { compose } from 'recompose';

// UI
import { FormInput } from 'components/Form';
import RadioButton from 'components/Radio/RadioButton/RadioButton';

// Styles
import styles from './RadioGroup.module.css';

class RadioGroup extends React.Component {
	static propTypes = {};

	static defaultProps = {
		name: 'radio_test',
	};

	static displayName = 'RadioGroup';

	state = {
		selected: '',
		errors: {},
	};

	componentDidUpdate() {
		if (this.props.isDateEmpty) {
			this.handleOnBlurDateInput('required');
		}
	}

	getChecked = (item) => {
		if (typeof this.props.onChange === 'function') {
			return this.props.value === item.value;
		} else {
			return this.state.selected === item.value;
		}
	};

	handleChange = (item) => (e) => {
		if (this.props.errorsFromInput) {
			this.setState({ errors: {} }, () => {
				this.props.errorsFromInput(this.state.errors);
			});
		}
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(e);
		} else {
			this.setState({ selected: item.value });
		}
	};

	changeField = (field) => (e) => {
		switch (field) {
			case 'sale_start_date':
				if (this.props.inputDate) {
					if (this.props.transferRelease) {
						this.props.personal[field] = e;
						this.props.personal.type = 'transfer';
					}

					this.props.personal[field] = e;
				} else if (this.props.inputTransferDate) {
					if (this.props.errorsFromInput) {
						this.setState({ errors: {} }, () => {
							this.props.errorsFromInput(this.state.errors);
						});
					}
					this.props.personal[field] = e;
					const date = Date.parse(e);

					if (date < 0) {
						return this.setState({
							errors: { sale_start_date: [{ rule: 'time_below-1970' }] },
						});
					}
				} else {
					const date = new Date(e);
					const desiredFormat = 'yyyy-MM-dd HH:mm:ss';
					const formattedDate = format(date, desiredFormat);
					this.props.personal[field] = formattedDate;
					this.props.personal.type = 'transfer';
				}
				break;
			default:
				this.props.personal[field] = e.target.value;
				break;
		}

		this.props.inputChangeField(this.props.personal);
	};

	handleOnBlurDateInput = (err, value) => {
		if (this.props.errorsFromInput) {
			this.setState({ errors: {} }, () => {
				this.props.errorsFromInput(this.state.errors);
			});
		}

		const date = Date.parse(value);

		if (this.props.inputTransferDate && date < 0) {
			return this.setState({
				errors: { sale_start_date: [{ rule: 'time_below-1970' }] },
			});
		}

		if (err === 'required') {
			this.setState(
				{
					errors: { sale_start_date: [{ rule: 'required' }] },
				},
				() => {
					this.props.clearIsDateEmpty();
				}
			);
		}

		if (err && this.props.inputDate) {
			err === 'invalidDate'
				? this.setState(
						{ errors: { sale_start_date: [{ rule: 'wrong_format' }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  )
				: this.setState(
						{ errors: { sale_start_date: [{ rule: err }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  );
		} else if (err && this.props.inputTransferDate) {
			err === 'minDate' || err === 'invalidDate'
				? this.setState(
						{ errors: { sale_start_date: [{ rule: 'wrong_format' }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  )
				: this.setState(
						{ errors: { sale_start_date: [{ rule: err }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  );
		}
	};

	render() {
		const {
			className,
			disabled,
			horizontal,
			items,
			value,
			withFocusRef,
			children,
			transferRelease,
			...props
		} = this.props;
		const classes = classNames(
			className,
			styles['RadioGroup'],
			disabled && 'disabled',
			horizontal && 'horizontal'
		);

		return (
			<div className={classes}>
				{!transferRelease &&
					items.map((item) => (
						<div
							className={
								item.value === '7+' || item.value === '1-'
									? styles.Input__date
									: styles.radioItem
							}
						>
							<RadioButton
								{...props}
								key={item.id || item.value}
								text={item.text}
								value={item.value}
								disabled={
									item.hasOwnProperty('disabled') ? item.disabled : false
								}
								onChange={this.handleChange(item)}
								checked={this.getChecked(item)}
								className={styles.radioBtnItem}
								itemChildren={item.children}
							/>

							{item.value === '7+' && (
								<>
									{this.props.inputDate && (
										<div className={styles.input}>
											<FormInput
												className={styles.date}
												type={'muiInputDate_v2'}
												minDate={true}
												maxDate={false}
												defaultValue={true}
												name={'sale_start_date'}
												handleOnBlurDateInput={this.handleOnBlurDateInput}
												onChange={this.changeField}
												errors={this.state.errors}
												data={props.personal}
												label={
													<FormattedMessage
														id={
															'rod.release.create.step.distribution.input_date'
														}
													/>
												}
											/>
											{Object.keys(this.state.errors).includes(
												'sale_start_date'
											) && (
												<span className={styles.Helper}>
													{
														<FormattedMessage
															id={`rod.error.${this.state.errors.sale_start_date[0].rule}`}
														/>
													}
												</span>
											)}
										</div>
									)}
								</>
							)}
							{item.value === '1-' && (
								<>
									{this.props.inputTransferDate && (
										<div>
											<div className={styles.input}>
												<FormInput
													className={styles.date}
													type={'muiInputDate_v2'}
													maxDate={true}
													defaultValue={false}
													name={'sale_start_date'}
													handleOnBlurDateInput={this.handleOnBlurDateInput}
													onChange={this.changeField}
													errors={this.state.errors}
													data={props.personal}
													label={
														<FormattedMessage
															id={
																'rod.release.create.step.distribution.input_date'
															}
														/>
													}
												/>
												{Object.keys(this.state.errors).includes(
													'sale_start_date'
												) && (
													<span className={styles.Helper}>
														{
															<FormattedMessage
																id={`rod.error.${this.state.errors.sale_start_date[0].rule}`}
															/>
														}
													</span>
												)}
											</div>
										</div>
									)}
								</>
							)}
						</div>
					))}

				{transferRelease && (
					<div className={styles.input} style={{ marginTop: '-20px' }}>
						<FormInput
							className={styles.date}
							type={'muiInputDate_v2-2'}
							maxDate={true}
							defaultValue={true}
							name={'sale_start_date'}
							handleOnBlurDateInput={this.handleOnBlurDateInput}
							onChange={this.changeField}
							data={props.personal}
							label={
								<FormattedMessage
									id={'rod.release.transfer.step.distribution.input_date'}
								/>
							}
						/>
						{Object.keys(this.state.errors).includes('sale_start_date') && (
							<span className={styles.Helper}>
								{
									<FormattedMessage
										id={`rod.error.${this.state.errors.sale_start_date[0].rule}`}
									/>
								}
							</span>
						)}
					</div>
				)}
				{children}
			</div>
		);
	}
}

export default compose()(RadioGroup);
