// Core
import TextareaAutosize from '@mui/base/TextareaAutosize';

// Styles
import s from './MuiTextareaAutosize.module.css';

const Textarea = ({
	placeholder,
	value,
	onChange,
	errors,
	isTextAreaEnable,
}) => {
	return (
		<TextareaAutosize
			aria-label="lyrics"
			placeholder={placeholder}
			className={errors ? s.TextareaWithError : s.Textarea}
			onChange={onChange}
			value={value}
			errors={errors}
			disabled={!isTextAreaEnable}
		/>
	);
};

export default Textarea;
