// Core
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { HelpButton } from 'components/Buttons';

// Styles
import styles from './InfoTitle.module.css';

const InfoTitle = ({ title, helpTitle }) => {
	const { showModal } = useContext(UIContext);

	return (
		<>
			<div className={styles.info__title}>
				<FormattedMessage id={title} />
				{helpTitle && (
					<HelpButton showModal={showModal} helpTitle={helpTitle} />
				)}
			</div>
		</>
	);
};

export default InfoTitle;
