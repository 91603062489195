/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import { FormInput } from 'components/Form/FormInput';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';
// import { Lang } from 'components';
// import { dateFormat } from 'logic';
// import Admin from 'services/api/admin';
// import union from 'lodash/union';
// import { DropZone } from 'components/Upload/DropZone';
// import { FaqAdminLang } from 'material-design/components/FaqAdminLang';
// import { useHistory } from 'react-router-dom';
// import { showError } from 'validators/showError';

import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import BaseModal from './BaseModal';
import styles from './Modal.module.css';
import debounce from 'lodash.debounce';
import TimePicker from 'components/Inputs/TimePicker/TimePicker';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		// delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

const NextReportDateModal = (props) => {
	// const [pageLoading, setPageLoading] = useState(true);
	const [isVisible, setIsVisible] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState({});

	const [data, setData] = useState({ report_date: null });
	const [isConfirmedBtnActive, setIsConfirmedBtnActive] = useState(true);
	const [isShowConfirmQuestion, setIsShowConfirmQuestion] = useState(false);
	const [timeInHHMM, setTimeInHHMM] = useState('');
	const [isSelectedTimeValid, setIsSelectedTimeValid] = useState(true);
	const [initialTimeInHHMM, setInitialTimeInHHMM] = useState('');

	const { reportId, onClose, handleNextReportDate, date } = props;

	const isEdit = reportId ? true : false;

	useEffect(() => {
		if (date) {
			let dateInfo = '',
				timeInfo = '';

			if (date.indexOf(' ')) {
				const dateArr = date.split(' ');
				dateInfo = dateArr[0];
				timeInfo = dateArr[1];
			}
			setData({ report_date: dateInfo });
			setInitialTimeInHHMM(timeInfo);
		}
		// return function cleanup() {
		// 	setIsVisible(false);
		// 	setTimeout(() => {
		// 		onClose();
		// 	}, 300);
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const sendData = (e) => {
		handleNextReportDate(data.report_date + ' ' + timeInHHMM + ':00');
		willUnmount();
	};

	const debouncedSendData = debounce(() => sendData(), 500);

	const changeField = (field) => (e) => {
		switch (field) {
			case 'report_date':
				setData({ [field]: e });
				break;
			default:
				break;
		}
	};

	const preConfirmData = () => {
		setIsShowConfirmQuestion(true);
	};

	const handleIsSelectedTimeValid = (data) => {
		setIsSelectedTimeValid(data);
	};

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,

					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col
									/* width={'416'} */ /* md={12 - 4} */ onClick={willUnmount}
								/>
								<Col width={'1'} /* md={4} */ className={styles.notify}>
									<div
										className={styles.notify__wrapper}
										style={{ width: '400px' }}
									>
										<div className={styles.nextReportsDateTitle}>
											<div className={styles.notify__title}>
												<FormattedHTMLMessage
													id={'rod.field.admin.reports.next_report_date'}
												/>
											</div>
											<CloseButton
												black
												small
												className={styles.CloseButtonNotify}
												onClick={willUnmount}
											/>
										</div>
										<div className={styles.nextReportsDateTimeWrapper}>
											<div className={styles.nextReportsDateWrapper}>
												<FormattedMessage
													id={'rod.field.admin.reports.next_report_date'}
												>
													{(placeholder) => (
														<FormInput
															type={'datePicker'}
															placeholder={placeholder}
															name={'report_date'}
															onChange={changeField}
															errors={errors}
															data={data}
															minDate={new Date()}
														/>
													)}
												</FormattedMessage>

												{Object.keys(errors).includes('report_date') && (
													<div className={styles.error}>
														<span className={styles.errorText}>
															{errors['report_date'] === 'invalid date' ? (
																<FormattedMessage
																	id={'rod.admin.invalid-date'}
																/>
															) : (
																''
															)}
														</span>
													</div>
												)}
											</div>
											<div
												className={styles.nextReportsDateWrapper}
												style={{
													marginTop: '0',
													// pointerEvents: publishingDate['publishing_date']
													// 	? 'all'
													// 	: 'none',
												}}
											>
												<TimePicker
													setTimeInHHMM={setTimeInHHMM}
													initialTimeInHHMM={initialTimeInHHMM}
													handleIsSelectedTimeValid={handleIsSelectedTimeValid}
												/>

												{/* {Object.keys(errors).includes('publishing_date') && (
												<div className={styles.error}>
													<span className={styles.errorText}>
														{errors['publishing_date'][0].rule ===
															'date_past' && (
															<FormattedMessage id={'rod.error.date_past'} />
														)}
													</span>
												</div>
											)} */}
											</div>
										</div>
										{!isShowConfirmQuestion ? (
											<>
												<div className={styles.reports__confirm}>
													<Button
														text={
															<FormattedHTMLMessage
																id={
																	isEdit
																		? 'rod.performance_work.save'
																		: 'rod.action.save'
																}
															/>
														}
														className={
															data.report_date && isSelectedTimeValid
																? styles.activeReportsBtn
																: styles.inactiveReportsBtn
														}
														variant={'primary'}
														// disabled={!confirmed}
														disabled={!data.report_date || !isSelectedTimeValid}
														onClick={preConfirmData}
													/>
												</div>
											</>
										) : (
											<div className={styles.notify__confirm_question_wrapper}>
												<div className={styles.notify__confirm_question}>
													<FormattedHTMLMessage
														id={'rod.modal.notify.admin.confirm-question'}
													/>
												</div>
												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-yes'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsConfirmedBtnActive(false);
																debouncedSendData();
															}
														}}
													/>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-no'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsShowConfirmQuestion(false);
															}
														}}
													/>
												</div>
												{/* 	)} */}
											</div>
										)}
									</div>
								</Col>
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default compose(withLang, withUI, withRoot)(NextReportDateModal);
