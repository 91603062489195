// Core
import { FormattedMessage } from 'react-intl';

// UI
import { Label } from 'components/Labels/Label';
import { FormInput } from 'components/Form/FormInput';

// Styles
import styles from '../LyricsForm.module.css';

const LyricWrapper = ({ changeField, errors, selectPerson, isTextAreaOn }) => {
	const lang = localStorage.getItem('lang');

	return (
		<div className={styles.lyricWrapper}>
			<Label
				text={
					<FormattedMessage
						id={'rod.release.create.step.lyrics.add_lyrics.title'}
					/>
				}
				font="--gilroy-Medium-24-28"
				black
			/>
			<FormInput
				type={'muiTextarea'}
				name={'lyrics'}
				placeholder={
					lang === 'ru'
						? 'введите текст песни (максимум 10000 символов)'
						: 'enter the lyrics of the song (maximum 10000 characters)'
				}
				onChange={changeField}
				errors={errors}
				data={selectPerson}
				isTextAreaEnable={isTextAreaOn}
			/>
			{errors && Object.keys(errors).includes('lyrics') && (
				<span className={styles.Helper}>
					{
						<FormattedMessage
							id={`rod.release_create.error.lyrics.${errors.lyrics}`}
						/>
					}
				</span>
			)}
		</div>
	);
};

export default LyricWrapper;
