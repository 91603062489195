/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/03/2021, 19:16
 */

import { Input } from 'components/Inputs/Input';
import { withRoot } from 'hocs';
import clearImg from 'images/close_button.svg';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { showError } from 'validators/showError';
// import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import Skeleton from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedAnimation';
import styles from './AutosuggestInput.module.css';

class AutosuggestInput extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
			items: [],
			text: props.value.title,
			isLoading: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
				text: this.props.value.title,
			});
		}
	}

	sendRequest = (value) => {
		if (value.length < 3) {
			this.setState({ items: [] });

			return;
		}
		// this.setState({ isLoading: true });
		const {
			rootContext: { suggestSociety },
		} = this.props;
		suggestSociety(value)
			.then((res) => {
				this.setState({ items: res });
			})
			.catch((err) => {
				console.info('Error:', err);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	clear = () => {
		this.setState({
			value: null,
			text: '',
		});
		this.props.onChange({});
	};

	change = (item) => () => {
		this.setState({
			items: [],
			value: item,
			text: item.title_ru, //todo
		});
		this.props.onChange(item);
	};

	debouncedChangeHandler = debounce((value) => this.sendRequest(value), 500);

	changeValue = (value) => {
		if (value.length > 3) {
			this.setState({ isLoading: true });
		} else if (value.length < 3 && this.state.isLoading) {
			this.setState({ isLoading: false });
		}

		this.setState({
			text: value,
			value: null,
		});

		this.debouncedChangeHandler(value);
	};

	render() {
		const { onChange, ...props } = this.props;
		const { items, value, text } = this.state;
		return (
			<div className={styles.Component}>
				<Input
					{...props}
					showError={showError('society')}
					required
					value={text}
					// onChange={this.changeValue}
					onChange={this.changeValue}
				/>
				{text && (
					<button className={styles.Icon} onClick={this.clear}>
						<img src={clearImg} alt="clear" />
					</button>
				)}
				{this.state.isLoading && (
					<div className={styles.Skeleton}>
						<Skeleton />
					</div>
				)}
				{!this.state.isLoading &&
					text &&
					!value &&
					text.length > 3 &&
					items.length === 0 && (
						<div className={styles.Dropdown}>
							<ul className={styles.List}>
								<li>
									<FormattedMessage id={'rod.autosuggest.nothing_to_show'} />
								</li>
							</ul>
						</div>
					)}
				{items.length > 0 && (
					<div className={styles.Dropdown}>
						<ul className={styles.List}>
							{items.map((item) => (
								<li key={item.title_ru} onClick={this.change(item)}>
									{item.title_ru}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

AutosuggestInput.propTypes = {};

AutosuggestInput.defaultProps = {};

export default compose(withRoot)(AutosuggestInput);
