// Core
import React, {
	useContext,
	useEffect,
	useState,
	useRef,
	useCallback,
} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import lscache from 'lscache';
import debounce from 'lodash.debounce';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { Col, Row, Container } from 'react-grid-system';
import { RepertoireFilterModal } from 'material-design/modals/RepertoireFilterModal';
import { Pagination, Input } from 'material-design/components';
import { MainStatsReleases } from 'material-design/components/MainStats/MainStatsReleases';
import Banner from './Banner/Banner';
import ContentEmpty from './ContentEmpty/ContentEmpty';

// Icons
import { Cd } from 'material-design/svg';
import edit from 'images/edit.svg';
import close from 'images/close_button.svg';

// Helpers
import {
	convertDataSale,
	getPerformers,
	getTypeTitle,
	getItemStatusNew,
	checkStatus,
	getItemStatus,
} from 'helpers';
import { useOutsideClick } from 'material-design/hooks';
import { setCache } from 'services/cacheHelper/setCache';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { accounts } from 'services';
import { Loading } from 'components';
import { onEditReleaseHandler } from './helpers/onEditReleaseHandler';

// Data
import { filterReleaseSettingsData } from './data/filterSettingsData';

// Styles
import s from './RepertoireReleaseSection.module.css';

function RepertoireReleaseSection(props) {
	const { statistics } = props;
	const {
		authContext: { accountId },
		UIContext: { prevRepertoireParams, setPrevRepertoireParams },
	} = props;
	const history = useHistory();
	const lang = localStorage.getItem('lang');

	const { materialsAvailableEditing } = useContext(UIContext);

	const { pathname } = useLocation();
	const resultRef = useRef(null);
	const helpRef = useRef(null);

	const [banner, setBanner] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [page, setPage] = useState(prevRepertoireParams.page);
	const [value, setValue] = useState('');
	const [total, setTotal] = useState(0);
	const [isFocus, setIsFocus] = useState(false);
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isReqLive] = useState(false);
	const [filterSettings, setFilterSettings] = useState(
		filterReleaseSettingsData
	);
	const [currentFilterSettings, setCurrentFilterSettings] = useState({});
	const [selectFilters, setSelectFilters] = useState({});
	const [releaseStatus, setReleaseStatus] = useState('');
	const [releasesIDs, setReleasesIDs] = useState([]);
	const [availableEditing, setAvailableEditing] = useState(
		materialsAvailableEditing
	);

	const handleReceivedData = (res) => {
		res.data.forEach((item) => {
			item.status_item = getItemStatus(item.statuses);
			item.status_title = checkStatus(item.statuses);
			if (Object.keys(currentFilterSettings).length) {
				item.priority_status = Object.values(currentFilterSettings)[0].code;
			} else {
				item.priority_status = getItemStatusNew(item.statuses);
			}

			if (item.sale_start_dates.length) {
				item.sale_start_dates.sort(
					(a, b) => convertDataSale(a.date) - convertDataSale(b.date)
				);
				item.sale_start_dates.forEach((itemDate) => {
					itemDate.date = itemDate.date.replace(
						/(\d{4})-(\d{2})-(\d{2})/,
						'$3.$2.$1'
					);
				});
			}
		});
		setTotal(res.total);
		setFilteredData(res.data);
	};

	const handleReceivedDraftFilterData = (res, status) => {
		if (res && Array.isArray(res.data) && res.data.length > 0) {
			res.data.forEach((item) => {
				item.priority_status = status;
			});
			setTotal(res.total);
			setFilteredData(res.data);
		} else {
			setTotal(0);
			setFilteredData([]);
		}
	};

	const onChange = (filter) => {
		setLoading(true);
		setValue(filter);
		getReleasesData(filter);
		setPage(1);
	};

	useEffect(() => {
		accounts.getFeatures().then((res) => {
			res = res.data.data;
			setAvailableEditing(res['feature.materials_available_editing']);
		});
	}, []);

	useEffect(() => {
		const releaseTypesAllCache = lscache.get('releaseTypesAllCache');
		if (
			releaseTypesAllCache &&
			!isReqLive &&
			releaseTypesAllCache.lang === lang
		) {
			setReleasesIDs(releaseTypesAllCache.data);
		} else {
			accounts
				.getReleaseTypesAll(lang)
				.then((res) => {
					res = res.data.data;
					setCache('releaseTypesAll', 'releaseTypesAllCache', res);
					setReleasesIDs(res);
				})
				.catch((err) => {
					console.error('Error:', err);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		const firstFilter = currentFilterSettings
			? Object.values(currentFilterSettings)[0]?.show
				? Object.values(currentFilterSettings)[0]
				: ''
			: '';

		if (firstFilter) {
			// setRepertoireStatus(firstFilter['name_en']);
			setReleaseStatus(firstFilter.code);
		} else {
			// setRepertoireStatus('');
			setReleaseStatus('');
		}
	}, [currentFilterSettings]);

	useEffect(() => {
		setLoading(true);
		setPage(1);
		getReleasesData(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseStatus]);

	useEffect(() => {
		setLoading(true);
		getReleasesData(value, page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const getReleasesData = (filter, page = 1) => {
		const searchByStatus = releaseStatus ? true : false;
		switch (filter.length < 3) {
			case true:
				switch (searchByStatus) {
					case true:
						if (
							['draft_processing', 'draft_verify', 'verify'].includes(
								releaseStatus
							)
						) {
							accounts
								.getAssetsPageByStatus(
									accountId,
									'releases',
									page,
									releaseStatus
								)
								.then((res) => {
									res = res.data;
									handleReceivedDraftFilterData(res, releaseStatus);
								})
								.catch((error) => {
									console.error('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getReleasesPageByStatus(accountId, releaseStatus, page)
								.then((res) => {
									res = res.data;
									handleReceivedData(res);
								})
								.catch((error) => {
									console.error('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPage(accountId, 'releases', page)
							.then((res) => {
								res = res.data.data;
								handleReceivedData(res);
							})
							.catch((error) => {
								console.error('Error', error);
							})
							.finally(() => {
								setLoading(false);
							});
						break;
					default:
						break;
				}

				break;
			case false:
				switch (searchByStatus) {
					case true:
						if (
							['draft_processing', 'draft_verify', 'verify'].includes(
								releaseStatus
							)
						) {
							accounts
								.getAssetsPageTextByStatus(
									accountId,
									filter,
									page,
									'releases',
									releaseStatus
								)
								.then((res) => {
									res = res.data;
									handleReceivedDraftFilterData(res, releaseStatus);
								})
								.catch((error) => {
									console.error('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getReleasesPageTextByStatus(
									accountId,
									filter,
									releaseStatus,
									page
								)
								.then((res) => {
									res = res.data;
									handleReceivedData(res);
								})
								.catch((error) => {
									console.error('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPageText(accountId, filter, page)
							.then((res) => {
								res = res.data.data;
								handleReceivedData(res);
							})
							.catch((error) => {
								console.error('Error', error);
							})
							.finally(() => {
								setLoading(false);
							});
						break;
					default:
						break;
				}

				break;

			default:
				break;
		}
	};

	const handleFilter = useCallback(debounce(onChange, 500), [releaseStatus]);

	const modalOpen = (active) => setModal(active);
	const handleChangeFilter = (data) => setFilterSettings(data);
	const handleCurrentFilter = (data) => setCurrentFilterSettings(data);

	const handleClear = () => {
		const newFilterSettings = [...filterSettings];
		newFilterSettings.map((item) =>
			item.list.map((list_item) => (list_item.checked = false))
		);
		setCurrentFilterSettings({});
		setFilterSettings(newFilterSettings);
		setSelectFilters({});
	};

	const handleFilterClear = (item) => {
		const newFilterSettings = [...filterSettings];
		newFilterSettings[item.parentId - 1].list[
			item.currentId - 1
		].checked = false;
		item.show = false;
		setCurrentFilterSettings({});
		setFilterSettings(newFilterSettings);
		setSelectFilters({});
	};

	const onClose = () => {
		setIsFocus(false);
	};

	useOutsideClick(resultRef, onClose, isFocus, helpRef);

	const changeStatusFromStatsBlock = (selectedStatus) => {
		if (selectedStatus === '') {
			handleClear();
			return;
		}

		const item = filterSettings[0];
		const setting = item.list.find((obj) => obj.code === selectedStatus);

		item.list.map((obj) =>
			obj.id === setting.id ? (obj.checked = true) : (obj.checked = false)
		);

		const newCurrentFilter = {};
		newCurrentFilter['' + item.id + setting.id] = {
			parentId: item.id,
			currentId: setting.id,
			id: '' + item.id + setting.id,
			title_en: item.title_en,
			title_ru: item.title_ru,
			name_en: setting.name_en,
			name_ru: setting.name_ru,
			code: setting.code,
			show: true,
		};

		setCurrentFilterSettings(newCurrentFilter);
		setSelectFilters(newCurrentFilter);
		setFilterSettings([item]);
	};

	const {
		UIContext: { upload },
	} = props;

	const clickToCreateRelease = () => {
		localStorage.removeItem('releaseId');
	};

	return (
		<>
			{banner && <Banner setBanner={setBanner} title="rod.releases.title" />}
			<MainStatsReleases
				data={statistics}
				changeStatus={changeStatusFromStatsBlock}
			/>

			<div className={s.inputWrapper}>
				<Input
					curRef={helpRef}
					handleOnChange={handleFilter}
					setIsFocus={setIsFocus}
					setActive={modalOpen}
					filter={currentFilterSettings}
					parent={'releaseSection'}
				/>

				{Object.values(currentFilterSettings).some((i) => i.show === true) && (
					<>
						<ul className={s.filter}>
							{Object.keys(currentFilterSettings).map(
								(item) =>
									currentFilterSettings[item].show &&
									currentFilterSettings[item].code !== 'verify' && (
										<li
											className={s.filter__item}
											key={currentFilterSettings[item].id}
											onClick={() =>
												handleFilterClear(currentFilterSettings[item])
											}
										>
											<span>
												{lang === 'en'
													? `${currentFilterSettings[item].title_en} :
													${currentFilterSettings[item].name_en}`
													: `${currentFilterSettings[item].title_ru} :
													${currentFilterSettings[item].name_ru}`}
											</span>
											<img
												src={close}
												width={16}
												onClick={() =>
													handleFilterClear(currentFilterSettings[item])
												}
												alt=""
											/>
										</li>
									)
							)}

							<li className={s.filter__itemButton}>
								<button
									className={s.btnAsLink}
									type="button"
									onClick={() => handleClear()}
								>
									<FormattedMessage id={'rod.repertoire.clear_all'} />
								</button>
							</li>
						</ul>
					</>
				)}
			</div>
			{loading ? (
				<Loading className={s.loader} />
			) : (
				<>
					{filteredData.length ? (
						<>
							<div className={s.repertoire__mainContent}>
								<div>
									<div
										className={`${s.repertoire__phonogramTitle} ${s.table} ${s.adaptive__songTitle}`}
									>
										<FormattedHTMLMessage id={'rod.for_all.title'} />
										<FormattedHTMLMessage id={'rod.for_all.performer'} />
										<FormattedHTMLMessage id={'rod.for_all.subtitle'} />
										<FormattedHTMLMessage id={'rod.for_all.type'} />
										<FormattedHTMLMessage id={'rod.for_all.date'} />
										<FormattedHTMLMessage id={'rod.for_all.status'} />
									</div>
									<ul className={s.repertoire__phonogramItemsWrapper}>
										{filteredData.map((item) => (
											<li className={s.itemWrapper}>
												<Link
													key={item.id}
													to={`/repertoire/releases/${item.id}/info`}
												>
													<div
														className={
															item.priority_status === 'disputed' ||
															item.priority_status === 'not_ready' ||
															item.priority_status === 'rejected'
																? `${s.repertoire__phonogramItemError} ${s.adaptive__item}`
																: `${s.adaptive__item}`
														}
													>
														<Container fluid>
															<Row>
																<Col xs={4}>
																	<div>
																		<Cd className={s.adaptive__songItemLogo} />
																	</div>
																</Col>
																<Col xs={6}>
																	<div className={s.adaptive__itemText}>
																		<span>{item.title}</span>
																		{item.title && <span>{item.title}</span>}
																	</div>
																</Col>
																{item.moderation_status === 'moderation' &&
																	availableEditing && (
																		<Col xs={2}>
																			<button
																				className={s.editIcon}
																				onClick={(e) =>
																					onEditReleaseHandler(
																						e,
																						item.id,
																						accounts,
																						history
																					)
																				}
																			>
																				<img src={edit} alt="" />
																			</button>
																		</Col>
																	)}
															</Row>

															<Row>
																<Col xs={4}>
																	<div className={s.adaptive__itemTitle}>
																		<FormattedHTMLMessage
																			id={'rod.for_all.performer'}
																		/>
																	</div>
																	<div className={s.adaptive__itemTitle}>
																		<FormattedHTMLMessage
																			id={'rod.for_all.subtitle'}
																		/>
																	</div>
																	<div className={s.adaptive__itemTitle}>
																		<FormattedHTMLMessage
																			id={'rod.for_all.type'}
																		/>
																	</div>
																	<div className={s.adaptive__itemTitle}>
																		<FormattedHTMLMessage
																			id={'rod.for_all.date'}
																		/>
																	</div>
																	<div className={s.adaptive__itemTitle}>
																		<FormattedHTMLMessage
																			id={'rod.for_all.status'}
																		/>
																	</div>
																</Col>
																<Col xs={8}>
																	<div className={s.adaptive__itemText}>
																		{getPerformers(item)}
																	</div>
																	<div className={s.adaptive__itemText}>
																		{item.subtitle ? (
																			<>
																				<span>{item.subtitle}</span>
																				{item.subtitle && (
																					<span>{item.subtitle}</span>
																				)}
																			</>
																		) : (
																			<span>-</span>
																		)}
																	</div>
																	<div className={s.adaptive__itemText}>
																		<span>
																			{getTypeTitle(
																				item.release_type_id,
																				releasesIDs
																			)}
																		</span>
																		<span>
																			{getTypeTitle(
																				item.release_type_id,
																				releasesIDs
																			)}
																		</span>
																	</div>
																	<div className={s.adaptive__itemText}>
																		{item.sale_start_dates &&
																		item.sale_start_dates.length ? (
																			<>
																				<span>
																					{item.sale_start_dates[0].date}
																				</span>
																				<span>
																					{item.sale_start_dates[0].date}
																				</span>
																			</>
																		) : (
																			<span>-</span>
																		)}
																	</div>
																	<div className={s.adaptive__itemText}>
																		<span>
																			<FormattedMessage
																				id={`rod.repertoire.${item.priority_status}`}
																			/>
																		</span>
																		<span>
																			<FormattedMessage
																				id={`rod.repertoire.${item.priority_status}`}
																			/>
																		</span>
																	</div>
																</Col>
															</Row>
														</Container>
													</div>

													<div
														className={
															item.priority_status === 'disputed' ||
															item.priority_status === 'not_ready' ||
															item.priority_status === 'rejected'
																? `${s.repertoire__phonogramItemError} ${s.table} ${s.adaptive__songTable}`
																: `${s.table} ${s.adaptive__songTable}`
														}
													>
														<div>
															<span>{item.title}</span>
															<span>{item.title}</span>
														</div>
														<div>{getPerformers(item)}</div>
														<div>
															{item.subtitle ? (
																<>
																	<span>{item.subtitle}</span>
																	<span>{item.subtitle}</span>
																</>
															) : (
																<span>-</span>
															)}
														</div>
														<div>
															<span>
																{getTypeTitle(
																	item.release_type_id,
																	releasesIDs
																)}
															</span>
															<span>
																{getTypeTitle(
																	item.release_type_id,
																	releasesIDs
																)}
															</span>
														</div>
														<div>
															{item.sale_start_dates &&
															item.sale_start_dates.length ? (
																<>
																	<span>{item.sale_start_dates[0].date}</span>
																	<span>{item.sale_start_dates[0].date}</span>
																</>
															) : (
																<span>-</span>
															)}
														</div>
														<div>
															<span>
																{item.moderation_status === 'moderation' &&
																availableEditing ? (
																	<FormattedMessage id="rod.repertoire.draft_processing" />
																) : (
																	<FormattedMessage
																		id={`rod.repertoire.${item.priority_status}`}
																	/>
																)}
															</span>
														</div>
														{item.moderation_status === 'moderation' &&
															availableEditing && (
																<button
																	className={s.editIcon}
																	onClick={(e) =>
																		onEditReleaseHandler(
																			e,
																			item.id,
																			accounts,
																			history
																		)
																	}
																>
																	<img src={edit} alt="" />
																</button>
															)}
														<Cd className={s.repertoire__phonogramItemLogo} />
													</div>
												</Link>
											</li>
										))}
									</ul>
								</div>

								{total > 10 && (
									<Pagination
										page={page}
										total={total}
										paginate={(pageNumber) => {
											setPage(pageNumber);
											setPrevRepertoireParams({ page: pageNumber });
										}}
									/>
								)}
							</div>
						</>
					) : (
						<div className={s.repertoire__mainContentEmpty}>
							<Cd width={64} />
							{upload.releases && (
								<ContentEmpty
									pathname={pathname}
									clickToCreateRelease={clickToCreateRelease}
								/>
							)}
						</div>
					)}

					<RepertoireFilterModal
						active={modal}
						setActive={modalOpen}
						filterData={filterSettings}
						setFilterData={handleChangeFilter}
						currentFilterSettings={currentFilterSettings}
						setCurrentFilter={handleCurrentFilter}
						selectFilters={selectFilters}
						setSelectFilters={setSelectFilters}
					/>
				</>
			)}
		</>
	);
}

export default compose(withUI, withAuth, withRoot)(RepertoireReleaseSection);
