import React, { useEffect, useState } from 'react';
import {
	TextFieldInput,
	AutocompleteInputGenres,
	CheckboxBlack,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import styles from './MuiAutocompleteMultiple.module.css';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				background: '#f0f0f0',
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 16px',
					'&:hover': {
						backgroundColor: '#F0F0F0;',
					},
				},
			},
		},
	})
);
const tracks = [
	{ id: 1, title: 'track1' },
	{ id: 2, title: 'track2' },
	{ id: 3, title: 'track3' },
];

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiAutocomplete = ({ onChange, label, value, required }) => {
	const [tracksArr, setTracksArr] = useState([]);
	// const lang = localStorage.getItem('lang');
	useEffect(() => {
		setTracksArr(tracks);
		// //---------get neccesary tracks from back:-----------
		// accounts
		// 	.getTracksByAccId(accId) //examples
		// 	.getTracksByOutlet(outletId) //examples
		// 	.getTracksByOutlets(outlets) //examples
		// 	.then((res) => {
		// 		res = res.data.data;
		// 		console.log(res);
		// 		res = res.sort((a, b) => (a.title > b.title ? 1 : -1));
		// 		setTracksArr(res);
		// 	})
		// 	.catch((error) => {
		// 		console.log('Outlets loading error', error);
		// 	});
	}, []);

	if (tracksArr.length) {
		for (let i = 0; i < value.length; i++) {
			for (let j = 0; j < tracksArr.length; j++) {
				if (value[i] === tracksArr[j].title) {
					value[i] = tracksArr[j];
					// value[i] = {
					// 	id: outletsArr[j].id,
					// 	title: outletsArr[j].title,
					// };
				}
			}
		}
	}

	const filterOptions = (_, state) => {
		const newOptions = [];
		if (state.inputValue) {
			tracksArr.forEach((item) => {
				const word = item.title;
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				) {
					newOptions.push(item);
				}
			});
			return newOptions;
		} else return tracksArr;
	};

	return (
		<div className={styles.autoGenres_wrapper}>
			<AutocompleteInputGenres
				multiple
				id="size-small-standard-multi"
				size="small"
				limitTags={2}
				onChange={(_, obj) => onChange(obj)}
				options={tracksArr}
				value={value}
				renderInput={(params) => (
					<>
						<TextFieldInput
							{...params}
							label={label}
							variant="standard"
							required={required}
						/>
						<CheckboxBlack />
					</>
				)}
				getOptionLabel={(option) => {
					return option.title;
				}}
				renderOption={(option, item) => {
					return <li {...option}>{item.title}</li>;
				}}
				isOptionEqualToValue={(option, value) => option.title === value.title}
				PopperComponent={CustomPopper}
				filterOptions={filterOptions}
			/>
			{value.length > 2 && (
				<span className={styles.autoTag}>+{value.length - 2}</span>
			)}
		</div>
	);
};

export default MuiAutocomplete;
