// Core
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import lscache from 'lscache';

// Context
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Services
import { accounts, dictionaries } from 'services';

// Icons
import icon_cd from 'images/icon_cd.svg';
import play_button from 'images/play_button.svg';
import stop_button from 'images/stop_button.svg';

// UI
import { AddRoleContractsBanner } from 'material-design/components/Banners/AddRoleContractsBanner';
import { BottomError, BottomNavi } from 'components';
import ConfirmDistributions from './ConfirmDistributions/ConfirmDistributions';
import { DropZone } from 'components/Upload/DropZone';

// Helpers
import { setCache } from 'services/cacheHelper/setCache';

// Styles
import s from './Confirm.module.css';

const Confirm = (props) => {
	const [personal, setPersonal] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);
	const [country, setCountry] = useState('');
	const [release, setRelease] = useState('');
	const [cover, setCover] = useState('');
	const [audio] = useState(new Audio());
	const [recommendedOutlets, setRecommendedOutlets] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isReqLive] = useState(false);
	const [isNextClicked, setIsNextClicked] = useState(false);
	const [moderationContract, setModerationContract] = useState(true);
	const [contractType, setContractType] = useState([]);

	const lang = localStorage.getItem('lang');
	const releaseId = localStorage.getItem('releaseId');
	const accountId = localStorage.getItem('accountId');
	const history = useHistory();

	const {
		play,
		handlePlay,
		audioId,
		handlePlayStop,
		isShowServicesRepertoireBanner,
		setIsTransferRelease,
	} = useContext(UIContext);

	const { postAdditionalServices } = useContext(RootContext);

	const handleFileUpload = (files) => {
		setErrors({});

		if (
			files[0].type !== 'image/jpg"' &&
			files[0].type !== 'image/jpeg' &&
			files[0].type !== 'image/png' &&
			files[0].type !== 'image/tiff'
		) {
			setErrors({ file: [{ rule: 'image_wrong_format' }] });
			return;
		}

		setLoading(true);
		const fd = new FormData();
		fd.append('file', files[0]);
		accounts
			.setReleaseCover(releaseId, fd)
			.then((res) => {
				setLoading(false);
				setCover(res.data.data.url);
			})
			.catch((err) => {
				setLoading(false);
				if (err?.errors) {
					if (err.errors.file[0].rule === 'image_dimensions') {
						setErrors(err.errors);
					}
				}
			});
		return Promise.resolve();
	};

	const nextHandler = () => {
		if (isNextClicked) {
			return;
		}

		setIsNextClicked(true);
		audio.currentTime = 0;
		audio.pause();
		accounts
			.sendModerateRelease(releaseId, personal)
			.then(() => {
				localStorage.removeItem('releaseId');
				history.push(`/release/${releaseId}/moderation`);
				setIsTransferRelease(false);
			})
			.catch((err) => {
				if (err.response.status === 422) {
					setErrors({ code: '422' });
					console.error(err.response.data.errors);
				}
			});
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			history.push(`/release/${releaseId}/distribution`);
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const emptyTrackList = () => {
		if (typeof props.emptyTrackList === 'function') {
			history.push(`/release/${releaseId}/file`);
			audio.currentTime = 0;
			audio.pause();
			props.emptyTrackList();
		}
	};

	useEffect(() => {
		const releaseId = localStorage.getItem('releaseId');

		const promise = new Promise((resolve, reject) => {
			accounts
				.getReleaseData(releaseId)
				.then((res) => {
					res.data.data.created_date = res.data.data.created_date.replace(
						/(\d{4})-(\d{2})-(\d{2})(.+)/gm,
						'$3.$2.$1'
					);
					res.data.data.distributions.sale_start_date = res.data.data.distributions.sale_start_date.replace(
						/(\d{4})-(\d{2})-(\d{2})(.+)/gm,
						'$3.$2.$1'
					);
					if (!res.data.data?.cover?.url) {
						setErrors({ ...errors, cover: 'required' });
					} else {
						setCover(res.data.data.cover.url);
					}

					if (res.data.data?.tracks && res.data.data?.tracks.length === 0) {
						setErrors({ ...errors, tracks: 'required' });
					}

					setModerationContract(res.data.moderation_contract);
					setContractType(res.data.type_contract);
					setPersonal(res.data.data);
					resolve(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		});

		promise
			.then((data) => {
				const countriesCache = lscache.get('countriesCashe');
				if (countriesCache && !isReqLive) {
					const a = countriesCache.find(
						(item) => item.id === data.created_country_id
					);
					setCountry(a);
				} else {
					dictionaries.getCountries().then((res) => {
						res = res.data.data;
						setCache('countries', 'countriesCashe', res);
						const a = res.find((item) => item.id === data.created_country_id);
						setCountry(a);
					});
				}
				gerReleaseTypes(data);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		accounts
			.getOutletsWithTypes(lang, props.releaseTypeId)
			.then((outlets) => {
				outlets = outlets.data.data;

				const recommendedOutletsCount = outlets.filter(
					(outlet) => outlet.is_recommended === 1
				);
				setRecommendedOutlets(recommendedOutletsCount);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.keys(personal).length) gerReleaseTypes(personal);
	}, [lang]);

	const gerReleaseTypes = (data) => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			const a = releaseTypesCache.data.find(
				(item) => item.id === data.release_type_id
			);
			setRelease(a);
		} else {
			accounts.getReleaseTypes(lang).then((res) => {
				res = res.data.data;
				setCache('releaseTypes', 'releaseTypesCache', res);
				const a = res.find((item) => item.id === data.release_type_id);
				setRelease(a);
			});
		}
	};

	const handleOnLoad = () => {
		setLoading(false);
	};

	return (
		<>
			<div className={s.page}>
				<div className={s.header}>
					<div className={s.header__title}>
						<img src={icon_cd} alt="" />
						<span>{release?.title}:</span>
						<span className={s.headerTitle__name}>{personal.title}</span>
					</div>
					<div className={s.header__isrc}>
						<span>EAN:</span>
						<span>
							{personal.ean ||
								(personal.generate_ean && (
									<FormattedMessage
										id={'rod.release.create.step.submit.assign_automatically'}
									/>
								)) ||
								'-'}
						</span>
					</div>
				</div>
				<div className={s.main}>
					{isShowServicesRepertoireBanner &&
						contractType &&
						((!moderationContract && contractType.length > 0) ||
							contractType.length > 0) && (
							<div className={s.addRoleContractsBanner}>
								<AddRoleContractsBanner
									data={{
										title: (
											<FormattedMessage
												id={
													'rod.account.add_role_banner_release_not_available_title'
												}
											/>
										),
										content: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.add_role_banner_release_not_available_moderation'
														: 'rod.account.add_role_banner_release_not_available_text'
												}
											/>
										),
										btnText: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.save_to_draft_btn'
														: 'rod.account.add_service_btn'
												}
											/>
										),

										data: ['distribution'],
										postAdditionalServices,
										id: accountId,
										action: !moderationContract ? 'to draft' : 'sign services',
									}}
								/>
							</div>
						)}
					<span className={s.main__title}>
						<FormattedMessage
							id={'rod.release.create.step.submit.final_preview'}
						/>
					</span>
					<div className={s.body}>
						<div className={s.section__information}>
							<div className={s.info__table}>
								<span className={s.title}>
									<FormattedMessage
										id={'rod.release.create.step.submit.body_title'}
									/>
								</span>
								<div className={s.table}>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.subtitle'}
											/>
										</span>
										<span className={s.item__text}>
											{personal?.subtitle || '-'}
										</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.relise_type'}
											/>
										</span>
										<span className={s.item__text}>{release?.title}</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.genres'}
											/>
										</span>
										<span className={`${s.item__text} ${s.item_genres}`}>
											{personal.genres && personal.genres.join(', ') && (
												<>
													<span>{personal.genres.join(', ')}</span>
													<span>{personal.genres.join(', ')}</span>
												</>
											)}
										</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.main_artist'}
											/>
										</span>
										<span className={s.item__text}>{personal.performers}</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.label'}
											/>
										</span>
										<span className={s.item__text}>
											{personal?.label || '-'}
										</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.create_date'}
											/>
										</span>
										<span className={s.item__text}>
											{personal.created_date}
										</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.country_name'}
											/>
										</span>
										<span className={s.item__text}>
											{lang === 'ru' ? country?.title_ru : country?.title_en}
										</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>GRID</span>
										<span className={s.item__text}>
											{personal.grid?.replace(
												/(\w{2})(\w{5})(\w{10})(\w{1})/,
												'$1-$2-$3-$4'
											) ||
												(personal.generate_grid && (
													<FormattedMessage
														id={
															'rod.release.create.step.submit.assign_automatically'
														}
													/>
												)) ||
												'-'}
										</span>
									</div>
									<div className={s.item}>
										<span className={s.item__title}>
											<FormattedMessage
												id={'rod.release.create.step.submit.catalog_number'}
											/>
										</span>
										<span className={s.item__text}>
											{personal.catalog_number ||
												(personal.generate_catalog_number && (
													<FormattedMessage
														id={
															'rod.release.create.step.submit.assign_automatically'
														}
													/>
												)) ||
												'-'}
										</span>
									</div>
								</div>
							</div>

							<div className={s.DropZone__wrapper}>
								{cover || loading ? (
									<div className={s.Preview}>
										<img
											className={loading ? s.loader : null}
											src={personal?.cover?.url || cover}
											onLoad={handleOnLoad}
											alt=""
										/>
										{loading && <div className={s.preLoader} />}
									</div>
								) : (
									<>
										<DropZone
											multiple={false}
											accept=".jpg, .jpeg, .png, .tiff"
											imgType={2}
											classStyles={s.DropZone}
											dropzoneId={'rod.release.create.step.cover.upload'}
											onUpload={handleFileUpload}
											kb
											maxSize={1024 * 1024 * 100}
										/>
										<p className={s.error}>
											<FormattedMessage
												id={'rod.release_create.error.cover_required'}
											/>
										</p>
									</>
								)}
							</div>
						</div>
						{!loading && (
							<div className={s.info}>
								<div className={s.recordings__wrapper}>
									<span className={s.lh__title}>
										<FormattedMessage
											id={'rod.release.create.step.submit.recordings'}
										/>
									</span>

									<div className={s.recordings}>
										{personal?.tracks && personal?.tracks.length !== 0 ? (
											personal.tracks.map((item, index) => (
												<div className={s.recordings__item} key={item.id}>
													<span>{index + 1}</span>
													<span>{item?.title}</span>
													<span>3:09</span>
													{(audioId === item.id && play) ||
													(audioId === item.file.id && play) ? (
														<img
															src={stop_button}
															className={s.play}
															onClick={handlePlayStop}
															alt=""
														/>
													) : (
														<img
															src={play_button}
															className={s.play}
															onClick={() =>
																handlePlay(
																	index,
																	item.file,
																	item?.title ?? item.file.client_name
																)
															}
															alt=""
														/>
													)}
												</div>
											))
										) : (
											<>
												<div className={s.error}>
													<FormattedMessage
														id={'rod.release_create.error.track_required'}
													/>
												</div>
												<span
													className={s.errorButton}
													onClick={() => emptyTrackList()}
												>
													<FormattedMessage
														id={'rod.release.create.step.submit.upload'}
													/>
												</span>
											</>
										)}
									</div>
								</div>

								<ConfirmDistributions
									personal={personal}
									recommendedOutlets={recommendedOutlets}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={s.bottom_container}>
				<BottomError
					errors={errors}
					error="code"
					messageId="rod.release.moderation.error"
				/>
				<BottomNavi
					showPrevBtn
					disabled={
						isShowServicesRepertoireBanner
							? Object.keys(errors).length !== 0 ||
							  (!moderationContract &&
									contractType &&
									contractType.length > 0) ||
							  (contractType && contractType.length > 0)
							: Object.keys(errors).length !== 0
					}
					back={backHandler}
					next={nextHandler}
					nextText="rod.release.create.step.submit.submit"
				/>
			</div>
		</>
	);
};

export default Confirm;
