// Messages
import fieldsEn from 'messages/EN/fields';
import fieldsRu from 'messages/RU/fields';

export const filterSettings = [
	{
		id: 1,
		title_ru: 'статус',
		title_en: 'status',
		list: [
			{
				id: 1,
				name_ru: fieldsRu['rod.field.admin.accounts-to-check'],
				name_en: fieldsEn['rod.field.admin.accounts-to-check'],
				code: 'notReady',
				checked: false,
			},
			{
				id: 2,
				name_ru: fieldsRu['rod.field.admin.accounts-moderation-requests'],
				name_en: fieldsEn['rod.field.admin.accounts-moderation-requests'],
				code: 'notReady',
				checked: false,
			},
		],
	},
];
