import React, { useState, useEffect } from 'react';
import s from './RepertoireRecordingComposition.module.css';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import nota from 'images/nota.svg';
import { Loading, NoData } from 'components';
// import plus_in_square from 'images/plus_in_square.svg';
import { Link } from 'react-router-dom';
// import stop_button from 'images/stop_button.svg';
import eye from 'images/eye_black.svg';

export default function RepertoireRecordingComposition({
	accountId,
	recordingId,
	getRecordingsCompositions,
}) {
	// const items = [
	// 	{
	// 		id: 1,
	// 		name: 'Название произведения',
	// 		author: 'Tyler The Creator',
	// 		share: '100',
	// 	},
	// ];
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([]);

	useEffect(() => {
		getRecordingsCompositions(accountId, recordingId).then((res) => {
			setItems(res);
			setLoading(false);
		});
	}, []);

	return loading ? (
		<Loading />
	) : (
		<>
			{items.length > 0 ? (
				<div className={s.body}>
					<div className={`${s.table__header} ${s.table}`}>
						<FormattedHTMLMessage id={'rod.for_all.title'} />
						<FormattedHTMLMessage id={'rod.for_all.author'} />
						{/* <FormattedHTMLMessage id={'rod.for_all.share'} /> */}
					</div>
					<ul className={s.main}>
						{items.map((i) => (
							<li key={i.id}>
								<Link key={i.id} to={`/repertoire/compositions/${i.id}`}>
									<div className={`${s.table__item} ${s.table}`}>
										<span className={s.item__name}>{i.title}</span>
										<span className={s.item__authors}>
											{i.authors.join(', ')}
										</span>
										{/* <span>{i.share ? `${i.share}%` : ''}</span> */}
										<img
											src={nota}
											width={22}
											height={22}
											className={s.nota}
											alt=""
										/>
										<img
											src={eye}
											className={s.eye}
											// onClick={() => handleEye(i.id)}
											alt=""
										/>
									</div>
								</Link>
							</li>
						))}
					</ul>
				</div>
			) : (
				// 	<div className={s.songPhonogram__empty}>
				// 		<img src={icon_cd} width={64} height={64} alt="" />
				// 		{/* <span>
				//       Просим <a className={s.songPhonogram__emptyButton} onClick={modalOpen}>загрузить</a> или <a className={s.songPhonogram__emptyButton} onClick={modalOpen}> добавить </a> фонограмму
				//    </span> */}
				// 	</div>
				<div>
					<NoData />
				</div>
			)}
		</>
	);
}
