// Core
import { FormattedHTMLMessage } from 'react-intl';

// UI
import { InfoButton } from '../InfoButton';

// Styles
import styles from './HelpButton.module.css';

const HelpButton = ({ showModal, helpTitle }) => {
	return (
		<InfoButton
			className={styles.help_btn}
			onClick={showModal({
				text: <FormattedHTMLMessage id={helpTitle} />,
			})}
		/>
	);
};

export default HelpButton;
