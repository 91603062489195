// Core
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Styles
import s from './EndConfirmationPage.module.css';

const EndConfirmationPage = ({ title, text, textLinks, btnText, btnLink }) => {
	const { setCurrentCompositionStatus, setSelectedRepertoire } = useContext(
		UIContext
	);
	let values = {};

	const onClickHandler = () => {
		if (textLinks.link.link === '/repertoire/compositions') {
			setSelectedRepertoire('compositions');
			setCurrentCompositionStatus('verify');
		}
	};

	if (textLinks) {
		for (let item in textLinks) {
			values[item] = (
				<Link
					to={textLinks[item].link}
					style={{ textDecoration: 'underline', color: 'unset' }}
					onClick={onClickHandler}
				>
					<FormattedMessage id={textLinks[item].phrase} />
				</Link>
			);
		}
	}

	return (
		<div className={s.end}>
			<span className={s.title}>
				<FormattedMessage id={title} />
			</span>
			<span className={s.text}>
				{values ? (
					<FormattedMessage id={text} values={values} />
				) : (
					<FormattedMessage id={text} />
				)}
			</span>
			<Link className={s.button} to={btnLink}>
				<span>
					<FormattedMessage id={btnText} />
				</span>
			</Link>
		</div>
	);
};

export default EndConfirmationPage;
