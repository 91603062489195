/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 06/11/2020, 14:03
 */
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { withUI } from 'hocs';
import styles from 'pages/contracts/AddContract/NoCode/NoCode.module.css';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

class NoCode extends Component {
	componentDidMount() {
		this.props.UIContext.hideBackNavi();
	}

	handleBack = () => {
		if (typeof this.props.onSubmit === 'function') {
			this.props.onSubmit();
		}
	};

	render() {
		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.contracts.no_code.header'}/>
						</Label>
						<Label font="--gilroy-Medium-24" black>
							<FormattedMessage id={'rod.contracts.no_code.body'}/>
						</Label>
					</div>
					<div className={styles.Controls}>
						<Button className={styles.Button}
										text={<FormattedMessage id={'rod.action.goto_contract'}/>}
										type={'submit'}
										variant={'primary'}
										onClick={this.handleBack}
						/>
					</div>
				</div>
			</div>

		);
	}
}

export default compose(
	withUI,
)(NoCode);
