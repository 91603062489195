// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { accounts } from 'services';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { RadioGroup } from 'components/Radio';
import { BottomNavi, InfoHelper, Loading } from 'components';
import { InfoButton } from 'components/Buttons';

// Helpers
import { getOutletInfo } from './helpers/getOutletInfo';
import { correctDate } from './helpers/correctDate';
import { radio1, radio2 } from './helpers/radio';

// Icons
import checkbox_bd from 'images/checkbox_bd.svg';
import checkbox from 'images/checkbox.svg';
import checkbox_bd_dgtal from 'images/checkbox_bd_dgtal.svg';
import checkbox_dgtal from 'images/checkbox_dgtal.svg';
import checkbox_disabled from 'images/checkbox_disabled.svg';

// Styles
import s from './Distribution.module.css';

const CheckBox = ({ item, allChecked, getCheckedOutlets, isLocked }) => {
	const handleChecked = () => {
		if (allChecked) return;
		item.checked ? (item.checked = false) : (item.checked = true);
		getCheckedOutlets(item);
	};

	return !isLocked ? (
		<div className={s.table__item} onClick={handleChecked} checked>
			{process.env.REACT_APP_CLIENT === 'dgtal' ? (
				<img
					src={
						!item.checked && !allChecked ? checkbox_bd_dgtal : checkbox_dgtal
					}
					alt=""
				/>
			) : (
				<img
					src={!item.checked && !allChecked ? checkbox_bd : checkbox}
					alt=""
				/>
			)}

			<span>{item.title}</span>
		</div>
	) : (
		<div className={s.table__item_locked} checked>
			<img src={checkbox_disabled} alt="" />
			<span>{item.title}</span>
		</div>
	);
};

function Distribution(props) {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [personalData, setPersonalData] = useState('7-');
	const [personalData2, setPersonalData2] = useState('all');
	const [inputDate, setInputDate] = useState(false);
	const [inputTransferDate, setInputTransferDate] = useState(false);
	const [isDateEmpty, setIsDateEmpty] = useState(false);
	const [showCheckbox, setShowCheckbox] = useState(false);
	const [allChecked, setAllChecked] = useState(true);
	const [checks, setChecks] = useState([]);
	const [checked, setChecked] = useState([]);
	const [personal, setPersonal] = useState(
		props.data || {
			distribution_date: '7-',
			outlets: 'All outlets available',
		}
	);
	const [errors, setErrors] = useState({});
	const [transferRelease, setTransferRelease] = useState(null);
	const [distributionData, setDistributionData] = useState();

	const releaseId = localStorage.getItem('releaseId');

	useEffect(() => {
		setIsLoading(true);
		accounts.getReleaseData(releaseId).then((res) => {
			res = res.data.data;
			if (res.isTransferRelease === 1) {
				setTransferRelease(true);
				setIsLoading(false);
			}
		});
	}, [releaseId]);

	useEffect(() => {
		accounts
			.getDistribution(releaseId)
			.then((res) => {
				res = res.data.data;
				if (res.sale_start_date) {
					const e = {};
					e.currentTarget = { value: getType(res.type) };
					personal.sale_start_date = res.sale_start_date.slice(0, 10);
					DistributionDateRadioHandler(e);
				}
				if (!res.outlets) {
					accounts
						.getOutletsWithTypes(lang, props.releaseTypeId)
						.then((outlets) => {
							outlets = outlets.data.data;
							const e = {};
							e.currentTarget = { value: getType(res?.type || 'asap') };
							DistributionDateRadioHandler(e);
							for (let i = 0; i < outlets.length; i++) {
								outlets[i].checked = true;
							}
							setChecks(outlets);
							setChecked(outlets);
							setShowCheckbox(false);
						});
				} else {
					accounts
						.getOutletsWithTypes(lang, props.releaseTypeId)
						.then((outlets) => {
							outlets = outlets.data.data;
							const data = res.outlets.split(',');
							const isAllOutlets = data.length === outlets.length;
							let e = {};
							e.currentTarget = { value: isAllOutlets ? 'all' : 'inc' };
							OutletsRadioHandler(e);
							setAllChecked(isAllOutlets);
							setShowCheckbox(!isAllOutlets);
							const checkedArr = [];
							for (let i = 0; i < outlets.length; i++) {
								if (
									data.includes(outlets[i].id.toString()) ||
									outlets[i].is_recommended === 1
								) {
									outlets[i].checked = true;
									checkedArr.push(outlets[i]);
								} else {
									outlets[i].checked = false;
								}
							}
							setChecks(outlets);
							setChecked(checkedArr);
						});
				}
				setDistributionData(res);
				setPersonal({ ...res });
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			checked.length - checks.filter((item) => item.is_recommended).length ===
				0 &&
			!allChecked
		) {
			setErrors((prev) => ({
				...prev,
				check_box_outlets: [{ rule: 'check_box_outlets_required' }],
			}));
		} else if (errors.check_box_outlets) {
			setErrors((prev) => {
				let obj = { ...prev };
				delete obj.check_box_outlets;
				return obj;
			});
		}
		// eslint-disable-next-line
	}, [checked, allChecked]);

	useEffect(() => {
		if (!distributionData?.outlets) {
			accounts
				.getOutletsWithTypes(lang, props.releaseTypeId)
				.then((outlets) => {
					outlets = outlets.data.data;
					for (let i = 0; i < outlets.length; i++) {
						outlets[i].checked = true;
					}
					setChecks(outlets);
					setChecked(outlets);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		} else {
			accounts
				.getOutletsWithTypes(lang, props.releaseTypeId)
				.then((outlets) => {
					outlets = outlets.data.data;

					const data = personal.outlets;
					const checkedArr = [];
					for (let i = 0; i < outlets.length; i++) {
						if (
							data.includes(outlets[i].id) ||
							outlets[i].is_recommended === 1
						) {
							outlets[i].checked = true;
							checkedArr.push(outlets[i]);
						} else {
							outlets[i].checked = false;
						}
					}
					setChecks(outlets);
					setChecked(checkedArr);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line
	}, [lang]);

	const getType = (type) => {
		if (type === 'transfer') return '1-';
		if (type === 'regular') return '7+';
		if (type === 'asap') return '7-';
	};

	const errorsFromInput = (err) => {
		if (!Object.keys(err).length) {
			setErrors((prev) => {
				let obj = { ...prev };
				delete obj?.sale_start_date;
				return obj;
			});
		} else {
			setErrors((prev) => ({ ...prev, ...err }));
		}
	};

	const clearIsDateEmpty = () => {
		setIsDateEmpty(false);
	};

	const nextHandler = () => {
		if (inputTransferDate && personal.sale_start_date === '')
			return setIsDateEmpty(true);

		if (transferRelease) {
			allChecked
				? (personal.outlets = checks.map((item) => item.id))
				: (personal.outlets = checked.map((item) => item.id));

			props.onChange({
				...personal,
				sale_start_date: correctDate(personal.sale_start_date),
			});
		}

		if (
			typeof props.onChange === 'function' &&
			Object.keys(errors).length === 0
		) {
			allChecked
				? (personal.outlets = checks.map((item) => item.id))
				: (personal.outlets = checked.map((item) => item.id));

			props.onChange({
				...personal,
				sale_start_date: correctDate(personal.sale_start_date),
			});
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			history.push(`/release/${releaseId}/cover`);
			props.handlePrevStep();
		}
	};

	const inputChangeField = (data) => {
		setPersonal({ ...data });
	};

	const DistributionDateRadioHandler = (e) => {
		if (e.currentTarget.value === '7+') {
			setPersonal((prev) => ({
				...prev,
				sale_start_date: correctDate(Date.now() + 604800000),
				type: 'regular',
			}));
			setInputTransferDate(false);
			setInputDate(true);
		} else if (e.currentTarget.value === '1-') {
			setPersonal((prev) => ({
				...prev,
				sale_start_date: '',
				type: 'transfer',
			}));
			setInputDate(false);
			setInputTransferDate(true);
		} else if (e.currentTarget.value === '7-') {
			setInputDate(false);
			setInputTransferDate(false);
			setPersonal((prev) => ({
				...prev,
				sale_start_date: correctDate(Date.now() + 172800000),
				type: 'asap',
			}));
		}
		setPersonalData(e.currentTarget.value);
	};

	const OutletsRadioHandler = (e) => {
		setPersonalData2(e.currentTarget.value);

		e.currentTarget.value === 'all'
			? setAllChecked(true)
			: setAllChecked(false);

		const checkedId = checked.map((item) => item.id);
		const allOutletsId = checks.map((item) => item.id);
		personal.outlets =
			e.currentTarget.value === 'all' ? allOutletsId : checkedId;

		e.currentTarget.value === 'all'
			? setShowCheckbox(false)
			: setShowCheckbox(true);
	};

	const getCheckedOutlets = (item) => {
		switch (item.checked) {
			case true:
				const youtubeOutlet = checks.find((outlet) => outlet.id === 21859);
				if (item.id === 436356 && !youtubeOutlet.checked) {
					youtubeOutlet['checked'] = true;
					setChecked((prev) => [...prev, item, youtubeOutlet]);
				} else setChecked((prev) => [...prev, item]);

				break;
			case false:
				const youtubeIdOutlet = checks.find((outlet) => outlet.id === 436356);
				if (item.id === 21859 && youtubeIdOutlet.checked) {
					youtubeIdOutlet['checked'] = false;
					const data = checked.filter(
						(i) => i.id !== item.id && i.id !== 436356
					);
					setChecked(data);
				} else {
					const data = checked.filter((i) => i.id !== item.id);
					setChecked(data);
				}
				break;
			default:
				break;
		}
	};

	return (
		<>
			<div className={s.page} style={allChecked ? { height: '85vh' } : {}}>
				{isLoading && <Loading className={s.loader} />}
				{!isLoading && (
					<>
						<div className={s.main}>
							<span className={s.title}>
								<FormattedMessage
									id={'rod.release.create.step.distribution'}
								></FormattedMessage>
							</span>
							<div className={s.body}>
								<div className={s.Input__radio}>
									<div className={s.Input__title}>
										<FormattedMessage
											id={'rod.release.create.step.submit.distribution_date'}
										/>
									</div>
									<RadioGroup
										className={s.RadioGroup_date}
										name={'distribution_date'}
										value={personalData}
										onChange={DistributionDateRadioHandler}
										items={radio1}
										personal={personal}
										inputDate={inputDate}
										inputTransferDate={inputTransferDate}
										inputChangeField={inputChangeField}
										errorsFromInput={errorsFromInput}
										isDateEmpty={isDateEmpty}
										clearIsDateEmpty={clearIsDateEmpty}
										transferRelease={transferRelease}
									/>
								</div>

								<div className={s.Input__radio}>
									<div className={s.Input__title}>
										<FormattedMessage
											id={'rod.release.create.step.distribution.input_title'}
										/>
									</div>

									<RadioGroup
										className={s.RadioGroup}
										name={'outlets'}
										value={personalData2}
										onChange={OutletsRadioHandler}
										items={radio2}
									/>

									{isLoading ? (
										<Loading className={s.loader} />
									) : (
										showCheckbox && (
											<>
												<div className={s.Outlets}>
													<div className={s.outlets__title}>
														<FormattedMessage
															id={
																'rod.release.create.step.distribution.outlets'
															}
														/>
													</div>

													<div className={s.table}>
														{checks.map(
															(item, index) =>
																!item.is_recommended &&
																(item.description_ru &&
																item.description_ru.trim() !== '' &&
																item.description_en &&
																item.description_en.trim() !== '' ? (
																	<InfoButton
																		className={s.info}
																		onClick={showModal({
																			text: getOutletInfo(item),
																		})}
																	>
																		<CheckBox
																			name={'check_box_outlets'}
																			key={index}
																			checks={checks}
																			onChange={setChecks}
																			item={item}
																			allChecked={allChecked}
																			index={index}
																			getCheckedOutlets={getCheckedOutlets}
																			isLocked={
																				item.is_recommended ? true : false
																			}
																		/>
																	</InfoButton>
																) : (
																	<CheckBox
																		name={'check_box_outlets'}
																		key={index}
																		checks={checks}
																		onChange={setChecks}
																		item={item}
																		allChecked={allChecked}
																		index={index}
																		getCheckedOutlets={getCheckedOutlets}
																		isLocked={
																			item.is_recommended ? true : false
																		}
																	/>
																))
														)}
													</div>
												</div>
											</>
										)
									)}
								</div>
							</div>
						</div>
						<InfoHelper text="rod.release.create.step.distributions.description" />
					</>
				)}
			</div>
			<div className={s.bottom_container}>
				<BottomNavi
					showPrevBtn
					disabled={!allChecked && Object.keys(errors).includes('check_box_outlets') || Object.keys(errors).includes('sale_start_date')}
					back={backHandler}
					next={nextHandler}
				/>
				<div className={s.error_container}>
					{Object.keys(errors).includes('check_box_outlets') && (
						<span className={s.error}>
							{
								<FormattedMessage
									id={`rod.error.${errors.check_box_outlets[0].rule}`}
								/>
							}
						</span>
					)}
				</div>
			</div>
		</>
	);
}

export default Distribution;
