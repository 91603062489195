import React, { useEffect, useState } from 'react';
import { Loading } from 'components';

import styles from './StatisticByDatesChart.module.css';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import close from 'images/close_button.svg';

import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

import {
	XYPlot,
	XAxis,
	YAxis,
	HorizontalGridLines,
	LineSeries,
	AreaSeries,
	Borders,
	Crosshair,
	MarkSeries,
} from 'react-vis';

const StatisticByDatesChart = ({
	data,
	selectedOutlets,
	xAxisSize,
	amountYAxisTitle,
}) => {
	const [loading, setLoading] = useState(false);

	const [isCumulativeBttActive, setIsCumulativeBttActive] = useState(true);
	const [helper, setHelper] = useState(false);
	const [comparisonChartData, setComparisonChartData] = useState(data);
	const [width, setWidth] = useState(0);
	const [legend, setLegend] = useState([]);
	const [total, setTotal] = useState(0);
	const [crosshairData, setCrosshairData] = useState([]);

	const lang = localStorage.getItem('lang');

	const colorDomain = [0, 1, 2, 3, 4, 5];
	const colorRange = [
		'#FFEFDE',
		'#FDF7DD',
		'#ECF4FE',
		'#E6F8EC',
		'#FBE8FF',
		'#F2DDDF',
	];
	const strokeColorRange = [
		'#FFAE59',
		'#F7D755',
		'#7EB6F6',
		'#82DAA1',
		'#EA8EFF',
		'#ED828E',
	];

	// const colorRange = [
	// 	'#FFAE59',
	// 	'#F7D755',
	// 	'#7EB6F6',
	// 	'#82DAA1',
	// 	'#EA8EFF',
	// 	'#ED828E',
	// ];
	// const strokeColorRange = [
	// 	'#FFAE59',
	// 	'#F7D755',
	// 	'#7EB6F6',
	// 	'#82DAA1',
	// 	'#EA8EFF',
	// 	'#ED828E',
	// ];
	const assignColorIndexToOutlet = (outlet) => {
		let colorIndex = 0;
		switch (outlet) {
			case 'apple':
				colorIndex = 0;
				break;
			case 'ok':
				colorIndex = 1;
				break;
			case 'spotify':
				colorIndex = 2;
				break;
			case 'vk':
				colorIndex = 3;
				break;
			case 'yandex':
				colorIndex = 4;
				break;
			case 'zvuk':
				colorIndex = 5;
				break;
			default:
				break;
		}
		return colorIndex;
	};

	const makeLegend = () => {
		let result = Object.values(data).map((element, index) => {
			const outlet = selectedOutlets.find((outlet) => {
				return outlet.code === element.code;
			});

			return {
				title: outlet[`title_${lang}`],
				code: outlet.code,
				color: strokeColorRange[assignColorIndexToOutlet(outlet.code)],
			};
		});
		setLegend(result.reverse());
	};

	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
	};

	const countTotal = () => {
		let result = 0;
		if (crosshairData) {
			crosshairData?.forEach((item) => {
				result = Math.round((result + item.y) * 1000000) / 1000000;
			});
			setTotal(result);
		}
	};
	useEffect(() => {
		countTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCumulativeBttActive]);

	useEffect(() => {
		countTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crosshairData]);

	useEffect(() => {
		setComparisonChartData(data);
		if (Object.values(data).length === selectedOutlets.length) {
			makeLegend();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (Object.values(data).length === selectedOutlets.length) {
			makeLegend();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		setLoading(false);
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);
		return () => {
			window.removeEventListener('resize', updateWindowDimensions);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chartModeSwitch = (e) => {
		e.preventDefault();
		setIsCumulativeBttActive(!isCumulativeBttActive);
	};

	const onNearestX = (value, { index }) => {
		let resultedCrosshairData = Object.values(comparisonChartData).map(
			(item) => {
				const itemData = item.data;
				return itemData[index];
			}
		);
		setCrosshairData(resultedCrosshairData.reverse());
	};

	const thousandDiv = (number) => {
		const numLength = Math.floor(number).toString().length;
		if (numLength > 3 && numLength < 7) {
			const result =
				number.toString().slice(0, numLength - 3) +
				' ' +
				number.toString().slice(numLength - 3);

			return result;
		} else return number;
	};

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={() => setHelper(false)} src={close} alt="" />
					<span>
						<FormattedHTMLMessage
							id={'rod.drafts.title'}
						></FormattedHTMLMessage>
					</span>
				</div>
			)}
			{loading ? (
				<Loading className={styles.loader} />
			) : (
				comparisonChartData && (
					<>
						<div className={styles.statistic__mainContent}>
							<div className={styles.statistic__mainContentHeader}>
								<div className={styles.statistic__mainContentHeaderInfo}>
									<div
										className={styles.statistic__mainContentHeaderInfo_title}
									>
										<FormattedMessage id={'rod.statistic.chart-title'} />
									</div>
									<div
										className={styles.statistic__mainContentHeaderInfo_amount}
									>
										{amountYAxisTitle && (
											<FormattedMessage id={`${amountYAxisTitle}`} />
										)}
									</div>
								</div>
								<div className={styles.statistic__mainContentButtons}>
									<button
										className={`${styles.statistic__mainContentButtonsFirst} ${
											isCumulativeBttActive
												? styles.statistic__mainContentButtonsActive
												: ''
										}`}
										onClick={chartModeSwitch}
									>
										<FormattedMessage id={'rod.statistic.chart-accumulative'} />
									</button>
									<button
										className={`${styles.statistic__mainContentButtonsSecond} ${
											!isCumulativeBttActive
												? styles.statistic__mainContentButtonsActive
												: ''
										}`}
										onClick={chartModeSwitch}
									>
										<FormattedMessage id={'rod.statistic.chart-comparative'} />
									</button>
								</div>
							</div>
							<XYPlot
								// dontCheckIfEmpty = {true}
								xType="time-utc"
								width={width > 1024 ? width - 246 : width}
								margin={{
									left: 80,
									right: 40,
									top: 10,
									bottom: 40,
								}}
								height={536}
								stackBy={isCumulativeBttActive ? 'y' : ''}
								onMouseLeave={() => setCrosshairData([])}
								style={{ background: 'white' }}
								colorType="category"
								colorDomain={colorDomain}
								colorRange={colorRange}
								// className={styles.statistic__XYPlot}
							>
								<MarkSeries
									data={[
										{
											x: Object.values(comparisonChartData)[0].data[0].x,
											y: 0,
										},
									]}
									style={{ display: 'none' }}
								/>
								{!isCumulativeBttActive
									? Object.values(comparisonChartData).map(
											(dataByOutlet, index) => (
												<LineSeries
													key={index}
													// width={'100%'}
													color={assignColorIndexToOutlet(dataByOutlet.code)}
													opacity={1}
													stroke={
														strokeColorRange[
															assignColorIndexToOutlet(dataByOutlet.code)
														]
													}
													data={dataByOutlet.data}
													onNearestX={onNearestX}
												/>
											)
									  )
									: Object.values(comparisonChartData).map(
											(dataByOutlet, index) => {
												// console.log(dataByOutlet.data);
												return (
													<AreaSeries
														key={index}
														// width={'100%'}
														color={assignColorIndexToOutlet(dataByOutlet.code)}
														opacity={1}
														// stroke={strokeColorRange[index]}
														// data={removeZeroY(dataByOutlet.data)}
														data={dataByOutlet.data}
														// onNearestX={onNearestX}
														className={styles.areaSeries}
													/>
												);
											}
									  )}
								{isCumulativeBttActive &&
									Object.values(comparisonChartData).map(
										(dataByOutlet, index) => {
											return (
												<LineSeries
													style={{ zIndex: -index }}
													key={index}
													// width={'100%'}
													color={assignColorIndexToOutlet(dataByOutlet.code)}
													opacity={1}
													stroke={
														strokeColorRange[
															assignColorIndexToOutlet(dataByOutlet.code)
														]
													}
													// strokeWidth={1}
													// data={dataByOutlet.data.filter(element=> element.y !==0)}
													// data={removeZeroY(dataByOutlet.data)}
													data={dataByOutlet.data}
													onNearestX={onNearestX}
												/>
											);
										}
									)}
								<HorizontalGridLines /* width={'100%'} */ />
								<Borders
									style={{
										bottom: { fill: '#fff' },
										left: { fill: '#fff' },
										right: { fill: '#fff' },
										top: { fill: '#fff' },
									}}
								/>
								<Crosshair values={crosshairData}>
									<div className={styles.crosshairData__wrapper}>
										<p className={styles.crosshairData__title}>
											{crosshairData[0] &&
												crosshairData[0]['x']
													.toISOString()
													.slice(0, 10)
													.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1')}
										</p>
										{crosshairData.map((item, index) => {
											return (
												<div key={index} className={styles.crosshairData__item}>
													<div
														className={styles.crosshairData__itemColor}
														style={{
															background:
																colorRange[
																	assignColorIndexToOutlet(legend[index]?.code)
																],
															borderTop: `2px ${legend[index]?.color}  solid`,
														}}
													></div>
													<div>
														<span className={styles.crosshairData__itemTitle}>
															{legend[index]?.title}:
														</span>
														<span
															className={styles.crosshairData__itemStatistic}
														>
															&nbsp;{thousandDiv(item.y)}
															{item.y !== 0 &&
																isCumulativeBttActive &&
																` (${((+item.y * 100) / +total).toFixed(2)}%)`}
														</span>
													</div>
												</div>
											);
										})}
										<div className={styles.crosshairData__total}>
											<span className={styles.crosshairData__itemTitle}>
												<FormattedMessage id={'rod.statistic.chart-total'} />:
											</span>
											<span className={styles.crosshairData__itemStatistic}>
												&nbsp;{thousandDiv(total)}
											</span>
										</div>
									</div>
								</Crosshair>
								<XAxis
									tickFormat={(v) =>
										v.toLocaleDateString('en-US', {
											day: 'numeric',
											month: 'short',
										})
									}
									tickTotal={xAxisSize < 10 ? xAxisSize : 10}
								/>
								<YAxis
									tickFormat={(v) =>
										v >= 0 ? (v.toString().includes('.') ? `` : `${v}`) : ``
									}
								/>
							</XYPlot>

							<div className={styles.crosshairData__legend}>
								{legend &&
									legend.map((item, index) => {
										return (
											<div
												key={index}
												className={styles.crosshairData__legendItem}
											>
												<div
													className={styles.crosshairData__legendItemColor}
													style={{
														background:
															colorRange[assignColorIndexToOutlet(item.code)],
														borderTop: `2px ${item?.color}  solid`,
													}}
												></div>
												<div>
													<span
														className={styles.crosshairData__legendItemTitle}
													>
														{legend[index]?.title}
													</span>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</>
				)
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(StatisticByDatesChart);
