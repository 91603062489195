/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/02/2021, 11:32
 */
import actions from './EN/actions';
import errors from './EN/errors';
import fields from './EN/fields';
import modals from './EN/modals';
import pages from './EN/pages';
// eslint-disable-next-line
const en = {
	...errors,
	...actions,
	...fields,
	...modals,
	...pages,

	/* Helpers */
	'rod.under_construction': 'Under construction',
	'rod.form.required_title': '* mandatory fields',
	'rod.label.required_fields': '* mandatory fields',
	'rod.autosuggest.nothing_to_show': 'Nothing to show',

	'rod.repertoire.banner.header': 'Hello and welcome to Broma 16 community.',
	'rod.repertoire.banner.body':
		'To start to provide you with services, we will need an information about your repertoire. Below, please find link to download form to fill in your repertoire information and also links to instructions on how to do it. Please download the form and read  instructions carefully, before start.  When you finish to fill in the form, please upload it back into your account, using “UPLOAD” option here. Use “HELP” widget on the right  below, to find some more explanatory materials or to communicate with us, should you need any help. ',
	'rod.repertoire.banner.action.upload': 'Upload',

	'rod.msg.validation.error.phone_not_valid': 'wrong phone',
	'rod.user.repertoire.upload.text':
		'<u>Browse</u> or drag and drop document file',

	'rod.account.create.confirm.header': 'Thank you!',
	'rod.account.create.confirm.subheader':
		'please confirm that data you provided below is correct',
	'rod.account.create.confirm.account': 'Personal data',
	'rod.account.create.confirm.personal_entrepreneur': 'Sole entrepreneur data',
	'rod.account.create.confirm.company': 'Company data',
	'rod.account.create.confirm.contacts': 'Contact details',
	'rod.account.create.confirm.documents': 'Documents',
	'rod.account.create.confirm.societies': 'Societies',
	'rod.account.create.confirm.identifiers': 'Identifiers',
	'rod.account.create.confirm.payments': 'Payment details',

	'rod.account.create.confirm.table.title': 'Title',
	'rod.account.create.confirm.table.value': 'Value',
	'rod.field.sex': 'Sex',

	'rod.account.create.confirm.roles': 'Role',
	'rod.account.create.confirm.scope_group': 'Repertoire',
	'rod.account.create.confirm.business_type': 'Party type',

	'rod.document.passport_photo_page': 'Passport (photo page)',
	'rod.document.passport_registration_page': 'Passport (registration page)',

	'rod.document.sole_entrepreneur_registration_certificate':
		'Sole entrepreneur registration certificate',
	'rod.document.company.registration_certificate':
		'Company registration certificate',
	'rod.document.company_tax_residency_certificate':
		'Company tax residency certificateе',
	'rod.document.company_registration_certificate':
		'Company registration certificate',

	'rod.account.create.moderation.declined_documents.header':
		'Thank you for providing your personal details',
	'rod.account.create.moderation.declined_documents':
		'Some errors were detected as follow:',
	'rod.account.create.moderation.declined_documents.todo':
		'Please upload  correct documents',
	'rod.action.to_documents': 'Upload documents',

	'rod.account.create.moderation.declined_contracts.header': 'Thank you!',
	'rod.account.create.moderation.declined_contracts':
		'Some error have found. Please improve it and upload a correct documents again',
	'rod.action.to_contracts': 'Upload documents',

	'rod.account.create.moderation.error_quality': 'low quality of the document',
	'rod.account.create.moderation.error_invalid':
		'invalid document (expired or incorrect)',
	'rod.account.create.moderation.error_sign': ' missing some signatures',
	'rod.account.create.moderation.error_pages': 'missing some pages',
	'rod.account.create.moderation.passport_regis': 'Passport / photo page',
	'rod.account.create.moderation.passport_address':
		'Passport / registration page',
	'rod.account.create.moderation.driving_license_front_page':
		'Driving license, front page',
	'rod.account.create.moderation.driving_license_back_page':
		'Driving license, back page',

	'rod.account.create.moderation.pending_contract.header': 'Thank you',
	'rod.account.data.confirm_title': 'data validation',
	'rod.account.data.confirm_description':
		'If the data is not correct, please go back to the settings and update the information',
	'rod.account.data.confirm.account': 'personal data',
	'rod.account.data.confirm.personal_entrepreneur': 'Sole entrepreneur data',
	'rod.account.data.confirm.company': 'company data',
	'rod.account.data.confirm.payments': 'payment details',
	'rod.account.data.confirm.tax_form': 'tax form',
	'rod.account.data.confirm.sign_type': 'Select the type of signing',
	'rod.account.data.confirm.company_address': 'Company registration address',

	'rod.account.invoice-sign.title': 'download and sign the invoice',
	'rod.account.invoice-sign.title_online': 'sign an invoice for payment online',
	'rod.account.invoice-sign.description':
		'to pay the bill you need to confirm it, download and sign. Please download the signed account in PDF format and send it to moderation.',
	'rod.account.invoice-sign.invoice_number': 'invoice for №{value}',
	'rod.account.invoice-sign.tooltip': `<p>Please sign the invoice with your signature using the standard tool for signing documents in Acrobat Reader or another PDF editor, <b>not the function for adding comments.</b></p>
	`,

	'rod.account.tooltip': `<p>Please sign the contracts with your signature using the standard tool for signing documents in Acrobat Reader or another PDF editor, <b>not the function for adding comments</b>.</p>
	<p>Also, sign each page of each contract at the bottom - (Sign here: )</p>
	`,
	// 'rod.account.create.moderation.pending_contract': 'Thank you',
};

export default en;
