// Core
import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useContext,
} from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Context
import { RootContext } from 'contexts/RootContext';

// UI
import { Pagination, Input } from 'material-design/components';
import { MainStatsCompositions } from 'material-design/components/MainStats/MainStatsCompositions';
import { Col, Row, Container } from 'react-grid-system';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import { accounts } from 'services';
import { Loading } from 'components';
import { RepertoireFilterModal } from 'material-design/modals/RepertoireFilterModal';
import { AudioPlayer } from 'material-design/components/audioPlayer';

// Icons
import close from 'images/close_button.svg';
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import playInactive from 'images/play_button_inactive.svg';
import { Dynamic } from 'material-design/svg';

// Data
import { filterRecordingSettingsData } from './data/filterSettingsData';

// Hooks
import { useOutsideClick } from 'material-design/hooks';

// Helpers
import { handleFilterClear } from './helpers/handleFilterClear';
import { handleClear } from './helpers/handleClear';
import { showStatus } from './helpers/showStatus';

// Styles
import styles from './RepertoireRecordingSection.module.css';

function RepertoirePhonogramSection(props) {
	const { statistics } = props;

	const [page, setPage] = useState('initial');
	const [phonogramItems, setPhonogramItems] = useState([]);
	const [value, setValue] = useState('');
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(true);

	const {
		authContext: { accountId },
		UIContext: { isPlayerShow, play, handlePlay, audioId, handlePlayStop },
	} = props;

	const lang = localStorage.getItem('lang');
	const [modal, setModal] = useState(false);
	const [filterSettings, setFilterSettings] = useState(
		filterRecordingSettingsData
	);

	const [currentFilterSettings, setCurrentFilterSettings] = useState({});
	const [selectFilters, setSelectFilters] = useState({});
	const [recordingsStatus, setRecordingsStatus] = useState('');
	const [isFocus, setIsFocus] = useState(false);

	const history = useHistory();
	const resultRef = useRef(null);
	const helpRef = useRef(null);

	const modalOpen = (active) => setModal(active);
	const handleChangeFilter = (data) => setFilterSettings(data);
	const handleCurrentFilter = (data) => setCurrentFilterSettings(data);
	const onClose = () => {
		setIsFocus(false);
	};
	useOutsideClick(resultRef, onClose, isFocus, helpRef);

	const { getRepertoireDataByCiType } = useContext(RootContext);

	useEffect(() => {
		const firstFilter = currentFilterSettings
			? Object.values(currentFilterSettings)[0]?.show
				? Object.values(currentFilterSettings)[0]
				: ''
			: '';

		if (firstFilter) {
			setRecordingsStatus(firstFilter.code);
		} else {
			setRecordingsStatus('');
		}
	}, [currentFilterSettings]);

	const handleChange = (e) => {
		onChange(e);
	};

	const debouncedChangeHandler = useCallback(debounce(handleChange, 500), [
		recordingsStatus,
	]);

	const onChange = (filter) => {
		setValue(filter);
		setLoading(true);
		getRecordingsData(filter);
		setPage(1);
	};

	useEffect(() => {
		setLoading(true);
		if (page !== 1) {
			setPage(1);
		} else {
			getRecordingsData(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recordingsStatus]);

	useEffect(() => {
		if (page !== 'initial') {
			setLoading(true);
			getRecordingsData(value, page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const getRecordingsData = (filterText, page = 1) => {
		const searchByStatus = recordingsStatus.length > 0;
		switch (filterText.length >= 3) {
			case true:
				switch (searchByStatus) {
					case true:
						if (['active', 'expired', 'expiring'].includes(recordingsStatus)) {
							getRepertoireDataByCiType(
								accountId,
								'recordings',
								recordingsStatus,
								page,
								filterText
							)
								.then((res) => {
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getAssetsPageTextByStatus(
									accountId,
									filterText,
									page,
									'recordings',
									recordingsStatus
								)
								.then((res) => {
									res = res.data.data;
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPageText(accountId, filterText, page, 'recordings')
							.then((res) => {
								res = res.data.data;
								setTotal(res.total);
								addAudioData(res.data);
							})
							.catch((error) => {
								console.info('Error', error);
							});
						break;
					default:
						break;
				}
				break;
			case false:
				switch (searchByStatus) {
					case true:
						if (['active', 'expired', 'expiring'].includes(recordingsStatus)) {
							getRepertoireDataByCiType(
								accountId,
								'recordings',
								recordingsStatus,
								page,
								filterText
							)
								.then((res) => {
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getRecordingsPageByStatus(accountId, recordingsStatus, page)
								.then((res) => {
									res = res.data;
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPage(accountId, 'recordings', page)
							.then((res) => {
								res = res.data.data;
								setTotal(res.total);
								addAudioData(res.data);
							})
							.catch((error) => {
								console.info('Error', error);
							});
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	};

	const {
		UIContext: { upload, isAccountFeatureRecordingView },
	} = props;

	const changeStatusFromStatsBlock = (selectedStatus) => {
		if (selectedStatus === '') {
			handleClear(
				filterSettings,
				setFilterSettings,
				setCurrentFilterSettings,
				setSelectFilters
			);
			return;
		}

		const item = filterSettings[0];
		const setting = item['list'].find((obj) => obj.code === selectedStatus);

		item.list.map((obj) =>
			obj.id === setting.id ? (obj.checked = true) : (obj.checked = false)
		);

		const newCurrentFilter = {};
		newCurrentFilter['' + item.id + setting.id] = {
			parentId: item.id,
			currentId: setting.id,
			id: '' + item.id + setting.id,
			title_en: item.title_en,
			title_ru: item.title_ru,
			name_en: setting.name_en,
			name_ru: setting.name_ru,
			code: setting.code,
			show: true,
		};

		setCurrentFilterSettings(newCurrentFilter);
		setSelectFilters(newCurrentFilter);
		setFilterSettings([item]);
	};

	async function addAudioData(phonograms) {
		let result = [];
		let count = 0;
		if (phonograms.length === 0) {
			setPhonogramItems([]);
			setLoading(false);
		} else
			await phonograms.forEach((item, index) => {
				accounts.getRecordingAudio(accountId, item.id).then((res) => {
					count += 1;
					res = res.data;
					item['audio'] = res;
					result.push(item);
					if (phonograms.length === count) {
						const sorted = result.sort((a, b) =>
							a.title.localeCompare(b.title)
						);
						setPhonogramItems(sorted);
						setLoading(false);
					}
				});
			});
	}

	const renderLine = (item, index, isClickable) => {
		return (
			<>
				<div
					className={
						showStatus(item.statuses) === 'disputed'
							? `${styles.repertoire__phonogramItemError} ${styles.adaptive__item}`
							: `${styles.adaptive__item}`
					}
				>
					<Container fluid>
						<Row>
							<Col xs={4}>
								<div>
									<Dynamic className={styles.adaptive__songItemLogo} />
								</div>
							</Col>
							<Col xs={8}>
								<div className={styles.adaptive__itemText}>
									<span>{item.title}</span>
									{item.title && <span>{item.title}</span>}
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs={4}>
								<div className={styles.adaptive__itemTitle}>
									<FormattedHTMLMessage id={'rod.for_all.performer'} />
								</div>
								<div className={styles.adaptive__itemTitle}>
									<FormattedHTMLMessage id={'rod.for_all.subtitle'} />
								</div>
								<div className={styles.adaptive__itemTitle}>
									<FormattedHTMLMessage id={'rod.for_all.status'} />
								</div>
							</Col>
							<Col xs={8}>
								<div className={styles.adaptive__itemText}>
									{item.performers.length ? (
										<>
											<span>{item.performers.join(', ')}</span>
											{item.performers && (
												<span>{item.performers.join(', ')}</span>
											)}
										</>
									) : (
										<span>-</span>
									)}
								</div>
								<div className={styles.adaptive__itemText}>
									{item.subtitle ? (
										<>
											<span>{item.subtitle}</span>
											{item.subtitle && <span>{item.subtitle}</span>}
										</>
									) : (
										<span>-</span>
									)}
								</div>
								<div className={styles.adaptive__itemText}>
									<span>
										<FormattedMessage
											id={`rod.repertoire.${showStatus(item.statuses)}`}
										/>
									</span>
									<span>
										<FormattedMessage
											id={`rod.repertoire.${showStatus(item.statuses)}`}
										/>
									</span>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				{/* ========================================== */}
				<div
					className={
						showStatus(item.statuses) ===
						'disputed' /*  ||
											item.status_title === 'not_ready' */
							? `${styles.repertoire__phonogramItemError} ${styles.song__table} ${styles.adaptive__songTable}`
							: `${styles.song__table} ${styles.adaptive__songTable}`
					}
				>
					<Dynamic className={styles.repertoire__phonogramItemLogo} />
					<div>
						<span>{item.title}</span>
						<span>{item.title}</span>
					</div>
					<div>
						{item.performers.length ? (
							<>
								<span>{item.performers.join(', ')}</span>
								<span>{item.performers.join(', ')}</span>
							</>
						) : (
							<span>-</span>
						)}
					</div>
					<div>
						{item.subtitle ? (
							<>
								<span>{item.subtitle}</span>
								<span>{item.subtitle}</span>
							</>
						) : (
							<span>-</span>
						)}
					</div>
					<div>
						<span>
							<FormattedMessage
								id={`rod.repertoire.${showStatus(item.statuses)}`}
							/>
						</span>
						<span>
							<FormattedMessage
								id={`rod.repertoire.${showStatus(item.statuses)}`}
							/>
						</span>
					</div>

					{item.audio.data.status === 'ready' ? (
						// <Link to={`${item.audio.data.preview}`}>
						<div className={styles.playBtn}>
							{/* <img
																	src={playActive}
																	alt=""
																	className={styles.repertoire__playLogo}
																/> */}
							<img
								data-tag={'play'}
								src={
									audioId === item.audio.data.id && play
										? stop_button
										: play_button
								}
								// className={s.play}
								className={styles.repertoire__playLogo}
								onClick={() => {
									// console.log(isSongReady);
									// isSongReady &&
									handlePlay(
										index,
										item.audio.data,
										item.title,
										item.performers
									);
									// (audioId !== item.audio.data.id || !play) &&
									// 	setIsSongReady(false);
								}}
								alt=""
							/>
						</div>
					) : (
						<div
							style={{
								padding: '0px 15px',
								marginLeft: 'auto',
								textOverflow: 'initial',
							}}
						>
							<img
								src={playInactive}
								alt=""
								className={styles.repertoire__playLogo}
								onClick={handlePlayStop}
							/>
						</div>
					)}
					{/* <div className={styles.eyeBtn}>
						{isClickable && (
							<img src={eye} alt="" className={styles.repertoire__playLogo} />
						)}
					</div> */}
				</div>
			</>
		);
	};

	return (
		<>
			<MainStatsCompositions
				data={statistics}
				changeStatus={changeStatusFromStatsBlock}
				parent={'recordings'}
			/>
			<div className={styles.inputWrapper}>
				<Input
					curRef={helpRef}
					handleOnChange={debouncedChangeHandler}
					setIsFocus={setIsFocus}
					setActive={modalOpen}
					filter={currentFilterSettings}
					parent={'releaseSection'}
				/>
				{Object.values(currentFilterSettings).some((i) => i.show === true) && (
					<>
						<ul className={styles.filter}>
							{Object.keys(currentFilterSettings).map(
								(item) =>
									currentFilterSettings[item].show && (
										<li
											className={styles.filter__item}
											key={currentFilterSettings[item].id}
											onClick={() =>
												handleFilterClear(
													currentFilterSettings[item],
													filterSettings,
													setFilterSettings,
													currentFilterSettings,
													setCurrentFilterSettings
												)
											}
										>
											<span>
												{lang === 'en'
													? `${currentFilterSettings[item].title_en} :
													${currentFilterSettings[item].name_en}`
													: `${currentFilterSettings[item].title_ru} :
													${currentFilterSettings[item].name_ru}`}
											</span>
											<img
												src={close}
												width={16}
												onClick={() =>
													handleFilterClear(
														currentFilterSettings[item],
														filterSettings,
														setFilterSettings,
														currentFilterSettings,
														setCurrentFilterSettings
													)
												}
												alt=""
											/>
										</li>
									)
							)}

							<li className={styles.filter__itemButton}>
								<button
									className={styles.btnAsLink}
									type="button"
									onClick={() =>
										handleClear(
											filterSettings,
											setFilterSettings,
											setCurrentFilterSettings,
											setSelectFilters
										)
									}
								>
									<FormattedMessage id={'rod.repertoire.clear_all'} />
								</button>
							</li>
						</ul>
					</>
				)}
			</div>
			{loading ? (
				<Loading className={styles.loader} />
			) : phonogramItems && phonogramItems.length ? (
				<div className={styles.repertoire__mainContent}>
					<div>
						<div
							className={`${styles.repertoire__phonogramTitle} ${styles.song__table} ${styles.adaptive__songTitle}`}
						>
							<div style={{ display: 'none' }}></div>
							<FormattedHTMLMessage id={'rod.for_all.title'} />
							<FormattedHTMLMessage id={'rod.for_all.performer'} />
							<FormattedHTMLMessage id={'rod.for_all.subtitle'} />
							<FormattedHTMLMessage id={'rod.for_all.status'} />
							<div style={{ display: 'hidden' }}></div>
							<div style={{ display: 'hidden' }}></div>
						</div>

						<ul className={styles.repertoire__phonogramItemsWrapper}>
							{phonogramItems.map(
								(item, index) => (
									<li className={styles.itemWrapper}>
										{/* ========================================== */}
										{isAccountFeatureRecordingView ? (
											// eslint-disable-next-line jsx-a11y/anchor-is-valid
											<a
												// to={`/repertoire/recordings/${item.id}`}
												onClick={(e) => {
													// console.log(e.target);
													// console.log(e.target.getAttribute('data-tag'));
													!e.target.getAttribute('data-tag') &&
														history.push(`/repertoire/recordings/${item.id}`);
												}}
											>
												{renderLine(item, index, true)}
											</a>
										) : (
											renderLine(item, index, false)
										)}
									</li>
								)
								// </a>
							)}
						</ul>
					</div>

					<div style={{ marginBottom: isPlayerShow ? '50px' : '0px' }}>
						<Pagination
							page={page === 'initial' ? 1 : page}
							total={total}
							paginate={(pageNumber) => setPage(pageNumber)}
						/>
					</div>
				</div>
			) : (
				<div className={styles.repertoire__mainContentEmpty}>
					<Dynamic />
					{upload.recordings && (
						<span>
							<FormattedMessage
								id={'rod.recordery.empty'}
								values={{
									// eslint-disable-next-line jsx-a11y/anchor-is-valid
									a: (msg) => <a onClick={() => {}}>{msg}</a>,
								}}
							/>
						</span>
					)}
				</div>
			)}
			<RepertoireFilterModal
				active={modal}
				setActive={modalOpen}
				filterData={filterSettings}
				setFilterData={handleChangeFilter}
				currentFilterSettings={currentFilterSettings}
				setCurrentFilter={handleCurrentFilter}
				selectFilters={selectFilters}
				setSelectFilters={setSelectFilters}
			/>
		</>
	);
}

export default compose(withUI, withAuth, withRoot)(RepertoirePhonogramSection);
