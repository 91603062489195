import { useState, useEffect } from 'react';
import s from './Loader.module.css';

const Loader = ({ uploadProgressPercents, width, height, padding }) => {
	const [circleStyles, setCircleStyles] = useState({
		strokeDasharray: '0, 150',
	});

	const CIRCLE_LENGTH = '150';

	useEffect(() => {
		let lineLength = {};
		if (!uploadProgressPercents) return;

		lineLength = Math.round(
			(uploadProgressPercents.value * CIRCLE_LENGTH) / 100
		);

		setCircleStyles({
			strokeDasharray: `${lineLength}, ${CIRCLE_LENGTH}`,
		});
	}, [uploadProgressPercents]);

	return (
		<div className={s.scroll} style={padding && { padding: `${padding}` }}>
			<svg
				className={s.scrollSvg}
				viewBox="-5 -5 128 128"
				width={width ? width : '64px'}
				height={height ? height : '64px'}
			>
				<path
					id="loaderBg"
					className={s.scrollSvgPathBackground}
					d="M24,0 a24,24 0 0,1 0,48 a24,24 0 0,1 0,-48"
				/>
				<path
					id="loader"
					style={circleStyles}
					className={s.scrollSvgPath}
					d="M24,0 a24,24 0 0,1 0,48 a24,24 0 0,1 0,-48"
				/>
			</svg>
		</div>
	);
};

export default Loader;
