/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 17/05/2020, 23:43
 */

import { Button } from 'components/Buttons/index';
import { Col, Container, Row } from 'components/Grid';
import { Label } from 'components/Labels/index';
import { Register as RegisterForm } from 'forms/index';
import { withAuth, withRoot } from 'hocs';
import React, { PureComponent } from 'react';
import { Hidden } from 'react-grid-system';
import { FormattedHTMLMessage } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { auth } from 'services';
import styles from './Registration.module.css';

class Registration extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);

		this.state = {
			isReady: false,
		};
	}

	checkInstanceAndRedirect = () => {
		if (!this.props.authContext.isRedirect) {
			this.setState({ isReady: true });
			return;
		}
		if (process.env.REACT_APP_CLIENT === 'hitmaker') {
			this.setState({ isReady: true });
		} else if (!this.props.authContext.isAllowLanguageDetection) {
			this.setState({ isReady: true });
		} else if (this.props.authContext.isAllowLanguageDetection) {
			auth.getCountryCode().then((res) => {
				const countryCode = res.data.data;

				switch (countryCode) {
					case 'RU':
						if (
							document.location.origin === process.env.REACT_APP_REDIRECT_URL_RU
						) {
							this.setState({ isReady: true });
						} else {
							window.location.assign(
								`${process.env.REACT_APP_REDIRECT_URL_RU}/registration`
							);
						}

						break;

					case 'BY':
						if (
							document.location.origin === process.env.REACT_APP_REDIRECT_URL_RU
						) {
							this.setState({ isReady: true });
						} else {
							window.location.assign(
								`${process.env.REACT_APP_REDIRECT_URL_RU}/registration`
							);
						}

						break;
					default:
						if (
							document.location.origin ===
							process.env.REACT_APP_REDIRECT_URL_COM
						) {
							this.setState({ isReady: true });
						} else {
							window.location.assign(
								`${process.env.REACT_APP_REDIRECT_URL_COM}/registration`
							);
						}

						break;
				}
			});
		}
	};

	componentDidMount() {
		this.checkInstanceAndRedirect();
	}

	render() {
		const {
			authContext: { isAuth, register, errors, isRegisterInProcess },
		} = this.props;

		if (isAuth) {
			return <Redirect to={'/'} />;
		}

		return (
			this.state.isReady && (
				<Container fluid className={styles.Page}>
					<Row className={styles.Page}>
						<Col xs={12} sm={6} md={5} className={styles.Login}>
							<Hidden xs>
								<div>
									<Label black className={'title'}>
										<FormattedHTMLMessage id={'rod.login.header'} />
									</Label>
									<Label black font="--gilroy-Medium-24">
										<FormattedHTMLMessage
											id={'rod.register.text.already_have_account'}
										/>
									</Label>
									<div className={'controls'}>
										<Button
											className={styles.loginBtn}
											onClick={() => {
												this.props.history.push('/login');
											}}
											text={<FormattedHTMLMessage id={'rod.action.login'} />}
										/>
									</div>
								</div>
							</Hidden>
						</Col>

						<Col xs={12} sm={6} md={7} className={styles.Register}>
							<RegisterForm
								onSubmit={register}
								errors={errors}
								isRegisterInProcess={isRegisterInProcess}
							/>
						</Col>
					</Row>
				</Container>
			)
		);
	}
}

export default compose(withRoot, withAuth, withRouter)(Registration);
