/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 23/09/2020, 15:24
 */
import { Label } from 'components/Labels';
import { withUI } from 'hocs';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import styles from './Unavailable.module.css';
import chevronUp from '../../../images/chevron_up.svg';
import chevronDown from '../../../images/chevron_down.svg';

class Unavailable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChevronOpen: false,
		};
	}

	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	handleOnClick = () => {
		!this.state.isChevronOpen
			? this.setState({ isChevronOpen: true })
			: this.setState({ isChevronOpen: false });
	};

	render() {
		const { err } = this.props;

		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.error_page.unavailable.header'} />
						</Label>
						<Label font="--gilroy-Medium-24" black>
							<FormattedMessage id={'rod.error_page.unavailable.body'} />
						</Label>
					</div>
					{err && (
						<>
							<div
								className={styles.detailsWrapper}
								onClick={this.handleOnClick}
							>
								<Label
									className={styles.detailsHeader}
									font="--gilroy-Medium-22"
									black
								>
									<FormattedMessage id={'rod.error_page.unavailable.datails'} />
								</Label>
								<img
									src={!this.state.isChevronOpen ? chevronUp : chevronDown}
									alt=""
								/>
							</div>
							{this.state.isChevronOpen && (
								<div className={styles.detailsText} font="--gilroy-Medium-20">
									{err}
								</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

export default compose(withUI)(Unavailable);
