/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */

import { getGlobalId } from 'App';
/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 9/12/19 12:36 PM
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { withForwardRef } from '../../../hocs';
import styles from './Checkbox.module.css';
import checkbox_addRole_disabled from 'images/checkbox_addRole_disabled.svg';

class Checkbox extends React.Component {
	static propTypes = {
		/**
		 * One or more button variant combinations
		 *
		 * buttons may be one of a variety of visual variants such as:
		 *
		 * `'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'`
		 *
		 * as well as "outline" versions (prefixed by 'outline-*')
		 *
		 * `'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'`
		 */
		variant: PropTypes.string,

		/** Manually set the visual state of the button to `:active` */
		active: PropTypes.bool,

		/**
		 * Disables the Button, preventing mouse events,
		 * even if the underlying component is an `<a>` element
		 */
		disabled: PropTypes.bool,

		/**
		 * Defines HTML button type attribute.
		 *
		 * @default 'button'
		 */
		type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

		/**
		 * Defines HTML button content.
		 */
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		checked: PropTypes.bool,
	};

	static defaultProps = {
		active: false,
		disabled: false,
	};

	static displayName = 'Checkbox';

	ref = React.createRef();

	render() {
		const {
			className,
			disabled,
			forwardRef,
			children,
			text,
			withFocusRef,
			rtl,
			active,
			forHeaders,
			hiddenCheckBox,
			isLocked,
			...props
		} = this.props;

		const classes = classNames(
			className,
			styles['Checkbox'],
			disabled && 'disabled',
			rtl && 'rtl',
			{ empty: !children && !text }
		);

		const id = getGlobalId('checkbox');

		if (forwardRef) {
			this.ref = forwardRef;
		}

		const checkboxClassName = forHeaders
			? hiddenCheckBox
				? 'hidden headermark'
				: 'mark headermark'
			: hiddenCheckBox
			? 'hidden'
			: 'mark';

		const labelClassName = disabled
			? `${checkboxClassName} disabled`
			: checkboxClassName;

		return !isLocked ? (
			<div className={classes}>
				<input
					{...props}
					id={id}
					type="checkbox"
					disabled={disabled}
					ref={this.ref}
				/>

				<label htmlFor={id} style={disabled ? { pointerEvents: 'none' } : {}}>
					<div className={labelClassName} />
					<div className="content">{children || text || ''}</div>
				</label>
			</div>
		) : (
			<div className={classes}>
				<div className={styles.checkbox_disabled_wrapper}>
					<img
						className={styles.checkbox_disabled}
						src={checkbox_addRole_disabled}
						alt=""
					/>
					<div className="content">{children || text || ''}</div>
				</div>
			</div>
		);
	}
}

export default compose(withForwardRef)(Checkbox);
