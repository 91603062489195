// Core
import { dateFormatEditor } from 'logic';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedHTMLMessage } from 'react-intl';

// Components
import { Loading, NoData } from 'components';
import download from 'images/download.svg';
import Banner from 'material-design/Repertoire/RepertoireCategory/RepertoireReleaseSection/Banner/Banner';
import { Pagination } from 'material-design/components';
import { RoyaltyBanner } from './RoyaltyBanner';

// Context
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';

// Styles
import styles from './UserReports.module.css';

const UserReportsTab = (props) => {
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [reports, setReports] = useState([]);
	const [royaltyData, setRoyaltyData] = useState({
		available_for_payment: false,
		data_currency: '',
		payout_button: false,
	});

	const [banner, setBanner] = useState(false);
	const [minimumOutput, setMinimumOutput] = useState('');

	const generateLink = () => {
		return `${window.location.origin}/profile/settings/private_data`;
	};

	const {
		getAccountReports,
		getAccountPaymentsInfo,
		aboutReportsDownloadData,
	} = useContext(RootContext);
	const { lang } = useContext(LangContext);

	const accountId = localStorage.getItem('accountId');

	useEffect(() => {
		const fetchUsePaymentData = async () => {
			const data = await getAccountPaymentsInfo(accountId);
			if (data) {
				setRoyaltyData(data);
				setBanner(!data.available_for_payment);

				if (data.minimum_output) {
					setMinimumOutput(data.minimum_output);
				}
			}
			setLoading(false);
		};
		setLoading(true);
		fetchUsePaymentData();
	}, []);

	useEffect(() => {
		const fetchPageData = async () => {
			getAccountReports(accountId, page).then((res) => {
				if (res.data.length) {
					setTotal(res.total);
					setReports(res.data);
				}
			});
		};
		fetchPageData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const getPaymentStatus = (status) => {
		let statusMessage = 'not_paid';
		switch (status) {
			case 'in_processing':
			case 'moderation':
			case 'signed':
			case 'signing':
			case 'new': {
				statusMessage = 'in_processing';
				break;
			}
			case 'paid': {
				statusMessage = 'paid';
				break;
			}
			default: {
				break;
			}
		}
		return (
			<FormattedHTMLMessage
				id={`rod.account.payment.status.${statusMessage}`}
			/>
		);
	};

	const delay = (milliseconds) =>
		new Promise((resolve) => {
			setTimeout(resolve, milliseconds);
		});

	const handleDownloadReport = async (report) => {
		aboutReportsDownloadData(accountId, report.report_archives);

		if (report.files.length) {
			report.files.forEach(async (item, index) => {
				await delay(index * 1000);
				window.open(item, '_parent');
			});
		}
	};

	return (
		<>
			<div className={styles.banner__wrapper}>
				{banner && (
					<Banner
						value={{ value: generateLink() }}
						setBanner={setBanner}
						title="rod.reports_page.banner"
					/>
				)}
			</div>
			<div className={styles.mainWrapper}>
				{loading ? (
					<Loading className={styles.loader} />
				) : (
					<>
						<RoyaltyBanner
							isDisabled={
								royaltyData.payout_button && royaltyData.available_for_payment
							}
							royaltyAmount={royaltyData.data_currency}
							paymentAvailable={royaltyData.available_for_payment}
							reports={reports}
							minimumOutput={minimumOutput}
						/>
						{reports.length ? (
							<>
								<div className={styles.RepertoireSongInfo__mainInfoTable}>
									<Row className={styles.row_header}>
										<Col width={'20%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.publishing_date'}
											/>
										</Col>
										<Col width={'35%'}>
											<FormattedHTMLMessage
												id={'rod.account.payment.payment_title'}
											/>
										</Col>
										<Col width={'15%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.sum'}
											/>
										</Col>
										<Col width={'15%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.status'}
											/>
										</Col>
										<Col width={'15%'}></Col>
									</Row>

                  <div className={styles.RepertoireMainInfoTable__body}>
                    <ul>
                      {reports.map((item, index) => (
                        <li className={styles.articlesListItem} key={index}>
                          <Row className={styles.row_data}>
                            <Row className={styles.row_title}>
                              <Col width={'20%'} className={styles.shell}>
                                {item.date_publication &&
                                  dateFormatEditor(
                                    item.date_publication,
                                    'dmy',
                                    '.'
                                  )}
                              </Col>
                              <Col width={'35%'} className={styles.shell}>
                                {lang === 'ru' ? item.heading : item.heading_en}
                              </Col>
                              <Col width={'15%'} className={styles.shell}>
                                {item.royalties
                                  ? `${item.royalties.replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    `$1\u00A0`
                                  )} ${item.currency}`
                                  : '-'}
                              </Col>
                              <Col width={'15%'} className={styles.shell}>
                                {
                                  getPaymentStatus(item.status_payment)
                                }
                              </Col>
                              <Col width={'15%'}>
                                <div
                                  className={styles.action}
                                  onClick={() => handleDownloadReport(item)}
                                >
                                  <img
                                    src={download}
                                    alt=""
                                  />
                                  <div
                                    className={styles.actionTitle}
                                  >
                                    <FormattedHTMLMessage
                                      id={'rod.action.download'}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
        {total > 10 && !loading && (
          <Pagination
            page={page}
            total={total}
            paginate={(pageNumber) => {
              setPage(pageNumber);
            }}
            items_on_page={10}
          />
        )}
      </div>
    </>
  );
};

export default UserReportsTab;
