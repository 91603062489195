// Core
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Services
import { accounts } from 'services';
import { withUI, withRoot } from 'hocs';
import { compose } from 'recompose';

// UI
import { BottomNavi } from 'components';
import { AddRoleContractsBanner } from 'material-design/components/Banners/AddRoleContractsBanner';

// Icons
import nota from 'images/nota.svg';
import nota_in_square from 'images/nota_in_square.svg';

// Styles
import styles from './Review.module.css';

const Review = (props) => {
	const [data, setData] = useState([]);
	const [languages, setLanguages] = useState();
	const [compositionTypes, setCompositionTypes] = useState();
	const [moderationContract, setModerationContract] = useState(true);
	const [contractType, setContractType] = useState([]);

	const match = useRouteMatch();
	const compositionId = match.params.id;

	const {
		UIContext: { showBackNavi, hideBackNavi, isShowServicesRepertoireBanner },
		rootContext: {
			getCompositionData,
			getCountryById,
			getCompositionTypes,
			postAdditionalServices,
		},
	} = props;

	const lang = localStorage.getItem('lang');
	const accountId = localStorage.getItem('accountId');

	//puplishing - издательский, sub-puplishing - субиздательский

	useEffect(() => {
		showBackNavi(null, backHandler);

		getCompositionTypes().then((res) => {
			if (res) {
				setCompositionTypes(res.data);
			}
		});

		accounts
			.getLanguages()
			.then((res) => {
				res = res.data.data;
				setLanguages(res);
			})
			.catch((error) => {
				console.info('getLanguages - Error', error);
			});

		if (compositionId) {
			getCompositionData(compositionId).then((res) => {
				if (res) {
					setModerationContract(res.moderation_contract);
					setContractType(res.type_contract);
					setData(res.data);
				}
			});
		}
		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			props.handlePrevStep();
		}
	};

	const nextHandler = () => {
		if (typeof props.onChange === 'function') {
			props.onChange();
		}
	};

	return (
		<>
			<div className={styles.Review__Wrapper}>
				<div className={styles.Review__Title_wrapper}>
					<img src={nota} width="24px" height="24px" alt="" />
					<span className={styles.Review__Title_name}>
						<FormattedMessage id={'rod.composition'} />
					</span>
					<span className={styles.Review__Title_song_name}>
						{data.title && data.title}
					</span>
				</div>
				<div className={styles.Review__Main_wrapper}>
					{isShowServicesRepertoireBanner &&
						contractType &&
						((!moderationContract && contractType.length > 0) ||
							contractType.length > 0) && (
							<div className={styles.addRoleContractsBanner}>
								<AddRoleContractsBanner
									data={{
										title: (
											<FormattedMessage
												id={
													'rod.account.add_role_banner_composition_not_available_title'
												}
											/>
										),
										content: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.add_role_banner_composition_not_available_moderation'
														: 'rod.account.add_role_banner_composition_has_not_contracts'
												}
											/>
										),
										btnText: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.save_to_draft_btn'
														: contractType.length > 1
														? 'rod.account.choose_few_services_btn'
														: 'rod.account.choose_service_btn'
												}
											/>
										),

										data: ['lyrics', 'author'],
										postAdditionalServices,
										id: accountId,
										action: !moderationContract ? 'to draft' : 'sign services',
									}}
								/>
							</div>
						)}
					<div className={styles.Review__Information_wrapper}>
						<span className={styles.Review__Section_title}>
							<FormattedMessage
								id={'rod.release.create.step.submit.body_title'}
							/>
						</span>
						<div className={styles.Review__Information_table_row}>
							<div className={styles.Review__Information_table_cell}>
								<span>
									<FormattedMessage id={'rod.composition.info.creation_date'} />
								</span>

								<span>
									{data.created_date ? data.created_date.slice(0, 10) : '-'}
								</span>
							</div>
							<div className={styles.Review__Information_table_cell}>
								<span>ISWC</span>
								<span>{data.iswc ? data.iswc : '-'}</span>
							</div>
							<div className={styles.Review__Information_table_cell}>
								<span>
									<FormattedMessage
										id={'rod.composition.info.catalog_number'}
									/>
								</span>

								<span>
									{data.catalogue_number ? data.catalogue_number : '-'}
								</span>
							</div>
							<div className={styles.Review__Information_table_cell}>
								<span>
									<FormattedMessage
										id={'rod.composition.info.lyrics_language'}
									/>
								</span>
								<span>
									{languages && data.language
										? languages.find((lan) => data.language === lan.id)[
												lang === 'ru' ? 'title_ru' : 'title_en'
										  ]
										: '-'}
								</span>
							</div>
						</div>
						<div className={styles.Review__Information_table_row}>
							<div className={styles.Review__Information_table_cell}>
								<span>
									<FormattedMessage id={'rod.composition.info.genres'} />
								</span>
								<span>
									{Array.isArray(data.genres) && data.genres.length > 0
										? data.genres.join(', ')
										: '-'}
								</span>
							</div>
							<div className={styles.Review__Information_table_cell}>
								<span>
									<FormattedMessage
										id={'rod.composition.info.creation_territory'}
									/>
								</span>
								<span>
									{data.created_country_id
										? getCountryById(data.created_country_id)
										: '-'}
								</span>
							</div>
							<div className={styles.Review__Information_table_cell}>
								<span>
									<FormattedMessage
										id={'rod.composition.info.composition_type'}
									/>
								</span>
								<span>
									{compositionTypes && data.composition_type_id
										? compositionTypes.find(
												(type) => parseInt(data.composition_type_id) === type.id
										  )[lang === 'ru' ? 'title_ru' : 'title_en']
										: '-'}
								</span>
							</div>
							<div className={styles.Review__Information_table_cell}></div>
						</div>
					</div>
					<div className={styles.Review__Recordings_wrapper}>
						<span className={styles.Review__Section_title}>
							<FormattedMessage id={'rod.main_page.phonograms'} />
						</span>
						<div className={styles.Review__Recordings_table_row}>
							<div className={styles.Review__Recordings_table_title_cell}>
								<span>
									<FormattedMessage id={'rod.for_all.title'} />
								</span>
							</div>
							<div className={styles.Review__Recordings_table_title_cell}>
								<span>
									<FormattedMessage id={'rod.for_all.performer'} />
								</span>
							</div>
							<div className={styles.Review__Recordings_table_title_cell}>
								<span>
									<FormattedMessage id={'rod.composition.recordery.Genre'} />
								</span>
							</div>
							<div
								className={`${styles.Review__Recordings_table_title_cell} ${styles.Text_uppercase}`}
							>
								<span>isrc</span>
							</div>
						</div>
						{data &&
							data.recordings &&
							data.recordings.map((recording) => {
								return (
									<div
										key={recording.id}
										className={`${styles.Review__Recordings_table_row} ${styles.Background_white}`}
									>
										<div className={styles.Review__Recordings_table_data_cell}>
											<img
												src={nota_in_square}
												width="24px"
												height="24px"
												alt=""
											/>
											<span>{recording.title ? recording.title : '-'}</span>
										</div>
										<div className={styles.Review__Recordings_table_data_cell}>
											<span>
												{recording.main_performer
													? recording.main_performer
													: '-'}
											</span>
										</div>
										<div className={styles.Review__Recordings_table_data_cell}>
											<span>
												{Array.isArray(recording.genres) &&
												recording.genres?.length > 0
													? recording.genres.join(', ')
													: '-'}
											</span>
										</div>
										<div className={styles.Review__Recordings_table_data_cell}>
											<span>{recording.isrc ? recording.isrc : '-'}</span>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={
					isShowServicesRepertoireBanner &&
					contractType &&
					((!moderationContract && contractType?.length > 0) ||
						contractType?.length > 0)
				}
				back={backHandler}
				next={nextHandler}
				nextText="rod.release.create.step.submit.submit"
			/>
		</>
	);
};

export default compose(withRoot, withUI)(Review);
