import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuth, withUI, withRoot } from 'hocs';

import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';

import styles from './RepertoireComposition.module.css';

import { styled } from '@mui/material/styles';

import {
	RepertoireCompositionInfo,
	RepertoireCompositionRecording,
	RepertoireCompositionReleases,
	RepertoireCompositionNoReady,
} from './RepertoireCompositionTab';

import nota from 'images/nota.svg';

import { FormattedMessage } from 'react-intl';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: '#f0f0f0',
	font: 'var(--gilroy-Bold-18)',
	padding: '25px 36px',
	border: 'none',
	'&:first-child': {
		paddingLeft: '30px',
		paddingRight: '35px',
	},
	'&:nth-child(2)': {
		paddingLeft: '35px',
	},
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		textDecoration: 'line-through',
	},
}));

function RepertoireComposition(props) {
	const [compositionMetadata, setCompositionMetadata] = useState(false);
	const [defaultTab, setDefaultTab] = useState('');

	const match = useRouteMatch();
	const {
		UIContext: {
			showBackNavi,
			setPrevRepertoireParams,
			changeBackTargetUrl,
			hideBackNavi,
			isAccountFeatureRecordingView,
		},
		rootContext: { getCompositionMetadata },
		authContext: { accountId },
	} = props;

	const history = useHistory();
	const compositionId = match.params.id;

	useEffect(() => {
		getCompositionMetadata(accountId, compositionId).then((res) => {
			setCompositionMetadata(res.data);
		});
		if (match.path.includes('details')) {
			setDefaultTab(0);
		} else if (match.path.includes('recordings')) {
			setDefaultTab(1);
		} else if (match.path.includes('releases')) {
			setDefaultTab(2);
		} else if (match.path.includes('notices')) {
			setDefaultTab(3);
		} else {
			setDefaultTab(0);
		}

		changeBackTargetUrl('/repertoire/compositions');
		showBackNavi('/repertoire/compositions', null, 'rod.action.back_to_list');

		return function cleanup() {
			hideBackNavi();
			setPrevRepertoireParams({ page: 1 });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getLink = (name) => {
		switch (name) {
			case 'details':
				return history.push(
					`/repertoire/compositions/${compositionId}/details`
				);
			case 'recordings':
				return history.push(
					`/repertoire/compositions/${compositionId}/recordings`
				);
			case 'releases':
				return history.push(
					`/repertoire/compositions/${compositionId}/releases`
				);
			case 'notices':
				return history.push(
					`/repertoire/compositions/${compositionId}/notices`
				);

			default:
				history.push(`/repertoire/compositions/${compositionId}/details`);
				break;
		}
	};

	return (
		<>
			{defaultTab !== '' && (
				<TabsUnstyled
					defaultValue={defaultTab}
					className={styles.repertoireSong}
				>
					<div className={styles.repertoireSong__titleWrapper}>
						<img
							className={styles.repertoireSong__logo}
							src={nota}
							width="32px"
							height="32px"
							alt=""
						/>
						<span className={styles.repertoireSong__title}>
							{compositionMetadata && compositionMetadata.title
								? compositionMetadata.title
								: '-'}
						</span>
						<div className={styles.repertoireSong__iswc}>
							<span>ISWC: </span>
							<span>
								{compositionMetadata && compositionMetadata.iswc
									? compositionMetadata.iswc
									: '-'}
							</span>
						</div>
					</div>
					<TabsListUnstyled className={styles.repertoireSong__header}>
						<HeaderButton
							onClick={() => getLink('details')}
							className={styles.headerButton}
						>
							<FormattedMessage
								id={'rod.composition.tab.main'}
							></FormattedMessage>
						</HeaderButton>
						{isAccountFeatureRecordingView && (
							<HeaderButton
								onClick={() => getLink('recordings')}
								className={styles.headerButton}
							>
								<FormattedMessage
									id={'rod.composition.tab.recordery'}
								></FormattedMessage>
							</HeaderButton>
						)}
						<HeaderButton
							onClick={() => getLink('releases')}
							className={styles.headerButton}
						>
							<FormattedMessage
								id={'rod.composition.tab.releases'}
							></FormattedMessage>
						</HeaderButton>
						{compositionMetadata &&
							!compositionMetadata[`statuses`].includes('ready') && (
								<HeaderButton
									onClick={() => getLink('notices')}
									className={styles.headerButton}
								>
									<FormattedMessage
										id={'rod.composition.tab.not_ready'}
									></FormattedMessage>
								</HeaderButton>
							)}
					</TabsListUnstyled>
					<div className={styles.repertoireSong__main}>
						<TabPanelUnstyled value={0}>
							<RepertoireCompositionInfo />
						</TabPanelUnstyled>
						{/* <TabPanelUnstyled value={1}>
					<RepertoireCompositionContracts />
				</TabPanelUnstyled> */}
						{isAccountFeatureRecordingView && (
							<TabPanelUnstyled value={1}>
								<RepertoireCompositionRecording />
							</TabPanelUnstyled>
						)}
						<TabPanelUnstyled value={2}>
							<RepertoireCompositionReleases />
						</TabPanelUnstyled>
						{compositionMetadata &&
							!compositionMetadata[`statuses`].includes('ready') && (
								<TabPanelUnstyled value={3}>
									<RepertoireCompositionNoReady />
								</TabPanelUnstyled>
							)}
					</div>
				</TabsUnstyled>
			)}
		</>
	);
}
export default compose(withAuth, withUI, withRoot)(RepertoireComposition);
