/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 12:37
 */

import { Loading, Wizard } from 'components';
import { Button } from 'components/Buttons';
import { ContractListItem } from 'components/ContractListItem';
import { Label } from 'components/Labels/Label';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import styles from 'pages/contracts/AddContract/steps/06-Complete/Complete.module.css';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

class Complete extends PureComponent {
	constructor(props) {
		super(props);
		this.steps = [
			{
				id: 1,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_3_1.title'} />,
			},
			{
				id: 2,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_3_2.title'} />,
			},
		];

		this.state = {
			loading: true,
			data: {},
		};
	}

	componentDidMount() {
		const {
			rootContext: { getContracts, getAdditionalContracts },
		} = this.props;
		if (this.props.isAdditional) {
			getAdditionalContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
				if (
					!this.props.sms &&
					!this.props.showSigned &&
					this.props.completionStep !==
						accountCompletionSteps.PENDING_CONTRACT &&
					!this.props.isAdditional
				) {
					this.submit();
				}
			});
		} else {
			getContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
				if (
					!this.props.sms &&
					!this.props.showSigned &&
					this.props.completionStep !== accountCompletionSteps.PENDING_CONTRACT
				) {
					this.submit();
				}
			});
		}
	}

	submit = () => {
		if (typeof this.props.onSubmit === 'function') {
			this.props.onSubmit(this.state.data);
		}
	};

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		const { sms, showSigned } = this.props;
		const { data } = this.state;

		return (
			<Container fluid className={styles.Page}>
				<Wizard steps={this.steps} className={styles.Wizard} />
				<div className={styles.Form}>
					<Row>
						<Col sm={10} offset={{ sm: 1 }} className={styles.Header}>
							<Label
								text={
									<FormattedHTMLMessage id={'rod.contracts.complete.header'} />
								}
								font="--gilroy-Medium-40"
								black
							/>
							{sms ? (
								<Label
									className="sub"
									text={
										<FormattedHTMLMessage
											id={'rod.contracts.complete.subheader_sms'}
										/>
									}
									font="--gilroy-Medium-24"
									black
								/>
							) : (
								<React.Fragment>
									{showSigned ? (
										<Label
											className="sub"
											text={
												<FormattedHTMLMessage
													id={'rod.contracts.complete_signed.subheader'}
												/>
											}
											font="--gilroy-Medium-24"
											black
										/>
									) : (
										<Label
											className="sub"
											text={
												<FormattedHTMLMessage
													id={'rod.contracts.complete.subheader'}
												/>
											}
											font="--gilroy-Medium-24"
											black
										/>
									)}
								</React.Fragment>
							)}

							<ul className={styles.List}>
								{data.map((item, index) => (
									<>
										<li>
											<ContractListItem
												{...item}
												index={index}
												showDownloadSigned={sms || this.props.showSigned}
												showDate
												date={item.signed_at || Date.now()}
												showHash
												hash={item.hash}
											/>
										</li>
									</>
								))}
							</ul>
							<div className={styles.Controls}>
								{(sms || showSigned) && (
									<Button
										text={
											<FormattedMessage id={'rod.action.goto_repertoire'} />
										}
										variant={'primary'}
										onClick={this.submit}
										disabled={this.props.isCompleteSigningInProcess}
									/>
								)}
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		);
	}
}

Complete.propTypes = {
	onSubmit: PropTypes.func,
};

Complete.defaultProps = {};

export default compose(withUI, withRoot)(Complete);
