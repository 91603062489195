import React from 'react';

import {
	TextFieldInput,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px  8px',
					'&:hover': {
						backgroundColor: 'var(--color-light-grey) !important',
					},
				},
			},
			'& 	.MuiAutocomplete-popupIndicator': {
				backgroundColor: 'var(--color-green)',
				color: 'white',
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const wholeWorld = {
	id: 17,
	code: 'WW',
	title_ru: 'Весь Мир',
	title_en: 'Worldwide',
	dialing_code: 0,
};

const MuiSelect = ({
	onChange,
	label,
	items,
	value,
	required,
	isNotSelectedRowExists,
	languages,
	errors,
}) => {
	const lang = localStorage.getItem('lang');

	if (lang === 'ru') {
		items = items.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
	} else {
		items = items.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
	}

	if (items.find((i) => i.id === 17) && !languages) {
		items = items.filter((i) => i.id !== 17);
		items = [wholeWorld, ...items];
	}

	if (isNotSelectedRowExists) {
		const notSelected = {
			id: -1,
			code: 'EMPTY',
			title_ru: 'Не выбрано',
			title_en: 'Not selected',
		};
		items = [notSelected, ...items];
	}

	if (value) {
		for (let j = 0; j < items.length; j++) {
			if (value === items[j].id) {
				value = {
					id: items[j].id,
					title_ru: items[j].title_ru,
					title_en: items[j].title_en,
				};
			}
		}
	}

	const filterOptions = (_, state) => {
		let newOptions = [];
		if (state.inputValue) {
			items.forEach((item) => {
				const word = lang === 'ru' ? item.title_ru : item.title_en;
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				)
					newOptions.push(item);
			});

			return newOptions;
		} else {
			return items;
		}
	};

	const isItemSelected = (selectedItem) => {
		if (
			(!selectedItem.id || !value.id) &&
			(selectedItem.title_ru === value.title_ru ||
				selectedItem.title_en === value.title_en)
		) {
			return true;
		}
		if (
			Array.isArray(value) &&
			value.some((element) => element.id && element.id === selectedItem.id)
		)
			return true;
		else if (selectedItem.id && value.id && selectedItem.id === value.id)
			return true;
		return false;
	};

	return (
		<AutocompleteInput
			id="disable-clearable"
			disableClearable
			onChange={(_, obj) => onChange(obj)}
			options={items}
			value={value}
			getOptionLabel={(option) => {
				return (lang === 'ru' ? option.title_ru : option.title_en) || '';
			}}
			isOptionEqualToValue={(option, value) =>
				lang === 'ru'
					? option.title_ru === value.title_ru
					: option.title_en === value.title_en
			}
			renderInput={(params) => (
				<TextFieldInput
					{...params}
					label={label}
					variant="standard"
					required={required}
					error={
						errors &&
						(typeof errors === 'string' ||
							typeof errors === 'boolean' ||
							(Array.isArray(errors) && errors.length > 0) ||
							(typeof errors === 'object' && Object.keys(errors).length > 0))
					}
				/>
			)}
			renderOption={(option, item) => {
				option = { ...option, key: item.id };
				return (
					<li
						key={item.id}
						{...option}
						style={{
							backgroundColor: isItemSelected(item)
								? 'var(--color-green)'
								: 'var(--color-white)',
							color: isItemSelected(item, value)
								? 'var(--color-white)'
								: 'unset',
						}}
					>
						{lang === 'ru' ? item.title_ru : item.title_en}
					</li>
				);
			}}
			PopperComponent={CustomPopper}
			filterOptions={filterOptions}
		/>
	);
};

export default MuiSelect;
