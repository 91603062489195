// Helpers
import {
	companyChecksWithItin,
	companyChecksWithoutItin,
	personalChecksWithItin,
	personalChecksWithoutItin,
} from './personalChecks';

// Types
import accountTypes from 'constants/accountTypes';

export const isAllRequiredFilledPersonal = (data) => {
	switch (data.business_type) {
		case accountTypes.PERSONAL:
			if (
				window.location.href.includes('.ru') ||
				window.location.href.includes('broma16ru-stage')
			) {
				return personalChecksWithItin(data) ? true : false;
			} else {
				return personalChecksWithoutItin(data) ? true : false;
			}
		case accountTypes.COMPANY:
			if (
				window.location.href.includes('.ru') ||
				window.location.href.includes('broma16ru-stage')
			) {
				return companyChecksWithItin(data) ? true : false;
			} else {
				return companyChecksWithoutItin(data) ? true : false;
			}
		case accountTypes.PERSONAL_ENTREPRENEUR:
			if (data.first_name) return true;
			else return false;
		default:
			return false;
	}
};
