import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useState, useEffect } from 'react';
import styles from './NoData.module.css';

let NoData = function (props) {
	const [topPosLoader, setTopPosLoader] = useState();
	const { textId, non_margin, textIdNext } = props;

	useEffect(() => {
		const data = document
			.getElementById('nodata_ancor')
			.getBoundingClientRect();
		const topPos = data ? data.top : 0;
		setTopPosLoader(Math.floor((window.innerHeight - topPos) / 2) - 20);
	}, []);

	return (
		<>
			{!non_margin ? (
				<>
					<div
						className={styles.noData}
						id="nodata_ancor"
						style={{
							marginTop: `${topPosLoader}px`,
						}}
					>
						{topPosLoader > 0 && (
							<>
								<div style={textIdNext && { fontWeight: 700 }}>
									<FormattedMessage
										id={textId ? textId : 'rod.admin.no-data'}
									/>
								</div>
								{textIdNext && (
									<div>
										<FormattedMessage id={textIdNext} />
									</div>
								)}
							</>
						)}
					</div>
				</>
			) : (
				<div className={styles.noData} id="nodata_ancor">
					<div style={textIdNext && { fontWeight: 700 }}>
						<FormattedMessage id={textId ? textId : 'rod.admin.no-data'} />
					</div>
					{textIdNext && (
						<div>
							<FormattedMessage id={textIdNext} />
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default NoData;
