// Core
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import lscache from 'lscache';
import { Col, Row } from 'react-grid-system';

// Helpers
import { formatDate } from 'helpers';
import { getDraftPerformers } from 'helpers';
import { compose } from 'recompose';
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { Loading, NoData } from 'components';
import TableHeader from './TableHeader/TableHeader';
import { Pagination } from 'material-design/components';
import { withLang, withUI, withRoot } from 'hocs';

// Icons
import remove from 'images/remove.svg';
import nota_in_square from 'images/nota_in_square.svg';

// Types
import modalTypes from 'constants/modalTypes';

// Styles
import styles from './HandlingDrafts.module.css';

const HandlingDrafts = (props) => {
	const [drafts, setDrafts] = useState('');
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noData, setNoData] = useState(false);
	const [isReqLive] = useState(false);
	const [releaseTypes, setReleaseTypes] = useState([]);
	const [compositionTypes, setCompositionTypes] = useState([]);

	const {
		UIContext: { showModal },
		langContext: { lang },
		rootContext: {
			getUnprocessedDrafts,
			getCompositionTypes,
			getReleaseTypes,
			deleteDraft,
		},
	} = props;

	useEffect(() => {
		getCompositionTypes().then((res) => {
			setCompositionTypes(res.data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoading(true);
		handleReleaseTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		if (releaseTypes.length && compositionTypes.length) {
			getDrafts(page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, releaseTypes, compositionTypes]);

	const handleReleaseTypes = () => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			setReleaseTypes(releaseTypesCache.data);
		} else {
			return getReleaseTypes(lang).then((res) => {
				setCache('releaseTypes', 'releaseTypesCache', res);
				setReleaseTypes(res);
			});
		}
	};

	const addTranslation = (data) => {
		const result = data.map((item) => {
			const titlesData =
				item.draft_type === 'composition'
					? compositionTypes.find((type) => type.id === +item.type_id)
					: releaseTypes.find((type) => type.id === +item.type_id);
			item['typeColumn'] = {
				title_ru:
					item.draft_type === 'composition'
						? titlesData.title_ru
						: titlesData.title,
				title_en:
					item.draft_type === 'composition'
						? titlesData.title_en
						: titlesData.title,
			};
			return item;
		});
		return result;
	};

	const getDrafts = (page) => {
		if (!loading) {
			setLoading(true);
		}

		setLoading(false);
		getUnprocessedDrafts(page)
			.then((res) => {
				if (res.data.length) {
					setTotal(res.total);
					setNoData(false);
					setDrafts(addTranslation(res.data));
				} else {
					setNoData(true);
				}
			})
			.catch((err) => console.info('Error:', err))
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRemoveDraft = (draft) => {
		deleteDraft(draft.draft_type, draft.id)
			.then((res) => {
				getDrafts(page);
			})
			.catch((err) => {
				console.info('Error:', err);
			});
	};

	const isUnknownStep = (step) => {
		const allKnownStatuses = [
			'moderation',
			'completed',
			'parsed',
			'check',
			'update',
			'release',
			'file',
			'tracks',
			'authors',
			'lyrics',
			'cover',
			'distribution',
			'confirm',
			'info',
			'authors_shares',
			'composition_recordings',
		];
		return !allKnownStatuses.includes(step);
	};

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.sectionTitleWrapper}>
				<div className={styles.sectionTitle}>
					<FormattedHTMLMessage id={'rod.admin.handling.drafts_title'} />
				</div>
			</div>
			{loading ? (
				<Loading className={styles.loader} />
			) : noData ? (
				<div>
					<NoData />
				</div>
			) : (
				<>
					<div className={styles.TableWrapper}>
						<TableHeader />
						<div className={styles.TableBody}>
							<ul>
								{drafts &&
									drafts.map((item, index) => (
										<li className={styles.TableItemWrapper}>
											<Row className={styles.row_data}>
												<Row className={styles.row_title}>
													<Col width={'5%'}>
														<img src={nota_in_square} alt="" />
													</Col>
													<Col
														width={'20%'}
														style={{ overflowWrap: 'break-word' }}
													>
														{item.title}
													</Col>
													<Col width={'20%'}>{getDraftPerformers(item)}</Col>
													<Col width={'15%'}>
														{
															item.typeColumn[
																lang === 'ru' ? 'title_ru' : 'title_en'
															]
														}
													</Col>
													<Col width={'15%'}>
														<FormattedMessage
															id={`rod.draft.release.${
																isUnknownStep(item.step)
																	? 'unknownstatus'
																	: item.step
															}`}
														/>
													</Col>
													<Col width={'20%'}>{formatDate(item.updated_at)}</Col>
													<Col width={'5%'}>
														<img
															src={remove}
															alt=""
															onClick={showModal(
																{
																	title: (
																		<FormattedMessage
																			id={
																				'rod.admin.handling.drafts_delete_title'
																			}
																		/>
																	),
																	text: (
																		<FormattedMessage
																			id={
																				'rod.admin.handling.drafts_delete_text'
																			}
																		/>
																	),
																	onAction: () => {
																		handleRemoveDraft(item);
																	},
																	confirmBtnTxt: (
																		<FormattedMessage
																			id={'rod.modal.yes_delete'}
																		/>
																	),
																	declineBtnTxt: (
																		<FormattedMessage id={'rod.modal.cancel'} />
																	),
																},
																modalTypes.FULL_MODAL
															)}
															className={styles.DeleteBtn}
														/>
													</Col>
												</Row>
											</Row>
										</li>
									))}
							</ul>
						</div>
					</div>

					{total > 10 && !loading && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => {
								setPage(pageNumber);
							}}
							items_on_page={10}
						/>
					)}
				</>
			)}
		</div>
	);
};
export default compose(withUI, withLang, withRoot)(HandlingDrafts);
