// Core
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { partner } from 'messages/EN/pages_branding';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { accounts } from 'services';

// Context
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Constants
import ReleaseCompletionSteps from 'material-design/constants/releaseCompletionSteps';

// UI
import Greetings from './Greetings/Greetings';
import Statistics from './Statistics/Statistics';
import ButtonAdd from './ButtonAdd/ButtonAdd';
import AddReleaseModal from 'containers/Modal/AddReleaseModal/AddReleaseModal';

// Icons
import releaseSvg from 'images/release_disc.svg';
import nota from 'images/nota_white.svg';
import dynamic from 'images/dynamic_white.svg';

// Styles
import s from './MainPage.module.css';

const MainPage = (props) => {
	let data = {};
	process.env.REACT_APP_CLIENT === 'hitmaker'
		? (data = {
				title: <FormattedMessage id={'rod.repertoire.welcome.title'} />,
				text: (
					<FormattedHTMLMessage
						id={
							process.env.REACT_APP_CLIENT === 'dgtal'
								? 'rod.main_page.greetings.body.dgtal'
								: 'rod.main_page.greetings.body'
						}
					/>
				),
		  })
		: (data = {
				title: <FormattedMessage id={'rod.repertoire.welcome.title'} />,
				text: (
					<FormattedHTMLMessage
						id={
							process.env.REACT_APP_CLIENT === 'dgtal'
								? 'rod.main_page.greetings.body.dgtal'
								: 'rod.main_page.greetings.body'
						}
					/>
				),
		  });

	const [modal, setModal] = useState(false);
	const [show, setShow] = useState(true);
	const [assetStatistics, setAssetStatistics] = useState({});
	const [downloadProgressPercents] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const { getAccountPersonalData, getAccountContactsData } = useContext(
		RootContext
	);
	const { setIsTransferRelease, notTransferReleaseFlag } = useContext(
		UIContext
	);

	let { params } = useRouteMatch();
	let { push } = useHistory();

	const { pathname } = useLocation();

	const onBtnClick = () => {
		setShow(false);
	};

	const createNewReleaseHandler = () => {
		setModal(false);
		localStorage.removeItem('releaseId');
		localStorage.removeItem('isTransferRelease');
		setIsTransferRelease(false);
		push('/release/create');
	};

	const transferReleaseHandler = () => {
		setModal(false);
		localStorage.removeItem('releaseId');
		localStorage.setItem('isTransferRelease', true);
		setIsTransferRelease(true);
		push('/release/create');
	};

	const clickToCreateComposition = () => {
		localStorage.removeItem('compositionId');
	};

	const {
		UIContext: { showTitle, hideTitle, changeBackTargetUrl, hideBackNavi },
		authContext: { accountId, user_completion_step },
	} = props;

	const {
		authContext: {
			getAccountCompletionStep,
			contractsExpiredRedirect,
			setIsNoContract,
		},
	} = props;

	const isNoContract = localStorage.getItem('no_contract');

	useEffect(() => {
		changeBackTargetUrl('/');
		hideBackNavi();
		if (user_completion_step !== 'account_legacy') {
			setIsLoading(true);
			accounts
				.getAssetStatistics(accountId)
				.then((res) => {
					setAssetStatistics(res.data.data);
					setIsLoading(false);
				})
				.catch((err) => {
					console.error(
						'MainPage - getAssetStatistics - api- error: ',
						err.response
					);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}

		showTitle('rod.navbar.main');

		getAccountPersonalData(params.id)
			.then((account) => {
				if (account.status === 207 || account.status === 205) {
					return contractsExpiredRedirect(account.status);
				} else if (isNoContract) {
					setIsNoContract();
				}
				account = account.data.data;
				if (!props.authContext.isJoin) {
					getAccountCompletionStep(account.completion_step);
				}
			})
			.catch((err) => {
				console.error(
					'mainPage - getAccountPersonalData  api- error.response',
					err.response
				);
				console.error('mainPage - getAccountPersonalData  api- error', err);
				push('/accounts');
			});

		getAccountContactsData(params.id)
			.then((res) => {
				res = res.data.data;
			})
			.catch((err) => push('/accounts'));

		return function cleanup() {
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		UIContext: { upload, isAddCompositionEnabled, isReleaseCreateDisabled },
	} = props;

	return (
		<>
			{modal && (
				<AddReleaseModal
					title={<FormattedMessage id={'rod.btn.add_release'} />}
					subtitle={
						<FormattedMessage
							id={'rod.release.create.step.distributions.description.transfer'}
						/>
					}
					newReleaseButtonLabel={<FormattedMessage id={'rod.new_release'} />}
					transferReleaseButtonLabel={
						<FormattedMessage id={'rod.transfer_release'} />
					}
					newReleaseButtonHandler={createNewReleaseHandler}
					transferReleaseButtonHandler={transferReleaseHandler}
					onClose={() => setModal(false)}
				/>
			)}
			<Helmet>
				<title>{partner.title_rod_company}</title>
			</Helmet>
			<div className={s.container}>
				{show && <Greetings data={data} onClickBtn={onBtnClick} />}
				<div className={!show ? s.Statistics : ''}>
					<Statistics
						statistics={assetStatistics}
						isLoading={isLoading}
						downloadProgressPercents={downloadProgressPercents}
					/>
				</div>

				<div className={s.wrapper__btn}>
					<div className={s.disabled__container}>
						{!isReleaseCreateDisabled ? (
							<ButtonAdd
								title={<FormattedMessage id={'rod.btn.add_release'} />}
								src={releaseSvg}
								disabled={false}
								onClick={() => {
									!notTransferReleaseFlag
										? setModal(true)
										: createNewReleaseHandler();
								}}
							/>
						) : (
							<ButtonAdd
								title={<FormattedMessage id={'rod.btn.add_release'} />}
								src={releaseSvg}
								className={s.btn__disabled}
							/>
						)}

						{false && (
							<p className={s.notify__text}>
								<FormattedMessage
									values={{
										span: (msg) => <span>{msg}</span>,
									}}
									id={'rod.main_page.you_need_to_sign_a_contract'}
								/>
							</p>
						)}
					</div>
					{isAddCompositionEnabled ? (
						<Link
							to={{
								pathname: '/compositions/create',
								state: { url: pathname },
							}}
							className={s.link}
							onClick={clickToCreateComposition}
						>
							<ButtonAdd
								title={<FormattedMessage id={'rod.btn.add_composition'} />}
								src={nota}
							/>
						</Link>
					) : (
						<ButtonAdd
							disabled={!isAddCompositionEnabled}
							className={isAddCompositionEnabled ? null : s.btn__disabled}
							title={<FormattedMessage id={'rod.btn.add_composition'} />}
							src={nota}
						/>
					)}

					<ButtonAdd
						className={upload.recordings ? null : s.btn__disabled}
						disabled={!upload.recordings}
						title={<FormattedMessage id={'rod.btn.add_recordings'} />}
						src={dynamic}
					/>
				</div>
			</div>
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(MainPage);
