import React, { useContext, useState, useEffect } from 'react';
import { LangContext } from 'contexts/LangContext';
import s from './RepertoireReleaseModeration.module.css';

import { FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { Label } from 'components/Labels';
import { RepertoireReleaseModerationItem } from './RepertoireReleaseModerationItem';
import { InfoButton } from 'components/Buttons';

import { withAuth, withUI } from 'hocs';
import { compose } from 'recompose';

import { accounts } from 'services';
import { NoData } from 'components';
import { Loading } from 'components';

function RepertoireReleaseModeration(props) {
	const { lang } = useContext(LangContext);
	const [moderationData, setModerationData] = useState([]);
	const [loading, setLoading] = useState(true);

	const {
		UIContext: { showModal },
		authContext: { accountId },
		releaseId,
		getInfo,
		isReleaseInQueue,
	} = props;

	useEffect(() => {
		accounts
			.getReleaseInfoForBrowsing(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				getInfo({ title: res.title, ean: res.ean });
			})
			.catch((error) => {
				console.info('Error', error);
			});

		accounts
			.getReleaseModeration(accountId, releaseId, lang)
			.then((res) => {
				res = res.data.data;

				setModerationData(res);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.info('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const structuredList = () => {
		let result = [];
		let issuesList = [];
		moderationData.reason.forEach((currentIssue) => {
			if (currentIssue.categories) {
				Object.values(currentIssue.categories).forEach((category) => {
					//category = [{},{},..]
					category.forEach((titlesObj) => {
						if (!issuesList.includes(titlesObj.title_en)) {
							issuesList.push(titlesObj.title_en);
						}
					});
				});
			}
		});

		result = issuesList.map((currentIssue) => {
			let trackList = [];
			moderationData.reason.forEach((currentResponse) => {
				let categoriesList = [];
				let track = {};
				if (currentResponse.categories) {
					for (const [categoryName, categoryValue] of Object.entries(
						currentResponse.categories
					)) {
						//category = [{},{},..]
						const track_id = currentResponse.track_id;
						const track_name = currentResponse.track_name;
						categoryValue.forEach((titlesObj) => {
							if (currentIssue === titlesObj.title_en) {
								const title_en = titlesObj.title_en;
								const title_ru = titlesObj.title_ru;
								categoryName === 'metadata'
									? track_id
										? categoriesList.push('track_metadata')
										: categoriesList.push('release_metadata')
									: categoriesList.push(categoryName);

								track = {
									title_en,
									title_ru,
									track_id,
									track_name,
									category: categoriesList,
								};
							}
						});
					}
				}
				if (categoriesList.length > 0) trackList.push(track);
			});
			return trackList;
		});

		return result;
	};

	return (
		<>
			{loading && <Loading className={s.loader} />}

			{moderationData?.status === 'approved' && !isReleaseInQueue && !loading && (
				<div className={s.status}>
					<NoData textId={'rod.release.moderation.approved'} />
				</div>
			)}
			{moderationData?.status !== 'rejected' &&
				(moderationData?.status === 'pending' || isReleaseInQueue) &&
				!loading && (
					<div className={s.status}>
						<NoData textId={'rod.release.moderation.pending'} />
					</div>
				)}
			{moderationData?.status === 'rejected' && !loading && (
				<>
					<InfoButton
						className={`${s.info} ${s.title__info}`}
						onClick={showModal({
							text: (
								<FormattedHTMLMessage
									id={'rod.release.moderation.declined.help'}
								/>
							),
						})}
					>
						<span className={s.title}>
							<FormattedHTMLMessage id={'rod.release.moderation.declined'} />
						</span>
					</InfoButton>
					<div className={s.main_wrapper}>
						<Row className={`${s.table__header}`}>
							<Col width={265} className={s.label_first}>
								<Label font="--gilroy-Medium-16">
									<FormattedHTMLMessage id={'rod.release.moderation.issues'} />
								</Label>
							</Col>
							<Col width={80} className={s.label}>
								<Label font="--gilroy-Medium-16">
									<FormattedHTMLMessage id={'rod.release.moderation.cover'} />
								</Label>
							</Col>

							<Col width={140} className={s.label}>
								<Label font="--gilroy-Medium-16">
									<FormattedHTMLMessage
										id={'rod.release.moderation.release_metadata'}
									/>
								</Label>
							</Col>

							<Col width={100} className={s.label}>
								<Label font="--gilroy-Medium-16">
									<FormattedHTMLMessage
										id={'rod.release.moderation.track_metadata'}
									/>
								</Label>
							</Col>

							<Col width={110} className={s.label}>
								<Label font="--gilroy-Medium-16">
									<FormattedHTMLMessage id={'rod.release.moderation.audio'} />
								</Label>
							</Col>
							<Col width={200} className={s.label_last}>
								<Label font="--gilroy-Medium-16">
									<FormattedHTMLMessage id={'rod.release.moderation.track'} />
								</Label>
							</Col>
						</Row>

						<ul className={s.table__body}>
							{structuredList().map((item) => (
								<li className={`${s.table__item}`} key={item.id}>
									<RepertoireReleaseModerationItem data={item} lang={lang} />
								</li>
							))}
						</ul>
					</div>
				</>
			)}
		</>
	);
}
export default compose(withAuth, withUI)(RepertoireReleaseModeration);
