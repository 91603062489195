// UI
import TabUnstyled from '@mui/base/TabUnstyled';

// Styles
import { styled } from '@mui/material/styles';

export const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: '#f0f0f0',
	font: 'var(--gilroy-Bold-18)',
	padding: '25px 36px',
	border: 'none',
	'&:first-child': {
		paddingLeft: '30px',
		paddingRight: '35px',
	},
	'&:nth-child(2)': {
		paddingLeft: '35px',
	},
	'&:nth-child(3)': {
		paddingLeft: '35px',
		paddingRight: '37px',
	},
	'&:nth-child(4)': {
		paddingLeft: '35px',
		paddingRight: '33px',
	},
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		textDecoration: 'line-through',
	},
}));
