import React from 'react';
import styles from './Notify.module.css';
import svg1 from 'images/list.svg';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const NotifyItem = (props) => {
	const item = props.item;

	const htmlParsing = (text) => {
		return parse(DOMPurify.sanitize(text));
	};

	return (
		<div className={styles.NotifyItem}>
			<img src={svg1} alt="" />
			<p className={styles.NotifyItem__text}>{htmlParsing(item.heading)}</p>
			<p className={styles.NotifyItem__date}>{item.created_at}</p>
			{!item.read_user && <span className={styles.NotifyItem__dots}></span>}
		</div>
	);
};
export default NotifyItem;
