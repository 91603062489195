// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Styles
import s from './InfoToast.module.css';

const InfoToast = (props = '') => {
	const [isToastClose, setIsToastClose] = useState(false);

	const { hideToast } = useContext(UIContext);

	useEffect(() => {
		setTimeout(() => {
			handleOnClose();
			hideToast();
		}, 3000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isToastClose && props.onClose) {
			setTimeout(() => {
				props.onClose();
				hideToast();
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isToastClose, props]);

	const handleOnClose = () => {
		setIsToastClose(true);
	};

	return (
		<div className={!isToastClose ? s.toastWrapper : s.toastWrapperClose}>
			{props.title && (
				<span className={s.toastTitle}>
					<FormattedMessage id={props.title} />
				</span>
			)}
			{props.text && (
				<span className={s.toastText}>
					<FormattedMessage id={props.text} />
				</span>
			)}
			{props.isCloseBtn && (
				<div className={s.toastClose} onClick={handleOnClose}>
					<FormattedMessage id={'rod.action.close'} />
				</div>
			)}
		</div>
	);
};

export default InfoToast;
