// Core
import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PoseGroup } from 'react-pose';
import { compose } from 'recompose';

// Context
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Constants
import ReleaseCompletionSteps from 'material-design/constants/releaseCompletionSteps';

// UI
import { withAuth, withUI } from 'hocs';
import { Col, Container, Row } from 'components/Grid';
import BaseModal from './BaseModal';
import AddReleaseModal from 'containers/Modal/AddReleaseModal/AddReleaseModal';
import { Modal, Shade } from './Posed/Posed';

// Icons
import nota from 'images/nota_white.svg';
import release from 'images/release_disc.svg';
import releaseDisabled from 'images/release_disabled.svg';
import dynamic from 'images/dynamic_white.svg';
import catalog from 'images/catalog.svg';
import close from 'images/close.svg';

// Styles
import s from './LoadPage.module.css';

const LoadPage = (props) => {
	const {
		active,
		setActive,
		authContext: { accountId, isAuth },
	} = props;
	const [modal, setModal] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const { getAccountPersonalData, getAccountContactsData } = useContext(
		RootContext
	);
	const { setIsTransferRelease, setCompletionStep, notTransferReleaseFlag } = useContext(UIContext);

	let { push } = useHistory();

	useEffect(() => {
		if (isAuth && accountId !== 'null') {
			getAccountPersonalData(accountId)
				.then((account) => {
					account = account.data.data;
				})
				.catch((err) => push('/accounts'));

			getAccountContactsData(accountId)
				.then((res) => {
					res = res.data.data;
				})
				.catch((err) => push('/accounts'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsVisible(active);
	}, [active]);

	const {
		UIContext: { upload, isAddCompositionEnabled, isReleaseCreateDisabled },
	} = props;

	const createNewReleaseHandler = (item) => {
		if (item === 'release') {
			setModal(false);
			localStorage.removeItem('releaseId');
			localStorage.removeItem('isTransferRelease');
			setIsTransferRelease(false);
			setCompletionStep(ReleaseCompletionSteps.RELEASE);
			push('/release/create');
			window.location.reload();
		}

		if (item === 'composition') {
			localStorage.removeItem('compositionId');
			push('/compositions/create');
		}

		if (item === 'catalogue') {
			push(`/repertoire/${accountId}/upload`);
		}

		willUnmount();
	};

	const transferReleaseHandler = () => {
		setModal(false);
		localStorage.removeItem('releaseId');
		localStorage.setItem('isTransferRelease', true);
		setIsTransferRelease(true);
		setCompletionStep(ReleaseCompletionSteps.RELEASE);
		push('/release/create');
		window.location.reload();
		willUnmount();
	};

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			setActive(false);
		}, 300);
	};

	return (
		<>
			<BaseModal className={s.Modal}>
				{modal && (
					<AddReleaseModal
						title={<FormattedMessage id={'rod.btn.add_release'} />}
						subtitle={
							<FormattedMessage
								id={
									'rod.release.create.step.distributions.description.transfer'
								}
							/>
						}
						newReleaseButtonLabel={<FormattedMessage id={'rod.new_release'} />}
						transferReleaseButtonLabel={
							<FormattedMessage id={'rod.transfer_release'} />
						}
						newReleaseButtonHandler={() => createNewReleaseHandler('release')}
						transferReleaseButtonHandler={transferReleaseHandler}
						onClose={() => setModal(false)}
					/>
				)}
				<PoseGroup>
					{isVisible && [
						<Shade key="shade" className={s.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										md={12 - 3}
										onClick={(e) => {
											willUnmount(e);
										}}
									/>
									<Col md={3} className={s.LeftSide}>
										<div className={`${s.backdrop} ${s.active} ${s.container}`}>
											<button
												type="button"
												className={s.close__btn}
												onClick={() => willUnmount()}
												style={{ cursor: 'pointer' }}
											>
												<img src={close} alt="" />
											</button>
											<div className={s.btn__container}>
												{!isReleaseCreateDisabled ? (
													<button
														type="button"
														className={s.btn__add}
														onClick={() => {
															!notTransferReleaseFlag
																? setModal(true)
																: createNewReleaseHandler('release');
														}}
													>
														<img className={s.img__svg} src={release} alt="" />
														<p className={s.text}>
															<FormattedMessage id={'rod.btn.add_release'} />
														</p>
													</button>
												) : (
													<button type="button" className={s.btn__disabled}>
														<img
															className={s.img__svg}
															src={releaseDisabled}
															alt=""
														/>
														<p className={s.text}>
															<FormattedMessage id={'rod.btn.add_release'} />
														</p>
													</button>
												)}

												<button
													type="button"
													className={s.btn__add}
													onClick={() => createNewReleaseHandler('catalogue')}
												>
													<img className={s.img__svg} src={catalog} alt="" />
													<p className={s.text}>
														<FormattedMessage id={'rod.btn.add_catalog'} />
													</p>
												</button>

												{isAddCompositionEnabled ? (
													<button
														type="button"
														className={s.btn__add}
														onClick={() =>
															createNewReleaseHandler('composition')
														}
													>
														<img className={s.img__svg} src={nota} alt="" />
														<p className={s.text}>
															<FormattedMessage
																id={'rod.btn.add_composition'}
															/>
														</p>
													</button>
												) : null}
												{upload.recordings && (
													<button type="button" className={s.btn__add}>
														<img className={s.img__svg} src={dynamic} alt="" />
														<p className={s.text}>
															<FormattedMessage id={'rod.btn.add_recordings'} />
														</p>
													</button>
												)}
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		</>
	);
};
export default compose(withAuth, withUI)(LoadPage);
