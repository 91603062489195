/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 18:59
 */

// Core
import React from 'react';
import { setConfiguration } from 'react-grid-system';
import { pdfjs } from 'react-pdf';
import Root from 'Root';
import TagManager from 'react-gtm-module';

// Helpers
import update, { extend } from 'immutability-helper';
import { AxiosInterceptor } from 'services/interceptor';
import { accounts } from 'services';

// UI
import { Routes } from 'routes';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

async function loadStyles() {
	let tagManagerArgs = {};
	const favicon = document.getElementById('fav');

	switch (process.env.REACT_APP_CLIENT) {
		case 'broma':
			await import(`./partner/broma/App.css`);
			document.title = 'ROD Broma 16';
			favicon.href = '/favicon.ico';
			tagManagerArgs = {
				gtmId: 'GTM-PS8PKKJ',
			};
			break;
		case 'hitmaker':
			await import(`./partner/hitmaker/App.css`);
			document.title = 'Hitmaker';
			favicon.href = '/favicon-partner.ico';
			tagManagerArgs = {
				gtmId: 'GTM-5TMGXB5',
			};
			break;
		case 'dgtal':
			await import(`./partner/dgtal/App.css`);
			document.title = 'DGTal Solutions';
			favicon.href = '/favicon-dgtal.png';
			break;
		case 'mts':
			await import(`./partner/mts/App.css`);
			document.title = 'MTS';
			favicon.href = '/favicon-mts-transparent.png';
			break;

		default:
			await import('./partnerDefault/App.css');
			document.title = 'Untitled';
			favicon.href = '/world.png';
			tagManagerArgs = {
				gtmId: 'GTM-PS8PKKJ',
			};

			break;
	}

	TagManager.initialize(tagManagerArgs);
}

loadStyles();

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
setConfiguration({
	breakpoints: [768, 1024, 1920, 3840],
	containerWidths: [708, 1024, 1920, 3840],
});

extend('$auto', function (value, object) {
	return object ? update(object, value) : update({}, value);
});

extend('$autoArray', function (value, object) {
	return object ? update(object, value) : update([], value);
});

let globalId = 0;
export const getGlobalId = (prefix) => {
	globalId++;
	return `${prefix}_${globalId}`;
};

const App = () => (
	<Root>
		<ErrorBoundary>
			<AxiosInterceptor>
				<Routes accounts={accounts} />
			</AxiosInterceptor>
		</ErrorBoundary>
	</Root>
);
export default App;
