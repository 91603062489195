/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 12:37
 */

// import { Button } from 'components/Buttons/';
import { Wizard } from 'components/Wizard';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import accountTypes from 'constants/accountTypes';
import repertoireOwn from 'constants/repertoireOwn';
import { withAuth, withRoot, withUI } from 'hocs';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import api from 'services/interceptor';
import styles from './Create.module.css';
import WhoAmI from './steps/01-WhoAmI/WhoAmI';
import Confirm from './steps/02-Confirm/Confirm';
import Info from './steps/03-Info/Info';
import Contacts from './steps/04-Contacts/Contacts';
import Documents from './steps/05-Documents/Documents';
import Identifiers from './steps/06-Identifiers/Identifiers';
import Payments from './steps/07-Payments/Payments';
import Complete from './steps/08-Complete/Complete';
import ConfirmAll from './steps/09-Confirm/Confirm';

class Create extends PureComponent {
	static propTypes = {
		account: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = this.initialState = {
			completion_step: accountCompletionSteps.BEGIN,
			personalData: {
				business_type: accountTypes.COMPANY,
				scope_group: repertoireOwn.MYSELF,
			},
			errors: {},
			isReqSend: false,
			isPseudonymsUpdateNeeded: false,
		};

		this.steps = this.defaultSteps = [
			{
				id: 1,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_1.title'} />,
			},
			{
				id: 2,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_2.title'} />,
			},
			{
				id: 3,
				status: 'default',
				title: <FormattedMessage id={'rod.account.create.step_3.title'} />,
			},
		];

		if (props.account) {
			this.state = {
				...this.state,
				id: props.account.id,
				personalData: { ...this.state.personalData, ...props.account },
				completion_step: this.getNextStep(props.account.completion_step),
			};
		}
	}
	componentDidMount() {
		this.update();
		// this.handleBack()
	}

	update() {
		const {
			UIContext: { showBackNavi, hideBackNavi },
		} = this.props;
		this.steps = this.defaultSteps;
		switch (this.state.completion_step) {
			case accountCompletionSteps.DOCUMENTS: {
				if (
					this.props.account.completion_step !==
					accountCompletionSteps.DECLINED_DOCUMENTS
				) {
					showBackNavi(null, this.handleBack);
				}
				break;
			}
			case accountCompletionSteps.CONTACT_DATA:
			case accountCompletionSteps.IDENTITY:
			case accountCompletionSteps.MONEY:
			case accountCompletionSteps.CONFIRM:
				showBackNavi(null, this.handleBack);
				break;
			// case accountCompletionSteps.DECLINED:
			//was after next case://  case accountCompletionSteps.PENDING:
			//was after next case:// case accountCompletionSteps.DECLINED_DOCUMENTS:
			case accountCompletionSteps.DECLINED_CONTRACTS:
			case accountCompletionSteps.PENDING_CONTRACT:
				this.steps[1].status = 'complete';
				this.steps[2].status = 'active';
				this.forceUpdate();
				break;
			default:
				hideBackNavi();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.completion_step !== this.state.completion_step) {
			this.update();
		} else if (
			prevProps.account &&
			prevProps.account.id !== this.props.account.id
		) {
			this.setState({
				id: this.props.account.id,
				personalData: {
					...this.initialState.personalData,
					...this.props.account,
				},
				completion_step: this.getNextStep(this.props.account.completion_step),
				errors: {},
			});
		}
	}

	getNextStep(completion_step = this.state.completion_step) {
		switch (completion_step) {
			case accountCompletionSteps.BEGIN:
				return accountCompletionSteps.ROLE;
			case accountCompletionSteps.ROLE:
				return accountCompletionSteps.PERSONAL_DATA;
			case accountCompletionSteps.PERSONAL_DATA:
				return accountCompletionSteps.CONTACT_DATA;
			case accountCompletionSteps.CONTACT_DATA:
				return accountCompletionSteps.DOCUMENTS;
			case accountCompletionSteps.DOCUMENTS:
				return accountCompletionSteps.IDENTITY;
			case accountCompletionSteps.IDENTITY:
				return accountCompletionSteps.MONEY;
			case accountCompletionSteps.MONEY:
				return accountCompletionSteps.CONFIRM;
			default:
				return completion_step;
		}
	}

	nextHandler = (state) => {
		this.setState({ completion_step: this.getNextStep(), ...state });
	};

	handleBack = () => {
		switch (this.state.completion_step) {
			case accountCompletionSteps.CONTACT_DATA:
				this.setState({
					completion_step: accountCompletionSteps.PERSONAL_DATA,
				});
				break;
			case accountCompletionSteps.DOCUMENTS:
				this.setState({ completion_step: accountCompletionSteps.CONTACT_DATA });
				break;

			case accountCompletionSteps.IDENTITY:
				this.setState({ completion_step: accountCompletionSteps.DOCUMENTS });
				break;

			case accountCompletionSteps.MONEY:
				this.setState({ completion_step: accountCompletionSteps.IDENTITY });
				break;
			case accountCompletionSteps.CONFIRM:
				this.setState({ completion_step: accountCompletionSteps.MONEY });
				break;

			default:
				this.setState({
					completion_step: accountCompletionSteps.PERSONAL_DATA,
				});
		}
	};

	createAccount = (data) => {
		const {
			rootContext: { createAccount },
		} = this.props;
		this.setState({ isReqSend: true });
		createAccount({
			business_type: data.personalData.business_type,
			scope_group: data.personalData.scope_group,
		})
			.then((res) => {
				if (res.headers['x-access-token'] && res.headers['refresh-token']) {
					localStorage.setItem('token', res.headers['x-access-token']);
					localStorage.setItem('refresh', res.headers['refresh-token']);
				}
				this.setState({ isReqSend: false });
				res = res.data.data;
				this.props.history.push(`/accounts/${res.id}`);
				// this.setState({
				// 	id: res.id,
				// 	completion_step: accountCompletionSteps.PERSONAL_DATA,
				// 	scopeIds,
				// });
			})
			.catch((error) => {
				console.log('Create - createAccount error: ', error);
				console.log('Create - createAccount error.response: ', error.response);
				this.setState({ isReqSend: false });
			})
			.finally(() => {
				this.setState({ isReqSend: false });
			});
	};

	submitRoles = (scopeIds) => {
		const {
			rootContext: { addRoles },
		} = this.props;
		addRoles(this.state.id, { scopeIds })
			.then((res) => {
				res = res.data.data;
				this.setState({
					completion_step: accountCompletionSteps.PERSONAL_DATA,
					errors: {},
				});
			})
			.catch((data) => {
				console.log('Create - addRoles error: ', data);
				console.log('Create - addRoles error.response: ', data.response);
				this.setState({
					errors: data.response.data.errors,
				});
			});
	};

	submitPersonalData = (data) => {
		const {
			rootContext: { updateAccountPersonalData, updateAccountCompanyData },
		} = this.props;
		if (this.state.personalData.business_type === accountTypes.COMPANY) {
			updateAccountCompanyData(this.state.id, data)
				.then((res) => {
					res = res.data.data;
					this.setState({
						completion_step: accountCompletionSteps.CONTACT_DATA,
						personalData: data,
						errors: {},
					});
				})
				.catch((data) => {
					console.log(
						'Create - updateAccountCompanyData error.response: ',
						data.response
					);
					this.setState({
						errors: data.response.data.errors,
					});
				});
		} else {
			updateAccountPersonalData(this.state.id, data)
				.then((res) => {
					res = res.data.data;
					this.setState({
						completion_step: accountCompletionSteps.CONTACT_DATA,
						personalData: data,
						errors: {},
					});
				})
				.catch((data) => {
					console.log('Create - updateAccountPersonalData error: ', data);
					console.log(
						'Create - updateAccountPersonalData error.response: ',
						data.response
					);
					this.setState({
						errors: data.response.data.errors,
					});
				});
		}
	};

	submitContactsData = ({ data, nonBackErrors }) => {
		this.setState({
			errors: {},
		});
		if (nonBackErrors) {
			this.setState({
				errors: { ...nonBackErrors },
			});
		}
		const {
			rootContext: { updateAccountContactsData },
		} = this.props;
		updateAccountContactsData(this.state.id, data)
			.then((res) => {
				res = res.data.data;
				!Object.keys(nonBackErrors).length &&
					this.setState({
						completion_step: accountCompletionSteps.DOCUMENTS,
						contactsData: data,
						errors: {},
					});
			})
			.catch((data) => {
				console.log('Create - updateAccountContactsData error: ', data);
				console.log(
					'Create - updateAccountContactsData error.response: ',
					data.response.data.errors
				);
				this.setState({
					errors: nonBackErrors
						? {
								...this.state.errors,
								...data.response.data.errors,
								...nonBackErrors,
						  }
						: { ...this.state.errors, ...data.response.data.errors },
				});
			});
	};

	submitDocuments = (data) => {
		const {
			rootContext: { acceptAccountDocuments },
		} = this.props;
		const completion_step =
			this.props.account.completion_step ===
			accountCompletionSteps.DECLINED_DOCUMENTS
				? accountCompletionSteps.CONFIRM
				: accountCompletionSteps.IDENTITY;
		acceptAccountDocuments(this.state.id)
			.then((res) => {
				// res = res.data.data;
				this.setState({
					completion_step,
					errors: {},
				});
			})
			.catch((data) => {
				console.log('Create - acceptAccountDocuments error: ', data);
				console.log(
					'Create - acceptAccountDocuments error.response: ',
					data.response
				);
				this.setState({
					errors: data.response.data.errors,
				});
			});
	};

	handleSubmitIdentifyDataSuccessResponse = (
		resolvedPromisesCount,
		rejectedPromisesCount,
		pseudonyms
	) => {
		resolvedPromisesCount++;
		if (rejectedPromisesCount) {
			this.setState({ isPseudonymsUpdateNeeded: true });
		}
		if (resolvedPromisesCount === pseudonyms.length) {
			this.setState({
				completion_step: accountCompletionSteps.MONEY,
				errors: {},
			});
		}

		return resolvedPromisesCount;
	};

	handleSubmitIdentifyDataRejectedResponse = (
		rejectedPromisesCount,
		resolvedPromisesCount,
		index,
		error,
		initialPseudonyms,
		item
	) => {
		rejectedPromisesCount++;
		if (resolvedPromisesCount) {
			this.setState({ isPseudonymsUpdateNeeded: true });
		}
		const errKey = `pseudonyms${index}`;
		this.setState({
			errors: {
				...this.state.errors,
				[errKey]: error.response
					? error.response.data.errors
					: initialPseudonyms.find(
							(pseudonym) => item.title === pseudonym.title
					  ) &&
					  initialPseudonyms.find(
							(pseudonym) => item.ipi_name_number === pseudonym.ipi_name_number
					  )
					? {
							title: [{ rule: 'non_unique' }],
							ipi_name_number: [{ rule: 'non_unique' }],
					  }
					: initialPseudonyms.find(
							(pseudonym) => item.title === pseudonym.title
					  )
					? {
							title: [{ rule: 'non_unique' }],
					  }
					: initialPseudonyms.find(
							(pseudonym) => item.ipi_name_number === pseudonym.ipi_name_number
					  ) && {
							ipi_name_number: [{ rule: 'non_unique' }],
					  },
			},
		});
		return rejectedPromisesCount;
	};

	submitIdentifyData = async ({ pseudonyms, societies }) => {
		let resolvedPromisesCount = 0;
		let rejectedPromisesCount = 0;
		this.setState({ errors: {}, isPseudonymsUpdateNeeded: false });
		const initialPseudonyms = pseudonyms.filter((item) => item.id !== null);
		const {
			rootContext: {
				createAccountPseudonyms,
				updatePseudonym,
				postAccountSocieties,
			},
		} = this.props;

		if (societies && societies.length > 0) {
			let societiesData = societies.map((item) => ({
				id: item.id,
				mebership_since: item.mebership_since,
			}));

			postAccountSocieties(this.state.id, {
				societies: societiesData,
			});
		}

		if (pseudonyms && pseudonyms.length > 0) {
			await pseudonyms.forEach((item, index) => {
				if (!item.id) {
					createAccountPseudonyms(this.state.id, item)
						.then((res) => {
							resolvedPromisesCount = this.handleSubmitIdentifyDataSuccessResponse(
								resolvedPromisesCount,
								rejectedPromisesCount,
								pseudonyms
							);
						})
						.catch((error) => {
							rejectedPromisesCount = this.handleSubmitIdentifyDataRejectedResponse(
								rejectedPromisesCount,
								resolvedPromisesCount,
								index,
								error,
								initialPseudonyms,
								item
							);
						});
				} else {
					const { title, isni, ipi_name_number } = item;
					updatePseudonym(this.state.id, item.id, {
						title,
						isni,
						ipi_name_number,
					})
						.then((res) => {
							resolvedPromisesCount = this.handleSubmitIdentifyDataSuccessResponse(
								resolvedPromisesCount,
								rejectedPromisesCount,
								pseudonyms
							);
						})
						.catch((error) => {
							rejectedPromisesCount = this.handleSubmitIdentifyDataRejectedResponse(
								rejectedPromisesCount,
								resolvedPromisesCount,
								index,
								error,
								initialPseudonyms,
								item
							);
						});
				}
			});
		} else {
			this.setState({
				completion_step: accountCompletionSteps.MONEY,
				errors: {},
			});
		}
	};

	submitPaymentData = (data) => {
		const {
			rootContext: { updateAccountPaymentData },
		} = this.props;
		updateAccountPaymentData(this.state.id, data)
			.then((res) => {
				res = res.data.data;
				this.setState({
					completion_step: accountCompletionSteps.CONFIRM,
					paymentData: data,
					errors: {},
				});
			})
			.catch((data) => {
				console.error('Create - submitPaymentData error: ', data);
				console.error(
					'Create - submitPaymentData error.response: ',
					data.response
				);
				this.setState({
					errors: data.response.data.errors,
				});
			});
	};

	submitConfirm = () => {
		const {
			rootContext: { sendAccountToModeration },
		} = this.props;
		sendAccountToModeration(this.state.id);
		this.setState({
			completion_step: accountCompletionSteps.PENDING,
			errors: {},
		});
	};

	resetContracts = () => {
		api
			.post(`/qa/account/${this.state.id}/step`, {
				completion_step: accountCompletionSteps.PENDING_CONTRACT,
			})
			.then((res) => {
				this.props.history.push(`/accounts/${this.state.id}`);
				this.setState({
					completion_step: accountCompletionSteps.PENDING_CONTRACT,
					errors: {},
				});
			})
			.catch((err) => {
				console.log('Create - resetContracts error.response: ', err.response);

				this.props.history.push(`/accounts/${this.state.id}`);
				this.setState({
					completion_step: accountCompletionSteps.PENDING_CONTRACT,
					errors: {},
				});
			});
	};

	handleOpenPrevTabs = (id) => {
		switch (id) {
			case 1:
				this.setState({
					completion_step: accountCompletionSteps.PERSONAL_DATA,
				});
				break;
			case 2:
				this.setState({
					completion_step: accountCompletionSteps.CONTACT_DATA,
				});
				break;
			case 3:
				this.setState({
					completion_step: accountCompletionSteps.DOCUMENTS,
				});
				break;
			case 4:
				this.setState({
					completion_step: accountCompletionSteps.IDENTITY,
				});
				break;

			default:
				break;
		}
	};

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>
						{this.state.personalData.title
							? this.state.personalData.title
							: 'Create account'}{' '}
						| Broma 16
					</title>
				</Helmet>
				<div className={styles.Page}>
					<Wizard steps={this.steps} className={styles.Wizard} />

					<div className={styles.Step}>{this.renderStep()}</div>
				</div>
			</React.Fragment>
		);
	}

	renderStep() {
		const { completion_step, ...state } = this.state;

		switch (completion_step) {
			case accountCompletionSteps.BEGIN:
				return (
					<WhoAmI
						onChange={this.createAccount}
						UIContext={this.props.UIContext}
						{...state}
					/>
				);
			case accountCompletionSteps.ROLE:
				return (
					<Confirm
						onChange={this.submitRoles}
						UIContext={this.props.UIContext}
						{...state}
					/>
				);
			case accountCompletionSteps.PERSONAL_DATA:
				return (
					<Info
						onChange={this.submitPersonalData}
						handleOpenPrevTabs={this.handleOpenPrevTabs}
						{...state}
					/>
				);
			case accountCompletionSteps.CONTACT_DATA:
				return (
					<Contacts
						onChange={this.submitContactsData}
						{...state}
						handleOpenPrevTabs={this.handleOpenPrevTabs}
					/>
				);
			case accountCompletionSteps.DOCUMENTS:
				return (
					<Documents
						onChange={this.submitDocuments}
						UIContext={this.props.UIContext}
						handleOpenPrevTabs={this.handleOpenPrevTabs}
						{...state}
					/>
				);
			case accountCompletionSteps.IDENTITY:
				return (
					<Identifiers
						onChange={this.submitIdentifyData}
						{...state}
						handleOpenPrevTabs={this.handleOpenPrevTabs}
					/>
				);
			case accountCompletionSteps.MONEY:
				return (
					<Payments
						onChange={this.submitPaymentData}
						{...state}
						handleOpenPrevTabs={this.handleOpenPrevTabs}
					/>
				);
			case accountCompletionSteps.APPROVED:
				return <Redirect to={`/accounts/${state.id}`} />;
			case accountCompletionSteps.DECLINED:
			case accountCompletionSteps.DECLINED_CONTRACTS:
			case accountCompletionSteps.DECLINED_DOCUMENTS:
			case accountCompletionSteps.PENDING:
			case accountCompletionSteps.PENDING_CONTRACT:
				return (
					<Complete
						{...state}
						toDocuments={() =>
							this.setState({
								completion_step: accountCompletionSteps.DOCUMENTS,
							})
						}
						toContracts={this.resetContracts}
						step={completion_step}
						onChange={() => {
							this.props.history.push('/accounts');
						}}
					/>
				);
			case accountCompletionSteps.CONFIRM:
				return <ConfirmAll onChange={this.submitConfirm} {...state} />;
			default:
				return;
		}
	}
}

export default compose(withRoot, withAuth, withUI, withRouter)(Create);
