/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 24/02/2021, 12:57
 */

import { Loading } from 'components';
import { Bullet } from 'components/Bullet';
import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { Label } from 'components/Labels/Label';
import { InfoButton } from 'components/Buttons';
import { withAuth, withLang, withRoot } from 'hocs';
import update from 'immutability-helper';
import styles from 'pages/account/Create/steps/02-Confirm/Confirm.module.css';
import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
import { getHelpMessage } from '../../../../../messages/helper';
import { accounts } from 'services';
// import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';

class Confirm extends PureComponent {
	static propTypes = {};

	state = {
		loading: true,
		roles: [],
	};

	componentDidMount() {
		const {
			UIContext: { showTitle, showBackNavi },
			isAddRole,
		} = this.props;
		if (isAddRole) {
			showTitle('rod.account.add_role');
			showBackNavi(`/treaties`);
		}
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.id !== this.props.id) {
			this.update();
		}
	}

	componentWillUnmount() {
		const {
			UIContext: { hideTitle, hideBackNavi },
			isAddRole,
		} = this.props;
		if (isAddRole) {
			hideTitle();
			hideBackNavi();
		}
	}

	update() {
		const {
			rootContext: { getRoles, getRolesForAddNew },
			authContext: { getAccountCompletionStep },
			UIContext: { showTitle, hideTitle, showBackNavi, hideBackNavi },
			isAddRole,
			completionStep,
			id,
		} = this.props;
		if (isAddRole) {
			getAccountCompletionStep(completionStep);

			getRolesForAddNew(id).then((res) => {
				this.setState({
					roles: res,
					loading: false,
				});
			});
		} else {
			getRoles(this.props.id).then((res) => {
				this.setState({
					roles: res,
					loading: false,
				});
			});
		}
	}

	nextHandler = () => {
		const { isAddRole } = this.props;
		if (typeof this.props.onChange === 'function') {
			if (isAddRole) {
				const scopeIds = this.state.roles
					.filter((role) => role.checked || role.selected)
					.map((role) => role.id);

				this.props.onChange(scopeIds);
			} else {
				this.props.onChange(
					this.state.roles.filter((role) => role.checked).map((role) => role.id)
				);
			}
		}
	};

	roleSelectHandler = (id) => (event) => {
		this.setState(
			update(this.state, {
				roles: {
					[this.state.roles.findIndex((role) => role.id === id)]: {
						checked: { $set: event.target.checked },
						selected: { $set: event.target.checked },
					},
				},
			})
		);
	};

	renderForm() {
		const {
			UIContext: { showModal },
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'account_your_role')
		);

		return (
			<Col sm={6} offset={{ sm: 1 }} style={{ padding: 0 }}>
				<div className={styles.Form}>
					<div
						className={styles.labelWrapper}
						style={{ 'margin-bottom': '50px' }}
					>
						<InfoButton
							className={styles.infoButton}
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.confirm_role.form.header'}
									/>
								}
								font="--gilroy-Medium-40"
								display="inline"
								black
								className={styles.Header}
							/>
						</InfoButton>
					</div>
					{this.renderFields()}
					{this.renderControls()}
				</div>
			</Col>
		);
	}

	renderFields() {
		const { roles } = this.state;

		return (
			<div>
				{roles.map((role) => (
					<Checkbox
						key={role.id}
						text={
							<FormattedMessage id={`rod.ref.roles.${role.keyword}.title`} />
						}
						onChange={this.roleSelectHandler(role.id)}
						className={styles.Field}
						isLocked={role.signed ? true : false}
						checked={role.selected || role.checked}
					/>
				))}
			</div>
		);
	}

	renderSummary() {
		const { roles } = this.state;
		return (
			<Col sm={5} className={styles.Summary}>
				<div className="content">
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.confirm_role.summary.header'}
							/>
						}
						font="--gilroy-Medium-32"
						black
						className={styles.Header}
					/>
					{roles.map((role) => {
						switch (true) {
							case role.signed:
								return (
									<Bullet key={role.id} className={styles.BulletSelected}>
										<FormattedMessage
											id={`rod.ref.roles.${role.keyword}.summary`}
										/>
									</Bullet>
								);
							case role.checked:
								return (
									<Bullet key={role.id} className={styles.Bullet}>
										<FormattedMessage
											id={`rod.ref.roles.${role.keyword}.summary`}
										/>
									</Bullet>
								);
							case role.selected:
								return (
									<Bullet key={role.id} className={styles.Bullet}>
										<FormattedMessage
											id={`rod.ref.roles.${role.keyword}.summary`}
										/>
									</Bullet>
								);
							default:
								break;
						}
					})}
				</div>
			</Col>
		);
	}

	render() {
		// const { authContext: { isAuth, login } } = this.props;

		const { loading } = this.state;
		if (loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<Container fluid className={styles.Page}>
				<Row style={{ height: '100%' }}>
					{this.renderForm()}
					{this.renderSummary()}
				</Row>
			</Container>
		);
	}

	isRoleChecked = () => {
		const role = this.state.roles.find((item) => item.checked);
		return role;
	};

	renderControls() {
		return (
			<div className={styles.Controls}>
				<Button
					text={<FormattedMessage id={'rod.action.next'} />}
					variant={'primary'}
					onClick={this.nextHandler}
					disabled={!this.isRoleChecked()}
				/>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withLang)(Confirm);
