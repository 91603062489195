// Core
import { FormattedMessage } from 'react-intl';

// UI
import LyricWrapper from './LyricWrapper/LyricWrapper';
import { FormInput } from 'components/Form/FormInput';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import close_button from 'images/close_button.svg';

// Styles
import s from './LyricsForm.module.css';

const LyricsForm = ({
	handleForm,
	toogleMenu,
	errors,
	changeField,
	selectPerson,
	languages,
	handleChecked,
	isTextAreaOn,
	recordingSave,
}) => {
	const narrative = [
		{
			id: 1,
			title_ru: 'Присутствует',
			title_en: 'Present',
			code: 'explicit',
		},
		{
			id: 2,
			title_ru: 'Отсутствует',
			title_en: 'None ',
			code: 'not_explicit',
		},
	];

	return (
		<div className={s.form}>
			<div className={s.formTitleWrapper}>
				<span className={s.form__title} id="general">
					<FormattedMessage id={'rod.release.tab.main'} />
				</span>
				<img
					src={close_button}
					className={s.form__close}
					onClick={() => handleForm(toogleMenu)}
					alt=""
				/>
			</div>
			<div className={`${s.form__section} ${s.first__section}`}>
				<div className={s.input_leftCol}>
					<FormInput
						type="muiInputSelect"
						name={'language'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.lang'}
							/>
						}
						items={languages}
						languages={true}
						required
					/>
					{errors && Object.keys(errors).includes('language') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>
				<div className={s.input_rightCol}>
					<FormInput
						className={s.narrative}
						type={'MuiSelectNarrative'}
						name={'parental_warning_type'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={
									'rod.release.create.step.recording_info.label.non_narrative'
								}
							/>
						}
						items={narrative}
						required
					/>
					{errors && Object.keys(errors).includes('parental_warning_type') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>
			</div>
			<div className={s.checkBoxWrapper}>
				<Checkbox
					text={
						<FormattedMessage
							id={'rod.release.create.step.lyrics.add_lyrics'}
						/>
					}
					checked={isTextAreaOn}
					onChange={handleChecked}
					className={s.checkbox}
				/>
			</div>
			{isTextAreaOn && (
				<LyricWrapper
					changeField={changeField}
					errors={errors}
					selectPerson={selectPerson}
					isTextAreaOn={isTextAreaOn}
				/>
			)}

			<button
				className={s.form__submit}
				onClick={() => recordingSave(toogleMenu)}
			>
				<FormattedMessage
					id={'rod.release.create.step.recording_info.form_save'}
				/>
			</button>
		</div>
	);
};

export default LyricsForm;
