import { FormInput } from 'components/Form/FormInput';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import styles from './RepertoireInfoFilter.module.css';

function RepertoireInfoFilter(props) {
	const { repertoireData, filtersData, changeField, errors, countries } = props;
	// const lang = localStorage.getItem('lang');
	return (
		<>
			{/* ============FILTERS============ */}

			<div className={styles.filter__container}>
				<div className={styles.headerInfo__item}>
					<div className={styles.headerInfoItemFilter__countryTitle}>
						<FormattedMessage id={'rod.composition.info.territory'} />
					</div>
					<FormInput
						type="muiInputSelect"
						name={'country_id'}
						value={filtersData.country_id}
						onChange={changeField}
						errors={errors}
						data={filtersData}
						className={styles.select}
						items={countries}
					/>
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.composition.info.exploitation_type'} />
					</span>

					{repertoireData.exploitationTypes && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.exploitation_type}
							name={'exploitation_type'}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.exploitationTypes}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.field.right_type'} />
					</span>

					{/* <Select info={info.rightType} /> */}
					{repertoireData.rightTypes && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.right_type}
							name={'right_type'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.rightTypes}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.release.info.outlet'} />
					</span>
					{repertoireData.repertoireOutlets && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.outlet}
							name={'outlet'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.repertoireOutlets}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.composition.info.product'} />
					</span>
					{repertoireData.repertoireProducts && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.product}
							name={'product'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.repertoireProducts}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.composition.info.commercial_model'} />
					</span>
					{/* <Select info={info.commercialModel} /> */}
					{repertoireData.commercialModels && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.commercial_model}
							name={'commercial_model'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.commercialModels}
						/>
					)}
				</div>

				{/* =================HIDDEN YET ===================*/}
				{/* <div
							className={
								isShow
									? `${styles.RepertoireMainFilter__hideContainerActive} ${styles.RepertoireMainFilter__hideContainer}`
									: `${styles.RepertoireMainFilter__hideContainer}`
							}
						>
							<div className={styles.RepertoireSongInfo__titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage
										id={'rod.composition.info.distribution_channel'}
									/>
								</span>
								<Select info={info.distributionChannel} />
							</div>

							<div className={styles.RepertoireSongInfo__titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage
										id={'rod.composition.info.marketing_channel'}
									/>
								</span>
								<Select info={info.promotionChannel} />
							</div>

							<div className={styles.RepertoireSongInfo__titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage
										id={'rod.composition.info.interface_type'}
									/>
								</span>
								<Select info={info.interfaceType} />
							</div>

							<div className={styles.RepertoireSongInfo__titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage id={'rod.composition.info.societies'} />
								</span>
								<Select info={info.societies} />
							</div>

							<div className={styles.RepertoireSongInfo__titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage id={'rod.composition.info.start_date'} />
								</span>
								<Select info={info.startDate} />
							</div>

							<div className={styles.RepertoireSongInfo__titleInfoItem}>
								<span className={styles.RepertoireInfoItem__title}>
									<FormattedMessage id={'rod.composition.info.report_date'} />
								</span>
								<Select info={info.reportDate} />
							</div>
						</div> */}

				{/* <div
							className={
								!isShow
									? `${styles.RepertoireMainFilter__buttonActive} ${styles.RepertoireMainFilter__button}`
									: `${styles.RepertoireMainFilter__button}`
							}
							onClick={() => setIsShow(!isShow)}
						>
							{isShow ? (
								<FormattedMessage id={'rod.composition.info.Show_all'} />
							) : (
								<FormattedMessage id={'rod.composition.info.Collapse'} />
							)}
						</div> */}

				{/* ===============================================*/}
			</div>
		</>
	);
}
export default compose(withAuth, withRoot)(RepertoireInfoFilter);
