import React, { useState } from 'react';

import s from './InputMenu.module.css';

import { Loop } from 'material-design/svg';

import filter_svg from 'images/filter.svg';
import filter_dot_svg from 'images/filter_dot.svg';
import { FormattedMessage } from 'react-intl';
import remove_white from 'images/remove_white.svg';

export default function InputMenu({
	handleOnChange,
	setIsFocus,
	filter,
	setActive,
	curRef,
	parent,
	initialValue,
	placeholder,
	lowerCasePlaceholder,
	removeSearch,
}) {
	filter = filter ? filter : '';
	// поменять потом
	const [inputValue, setInputValue] = useState('');
	setIsFocus = setIsFocus ? setIsFocus : () => {};

	const inputChange = handleOnChange ? handleOnChange : () => {};
	const handleInput = (e) => {
		setInputValue(e.target.value);
		inputChange(e.target.value);
	};

	const handleRemove = () => {
		setInputValue('');
		inputChange('');
		removeSearch();
	};

	return (
		<div className={s.repertoire__mainFilter}>
			<div
				className={`${s.repertoire__mainFilterInput} ${
					inputValue && s.inputFilled
				}`}
			>
				<FormattedMessage id={placeholder}>
					{(msg) => (
						<input
							className={`${lowerCasePlaceholder} ${
								s.inputLowercasePlaceholder
							} ${inputValue && s.inputFilled}`}
							ref={curRef}
							value={inputValue}
							type="text"
							placeholder={msg}
							// onChange={(e) => inputChange(e.target.value)}
							onChange={handleInput}
							onClick={() => setIsFocus(true)}
						/>
					)}
				</FormattedMessage>
				<Loop />
				{inputValue.length && (
					<img
						src={remove_white}
						alt="remove"
						className={s.remove}
						onClick={handleRemove}
						ref={curRef}
					/>
				)}
			</div>
		</div>
	);
}
