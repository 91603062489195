// Core
import React, { useEffect, useState } from 'react';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import moment from 'moment';

// Helpers
import { isAllRequiredFilledPersonal } from 'pages/accountSettings/helpers/isAllRequiredFilledPersonal';
import { showError } from 'validators/showError';

// UI
import { Individual, Company, SoleProprietor } from './PrivateDataType';

// Types
import accountTypes from 'constants/accountTypes';

const SettingsPrivateDataSection = (props) => {
	const [errors, setErrors] = useState({});
	const [personal, setPersonal] = useState({
		sex: 'male',
	});
	const [businessType, setBusinessType] = useState('');

	useEffect(() => {
		isAllRequiredFilledPersonal(personal)
			? props.setIsSectionPrivateReady(true)
			: props.setIsSectionPrivateReady(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal]);

	useEffect(() => {
		setErrors(props.errors);
		if (!Object.keys(props.errors).length) {
			props.setIsSectionPrivateReady(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.errors]);

	useEffect(() => {
		setErrors({});
		const { data } = props;

		if (data.business_type === 'individual') {
			setPersonal((prev) => ({
				...prev,
				...data,
				sex: data.sex ? data.sex : 'male',
			}));
		} else {
			setPersonal((prev) => ({
				...prev,
				...data,
			}));
		}
		setBusinessType(data.business_type);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);

	function dateErrorCheck(errors, fieldName) {
		const err = showError(fieldName)({
			rule: 'date_format',
			value: 'YYYY-mm-dd',
		});

		this.setState({ errFromInput: true });
		errors[fieldName] = [
			{
				rule: 'date_format',
				value: 'YYYY-mm-dd',
			},
		];
		return err;
	}

	const saveHandler = (e) => {
		const { business_type } = e;
		const { birth_date, national_id_issued_date } = e;

		if (business_type !== accountTypes.COMPANY) {
			if (
				(birth_date && !moment(birth_date).isValid()) ||
				(birth_date && birth_date.length < 10)
			) {
				return dateErrorCheck(errors, 'birth_date');
			}
			if (
				(national_id_issued_date &&
					!moment(national_id_issued_date).isValid()) ||
				(national_id_issued_date && national_id_issued_date.length < 10)
			) {
				return dateErrorCheck(errors, 'national_id_issued_date');
			}
		}
		props.onChange({
			...e,
		});
	};

	return (
		<>
			{personal && (
				<>
					{businessType && businessType === accountTypes.PERSONAL && (
						<Individual
							data={personal}
							saveHandler={saveHandler}
							errors={errors}
						/>
					)}
					{businessType && businessType === accountTypes.COMPANY && (
						<Company
							data={personal}
							saveHandler={saveHandler}
							errors={errors}
						/>
					)}
					{businessType &&
						businessType === accountTypes.PERSONAL_ENTREPRENEUR && (
							<SoleProprietor
								data={personal}
								saveHandler={saveHandler}
								errors={errors}
							/>
						)}
				</>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(SettingsPrivateDataSection);
