import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import MuiInput from '@mui/material/Input';

// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';

import { styled } from '@mui/material/styles';

export const InputLab = styled(InputLabel)(({ theme }) => ({
	// color: 'var(--color-black)',
	// opacity: 0.54,

	// font: 'var(--gilroy-Medium-14)',
	// padding: '8px 4px !important',

	color: 'var(--color-grey100)',
	'&.Mui-focused': {
		// color: 'var(--color-light-green)',
		color: 'var(--color-dark-grey)',
	},
}));
export const Input = styled(InputLab)(({ theme }) => ({
	font: 'var(--gilroy-Medium-18-22)',
	color: 'var(--color-grey)',

	'& .Mui-focused': {
		padding: '6px 4px 8px !important',
		color: 'var(--color-dark-grey)',
	},
	'&.MuiFormLabel-filled,&.Mui-focused': {
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		padding: '6px 4px 8px !important',
		transform: 'none',
	},
}));
export const TextFieldInput = styled(TextField)(({ theme }) => ({
	'& *': { cursor: 'pointer' },
	'& .MuiInput-root:hover:not(.Mui-disabled)::before': {
		// borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		borderBottom: '2px solid var(--color-grey)',
	},
	'& .MuiInputLabel-root': {
		font: 'var(--gilroy-Medium-18-22)',
		color: 'var(--color-grey)',
		letterSpacing: '0.15px',
		padding: '8px 4px 10px !important',
	},
	'& .Mui-error': {
		color: 'var(--color-red) !important',
	},
	'& .Mui-error .MuiInput-input': {
		color: 'var(--color-red) !important',
	},

	'& .Mui-error .MuiInput-root': {
		color: 'var(--color-red) !important',
	},
	'& .MuiInput-root': {
		marginTop: '24px !important',
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-root .MuiInput-input': {
		padding: 0,
		lineHeight: '24px',
		height: 24,
		color: 'var(--color-dark-grey)',
	},

	'& label.MuiFormLabel-filled,& label.Mui-focused': {
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		transform: 'none',
	},
	'& .MuiInput-root::before': {
		borderBottom: '2px solid var(--color-grey)',
	},
	'& .MuiInput-root::after': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},

	'& .MuiInput-input': {
		font: 'var(--gilroy-Medium-18-22)',
		color: 'var(--color-dark-grey)',
	},
	'& .MuiFormLabel-filled + * > .MuiInput-input': {
		font: 'var(--gilroy-Medium-18-22)',
		color: 'var(--color-dark-grey)',
	},
	'& .MuiFormLabel-filled + .MuiInput-root::before': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},
	'& .MuiFormLabel-filled + .MuiInput-root:hover:not(.Mui-disabled)::before': {
		// borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		borderBottom: '2px solid var(--color-dark-grey)',
	},
	// '& .MuiAutocomplete-hasClearIcon > .MuiFormControl-root > label + .MuiInput-root::before': {
	// 	borderBottom: '2px solid var(--color-dark-grey)',
	// },
	// '& .MuiAutocomplete-hasClearIcon': {
	// 	backgroundColor: 'red',
	// },
}));

export const TextFieldInputAdminRubrics = styled(TextField)(({ theme }) => ({
	'& *': { cursor: 'pointer' },
	'& .MuiInput-root:hover:not(.Mui-disabled)::before': {
		// borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		borderBottom: '2px solid var(--color-grey)',
	},
	'& .MuiInputLabel-root': {
		font: 'var(--gilroy-Medium-18)',
		// color: 'var(--color-black)',
		// opacity: 0.54,
		letterSpacing: '0.15px',
		'&.Mui-focused': {
			// color: 'var(--color-light-green)',
			color: 'var(--color-dark-grey)',
		},
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-root': {
		marginTop: '24px !important',
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-root .MuiInput-input': {
		padding: 0,
		height: 24,
	},
	'& label.MuiFormLabel-filled,& label.Mui-focused': {
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		transform: 'none',
	},
	'& .MuiInput-root::before': {
		borderBottom: '2px solid var(--color-grey)',
	},
	'& .MuiInput-root::after': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},
	'& .MuiInput-input': {
		font: 'var(--gilroy-Medium-18)',
	},
	'& .MuiFormLabel-filled + * > .MuiInput-input': {
		font: 'var(--gilroy-Medium-16-19)',
	},
}));

export const TextFieldInputAdminReports = styled(TextField)(({ theme }) => ({
	'& *': { cursor: 'pointer' },
	'& .MuiInput-root:hover:not(.Mui-disabled)::before': {
		// borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		borderBottom: '2px solid var(--color-grey)',
	},
	'& .MuiInputLabel-root': {
		font: 'var(--gilroy-Medium-18)',
		// color: 'var(--color-black)',
		// opacity: 0.54,
		letterSpacing: '0.15px',
		'&.Mui-focused': {
			color: 'var(--color-grey100)',
		},
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-root': {
		marginTop: '24px !important',
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-root .MuiInput-input': {
		padding: 0,
		height: 24,
	},
	'& label.MuiFormLabel-filled,& label.Mui-focused': {
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		transform: 'none',
	},
	'& .MuiInput-root::after': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},
	'& .MuiInput-input': {
		paddingLeft: '8px !important',
		font: 'var(--gilroy-Medium-18)',
	},
	'& .MuiFormLabel-filled + * > .MuiInput-input': {
		font: 'var(--gilroy-Medium-16-19)',
	},
}));

export const TextFieldInputNotifyUsers = styled(TextField)(({ theme }) => ({
	'& *': { cursor: 'pointer' },
	'& .MuiInput-root:hover:not(.Mui-disabled)::before': {
		// borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		borderBottom: '2px solid var(--color-grey)',
	},
	'& .MuiInputLabel-root': {
		font: 'var(--gilroy-Medium-18)',
		// color: 'var(--color-black)',
		// opacity: 0.54,
		letterSpacing: '0.15px',
		'&.Mui-focused': {
			color: 'var(--color-grey100)',
		},
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-root': {
		marginTop: '24px !important',
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-root .MuiInput-input': {
		padding: 0,
		height: 24,
	},
	'& label.MuiFormLabel-filled,& label.Mui-focused': {
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		transform: 'none',
	},
	'& .MuiInput-root::after': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},
	'& .MuiInput-input': {
		paddingBottom: '3px !important',
		paddingLeft: '5px !important',
		font: 'var(--gilroy-Medium-18)',
	},
	'& .MuiFormLabel-filled + * > .MuiInput-input': {
		font: 'var(--gilroy-Medium-16-19)',
	},
}));
export const TextFieldInpytPerformanceWork = styled(TextFieldInput)(
	({ theme }) => ({
		'& .MuiInputLabel-root': {
			font: 'var(--gilroy-Medium-18-22)',
			color: 'var(--color-grey)',
		},
		padding: '8px 4px 10px !important',
	})
);
export const TextFieldDate = styled(TextFieldInput)(({ theme }) => ({
	'& .MuiInput-input': {
		color: 'transparent',
		transition: '.2s',
	},
	'& .Mui-focused > .MuiInput-input': {
		// color: 'var(--color-black)',
		// opacity: 0.54,
	},
	'& .MuiFormLabel-filled + * > input': {
		// color: 'var(--color-black)',
		// opacity: 0.87,
		font: 'var(--gilroy-Medium-16-19)',
	},
	'& .MuiInput-root:focus-within': {
		color: 'var(--color-black)',
	},
}));
export const SelectCustom = styled(Select)(({ theme }) => ({
	// '& .MuiInput-root:hover:not(.Mui-disabled)::before': {
	// 	// borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
	// 	borderBottom: '2px solid var(--color-grey)',
	// },
	marginTop: '20px !important',
	'& .MuiSelect-select': {
		padding: '8px 4px 10px !important',
		font: 'var(--gilroy-Medium-16-19)',
	},
	// '& .MuiInputLabel-root': {
	// 	font: 'var(--gilroy-Medium-18)',
	// 	backgroundColor: 'red',
	// 	letterSpacing: '0.15px',
	// 	'&.Mui-focused': {
	// 		color: 'var(--color-grey100)',
	// 	},
	// 	padding: '8px 4px 10px !important',
	// },
	'&.MuiInput-root::before': {
		borderBottom: '2px solid var(--color-grey)',
		// borderBottom: '2px solid var(--color-dark-grey)',
	},
	'&.MuiInput-root::after': {
		// border: '2px solid var(--color-light-green)',
		borderBottom: '2px solid var(--color-dark-grey)',
	},
}));

export const CheckboxBlack = styled(Checkbox)(({ theme }) => ({
	border: 'none',
	color: 'var(--color-dark-grey)',
	padding: '0',
	marginRight: '21px',

	'& .MuiSvgIcon-root': { fontSize: 24 },
	'&.Mui-checked': {
		color: 'var(--color-dark-grey)',
	},
}));
export const FormControlLabelText = styled(FormControlLabel)(({ theme }) => ({
	marginLeft: '0',
	'& .MuiFormControlLabel-label': {
		font: 'var(--gilroy-Medium-16-140)',
		color: 'var(--color-dark-grey)',
	},
}));
export const RadioCustom = styled(Radio)(({ theme }) => ({
	padding: '0',
	marginRight: '11px',
	color: 'var(--color-grey100)',
	'&.Mui-checked': {
		color: 'var(--color-light-green)',
	},
}));
export const FormControlLabelCustom = styled(FormControlLabel)(({ theme }) => ({
	marginLeft: '0',
	marginRight: '0',
	'& .MuiFormControlLabel-label': {
		font: 'var(--gilroy-Medium-14-24)',
		color: 'var(--color-black)',
		opacity: 0.87,
		letterSpacing: '0.15px',
	},
}));
export const FormControlLabelSwitch = styled(FormControlLabelCustom)(
	({ theme }) => ({
		'& .MuiFormControlLabel-label': {
			font: 'var(--gilroy-Medium-16-140)',
		},
	})
);
export const SwitchCustom = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: 'var(--color-light-green)',
	},
	'& .MuiSwitch-track': {
		backgroundColor: 'rgba(34, 31, 31, 0.26)',
	},
	'& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
		backgroundColor: 'var(--color-light-green)',
	},
	'& .MuiSwitch-switchBase': {
		color: 'var(--color-white200)',
	},
}));
export const SharesSlider = styled(Slider)(({ theme }) => ({
	color: 'var(--color-black)',
	height: '2px',
	padding: '0  !important',
	position: 'absolute',
	bottom: '0',
	left: '6px',
	width: 'calc(100% - 12px)',
	'& .MuiSlider-track': {
		// border: '2px solid var(--color-light-green)',
		color: 'var(--color-light-green)',
		borderTopLeftRadius: '0',
		borderBottomLeftRadius: '0',
		'&::before': {
			content: '""',
			position: 'absolute',
			width: '6px',
			left: '-7px',
			top: '50%',
			height: '2px',
			background: 'var(--color-light-green)',
			transform: 'translateY(-50%)',
			borderTopLeftRadius: '12px',
			borderBottomLeftRadius: '12px',
		},
	},
	'& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen': {
		transform: 'translateY(-100%) scale(0)',
	},
	'& .MuiSlider-valueLabel': {
		transform: 'translateY(-100%) scale(0)',
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		opacity: 0.87,
		backgroundColor: 'transparent',
		top: '0',
		padding: '0',
	},

	'& .MuiSlider-thumb': {
		width: '12px',
		height: '12px',
		color: 'var(--color-light-green)',
		'&::after': {
			width: '12px',
			height: '12px',
		},
		'&::before': {
			boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
		},
		'&:hover, &.Mui-focusVisible, &.Mui-active': {
			boxShadow: 'none',
		},
	},
	'& .MuiSlider-rail': {
		width: 'calc(100% + 12px)',
		left: '-6px ',
	},
}));
export const TypographySlider = styled(Typography)(({ theme }) => ({
	marginBottom: '9.5px',
	letterSpacing: '0.15px',
	color: 'var(--color-grey100)',
	opacity: 0.54,
	font: 'var(--gilroy-Medium-14)',
}));

export const AudioPlayerSlider = styled(Slider)(({ theme }) => ({
	color: 'var(--color-black)',
	height: '4px',
	padding: '0  !important',
	position: 'absolute',
	borderRadius: '0',
	bottom: '0',
	left: '6px',
	width: 'calc(100% - 14px)',
	'& .MuiSlider-track': {
		border: '0px solid transparent',
		borderRadius: '0',
		'&::before': {
			content: '""',
			position: 'absolute',
			width: '100%',
			left: '-7px',
			top: '50%',
			height: '100%',
			background: 'var(--color-light-green)',
			transform: 'translateY(-50%)',
		},
	},
	'& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen': {
		transform: 'translateY(-100%) scale(0)',
	},
	'& .MuiSlider-valueLabel': {
		transform: 'translateY(-100%) scale(0)',
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		opacity: 0.87,
		backgroundColor: 'transparent',
		top: '0',
		padding: '0',
	},

	'& .MuiSlider-thumb': {
		width: '14px',
		height: '14px',
		color: 'var(--color-light-green)',
		'&::after': {
			width: '14px',
			height: '14px',
		},
		'&::before': {
			boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
		},
		'&:hover, &.Mui-focusVisible, &.Mui-active': {
			boxShadow: 'none',
		},
	},
	'& .MuiSlider-rail': {
		left: '-7px ',
		backgroundColor: 'var(--color-white100)',
		opacity: 0.3,
	},
}));

export const AudioPlayerSliderVolume = styled(Slider)(({ theme }) => ({
	color: 'var(--color-black)',
	width: '4px',

	left: 'auto',
	top: '12px',
	padding: '0 12px',
	height: 'calc(100% - 26px)',
	'& .MuiSlider-track': {
		bottom: '0 !important',
		left: 'auto !important',
		borderRadius: 0,
		color: 'var(--color-light-green)',

		'&::before': {
			content: '""',

			background: 'var(--color-light-green)',
			transform: 'translateX(-50%)',
		},
	},
	'& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen': {
		transform: 'translateX(-100%) scale(0)',
	},
	'& .MuiSlider-valueLabel': {
		transform: 'translateX(-100%) scale(0)',
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		opacity: 0.87,
		backgroundColor: 'transparent',
		top: '0',
		padding: '0',
	},

	'& .MuiSlider-thumb': {
		width: '14px',
		height: '14px',
		color: 'var(--color-light-green)',
		left: 'auto !important',

		'&::after': {
			width: '14px',
			height: '14px',
		},
		'&::before': {
			boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
		},
		'&:hover, &.Mui-focusVisible, &.Mui-active': {
			boxShadow: 'none',
		},
	},
	'& .MuiSlider-rail': {
		borderRadius: 0,
		left: 'auto',
		backgroundColor: 'var(--color-white100)',
		opacity: 0.3,
	},
}));

export const CheckboxMyShares = styled(Checkbox)(({ theme }) => ({
	color: 'var(--color-grey100)',
	'&.Mui-checked': {
		color: 'var(--color-light-green)',
	},
}));
export const FormControlLabelCheckbox = styled(FormControlLabel)(
	({ theme }) => ({
		margin: 0,
		'& .MuiFormControlLabel-label': {
			font: 'var(--gilroy-Medium-16-150)',
			color: 'var(--color-black)',
			opacity: 0.87,
			letterSpacing: '0.15px',
			pointerEvents: 'none',
		},
		'& .MuiSvgIcon-root': {
			width: 20,
			height: 20,
			margin: '11px',
		},
		'& .MuiCheckbox-root': {
			padding: 0,
		},
	})
);
export const AutocompleteInput = styled(Autocomplete)(({ theme }) => ({
	'& .MuiInput-root': {
		'& > .MuiChip-filled:not(:nth-child(1)), & > .MuiChip-filled:not(:nth-child(2))': {
			opacity: 0,
			visibility: 'hidden',
			display: 'none',
		},
		'& > .MuiChip-filled:nth-child(1), & > .MuiChip-filled:nth-child(2)': {
			opacity: 1,
			visibility: 'visible',
			display: 'inline-flex',
		},
	},
	'& .MuiInput-input': {
		// padding: '0 0 6.5px !important',
		paddingBottom: '10px !important',
		paddingTop: '8px !important',
	},
	'& .MuiInput-underline.MuiAutocomplete-inputRoot.MuiInput-root': {
		padding: '0 !important',
		flexWrap: 'nowrap',
		paddingLeft: '4px !important',
		paddingRight: '4px !important',
		overflow: 'hidden',
	},

	'& .MuiIconButton-root': {
		padding: 0,
		margin: 0,
	},
	'& .MuiChip-filled': {
		margin: '0 8px 0px 0',
		borderRadius: 0,
		position: 'relative',
		// bottom: '2px',
		height: 'auto',
		'& > span': {
			padding: '4px 0 4px 8px',
			font: 'var(--gilroy-Medium-16-150)',
		},
		'& > svg': {
			margin: '6.5px 9.5px 6.5px',
			fontSize: '16px',
			color: 'var(--color-black)',
			opacity: 0.6,
		},
	},

	'&.MuiAutocomplete-hasClearIcon label.MuiInputLabel-root': {
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		transform: 'none',
		paddingLeft: '4px !important',
		paddingRight: '4px !important',
	},

	'&.MuiAutocomplete-hasClearIcon .MuiInput-root::before': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},

	'&.MuiAutocomplete-hasClearIcon .MuiInput-root:hover::before': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},
}));

export const AutocompleteInputNotificationsAdmin = styled(Autocomplete)(
	({ theme }) => ({
		// '& .MuiInput-root': {
		// 	'& > .MuiChip-filled:not(:nth-child(1)), & > .MuiChip-filled:not(:nth-child(2))': {
		// 		opacity: 0,
		// 		visibility: 'hidden',
		// 		display: 'none',
		// 	},
		// 	'& > .MuiChip-filled:nth-child(1), & > .MuiChip-filled:nth-child(2)': {
		// 		opacity: 1,
		// 		visibility: 'visible',
		// 		display: 'inline-flex',
		// 	},
		// },
		'& .MuiInput-input': {
			// padding: '0 0 6.5px !important',
			padding: '8px 4px 10px !important',
		},
		'& .MuiInput-underline.MuiAutocomplete-inputRoot.MuiInput-root': {
			padding: '0 !important',
			flexWrap: 'nowrap',
			overflow: 'hidden',
		},

		'& .MuiIconButton-root': {
			padding: 0,
			margin: 0,
		},
		'& .MuiChip-filled': {
			margin: '0 8px 0px 0',
			borderRadius: 0,
			position: 'relative',
			// bottom: '2px',
			height: 'auto',
			'& > span': {
				padding: '4px 0 4px 8px',
				font: 'var(--gilroy-Medium-16-150)',
			},
			'& > svg': {
				margin: '6.5px 9.5px 6.5px',
				fontSize: '16px',
				color: 'var(--color-black)',
				opacity: 0.6,
			},
		},
		'&.MuiAutocomplete-hasClearIcon label.MuiInputLabel-root': {
			font: 'var(--gilroy-Medium-14)',
			color: 'var(--color-grey100)',
			transform: 'none',
			paddingLeft: '4px !important',
			paddingRight: '4px !important',
		},
		'&.MuiAutocomplete-hasClearIcon .MuiInput-root::before': {
			borderBottom: '2px solid var(--color-dark-grey)',
		},

		'&.MuiAutocomplete-hasClearIcon .MuiInput-root:hover::before': {
			borderBottom: '2px solid var(--color-dark-grey)',
		},
	})
);

export const AutocompleteInputGenres = styled(Autocomplete)(({ theme }) => ({
	'& .MuiInput-root': {
		'& > .MuiChip-filled:not(:nth-child(1)), & > .MuiChip-filled:not(:nth-child(2))': {
			opacity: 0,
			/* visibility: 'hidden', */
			visibility: 'visible',
			display: 'none',
		},
		'& > .MuiChip-filled:nth-child(1), & > .MuiChip-filled:nth-child(2)': {
			opacity: 1,
			visibility: 'visible',
			display: 'inline-flex',
			backgroundColor: 'var(--color-light-grey)',
			'& > svg': {
				fill: 'var(--color-grey)',
				opacity: '1',
			},
		},
		'&::before': {
			borderBottom: '2px solid var(--color-grey)',
		},
	},
	'& .MuiInput-input': {
		// marginLeft: '15px',
		// padding: '0 0 6.5px !important',
		padding: '8px 4px 10px !important',
	},
	'& .MuiInput-underline.MuiAutocomplete-inputRoot.MuiInput-root': {
		padding: '0 !important',
		flexWrap: 'nowrap',
		overflow: 'hidden',
	},

	'& .MuiIconButton-root': {
		padding: 0,
		margin: 0,
	},
	'& .MuiChip-filled': {
		margin: '0 8px 0px 0',
		borderRadius: 0,
		position: 'relative',
		// bottom: '2px',
		height: 'auto',
		'& > span': {
			padding: '4px 0 4px 8px',
			font: 'var(--gilroy-Medium-16-150)',
		},
		'& > svg': {
			margin: '6.5px 9.5px 6.5px',
			fontSize: '16px',
			fill: 'var(--color-black)',
			// color: 'var(--color-black)',
			opacity: 0.6,
		},
	},
	'&.MuiAutocomplete-hasClearIcon label.MuiInputLabel-root': {
		font: 'var(--gilroy-Medium-14)',
		color: 'var(--color-grey100)',
		transform: 'none',
		paddingLeft: '4px !important',
		paddingRight: '4px !important',
	},

	'&.MuiAutocomplete-hasClearIcon .MuiInput-root::before': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},

	'&.MuiAutocomplete-hasClearIcon .MuiInput-root:hover::before': {
		borderBottom: '2px solid var(--color-dark-grey)',
	},
}));

export const AutocompleteInputPseudonyms = styled(Autocomplete)(
	({ theme }) => ({
		'& .MuiInput-input': {
			// marginLeft: '15px',
			// padding: '0 0 6.5px !important',
			padding: '8px 4px 10px !important',
		},
		'& .MuiInput-underline.MuiAutocomplete-inputRoot.MuiInput-root': {
			padding: '0 !important',
			flexWrap: 'nowrap',
			overflow: 'hidden',
		},

		'& .MuiIconButton-root': {
			padding: 0,
			margin: 0,
		},
		'& .MuiChip-filled': {
			margin: '0 8px 0px 0',
			borderRadius: 0,
			position: 'relative',
			// bottom: '2px',
			height: 'auto',
			'& > span': {
				padding: '4px 0 4px 8px',
				font: 'var(--gilroy-Medium-16-150)',
			},
			'& > svg': {
				margin: '6.5px 9.5px 6.5px',
				fontSize: '16px',
				fill: 'var(--color-black)',
				// color: 'var(--color-black)',
				opacity: 0.6,
			},
		},
		'&.MuiAutocomplete-hasClearIcon label.MuiInputLabel-root': {
			font: 'var(--gilroy-Medium-14)',
			color: 'var(--color-grey100)',
			transform: 'none',
			paddingLeft: '4px !important',
			paddingRight: '4px !important',
		},

		'&.MuiAutocomplete-hasClearIcon .MuiInput-root::before': {
			borderBottom: '2px solid var(--color-dark-grey)',
		},

		'&.MuiAutocomplete-hasClearIcon .MuiInput-root:hover::before': {
			borderBottom: '2px solid var(--color-dark-grey)',
		},
	})
);

export const SwitchSave = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
		color: 'var(--color-green)',
	},
	'&.MuiSwitch-thumb': {
		color: 'var(--color-white200)',
		boxShadow:
			'0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: 'var(--color-light-green) ',
	},
	'& .MuiSwitch-switchBase + .MuiSwitch-track': {
		backgroundColor: 'rgba(34, 31, 31, 0.26) ',
	},
}));
export const SwitchSaveDark = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
		color: 'var(--color-dark-grey100)',
	},
	'&.MuiSwitch-thumb': {
		color: 'var(--color-white200)',
		boxShadow:
			'0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: 'var(--color-dark-grey) ',
	},
	'& .MuiSwitch-switchBase + .MuiSwitch-track': {
		backgroundColor: 'rgba(34, 31, 31, 0.26) ',
	},
}));
export const InputSlider = styled(MuiInput)(({ theme }) => ({
	display: 'block',
	marginBottom: '7.5px',
	font: ' var(--gilroy-Medium-16-19)',
	letterSpacing: '0.15px',
	color: 'var(--color-black)',
	opacity: 0.87,
	'& .MuiInput-input': {
		padding: 0,
		height: 'auto',
	},
	'&::before': {
		border: 'none',
	},
	'&:hover:not(.Mui-Disabled)::before': {
		border: 'none',
	},
	'&::after': {
		border: 'none',
	},
	'&.Mui-focused::after': {
		border: 'none',
	},
}));
