// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';

// UI
import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { RadioSexGroup } from 'components/Radio';
import { FormInput } from 'components/Form/FormInput';
import { SettingsLayout } from 'pages/accountSettings/SettingsCategory/SettingsLayout';

// Style
import styles from './Individual.module.css';

const Individual = (props) => {
	const lang = localStorage.getItem('lang');

	const [errors, setErrors] = useState({});
	const [personal, setPersonal] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [patronymic, setPatronymic] = useState(true);
	const [isChangedSmth, setIsChangedSmth] = useState(false);

	const {
		rootContext: { countries },
		UIContext: { showModal },
	} = props;

	const message = DOMPurify.sanitize(...getHelpMessage(lang, 'personal_info'));

	const changeField = (field) => (value) => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		setPersonal((prev) => ({ ...prev, [field]: value }));
	};

	const saveHandler = (e) => {
		e.preventDefault();

		setIsChangedSmth(false);
		props.saveHandler(personal);
	};

	const handleEditToggle = () => {
		setIsEdit(!isEdit);
	};

	const patronymicCheckboxHandler = () => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		if (patronymic) {
			setPersonal((prev) => ({ ...prev, patronymic: '' }));
		}

		setPatronymic(!patronymic);
	};

	useEffect(() => {}, [lang]);
	useEffect(() => {
		setPersonal({ sex: 'male', ...props.data });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setErrors(props.errors);
		setIsEdit(Object.keys(props.errors).length > 0);
		setIsChangedSmth(Object.keys(props.errors).length > 0);
	}, [props.errors]);

	return (
		<>
			{personal && (
				<SettingsLayout
					isEdit={isEdit}
					handleEditToggle={handleEditToggle}
					showModal={showModal}
					message={message}
					title="rod.account.personal.title"
				>
					<div>
						<RadioSexGroup
							changeField={changeField}
							errors={errors}
							personal={personal}
						/>

						<FormattedMessage id={'rod.field.lastname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'last_name'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.firstname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'first_name'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.patronymic'}>
							{(placeholder) => (
								<>
									<FormInput
										placeholder={placeholder}
										name={'patronymic'}
										onChange={changeField}
										errors={errors}
										data={personal}
										disabled={!patronymic}
										style={{
											pointerEvents: patronymic ? 'all' : 'none',
											opacity: patronymic ? '1' : '0.6',
										}}
									/>
									<Checkbox
										text={<FormattedMessage id={'rod.field.no_patronymic'} />}
										className={styles.checkbox}
										checked={!patronymic}
										onChange={patronymicCheckboxHandler}
									/>
								</>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.birthdate'}>
							{(placeholder) => (
								<FormInput
									type="date"
									name={'birth_date'}
									placeholder={placeholder}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									isEdit={isEdit}
									hideCross={true}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.registration_address'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'birth_place'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						{isEdit && (
							<div className={styles.save_btn}>
								<Button
									text={<FormattedMessage id={'rod.action.save'} />}
									variant={'primary'}
									onClick={saveHandler}
									disabled={!isEdit || !isChangedSmth}
									style={{
										pointerEvents: isChangedSmth ? 'all' : 'none',
									}}
								/>
							</div>
						)}
					</div>
					<div>
						<FormattedMessage id={'rod.field.passport'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'national_id_number'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.passport_issuer'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'national_id_issued_by'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.passport_issue_date'}>
							{(placeholder) => (
								<FormInput
									type="date"
									name={'national_id_issued_date'}
									placeholder={placeholder}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									isEdit={isEdit}
									hideCross={true}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.passport_expire_date'}>
							{(placeholder) => (
								<FormInput
									type="date"
									name={'national_id_expiration_date'}
									placeholder={placeholder}
									onChange={changeField}
									errors={errors}
									data={personal}
									isEdit={isEdit}
									hideCross={true}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.tax_number'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'itin'}
									onChange={changeField}
									errors={errors}
									data={personal}
									required={
										window.location.href.includes('.ru') ||
										window.location.href.includes('broma16ru-stage')
											? true
											: false
									}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.residence_country'}>
							{(placeholder) => (
								<FormInput
									type="country"
									countries={countries}
									placeholder={placeholder}
									name={'country_id'}
									onChange={changeField}
									errors={errors}
									data={personal}
									className={styles.country}
								/>
							)}
						</FormattedMessage>
					</div>
				</SettingsLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(Individual);
