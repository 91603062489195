// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { RootContext } from 'contexts/RootContext';

// Helpers
import { allStatusesChecked } from './helpers/allStatusesChecked';
import { fm } from 'helpers';

// UI
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels';

// Styles
import styles from '../AccountData.module.css';

const PaymentsWithdrawal = ({ payments }) => {
	const [accountPayments, setAccountPayments] = useState(payments);
	const [status, setStatus] = useState('');
	const [isBtnDisabled, setIsBtnDisabled] = useState(false);
	const [disabledPayments, setDisabledPayments] = useState(false);

	const { changePaymentStatus } = useContext(RootContext);

	const submitPaymentStatusHandler = async () => {
		setIsBtnDisabled(true);
		setDisabledPayments(true);

		try {
			const promises = accountPayments
				.filter((payment) => payment.status === 'moderation')
				.map(async (payment) => {
					if (!payment.moderation_status) {
						const nullModerationStatus =
							'{"quality":"0","signature":"0","valid":"0"}';
						const moderationStatus = JSON.parse(nullModerationStatus);
						payment.moderation_status = JSON.stringify(moderationStatus);
					}

					const quality =
						JSON.parse(payment.moderation_status).quality === '1' ? 1 : 0;
					const signature =
						JSON.parse(payment.moderation_status).signature === '1' ? 1 : 0;
					const valid =
						JSON.parse(payment.moderation_status).valid === '1' ? 1 : 0;

					const { data } = await changePaymentStatus(
						payment.id,
						quality,
						signature,
						valid
					);
					console.info('data', data);
					return data;
				});

			const results = await Promise.all(promises);
			console.info(results);
		} catch (error) {
			console.error(error);
		}
	};

	const toggleModerationStatus = (paymentId, field) => (event) => {
		const value = event.currentTarget.checked ? '1' : '0';
		const updatedPayments = accountPayments.map((payment) => {
			if (payment.id === paymentId) {
				if (!payment.moderation_status) {
					const nullModerationStatus =
						'{"quality":"0","signature":"0","valid":"0"}';
					const moderationStatus = JSON.parse(nullModerationStatus);
					moderationStatus[field] = value;
					return {
						...payment,
						moderation_status: JSON.stringify(moderationStatus),
					};
				}

				if (payment.moderation_status) {
					const moderationStatus = JSON.parse(payment.moderation_status);
					moderationStatus[field] = value;
					return {
						...payment,
						moderation_status: JSON.stringify(moderationStatus),
					};
				}
			}

			return payment;
		});

		setAccountPayments(updatedPayments);

		if (
			allStatusesChecked(
				updatedPayments.filter((payment) => payment.status === 'moderation')
			)
		) {
			setStatus(fm('rod.account.payment.status.in_processing'));
		} else {
			setStatus(fm('rod.account.payment.status.rejected'));
		}
	};

	useEffect(() => {
		if (
			allStatusesChecked(
				accountPayments.filter((payment) => payment.status === 'moderation')
			)
		) {
			setStatus(fm('rod.account.payment.status.in_processing'));
		} else {
			setStatus(fm('rod.account.payment.status.rejected'));
		}

		const isPaymentsOnModeration = accountPayments.some(
			(payment) => payment.status === 'moderation'
		);
		if (isPaymentsOnModeration) {
			setIsBtnDisabled(false);
		} else {
			setIsBtnDisabled(true);
		}
	}, [accountPayments]);

	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.field.payments_withdrawal')}
					font="--gilroy-Medium-32"
				/>
			</div>
			<table className={styles.Table}>
				<thead>
					<tr>
						<th>{fm('rod.admin.account.files.document_type')}</th>
						<th>{fm('rod.admin.account.files.file')}</th>
						<th className="cb">{fm('rod.admin.account.files.quality')}</th>
						<th className="cb">{fm('rod.admin.account.contracts.signs')}</th>
						<th className="cb">
							{fm('rod.admin.account.contracts.all_pages')}
						</th>
					</tr>
				</thead>
				<tbody>
					{accountPayments.map((payment, index) => (
						<tr key={payment.id}>
							<td>
								<FormattedMessage id='rod.account.payment.payment_type' />
							</td>
							<td
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<div>{payment.signed_url.id}.pdf</div>
								<a
									href={payment.signed_url.url}
									target="_blank"
									rel="noreferrer"
									style={{
										marginLeft: 'auto',
									}}
								>
									<i className="fa fa-download" />
								</a>
							</td>
							<td className="cb">
								<div data-name={'01' + index}>
									<Checkbox
										className={styles.Checkbox}
										disabled={
											payment.status !== 'moderation' || disabledPayments
										}
										checked={
											(payment.moderation_status &&
												JSON.parse(payment.moderation_status).quality ===
													'1') ||
											(!payment.moderation_status && payment.sign === 2)
												? true
												: false
										}
										onChange={toggleModerationStatus(payment.id, 'quality')}
									/>
								</div>
							</td>
							<td className="cb">
								<div data-name={'02' + index}>
									<Checkbox
										className={styles.Checkbox}
										disabled={
											payment.status !== 'moderation' || disabledPayments
										}
										checked={
											(payment.moderation_status &&
												JSON.parse(payment.moderation_status).signature ===
													'1') ||
											(!payment.moderation_status && payment.sign === 2)
												? true
												: false
										}
										onChange={toggleModerationStatus(payment.id, 'signature')}
									/>
								</div>
							</td>
							<td className="cb">
								<div data-name={'03' + index}>
									<Checkbox
										className={styles.Checkbox}
										disabled={
											payment.status !== 'moderation' || disabledPayments
										}
										checked={
											(payment.moderation_status &&
												JSON.parse(payment.moderation_status).valid === '1') ||
											(!payment.moderation_status && payment.sign === 2)
												? true
												: false
										}
										onChange={toggleModerationStatus(payment.id, 'valid')}
									/>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			{accountPayments.length &&
			accountPayments.some((payment) => payment.status === 'moderation') ? (
				<div className={styles.paymentsStatus}>
					<div>
						<div>
							{fm('rod.admin.account.files.set_status')}
							{': '}
							{status}
						</div>
					</div>

					<Button
						primary
						text={
							isBtnDisabled
								? fm('rod.admin.account.files.sent')
								: fm('rod.admin.account.files.to_send')
						}
						disabled={isBtnDisabled}
						className={styles.Button}
						onClick={submitPaymentStatusHandler}
					/>
				</div>
			) : (
				''
			)}
		</section>
	);
};

export default PaymentsWithdrawal;
