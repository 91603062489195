/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/02/2021, 11:32
 */

import { Loading } from 'components';
import { Label } from 'components/Labels';
import { InfoButton } from 'components/Buttons';
import { withAuth, withRoot, withUI } from 'hocs';
import { ReactComponent as CreateImg } from 'images/add-account.svg';
import styles from 'pages/account/Accounts/Accounts.module.css';
import Create from 'pages/account/Accounts/components/Create';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { Col, Container, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { getHelpMessage } from '../../../messages/helper';
import { partner } from 'messages/EN/pages_branding';
import { auth } from 'services';

class Accounts extends Component {
	static propTypes = {
		accounts: PropTypes.array,
	};

	static defaultProps = {};

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			accountsQty: 0,
			allAccounts: [],
		};
	}

	componentDidMount() {
		this.props.UIContext.hideBackNavi();
		const {
			UIContext: { hideNavAndButtons },
			authContext: { user },
		} = this.props;

		hideNavAndButtons();

		auth.getAllUserAccounts().then((res) => {
			res = res.data.data;
			this.setState({
				loading: false,
				accountsQty: user.accounts.length,
				allAccounts: res.accounts,
			});
		});
	}

	componentWillUnmount() {
		const {
			UIContext: { showNavAndButtons },
		} = this.props;

		showNavAndButtons();
	}

	render() {
		const {
			authContext: { user },
		} = this.props;
		const { loading } = this.state;

		if (loading) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>
						Accounts-{user.title} | {partner.title_rod_company}
					</title>
				</Helmet>
				<Container fluid style={{ height: '100%' }}>
					<Row style={{ height: '100%' }}>
						<Col offset={{ sm: 1 }} sm={10} className={styles.Page}>
							{this.state.allAccounts.length > 0
								? this.renderAccounts(this.state.allAccounts)
								: this.renderCreateAccount()}
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}

	getTitle = (acc) => {
		if (acc.title) {
			return acc.title;
		} else {
			return `Account #${acc.id}`;
		}
	};

	renderAccounts(accounts) {
		const {
			UIContext: { showModal },
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'account_step_1')
		);

		return (
			<div className={styles.Content}>
				<div className={styles.titleWrapper}>
					<Label black font="--gilroy-Medium-40">
						<FormattedMessage id={'rod.account.header'} />
					</Label>
				</div>
				<InfoButton
					onClick={showModal({
						text: parse(message),
					})}
				>
					<Label black font="--gilroy-Medium-24" display="inline">
						<FormattedMessage id={'rod.account.sub_header'} />
					</Label>
				</InfoButton>
				<div className={styles.List}>
					{this.props.UIContext.isAllowAccountCreate === true &&
						this.props.UIContext.isAdditionalAccountCreationEnabled ===
							true && (
							<div className={styles.Create}>
								<Link to={'/accounts/new'}>
									<Create />
								</Link>
							</div>
						)}

					{accounts.map((acc) => (
						<Link
							key={acc.id}
							to={`/accounts/${acc.id}`}
							className={styles.Account}
						>
							<span>{this.getTitle(acc)}</span>
						</Link>
					))}
				</div>
			</div>
		);
	}

	isRenderCreateAccount = () => {
		const isAllowCreatingMain = this.props.UIContext.isAllowAccountCreate;
		switch (isAllowCreatingMain) {
			case true:
				if (!this.state.accountsQty) {
					return true;
				} else if (this.props.UIContext.isAdditionalAccountCreationEnabled) {
					return true;
				} else return false;

			case false:
				return false;
			default:
				break;
		}
	};

	renderCreateAccount() {
		const {
			UIContext: { isAdditionalAccountCreationEnabled },
		} = this.props;
		return (
			<>
				{this.isRenderCreateAccount && (
					<div className={styles.Content}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage
								id={'rod.account.header.have_not_account_yet'}
							/>
						</Label>
						{isAdditionalAccountCreationEnabled && (
							<Label
								l
								black
								font="--gilroy-Medium-24"
								className={styles.SubHeader}
							>
								<FormattedMessage
									id={'rod.account.sub_header.have_not_account_yet'}
								/>
							</Label>
						)}
						<div className={styles.List}>
							<Link to={'/accounts/new'}>
								<div className={styles.Create}>
									{/* <img src={createImg} alt="" /> */}
									<CreateImg className={styles.svg_branding} />
								</div>
							</Link>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(Accounts);
