// Langs
import pagesEn from 'messages/EN/pages';
import pagesRu from 'messages/RU/pages';

export const filterCompositionSettingsData = [
	{
		id: 1,
		title_ru: 'статус',
		title_en: 'status',
		list: [
			{
				id: 1,
				name_ru: pagesRu['rod.draft.release.moderation'],
				name_en: pagesEn['rod.draft.release.moderation'],
				code: 'draft_processing',
				checked: false,
			},
			{
				id: 2,
				name_ru: pagesRu['rod.treaties_page.pending_review'],
				name_en: pagesEn['rod.treaties_page.pending_review'],
				code: 'draft_verify',
				checked: false,
			},
			{
				id: 3,
				name_ru: pagesRu['rod.repertoire.not_ready'],
				name_en: pagesEn['rod.repertoire.not_ready'],
				code: 'not_ready',
				checked: false,
			},
			{
				id: 4,
				name_ru: pagesRu['rod.repertoire.ready'],
				name_en: pagesEn['rod.repertoire.ready'],
				code: 'ready',
				checked: false,
			},
			{
				id: 5,
				name_ru: pagesRu['rod.repertoire.status.disputed'],
				name_en: pagesEn['rod.repertoire.status.disputed'],
				code: 'disputed',
				checked: false,
			},
			{
				id: 6,
				name_ru: pagesRu['rod.composition.filter.active'],
				name_en: pagesEn['rod.composition.filter.active'],
				code: 'active',
				checked: false,
			},
			{
				id: 7,
				name_ru: pagesRu['rod.repertoire.expiring'],
				name_en: pagesEn['rod.repertoire.expiring'],
				code: 'expiring',
				checked: false,
			},
			{
				id: 8,
				name_ru: pagesRu['rod.composition.filter.expired'],
				name_en: pagesEn['rod.composition.filter.expired'],
				code: 'expired',
				checked: false,
			},
			{
				id: 9,
				name_ru: pagesRu['rod.repertoire.draft_verify'],
				name_en: pagesEn['rod.repertoire.draft_verify'],
				code: 'verify',
				checked: false,
			},
		],
	},
];

export const filterVerifyCompositionsData = {
	'19': {
		parentId: 1,
		currentId: 9,
		id: '19',
		title_en: 'status',
		title_ru: 'статус',
		name_en: 'Pending review',
		name_ru: 'на проверке',
		code: 'verify',
		show: true,
	},
};

export const filterStatuses = [
	{
		id: 1,
		title_ru: 'статус',
		title_en: 'status',
		list: [
			{
				id: 1,
				name_ru: 'в обработке',
				name_en: 'Processing',
				code: 'draft_processing',
				checked: false,
			},
			{
				id: 2,
				name_ru: 'На проверке',
				name_en: 'Pending review',
				code: 'draft_verify',
				checked: false,
			},
			{
				id: 3,
				name_ru: 'не готово',
				name_en: 'Not ready',
				code: 'not_ready',
				checked: false,
			},
			{
				id: 4,
				name_ru: 'готово',
				name_en: 'Ready',
				code: 'ready',
				checked: false,
			},
			{
				id: 5,
				name_ru: 'в диспуте',
				name_en: 'Disputed',
				code: 'disputed',
				checked: false,
			},
			{
				id: 6,
				name_ru: 'активные',
				name_en: 'active',
				code: 'active',
				checked: false,
			},
			{
				id: 7,
				name_ru: 'заканчивающиеся',
				name_en: 'Expiring',
				code: 'expiring',
				checked: false,
			},
			{
				id: 8,
				name_ru: 'закончившиеся',
				name_en: 'expired',
				code: 'expired',
				checked: false,
			},
			{
				id: 9,
				name_ru: 'на проверке',
				name_en: 'Pending review',
				code: 'verify',
				checked: true,
			},
		],
	},
];
