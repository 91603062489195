/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 17/12/2020, 12:57
 */

import { RegisterVerifySMS as VerifyForm } from 'forms/index';
import { withAuth, withRoot, withUI } from 'hocs';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import styles from './SMSCheck.module.css';

class SMSCheck extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);
		this.state = {
			remainSMSTime: '',
			errors: {},
		};
	}

	componentDidMount() {
		const {
			authContext: { resendSMS },
		} = this.props;

		resendSMS()
			.then()
			.catch((err) => {
				if (
					err.status &&
					err.status === 'validation_error' &&
					err?.errors?.code[0]?.rule === 'retry'
				) {
					const lastTime = new Date(err.errors.code[0].value);
					const currTime = new Date();
					const remainMs = lastTime - currTime;
					this.setState({ remainSMSTime: remainMs });
				} else this.setState({ errors: err.errors });
			});
	}

	componentWillUnmount() {}

	render() {
		const {
			authContext: { verifySMS, resendSMS, regData, errors, user },
		} = this.props;

		return (
			<div className={styles.Page}>
				<VerifyForm
					onSubmit={verifySMS}
					clearAllErrors={this.props.authContext.clearAllErrors}
					onResend={resendSMS}
					phone={regData.phone || user.phone}
					errors={{ ...errors, ...this.state.errors }}
					remainSMSTime={this.state.remainSMSTime}
				/>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(SMSCheck);
